import { createContext, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { learningState } from '../recoil/model/learning';
import { deviceState } from '../recoil/common/device';
import { CDN } from '../utils/constants';
import { userState } from '../recoil/model/user';
import { routesState } from 'recoil/common/routes';

declare let window: any;

interface BackgroundSoundContextType {
  firstStart: () => void;
  toggleBGM: () => void;
  muteBGM: () => void;
  upVolumeBGM: () => void;
  resetBGM: () => void;
  playingBGM: boolean;
  firstPlayingBGM: boolean;
}

interface Props {
  children: React.ReactNode;
}

export const BackgroundSoundContext = createContext<BackgroundSoundContextType>({
  firstStart: () => {
    return;
  },
  toggleBGM: () => {
    return;
  },
  muteBGM: () => {
    return;
  },
  upVolumeBGM: () => {
    return;
  },
  resetBGM: () => {
    return;
  },
  playingBGM: false,
  firstPlayingBGM: true,
});

export const BackgroundSoundContextProvider = ({ children }: Props) => {
  const [playingBGM, setPlayingBGM] = useState(false);
  const [firstPlayingBGM, setFirstplayBGM] = useState(true);
  const firstPlayingBGMRef = useRef(true);
  const bgm_01 = `/assets/audio/bgm/bgm_01.mp3`;
  const bgm_02 = `/assets/audio/bgm/bgm_02.mp3`;
  const [bgmTrack, setBgmTrack] = useState(bgm_01);
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const [prevState, setPrevState] = useState(false);
  const userStateData = useRecoilValue(userState);
  const { pass } = userStateData;
  const passRef = useRef(pass);
  const learningStateData = useRecoilValue(learningState);
  const { path } = useRecoilValue(routesState);
  const { status } = learningStateData;
  const statusRef = useRef(status);
  statusRef.current = status;
  const volumeRef = useRef(0.35);
  const deviceStateData = useRecoilValue(deviceState);
  const { device_pause } = deviceStateData;
  const [prevPause, setPrevPause] = useState(false);

  const loadAudioFileAsBlob = async (filePath: string) => {
    const response = await fetch(filePath);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  const firstStart = () => {
    if (!passRef.current) return false;

    if (firstPlayingBGMRef.current) {
      setFirstplayBGM(false);
      setPlayingBGM(true);
    }
  };

  const toggleBGM = () => {
    if (!passRef.current) return false;

    if (firstPlayingBGMRef.current) {
      setFirstplayBGM(false);
      setPlayingBGM(false);
    } else setPlayingBGM(!playingBGM);
  };

  const muteBGM = () => {
    if (!passRef.current) return false;

    if (window?.cordova?.platformId == 'ios') {
      audioRef.current.muted = true;
    } else {
      const cur_volume = audioRef.current.volume;
      if (cur_volume == 0.35) {
        audioRef.current.volume = 0;
      }
    }
  };

  const upVolumeBGM = () => {
    if (!passRef.current) return false;

    if (window?.cordova?.platformId == 'ios') {
      audioRef.current.muted = false;
    } else {
      const cur_volume = audioRef.current.volume;
      if (cur_volume == 0) {
        audioRef.current.volume = 0.35;
      }
    }
  };

  const resetBGM = () => {
    if (bgmTrack == bgm_01) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    } else {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleEnd);
        audioRef.current.removeEventListener('playing', handlePlaying);
        audioRef.current.removeEventListener('pause', handlePause);
        audioRef.current.pause();
      }
      audioRef.current = new Audio();
      setBgmTrack(bgm_01);
    }

    setPlayingBGM(false);
    setFirstplayBGM(true);
  };

  useEffect(() => {
    if (playingBGM) {
      if (statusRef.current) {
        setPlayingBGM(false);
      } else {
        audioRef.current.volume = 0.35;
        audioRef.current.play().catch((e: object) => {
          setPlayingBGM(false);
          console.error(e);
        });
      }
    } else {
      audioRef.current.pause();
    }
  }, [playingBGM]);

  const handleEnd = () => {
    setBgmTrack(bgmTrack === bgm_01 ? bgm_02 : bgm_01);
  };

  const handlePause = () => {
    if (!audioRef.current.ended) {
      setPlayingBGM(false);
    }
  };

  const handlePlaying = () => {
    if (!playingBGM) setPlayingBGM(true);
  };

  const loadAudio = async () => {
    const blobUrl = window?.cordova?.platformId == 'ios' ? bgmTrack : await loadAudioFileAsBlob(bgmTrack);

    if (audioRef.current) {
      audioRef.current.removeEventListener('ended', handleEnd);
      audioRef.current.removeEventListener('playing', handlePlaying);
      audioRef.current.removeEventListener('pause', handlePause);
    }
    audioRef.current = new Audio(blobUrl);
    audioRef.current.volume = 0.35;
    audioRef.current.addEventListener('playing', handlePlaying);
    audioRef.current.addEventListener('ended', handleEnd);
    audioRef.current.addEventListener('pause', handlePause);
    if (playingBGM) {
      audioRef.current.play();
    }
  };

  useEffect(() => {
    loadAudio();

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleEnd);
        audioRef.current.removeEventListener('playing', handlePlaying);
        audioRef.current.removeEventListener('pause', handlePause);
        audioRef.current.pause();
      }
    };
  }, [bgmTrack]);

  useLayoutEffect(() => {
    if (path.includes('/learning')) {
      setPlayingBGM(false);
    }
  }, [path]);

  useEffect(() => {
    if (!firstPlayingBGM) {
      if (status) {
        setPrevState(playingBGM);
        if (playingBGM) {
          if (window?.cordova?.platformId == 'ios') {
            volumeRef.current = 0;
            setPlayingBGM(false);
          } else {
            volumeRef.current = 0.35;
            let fadeOutCount = 0;
            const fadeOutInterval = setInterval(() => {
              fadeOutCount++;
              volumeRef.current -= 0.035;
              audioRef.current.volume = Math.round(volumeRef.current * 100) / 100;
              if (audioRef.current.volume <= 0.035 || fadeOutCount >= 9) {
                audioRef.current.volume = 0;
                volumeRef.current = 0;
                clearInterval(fadeOutInterval);
                setPlayingBGM(false);
              }
            }, 100);
          }
        }
      } else {
        if (prevState) {
          setPlayingBGM(prevState);
          if (window?.cordova?.platformId == 'ios') {
            volumeRef.current = 0.35;
          } else {
            let fadeInCount = 0;
            const fadeInInterval = setInterval(() => {
              fadeInCount++;
              volumeRef.current += 0.035;
              audioRef.current.volume += 0.035;
              audioRef.current.volume = Math.round(volumeRef.current * 100) / 100;
              if (audioRef.current.volume >= 0.315 || fadeInCount >= 9) {
                volumeRef.current = 0.35;
                clearInterval(fadeInInterval);
              }
            }, 100);
          }
        }
      }
    }
  }, [status]);

  useEffect(() => {
    if (device_pause) {
      setPrevPause(playingBGM);
      setPlayingBGM(false);
    } else {
      if (prevPause) {
        setPlayingBGM(prevPause);
      }
    }
  }, [device_pause]);

  useEffect(() => {
    passRef.current = pass;
  }, [pass]);

  useEffect(() => {
    firstPlayingBGMRef.current = firstPlayingBGM;
  }, [firstPlayingBGM]);

  return (
    <BackgroundSoundContext.Provider
      value={{ firstStart, toggleBGM, playingBGM, firstPlayingBGM, resetBGM, muteBGM, upVolumeBGM }}
    >
      {children}
    </BackgroundSoundContext.Provider>
  );
};

export default BackgroundSoundContextProvider;
