import { useState, useEffect, useContext, useRef } from 'react';
import { ModalContext } from '../../provider/ModalProvider';
import FlipOver from 'react-flip-over';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { styled, Box, BoxProps, LinearProgress, linearProgressClasses } from '@mui/material';
import { dir_column, d_flex_center } from '../../styles/common';
import bi_white from '../../assets/images/longedu_bi_white.png';
import { useVoice, voiceState } from '../../recoil/common/voice';
import { options } from '../../utils/setting_contents';
import { userState } from '../../recoil/model/user';
import { fetchGetApi } from '../../utils/api';
import AutoText from '../../components/text/AutoText';
import theme from '../../theme';
import ModalEndModule from '../../components/modal/ModalEndModule';
import { makeEndModuleModalProps, shuffleWordBingo } from '../../utils/tools';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { deviceState } from '../../recoil/common/device';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';

interface WordbingoProps {
  sendResultsheet: () => void;
}

interface WordbingoBoxProps extends BoxProps {
  status: 'correct' | 'wrong' | '';
}

const StyledWordbingoWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledQuestionWrap = styled(Box)(props => ({
  width: '100%',
  height: '42.5%',
  ...d_flex_center,
}));

const StyledFlipOverQuestionBox = styled(FlipOver)(props => ({
  width: '23% !important',
  height: '17.5vh !important',
  padding: '4px',
}));

const StyledQuestionFront = styled(Box)<WordbingoBoxProps>(props => ({
  width: '100%',
  height: '100%',
  ...d_flex_center,
  border:
    props.status == 'correct'
      ? '1px solid #b6eabb'
      : props.status == 'wrong'
      ? '1px solid #ffd5d1'
      : `1px solid ${props.theme.palette.light_purple.main}`,
  boxShadow:
    props.status == 'correct'
      ? '0 0 12px 2px #bfffb5'
      : props.status == 'wrong'
      ? '0 0 12px 2px #ffd5d1'
      : `0 0 12px 2px ${props.theme.palette.light_purple.main}`,
  borderRadius: '1rem',
  backgroundColor: props.status == 'correct' ? '#daffd7' : props.status == 'wrong' ? '#ffe9e5' : 'transparent',
  outline: 'none',
  '& > span': {
    fontSize: '1.4rem',
    color:
      props.status == 'correct' ? '#1bcf3d' : props.status == 'wrong' ? '#ff4c39' : props.theme.palette.purple.main,
    fontWeight: '700',
  },
}));

const StyledQuestionBack = styled(Box)<WordbingoBoxProps>(props => ({
  width: '100%',
  height: '100%',
  ...d_flex_center,
  border:
    props.status == 'correct'
      ? '1px solid #b6eabb'
      : props.status == 'wrong'
      ? '1px solid #ffd5d1'
      : `1px solid ${props.theme.palette.light_purple.main}`,
  boxShadow:
    props.status == 'correct'
      ? '0 0 12px 2px #bfffb5'
      : props.status == 'wrong'
      ? '0 0 12px 2px #ffd5d1'
      : `0 0 12px 2px ${props.theme.palette.light_purple.main}`,
  borderRadius: '1rem',
  outline: 'none',
  backgroundColor:
    props.status == 'correct' ? '#daffd7' : props.status == 'wrong' ? '#ffe9e5' : props.theme.palette.purple.main,
}));

const StyledSelectionWrap = styled(Box)(props => ({
  width: '100%',
  height: '57.5%',
  borderTop: '1px solid #dde3e4',
  backgroundColor: '#fcfaff',
  ...d_flex_center,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

const StyledSelectionBox = styled(Box)(props => ({
  width: '70%',
  height: '100%',
  ...d_flex_center,
  flexWrap: 'wrap',
  alignContent: 'center',
  paddingTop: '1.5vh',
}));

const StyledFlipOverSelectionBox = styled(FlipOver)(props => ({
  width: '20% !important',
  padding: '4px',
}));

const StyledSelectionFront = styled(Box)<WordbingoBoxProps>(props => ({
  width: '100%',
  height: '100%',
  backgroundColor:
    props.status == 'correct' ? '#daffd7' : props.status == 'wrong' ? '#ffe9e5' : props.theme.palette.white.main,
  border:
    props.status == 'correct'
      ? '1px solid #b6eabb'
      : props.status == 'wrong'
      ? '1px solid #ffd6d2'
      : `1px solid #eee5fe`,
  borderRadius: '1rem',
  ...d_flex_center,
}));

const StyledSelectionBack = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#d4d7ff',
  opacity: '0.5',
  borderRadius: '1rem',
  border: '1px solid #d5d8ff',
  ...d_flex_center,
}));

const StyledWordbingoBottom = styled(Box)(props => ({
  width: '96%',
  height: '4rem',
  borderTop: '1px solid #e0e3e5',
  ...d_flex_center,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(props => ({
  height: '2rem',
  width: '100%',
  borderRadius: '2rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#f5f5f5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '2rem',
    transition: props.value == 100 ? 'none' : 'transform .2s linear',
    backgroundImage: props.value ? `linear-gradient(to right, #1fda72 ${100 - props.value}%, #39d5f3 100%)` : null,
  },
}));

function Wordbingo(props: WordbingoProps) {
  const sendResultsheet = props.sendResultsheet;
  const setTutorialState = useSetRecoilState(tutorialStateData);

  const { modal_alert } = useContext(ModalContext);

  const { playEffectSound } = useContext(EffectSoundContext);

  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);

  const [voiceStateData, setVoiceStateData] = useRecoilState<VoiceType>(voiceState);
  const { setVoice, voicePlay } = useVoice();

  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const {
    mod,
    current_step,
    contents,
    current_page,
    show_modal,
    resultsheet,
    bingo_card,
    current_module_settings,
    learning_type,
  } = learningStateData;

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const deviceStateData = useRecoilValue(deviceState);
  const { device_pause } = deviceStateData;

  const { retry, time } = current_module_settings.bingo;

  const timeout = Number(options['popquiz']['time'][time].value);

  const retryCnt = useRef<number>(retry);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [contentsLength, setContentsLength] = useState(0);
  const [word, setWord] = useState<string>(contents[current_step].spell);
  const [mean, setMean] = useState<string>(contents[current_step].mean);

  // const [disabledClick, setDisabledClick] = useState<boolean>(false);
  // disabledClickRef.current = disabledClick;
  const disabledClickRef = useRef(false);

  const [correct, setCorrect] = useState<boolean>(false);
  const [correctIndex, setCorrectIndex] = useState<number | null>(null);
  const [wrong, setWrong] = useState<boolean>(false);
  const [wrongIndex, setWrongIndex] = useState<number | null>(null);

  const [goResult, setGoResult] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(100);

  const [flipped, setFlipped] = useState<boolean>(false);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);

  const [logo, setLogo] = useState<string>(bi_white);

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });

  const nextCallback = () => {
    sendResultsheet();
  };

  useEffect(() => {
    if (userStateData.logo == '1') {
      fetchGetApi(`/customers/${userStateData.customer_id}/logo`).then(res => {
        if (res && res.data && res.data.logo) {
          setLogo(`data:image/png;base64,${res.data.logo}`);
        }
      });
    }
    let wordbingo_contents = [...contents];
    wordbingo_contents = wordbingo_contents.slice(0, 15);
    setContentsLength(wordbingo_contents.length);
    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      const resultsheet_tmp = mod[current_page].resultsheet as ResultsheetType[];
      const bingo_card_tmp = mod[current_page].bingo_card as WordbingoCardType[];

      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
        bingo_card: bingo_card_tmp,
      }));
    } else {
      const card: WordbingoCardType[] = [];
      for (let i = 0; i < wordbingo_contents.length; i++) {
        card.push({
          id: wordbingo_contents[i].id,
          spell: wordbingo_contents[i].spell,
          right: false,
        });
      }

      const shuffle_card = shuffleWordBingo(card);

      setLearningStateData(prevState => ({
        ...prevState,
        bingo_card: shuffle_card,
      }));
    }
    setPercent(setLoadingStateData, 100);
    return () => {
      setLearningStateData(prevState => {
        const cloneState = JSON.parse(JSON.stringify(prevState));
        delete cloneState['bingo_card'];
        return {
          ...cloneState,
        };
      });
    };
  }, []);

  useEffect(() => {
    if (show_modal || !loaded || correct || wrong || endModuleModal.visible || device_pause) return;
    if (timeout > 0) {
      if (progress > 0) {
        const tick = setTimeout(() => {
          setProgress(progress - 1);
        }, timeout * 10);

        return () => clearTimeout(tick);
      } else {
        const tick = setTimeout(() => {
          checkRight(-1);
        }, 200 + timeout * 10);

        return () => clearTimeout(tick);
      }
    }
  }, [show_modal, progress, correct, wrong, endModuleModal, device_pause]);

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.basic && learning_type != 5) {
        // 튜토리얼을 진행해야 한다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
        setTimeout(() => {
          setLoaded(true);
        }, 400);
      } else {
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('wordbingo_start', undefined, undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
          setLoaded(true);
        }, 400);
      }
    }
  }, [percent]);

  useEffect(() => {
    setContent(contents[current_step]);
  }, [current_step]);

  useEffect(() => {
    setVoiceStateData(() => ({
      element: undefined,
      playing: false,
      loading: false,
      error: false,
      ready: false,
      volume: 1,
    }));
    setWord(content['spell']);
    setMean(content['mean']);
    const voice = content['voice'] ? content['voice'] : '0.mp3';
    setVoice(content['spell'], voice);
  }, [content]);

  // useEffect(() => {
  //   if (goResult) {
  //     sendResultsheet();
  //   }
  // }, [goResult]);

  const checkRight = async (i: number) => {
    try {
      if (!bingo_card || (disabledClickRef.current && retryCnt.current == -1)) return false;
      setWrongIndex(null);
      const bingo_card_tmp = JSON.parse(JSON.stringify(bingo_card));
      const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
      clearTimeout1();
      if (i < 0) {
        retryCnt.current = -1;
        setWrong(true);
        setFlipped(true);
        disabledClickRef.current = true;
        // setDisabledClick(true);
        setLearningStateData(prevState => ({
          ...prevState,
          modal_disable: true,
        }));
        if (current_step < contentsLength - 1) {
          setMean(contents[current_step + 1].mean);
        }

        setTimeout(() => {
          voicePlay();
        }, 1500);
        console.log('wrong_1');
        await playEffectSound('wrong');
        if (current_step < contentsLength - 1) {
          setMean(contents[current_step + 1].mean);
        }
        resultsheet_tmp[current_step] = {
          no: current_step + 1,
          word_id: content['id'],
          answer: '',
          rightanswer: word,
          question: mean,
        };
        setLearningStateData(prevState => ({
          ...prevState,
          resultsheet: resultsheet_tmp,
        }));
        goNextProcess(resultsheet_tmp);
      } else {
        if (bingo_card[i].right) return false;
        const answer = bingo_card[i].spell;

        if (word == answer) {
          retryCnt.current = -1;
          setCorrect(true);
          setFlipped(true);
          setCorrectIndex(i);
          disabledClickRef.current = true;
          // setDisabledClick(true);
          setLearningStateData(prevState => ({
            ...prevState,
            modal_disable: true,
          }));
          setTimeout(() => {
            voicePlay();
          }, 1500);
          console.log('correct');
          await playEffectSound('correct');
          if (current_step < contentsLength - 1) {
            setMean(contents[current_step + 1].mean);
          }
          bingo_card_tmp[i].right = true;
          resultsheet_tmp[current_step] = {
            no: current_step + 1,
            word_id: content['id'],
            answer: answer,
            rightanswer: word,
            question: mean,
          };
          setLearningStateData(prevState => ({
            ...prevState,
            resultsheet: resultsheet_tmp,
            bingo_card: bingo_card_tmp,
          }));
          goNextProcess(resultsheet_tmp);
        } else if (retryCnt.current > 0) {
          // setDisabledClick(true);
          disabledClickRef.current = true;
          console.log('wrong_2');
          playEffectSound('wrong');
          setWrongIndex(i);
          retryCnt.current -= 1;
          settimeout1.current.push(
            setTimeout(() => {
              // setDisabledClick(false);
              disabledClickRef.current = false;
              setWrongIndex(null);
            }, 2000),
          );
        } else {
          retryCnt.current -= 1;
          setWrong(true);
          setFlipped(true);
          setWrongIndex(i);
          // disabledClickRef(true);
          disabledClickRef.current = true;
          setLearningStateData(prevState => ({
            ...prevState,
            modal_disable: true,
          }));

          setTimeout(() => {
            voicePlay();
          }, 1500);
          console.log('wrong_3');
          playEffectSound('wrong');
          await new Promise(resolve => setTimeout(resolve, 2000));
          if (current_step < contentsLength - 1) {
            setMean(contents[current_step + 1].mean);
          }
          resultsheet_tmp[current_step] = {
            no: current_step + 1,
            word_id: content['id'],
            answer: answer,
            rightanswer: word,
            question: mean,
          };
          setLearningStateData(prevState => ({
            ...prevState,
            resultsheet: resultsheet_tmp,
          }));
          goNextProcess(resultsheet_tmp);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearTimeout1 = () => {
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
  };

  const goNextProcess = (resultsheet_tmp: ResultsheetType[]) => {
    setFlipped(false);
    setWrongIndex(null);
    setCorrectIndex(null);
    setTimeout(() => {
      goNext(resultsheet_tmp);
    }, 1000);
  };

  const goNext = (resultsheet_tmp: ResultsheetType[]) => {
    if (!bingo_card) return false;
    retryCnt.current = retry;
    setWrong(false);
    setCorrect(false);
    // setDisabledClick(false);
    disabledClickRef.current = false;
    if (current_step < contentsLength - 1) {
      setProgress(100);
      setLearningStateData(prevState => ({
        ...prevState,
        current_step: current_step + 1,
        modal_disable: false,
      }));
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        modal_disable: false,
      }));

      const { label, icon, score, kor_label } = makeEndModuleModalProps(resultsheet_tmp);
      setEndModuleModal({ visible: true, icon, label, score, kor_label });
    }
  };

  return (
    <StyledWordbingoWrap>
      <StyledQuestionWrap>
        <StyledFlipOverQuestionBox flipped={flipped} duration={0.5}>
          <StyledQuestionFront
            status={correctIndex != null && correct ? 'correct' : wrongIndex != null && wrong ? 'wrong' : ''}
          >
            <AutoText
              sx={{
                width: '90%',
                textAlign: 'center',
                color:
                  correctIndex != null && correct
                    ? '#1bcf3d'
                    : wrongIndex != null && wrong
                    ? '#ff4c39'
                    : theme.palette.purple.main,
                fontWeight: '700',
              }}
            >
              {mean}
            </AutoText>
          </StyledQuestionFront>
          <StyledQuestionBack status={correct ? 'correct' : wrong ? 'wrong' : ''}>
            <AutoText
              sx={{
                width: '90%',
                textAlign: 'center',
                color: correct ? '#1bcf3d' : wrong ? '#ff4c39' : theme.palette.purple.main,
                fontWeight: '700',
              }}
              maxFontSize={1.54}
              minFontSize={1.4}
            >
              {word}
            </AutoText>
          </StyledQuestionBack>
        </StyledFlipOverQuestionBox>
      </StyledQuestionWrap>
      <StyledSelectionWrap>
        <StyledSelectionBox>
          {bingo_card
            ? (function () {
                const arr = [];
                for (let i = 0; i < contentsLength; i++) {
                  arr.push(
                    <StyledFlipOverSelectionBox
                      key={`wordbingo_selection_${i}`}
                      flipped={bingo_card[i].right ? true : false}
                      duration={0.5}
                      sx={{
                        height:
                          contentsLength > 10
                            ? '14vh !important'
                            : contentsLength > 5
                            ? '17vh !important'
                            : '20vh !important',
                      }}
                    >
                      <StyledSelectionFront
                        status={correct && correctIndex == i ? 'correct' : wrongIndex == i ? 'wrong' : ''}
                        onClick={() => checkRight(i)}
                      >
                        <AutoText
                          maxFontSize={1.54}
                          minFontSize={1.4}
                          sx={{
                            textAlign: 'center',
                            whiteSpace: 'break-spaces',
                            lineBreak: 'anywhere',
                            width: '90%',
                            fontWeight: '700',
                            color:
                              correct && correctIndex == i
                                ? '#1bcf3d'
                                : wrongIndex == i
                                ? '#ff4d3a'
                                : theme.palette.purple.main,
                          }}
                        >
                          {bingo_card[i].spell}
                        </AutoText>
                      </StyledSelectionFront>
                      <StyledSelectionBack>
                        <Box component={'img'} src={logo} sx={{ width: '60%' }}></Box>
                      </StyledSelectionBack>
                    </StyledFlipOverSelectionBox>,
                  );
                }
                return arr;
              })()
            : null}
        </StyledSelectionBox>
      </StyledSelectionWrap>
      <StyledWordbingoBottom>
        {timeout > 0 ? <StyledLinearProgress key={current_step} variant={'determinate'} value={progress} /> : null}
      </StyledWordbingoBottom>
      <ModalEndModule
        visible={endModuleModal.visible}
        label={endModuleModal.label}
        kor_label={endModuleModal.kor_label}
        icon={endModuleModal.icon}
        score={endModuleModal.score}
        nextCallback={nextCallback}
      />
    </StyledWordbingoWrap>
  );
}

export default Wordbingo;
