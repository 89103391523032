import { TutorialContentUI } from './TutorialContent.Presenter';

interface TutorialContentContainerProps {
  currentKey: string;
}

export function TutorialContentContainer({ currentKey }: TutorialContentContainerProps) {
  const props = {
    currentKey,
  };

  return <TutorialContentUI {...props} />;
}
