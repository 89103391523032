import {
  SignupContainer,
  SignupWrapper,
  SignupTitle,
  CertButton,
  PhoneInput,
  QuestionRow,
  SignupExplainRow,
  SignupFirstRow,
  SignupForm,
  SignupLabelRow,
  ErrorText,
  SignupFirstRow2,
  SignupInfoBox,
  SignupExplainRow2,
  LoginButton,
  Input,
  CheckboRow,
  Checkbox,
  SignupButton,
  Errormsg,
  BackButtonBox,
  IconButton,
} from './Signup.Styles';
import { RiQuestionLine } from 'react-icons/ri';
import { AiOutlineLeft } from 'react-icons/ai';
import { ModalTab } from 'components/modal/ModalTab';
import { ModalManual2 } from 'components/modal/ModalManual2';
import { autoHypenPhone } from 'utils/tools';

export interface SignupVAProps {
  step: number;
  existMember: boolean;
  firstStepDone: boolean;
  parentName: string;
  phoneNumber: string;
  isChecked1: boolean;
  isChecked2: boolean;
  postdate: string;
  password: string;
  errMsgPassword: string;
  onClickUnderbar: (tab: number) => void;
  onClickQuestion: () => void;
  onClickCheckbox: (idx: number) => void;
  onClickKCP: () => void;
  // handleNext: () => void;
  onClickToLoginPage: () => void;
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitToSignup: () => void;
  onClickBackButton: () => void;
  // mobile 관련 처리
  top: string;
  is_mobile: boolean;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  // iframe src
  ifrmae_url: string;
}

export function SignupUI({
  onClickKCP,
  step,
  existMember,
  firstStepDone,
  parentName,
  phoneNumber,
  isChecked1,
  isChecked2,
  postdate,
  password,
  errMsgPassword,
  onClickCheckbox,
  onClickUnderbar,
  onClickQuestion,
  // handleNext,
  onClickToLoginPage,
  onChangePassword,
  handleSubmitToSignup,
  onClickBackButton,
  top,
  onFocus,
  onBlur,
  ifrmae_url,
  is_mobile,
}: SignupVAProps) {
  return (
    <>
      <iframe
        width='100%'
        height='100%'
        scrolling='auto'
        style={{
          display: 'none',
          position: 'fixed',
          backgroundColor: '#fff',
          left: 0,
          top: 0,
          zIndex: 9999,
        }}
        src={ifrmae_url}
        id='kcpcert_student'
        name='kcpcert_student'
      />
      <SignupContainer sx={{ maxHeight: '100vh' }}>
        <SignupWrapper sx={{ marginTop: `${top}` }}>
          <SignupTitle>회원가입</SignupTitle>
          <br />
          <SignupForm>
            {step == 1 ? (
              <>
                {/* step 1 */}
                <SignupFirstRow>
                  <div>
                    <h4>가입까지 딱 10초!</h4>
                    <div>
                      <h3 style={{ marginRight: '0.05rem' }}>1</h3>
                      <span>/</span>
                      <h3 style={{ marginLeft: '0.05rem' }}>2</h3>
                    </div>
                  </div>
                  <div>
                    <div></div>
                  </div>
                </SignupFirstRow>
                <br />
                <SignupLabelRow>휴대폰 번호를 인증해주세요.</SignupLabelRow>
                <SignupExplainRow>
                  학부모님 번호 입력 <span>(자녀 학습 알림톡이 실시간 발송되요!)</span>
                </SignupExplainRow>
                <PhoneInput
                  sx={{ '&::placeholder': { fontSize: '0.925rem', color: 'rgb(156, 163, 175)', fontWeight: '300' } }}
                  placeholder='인증 버튼을 클릭하세요.'
                  type='text'
                  disabled
                  value={''}
                />
                <ErrorText></ErrorText>
                <QuestionRow onClick={onClickQuestion}>
                  <RiQuestionLine />
                  <span>인증에 어려움이 있으시다면?</span>
                </QuestionRow>
                <br />
                {firstStepDone ? (
                  <CertButton sx={{ backgroundColor: '#551cff' }}>다음</CertButton>
                ) : (
                  <CertButton onClick={onClickKCP}>인증</CertButton>
                )}
              </>
            ) : existMember ? (
              <>
                {/* step 2 (기존 가입자) */}
                <SignupFirstRow2>
                  <div>
                    <div>
                      <h2 style={{ fontSize: '0.975rem' }}>이미 오래영어의 회원입니다!</h2>
                      <div>
                        <h3 style={{ marginRight: '0.05rem' }}>2</h3>
                        <span>/</span>
                        <h3 style={{ marginLeft: '0.05rem' }}>2</h3>
                      </div>
                    </div>
                    <div>가입 정보를 확인하시고 로그인해주세요.</div>
                  </div>
                </SignupFirstRow2>
                <SignupInfoBox>
                  <h4>{parentName} 회원님</h4>
                </SignupInfoBox>
                <SignupInfoBox sx={{ marginTop: '0.75rem', marginBottom: '0.7rem', color: '#9ca3af' }}>
                  <p>가입일 {postdate}</p>
                </SignupInfoBox>
                <SignupExplainRow2>
                  <RiQuestionLine />
                  <span>회원님의 학습 담당자</span>
                </SignupExplainRow2>
                <SignupExplainRow2>
                  <RiQuestionLine color='white' />
                  <span>정지훈 팀장 (010-9321-4810)</span>
                </SignupExplainRow2>
                <br />
                <LoginButton onClick={onClickToLoginPage}>로그인</LoginButton>
              </>
            ) : (
              <>
                {/* step2 (신규 가입자) */}
                <SignupFirstRow2>
                  <div>
                    <div>
                      <h2>오래영어가 처음이시네요!</h2>
                      <div>
                        <h3 style={{ marginRight: '0.05rem' }}>2</h3>
                        <span>/</span>
                        <h3 style={{ marginLeft: '0.05rem' }}>2</h3>
                      </div>
                    </div>
                    <div>회원가입을 위해 다음 항목을 기입해주세요.</div>
                  </div>
                </SignupFirstRow2>
                <Input
                  sx={{ marginBottom: '0.7rem', backgroundColor: '#e4e4e4', color: 'rgb(75, 85, 99)' }}
                  disabled
                  placeholder='학부모님 성함'
                  value={parentName}
                />
                <PhoneInput
                  sx={{
                    marginBottom: '0.7rem',
                    marginTop: '0',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    padding: '0.7rem',
                  }}
                  disabled
                  value={autoHypenPhone(phoneNumber)}
                />
                <Input
                  sx={{ marginBottom: '0.7rem', color: '#000' }}
                  type='password'
                  placeholder='비밀번호'
                  value={password}
                  onChange={onChangePassword}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
                {errMsgPassword ? <Errormsg>{errMsgPassword}</Errormsg> : null}
                <CheckboRow sx={{ marginTop: '0.5rem' }}>
                  <Checkbox checked={isChecked1} onClick={() => onClickCheckbox(1)} />
                  <h3>
                    <span onClick={() => onClickUnderbar(2)}>이용약관</span>에 동의합니다.
                  </h3>
                </CheckboRow>
                <CheckboRow>
                  <Checkbox checked={isChecked2} onClick={() => onClickCheckbox(2)} />
                  <h3>
                    <span onClick={() => onClickUnderbar(1)}>개인정보 취급방침</span>에 동의합니다.
                  </h3>
                </CheckboRow>
                <br />
                <SignupButton
                  sx={
                    password.trim() === '' || parentName.trim() === '' || !isChecked1 || !isChecked2
                      ? { backgroundColor: 'rgb(229, 231, 235)', cursor: 'not-allowed' }
                      : { backgroundColor: 'rgb(83, 21, 255)', cursor: 'pointer' }
                  }
                  disabled={password.trim() === '' || parentName.trim() === '' || !isChecked1 || !isChecked2}
                  onClick={handleSubmitToSignup}
                >
                  회원가입
                </SignupButton>
              </>
            )}
          </SignupForm>
          <BackButtonBox is_mobile={is_mobile ? 1 : 0} onClick={onClickBackButton}>
            <IconButton disableRipple>
              <AiOutlineLeft />
            </IconButton>
          </BackButtonBox>
        </SignupWrapper>
      </SignupContainer>
      <ModalTab />
      <ModalManual2 />
    </>
  );
}
