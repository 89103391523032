import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import theme from '../../../theme';

interface DonutChartProps {
  score: number;
}

const DonutChart = (props: DonutChartProps) => {
  const { score } = props;
  const data = [{ score }, { score: 100 - score }];
  const colors = [theme.palette.purple_3.main, theme.palette.grey[300]];

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart>
        <Pie
          data={data}
          dataKey='score'
          cx='50%'
          cy='50%'
          startAngle={90}
          endAngle={-270}
          innerRadius='72%'
          outerRadius='84%'
          paddingAngle={0}
          isAnimationActive={false}
          strokeWidth={0}
        >
          <Cell key='cell-0' fill={colors[0]} />
          <Cell key='cell-1' fill='transparent' />
        </Pie>
        <Pie
          data={data}
          dataKey='score'
          cx='50%'
          cy='50%'
          startAngle={90}
          endAngle={-270}
          innerRadius='76.5%'
          outerRadius='81%'
          paddingAngle={0}
          isAnimationActive={false}
          strokeWidth={0}
        >
          <Cell key='cell-0' fill='transparent' />
          <Cell key='cell-1' fill={colors[1]} />
        </Pie>
        <text x='50%' y='41.5%' textAnchor='middle' dominantBaseline='middle' fontSize={'2vh'} fontWeight={600}>
          종합 점수
        </text>
        <text
          fill={theme.palette.purple_3.main}
          x='50%'
          y='59%'
          textAnchor='middle'
          dominantBaseline='middle'
          fontSize={'4.5vh'}
          fontWeight={600}
        >
          {score}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
