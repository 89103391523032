import { MODULES } from './constants';

export function setModuleCategory(learning_type: learningTypeIndex | null, module: string, tag?: string | null) {
  const category_arr: { id: string; label: string }[] = [];
  if (learning_type == null) {
    category_arr.push(setting_category['acidrain_sound']);
    category_arr.push(setting_category['acidrain_difficult']);
    category_arr.push(setting_category['acidrain_background']);
    return category_arr;
  }
  if (learning_type == 1) {
    category_arr.push(setting_category['memorize_hot_stage']);
    category_arr.push(setting_category['memorize_voice']);
    category_arr.push(setting_category['memorize_book']);
    return category_arr;
  } else if (learning_type == 2) {
    if (tag && tag == 'new') {
      //  tag가 new인 경우
      category_arr.push(setting_category['memorize_new_stage']);
      category_arr.push(setting_category['memorize_voice']);
      category_arr.push(setting_category['memorize_book']);
    } else {
      // tag가 normal인 경우
      category_arr.push(setting_category['memorize_normal_stage']);
      category_arr.push(setting_category['memorize_voice']);
      category_arr.push(setting_category['memorize_book']);
    }
    return category_arr;
  } else if (learning_type > 2) {
    for (let i = 0; i < module.length; i++) {
      const idx = Number(module[i]) as ModuleIndex;
      category_arr.push(setting_category[MODULES[idx]]);
    }
    return category_arr;
  } else {
    return category_arr;
  }
}

export function setManualCategory(is_mobile: boolean, platform: string) {
  const category_arr: { id: string; label: string }[] = [];
  if (!is_mobile) {
    category_arr.push(manual_datas['browser']);
    category_arr.push(manual_datas['control']);
    // category_arr.push(manual_datas['kakao']);
  } else {
    category_arr.push(manual_datas['android']);
    category_arr.push(manual_datas['ios']);
  }

  return category_arr;
}

export function setModuleSetting(
  learning_type: learningTypeIndex | null,
  module: string,
  module_info: ModuleSettings,
  platform?: string,
  tag?: string | null,
) {
  if (!module_info) return [];

  const setting_data = [];

  if (learning_type == 1) {
    const tmp_arr = ['memorize_hot_stage', 'memorize_voice', 'memorize_book'];
    setting_data.push(setting_datas['memorize_hot_stage']);
    setting_data.push(setting_datas['memorize_voice']);
    setting_data.push(setting_datas['memorize_book']);
    for (let i = 0; i < setting_data.length; i++) {
      for (let j = 0; j < setting_data[i].length; j++) {
        const item = setting_data[i][j];
        if (item.checked !== undefined && module_info[`${tmp_arr[i]}`][`${item.id}`] === 1) item.checked = true;
        else item.checked = false;
      }
    }
    return setting_data;
  } else if (learning_type == 2) {
    if (tag && tag == 'new') {
      // tag가 new 인 경우
      const tmp_arr = ['memorize_new_stage', 'memorize_voice', 'memorize_book'];
      setting_data.push(setting_datas['memorize_new_stage']);
      setting_data.push(setting_datas['memorize_voice']);
      setting_data.push(setting_datas['memorize_book']);
      for (let i = 0; i < setting_data.length; i++) {
        for (let j = 0; j < setting_data[i].length; j++) {
          const item = setting_data[i][j];
          if (item.checked !== undefined && module_info[`${tmp_arr[i]}`][`${item.id}`] === 1) item.checked = true;
          else item.checked = false;
        }
      }
    } else {
      // tag가 normal인 경우
      const tmp_arr = ['memorize_normal_stage', 'memorize_voice', 'memorize_book'];
      setting_data.push(setting_datas['memorize_normal_stage']);
      setting_data.push(setting_datas['memorize_voice']);
      setting_data.push(setting_datas['memorize_book']);
      for (let i = 0; i < setting_data.length; i++) {
        for (let j = 0; j < setting_data[i].length; j++) {
          const item = setting_data[i][j];
          if (item.checked !== undefined && module_info[`${tmp_arr[i]}`][`${item.id}`] === 1) item.checked = true;
          else item.checked = false;
        }
      }
    }
    return setting_data;
  }

  if (learning_type == 6) return [];

  for (let i = 0; i < module.length; i++) {
    const idx = Number(module[i]) as ModuleIndex;

    let items = setting_datas[`${MODULES[idx]}`];
    if (learning_type == 4) {
      items = items.filter(item => {
        if (MODULES[idx] === 'speaking' && item.id == 'example_type') {
          if (tag) {
            return true;
          } else {
            return false;
          }
        } else if (MODULES[idx] === 'speaking' && item.id == 'type') {
          if (tag) {
            return false;
          } else {
            return true;
          }
        } else {
          if (platform == 'android' && item.id == 'timer') {
            // 안드로이드는 녹음 유형 X
            return false;
          }

          return true;
        }
      });
    }

    for (let j = 0; j < items.length; j++) {
      const item = items[j];
      if (item.checked !== undefined && module_info[`${MODULES[idx]}`][`${item.id}`] === 1) item.checked = true;
      else item.checked = false;
      if (MODULES[idx] === 'speaking' && item.id === 'type') {
        const sub_title_arr = [
          '단어를 듣고 따라 읽으며 발음 훈련을 합니다.',
          '뜻을 보고 영어로 바꿔 말하며 발음 훈련을 합니다.',
          '따라 읽거나, 바꿔 말하며 발음 훈련을 합니다.',
        ];
        item.sub_title = sub_title_arr[module_info['speaking']['type']];

        if (platform == 'android') item.height = '50%';
      } else if (MODULES[idx] === 'speaking' && item.id === 'example_type') {
        const sub_title_arr = [
          '예문을 보고 따라 읽으며 문장 발음 훈련을 합니다.',
          '단어를 듣고 따라 읽으며 발음 훈련을 합니다.',
          '예문과 단어를 보고 발음 훈련을 합니다.',
        ];
        item.sub_title = sub_title_arr[module_info['speaking']['example_type']];
        if (platform == 'android') item.height = '50%';
      } else if (MODULES[idx] === 'speaking' && item.id === 'timer') {
        const sub_title_arr = [
          '3초 카운트 다운 이후 녹음이 활성화 됩니다.',
          '카운트 다운 대기 없이 즉시 녹음이 활성화 됩니다.',
        ];
        item.sub_title = sub_title_arr[module_info['speaking']['timer']];
      } else if (MODULES[idx] === 'speaking' && item.id === 'retry') {
        if (platform == 'android') item.height = '50%';
      }
    }
    setting_data.push(items);
  }
  return setting_data;
}

export const setting_category: SettingCategoryType = {
  acidrain_sound: {
    id: 'acidrain_sound',
    label: '소리',
  },

  acidrain_difficult: {
    id: 'acidrain_difficult',
    label: '난이도',
  },

  acidrain_background: {
    id: 'acidrain_background',
    label: '배경',
  },

  memorize_hot_stage: {
    id: 'memorize_hot_stage',
    label: '단계',
  },

  memorize_new_stage: {
    id: 'memorize_new_stage',
    label: '단계',
  },

  memorize_normal_stage: {
    id: 'memorize_normal_stage',
    label: '단계',
  },

  memorize_voice: {
    id: 'memorize_voice',
    label: '음성',
  },

  memorize_book: {
    id: 'memorize_book',
    label: '교재',
  },

  flash: {
    id: 'flash',
    label: 'FLASH CARD',
  },

  bingo: {
    id: 'bingo',
    label: 'WORD BINGO',
  },

  popquiz: {
    id: 'popquiz',
    label: 'POP QUIZ',
  },

  dictation: {
    id: 'dictation',
    label: 'DICTATION',
  },

  writing: {
    id: 'writing',
    label: 'WRITING',
  },

  speaking: {
    id: 'speaking',
    label: 'SPEAKING',
  },
};

export const options: { [key: string]: { [tab: string]: ModalSettingsOptions[] } } = {
  acidrain_sound: {
    effect: [
      { title: 'OFF', value: '0' },
      { title: 'ON', value: '1' },
    ],
    repeat: [
      { title: 'OFF', value: '0' },
      { title: 'ON', value: '1' },
    ],
  },
  acidrain_difficult: {
    spelling: [{ value: '0' }, { value: '1' }],
    mean: [{ value: '0' }, { value: '1' }],
    image: [{ value: '0' }, { value: '1' }],
  },
  acidrain_background: {
    default: [{ value: '0' }, { value: '1' }],
    manual: [{ value: '0' }, { value: '1' }],
    src: [{ value: '0' }, { value: '1' }],
  },
  memorize_hot_stage: {
    example: [{ value: '0' }, { value: '1' }],
    lecture: [{ value: '0' }, { value: '1' }],
    spell: [{ value: '0' }, { value: '1' }],
    image: [{ value: '0' }, { value: '1' }],
    dictionary: [{ value: '0' }, { value: '1' }],
  },
  memorize_new_stage: {
    example: [{ value: '0' }, { value: '1' }],
    spell: [{ value: '0' }, { value: '1' }],
    mean: [{ value: '0' }, { value: '1' }],
    image: [{ value: '0' }, { value: '1' }],
    dictionary: [{ value: '0' }, { value: '1' }],
  },
  memorize_normal_stage: {
    spell: [{ value: '0' }, { value: '1' }],
    mean: [{ value: '0' }, { value: '1' }],
    image: [{ value: '0' }, { value: '1' }],
    dictionary: [{ value: '0' }, { value: '1' }],
  },
  memorize_voice: {
    count: [
      { title: '1회', value: '1' },
      { title: '2회', value: '2' },
      { title: '3회', value: '3' },
      { title: '4회', value: '4' },
      { title: '5회', value: '5' },
    ],
    speed: [
      { title: '0.5', sub_title: '배속', value: '0.5' },
      { title: '0.7', sub_title: '배속', value: '0.7' },
      { title: '1.0', sub_title: '배속', value: '1' },
      { title: '1.2', sub_title: '배속', value: '1.2' },
      { title: '1.5', sub_title: '배속', value: '1.5' },
    ],
    flash: [
      { title: '1', sub_title: '매우느림', value: '1' },
      { title: '2', sub_title: '느림', value: '2' },
      { title: '3', sub_title: '보통', value: '3' },
      { title: '4', sub_title: '빠름', value: '4' },
      { title: '5', sub_title: '매우빠름', value: '5' },
    ],
  },
  memorize_book: {
    auto: [
      { title: 'OFF', value: '0' },
      { title: 'ON', value: '1' },
    ],
    order: [
      { title: '원본', value: '0' },
      { title: '랜덤', value: '1' },
    ],
  },
  flash: {
    count: [
      { title: '1회', value: '1' },
      { title: '2회', value: '2' },
      { title: '3회', value: '3' },
      { title: '4회', value: '4' },
      { title: '5회', value: '5' },
    ],
    speed: [
      { title: '0.5', sub_title: '배속', value: '0.5' },
      { title: '0.7', sub_title: '배속', value: '0.7' },
      { title: '1.0', sub_title: '배속', value: '1' },
      { title: '1.2', sub_title: '배속', value: '1.2' },
      { title: '1.5', sub_title: '배속', value: '1.5' },
    ],
    flash: [
      { title: '1', sub_title: '매우느림', value: '1' },
      { title: '2', sub_title: '느림', value: '2' },
      { title: '3', sub_title: '보통', value: '3' },
      { title: '4', sub_title: '빠름', value: '4' },
      { title: '5', sub_title: '매우빠름', value: '5' },
    ],
  },
  bingo: {
    time: [
      { title: 'OFF', value: '0' },
      { title: '3초', value: '3' },
      { title: '5초', value: '5' },
      { title: '7초', value: '7' },
      { title: '10초', value: '10' },
    ],
    retry: [
      { title: 'OFF', value: '0' },
      { title: '1회', value: '1' },
      { title: '2회', value: '2' },
    ],
  },
  popquiz: {
    time: [
      { title: 'OFF', value: '0' },
      { title: '3초', value: '3' },
      { title: '5초', value: '5' },
      { title: '7초', value: '7' },
      { title: '10초', value: '10' },
    ],
    retry: [
      { title: 'OFF', value: '0' },
      { title: '1회', value: '1' },
      { title: '2회', value: '2' },
    ],
  },
  dictation: {
    count: [
      { title: '1회', value: '1' },
      { title: '2회', value: '2' },
      { title: '3회', value: '3' },
      { title: '5회', value: '5' },
      { title: '무제한', value: '0' },
    ],
    hint: [
      { title: '기본', value: '0' },
      { title: '심화', value: '1' },
    ],
  },
  writing: {
    hint: [
      { title: '없음', value: '0' },
      { title: '20%', value: '1' },
      { title: '50%', value: '2' },
    ],
  },
  speaking: {
    example_type: [
      { title: '예문', value: '0' },
      { title: '단어', value: '1' },
      { title: '랜덤', value: '2' },
    ],
    type: [
      { title: '단어', value: '0' },
      { title: '뜻', value: '1' },
      { title: '랜덤', value: '2' },
    ],
    timer: [
      { title: '대기형', value: '0' },
      { title: '즉시', value: '1' },
    ],
    retry: [
      { title: '1회', value: '1' },
      { title: '2회', value: '2' },
    ],
  },
  video: {
    speed: [
      { title: '0.7', value: '0.7' },
      { title: '1.0', value: '1.0' },
      { title: '1.2', value: '1.2' },
      { title: '1.5', value: '1.5' },
      { title: '2.0', value: '2.0' },
    ],
    repeat: [
      { title: '1회', value: '1' },
      { title: '2회', value: '2' },
      { title: '3회', value: '3' },
      { title: '5회', value: '5' },
      { title: '7회', value: '7' },
    ],
  },
};

export const manual_datas: SettingCategoryType = {
  browser: {
    id: 'browser',
    label: '브라우져 점검',
  },
  android: {
    id: 'android',
    label: '안드로이드 기기 점검',
  },
  ios: {
    id: 'ios',
    label: 'IOS 기기 점검',
  },
  control: {
    id: 'control',
    label: '제어판 설정',
  },
  kakao: {
    id: 'kakao',
    label: '카카오톡 채널 접수',
  },
};

export const setting_datas: { [key: string]: ModalSettingsDatas[] } = {
  acidrain_sound: [
    {
      id: 'effect',
      title: '효과음',
      sub_title: '체크업 학습의 배경 음악 및 효과음을 제어합니다.',
      type: 'updown',
      height: '50%',
      options: options['acidrain_sound']['effect'],
    },
    {
      id: 'repeat',
      title: '음성 다시듣기',
      sub_title: '정답 입력 후, 단어 음성 다시듣기를 제어합니다.',
      type: 'updown',
      height: '50%',
      options: options['acidrain_sound']['repeat'],
    },
  ],
  acidrain_difficult: [
    {
      id: 'spelling',
      title: '철자',
      sub_title: '철자를 보고, 그대로 적습니다. 영문 타자 연습용으로 좋아요!',
      type: 'radio',
      height: '33.3%',
      checked: false,
      options: options['acidrain_difficult']['spelling'],
    },
    {
      id: 'mean',
      title: '의미',
      sub_title: '의미를 보고, 알맞은 영어 단어를 적어보세요.',
      type: 'radio',
      height: '33.4%',
      checked: false,
      options: options['acidrain_difficult']['mean'],
    },
    {
      id: 'image',
      title: '이미지',
      sub_title: '이미지를 보고, 알맞은 영어 단어를 적어보세요.',
      type: 'radio',
      height: '33.3%',
      checked: false,
      options: options['acidrain_difficult']['image'],
    },
  ],
  acidrain_background: [
    {
      id: 'default',
      title: '기본',
      sub_title: '오전 오후 시간대 / 계절에 맞게 추천 배경화면을 사용합니다.',
      type: 'radio',
      height: '50%',
      checked: false,
      options: options['acidrain_background']['default'],
    },
    {
      id: 'manual',
      title: '수동 선택',
      sub_title: '제공되는 배경화면을 고정 선택하여 사용합니다.',
      type: 'radio',
      height: 'auto',
      checked: false,
      options: options['acidrain_background']['manual'],
    },
  ],
  memorize_hot_stage: [
    {
      id: 'example',
      title: '예문 <span class="c_red">(필수)</span>',
      sub_title: '단어가 활용된 예문 문제를 풀어봅니다. <span class="c_purple_2">(실전 대비)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_hot_stage']['example'],
    },
    {
      id: 'lecture',
      title: '강의',
      sub_title: '해당 단어에 맞는 선생님 강의를 듣습니다. <span class="c_purple_2">(수업)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_hot_stage']['lecture'],
    },
    {
      id: 'spell',
      title: '철자',
      sub_title: '단어의 영어 철자를 표기합니다. <span class="c_purple_2">(듣기)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_hot_stage']['spell'],
    },
    {
      id: 'image',
      title: '이미지',
      sub_title: '단어의 연상 이미지를 표기합니다. <span class="c_purple_2">(연상학습)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_hot_stage']['image'],
    },
    {
      id: 'dictionary',
      title: '사전',
      sub_title: '단어의 사전적 정의, 개념을 표기합니다. <span class="c_purple_2">(학습 참고)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_hot_stage']['dictionary'],
    },
  ],
  memorize_new_stage: [
    {
      id: 'example',
      title: '예문 <span class="c_red">(필수)</span>',
      sub_title: '단어가 활용된 예문 문제를 풀어봅니다. <span class="c_purple_2">(실전 대비)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_new_stage']['example'],
    },
    {
      id: 'spell',
      title: '철자',
      sub_title: '단어의 영어 철자와 예문을 표기합니다. <span class="c_purple_2">(듣기)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_new_stage']['spell'],
    },
    {
      id: 'mean',
      title: '의미',
      sub_title: '단어의 한글 의미를 표기합니다. <span class="c_purple_2">(영작)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_new_stage']['mean'],
    },
    {
      id: 'image',
      title: '이미지',
      sub_title: '단어의 연상 이미지를 표기합니다. <span class="c_purple_2">(연상학습)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_new_stage']['image'],
    },
    {
      id: 'dictionary',
      title: '사전',
      sub_title: '단어의 사전적 정의, 개념을 표기합니다. <span class="c_purple_2">(학습 참고)</span>',
      type: 'checkbox',
      height: '20%',
      checked: false,
      options: options['memorize_new_stage']['dictionary'],
    },
  ],
  memorize_normal_stage: [
    {
      id: 'spell',
      title: '철자 <span class="c_red">(필수)</span>',
      sub_title: '단어의 영어 철자와 예문을 표기합니다. <span class="c_purple_2">(듣기)</span>',
      type: 'checkbox',
      height: '25%',
      checked: false,
      options: options['memorize_normal_stage']['spell'],
    },
    {
      id: 'mean',
      title: '의미',
      sub_title: '단어의 한글 의미를 표기합니다. <span class="c_purple_2">(영작)</span>',
      type: 'checkbox',
      height: '25%',
      checked: false,
      options: options['memorize_normal_stage']['mean'],
    },
    {
      id: 'image',
      title: '이미지',
      sub_title: '단어의 연상 이미지를 표기합니다. <span class="c_purple_2">(연상학습)</span>',
      type: 'checkbox',
      height: '25%',
      checked: false,
      options: options['memorize_normal_stage']['image'],
    },
    {
      id: 'dictionary',
      title: '사전',
      sub_title: '단어의 사전적 정의, 개념을 표기합니다. <span class="c_purple_2">(학습 참고)</span>',
      type: 'checkbox',
      height: '25%',
      checked: false,
      options: options['memorize_normal_stage']['dictionary'],
    },
  ],
  memorize_voice: [
    {
      id: 'count',
      title: '재생 횟수',
      sub_title: '단어별 음성 재생 횟수를 조정합니다.',
      type: 'plusminus',
      height: '33.3%',
      options: options['memorize_voice']['count'],
    },
    {
      id: 'speed',
      title: '재생 속도',
      sub_title: '단어별 음성 재생 속도를 조정합니다.',
      type: 'plusminus',
      height: '33.4%',
      options: options['memorize_voice']['speed'],
    },
    {
      id: 'flash',
      title: '플래시 속도',
      sub_title: '플래시 카드의 깜빡임 속도를 조정합니다.',
      type: 'plusminus',
      height: '33.3%',
      options: options['memorize_voice']['flash'],
    },
  ],
  memorize_book: [
    {
      id: 'auto',
      title: '자동 학습',
      sub_title: '학습 단계가 종료되면, 슬라이드 되어 자동으로 다음 학습이 진행됩니다.',
      type: 'updown',
      height: '50%',
      options: options['memorize_book']['auto'],
    },
    {
      id: 'order',
      title: '교재 순서',
      sub_title: '교재 컨텐츠 학습 순서를 자유롭게 조정할 수 있습니다.',
      type: 'updown',
      height: '50%',
      options: options['memorize_book']['order'],
    },
  ],
  flash: [
    {
      id: 'count',
      title: '재생 횟수',
      sub_title: '단어별 음성 재생 횟수를 조정합니다.',
      type: 'plusminus',
      height: '33.3%',
      options: options['flash']['count'],
    },
    {
      id: 'speed',
      title: '재생 속도',
      sub_title: '단어별 음성 재생 속도를 조정합니다.',
      type: 'plusminus',
      height: '33.4%',
      options: options['flash']['speed'],
    },
    {
      id: 'flash',
      title: '플래시 속도',
      sub_title: '플래시 카드의 깜빡임 속도를 조정합니다.',
      type: 'plusminus',
      height: '33.3%',
      options: options['flash']['flash'],
    },
  ],
  bingo: [
    {
      id: 'time',
      title: '시간 제한',
      sub_title: '문제별 시간 제한을 설정하여 학습할 수 있습니다.',
      type: 'updown',
      height: '50%',
      options: options['bingo']['time'],
    },
    {
      id: 'retry',
      title: '재시도 횟수',
      sub_title: '문항 오답 시, 추가 재시도 횟수를 늘릴수 있습니다.',
      type: 'updown',
      height: '50%',
      options: options['bingo']['retry'],
    },
  ],
  popquiz: [
    {
      id: 'time',
      title: '시간 제한',
      sub_title: '문제별 시간 제한을 설정하여 학습할 수 있습니다.',
      type: 'updown',
      height: '50%',
      options: options['popquiz']['time'],
    },
    {
      id: 'retry',
      title: '재시도 횟수',
      sub_title: '문항 오답 시, 추가 재시도 횟수를 늘릴수 있습니다.',
      type: 'updown',
      height: '50%',
      options: options['popquiz']['retry'],
    },
  ],
  dictation: [
    {
      id: 'count',
      title: '재생 횟수',
      sub_title: '단어별 음성 재생 횟수를 조정합니다.',
      type: 'updown',
      height: '50%',
      options: options['dictation']['count'],
    },
    {
      id: 'hint',
      title: '보기 범위',
      sub_title: '표기되는 보기의 난이도 범위를 조정합니다.',
      type: 'updown',
      height: '50%',
      options: options['dictation']['hint'],
    },
  ],
  writing: [
    {
      id: 'hint',
      title: '힌트 범위',
      sub_title: '표기되는 힌트의 난이도 범위를 조정합니다.',
      type: 'updown',
      height: '100%',
      options: options['writing']['hint'],
    },
  ],
  speaking: [
    {
      id: 'example_type',
      title: '문제 유형',
      sub_title: '예문을 보고 따라 읽으며 문장 발음 훈련을 합니다.',
      type: 'updown',
      height: '33.3%',
      options: options['speaking']['example_type'],
    },
    {
      id: 'type',
      title: '문제 유형',
      sub_title: '단어를 듣고 따라 읽으며 발음 훈련을 합니다.',
      type: 'updown',
      height: '33.3%',
      options: options['speaking']['type'],
    },
    {
      id: 'timer',
      title: '녹음 유형',
      sub_title: '3초 카운트 다운 이후 녹음이 활성화 됩니다.',
      type: 'updown',
      height: '33.4%',
      options: options['speaking']['timer'],
    },
    {
      id: 'retry',
      title: '재녹음 시도',
      sub_title: '1차 녹음 후 재녹음 여부를 선택합니다.',
      type: 'updown',
      height: '33.3%',
      options: options['speaking']['retry'],
    },
  ],
};
