import { Routes, Route, useLocation } from 'react-router';
import { Suspense, lazy, useState, useEffect } from 'react';
import { styled, Box } from '@mui/material';

const Notice = lazy(() => import('./Notice'));
const Notification = lazy(() => import('./Notification'));
const Manual = lazy(() => import('./Manual'));
const Qna = lazy(() => import('./Qna'));
const Settings = lazy(() => import('./Settings'));

function ScheduleIndex() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/' element={<Notice />} />
        <Route path='/notice' element={<Notice />} />
        <Route path='/notification' element={<Notification />} />
        {/* <Route path='/manual' element={<Manual />} /> */}
        <Route path='/qna' element={<Qna />} />
        <Route path='/settings' element={<Settings />} />
      </Routes>
    </Suspense>
  );
}

export default ScheduleIndex;
