import { SetterOrUpdater, atom } from 'recoil';

export const userState = atom<UserType>({
  key: 'userState',
  default: {
    customer_id: 0,
    center_name: '',
    id: 0,
    logo: '',
    name: '',
    profile: '',
    profile_type: 'default',
    product: '',
    type: 11,
    class: [],
    pass: null,
    postdate: '',
    show_tutorial: {
      advanced: false,
      memorize: false,
      basic: false,
      speak: false,
    },
    default_profile: '',
  },
});

interface UserStateSetter {
  setUserStateData: SetterOrUpdater<UserType>;
}

export const resetUserState = ({ setUserStateData }: UserStateSetter) => {
  setUserStateData({
    customer_id: 0,
    center_name: '',
    id: 0,
    logo: '',
    name: '',
    profile: '',
    profile_type: 'default',
    product: '',
    type: 11,
    class: [],
    pass: false,
    postdate: '',
    show_tutorial: {
      memorize: false,
      basic: false,
      advanced: false,
      speak: false,
    },
    default_profile: '',
  });
};
