import React, { useState, useEffect, useRef, useContext } from 'react';
import { IconButton } from '@mui/material';
import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa';
import { styled } from '@mui/system';
import { fetchGetAudioApi } from '../../../utils/api';
import { BackgroundSoundContext } from 'provider/BackgroundSoundProvider';

interface CircleAudioPlayerProps {
  audio_url: string;
  visibleWordDetail?: boolean;
  idx?: number;
}

const StyledIconButton = styled(IconButton)(props => ({
  position: 'relative',
  '& > svg': {
    fill: !props.disabled ? props.theme.palette.purple.main : props.theme.palette.gray_2.main,
    stroke: props.theme.palette.white.main,
    width: '1.8rem',
    height: '1.8rem',
  },
}));

const CircleAudioPlayer = (props: CircleAudioPlayerProps) => {
  const { muteBGM, upVolumeBGM } = useContext(BackgroundSoundContext);
  const visibleWordDetail = props.visibleWordDetail;
  const audio_url = props.audio_url;
  const idx = props.idx;
  const [playing, setPlaying] = useState(false);
  const [validAudio, setValidAudio] = useState(false);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    return () => {
      if (!audioRef.current.paused) audioRef.current.pause();
      audioRef.current.onloadedmetadata = null;
      audioRef.current.onended = null;
      upVolumeBGM();
    };
  }, []);

  useEffect(() => {
    if (!audio_url) return;
    const fetchAudio = async () => {
      try {
        const audioBlob = await fetchGetAudioApi(audio_url);
        if (audioBlob) {
          const audioSrc = URL.createObjectURL(audioBlob);
          if (audioRef.current) {
            audioRef.current.src = audioSrc;
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAudio();

    const audio = audioRef.current;

    audio.onloadedmetadata = () => {
      setValidAudio(true);
    };

    audio.onended = () => {
      setPlaying(false);
      upVolumeBGM();
    };
  }, [audio_url]);

  useEffect(() => {
    const audio = audioRef.current;

    if (playing) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [playing]);

  useEffect(() => {
    if (visibleWordDetail) {
      setPlaying(false);
    }
  }, [visibleWordDetail]);

  const handlePlayPauseClick = () => {
    if (!playing && typeof idx == 'number') {
      const buttons = document.getElementsByClassName('audio-play-button');
      for (let i = 0; i < buttons.length; i++) {
        const button_el = buttons[i] as HTMLButtonElement;
        const className = button_el.className;
        if (className.includes('playing')) {
          button_el.click();
        }
      }
      muteBGM();
    } else {
      upVolumeBGM();
    }
    setPlaying(!playing);
  };

  return (
    <StyledIconButton
      disableRipple
      onClick={handlePlayPauseClick}
      disabled={!validAudio}
      className={`audio-play-button ${playing ? 'playing' : ''}`}
    >
      {playing ? <FaPauseCircle /> : <FaPlayCircle />}
    </StyledIconButton>
  );
};

export default CircleAudioPlayer;
