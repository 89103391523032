import {
  Wrapper,
  CountBox,
  TutorialButton,
  CenterImageBox,
  ExplainText,
  EndTuTorial,
  ContentWrap,
  StyledIconButton,
} from './Tutorial.Styles';
import { TutorialContentContainer } from './TutorialContent/TutorialContent.Container';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

interface TutorialVAProps {
  currentKey: string;
  tutorialImage: string;
  explainText: string;
  totalStep: number;
  currentStep: number;
  visibleLeftButton: boolean;
  visibleRightButton: boolean;
  isFreeCenter: boolean;
  curShowTutorial: boolean;
  onClickLeftButton: () => void;
  onClickRightButton: () => void;
  onClickTutorialExit: () => void;
  onClickNeverLookTutorial: () => void;
}

export function TutorialUI({
  currentKey,
  tutorialImage,
  explainText,
  totalStep,
  currentStep,
  visibleLeftButton,
  visibleRightButton,
  isFreeCenter,
  curShowTutorial,
  onClickLeftButton,
  onClickRightButton,
  onClickTutorialExit,
  onClickNeverLookTutorial,
}: TutorialVAProps) {
  return (
    <Wrapper id='tutorial-container'>
      <CountBox>
        {currentStep + 1} / {totalStep}
      </CountBox>
      <TutorialButton onClick={onClickTutorialExit}>튜토리얼 종료 X</TutorialButton>
      <CenterImageBox>
        <img src={tutorialImage} className={currentKey} />
      </CenterImageBox>
      <ExplainText
        dangerouslySetInnerHTML={{
          __html: explainText,
        }}
      ></ExplainText>
      {isFreeCenter ? null : curShowTutorial ? (
        <EndTuTorial onClick={onClickNeverLookTutorial}>다시 보지 않기</EndTuTorial>
      ) : null}
      <ContentWrap>
        <TutorialContentContainer currentKey={currentKey} />
      </ContentWrap>
      <StyledIconButton
        sx={{ left: '5.24vw' }}
        className={visibleLeftButton ? '' : 'd-none'}
        onClick={onClickLeftButton}
      >
        <AiOutlineLeft />
      </StyledIconButton>
      <StyledIconButton
        sx={{ right: '5.24vw' }}
        className={visibleRightButton ? '' : 'd-none'}
        onClick={onClickRightButton}
      >
        <AiOutlineRight />
      </StyledIconButton>
    </Wrapper>
  );
}
