import { ModalHiddenMissionType } from 'recoil/common/modalHiddenMission';
import { MissionType } from 'recoil/common/mission';
import { ContentWrap, Modal, ModalCloseButton, Wrapper } from './ModalHiddenMission.Styles';
import { MdClose } from 'react-icons/md';
import { MissionStepCardContainer } from './MissionStepCard/MissionStepCard.Container';

interface ModalHiddenMissionVAProps {
  onClose: () => void;
  visible: ModalHiddenMissionType;
  mission?: MissionType;
}

export function ModalHiddenMissionUI({ onClose, visible, mission }: ModalHiddenMissionVAProps) {
  return (
    <Modal open={visible.visible}>
      <Wrapper>
        <ContentWrap>
          <ModalCloseButton onClick={onClose}>
            <MdClose size={25} />
          </ModalCloseButton>
          <MissionStepCardContainer missionData={mission} />
        </ContentWrap>
      </Wrapper>
    </Modal>
  );
}
