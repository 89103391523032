import { useState, useEffect, useRef } from 'react';
import ModalDefault from './ModalDefault';
import Button from '../button/Button';
import { styled, Box, IconButton } from '@mui/material';
import { d_flex_center, dir_column, d_flex_space_evenly } from '../../styles/common';

const StyledModalCountingContainer = styled(Box)(props => ({
  width: '32.5rem',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '2rem',
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledModalCountingWrapper = styled(Box)(props => ({
  width: '100%',
  height: '18.5rem',
  minHeight: '170px',
  padding: '1rem 0 1rem 0',
  ...d_flex_center,
}));

const StyledModalCountingBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  ...d_flex_space_evenly,
  ...dir_column,
  justifyContent: 'center',
}));

const BackIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '3rem',
  transform: 'translate(50%, -50%)',
  right: '3.8rem',
  color: props.theme.palette.gray_2.main,
  fontSize: '1.2rem',
}));

const StyledCountingBox = styled(Box)(props => ({
  width: '6rem',
  height: '6rem',
  borderRadius: '50%',
  ...d_flex_center,
  fontFamily: "'TTTogether'",
  fontSize: '3rem',
  color: 'white',
}));

const StyledButtonWrapper = styled(Box)(props => ({
  width: '100%',
  height: '60px',
  maxHeight: '10vh',
  ...d_flex_center,
  border: 'none',
  borderBottomLeftRadius: '2rem',
  borderBottomRightRadius: '2rem',
}));

const StyledButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.2rem',
}));

const StyledSpanMain = styled(Box)(props => ({
  cursor: 'default',
  fontSize: '1.6rem',
  color: '#777777',
}));

interface ModalCountingProps {
  visible: boolean;
  onClose: () => void;
  onCallback: () => void;
}

function ModalCounting(props: ModalCountingProps) {
  const visible = props.visible;
  const onClose = props.onClose;
  const onCallback = props.onCallback;
  const [count, setCount] = useState(5);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);

  useEffect(() => {
    if (visible) setCount(5);
  }, [visible]);

  useEffect(() => {
    if (visible) {
      clearTimeout1();
      if (count > 0) {
        settimeout1.current.push(
          setTimeout(() => {
            setCount(count - 1);
          }, 1000),
        );
      } else {
        settimeout1.current.push(
          setTimeout(() => {
            onClose();
            onCallback();
          }, 1000),
        );
      }
    }
  }, [visible, count]);

  const color = '#48a1fd';

  const onClickClose = () => {
    onClose();
    onCallback();
  };

  const clearTimeout1 = () => {
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
  };

  return (
    <ModalDefault
      visible={visible}
      onClose={() => {
        onClose();
      }}
      boxShadow={true}
      className={'grade'}
    >
      <StyledModalCountingContainer>
        <BackIconButton onClick={() => onClose()} disableRipple>
          취소
        </BackIconButton>
        <StyledModalCountingWrapper>
          <StyledModalCountingBox>
            <StyledCountingBox
              sx={{
                backgroundColor: color,
              }}
            >
              {count}
            </StyledCountingBox>
            <Box sx={{ width: '80%', ...d_flex_space_evenly, ...dir_column, marginTop: '2rem' }}>
              <StyledSpanMain component={'span'}>다음 단어로 이동합니다.</StyledSpanMain>
            </Box>
          </StyledModalCountingBox>
        </StyledModalCountingWrapper>
        <StyledButtonWrapper
          sx={{
            cursor: 'pointer',
            backgroundColor: color,
          }}
          onClick={() => onClickClose()}
        >
          <StyledButton color='white'>확인</StyledButton>
        </StyledButtonWrapper>
      </StyledModalCountingContainer>
    </ModalDefault>
  );
}

export default ModalCounting;
