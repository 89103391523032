import { atom, SetterOrUpdater } from 'recoil';

interface ModalTabType {
  visible: boolean;
  tabNumber: number;
}

export const modalTabState = atom<ModalTabType>({
  key: 'modalTab',
  default: {
    visible: false,
    tabNumber: 0,
  },
});

interface ModalTabSetter {
  setModalTab: SetterOrUpdater<ModalTabType>;
}

export const openModalTab = ({ setModalTab }: ModalTabSetter, params: Omit<ModalTabType, 'visible'>) => {
  setModalTab({
    visible: true,
    ...params,
  });
};

export const closeModalTab = ({ setModalTab }: ModalTabSetter) => {
  setModalTab({
    visible: false,
    tabNumber: 0,
  });
};
