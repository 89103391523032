import { useCallback, useRef } from 'react';
import { ModalHiddenMissionUI } from './ModalHiddenMission.Presenter';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ModalHiddenMissionType,
  closeModalHiddenMission,
  modalHiddenMissionState,
} from 'recoil/common/modalHiddenMission';
import { userState } from 'recoil/model/user';
import { hiddenMissionState } from 'recoil/common/mission';
import { ModalLeaderBoardType, openModalLeaderBoard, modalLeaderBoardState } from 'recoil/common/modalLeaderBoard';

export function ModalHiddenMissionContainer() {
  const [modalHiddenMission, setModalHiddenMission] = useRecoilState<ModalHiddenMissionType>(modalHiddenMissionState);
  const { isFirstPopup, closeCallback } = modalHiddenMission;

  const userStateData = useRecoilValue(userState);
  const setModalLeaderBoard = useSetRecoilState<ModalLeaderBoardType>(modalLeaderBoardState);
  const isOpen = useRecoilValue(modalHiddenMissionState);
  const [hiddenMission, setHiddenMission] = useRecoilState(hiddenMissionState);
  const closeCallback_ref = useRef<{ close_cb?: () => void }>({});

  if (closeCallback) {
    closeCallback_ref.current = {
      close_cb: closeCallback,
    };
  }

  const closeHiddenMissionModal = useCallback(() => {
    closeModalHiddenMission({ setModalHiddenMission });

    if (isFirstPopup) {
      // 리더보드 모달 open
      if (userStateData.class && userStateData.class[0].use_rank) {
        openModalLeaderBoard({ setModalLeaderBoard });
      }
    } else if (!isFirstPopup) {
      // 학습 미션 달성 -> 초기화 진행 (중복 요청 막기 위해)
      setHiddenMission({
        benefit_first_img: '',
        benefit_last_img: '',
        complete_img: '',
        curr_step: 0,
        title: '',
        step: [],
      });

      // 만약 마무리 된 부분이 암기학습이면 암기학습 modalStudyModal open 할 수 있도록 작업
      if (closeCallback_ref.current && closeCallback_ref.current.close_cb) {
        closeCallback_ref.current.close_cb();
        closeCallback_ref.current = {};
      }
    }
  }, [userStateData.class, isFirstPopup]);

  const handleSinglePopupCloseClick = useCallback(() => {
    closeHiddenMissionModal();
  }, []);

  const props = {
    onClose: handleSinglePopupCloseClick,
    visible: isOpen,
    mission: hiddenMission,
  };

  return <ModalHiddenMissionUI {...props} />;
}
