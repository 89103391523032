import { Routes, Route, useLocation } from 'react-router';
import { Suspense, lazy, useState, useEffect, useContext } from 'react';
import { styled, Box, Button } from '@mui/material';
import dayjs from 'dayjs';

import { ModalContext } from '../../provider/ModalProvider';
import ModalLeveltest from '../../components/modal/ModalLeveltest';
import ModalLogoUpload from '../../components/modal/ModalLogoUpload';
import ModalText from '../../components/modal/ModalText';
import ModalSelect from '../../components/modal/ModalSelect';
import ModalFolderEdit from '../../components/modal/ModalFolderEdit';
import ModalSettings from '../../components/modal/ModalSettings';
import ModalTestInfo from '../../components/modal/ModalTestInfo';
import ModalGrading from '../../components/modal/ModalGrading';
import ModalLeaderboard from '../../components/modal/ModalLeaderboard';
import ModalDateRangePicker from '../../components/modal/ModalDateRangePicker';
import CustomDatePicker from '../../components/datepicker/DatePicker';
import Table from '../../components/table/Table';
import ModalWordDetail from '../../components/modal/ModalWordDetail';
import ModalCheckupMode from '../../components/modal/ModalCheckupMode';
import ModalWordlist from '../../components/modal/ModalWordlist';
import ModalAddPocketNote from '../../components/modal/ModalAddPocketNote';

import { schedule_books_fields } from '../../utils/table_fields';
import { BsFillBookmarkXFill } from 'react-icons/bs';
import { userState } from '../../recoil/model/user';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/common/loading';
import ModalCounting from '../../components/modal/ModalCounting';

function QuizIndex() {
  const { modal_confirm, modal_alert, modal_check } = useContext(ModalContext);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const [visibleLeveltest, setVisibleLeveltest] = useState(false);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [profileSrc, setProfileSrc] = useState('');
  const [visibleText, setVisibleText] = useState(false);
  const [selFolder, setSelFolder] = useState<FolderContens>({ id: '', name: '' });
  const [visibleSettings, setVisibleSettings] = useState(false);
  const [visibleTestInfo, setVisibleTestInfo] = useState(false);
  const [visibleGrading, setVisibleGrading] = useState(false);
  const [visibleDateRangePicker, setVisibleDateRangePicker] = useState(false);
  const [visibleLeaderboard, setVisibleLeaderboard] = useState(false);
  const [visibleCheckupMode, setVisibleCheckupMode] = useState(false);
  const [visibleAddPocketNote, setVisibleAddPocketNote] = useState(false);
  const [visibleCounting, setVisibleCounting] = useState(false);

  const tmp_list_items = [
    {
      book_name: '능률VOCA 고교필수편',
      bookmark: '북마크 교재',
      progress_rate: '21 / 50',
      startdate: '2021. 03. 25',
      details: {
        schedule_type: '단계별 학습 (기본 3단계: 플래시카드-팝퀴즈-딕테이션 / 심화 3단계: 위드빙고-스피킹-라이팅)',
        grade: '고등학교 1학년',
        publisher: '능률NE',
        publishdate: '2018년',
      },
    },
    {
      book_name: '워드마스터 중등고난도',
      bookmark: '-',
      progress_rate: '3 / 50',
      startdate: '2021. 01. 04',
      details: {
        schedule_type: '단계별 학습 (기본 3단계: 플래시카드-팝퀴즈-딕테이션 / 심화 3단계: 위드빙고-스피킹-라이팅)',
        grade: '고등학교 1학년',
        publisher: '능률NE',
        publishdate: '2018년',
      },
    },
    {
      book_name: '중등영어3 동아 (이병민)',
      bookmark: '북마크 교재',
      progress_rate: '2 / 8',
      startdate: '2021. 04. 08',
      details: {
        schedule_type: '단계별 학습 (기본 3단계: 플래시카드-팝퀴즈-딕테이션 / 심화 3단계: 위드빙고-스피킹-라이팅)',
        grade: '고등학교 1학년',
        publisher: '능률NE',
        publishdate: '2018년',
      },
    },
    {
      book_name: '보카패스 초급어휘 1200',
      bookmark: '-',
      progress_rate: '1 / 8',
      startdate: '2021. 04. 01',
      details: {
        schedule_type: '단계별 학습 (기본 3단계: 플래시카드-팝퀴즈-딕테이션 / 심화 3단계: 위드빙고-스피킹-라이팅)',
        grade: '고등학교 1학년',
        publisher: '능률NE',
        publishdate: '2018년',
      },
    },
  ];

  const [tableItems, setTableItems] = useState<TableItemsType[]>([...tmp_list_items]);
  const [tableFields, setTableFields] = useState<TableFieldsType[]>([...schedule_books_fields]);
  const [tableQuery, setTableQuery] = useState<TableQueryType>();

  const [visibleWordlist, setVisibleWordlist] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const [modalSelectContents, setModalSelectContents] = useState<ModalSelectDataType>({
    visible: false,
    data: {
      modal_id: '',
      title: '',
      subtitle: '',
      type: '',
      contents: [],
    },
    currentInput: [],
  });

  const [modalFolder, setModalFolder] = useState<ModalFolderDataType>({
    visible: false,
    data: {
      type: '',
    },
  });

  useEffect(() => {
    return () => {
      modal_check.closeModalCheck();
    };
  }, []);

  const onClickConfirm = () => {
    modal_confirm.openModalConfirm('normal', 'move_main', () => console.log('테스트'), undefined);
  };

  const onClickAlert1 = () => {
    modal_alert.openModalAlert('success_setting', undefined, '테스트1');
  };

  const onClickAlert2 = () => {
    modal_alert.openModalAlert('success_setting', undefined, '테스트2');
  };

  const onClickCheck1 = () => {
    modal_check.openModalCheck('checkupnote_check');
  };

  const onClickCheck2 = () => {
    modal_check.openModalCheck('score', '73');
  };

  const onClickLeveltest = () => {
    setVisibleLeveltest(true);
  };

  const clearModalLeveltest = () => {
    setVisibleLeveltest(false);
  };

  const onCloseLeveltest = () => {
    setVisibleLeveltest(false);
  };

  const onClickProfile = () => {
    setVisibleProfile(true);
  };

  const onClickText = () => {
    setVisibleText(true);
  };

  const onCloseText = () => {
    setVisibleText(false);
  };

  const openFolderEdit = () => {
    setSelFolder({ id: '', name: '' });
    setModalFolder({
      visible: true,
      data: {
        type: 'create',
      },
    });
  };

  const clearFolderEdit = () => {
    setModalFolder({
      visible: false,
      data: {
        type: '',
      },
    });
  };

  const editFolder = (folder_name: string) => {
    if (selFolder.id == '') {
      console.log(selFolder, folder_name);
      modal_alert.openModalAlert('success_create', undefined, '폴더가');
    } else {
      console.log(selFolder, folder_name);
      modal_alert.openModalAlert('success_modify', undefined, '폴더가');
    }
  };

  const openModalSelect = (folder: FolderContens) => {
    setSelFolder(folder);

    setModalSelectContents({
      visible: true,
      data: {
        modal_id: 'folder_setting_modal',
        title: '폴더 설정',
        subtitle: '',
        type: 'radio',
        contents: [
          { name: '수정', id: 'edit' },
          { name: '삭제', id: 'delete' },
        ],
        selectedData: undefined,
      },
      currentInput: [],
    });
  };

  const clearModalSelect = () => {
    setModalSelectContents({
      visible: false,
      data: {
        modal_id: '',
        title: '',
        subtitle: '',
        type: '',
        contents: [],
      },
      currentInput: [],
    });
  };

  const selectFolderSetting = (value: ModalSelectContentsType[]) => {
    if (selFolder.id === '') return;

    const type = value.map(item => item.id).toString();

    if (type == 'edit') {
      setModalFolder({
        visible: true,
        data: {
          type: 'modify',
          origin: selFolder.name,
        },
      });
    } else if (type == 'delete') {
      modal_confirm.openModalConfirm(
        'normal',
        'used_folder',
        () => modal_alert.openModalAlert('success_delete', undefined, '폴더가'),
        undefined,
        undefined,
      );
    }
  };

  const openModalSettings = () => {
    setVisibleSettings(true);
  };

  const onClickLeaderboard = () => {
    setVisibleLeaderboard(true);
  };

  const onClickDateRangePicker = () => {
    setVisibleDateRangePicker(true);
  };

  const loadingTest = () => {
    setLoadingStateData(prevState => ({ ...prevState, percent: 50 }));
    setTimeout(() => {
      setLoadingStateData(prevState => ({ ...prevState, percent: 100 }));
    }, 3000000);
  };

  return (
    <Box>
      <Box>모달 테스트</Box>
      <Box>
        <Button variant='contained' color='green' onClick={onClickConfirm}>
          컨펌모달
        </Button>
        <Button variant='contained' color='red' onClick={onClickAlert1}>
          알럿모달1
        </Button>
        <Button variant='contained' color='blue' onClick={onClickAlert2}>
          알럿모달2
        </Button>
        <Button variant='contained' color='cyan' onClick={onClickCheck1}>
          체크모달1
        </Button>
        <Button variant='contained' color='brown' onClick={onClickCheck2}>
          체크모달2
        </Button>
        <Button variant='contained' color='light_pink' onClick={onClickLeveltest}>
          레벨테스트 모달
        </Button>
        <Button variant='contained' color='cyan' onClick={() => setVisibleGrading(true)}>
          레벨테스트 결과
        </Button>
        <Button variant='contained' color='yellow_2' onClick={onClickProfile}>
          프로필 변경 모달 (모바일 임시)
        </Button>
        <Button variant='contained' color='black_4' onClick={onClickLeaderboard}>
          리더보드 순위
        </Button>
        <Button variant='contained' color='dark' onClick={onClickText}>
          텍스트 상세보기
        </Button>
        <Button variant='contained' color='dark_blue' onClick={onClickDateRangePicker}>
          레포트 기간 조회
        </Button>
        <CustomDatePicker onClose={date => console.log(date)} />
        <CustomDatePicker useMonth={true} />
        <Button variant='contained' color='green' onClick={() => openFolderEdit()}>
          포켓노트 폴더 추가
        </Button>
        <Button variant='contained' color='white' onClick={() => openModalSelect({ id: '1', name: '기본 단어장' })}>
          포켓노트 폴더 설정
        </Button>
        <Button variant='contained' color='cyan' onClick={() => openModalSettings()}>
          모듈 설정
        </Button>
        <Button variant='contained' color='red' onClick={() => setVisibleTestInfo(true)}>
          테스트 정보
        </Button>
        <Button variant='contained' color='success' onClick={() => setVisibleCheckupMode(true)}>
          체크업 모드 선택
        </Button>
        <Button variant='contained' color='black' onClick={() => setVisibleWordlist(true)}>
          단어 목록
        </Button>
        <Button variant='contained' color='info' onClick={() => setVisibleAddPocketNote(true)}>
          포켓노트 추가
        </Button>
        <Button variant='contained' color='info' onClick={() => loadingTest()}>
          로딩 테스트
        </Button>
        <Button variant='contained' color='blue' onClick={() => setVisibleCounting(true)}>
          카운팅
        </Button>
      </Box>

      <ModalLeveltest
        visible={visibleLeveltest}
        onClick={() => clearModalLeveltest()}
        onChange={(value: ModalSelectContentsType[]) => console.log(value)}
        onClose={onCloseLeveltest}
      ></ModalLeveltest>
      <ModalLogoUpload
        visible={visibleProfile}
        onClose={() => {
          setVisibleProfile(false);
        }}
        onSetDefaultImage={() => {
          setProfileSrc('');
        }}
        isDefault={userStateData.profile_type == 'default'}
      />
      <ModalText visible={visibleText} title='유닛명' text='Lesson 01. Hi, Sena.' onClose={onCloseText}></ModalText>
      <ModalSelect
        visible={modalSelectContents.visible}
        data={modalSelectContents.data}
        currentInput={modalSelectContents.currentInput}
        onClick={() => clearModalSelect()}
        onChange={(value: ModalSelectContentsType[]) => selectFolderSetting(value)}
      />
      <ModalFolderEdit
        visible={modalFolder.visible}
        data={modalFolder.data}
        onClick={() => clearFolderEdit()}
        onChange={(value: string) => editFolder(value)}
      />
      <ModalSettings visible={visibleSettings} onClose={() => setVisibleSettings(false)} />
      <ModalTestInfo
        visible={visibleTestInfo}
        onClose={() => setVisibleTestInfo(false)}
        questions={60}
        passing={95}
      ></ModalTestInfo>
      <ModalGrading
        visible={visibleGrading}
        onClose={() => setVisibleGrading(false)}
        type='wrong_answer2'
        record_cnt={0}
        answer='haaap'
        rightanswer='happy'
        question='행복한'
        grade='m1'
        score={90}
        studytime={12}
        bookmark={false}
      ></ModalGrading>
      <ModalDateRangePicker
        visible={visibleDateRangePicker}
        onClose={(sdate: Date, edate: Date) => {
          console.log(sdate, edate);
          setVisibleDateRangePicker(false);
        }}
      ></ModalDateRangePicker>
      <ModalLeaderboard />
      <ModalCheckupMode visible={visibleCheckupMode} onClose={() => setVisibleCheckupMode(false)} />
      <ModalCounting
        visible={visibleCounting}
        onClose={() => setVisibleCounting(false)}
        onCallback={() => console.log('counting close')}
      ></ModalCounting>
    </Box>
  );
}

export default QuizIndex;
