import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { routesState } from '../../recoil/common/routes';
import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { userState } from '../../recoil/model/user';
import { tutorialStateData } from '../../recoil/common/tutorial';
import { imgPreload } from 'utils/tools';
import { MEMORIZE_IMGS, BASIC_IMGS, ADVANCED_IMGS } from 'utils/constants';

import { styled, Box } from '@mui/material';
import dayjs from 'dayjs';

import { fetchPostApi, fetchPutApi } from '../../utils/api';
import { BASE_URL, DICTATION, FLASHCARD, POPQUIZ, SPEAKING, WORDBINGO, WRITING } from '../../utils/constants';

import Stepper from '../../components/common/Study/Stepper';

import Dictation from './Dictation';
import Flashcard from './Flashcard';
import MemorizeHot from './MemorizeHot';
import MemorizeNew from './MemorizeNew';
import Popquiz from './Popquiz';
import Speaking from './Speaking';
import Wordbingo from './Wordbingo';
import Writing from './Writing';

import { TutorialContainer } from '../../components/common/Tutorial/Tutorial.Container';
import { ModalSelectCardContainer } from '../../components/common/ModalSelectCard/ModalSelectCard.Container';
import { ModalContext } from 'provider/ModalProvider';

const StyledLearningWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
}));

function LearningIndex() {
  const navigate = useNavigate();
  const { modal_alert } = useContext(ModalContext);
  const { visible: visibleTutorial } = useRecoilValue(tutorialStateData);
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const {
    status,
    current_page,
    mod,
    learning_type,
    bingo_card,
    resultsheet,
    current_step,
    book_type,
    schedule_id,
    unit_id,
    record_id,
    first_learning,
    relearning,
    reset_learning,
    save_enabled,
    tag,
  } = learningStateData;

  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { customer_id } = userStateData;

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);

  useLayoutEffect(() => {
    switch (learning_type) {
      case 1:
      case 2:
        imgPreload(MEMORIZE_IMGS);
        break;
      case 3:
        imgPreload(BASIC_IMGS);
        break;
      case 4:
        imgPreload(ADVANCED_IMGS);
        break;
    }
  }, []);

  useEffect(() => {
    if (!status || learning_type == null) {
      if (child_path == 'learning') navigate(`${BASE_URL}/${root_path}`);
      else if (root_path && child_path) navigate(`${BASE_URL}/${root_path}/${child_path}`);
      else navigate(`${BASE_URL}/`);
    } else if (['S003Y', 'S003DY', 'S003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) {
      setLearningStateData(prevState => ({
        ...prevState,
        status: false,
      }));
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      navigate(`${BASE_URL}/`);
    }
  }, []);

  const sendResultsheet = async () => {
    let mod_tmp: ModType[] = [];
    if (mod[current_page].module == WORDBINGO) {
      if (!bingo_card || !resultsheet) return false;

      // 현재 모듈 정보 저장
      mod_tmp = JSON.parse(JSON.stringify(mod));
      mod_tmp[current_page].done = 1;
      mod_tmp[current_page].step = current_step;
      mod_tmp[current_page].resultsheet = resultsheet;
      mod_tmp[current_page].bingo_card = bingo_card;
    } else {
      if (!resultsheet) return false;

      // 현재 모듈 정보 저장
      mod_tmp = JSON.parse(JSON.stringify(mod));
      mod_tmp[current_page].done = 1;
      mod_tmp[current_page].step = current_step;
      mod_tmp[current_page].resultsheet = resultsheet;
    }

    let module_record_id = learningStateData.module_record_id;

    if (userStateData.id > 5) {
      if (first_learning) {
        const type =
          learningStateData.learning_type == 3 ? 'basic' : learningStateData.learning_type == 4 ? 'advanced' : 'wrong';
        const prepare_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules`,
          { type: type, book_type: book_type },
        );

        if (prepare_record.result) {
          module_record_id = prepare_record.data.moduleRecordId;
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: module_record_id,
            first_learning: false,
            save_enabled: true,
          }));
        }
      } else if (relearning) {
        const reset_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}`,
          { type: 'relearning', book_type: book_type },
        );

        if (reset_record.result) {
          module_record_id = reset_record.data.moduleRecordId;
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: module_record_id,
            relearning: false,
            save_enabled: true,
          }));
        }
      } else if (reset_learning) {
        const reset_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}`,
          { type: 'reset', book_type: book_type },
        );

        if (reset_record.result) {
          setLearningStateData(prevState => ({
            ...prevState,
            reset_learning: false,
            save_enabled: true,
          }));
        }
      } else if (!save_enabled) {
        setLearningStateData(prevState => ({
          ...prevState,
          save_enabled: true,
        }));
      }
    }

    // 현재 단계 이후 완료 모듈 확인
    const incompleteModules = mod.slice(current_page + 1).filter((module: ModType) => module.done == 0);

    // 학습 단계 이후 미완료 모듈이 있는 경우
    if (incompleteModules.length > 0) {
      setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
      const firstIncompleteModule = incompleteModules[0];

      setLearningStateData(prevState => ({
        ...prevState,
        mod: mod_tmp,
        current_page: firstIncompleteModule.seq,
        current_step: firstIncompleteModule.step ?? 0, // 학습 진행도 없으면 0
        resultsheet: [], // 성적표 초기화
      }));
    } else {
      // 학습 단계 이전 미완료 모듈 확인
      setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
      const incompleteModulesBeforeCurrent = mod.slice(0, current_page).filter((module: ModType) => module.done == 0);

      // 이전 학습 단계에 미완료 모듈이 있을 경우
      if (incompleteModulesBeforeCurrent.length > 0) {
        const firstIncompleteModule = incompleteModulesBeforeCurrent[0];
        setLearningStateData(prevState => ({
          ...prevState,
          mod: mod_tmp,
          current_page: firstIncompleteModule.seq,
          current_step: firstIncompleteModule.step ?? 0, // 학습 진행도 없으면 0
          resultsheet: [], // 성적표 초기화
        }));
      } else {
        setPercent(setLoadingStateData, 1, '학습 데이터를 분석하는 중입니다.');
        const learning_data = JSON.parse(JSON.stringify(learningStateData));
        learning_data.mod = mod_tmp;
        learning_data.studytime = learning_data.studytime + dayjs().diff(learningStateData.starttime, 's');

        if (userStateData.id > 5) {
          const result_res = await fetchPutApi(
            `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}/result`,
            { ...learning_data },
          );
          if (result_res.result) {
            setTimeout(() => {
              const path =
                root_path == 'recent'
                  ? `${BASE_URL}/${root_path}/result`
                  : `${BASE_URL}/${root_path}/${child_path}/result`;
              navigate(path, {
                state: {
                  schedule_id: schedule_id,
                  unit_id: unit_id,
                  record_id: record_id,
                  module_record_id: module_record_id,
                  tab_name: learningStateData.tab_name,
                  schedule_type: learningStateData.book_type,
                  afterLearn: true,
                  tag,
                },
              });
            }, 0);
          }
        } else {
          setLearningStateData(prevState => ({
            ...prevState,
            mod: mod_tmp,
          }));
          setTimeout(() => {
            const path =
              root_path == 'recent'
                ? `${BASE_URL}/${root_path}/result`
                : `${BASE_URL}/${root_path}/${child_path}/result`;
            navigate(path, {
              state: {
                schedule_id: schedule_id,
                unit_id: unit_id,
                record_id: record_id,
                module_record_id: module_record_id,
                tab_name: learningStateData.tab_name,
                schedule_type: learningStateData.book_type,
                tag,
              },
            });
          }, 0);
        }
      }
    }
  };

  useEffect(() => {
    if (visibleTutorial) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  }, [visibleTutorial]);

  return (
    <StyledLearningWrap>
      {visibleTutorial ? <TutorialContainer /> : false}
      {status && learning_type != null ? (
        <>
          {learning_type == 1 || learning_type == 2 ? null : <Stepper></Stepper>}
          {learning_type == 1 ? (
            <MemorizeHot />
          ) : learning_type == 2 ? (
            <MemorizeNew />
          ) : mod && current_page >= 0 ? (
            (function () {
              if (mod[current_page].module == FLASHCARD) {
                return <Flashcard sendResultsheet={sendResultsheet} />;
              } else if (mod[current_page].module == WORDBINGO) {
                return <Wordbingo sendResultsheet={sendResultsheet} />;
              } else if (mod[current_page].module == POPQUIZ) {
                return <Popquiz sendResultsheet={sendResultsheet} />;
              } else if (mod[current_page].module == DICTATION) {
                return <Dictation sendResultsheet={sendResultsheet} />;
              } else if (mod[current_page].module == WRITING) {
                return <Writing sendResultsheet={sendResultsheet} />;
              } else if (mod[current_page].module == SPEAKING) {
                return <Speaking sendResultsheet={sendResultsheet} tag={tag} />;
              }
            })()
          ) : null}
        </>
      ) : null}
      <ModalSelectCardContainer />
    </StyledLearningWrap>
  );
}

export default LearningIndex;
