import { atom, useRecoilState } from 'recoil';
import { SetterOrUpdater } from 'recoil';
import { CDN, VOICE_DIR, VOICE_TTS_CDN } from '../../utils/constants';
import { getContentFoldername } from '../../utils/tools';
import { fetchCreateTTSApi } from '../../utils/api';

export const voiceState = atom<VoiceType>({
  key: 'voiceState',
  default: {
    element: undefined,
    playing: false,
    loading: false,
    error: false,
    ready: false,
    volume: 1,
  },
});

export function useVoice() {
  const [voice, setVoiceState] = useRecoilState(voiceState);

  function setVoice(spell: string, filename: string, isSpell = true) {
    if (!spell) return false;
    const foldername = getContentFoldername(spell);
    // isSpell로 단어음성 / 예문음성 경로 분기처리
    const voice_file_path = isSpell
      ? CDN + VOICE_DIR + foldername + '/' + filename
      : 'https://kr.object.ncloudstorage.com/longvoca-tts/' + filename;
    const voiceElement = new Audio(voice_file_path);
    voiceElement.setAttribute('playsinline', '');
    voiceElement.onloadedmetadata = () => {
      voiceElement.onplaying = () => setVoiceState(prevState => ({ ...prevState, playing: true }));
      setVoiceState(prevState => ({ ...prevState, ready: true, element: voiceElement, playing: false }));
    };

    if (isSpell) {
      voiceElement.onerror = () => {
        const gender = 'M'; // 추후 변경
        const path = foldername.charAt(0).toLocaleLowerCase();
        const full_path = `${gender}/${path}/${foldername}.mp3`;
        const voiceTTSElement = new Audio(VOICE_TTS_CDN + full_path);
        voiceTTSElement.setAttribute('playsinline', '');

        voiceTTSElement.onloadedmetadata = () => {
          voiceTTSElement.onplaying = () => setVoiceState(prevState => ({ ...prevState, playing: true }));
          setVoiceState(prevState => ({ ...prevState, ready: true, element: voiceTTSElement, playing: false }));
        };

        voiceTTSElement.onerror = () => {
          const create_tts_res = fetchCreateTTSApi({
            text: foldername.replace(/\_/g, ' '),
            gender,
            mp3name: `${foldername}.mp3`,
          });

          if (create_tts_res) {
            create_tts_res
              .then(res => res.json())
              .then(data => {
                if (data && data.done) {
                  const newTTSElement = new Audio(VOICE_TTS_CDN + full_path);
                  newTTSElement.setAttribute('playsinline', '');
                  newTTSElement.onloadedmetadata = () => {
                    newTTSElement.onplaying = () => setVoiceState(prevState => ({ ...prevState, playing: true }));
                    setVoiceState(prevState => ({ ...prevState, ready: true, element: newTTSElement, playing: false }));
                  };

                  newTTSElement.onerror = () => {
                    setVoiceState(prevState => ({ ...prevState, ready: false, playing: false }));
                  };
                } else {
                  setVoiceState(prevState => ({ ...prevState, ready: false, playing: false }));
                }
              });
          } else {
            setVoiceState(prevState => ({ ...prevState, ready: false, playing: false }));
          }
        };
      };
    }
  }

  function voicePlay() {
    if (voice.element && !voice.element.error) {
      voice.element.volume = voice.volume * 0.8;
      if (voice.element.currentTime > 0 && !voice.element.paused && !voice.element.ended) {
        voice.element.pause();
        voice.element.currentTime = 0;
      }
      voice.element.play();

      return new Promise(resolve => {
        if (voice.element)
          voice.element.onended = () => {
            setVoiceState(prevState => ({ ...prevState, playing: false }));
            resolve(true);
          };
        else {
          setVoiceState(prevState => ({ ...prevState, playing: false }));
          resolve(true);
        }
      });
    } else {
      return new Promise(resolve => {
        resolve(true);
      });
    }
  }

  function voiceStop() {
    if (voice.element && !voice.element.error && !voice.element.paused && !voice.element.ended) {
      voice.element.pause();
      voice.element.currentTime = 0;
      setVoiceState(prevState => ({ ...prevState, playing: false }));
    }
  }

  function setAudioRate(audio_rate: number) {
    if (voice.element && !voice.element.error) {
      voice.element.playbackRate = audio_rate;
    }
  }

  return { setVoice, voicePlay, voiceStop, setAudioRate };
}
