import { ModalSelectCardUI } from './ModalSelectCard.Presenters';
import React, { useCallback, useContext } from 'react';
import { useRecoilState } from 'recoil';
import {
  ModalSelectCardDataType,
  modalSelectCardData,
  closeModalSelectCard,
} from '../../../recoil/common/modalSelectCard';

export const ModalSelectCardContainer = React.memo(function ModalStudySettingContainer() {
  const [modalSelectCard, setModalSelectCard] = useRecoilState<ModalSelectCardDataType>(modalSelectCardData);

  const onClickClose = useCallback(() => {
    if (modalSelectCard.onClose) {
      modalSelectCard.onClose();
    }
    closeModalSelectCard({ setModalSelectCard });
  }, [modalSelectCard.type, modalSelectCard.onClose]);

  const onClose = useCallback(() => {
    onClickClose();
  }, [onClickClose]);

  const onSuccess = useCallback(
    (idx: number) => {
      modalSelectCard.onSuccess(idx);
    },
    [modalSelectCard],
  );

  const props_obj = {
    visible: modalSelectCard.visible,
    title: modalSelectCard.title,
    contents: modalSelectCard.contents,
    value: modalSelectCard.value,
    type: modalSelectCard.type,
    onSuccess,
    onClose,
    onClickClose,
  };

  return <ModalSelectCardUI {...props_obj} />;
});
