export const schedule_books_fields = [
  { key: 'name', label: '교재', width: '55%', sort: true, text_color: 'rgb(43, 138, 209)', weight: 500 },
  { key: 'bookmark', label: '북마크 교재', width: '15%', sort: true },
  { key: 'completed_units_count', label: '진도율', width: '15%', sort: true },
  { key: 'startdate', label: '학습 시작일', width: '15%', sort: true },
];

export const manager_notice_fields = [
  { key: 'type', label: '구분', width: '10%', sort: true },
  { key: 'readed', label: '', width: '3%', sort: false },
  { key: 'title', label: '내용', width: '57%', sort: true, text_color: 'rgb(43, 138, 209)', weight: 500 },
  { key: 'postdate', label: '등록일', width: '20%', sort: true },
];

export const report_lookup_fields = [
  { key: 'category_name', label: '구분', width: '10%', sort: true },
  { key: 'schedule_name', label: '교재명', width: '35%', sort: true, text_color: 'rgb(43, 138, 209)', weight: 500 },
  { key: 'unit_name', label: '유닛', width: '24%', sort: true },
  { key: 'avg', label: '점수', width: '10%', sort: true, text_color: '#4620e9', weight: 500 },
  { key: 'lastdate', label: '학습일', width: '15%', sort: true },
];

export const report_month_total_fields = [
  { key: 'category', label: '항목', width: '18%' },
  { key: 'word_count', label: '학습 문제', width: '18%' },
  { key: 'wrong_word_count', label: '오답 문제', width: '18%' },
  { key: 'wrong_rate', label: '오답률', width: '18%' },
  { key: 'studytime', label: '학습 시간', width: '18%' },
  { key: 'avg', label: '평균 점수', width: '10%', text_color: '#4620e9', weight: 500 },
];

export const report_month_detail_fields = [
  { key: 'category', label: '항목', width: '18%', sort: true },
  { key: 'word_count', label: '학습 문제', width: '18%', sort: true },
  { key: 'wrong_word_count', label: '오답 문제', width: '18%', sort: true },
  { key: 'wrong_rate', label: '오답률', width: '18%', sort: true },
  { key: 'studytime', label: '학습 시간', width: '18%', sort: true },
  { key: 'avg', label: '평균 점수', width: '10%', sort: true, text_color: '#4620e9', weight: 500 },
];

export const pocket_note_fields_note = [
  { isNote: true, label: 'check' },
  { key: 'name', label: '노트이름' },
  { key: 'word_count', label: '단어수' },
];

export const pocket_note_fields_words = [
  { isNote: false, label: 'check' },
  { key: 'spell', label: '철자' },
  { key: 'mean', label: '의미' },
];

export const modal_leaderboard_fields = [
  { key: 'ranking', label: '순위', width: '17.5%', center: true },
  { key: 'class_name', label: '클래스', width: '27.5%', center: true },
  { key: 'name', label: '이름', width: '27.5%', center: true },
  { key: 'score', label: '총합 점수', width: '27.5%', center: true },
];

export const leaderboard_fields = [
  { key: 'ranking', label: '순위', width: '12%', sort: true, center: true },
  { key: 'name', label: '이름', width: '14.1667%', sort: true, is_padding: '1rem', text_color: '#4620e9', weight: 500 },
  { key: 'class_name', label: '클래스', width: '14.1667%', sort: true },
  { key: 'study_count', label: '학습 일수', width: '14.1667%', sort: true },
  { key: 'studytime', label: '누적 시간', width: '14.1667%', sort: true },
  { key: 'words_count', label: '누적 문제', width: '14.1667%', sort: true },
  { key: 'score', label: '종합 점수', width: '14.1667%', sort: true, text_color: '#4620e9', weight: 500 },
];

export const award_hitory_fields = [
  { key: 'ranking', label: '순위', width: '12%', sort: true, center: true },
  { key: 'name', label: '이름', width: '14.1667%', sort: true, is_padding: '1rem' },
  { key: 'class_name', label: '클래스', width: '14.1667%', sort: true },
  { key: 'study_count', label: '학습 일수', width: '14.1667%', sort: true },
  { key: 'studytime', label: '누적 시간', width: '14.1667%', sort: true },
  { key: 'words_count', label: '누적 문제', width: '14.1667%', sort: true },
  { key: 'award_date', label: '수상년월', width: '14.1667%', sort: true, text_color: '#4620e9', weight: 500 },
];
