import { Box, styled } from '@mui/material';

const Table = styled('table')({});

export const Personal = () => {
  return (
    <Box
      style={{ paddingRight: '1.25rem', fontSize: '0.875rem' }}
      sx={{
        '& > *': {
          fontFamily: 'Apple SD Gothic Neo',
          fontWeight: '300',
          lineHeight: '1.5',
        },
      }}
    >
      <p>
        {` 오래영어 (이하 '당사'라 함)는 오래영어 영어학습 어플리케이션 서비스
        사용자의 개인정보보호를 매우 중요시 하며, 『개인정보 보호법』,
        『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등 개인정보와 관련된
        법령상의 개인정보보호 규정을 준수하고 있습니다. 이 개인정보 추방침은
        개인정보와 관련한 법령 또는 지침의 변경이 있는 경우 갱신되고, 오래영어
        정책의 변화에 따라 달라질 수 있으니 사용자께서는 오래영어 사이트를 방문
        시 수시로 확인하여 주시기 바랍니다. 오래영어는 아래와 같이 개인정보를
        보호하고 있습니다.`}
      </p>

      <p>
        <br />
      </p>

      <p>1. 개인정보 수집 항목, 이용 목적 및 방법</p>

      <p>2. 수집하는 개인정보의 보유 및 이용기간</p>

      <p>3. 개인정보의 파기절차 및 방법</p>

      <p>4. 개인정보 3자 제공</p>

      <p>5. 개인정보의 취급위탁</p>

      <p>6. 사용자 및 법정대리인의 권리와 그 행사방법</p>

      <p>7. 링크 사이트에 대한 책임</p>

      <p>8. 개인정보 자동 수집 장치의 설치*운영 및 거부에 관한 사항</p>

      <p>9. 개인정보 보호책임자 및 상담*신고</p>

      <p>10. 고지의 의무</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>1. 개인정보 수집 항목, 이용 목적 및 방법</span>
      </p>

      <p>{`1) 수집하는 개인정보의 항목`}</p>

      <p>{`가) 필수항목 : 성명, 휴대폰번호, 학원명 등`}</p>

      <p>{`나) 서비스 이용 또는 사업처리 과정에서 생성/ 수집되는 정보서비스 이용기록(이용시간 또는 서비스 구매내역`}</p>

      <p>
        {`다) 정식가입 시 정식 가입자가 선택하는 이체서비스 방식에 따른 이체 관련
        필요 정보`}
      </p>

      <p>- CMS 출금이체 서비스 진행자의 경우 예금주 생년월일, 금융기관명, 계좌번호, 전화번호, 핸드폰번호</p>

      <p>- 카드 자동이체 서비스 진행자의 경우 카드주 생년월일, 카드사, 카드번호, 카드유효기간, 전화번호, 핸드폰번호</p>

      <p>
        <br />
      </p>

      <p>
        {`2) 개인정보 수집 목적당사는 수집한 개인정보를 다음의 목적을 위해
        활용합니다. 사용자가 제공한 모든 정보는 아래 목적에 필요한 용도 이외로는
        사용되지 않으며, 변경될 경우 사전 동의를 구하도록 할 것입니다.`}
      </p>

      <p>
        {`가) 회원 관리서비스 이용에 따른 본인확인, 개인식별, 부정 이용방지와
        비인가 사용 방지, 가입 의사 확인, 분쟁 조정을 위한 기록 보존, 고객상담,
        불만처리 등 민원처리, 고지사항 전달, 회원탈퇴 의사의 확인`}
      </p>

      <p>
        {`나) 신규 서비스 개발, 기능 개선, 마케팅 및 광고에 활용사용자에게 최적화된
        서비스 제공, 신규 서비스 및 상품 개발 및 특화, 인구통계학적 특성에 따른
        서비스 제공 및 광고 게재, 접속 빈도 파악, 서비스 이용 현황 통계/ 분석,
        고객 관심사에 부합하는 이벤트 기획, 이벤트 광고성 정보 제공 및 이벤트
        참여 기회 제공`}
      </p>

      <p>
        {`다) CMS 출금이체서비스 또는 효성에프엠에스㈜ 카드자동이체서비스를 통한
        요금 수납`}
      </p>

      <p>
        <br />
        <p>
          {`3) 개인정보 수집 방법당사는 오래영어 서비스 제공을 위해 다음과 같은
          방법으로 개인정보를 수집합니다.`}
        </p>
      </p>

      <p>{`가) 사용자 및 이용 교육기관(학원 등에서 제공한 고객 정보)`}</p>

      <p>{`나) 생성정보 수집 툴을 통한 자동 수집`}</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>2. 수집하는 개인정보의 보유 및 이용 기간</span>
      </p>

      <p>
        오래영어는 원칙적으로 개인정보의 수집 및 이용 목적이 달성 되면 사용자의 개인정보를 파기합니다. 단, 당사 내부
        방침이나 관계 법령의 규정에 의하여 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다.
      </p>

      <p>
        <br />
      </p>

      <p>{`1) 상법 등 법령에 따라 보존할 필요성이 있는 경우`}</p>

      <p>{`가) 보존항목 : 상거래이력`}</p>

      <p>{`나) 보존근거 : 상법, 전자상거래 등에서의 소비자보호에 관한 법률`}</p>

      <p>{`다) 보존기간 : - 계약 또는 청약철회 등에 관한 기록 : 5년`}</p>

      <p>- 대금결제 및 재화 등의 공급에 관한 기록 : 5년</p>

      <p>- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</p>

      <p>
        <br />
      </p>

      <p>{`2) 로그기록 관련 보존 개인정보`}</p>

      <p>{`가) 보존항목 : 접속기록`}</p>

      <p>{`나) 보존근거 : 통신비밀보호법`}</p>

      <p>{`다) 보존기간 : 3개월`}</p>

      <p>
        - 법령, 회사와 사용자 간의 서비스 이용 약관 또는 공서양속을 위반하거나 기타 회사, 회원 또는 타인의 권리나 이익을
        침해하는 방법이나 내용의 거래를 말함
      </p>

      <p>
        <br />
      </p>

      <p>{`3) 요금 이체 관련 개인정보`}</p>

      <p>{`가) 보존항목 : 개인정보 수집항목, 이용 목적 및 방법, 이체 관련 필요 정보`}</p>

      <p>
        {`나) 보존기간 : 수집, 이용 동의일부터 CMS 출금이체 또는 카드 자동이체
        종료일(해지일까지)`}
      </p>

      <p>- 신청자는 개인정보 수집 및 이용을 거부할 권리가 있으며, 권리행사시 출금이체 신청이 거부될 수 있습니다.</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>3. 개인정보의 파기절차 및 방법</span>
      </p>

      <p>
        {`1) 파기 절차사용자가 회원가입 등을 위해 입력하신 정보는 이용목적이 달성된
        후 파기 됩니다. 다만, 법령에 따라(보유 및 이용기간 참조) 일정 기간 저장된
        후 파기될 수 있습니다. 동 개인 정보는 법률에 의한 경우가 아니고서는
        보전되는 이외의 다른 목적으로 이용되지 않습니다.`}
      </p>

      <p>
        {`2) 파기 방법종이(인쇄물, 서면 등)에 출력 된 개인 정보는 분쇄기로
        분쇄하거나 소각을 통하여 파기하고 전자적 파일 형태로 저장된 개인 정보는
        복원이 불가능한 방법으로 영구 삭제합니다.`}
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>4. 개인정보 제3자 제공</span>
      </p>

      <p>
        오래영어 서비스 이용 도중 프로필 정보는 다른 사용자와 공유될 수 있지만, 다른정보는 사용자의 개인정보를 수집 및
        이용목적에 한해서만 이용하며 타인 또는 타기업・기관에 공개하지 않습니다. 다만, 아래의 경우는 예외로 합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        {`1) 사용자들이 사전에 동의한 경우정보수집 또는 정보제공 이전에 사용자께
        비즈니스 파트너가 누구인지, 어떤 정보가 왜 필요한지, 그리고 언제까지
        어떻게 보고/ 관리되는지 알려드리고 동의를 구하는 절차를 거치게 되며,
        사용자께서사전에 동의하지 않는 경우에는 추가적인 정보를 수집하거나
        비즈니스 파트너와 공유하지 않습니다.`}
      </p>

      <p>
        {`2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우`}
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>5. 개인정보의 취급위탁</span>
      </p>

      <p>
        당사는 오래영어 서비스 제공을 위해 아래와 같이 개인 정보를 위탁하고 있으며, 위탁계약 시 개인 정보가 안전하게
        관리될 수 있도록 필요한 사항을 규정하고 있습니다. 개인 정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        {/* <span style={{ fontWeight: 'bold', color: '#cccccc' }}>
          (표식 참고)
        </span> */}
        <Table
          sx={{
            border: '1px solid rgb(197, 197, 197)',
            borderCollapse: 'collapse',
            'thead, tbody, tr, td, th': {
              border: '1px solid rgb(197, 197, 197)',
              fontWeight: '300',
            },
          }}
        >
          <thead>
            <tr>
              <th scope='col'>목적 및 업무내용</th>
              <th scope='col'>제공 정보</th>
              <th scope='col'>업체 명</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row'>
                CMS 출금이체서비스,
                <br />
                효성에프엠에스㈜ 카드자동이체서비스를
                <br />
                통한 요금 수납
              </th>
              {/* <td style="text-align:left; vertical-align:middle"> */}
              <td>
                이름, 예금주 또는 카드주 생년월일, 연락처(전화번호), 휴대전화번호, 금융기관명, 계좌번호 혹은 카드사 및
                카드번호, 카드유효기간
                <br />
                <br />
                ※서비스 정식가입 신청자는 개인정보를 수납기관이 효성에프엠에스㈜ 에 제공하는 것을 거부할 권리가 있으며,
                거부 시 출금이체 신청이 거부될 수 있습니다.
              </td>
              <td>효성에프엠에스㈜</td>
            </tr>
            <tr>
              <th scope='row'>
                서비스 이용을 위한
                <br />
                가입 자동이체신청 서비스 신청 및 등록
              </th>
              <td>
                {/* <td style="text-align:left"></td> */}
                이름, 학원명, 연락처, 사업자번호, CMS 자동이체 신청서 내 결제정보
              </td>
              <td>효성에프엠에스㈜</td>
            </tr>
            <tr>
              <th scope='row'>
                서비스 가입인증 및
                <br />
                문자 메세지 발송
              </th>
              <td>이름, 학원명, 연락처</td>
              {/* <td style="text-align:left">이름, 학원명, 연락처</td></td> */}
              <td>
                NHN한국사이버결제
                <br />
                인포뱅크(주)
                <br />
                슈어엠(주)
              </td>
            </tr>
            <tr>
              <th scope='row'>
                서비스 가입인증 및
                <br />
                문자/카카오톡 알림톡 메세지 발송
              </th>
              <td>이름, 학원명, 연락처</td>
              {/* <td style="text-align:left">이름, 학원명, 연락처</td></td> */}
              <td>
                인포뱅크(주)
                <br />
                슈어엠(주)
              </td>
            </tr>
          </tbody>
        </Table>
      </p>

      <p>
        <br />
      </p>

      <p>* 개인정보의 보유 및 이용 기간: 가입 해지 후 6개월 또는 위탁 계약 종료 시점까지</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>6. 사용자 및 법정대리인의 권리와 그 행사방법</span>
      </p>

      <p>
        사용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할
        수 있으며, 계정삭제를 요청할 수도 있습니다. 당사에 직접 이메일을 통한 문의 및 이용 교육기관에 요청을 통해 요청을
        할 수 있습니다.
      </p>

      <p>
        사용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는
        제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이
        통지하여 정정이 이루어지도록 하겠습니다. 당사는 사용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 &quot;개인정보의 보유 및 이용기간&quot;에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할
        수 없도록 처리하고 있습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>7. 링크 사이트에 대한 책임</span>
      </p>

      <p>
        당사는 사용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다.이 경우 오래영어는 외부사이트
        및 자료에 대한 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수
        없습니다. 링크되어있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 개인정보 취급방침이 적용되지
        않습니다. 오래영어가 제공하는 링크를 통해 타 사이트의 페이지로 옮겨갈 경우 해당 사이트의 개인정보취급방침은
        오래영어와 무관하므로 새로 방문한 사이트의 정책을 검토해보시기 바랍니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>8. 개인정보 자동 수집 장치의 설치*운영 및 거부에 관한 사항</span>
      </p>

      <p>사용자 개개인에게 개인화 되고 맞춤화 된 서비스를 제공하기 위해서 당사는 자체 로그 기록을 운용하고 있습니다.</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>9. 개인정보 보호책임자 및 상담*신고</span>
      </p>

      <p>
        사용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 당사는 개인정보 보호책임자를 두고
        있습니다. 사용자의 개인정보와 관련한 문의 사항이 있으시면 아래의 개인정보 보호책임자 또는 개인정보 민원처리
        담당부서에 연락 주시기 바랍니다.
      </p>

      <p>
        <br />
      </p>

      <p>개인정보 보호책임자</p>

      <p>- 이름 : 정상용</p>

      <p>- 직위 : 대리</p>

      <p>- 전화 : 1661-5655</p>

      <p>- 메일 : help@longedu.co.kr</p>

      <p>
        <br />
      </p>

      <p>
        기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        <br />- 개인정보침해신고센터 (www.118.or.kr/ 국번없이 118) <br />- 대검찰청 사이버범죄수사단 (icic.sppo.go.kr/
        02-3480-3600) <br />- 경찰청 사이버테러대응센터 (www.ctrc.go.kr/ 1566-0112)
      </p>
      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>10. 고지의 의무</span>
      </p>

      <p>
        본 개인정보 취급방침을 포함한 기타 개인정보보호에 대한 상세한 내용은 당사가 운영하는 오래영어 웹페이지 하단에
        공개됩니다. 본 개인정보 취급방침의 내용은 수시로 변경될 수 있으므로 홈페이지를 방문하실 때마다 이를 확인하시기
        바랍니다. <br />
        본 개인정보 취급방침이 관련 법령 및 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 경우
        변경되는 개인정보 취급방침을 시행하기 전에 오래영어 홈페이지를 통해 변경 사유 및 내용 등을 공지합니다. <br />
      </p>
      <p>
        개인정보취급방침 변경공고일자 : 2023년 7월 1일 <br />
        개인정보취급방침 시행일자 : 2023년 7월 1일
      </p>
    </Box>
  );
};
