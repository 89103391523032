import { atom } from 'recoil';

export const moduleSettingState = atom<ModuleSettings>({
  key: 'moduleSettingState',
  default: {
    memorize_hot_stage: {
      example: 1,
      lecture: 1,
      spell: 1,
      image: 1,
      dictionary: 0,
    },
    memorize_new_stage: {
      example: 1,
      spell: 1,
      mean: 1,
      image: 1,
      dictionary: 0,
    },
    memorize_normal_stage: {
      spell: 1,
      mean: 1,
      image: 1,
      dictionary: 1,
    },
    memorize_voice: { count: 2, speed: 2, flash: 2, audio: 1 },
    memorize_book: { auto: 1, order: 0 },
    flash: { count: 2, speed: 2, flash: 2, audio: 1 },
    bingo: { time: 0, retry: 1 },
    popquiz: { time: 1, retry: 0 },
    dictation: { count: 2, hint: 0 },
    writing: { hint: 0 },
    speaking: { example_type: 0, type: 0, timer: 0, retry: 0 },
  },
});
