import { FormControl, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { d_flex_center, d_flex_space_between, d_flex_start } from '../../styles/common';

import { modal_leaderboard_fields } from '../../utils/table_fields';
import Table from '../table/Table';
import dayjs from 'dayjs';
import { userState } from 'recoil/model/user';
import { modalLeaderBoardState, ModalLeaderBoardType, closeModalLeaderBoard } from 'recoil/common/modalLeaderBoard';
import {
  ModalDefaultProfileSettingType,
  modalDefaultProfileSettingState,
  openModalDefaultProfileSetting,
} from 'recoil/common/modalDefaultProfileSetting';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { fetchGetApi } from 'utils/api';
import DropdownCircle from 'components/dropdown/DropdownCircle';

// style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '50%',
  height: 'auto',
};

const StyledLeaderboardModalWrapper = styled(FormControl)({
  width: '100%',
  alignItems: 'center',
  backgroundColor: 'var(--white)',
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '1rem',
});

const StyledModalBody = styled(Box)(props => ({
  padding: '8px 16px',
  ...d_flex_center,
  width: '100%',
  flexDirection: 'column',
}));

const StyledModalTitle = styled(Box)({
  ...d_flex_center,
  width: '98%',
  color: 'var(--purple)',
  fontSize: '1.2rem',
  fontWeight: '600',
  textAlign: 'center',
  padding: '8px 0 16px 0',
  '&.Mui-focused': {
    color: 'var(--purple)',
  },
});

const StyledModalUtil = styled(Box)(props => ({
  border: 'none',
  width: '100%',
  height: '3.5rem',
  maxHeight: '10vh',
  ...d_flex_center,
  cursor: 'pointer',
  borderBottomLeftRadius: '1rem',
  borderBottomRightRadius: '1rem',
  background: 'linear-gradient(#652ae9, #4620e9)',
}));

const CheckButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1rem',
  color: 'var(--white)',
}));

function ModalLeaderboard() {
  const setModalDefaultProfileSetting = useSetRecoilState<ModalDefaultProfileSettingType>(
    modalDefaultProfileSettingState,
  );
  const [modalLeaderBoard, setModalLeaderBoard] = useRecoilState<ModalLeaderBoardType>(modalLeaderBoardState);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { visible } = modalLeaderBoard;

  const today_ym = dayjs().format('YYYY년 MM월');

  const [tableItems, setTableItems] = useState<TableItemsType[]>([]);
  const [tableFields, setTableFields] = useState<TableFieldsType[]>([...modal_leaderboard_fields]);
  const [tableQuery, setTableQuery] = useState<TableQueryType>();
  const [filterClasses, setFilterClasses] = useState<ClassType[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassType>();
  const [classNames, setClassNames] = useState<string[]>([]);

  useEffect(() => {
    if (userStateData.class && userStateData.class.length > 0) {
      if (userStateData.class.length == 1) {
        if (userStateData.product == 'F') {
          setSelectedClass({
            id: -1,
            name: '전체',
            study_days: '1,2,3,4,5,6,7',
            use_rank: 1,
            createdAt: '',
          });
        } else {
          setSelectedClass(userStateData.class[0]);
          setFilterClasses(userStateData.class);
        }
      } else {
        let classes = userStateData.class;
        classes = classes.filter(item => item.use_rank == 1);
        classes.sort((a, b) => {
          if (a.createdAt < b.createdAt) return -1;
          else if (a.createdAt > b.createdAt) return 1;
          return 0;
        });
        const class_name: string[] = [];
        for (let i = 0; i < classes.length; i++) {
          class_name.push(classes[i].name);
        }
        setFilterClasses([...classes]);

        if (userStateData.product == 'F') {
          // 무료체험 계정이었을
          setClassNames(['전체반']);
        } else {
          setClassNames([...class_name]);
        }
        setSelectedClass(classes[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (visible) bindKeyboard();
    return () => {
      unbindKeyboard();
    };
  }, [visible]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (evt: KeyboardEvent) => {
    if (evt.key == 'Enter') {
      evt.preventDefault();
      closeModalLeaderBoard({ setModalLeaderBoard });
    }
  };

  useEffect(() => {
    if (selectedClass) {
      getBoardList();
    }
  }, [selectedClass]);

  const onClickItem = useCallback(
    (idx: number | string) => {
      setSelectedClass(filterClasses[+idx]);
    },
    [filterClasses],
  );

  const dropdownTitle = useMemo(() => {
    if (selectedClass) return selectedClass.name;
    else return '클래스';
  }, [selectedClass]);

  const getBoardList = async () => {
    let url = `/customers/${userStateData.customer_id}/board/month/${dayjs().format('YYYY-MM')}?class_id=${
      selectedClass?.id
    }`;

    if (userStateData.product == 'F' || [1, 2, 3, 4, 5].includes(userStateData.id)) {
      url = `/customers/${userStateData.customer_id}/board/month/${dayjs().format('YYYY-MM')}`;
    }

    const res = await fetchGetApi(url);
    if (res.result && res.data.data.length > 0) {
      const res_data = res.data.data;
      const table_itmes: TableItemsType[] = [];
      for (let i = 0; i < res_data.length; i++) {
        table_itmes.push({
          student_id: res_data[i].student_id,
          ranking: res_data[i].ranking,
          name: res_data[i].name,
          class_name: res_data[i].class_name,
          study_count: res_data[i].study_count + '일',
          studytime: Math.round(+res_data[i].studytime / 60) + '분',
          words_count: res_data[i].words_count + '개',
          score: res_data[i].score + '점',
        });
      }
      setTableItems([...table_itmes]);
    } else {
      setTableItems([]);
    }
  };

  const onClick = () => {
    closeModalLeaderBoard({ setModalLeaderBoard });

    if (userStateData.default_profile == '0' && userStateData.profile_type == 'default') {
      openModalDefaultProfileSetting({ setModalDefaultProfileSetting });
    }
  };

  return (
    <Modal
      open={visible}
      onClose={() => ''}
      sx={{
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Box sx={style}>
        <StyledLeaderboardModalWrapper>
          <StyledModalBody>
            <StyledModalTitle>
              <Box
                sx={{
                  ...d_flex_start,
                  flex: '1',
                }}
              >
                <DropdownCircle
                  disabled={classNames.length < 2}
                  type='custom'
                  contents={classNames}
                  onClickItem={onClickItem}
                  title={dropdownTitle}
                />
              </Box>
              <Box sx={{ flex: '2', margin: '0 auto' }}>리더보드 순위 ({today_ym})</Box>
              <Box sx={{ flex: '1' }}></Box>
            </StyledModalTitle>
            <Box sx={{ overflowY: 'auto', height: '45vh', paddingBottom: '1rem', maxHeight: '45vh' }}>
              <Table
                fields={tableFields}
                items={tableItems}
                emptyText='조회된 리더보드 내역이 없습니다.'
                dataKey='modal_leaderboard'
                onChangeTable={(query: TableQueryType) => {
                  setTableQuery(query);
                }}
                pagination={false}
                count={0}
              />
            </Box>
          </StyledModalBody>
          <StyledModalUtil onClick={onClick}>
            <CheckButton>확인</CheckButton>
          </StyledModalUtil>
        </StyledLeaderboardModalWrapper>
      </Box>
    </Modal>
  );
}

export default ModalLeaderboard;
