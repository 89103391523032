import { Modal, Wrapper, Wrap, Header, HeaderIconWrap, Body, ChoiceCard } from './ModalSelectCard.Styles';
import { Box } from '@mui/material';
import { IoCloseSharp } from 'react-icons/io5';
import { ModalSelectCardContentType } from '../../../recoil/common/modalSelectCard';
import React from 'react';

export interface ModalStudySettingVAProps {
  visible: boolean;
  onClose: () => void;
  onClickClose: () => void;
  title: string;
  type: string;
  contents: ModalSelectCardContentType[];
  value?: number;
  onSuccess: (idx: number) => void;
}

export const ModalSelectCardUI = React.memo(function ModalSelectCardUI({
  visible,
  onClose,
  onClickClose,
  type,
  title,
  contents,
  value,
  onSuccess,
}: ModalStudySettingVAProps) {
  return (
    <Modal open={visible} onClose={onClose}>
      <Wrapper>
        <Wrap>
          <Header>
            <Box component={'span'} dangerouslySetInnerHTML={{ __html: title }} />
            <HeaderIconWrap onClick={onClickClose}>
              <IoCloseSharp />
            </HeaderIconWrap>
          </Header>
          <Body className={`${value && value !== 0 ? 'selected' : ''}`}>
            {contents?.map((content, cIdx) => (
              <ChoiceCard
                key={`content_${cIdx}`}
                onClick={() => onSuccess(cIdx)}
                className={`${cIdx + 1 === value ? 'selected' : ''}`}
                sx={{ width: `calc(${Math.round(100 / contents.length)}% - 0.5rem)` }}
              >
                <Box>
                  <img style={{ transform: type == 'printType' ? 'scale(0.6)' : '' }} src={content.src} alt='image_1' />
                </Box>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.content,
                  }}
                />
              </ChoiceCard>
            ))}
          </Body>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
