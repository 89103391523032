import { TutorialUI } from './Tutorial.Presenter';
import {
  memorize_tutorial,
  basic_tutorial,
  advanced_tutorial,
  CHECK,
  VIDEO,
  speak_tutorial,
} from '../../../utils/constants';
import { useMemo, useState, useCallback, useContext, useEffect } from 'react';
import { fetchPostApi, fetchPutApi } from '../../../utils/api';
import { DICTATION, FLASHCARD, POPQUIZ, SPEAKING, WORDBINGO, WRITING } from '../../../utils/constants';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../../../recoil/model/user';
import { learningState } from '../../../recoil/model/learning';
import { tutorialStateData, closeTutorial } from '../../../recoil/common/tutorial';
import { deviceState } from '../../../recoil/common/device';
import { ModalContext } from '../../../provider/ModalProvider';

export function TutorialContainer() {
  const setTutorialState = useSetRecoilState(tutorialStateData);
  const [learningStateData, setLearningStateData] = useRecoilState(learningState);
  const { modal_alert } = useContext(ModalContext);
  const [userStateData, setUserStateData] = useRecoilState(userState);
  const [currentStep, setCurrentStep] = useState(0);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);

  const { show_tutorial } = userStateData;
  const { is_mobile } = deviceStateData;
  const { learning_type, mod, current_page } = learningStateData;

  if (learning_type == null || learning_type == 5) return null;

  // keyboard Event bind & unbind
  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (e.keyCode == 37) {
      // 왼쪽 클릭
      setCurrentStep(prev => (prev - 1 > -1 ? prev - 1 : 0));
    } else if (e.keyCode == 39) {
      // 오른쪽 클릭
      setCurrentStep(prev => (prev + 1 > tutorialArray.length - 1 ? tutorialArray.length - 1 : prev + 1));
    }
  };

  useEffect(() => {
    bindKeyboard();
    console.log('bind');
    return () => {
      unbindKeyboard();
      console.log('undbind');
    };
  }, []);

  const tutorialArray = useMemo(() => {
    switch (learning_type) {
      case 1:
      case 2:
        return memorize_tutorial;
      case 3:
        return basic_tutorial;
      case 4:
        return advanced_tutorial;
      default:
        return speak_tutorial;
    }
  }, [learning_type]);

  const currentTutorial = useMemo(() => {
    return tutorialArray[currentStep];
  }, [tutorialArray, currentStep]);

  const visibleLeftButton = useMemo(() => {
    return currentStep != 0;
  }, [tutorialArray, currentStep]);

  const visibleRightButton = useMemo(() => {
    return currentStep != tutorialArray.length - 1;
  }, [tutorialArray, currentStep]);

  const onClickLeftButton = useCallback(() => {
    setCurrentStep(prev => prev - 1);
  }, []);

  const onClickRightButton = useCallback(() => {
    setCurrentStep(prev => prev + 1);
  }, []);

  const updateShowTutorialUri = useMemo(() => {
    return `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/tutorial`;
  }, []);

  const endTutorial = useCallback(() => {
    console.log('show_tutorial', show_tutorial);
    const new_show_tutorial: ShowTutorial = {
      ...show_tutorial,
    };
    let alert_type = '';
    let target = undefined;
    if ([1, 2].includes(learning_type)) {
      alert_type = 'memorize_start';
      new_show_tutorial['memorize'] = false;
    } else if ([3].includes(learning_type)) {
      switch (mod[current_page].module) {
        case FLASHCARD:
          alert_type = 'flashcard_start';
          break;
        case WORDBINGO:
          alert_type = 'wordbingo_start';
          break;
        case POPQUIZ:
          alert_type = 'popquiz_start';
          target = '단어를 선택';
          break;
      }
      new_show_tutorial['basic'] = false;
    } else if ([4].includes(learning_type)) {
      switch (mod[current_page].module) {
        case DICTATION:
          alert_type = 'dictation_start';
          break;
        case WRITING:
          alert_type = 'writing_start';
          break;
        case SPEAKING:
          if (is_mobile) {
            alert_type = 'speaking_start';
          } else {
            alert_type = 'pc_speaking_start';
          }
          break;
      }
      new_show_tutorial['advanced'] = false;
    } else if ([6].includes(learning_type)) {
      switch (mod[current_page].module) {
        case VIDEO:
          alert_type = 'speak_video_start';
          break;
        case CHECK:
          alert_type = 'speak_check_start';
          break;
        case SPEAKING:
          if (is_mobile) {
            alert_type = 'speak_speaking_start';
          } else {
            alert_type = 'speak_speaking_start';
          }
          break;
      }
      new_show_tutorial['speak'] = false;
    }

    const tutorial_container_dom = document.getElementById('tutorial-container');
    if (tutorial_container_dom) {
      tutorial_container_dom.style.display = 'none';
    }

    modal_alert.openModalAlert(alert_type, undefined, target, () => {
      closeTutorial({ setTutorialState });
      setUserStateData(prevState => ({
        ...prevState,
        show_tutorial: new_show_tutorial,
      }));
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
    });
  }, [show_tutorial, learning_type, current_page, mod]);

  const onClickTutorialExit = useCallback(async () => {
    try {
      let cur_show_tutorial = false;

      const args: { [key: string]: string } = {};
      if ([1, 2].includes(learning_type)) {
        args['mode'] = 'memorize';
        cur_show_tutorial = show_tutorial['memorize'];
      } else if ([3].includes(learning_type)) {
        args['mode'] = 'basic';
        cur_show_tutorial = show_tutorial['basic'];
      } else if ([4].includes(learning_type)) {
        args['mode'] = 'advanced';
        cur_show_tutorial = show_tutorial['advanced'];
      } else if ([6].includes(learning_type)) {
        args['mode'] = 'speak';
        cur_show_tutorial = show_tutorial['speak'];
      }

      if (cur_show_tutorial) {
        if (userStateData.product == 'F') {
          endTutorial();
        } else {
          const res = await fetchPostApi(updateShowTutorialUri, args);
          if (res && res.result) {
            endTutorial();
          }
        }
      } else {
        // 설정 모달에서 클릭 해서 튜토리얼 띄우는 경우로 이때는 모달 띄울 필요 x
        closeTutorial({ setTutorialState });
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  }, [learning_type, endTutorial, show_tutorial]);

  const onClickNeverLookTutorial = useCallback(async () => {
    const res = await fetchPutApi(updateShowTutorialUri, {});
    if (res && res.result) {
      endTutorial();
    }
  }, [endTutorial]);

  const isFreeCenter = useMemo(() => {
    return userStateData.product == 'F';
  }, [userStateData.product]);

  const curShowTutorial = useMemo(() => {
    let mode: 'memorize' | 'basic' | 'advanced';
    if ([1, 2].includes(learning_type)) {
      mode = 'memorize';
    } else if ([3].includes(learning_type)) {
      mode = 'basic';
    } else {
      mode = 'advanced';
    }
    return show_tutorial[mode];
  }, [show_tutorial, learning_type]);

  const props = {
    currentKey: currentTutorial.key,
    tutorialImage: currentTutorial.tutorialImage,
    explainText: currentTutorial.explainText,
    totalStep: tutorialArray.length,
    curShowTutorial,
    isFreeCenter,
    currentStep,
    visibleLeftButton,
    visibleRightButton,
    onClickLeftButton,
    onClickRightButton,
    onClickTutorialExit,
    onClickNeverLookTutorial,
  };

  return <TutorialUI {...props} />;
}
