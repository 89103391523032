import React from 'react';

interface IconReportProps {
  seq_id: string;
}

const IconReportX = (props: IconReportProps) => {
  const seq_id = props.seq_id;
  return (
    <svg
      width='80'
      height='80'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 80 80'
      xmlSpace='preserve'
    >
      <defs>
        <linearGradient id={seq_id} x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor='#ffbfb9'>
            <animate attributeName='stop-color' values='#ffbfb9; #fc4d41; #ffbfb9' dur='10s' repeatCount='indefinite' />
          </stop>
          <stop offset='100%' stopColor='#fc4d41'>
            <animate attributeName='stop-color' values='#fc4d41; #ffbfb9; #fc4d41' dur='10s' repeatCount='indefinite' />
          </stop>
        </linearGradient>
      </defs>
      <g>
        <rect fill='none' id='canvas_background' height='82' width='82' y='-1' x='-1' />
        <g display='none' overflow='visible' y='0' x='0' height='100%' width='100%' id='canvasGrid'>
          <rect fill='url(#gridpattern)' strokeWidth='0' y='0' x='0' height='100%' width='100%' />
        </g>
      </g>
      <g>
        <ellipse
          id='svg_4'
          cy='50.453125'
          cx='73'
          fillOpacity='null'
          strokeOpacity='null'
          strokeWidth='11'
          stroke='#000'
          fill='#fff'
        />
        <line
          stroke={`url(#${seq_id})`}
          strokeLinecap='round'
          id='svg_11'
          y2='67.476268'
          x2='67.023143'
          y1='13.453125'
          x1='13'
          fillOpacity='null'
          strokeOpacity='null'
          strokeWidth='18'
          fill='none'
        />
        <line
          stroke={`url(#${seq_id})`}
          strokeLinecap='round'
          id='svg_13'
          y2='67'
          x2='13'
          y1='13.453123'
          x1='67'
          fillOpacity='null'
          strokeOpacity='null'
          strokeWidth='18'
          fill='none'
        />
      </g>
    </svg>
  );
};

export default IconReportX;
