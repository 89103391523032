import { styled, Box, Checkbox as MuiCheckbox, IconButton as MuiIconButton, BoxProps } from '@mui/material';
import { d_flex_center } from 'styles/common';

export const SignupContainer = styled(Box)({
  width: '100%',
  height: '100%',
  lineHeight: '1.5',
  fontFamily: 'Apple SD Gothic Neo',
  '& > *': {
    fontFamily: 'Apple SD Gothic Neo',
  },
});

export const SignupWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '6% 5%',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: '1.5',
  fontFamily: 'Apple SD Gothic Neo',

  transform: 'scale(1.1)',
  '@media (min-width: 1266px) and (max-width: 1905px)': {
    transform: 'scale(1.6)',
  },

  '@media (min-width: 962px) and (max-width: 1265px)': {
    transform: 'scale(1.4)',
  },

  '@media (min-width: 770px) and (max-width: 961px)': {
    transform: 'scale(1.35)',
  },

  '@media (max-width: 769px)': {
    transform: 'scale(1.15)',
  },
});

export const SignupTitle = styled('h1')({
  color: 'rgb(75, 85, 99)',
  fontWeight: '600',
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  textAlign: 'center',
  fontFamily: 'Apple SD Gothic Neo',
});

export const SignupForm = styled(Box)({
  border: '2px solid rgba(241, 241, 241, 0.5)',
  padding: '2rem',
  backgroundColor: '#fff',
  maxWidth: '28rem',
  width: '100%',
  lineHeight: '1.5',
  fontFamily: 'Apple SD Gothic Neo',
});

//! step 1
export const SignupFirstRow = styled(Box)({
  marginBottom: '1rem',
  lineHeight: '1.5',
  '& > div': {
    fontFamily: 'Apple SD Gothic Neo',
    lineHeight: '1.5',
    '&:first-of-type': {
      display: 'flex',
      justifyContent: 'space-between',
      whiteSpace: 'nowrap',
      color: 'rgb(156,163,175)',
      fontWeight: '600',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      marginBottom: '0.5rem',
      h4: {
        fontSize: '0.75rem',
        lineHeight: '1.5',
        fontFamily: 'Apple SD Gothic Neo',
        fontWeight: '600',
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bolder',
        lineHeight: '1.5',
        fontFamily: 'Apple SD Gothic Neo',
        h3: {
          fontSize: '0.75rem',
          lineHeight: '1.5',
          fontFamily: 'Apple SD Gothic Neo',
          fontWeight: '600',
        },
        span: {
          fontWeight: '600',
        },
      },
    },
    '&:nth-of-type(2)': {
      width: '100%',
      borderRadius: '9999px',
      '& > div': {
        fontFamily: 'Apple SD Gothic Neo',
        width: '50%',
        color: 'rgb(147,51, 234)',
        height: '0.375rem',
        fontWeight: '500',
        fontSize: '0.75rem',
        lineHeight: '1rem',
        textAlign: 'center',
        backgroundColor: 'rgb(192, 132, 252)',
        borderRadius: '9999px',
      },
    },
  },
});

export const SignupLabelRow = styled(Box)({
  color: 'rgb(75, 85, 99)',
  fontWeight: '600',
  marginBottom: '0.25rem',
  lineHeight: '1.5',
  fontFamily: 'Apple SD Gothic Neo',
});

export const SignupExplainRow = styled(Box)({
  color: 'rgb(156, 163, 175)',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  marginBottom: '0.5rem',
  fontFamily: 'Apple SD Gothic Neo',
  '& > span': {
    color: 'rgb(192, 132, 252)',
    fontWeight: '500,',
    lineHeight: '1.5',
    fontFamily: 'Apple SD Gothic Neo',
  },
});

export const PhoneInput = styled('input')({
  marginTop: '0.5rem',
  marginBottom: '0.7rem',
  whiteSpace: 'nowrap',
  color: 'rgb(75, 85, 99)',
  padding: '0.5rem',
  backgroundColor: '#e4e4e4',
  borderRadius: '0.5rem',
  cursor: 'not-allowed',
  width: '100%',
  fontWeight: '500',
  lineHeight: '1.5',
  fontSize: '1rem',
  outline: 'none',
  border: 'none',
  fontFamily: 'Apple SD Gothic Neo',
});

export const ErrorText = styled(Box)({
  color: 'rgb(239, 68, 68)',
  lineHeight: '1.5',
  fontFamily: 'Apple SD Gothic Neo',
  fontWeight: '500',
});

export const QuestionRow = styled(Box)({
  fontSize: '0.75rem',
  lineHeight: '1rem',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
  fontFamily: 'Apple SD Gothic Neo',
  fontWeight: '300',

  '& > svg': {
    color: 'rgb(156, 163, 175)',
    fontWeight: '700',
    cursor: 'pointer',
    marginBottom: '0.5rem',
  },

  '& > span': {
    color: 'rgb(156, 163, 175)',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    cursor: 'pointer',
    marginBottom: '0.5rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    fontFamily: 'Apple SD Gothic Neo',
  },
});

export const CertButton = styled('button')({
  color: '#fff',
  padding: '0.5rem',
  backgroundColor: 'rgb(83, 21, 255)',
  borderRadius: '0.5rem',
  width: '100%',
  cursor: 'pointer',
  border: 'none',
  fontSize: '1rem',
  lineHeight: '1.5',
  fontFamily: 'Apple SD Gothic Neo',
  fontWeight: '500',
});

//! step2 + 이미 등록되어 있는 케이스
export const SignupFirstRow2 = styled(Box)({
  marginBottom: '1rem',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      '&:first-of-type': {
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        color: 'rgb(156, 163, 175)',
        fontWeight: '600',
        fontSize: '0.75rem',
        lineHeight: '1rem',
        marginBottom: '0.5rem',
        h2: {
          fontSize: '0.9rem',
          color: 'rgb(75, 88, 99)',
          fontWeight: '600',
          margin: '0',
          fontFamily: 'Apple SD Gothic Neo',
        },
        '& > div': {
          display: 'flex',
          justifyContent: 'space-between',
          whiteSpace: 'nowrap',
          color: 'rgb(156, 163, 175)',
          fontWeight: '600',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontFamily: 'Apple SD Gothic Neo',
          h3: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontFamily: 'Apple SD Gothic Neo',
            fontWeight: '600',
          },
          span: {
            fontWeight: '600',
            lineHeight: '1rem',
            fontFamily: 'Apple SD Gothic Neo',
          },
        },
      },
      '&:nth-of-type(2)': {
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        color: 'rgb(156, 163, 175)',
        fontWeight: '600',
        fontSize: '0.75rem',
        lineHeight: '1rem',
        marginBottom: '0.5rem',
        fontFamily: 'Apple SD Gothic Neo',
      },
    },
  },
});

export const SignupInfoBox = styled(Box)({
  background: 'rgb(249, 246, 255)',
  maxHeight: '50px',
  padding: '1rem 0',
  borderRadius: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  display: 'flex',
  h4: {
    color: 'rgba(83, 21, 255, 0.8)',
    fontWeight: '600',
    textAlign: 'center',
    margin: '0',
    fontSize: '1rem',
    fontFamily: 'Apple SD Gothic Neo',
  },
  p: {
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
    margin: '0',
    fontFamily: 'Apple SD Gothic Neo',
  },
});

export const SignupExplainRow2 = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  svg: {
    fontWeight: '800',
    color: 'rgb(156, 163, 175)',
  },
  span: {
    color: 'rgb(156, 163, 175)',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    fontFamily: 'Apple SD Gothic Neo',
  },
});

export const LoginButton = styled('button')({
  background: 'rgb(83, 21, 255)',
  color: '#fff',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  width: '100%',
  cursor: 'pointer',
  fontFamily: 'Apple SD Gothic Neo',
  fontSize: '1rem',
  lineHeight: '1.5',
  border: 'none',
});

//! step2 + 신규가입
export const Input = styled('input')({
  outline: 'none',
  whiteSpace: 'nowrap',
  padding: '0.7rem',
  color: 'rgb(156, 163, 175)',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1rem',
  borderRadius: '0.5rem',
  width: '100%',
  border: '1px solid #e5e7eb',
  '&::placeholder': {
    fontWeight: '400',
    fontSize: '1rem',
    paddingLeft: '0.25rem',
    paddingBottom: '0',
    color: '#e4e4e4',
  },
});

export const CheckboRow = styled(Box)({
  display: 'flex',
  h3: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.25rem',
    span: {
      textDecoration: 'underline',
      color: 'rgb(83, 21, 255)',
      fontWeight: '800',
      cursor: 'pointer',
      fontFamily: 'Apple SD Gothic Neo',
    },
    fontWeight: '300',
    fontFamily: 'Apple SD Gothic Neo',
  },
});

export const Checkbox = styled(MuiCheckbox)({
  '&.Mui-checked': {
    color: '#4d4eff', // 체크된 상태의 색상
  },
  '&.MuiCheckbox-root': {
    width: '1.5rem', // 너비
    height: '1.5rem', // 높이
  },
  '& .MuiSvgIcon-root': { fontSize: '1.25rem' },
});

export const SignupButton = styled('button')({
  color: '#fff',
  padding: '0.5rem',
  backgroundColor: '#e4e4e4',
  borderRadius: '0.5rem',
  width: '100%',
  textTransform: 'none',
  border: 'none',
  fontFamily: 'Apple SD Gothic Neo',
  fontSize: '1rem',
  lineHeight: '1.5',
});

export const Errormsg = styled(Box)({
  color: 'rgb(239, 68, 68)',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: '600',
});

//* 뒤로가기 버튼 추가 (24.08.21)
interface MobileProps extends BoxProps {
  is_mobile: number;
}

export const BackButtonBox = styled(Box)<MobileProps>(props => ({
  position: 'absolute',
  top: '45%',
  left: '7.5%',
  transform: 'translateY(50%)',
  ...d_flex_center,

  '@media (min-width: 1266px) and (max-width: 1905px)': {
    left: '20%',
    top: '45%',
  },

  '@media (min-width: 962px) and (max-width: 1265px)': {
    left: '16%',
    top: '45%',
  },

  '@media (min-width: 770px) and (max-width: 961px)': {
    left: '15%',
    top: '45%',
  },

  '@media (max-width: 769px)': {
    left: '10%',
    top: '45%',
  },
}));

export const IconButton = styled(MuiIconButton)({
  backgroundColor: 'transparent',
  width: '3rem',
  height: '3rem',
  padding: '0',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
});
