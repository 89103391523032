import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';

import ModalDefault from './ModalDefault';

import Button from '../button/Button';

import { d_flex_center, d_flex_end, dir_column, d_flex_space_between, dir_row, d_flex } from '../../styles/common';
import { styled, Box, IconButton } from '@mui/material';

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';

interface ModalDateRangePickerProps {
  visible: boolean;
  onClose: (sdate: Date, edate: Date) => void;
  sdate?: string;
  edate?: string;
}

const StyledModalDateRangePicker = styled(Box)(props => ({
  minWidth: '55rem',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '1rem',
  ...d_flex_center,
  ...dir_column,
}));

const StyledTitle = styled(Box)(props => ({
  width: '96%',
  height: '4rem',
  color: props.theme.palette.purple.main,
  padding: '0 1rem',
  borderBottom: `1px solid #f8f6fe`,
  ...d_flex_end,
  ...dir_row,
}));

const StyledTitleBox = styled(Box)(props => ({
  ...d_flex,
  alignItems: 'center',
  minWidth: '50%',
  color: props.theme.palette.purple.main,
  fontWeight: '600',
  border: `1px solid #efefef`,
  borderRadius: '3px',
  backgroundColor: '#f8f6fe',
  margin: '0 4px',
  lineHeight: '2.2rem',
  '& > svg': {
    color: props.theme.palette.purple.main,
    width: '1.4rem',
    height: '1.4rem',
    margin: '0 4px 0 12px',
  },
}));

const StyledBody = styled(Box)(props => ({
  width: '100%',
  ...d_flex_center,
  '& > div': {
    width: '100%',
  },
}));

const CustomHeaderBox = styled(Box)(props => ({
  ...d_flex_space_between,
  color: props.theme.palette.purple.main,
  padding: '0.7rem 0 0.7rem 0',
  fontSize: '1.2rem',
  fontWeight: 'bolder',
}));

const CustomHeaderIconButton = styled(IconButton)(props => ({
  backgroundColor: 'white',
  width: '2rem',
  height: '2rem',
  transform: 'scale(0.75)',
  flex: '15%',
  svg: {
    color: props.theme.palette.purple.main,
    width: '1rem',
    height: '1rem',
    strokeWidth: '1rem',
    transform: 'scale(1.3)',
  },
  '&.Mui-disabled > svg': {
    color: props.theme.palette.gray_2.main,
  },
}));

const CustomIconButton = styled(IconButton)(props => ({
  backgroundColor: 'white',
  svg: {
    color: props.theme.palette.purple.main,
    width: '1rem',
    height: '1rem',
    strokeWidth: '1rem',
  },
  '&.Mui-disabled > svg': {
    color: props.theme.palette.gray_2.main,
  },
}));

const CustomButton = styled(Button)(props => ({
  width: '100%',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  background: 'linear-gradient(#652ae9, #4620e9)',
  color: props.theme.palette.white.main,
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  borderBottomLeftRadius: '1rem',
  borderBottomRightRadius: '1rem',
  '&:hover': {
    background: 'linear-gradient(#652ae9, #4620e9)',
    color: props.theme.palette.white.main,
  },
}));

function ModalDateRangePicker(props: ModalDateRangePickerProps) {
  const visible = props.visible;
  const onClose = props.onClose;
  const sdate = props.sdate ? props.sdate : dayjs().startOf('M').format();
  const edate = props.edate ? props.edate : dayjs().format();
  const [startDate, setStartDate] = useState<Date>(new Date(dayjs(sdate).format()));
  const [endDate, setEndDate] = useState<Date>(new Date(dayjs(edate).format()));
  const maxDate = new Date(dayjs().format());
  const [showPrevMonth, setShowPrevMonth] = useState<boolean>(true);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setShowPrevMonth(false);
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <ModalDefault visible={visible} onClose={() => ''}>
        <StyledModalDateRangePicker>
          <StyledTitle>
            <Box sx={{ width: 'auto', minWidth: '50%', ...d_flex }}>
              <StyledTitleBox>
                <BiCalendar />
                <Box component={'span'} sx={{ margin: '0 4px', flex: '50%', fontWeight: '500' }}>
                  {dayjs(startDate).format('ddd, D MMM')}
                </Box>
                <Box sx={{ ...d_flex, flex: '35%' }}>
                  <CustomHeaderIconButton
                    onClick={() => onChange([new Date(dayjs(startDate).subtract(1, 'days').format()), endDate])}
                    disableRipple
                  >
                    <AiOutlineLeft />
                  </CustomHeaderIconButton>
                  <CustomHeaderIconButton
                    onClick={() => onChange([new Date(dayjs(startDate).add(1, 'days').format()), endDate])}
                    disableRipple
                    disabled={dayjs(startDate).format('YYYY-MM-DD') >= dayjs(endDate).format('YYYY-MM-DD')}
                  >
                    <AiOutlineRight />
                  </CustomHeaderIconButton>
                </Box>
              </StyledTitleBox>
              <StyledTitleBox>
                <BiCalendar />
                <Box component={'span'} sx={{ margin: '0 4px', flex: '50%', fontWeight: '500' }}>
                  {endDate ? dayjs(endDate).format('ddd, D MMM') : dayjs(startDate).format('ddd, D MMM')}
                </Box>
                <Box sx={{ ...d_flex, flex: '35%' }}>
                  <CustomHeaderIconButton
                    onClick={() => setEndDate(new Date(dayjs(endDate).subtract(1, 'days').format()))}
                    disableRipple
                    disabled={!endDate || dayjs(startDate).format('YYYY-MM-DD') >= dayjs(endDate).format('YYYY-MM-DD')}
                  >
                    <AiOutlineLeft />
                  </CustomHeaderIconButton>
                  <CustomHeaderIconButton
                    onClick={() => setEndDate(new Date(dayjs(endDate).add(1, 'days').format()))}
                    disableRipple
                    disabled={dayjs(endDate).format('YYYY-MM-DD') >= dayjs(maxDate).format('YYYY-MM-DD')}
                  >
                    <AiOutlineRight />
                  </CustomHeaderIconButton>
                </Box>
              </StyledTitleBox>
            </Box>
          </StyledTitle>
          <StyledBody>
            <DatePicker
              renderCustomHeader={({
                monthDate,
                decreaseMonth,
                increaseMonth,
                customHeaderCount,
                nextMonthButtonDisabled,
              }) => (
                <CustomHeaderBox>
                  <CustomIconButton
                    sx={{ visibility: customHeaderCount === 1 ? 'hidden' : null }}
                    onClick={decreaseMonth}
                    disableRipple
                  >
                    <AiOutlineLeft />
                  </CustomIconButton>
                  <Box>
                    <Box component={'span'} sx={{ cursor: 'default' }}>
                      {dayjs(monthDate).format('MMM YYYY')}
                    </Box>
                  </Box>
                  <CustomIconButton
                    sx={{ visibility: customHeaderCount === 0 ? 'hidden' : null }}
                    onClick={increaseMonth}
                    disableRipple
                    disabled={nextMonthButtonDisabled}
                  >
                    <AiOutlineRight />
                  </CustomIconButton>
                </CustomHeaderBox>
              )}
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              inline
              // calendarStartDay={1}
              monthsShown={2}
              useWeekdaysShort={true}
              dayClassName={date =>
                dayjs(date).format('ddd') == 'Sat' ? 'saturday' : dayjs(date).format('ddd') == 'Sun' ? 'sunday' : ''
              }
              calendarClassName={'rangepicker'}
              selectsRange
              maxDate={maxDate}
              showPreviousMonths={showPrevMonth}
            />
          </StyledBody>
          <CustomButton onClick={() => onClose(startDate, endDate)}>확인</CustomButton>
        </StyledModalDateRangePicker>
      </ModalDefault>
    </>
  );
}

export default ModalDateRangePicker;
