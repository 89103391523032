import { atom, SetterOrUpdater } from 'recoil';

export interface ModalHiddenMissionType {
  visible: boolean;
  isFirstPopup: boolean;
  closeCallback?: () => void;
}

export const modalHiddenMissionState = atom<ModalHiddenMissionType>({
  key: 'modalHiddenMissionState',
  default: {
    visible: false,
    isFirstPopup: true,
  },
});

interface ModalHiddenMissionSetter {
  setModalHiddenMission: SetterOrUpdater<ModalHiddenMissionType>;
}

export const openModalHiddenMission = (
  { setModalHiddenMission }: ModalHiddenMissionSetter,
  isFirstPopup: boolean,
  closeCallback?: () => void,
) => {
  setModalHiddenMission(prev => ({
    ...prev,
    visible: true,
    isFirstPopup,
    closeCallback,
  }));
};

export const closeModalHiddenMission = ({ setModalHiddenMission }: ModalHiddenMissionSetter, callback?: () => void) => {
  setModalHiddenMission({
    visible: false,
    isFirstPopup: false,
  });
  if (callback) {
    setTimeout(() => {
      callback();
    }, 0);
  }
};
