export const superServerName =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SUPER_REAL_HOST
    : process.env.REACT_APP_SUPER_DEV_HOST;

export const servername =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_REAL_HOST
    : process.env.REACT_APP_LOCAL === 'false'
    ? process.env.REACT_APP_DEV_HOST
    : process.env.REACT_APP_LOCAL_HOST;

import { VOICE_TTS_URL } from './constants';

function setHeader(no_content_type?: boolean) {
  const authToken = sessionStorage.getItem('authToken');
  const refreshToken = sessionStorage.getItem('refreshToken');

  const header: { [key: string]: string } = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (no_content_type) {
    delete header['Content-Type'];
  }

  if (authToken) {
    header['Authorization'] = `Bearer ${authToken}`;
  }

  if (refreshToken) {
    header['RefreshToken'] = `Bearer ${refreshToken}`;
  }

  return header;
}

function setToken(response: Response) {
  const authToken = response.headers.get('Authorization')?.substring(7);
  const refreshToken = response.headers.get('RefreshToken')?.substring(7);

  if (authToken) {
    sessionStorage.setItem('authToken', authToken);
  }

  if (refreshToken) {
    sessionStorage.setItem('refreshToken', refreshToken);
  }
}

function setUri(uri: string) {
  const new_servername = servername;

  return new_servername + uri;
}

// Fetch POST
export const fetchPostApi = async function (uri: string, args: object, use_vali?: boolean) {
  try {
    const response = await fetch(setUri(uri), {
      method: 'POST',
      headers: {
        ...setHeader(),
      },
      body: JSON.stringify(args),
      credentials: 'include',
    });
    if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

    const responseJson = await response.json();

    return responseJson;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Fetch Raw POST
export const fetchRawPostApi: any = async function (uri: string, args: object, use_vali?: boolean) {
  try {
    const response = await fetch(setUri(uri), {
      method: 'POST',
      headers: {
        ...setHeader(),
      },
      body: JSON.stringify(args),
      credentials: 'include',
    });
    if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Fetch GET Super
export const fetchSuperGetApi = async function (uri: string) {
  const request_url = superServerName + uri;
  const response = await fetch(request_url, {
    method: 'GET',
    credentials: 'include',
  });

  const responseJson = await response.json();

  return responseJson;
};

// Fetch POST Super
export const fetchSuperPostApi = async function (uri: string, arg = {}) {
  const request_url = superServerName + uri;
  const response = await fetch(request_url, {
    method: 'POST',
    body: JSON.stringify(arg),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseJson = await response.json();

  return responseJson;
};

// Fetch GET
export const fetchGetApi = async function (uri: string) {
  const response = await fetch(setUri(uri), {
    method: 'GET',
    headers: {
      ...setHeader(),
    },
    credentials: 'include',
  });

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  const responseJson = await response.json();

  return responseJson;
};

// Fetch DELETE
export const fetchDeleteApi = async function (uri: string, args?: object) {
  const response = await fetch(setUri(uri), {
    method: 'DELETE',
    headers: {
      ...setHeader(),
    },
    body: JSON.stringify(args),
    credentials: 'include',
  });

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  const responseJson = await response.json();

  return responseJson;
};

// Fetch PATCH
export const fetchPatchApi = async function (uri: string, args: { target: string; value: string | number }) {
  const response = await fetch(setUri(uri), {
    method: 'PATCH',
    headers: {
      ...setHeader(),
    },
    body: JSON.stringify(args),
    credentials: 'include',
  });

  const responseJson = await response.json();

  return responseJson;
};

// Fetch PUT
export const fetchPutApi = async function (uri: string, args: object) {
  const response = await fetch(setUri(uri), {
    method: 'PUT',
    headers: {
      ...setHeader(),
    },
    body: JSON.stringify(args),
    credentials: 'include',
  });

  if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

  const responseJson = await response.json();

  return responseJson;
};

// Fetch POST FILES
export const fetchFileApi = async function (uri: string, args: FormData) {
  try {
    const response = await fetch(setUri(uri), {
      method: 'POST',
      headers: {
        ...setHeader(true),
      },
      body: args,
    });

    if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Fetch GET AUDIO FILES
export const fetchGetAudioApi = async function (uri: string) {
  try {
    const response = await fetch(setUri(uri), {
      method: 'GET',
      headers: {
        ...setHeader(),
      },
      credentials: 'include',
    });

    if (response.headers.get('Authorization') || response.headers.get('RefreshToken')) setToken(response);

    const responseBlob = await response.blob();

    return responseBlob;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fetchCreateTTSApi = function (args: { text: string; gender: string; mp3name: string }) {
  try {
    const response = fetch(VOICE_TTS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(args),
    });
    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// Tabstudy Fetch GET
export const fetchTabStudyGetApi = async function (uri: string) {
  const tab_dev_host = 'https://dev.tabstudy.co.kr';
  const tab_real_host = 'https://api.tabstudy.co.kr';

  const host = process.env.REACT_APP_NODE_ENV === 'production' ? tab_real_host : tab_dev_host;
  try {
    const response = await fetch(`${host}/${uri}`);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
    return false;
  }
};
