import React, { useContext } from 'react';
import Tab from '@mui/material/Tab';
import { TabContext, TabList } from '@mui/lab';
import { styled, Box } from '@mui/material';
import { ModalContext } from '../../provider/ModalProvider';

const CustomRootBox = styled(Box)(props => ({
  '& .MuiBox-root': {
    alignItems: 'center',
    borderWidth: '2px',
    borderColor: props.theme.palette.light_orange_2.main,
  },
}));

const CustomBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const CustomTabList = styled(TabList)(props => ({
  minHeight: '3.15rem',
  maxHeight: '3.15rem',
  '.MuiTabs-scroller': {
    '.MuiTabs-flexContainer': {
      width: '35.4rem',
      '.MuiButtonBase-root': {
        '&::after': {
          content: '""',
          borderRight: '0.85rem solid transparent',
          position: 'absolute',
          right: '-0.725rem',
          bottom: 0,
          display: 'block',
          zIndex: 10,
          borderBottom: 'calc(2.15rem + 1px) solid',
          borderBottomColor: '#eef0f1',
        },
        overflow: 'initial',
        position: 'relative',
        border: 'none',
        borderRadius: '0.375rem 1.5rem 0 0',
        flexDirection: 'row',
        width: '14.5rem',
        height: '3.15rem',
        backgroundColor: '#eef0f1',
        fontSize: '1.25rem',
        color: 'var(--gray-2)',
        padding: '0.45rem 0.75rem 0.25rem',
        marginRight: '0.725rem',
        minHeight: 'calc(2.3rem + 9px)',
        '&.Mui-selected.left_tab_blue': {
          color: 'var(--white)',
          backgroundColor: props.theme.palette.light_orange_2.main,
          '.count': {
            fontWeight: '600',
            color: '#f6ff00',
          },
          '&::after': {
            borderBottomColor: props.theme.palette.light_orange_2.main,
          },
        },
      },
    },
    '.MuiTabs-indicator': {
      display: 'none',
      height: '0px',
    },
  },
}));

export interface TabsContentsType {
  id: number;
  label: string;
  count: number;
}

export interface TabsCustomProps {
  tabValue: string;
  tabContents: TabsContentsType[];
}

function TabsCustom({ tabValue, tabContents }: TabsCustomProps) {
  const { modal_alert } = useContext(ModalContext);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue == '체크업 노트') {
      modal_alert.openModalAlert('is_ready_checkup_note');
    }
  };
  return (
    <CustomRootBox sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tabValue}>
        <CustomBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <CustomTabList onChange={handleChange}>
            {tabContents.map(tabContent => (
              <Tab
                key={tabContent.id}
                label={
                  <>
                    <Box component={'span'} sx={{ fontSize: '1.14rem' }}>
                      {tabContent.label}
                    </Box>
                    {tabContent.count > -1 ? (
                      <Box component={'span'} className='count' sx={{ ml: '8px', fontSize: '1.14rem' }}>
                        {tabContent.count}
                      </Box>
                    ) : null}
                  </>
                }
                value={tabContent.label}
                className='left_tab_blue'
              />
            ))}
          </CustomTabList>
        </CustomBox>
      </TabContext>
    </CustomRootBox>
  );
}

export default React.memo(TabsCustom);
