import { Box } from '@mui/material';
import { CDN } from 'utils/constants';
import { MissionCard, MissionCardWrap, Title, Wrapper } from './MissionStepCard.Styles';
import { MissionType, Step } from 'recoil/common/mission';

interface MissionStepCardVAProps {
  missionData: MissionType | undefined;
  stepData: Step[] | undefined;
}

export function MissionStepCardUI({ missionData, stepData }: MissionStepCardVAProps) {
  const backgroundImageUrl = `url(${CDN}image/mission/popup_bg_1.png)`;

  return (
    <Wrapper sx={{ backgroundImage: backgroundImageUrl }}>
      <Title>{missionData?.title}</Title>
      <MissionCardWrap>
        {stepData?.map((mission, index) => {
          const isCompleted = mission?.completed;
          const currentStep =
            missionData?.curr_step === 2.5
              ? missionData?.curr_step === index + 1.5
              : missionData?.curr_step === index + 1;
          const contentLength = mission?.content.length;

          const contentBoxStyles = {
            width: contentLength > 5 ? '5rem' : '4rem',
            '@media (max-width: 1903px)': {
              width: contentLength > 5 ? '6.5rem' : '5.2rem',
            },

            '@media (max-width: 959px)': {
              width: contentLength > 5 ? '5.5rem' : '4.5rem',
            },

            '@media (max-width: 767px)': {
              width: contentLength > 5 ? '5rem' : '4rem',
            },
          };

          return (
            <MissionCard
              className={isCompleted ? 'completed-step' : currentStep ? 'current-step' : ''}
              key={mission.step}
            >
              <div>
                {mission.step}단계{isCompleted ? ` 성공` : ''}
              </div>
              <Box sx={contentBoxStyles}>{mission?.content}</Box>
            </MissionCard>
          );
        })}
      </MissionCardWrap>
    </Wrapper>
  );
}
