import * as React from 'react';
import { useState, useEffect } from 'react';

import ModalDefault from './ModalDefault';
import Button from '../button/Button';

import { StyledModalWrapper, StyledModalTitle, StyledModalUtil } from '../../styles/common';
import { styled, Box, OutlinedInput } from '@mui/material';

import { AiOutlineLeft } from 'react-icons/ai';

const ModalConfirmButton = styled(Button)(({ theme }) => ({
  fontSize: '1.075rem',
  width: '100%',
  height: '100%',
}));

const StyledBackWrap = styled(Box)(props => ({
  position: 'absolute',
  top: '1.5rem',
  left: '1.5rem',
  cursor: 'pointer',
  color: props.theme.palette.gray_1.main,
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginRight: '1px',
  },
}));

const StyledInput = styled(OutlinedInput)(props => ({
  marginTop: '1rem',
  height: '2.5rem',
  width: '70%',
  backgroundColor: props.theme.palette.gray_6.main,

  '&.Mui-focused': {
    backgroundColor: props.theme.palette.white.main,
    '.MuiOutlinedInput-input::placeholder': {
      color: 'transparent',
    },
  },

  '.MuiOutlinedInput-input': {
    textAlign: 'center',
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${props.theme.palette.yellow_1.main}`,
  },
}));

function ModalFolderEdit(props: ModalFolderFormProps) {
  const visible = props.visible;
  const type = props.data.type;
  const origin = props.data?.origin;
  const id = props.data?.id;
  const changeValue = props.onChange;
  const offModal = props.onClick;
  const [folderName, setFolderName] = useState('');
  const [disabled, setDisabled] = useState<boolean>(true);

  const onClick = () => {
    changeValue(folderName, id, type);
    offModal();
  };

  const onChange = (e: any) => {
    setFolderName(e.target.value);
  };

  useEffect(() => {
    if (folderName == '') setDisabled(true);
    else setDisabled(false);

    if (origin) {
      if (origin === folderName) setDisabled(true);
    }
  }, [folderName]);

  useEffect(() => {
    setFolderName('');
  }, [visible]);

  return (
    <>
      <ModalDefault visible={visible} onClose={offModal}>
        <StyledModalWrapper>
          <StyledModalTitle>
            <Box>포켓노트 폴더명을 {type == 'create' ? '입력' : '수정'}하세요.</Box>
            <StyledInput onChange={onChange} placeholder={origin} inputProps={{ maxLength: 30 }} />
          </StyledModalTitle>
          <StyledModalUtil>
            <ModalConfirmButton color='purple' onClick={() => onClick()} disabled={disabled}>
              {type == 'create' ? '확인' : '수정'}
            </ModalConfirmButton>
          </StyledModalUtil>
        </StyledModalWrapper>
      </ModalDefault>
    </>
  );
}

export default ModalFolderEdit;
