import { MissionType } from 'recoil/common/mission';
import { MissionStepCardUI } from './MissionStepCard.Presenter';

interface MissionStepCardProps {
  missionData: MissionType | undefined;
}

export function MissionStepCardContainer({ missionData }: MissionStepCardProps) {
  return <MissionStepCardUI missionData={missionData} stepData={missionData?.step} />;
}
