import { useState, useEffect, useRef, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { userState } from '../../recoil/model/user';

import { ModalContext } from '../../provider/ModalProvider';

import {
  styled,
  Box,
  BoxProps,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  IconButtonProps,
  ButtonGroup,
  Button,
  ButtonProps,
} from '@mui/material';

import { dir_column, d_flex, d_flex_center, d_flex_start } from '../../styles/common';

import ModalEndModule from '../../components/modal/ModalEndModule';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { deviceState } from '../../recoil/common/device';
import { IoPause, IoPlay } from 'react-icons/io5';
import ModalSpeakVideoSetting from 'components/modal/ModalSpeakVideoSetting';
import { IoMdSettings } from 'react-icons/io';
import { options } from 'utils/setting_contents';
import { makeEndModuleModalProps } from 'utils/tools';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import theme from 'theme';
import { CDN } from 'utils/constants';
import StudyModuleAlert from 'components/modal/ModalStudyModuleAlert';
import { AiOutlineRight } from 'react-icons/ai';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';

interface VideoProps {
  sendResultsheet: () => void;
}

const StyledVideoWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledVideoBody = styled(Box)(props => ({
  height: 'calc(100% - 4rem)',
  width: '100%',
  ...d_flex_center,
}));

interface VideoBoxProps extends BoxProps {
  mode: number;
}

const StyledVideoTextWrap = styled(Box)<VideoBoxProps>(props => ({
  height: props.mode == 0 ? '65vh' : '95%',
  width: '100%',
  padding: props.mode == 0 ? '0 2vw' : 'unset',
  position: 'relative',
  ...d_flex_center,
}));

const StyledLangButtonGroup = styled(ButtonGroup)(props => ({
  top: 0,
  right: '1.8vw',
  width: 'auto',
  position: 'absolute',
  ...d_flex_center,
  zIndex: 1,
  '& > .MuiButtonGroup-grouped': {
    marginBottom: '1px',
  },
  '& > .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRight: '2px solid white !important',
    '&:hover': {
      borderRight: '2px solid white !important',
    },
  },
}));

interface LangButtonProps extends ButtonProps {
  checked: boolean;
}

const StyledLangButton = styled(Button)<LangButtonProps>(props => ({
  color: 'white',
  backgroundColor: props.checked ? '#00f49b' : '#ebebeb',
  fontSize: '1rem',
  padding: '0.5rem 1.4rem',
  border: 'none',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: props.checked ? '#00f49b' : '#ebebeb',
    border: 'none',
  },
}));

const StyledVideoTextVideoBox = styled(Box)<VideoBoxProps>(props => ({
  height: '100%',
  width: props.mode == 0 ? '40%' : '70%',
  padding: props.mode == 0 ? '2rem' : 'unset',
  ...d_flex_center,
  borderRight: props.mode == 0 ? '2px solid #f2f2f2' : 'unset',
}));

const StyledTextVideoBox = styled(Box)<VideoBoxProps>(props => ({
  height: 'fit-content',
  width: 'fit-content',
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'flex',
  position: 'relative',
  '& > video': {
    objectFit: props.mode == 0 ? 'cover' : 'contain',
  },
}));

const StyledVideoPauseBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: 'rgb(0,0,0,0.3)',
  ...d_flex_center,
}));

interface VideoIconButtonProps extends IconButtonProps {
  mode: number;
  pause?: boolean;
}

const StyledPauseIconButton = styled(IconButton)<VideoIconButtonProps>(props => ({
  backgroundColor: 'rgb(255,255,255,0.3)',
  width: props.mode == 0 ? '8vh' : '11vh',
  height: props.mode == 0 ? '8vh' : '11vh',
  zIndex: 1,
  color: 'white',
  ...d_flex_center,
  // paddingLeft: props.pause ? '14px' : '',
  svg: {
    width: props.mode == 0 ? '8vh' : '11vh',
    height: props.mode == 0 ? '8vh' : '11vh',
  },
}));

const StyledSettingIconButton = styled(IconButton)<VideoIconButtonProps>(props => ({
  backgroundColor: '#fff',
  top: '1rem',
  // width: props.mode == 0 ? '2rem' : '3rem',
  // height: props.mode == 0 ? '2rem' : '3rem',
  width: '2rem',
  height: '2rem',
  zIndex: 2,
  ...d_flex_center,
  position: 'absolute',
  svg: {
    width: '100%',
    height: '100%',
  },
}));

const StyledVideoTextTextBox = styled(Box)(props => ({
  height: '100%',
  width: '60%',
  paddingTop: '3.5rem',
  ...d_flex_center,
  position: 'relative',
  '& > div:first-of-type': {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    '& > div': {
      padding: '1.25rem 2rem 1.25rem 2rem',
      height: '6.4rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      cursor: 'pointer',
      '@media (min-width: 1024px) and (max-width: 1179px) and (min-height: 768px) and (max-height: 835px)': {
        //* IPad Mini
        height: '7.5rem',
      },
      '@media (min-width: 1180px) and (max-width: 1365px) and (min-height: 820px) and (max-height: 900px)': {
        //* IPad Air
        height: '8rem',
      },
      '@media (width: 1366px) and (min-height: 1024px) and (max-height: 1050px)': {
        //* IPad Pro
        height: '8.75rem',
      },
      '@media (width: 1368px) and (height: 912px)': {
        //* Surface Pro 7
        height: '8.25rem',
      },
      '&:last-of-type': {
        marginBottom: '3.5rem',
      },
    },
  },
}));

interface TitleBoxProps extends BoxProps {
  current: boolean;
  isKorLanguage: boolean;
}

const StyledVideoTextTitle = styled(Box)<TitleBoxProps>(props => ({
  fontSize: props.isKorLanguage ? '1.539rem' : '1.8rem',
  color: props.current ? props.theme.palette.purple.main : 'black',
  fontWeight: props.isKorLanguage ? '500' : '600',
  '@media (min-width: 1024px) and (max-width: 1179px) and (min-height: 768px) and (max-height: 835px)': {
    //* IPad Mini
    fontSize: props.isKorLanguage ? '1.639rem' : '1.9rem',
  },
  '@media (min-width: 1180px) and (max-width: 1365px) and (min-height: 820px) and (max-height: 900px)': {
    //* IPad Air
    fontSize: props.isKorLanguage ? '1.859rem' : '2.1rem',
  },
  '@media (width: 1366px) and (min-height: 1024px) and (max-height: 1050px)': {
    //* IPad Pro
    fontSize: props.isKorLanguage ? '2.059rem' : '2.3rem',
  },
  '@media (width: 1368px) and (height: 912px)': {
    //* Surface Pro 7
    fontSize: props.isKorLanguage ? '1.959rem' : '2.2rem',
  },
}));

const StyledVideoTextSubTitle = styled(Box)(props => ({
  fontSize: '1.44rem',
  color: '#828282',
  '@media (min-width: 1024px) and (max-width: 1179px) and (min-height: 768px) and (max-height: 835px)': {
    //* IPad Mini
    fontSize: '1.54rem',
  },
  '@media (min-width: 1180px) and (max-width: 1365px) and (min-height: 820px) and (max-height: 900px)': {
    //* IPad Air
    fontSize: '1.74rem',
  },
  '@media (width: 1366px) and (min-height: 1024px) and (max-height: 1050px)': {
    //* IPad Pro
    fontSize: '1.94rem',
  },
  '@media (width: 1368px) and (height: 912px)': {
    //* Surface Pro 7
    fontSize: '1.84rem',
  },
}));

const StyledVideoTextCounter = styled(Box)<{ isKorLanguage: boolean; mode: number }>(props => ({
  display: 'inline',
  position: 'relative',
  '& > div': {
    display: 'inline-flex',
    width: '2rem',
    height: '2rem',
    fontSize: '1.44rem',
    color: 'white',
    backgroundColor: '#ffd500',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    '@media (min-width: 1024px) and (max-width: 1179px) and (min-height: 768px) and (max-height: 835px)': {
      //* IPad Mini
      width: props.mode == 1 ? '2rem' : '2.25rem',
      height: props.mode == 1 ? '2rem' : '2.25rem',
      fontSize: props.mode == 1 ? '1.44rem' : '1.54rem',
      top: props.isKorLanguage ? '-0.25rem' : '0',
    },
    '@media (min-width: 1180px) and (max-width: 1365px) and (min-height: 820px) and (max-height: 900px)': {
      //* IPad Air
      width: props.mode == 1 ? '2rem' : '2.5rem',
      height: props.mode == 1 ? '2rem' : '2.5rem',
      fontSize: props.mode == 1 ? '1.44rem' : '1.74rem',
      top: props.isKorLanguage ? '-0.25rem' : '0',
    },
    '@media (width: 1366px) and (min-height: 1024px) and (max-height: 1050px)': {
      //* IPad Pro
      width: props.mode == 1 ? '2rem' : '2.5rem',
      height: props.mode == 1 ? '2rem' : '2.5rem',
      fontSize: props.mode == 1 ? '1.44rem' : '1.74rem',
      top: props.isKorLanguage ? '-0.25rem' : '0',
    },
    '@media (width: 1368px) and (height: 912px)': {
      //* Surface Pro 7
      width: props.mode == 1 ? '2rem' : '2.5rem',
      height: props.mode == 1 ? '2rem' : '2.5rem',
      fontSize: props.mode == 1 ? '1.44rem' : '1.74rem',
      top: props.isKorLanguage ? '-0.25rem' : '0',
    },
  },

  '& .full-screen-mode': {
    position: 'unset',
  },
}));

const StyledVideoBottom = styled(Box)(props => ({
  width: '96%',
  height: '4rem',
  borderTop: '1px solid #e0e3e5',
  ...d_flex_center,
}));

const StyledLinearProgress = styled(LinearProgress)(props => ({
  // height: '2rem',
  height: '0.7rem',
  width: '100%',
  // borderRadius: '2rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#ffedc1',
  },
  [`& .${linearProgressClasses.bar}`]: {
    // borderRadius: '2rem',
    transition: props.value == 0 || props.value == 100 ? 'none' : 'transform .2s linear',
    // backgroundImage: props.value ? `linear-gradient(to right, #e639f3 ${100 - props.value}%, #461fda 100%)` : null,
    background: '#ffb623',
  },
}));

const StyledSubtitleWrapper = styled(Box)(props => ({
  width: '100%',
  position: 'absolute',
  bottom: '7%',
  height: '3rem',
  ...d_flex_center,
}));

const StyledSubtitleWrap = styled(Box)(props => ({
  width: 'max-content',
  height: 'max-content',
  maxWidth: '80%',
  gap: '1rem',
  background: 'rgba(0,0,0,0.7)',
  color: '#fff',
  fontSize: '1.7rem',
  fontWeight: '300',
  padding: '0.2rem 1rem',
  ...d_flex_center,
}));

const StyledIconButton = styled(IconButton)(props => ({
  position: 'absolute',
  top: 'calc((100% - 4rem) / 2)',
  transform: 'translateY(-50%)',
  width: '3rem',
  height: '3rem',
  padding: '0',
  zIndex: '1',
  backgroundColor: 'transparent',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
  '&.Mui-disabled > svg': {
    color: '#e8e9ec',
  },
}));

function Video(props: VideoProps) {
  const sendResultsheet = props.sendResultsheet;
  const setTutorialState = useSetRecoilState(tutorialStateData);
  const { modal_alert } = useContext(ModalContext);

  const userStateData = useRecoilValue<UserType>(userState);

  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { mod, contents, current_step, current_page, show_modal, learning_type, resultsheet } = learningStateData;

  const resultsheetRef = useRef(resultsheet);

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, device_pause, screen_width, screen_height } = deviceStateData;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  // const [videoSrc, setVideoSrc] = useState<string>('');

  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);

  const [currentIndex, setCurrentIndex] = useState<null | number>(null);
  const currentIndexRef = useRef<null | number>(null);

  const timeMapRef = useRef<Array<number | null>>();

  const [videoSettings, setVideoSettings] = useState<{ [key: string]: number }>({ speed: 1, repeat: 2 });
  const [mode, setMode] = useState(0);

  const maxRepeatRef = useRef<number>(+options.video.repeat[2].value);
  const [repeatCnt, setRepeatCnt] = useState<number>(maxRepeatRef.current);
  const repeatCntRef = useRef(repeatCnt);

  const [pause, setPause] = useState(false);
  const [ended, setEnded] = useState(false);
  const [hover, setHover] = useState(false);

  const [loaded, setLoaded] = useState<boolean>(false);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);

  const [progress, setProgress] = useState<number>(0);

  const [visibleVideoSetting, setVisibleVideoSetting] = useState(false);

  const [alertText, setAlertText] = useState('');
  const [alertTextOpen, setAlertTextOpen] = useState(false);

  const [textLanguage, setTextLanguage] = useState<{
    [key: string]: {
      title: string;
      value: boolean;
    };
  }>({
    eng: {
      title: '영어',
      value: true,
    },
    kor: {
      title: '한글',
      value: true,
    },
  });

  const [videoLanguage, setVideoLanguage] = useState<{
    [key: string]: {
      title: string;
      value: boolean;
    };
  }>({
    eng: {
      title: '영어',
      value: true,
    },
    kor: {
      title: '한글',
      value: false,
    },
  });

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });

  const nextCallback = () => {
    sendResultsheet();
  };

  const [videoBlob, setVideoBlob] = useState<string>('');
  const videoBlobRef = useRef<string>('');
  videoBlobRef.current = videoBlob;

  useEffect(() => {
    return () => {
      if (videoBlobRef.current) {
        URL.revokeObjectURL(videoBlobRef.current);
      }
    };
  }, []);

  const getVideo = async (url: string) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setVideoBlob(blobUrl);
      } else {
        //! error
        console.log('error1');
      }
    } catch (error) {
      console.log('error2', error);
    }
  };

  useEffect(() => {
    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      resultsheetRef.current = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheetRef.current,
      }));
    } else {
      resultsheetRef.current = [];
      for (let i = 0; i < contents.length; i++) {
        const sentence = contents[i].sentence ?? '';
        const translate = contents[i].translate ?? '';
        const word_id = contents[i].word_id ?? '';
        const sentence_id = contents[i].sentence_id ?? '';
        resultsheetRef.current[i] = {
          no: i + 1,
          word_id: word_id,
          sentence_id: sentence_id,
          answer: null,
          rightanswer: sentence,
          question: translate,
        };
      }
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheetRef.current,
      }));
    }

    const video_src_url = `${CDN}video/${learningStateData.category_id}/${learningStateData.folder_id}/${
      learningStateData.schedule_name.includes('?')
        ? learningStateData.schedule_name.replace(/\?/g, '')
        : learningStateData.schedule_name
    }.mp4`;

    // setVideoSrc(video_src_url);

    getVideo(video_src_url);

    return () => {
      if (videoRef.current) {
        clearTimeout1();
        if (videoRef.current.played) videoRef.current.pause();
        videoRef.current.onclick = null;
        videoRef.current.onended = null;
        videoRef.current.oncanplaythrough = null;
        videoRef.current.onloadedmetadata = null;
        videoRef.current.ontimeupdate = null;
        videoRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!show_modal) {
      document.addEventListener('keydown', keyboardDownEvent);
    }

    return () => {
      document.removeEventListener('keydown', keyboardDownEvent);
    };
  }, [show_modal]);

  useEffect(() => {
    setVideoSize();
  }, [screen_width, screen_height, mode, videoBlob]);

  useEffect(() => {
    const sentence = document.getElementById(`sentence-${currentIndex}`);
    if (sentence) {
      sentence.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [currentIndex]);

  useEffect(() => {
    if (ended) {
      const { label, icon, score, kor_label } = makeEndModuleModalProps(resultsheetRef.current);
      setEndModuleModal({ visible: true, icon, label, score, kor_label });
    }
  }, [ended]);

  useEffect(() => {
    const currentRepeatOption = Number(options.video.repeat[videoSettings.repeat].value);

    let text = repeatCnt > 1 ? '한번 더 듣고 따라 말해보세요.' : '마지막으로 듣고 따라 말해보세요.';

    if (repeatCnt === currentRepeatOption) {
      setAlertTextOpen(false);
    } else if (repeatCnt > 1) {
      text = '한번 더 듣고 따라 말해보세요.';
      setAlertTextOpen(true);
      setTimeout(() => {
        setAlertTextOpen(false);
      }, 100);
    } else {
      text = '마지막으로 듣고 따라 말해보세요.';
      setAlertTextOpen(true);
    }

    setAlertText(text);
  }, [repeatCnt]);

  function setVideoSize() {
    if (!videoRef.current || !videoBlob) return;

    if (mode == 0) {
      const video_box = document.getElementById('video-box');
      if (video_box) {
        const style = window.getComputedStyle(video_box);
        const box_width =
          video_box.offsetWidth -
          (parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)) -
          (parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth));
        const box_height =
          video_box.offsetHeight -
          (parseFloat(style.paddingTop) + parseFloat(style.paddingBottom)) -
          (parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth));

        if (box_width >= box_height) {
          setVideoWidth(+box_height);
          setVideoHeight(+box_height);
        } else {
          setVideoWidth(+box_width);
          setVideoHeight(+box_width);
        }
      }
    } else {
      const video_box = document.getElementById('video-box');
      if (video_box) {
        const box_width = video_box.clientWidth;
        const box_height = video_box.clientHeight;

        const video_ratio = videoRef.current.videoWidth / videoRef.current.videoHeight;
        const box_ratio = box_width / box_height;

        if (box_ratio > video_ratio) {
          setVideoWidth(box_height * video_ratio);
          setVideoHeight(box_height);
        } else {
          setVideoWidth(box_width);
          setVideoHeight(box_width / video_ratio);
        }
      }
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onloadedmetadata = () => {
        if (videoRef.current) {
          const duration = videoRef.current.duration;
          const timeMapping = new Array(Math.ceil(duration * 100)).fill(null);

          // 재생시간에 해당하는 문장 배열의 index를 매핑 (소수점 단위 포함)
          contents.forEach((content, index) => {
            const start = content.start;
            const end = content.end;
            if (start && end) {
              for (let i = Math.ceil(Math.floor(+start * 100)); i <= Math.floor(+end * 100); i++) {
                timeMapping[i] = index;
              }
            }
          });

          timeMapRef.current = [...timeMapping];
          setTimeout(() => {
            setPercent(setLoadingStateData, 100);
          }, 200);
          videoRef.current.play();
        }
      };

      videoRef.current.playbackRate = +options.video.speed[1].value;

      videoRef.current.onended = () => {
        setEnded(true);
      };

      if (is_mobile) {
        videoRef.current.onclick = () => {
          clearTimeout1();
          settimeout1.current.push(
            setTimeout(() => {
              setHover(false);
            }, 2500),
          );
          setHover(true);
        };
      }

      videoRef.current.ontimeupdate = () => {
        if (videoRef.current && timeMapRef.current) {
          const timeIndex = Math.round(videoRef.current.currentTime * 100);

          const contentIndex = timeMapRef.current[timeIndex];
          if (currentIndexRef.current != null) {
            if (contentIndex != null) {
              const start = Number(contents[contentIndex].start);
              const currentTime = videoRef.current.currentTime;
              const end = Number(contents[contentIndex].end);
              const progressValue = ((currentTime - start) / (end - start)) * 100;
              setProgress(progressValue);
              if (resultsheetRef.current[currentIndexRef.current].answer == null) {
                // 여기서 세모로 처리
                const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheetRef.current));
                resultsheet_tmp[currentIndexRef.current].answer = '333';
                setLearningStateData(prevState => ({
                  ...prevState,
                  resultsheet: resultsheet_tmp,
                }));
                resultsheetRef.current = resultsheet_tmp;
              }
            }
            if (currentIndexRef.current != contentIndex) {
              if (repeatCntRef.current > 1) {
                repeatCntRef.current--;
                setRepeatCnt(repeatCntRef.current);
                const start = contents[currentIndexRef.current]?.start;
                if (start) videoRef.current.currentTime = +start;
              } else {
                if (
                  resultsheetRef.current[currentIndexRef.current].answer !==
                  resultsheetRef.current[currentIndexRef.current].rightanswer
                ) {
                  const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheetRef.current));
                  resultsheet_tmp[currentIndexRef.current].answer = contents[currentIndexRef.current].sentence ?? '';
                  setLearningStateData(prevState => ({
                    ...prevState,
                    resultsheet: resultsheet_tmp,
                  }));
                  resultsheetRef.current = resultsheet_tmp;
                }
                setCurrentIndex(contentIndex);
                currentIndexRef.current = contentIndex;
                repeatCntRef.current = maxRepeatRef.current;
                setRepeatCnt(maxRepeatRef.current);
              }
            }
          } else {
            setProgress(0);
            if (currentIndexRef.current != contentIndex) {
              setCurrentIndex(contentIndex);
              currentIndexRef.current = contentIndex;
              repeatCntRef.current = maxRepeatRef.current;
              setRepeatCnt(maxRepeatRef.current);
            }
          }
        }
      };

      videoRef.current.onpause = () => {
        if (videoRef.current) videoRef.current.onpause = null;
        setProgress(0);
      };
      videoRef.current.load();
    }
  }, [videoBlob]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (show_modal || device_pause) {
      videoRef.current.pause();
    } else {
      if (pause) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }, [device_pause, show_modal]);

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.speak && learning_type != 5) {
        //  튜토리얼을 진행해야 한다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
        setTimeout(() => {
          setLoaded(true);
        }, 400);
      } else {
        if (videoRef.current && videoRef.current.played) videoRef.current.pause();
        setTimeout(() => {
          if (videoRef.current && videoRef.current.played) videoRef.current.pause();
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('speak_video_start', undefined, undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
          setLoaded(true);
        }, 400);
      }
    }
  }, [percent]);

  const clearTimeout1 = () => {
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
  };

  const onClickChangeMode = () => {
    setMode(mode ? 0 : 1);
    clearTimeout1();
    setHover(false);
  };

  const videoPause = () => {
    if (!videoRef.current) return;
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPause(false);
    } else {
      videoRef.current.pause();
      setPause(true);
    }
  };

  const onClickPause = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    videoPause();
  };

  const onClickSettingButton = () => {
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));
    setVisibleVideoSetting(true);
  };

  const onCloseVideoSetting = (settings: { [key: string]: number }) => {
    if (videoSettings.speed != settings.speed) {
      if (videoRef.current) videoRef.current.playbackRate = +options.video.speed[settings.speed].value;
    }
    setVisibleVideoSetting(false);
    setVideoSettings({ ...settings });
    if (videoSettings.repeat != settings.repeat) {
      modal_alert.openModalAlert('change_video_setting', undefined, undefined, () => {
        setPercent(setLoadingStateData, 1);
        maxRepeatRef.current = +options.video.repeat[settings.repeat].value;
        setRepeatCnt(+options.video.repeat[settings.repeat].value);
        repeatCntRef.current = +options.video.repeat[settings.repeat].value;
        if (videoRef.current) {
          if (!videoRef.current.paused) {
            videoRef.current.pause();
          }
          videoRef.current.currentTime = 0;
        }

        setPause(false);
        setPercent(setLoadingStateData, 100);
      });
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
    }
  };

  const onClickLang = (key: string, value: boolean) => {
    if (mode == 0) {
      if (key == 'kor') {
        if (!textLanguage.eng.value && value) return;
      } else if (key == 'eng') {
        if (!textLanguage.kor.value && value) return;
      }
      setTextLanguage({
        ...textLanguage,
        [key]: {
          title: textLanguage[key].title,
          value: !value,
        },
      });
    } else {
      if (value) return;
      if (key == 'kor') {
        setVideoLanguage({
          eng: { title: videoLanguage.eng.title, value: value },
          kor: {
            title: videoLanguage.kor.title,
            value: !value,
          },
        });
      } else if (key == 'eng') {
        setVideoLanguage({
          eng: { title: videoLanguage.eng.title, value: !value },
          kor: {
            title: videoLanguage.kor.title,
            value: value,
          },
        });
      }
    }
  };

  const langButtonGroup = () => {
    const result = [];
    const language = mode == 0 ? textLanguage : videoLanguage;
    for (const [key, value] of Object.entries(language)) {
      result.push(
        <StyledLangButton checked={value.value} disableRipple onClick={() => onClickLang(key, value.value)}>
          <Box>{value.title}</Box>
        </StyledLangButton>,
      );
    }
    return result;
  };

  const onClickContent = (index: number) => {
    if (currentIndexRef.current == index) return;
    setCurrentIndex(index);
    currentIndexRef.current = index;
    repeatCntRef.current = maxRepeatRef.current;
    setRepeatCnt(maxRepeatRef.current);
    const start = contents[index]?.start;
    if (start && videoRef.current) videoRef.current.currentTime = +start;
  };

  const onClickRetry = () => {
    setPercent(setLoadingStateData, 1);
    setRepeatCnt(maxRepeatRef.current);
    repeatCntRef.current = maxRepeatRef.current;
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
      videoRef.current.currentTime = 0;
    }

    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheetRef.current));
    for (let i = 0; i < resultsheet_tmp.length; i++) {
      resultsheet_tmp[i].answer = null;
    }
    setLearningStateData(prevState => ({
      ...prevState,
      resultsheet: resultsheet_tmp,
    }));
    resultsheetRef.current = resultsheet_tmp;
    setEndModuleModal(prev => ({ ...prev, visible: false }));
    setPause(false);
    setEnded(false);
    setTimeout(() => {
      setPercent(setLoadingStateData, 100);
    }, 100);
  };

  const onMouse = (bol: boolean) => {
    if (is_mobile) return;
    setHover(bol);
  };

  const onClickPauseBox = () => {
    if (is_mobile) {
      clearTimeout1();
      setHover(false);
    }
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (show_modal) return false;

    if (currentIndexRef.current !== null) {
      if (e.key === 'ArrowUp') {
        const moveToIndex = currentIndexRef.current - 1;
        if (moveToIndex >= 0) {
          onClickContent(moveToIndex);
        }
      }

      if (e.key === 'ArrowDown') {
        const moveToIndex = currentIndexRef.current + 1;
        if (moveToIndex < contents.length) {
          onClickContent(moveToIndex);
        }
      }

      if (e.keyCode == 32) {
        videoPause();
      }
    }
  };

  const goNextProcess = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setEnded(true);
    onClickPause(e);
  };

  const isKorLanguage = textLanguage.kor.value && !textLanguage.eng.value;

  return (
    <StyledVideoWrap>
      <StyledIconButton onClick={goNextProcess} sx={{ right: '16px' }} disableRipple>
        <AiOutlineRight />
      </StyledIconButton>
      <StyledVideoBody>
        <StyledVideoTextWrap mode={mode}>
          <StyledLangButtonGroup>{langButtonGroup()}</StyledLangButtonGroup>
          <StyledVideoTextVideoBox id='video-box' mode={mode}>
            <StyledTextVideoBox
              sx={{
                '& > video': {
                  width: `${videoWidth}px`,
                  height: `${videoHeight}px`,
                },
              }}
              mode={mode}
              onMouseOver={() => {
                onMouse(true);
              }}
              onMouseOut={() => {
                onMouse(false);
              }}
            >
              <StyledSettingIconButton
                sx={{
                  left: '1rem',
                  // padding: mode == 0 ? '0.25rem' : '0.5rem',
                  padding: '0.25rem',
                  svg: {
                    color: theme.palette.gray.main,
                  },
                }}
                disableRipple
                onClick={() => onClickSettingButton()}
                mode={mode}
              >
                <IoMdSettings />
              </StyledSettingIconButton>
              <StyledSettingIconButton
                sx={{
                  right: '1rem',
                  // padding: mode == 0 ? '0.35rem' : '0.7rem',
                  padding: '0.35rem',
                  svg: {
                    color: '#7a7a7a',
                  },
                }}
                disableRipple
                onClick={() => {
                  onClickChangeMode();
                }}
                mode={mode}
              >
                {mode ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
              </StyledSettingIconButton>
              <StyledVideoPauseBox
                sx={{
                  display: hover ? 'flex' : 'none',
                }}
                onClick={() => onClickPauseBox()}
              >
                <StyledPauseIconButton
                  disableRipple
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClickPause(e)}
                  mode={mode}
                >
                  {pause ? <IoPlay /> : <IoPause />}
                </StyledPauseIconButton>
              </StyledVideoPauseBox>
              {videoBlob ? (
                <video /* src={`${videoSrc}`} */ ref={videoRef} autoPlay={false} playsInline={true} controls={false}>
                  <source src={videoBlob} type='video/mp4' />
                </video>
              ) : null}
              {currentIndex !== null && mode === 1 ? (
                <StyledSubtitleWrapper>
                  <StyledSubtitleWrap>
                    <div style={{ textAlign: 'center', display: !videoLanguage.eng.value ? 'none' : '' }}>
                      {contents[currentIndex].sentence}&nbsp;&nbsp;
                      <StyledVideoTextCounter isKorLanguage={isKorLanguage} mode={mode}>
                        <Box className='full-screen-mode'>{repeatCnt}</Box>
                      </StyledVideoTextCounter>
                    </div>
                    <div style={{ textAlign: 'center', display: !videoLanguage.kor.value ? 'none' : '' }}>
                      {contents[currentIndex].translate}&nbsp;&nbsp;
                      <StyledVideoTextCounter isKorLanguage={isKorLanguage} mode={mode}>
                        <Box className='full-screen-mode'>{repeatCnt}</Box>
                      </StyledVideoTextCounter>
                    </div>
                  </StyledSubtitleWrap>
                </StyledSubtitleWrapper>
              ) : null}
              <Box sx={{ position: 'absolute', bottom: '0', width: '100%', transform: 'translateY(0.7rem)' }}>
                <StyledLinearProgress key={current_step} variant={'determinate'} value={progress} />
              </Box>
            </StyledTextVideoBox>
          </StyledVideoTextVideoBox>
          {mode == 0 ? (
            <StyledVideoTextTextBox>
              <Box>
                {contents.map((content, idx) => {
                  const isKorLanguage = textLanguage.kor.value && !textLanguage.eng.value;
                  return (
                    <Box
                      onClick={() => onClickContent(idx)}
                      sx={{ backgroundColor: currentIndex == idx ? '#fff7ff' : 'unset' }}
                      key={`video-setence-${idx}`}
                      id={`sentence-${idx}`}
                    >
                      <StyledVideoTextTitle current={currentIndex == idx} isKorLanguage={isKorLanguage}>
                        {textLanguage.eng.value ? content.sentence : content.translate}&nbsp;
                        {currentIndex == idx ? (
                          <StyledVideoTextCounter isKorLanguage={isKorLanguage} mode={mode}>
                            <Box>{repeatCnt}</Box>
                          </StyledVideoTextCounter>
                        ) : null}
                      </StyledVideoTextTitle>
                      <StyledVideoTextSubTitle
                        sx={{ display: !textLanguage.kor.value || !textLanguage.eng.value ? 'none' : 'block' }}
                      >
                        {content.translate}
                      </StyledVideoTextSubTitle>
                    </Box>
                  );
                })}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '3.5rem',
                  position: 'absolute',
                  bottom: 0,
                  background: 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)',
                }}
              ></Box>
            </StyledVideoTextTextBox>
          ) : null}
        </StyledVideoTextWrap>
      </StyledVideoBody>

      <ModalEndModule
        visible={endModuleModal.visible}
        label={endModuleModal.label}
        kor_label={endModuleModal.kor_label}
        icon={endModuleModal.icon}
        score={endModuleModal.score}
        nextCallback={nextCallback}
        onClickRetry={onClickRetry}
      />
      <ModalSpeakVideoSetting
        visible={visibleVideoSetting}
        settings={videoSettings}
        onClose={(settings: { [key: string]: number }) => {
          onCloseVideoSetting(settings);
        }}
      />
      <StudyModuleAlert visible={alertTextOpen} message={alertText} />
    </StyledVideoWrap>
  );
}

export default Video;
