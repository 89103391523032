import { atom, SetterOrUpdater } from 'recoil';

export const deviceState = atom<DeviceType>({
  key: 'deviceState',
  default: {
    platform: '',
    screen_width: 0,
    screen_height: 0,
    recognition_available: false,
    webkit_speech_available: false,
    is_mobile: false,
    is_tablet: false,
    current_version: '1.1.32', // 버전 업데이트 마다 변경
    last_version: '1.1.32', // 앱 실행시 업데이트
    device_pause: null, // 앱 백그라운드 여부
    store: 'google', // 스토어 종류 (only Android) google / onestore
  },
});
