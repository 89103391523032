import { LayoutFind, FindWrap, FindTextField, TitleTypography, FindButton, FindBackButton } from './FindPW.Styles';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';

export interface FindPWVAProps {
  onClickBackButton: () => void;
  contents: { [key: string]: string };
  placeholderList: { [key: string]: string };
  handleFindInput: (value: string, key: string) => void;
  setEndAdornment: (key: string) => JSX.Element | undefined;
  findButtonDisabled: boolean;
  inputDisabled: boolean;
  clickFindButton: () => void;
  // mobile 관련 처리
  top: string;
  onFocus: (e: React.FocusEvent<HTMLInputElement>, type: string) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export function FindPWUI({
  onClickBackButton,
  contents,
  placeholderList,
  handleFindInput,
  setEndAdornment,
  findButtonDisabled,
  inputDisabled,
  clickFindButton,
  top,
  onFocus,
  onBlur,
}: FindPWVAProps) {
  return (
    <LayoutFind>
      <FindWrap sx={{ marginTop: `${top}` }}>
        <FindBackButton onClick={onClickBackButton}>
          <HiOutlineArrowSmLeft />
        </FindBackButton>
        <TitleTypography sx={{ position: 'relative', top: '-0.8rem' }}>비밀번호 찾기</TitleTypography>
        {Object.keys(contents).map((key, kIdx) => {
          return (
            <FindTextField
              variant='filled'
              color='purple'
              key={`${key}_${kIdx}`}
              placeholder={placeholderList[key]}
              value={contents[key] ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFindInput(e.target.value, key)}
              InputProps={{
                endAdornment: setEndAdornment(key),
                disabled: ['name', 'loginid', 'phone'].includes(key) && inputDisabled,
              }}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => onFocus(e, key)}
              onBlur={onBlur}
              sx={key == 'phone' ? { marginTop: '-0.5rem' } : { marginBottom: '1rem' }}
            />
          );
        })}
        <FindButton variant='contained' color='purple' disabled={findButtonDisabled} onClick={clickFindButton}>
          비밀번호 조회
        </FindButton>
      </FindWrap>
    </LayoutFind>
  );
}
