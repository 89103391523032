import { atom, SetterOrUpdater } from 'recoil';

export const tutorialStateData = atom<TutorialType>({
  key: 'tutorialState',
  default: {
    visible: false,
    reopen: false,
  },
});

interface TutorialSetter {
  setTutorialState: SetterOrUpdater<TutorialType>;
}

interface TutorialArgs {
  reopen: boolean;
}

export const openTutorial = ({ setTutorialState }: TutorialSetter, { reopen }: TutorialArgs) => {
  setTutorialState({
    visible: true,
    reopen,
  });
};

export const closeTutorial = ({ setTutorialState }: TutorialSetter) => {
  setTutorialState(prev => ({
    ...prev,
    visible: false,
  }));
};
