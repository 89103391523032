import { useState, useEffect, useContext, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { useVoice, voiceState } from '../../recoil/common/voice';
import { deviceState } from '../../recoil/common/device';
import { settingState } from '../../recoil/model/settings';

import { ModalContext } from '../../provider/ModalProvider';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';

import ModalGrading from '../../components/modal/ModalGrading';

import { styled, Box, IconButton, BoxProps, keyframes, Input } from '@mui/material';

import { dir_column, d_flex_center, d_flex } from '../../styles/common';
import { arrShuffle, korToEng, makeEndModuleModalProps } from '../../utils/tools';

import { AiOutlineRight } from 'react-icons/ai';
import AutoText from '../../components/text/AutoText';
import ModalEndModule from '../../components/modal/ModalEndModule';
import { userState } from '../../recoil/model/user';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';

declare let window: any;

interface WritingProps {
  sendResultsheet: () => void;
}

const StyledWritingWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  backgroundColor: 'white',
  position: 'relative',
  ...d_flex_center,
  ...dir_column,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledIconButton = styled(IconButton)(props => ({
  position: 'absolute',
  backgroundColor: 'white',
  width: '3rem',
  height: '3rem',
  padding: '0',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
  '&.Mui-disabled > svg': {
    color: '#e8e9ec',
  },
}));

interface WritingBoxProps extends BoxProps {
  is_mobile: number;
}

const StyledQuestionWrap = styled(Box)<WritingBoxProps>(props => ({
  width: '100%',
  height: props.is_mobile == 1 ? '20%' : '47%',
  ...d_flex_center,
}));

const StyledQuestionAutoText = styled(AutoText)(props => ({
  width: '70%',
  color: props.theme.palette.purple.main,
  fontWeight: '700',
}));

const StyledAnswerWrap = styled(Box)<WritingBoxProps>(props => ({
  width: '100%',
  height: props.is_mobile == 1 ? '80%' : '53%',
  borderTop: '1px solid #dde3e4',
  backgroundColor: '#fcfaff',
  ...d_flex,
  justifyContent: 'center',
  alignItems: props.is_mobile == 1 ? 'flex-start' : 'center',
  padding: '1% 7%',
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

const StyledAnswerBox = styled(Box)(props => ({
  width: '100%',
  cursor: 'text',
  lineHeight: '1.4',
  fontSize: '7vh',
  color: props.theme.palette.purple.main,
  lineBreak: 'anywhere',
  textAlign: 'center',
  ...d_flex_center,
  position: 'relative',
}));

const cursorAnimation = keyframes`50% {opacity: 0%}`;

const StyledCursorBox = styled(Box)(props => ({
  width: '2px',
  height: '100%',
  position: 'absolute',
  backgroundColor: '#6800ff',
  animation: `${cursorAnimation} 1s step-end infinite`,
}));

const StyledAnswerChar = styled(Box)(props => ({
  position: 'relative',
  zIndex: '2',
}));

const StyledAnswerLeftBox = styled(Box)(props => ({
  height: '100%',
  width: '50%',
  left: '0',
  position: 'absolute',
  zIndex: '1',
}));

const StyledAnswerRightBox = styled(Box)(props => ({
  height: '100%',
  width: '50%',
  right: '0',
  position: 'absolute',
  zIndex: '1',
}));

const StyledHiddenInput = styled(Input)(props => ({
  position: 'absolute',
  height: '1px',
  top: '20%',
  opacity: '-',
  zIndex: '-1',
  caretColor: 'transparent',
}));

function Writing(props: WritingProps) {
  const sendResultsheet = props.sendResultsheet;
  const setTutorialState = useSetRecoilState(tutorialStateData);
  const { modal_alert } = useContext(ModalContext);

  const userStateData = useRecoilValue(userState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { mod, current_step, contents, current_page, show_modal, resultsheet, current_module_settings } =
    learningStateData;

  const { hint } = current_module_settings.writing;
  const [hintArr, setHintArr] = useState<number[]>([]);

  const [voiceStateData, setVoiceStateData] = useRecoilState<VoiceType>(voiceState);
  const { ready } = voiceStateData;
  const { setVoice, voicePlay, voiceStop } = useVoice();

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;
  const [loaded, setLoaded] = useState<boolean>(false);

  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile, platform } = deviceStateData;

  const settingStateData = useRecoilValue(settingState);
  const { check_type } = settingStateData;

  const { playEffectSound } = useContext(EffectSoundContext);

  const [mobileWriting, setMobileWriting] = useState<boolean>(false);

  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [answer, setAnswer] = useState<string>('');
  const [rightanswer, setRightanswer] = useState<string>(content.spell);
  const [question, setQuestion] = useState<string>(content.mean);
  const [right, setRight] = useState<boolean>(false);

  const [visibleGrading, setVisibleGrading] = useState<boolean>(false);

  // const [goResult, setGoResult] = useState<boolean>(false);

  const [selectedLetters, setSelectedLetters] = useState<string[]>([]);

  const [cursorIdx, setCursorIdx] = useState<number>(0);

  const input_val = useRef<string>('');

  const [focus, setFocus] = useState<boolean>(false);

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });

  const nextCallback = () => {
    sendResultsheet();
  };

  const [keyboardStop, setKeyboardStop] = useState(false);

  const onSoundPlayRef = useRef<() => Promise<void>>();
  const onPlayEffectSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundStopRef = useRef<() => Promise<void>>();

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (!window.ExternalKeyboard.isUsed) setMobileWriting(true);
    } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobileWriting(true);
    }

    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      const resultsheet_tmp = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
      }));
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: [],
      }));
    }

    setPercent(setLoadingStateData, 100);
    return () => {
      unbindKeyboard();
      setVoiceStateData(() => ({
        element: undefined,
        playing: false,
        loading: false,
        error: false,
        ready: false,
        volume: 1,
      }));
      if (mobileWriting) {
        document?.getElementById('writing-input')?.removeEventListener('focus', () => setFocus(true));
        document?.getElementById('writing-input')?.removeEventListener('blur', () => setFocus(false));
      }
    };
  }, []);

  useEffect(() => {
    if (mobileWriting) {
      document?.getElementById('writing-input')?.addEventListener('focus', () => setFocus(true));
      document?.getElementById('writing-input')?.addEventListener('blur', () => setFocus(false));
    }
  }, [mobileWriting]);

  useEffect(() => {
    if (mobileWriting && !show_modal && platform == 'android') {
      document?.getElementById('writing-input')?.focus();
    }
  }, [show_modal]);

  useEffect(() => {
    setVoiceStateData(() => ({
      element: undefined,
      playing: false,
      loading: false,
      error: false,
      ready: false,
      volume: 1,
    }));
    setHintArr([]);
    setContent(contents[current_step]);
  }, [current_step]);

  useEffect(() => {
    const voice = content['voice'] ? content['voice'] : '0.mp3';
    setVoice(content['spell'], voice);
    setInit();
  }, [content]);

  useEffect(() => {
    bindKeyboard();
    return () => {
      unbindKeyboard();
    };
  }, [answer, ready, cursorIdx, show_modal, visibleGrading, keyboardStop]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const setInit = () => {
    const spell = content['spell'];
    const mean = content['mean'];
    setRightanswer(spell);
    setQuestion(mean);
    let splited = Array.from(spell);

    const selected_letters = [];
    const choice_letters: string[] = [];
    let hint_arr: number[] = [];

    splited = splited.map((v, i) => {
      if (new RegExp('[A-Za-z]').test(v)) {
        choice_letters.push(v);
        if (hint > 0) hint_arr.push(i);
        return '_';
      } else {
        return v;
      }
    });

    if (hint > 0) {
      hint_arr = arrShuffle(hint_arr);
      if (hint == 1) {
        hint_arr = hint_arr.slice(0, Math.round(hint_arr.length * 0.2));
      } else if (hint == 2) {
        hint_arr = hint_arr.slice(0, Math.round(hint_arr.length * 0.5));
      }
      for (let i = 0; i < hint_arr.length; i++) {
        const hint_idx = hint_arr[i];
        splited[hint_idx] = spell[hint_idx];
      }
    }

    for (let i = 0; i < splited.length; i++) {
      selected_letters.push(splited[i]);
    }
    setSelectedLetters([...selected_letters]);
    setHintArr([...hint_arr]);
    setVisibleGrading(false);
    if (mobileWriting && loaded && platform == 'android') document?.getElementById('writing-input')?.focus();
  };

  useEffect(() => {
    if (hintArr.length > 0 && hintArr.includes(0)) {
      moveCursor('right');
    }
  }, [hintArr]);

  const keyboardDownEvent = (evt: KeyboardEvent) => {
    if (show_modal || !loaded) {
      evt.preventDefault();
      return false;
    }

    const key = korToEng(evt.key);

    if (key == 'Enter' && !keyboardStop) {
      evt.preventDefault();
      goNext();
      return false;
    }
    if (key == 'ArrowRight' || key == ' ') {
      evt.preventDefault();
      moveCursor('right');
      return;
    } else if (key == 'ArrowLeft') {
      evt.preventDefault();
      moveCursor('left');
      return;
    }
    if (key == 'Backspace') {
      evt.preventDefault();
      subSelection();
      return false;
    }

    if (new RegExp('^[A-Za-z]*$', 'g').test(key) && key.length == 1) {
      evt.preventDefault();
      toggleLetter(key);
      return true;
    } else {
      return false;
    }
  };

  const moveCursor = (dir: string) => {
    let tmp_idx = cursorIdx;
    if (dir == 'left') {
      if (cursorIdx < 1) return false;
      while (tmp_idx > 0) {
        tmp_idx--;
        if (new RegExp('^[A-Za-z_]*$', 'g').test(selectedLetters[tmp_idx])) break;
      }
      setCursorIdx(tmp_idx);
    } else if (dir == 'right') {
      if (cursorIdx >= selectedLetters.length) return false;
      while (tmp_idx < selectedLetters.length) {
        tmp_idx++;
        if (
          tmp_idx == selectedLetters.length ||
          (new RegExp('^[A-Za-z_]*$', 'g').test(selectedLetters[tmp_idx]) && !hintArr.includes(tmp_idx))
        )
          break;
      }
      setCursorIdx(tmp_idx);
    }
  };

  const touchKeyboardChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (show_modal && platform == 'ios') {
      evt.preventDefault();
      return false;
    }

    const key = evt.target.value;

    if (new RegExp('^[A-Za-z]*$', 'g').test(key) && key.length == 1) {
      toggleLetter(key);
      return true;
    } else {
      return false;
    }
  };

  const touchKeyboardUp = (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (show_modal) {
      evt.preventDefault();
      return false;
    }
  };

  const toggleLetter = (char: string) => {
    if (show_modal || cursorIdx >= selectedLetters.length) return false;

    const selected_letters = JSON.parse(JSON.stringify(selectedLetters));

    if (!hintArr.includes(cursorIdx)) {
      selected_letters.splice(cursorIdx, 1, char);
      setSelectedLetters([...selected_letters]);
    }
    playEffectSound('dicbtn');
    moveCursor('right');
  };

  useEffect(() => {
    const tmp_answer = selectedLetters.join('');
    setAnswer(tmp_answer);
  }, [selectedLetters]);

  const subSelection = () => {
    if (show_modal) return false;
    if (cursorIdx == 0 || cursorIdx > selectedLetters.length) return;

    const selected_letters = JSON.parse(JSON.stringify(selectedLetters));

    let tmp_idx = cursorIdx;

    while (tmp_idx > 0) {
      tmp_idx--;
      if (new RegExp('^[A-Za-z_]*$', 'g').test(selectedLetters[tmp_idx])) break;
    }

    if (!hintArr.includes(tmp_idx)) {
      selected_letters.splice(tmp_idx, 1, '_');
      setSelectedLetters([...selected_letters]);
    }

    setCursorIdx(tmp_idx);
  };

  const goNext = async () => {
    if (show_modal) return false;
    if (platform == 'ios') document?.getElementById('writing-input')?.blur();
    let right = false;

    if (answer == rightanswer) {
      right = true;
      setRight(right);
    } else {
      setRight(right);
    }

    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));
    if (check_type) {
      onSoundPlayRef.current = async () => {
        playEffectSound(right ? 'correct' : 'wrong');
        await new Promise(resolve => setTimeout(resolve, 1500));
        await voicePlay();
      };
      onPlayEffectSoundPlayRef.current = async () => {
        await playEffectSound(right ? 'correct' : 'wrong');
      };
      onAnswerSoundPlayRef.current = async () => {
        await voicePlay();
      };
      onAnswerSoundStopRef.current = async () => {
        voiceStop();
      };
      setVisibleGrading(true);
    } else {
      setTimeout(() => {
        goNextProcess();
      }, 2000);
    }
  };

  const goNextProcess = () => {
    onSoundPlayRef.current = undefined;
    onPlayEffectSoundPlayRef.current = undefined;
    onAnswerSoundPlayRef.current = undefined;
    onAnswerSoundStopRef.current = undefined;
    setKeyboardStop(true);
    setCursorIdx(0);
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
    resultsheet_tmp[current_step] = {
      no: current_step + 1,
      word_id: content['id'],
      answer: answer,
      rightanswer: rightanswer,
      question: question,
    };
    if (current_step == contents.length - 1) {
      setVisibleGrading(false);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
          resultsheet: resultsheet_tmp,
        }));
        unbindKeyboard();
        const { label, icon, score, kor_label } = makeEndModuleModalProps(resultsheet_tmp);
        setEndModuleModal({ visible: true, icon, label, score, kor_label });
      }, 0);
    } else {
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
          current_step: current_step + 1,
          resultsheet: resultsheet_tmp,
        }));
      }, 0);
      setTimeout(() => {
        setKeyboardStop(false);
      }, 1000);
    }
  };

  // useEffect(() => {
  //   if (goResult) {
  //     sendResultsheet();
  //   }
  // }, [goResult]);

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.advanced) {
        // 튜토리얼을 진행해야 한다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
        setTimeout(() => {
          setLoaded(true);
        }, 400);
      } else {
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('writing_start', undefined, undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
          setLoaded(true);
        }, 400);
      }
    }
  }, [percent]);

  const onClickAnswer = (i: number) => {
    setCursorIdx(i);
    if (mobileWriting) document?.getElementById('writing-input')?.focus();
  };

  return (
    <StyledWritingWrap>
      <StyledIconButton
        onClick={() => goNext()}
        sx={{
          right: mobileWriting && focus ? '7.5%' : '16px',
          top: mobileWriting && focus ? '10%' : '42%',
          transform: 'translateY(-50%)',
        }}
        disableRipple
      >
        <AiOutlineRight />
      </StyledIconButton>
      <StyledQuestionWrap is_mobile={mobileWriting && focus ? 1 : 0}>
        <StyledQuestionAutoText minFontSize={5} maxFontSize={7} fontSizeUnit='vh'>
          {question}
        </StyledQuestionAutoText>
      </StyledQuestionWrap>
      <StyledAnswerWrap is_mobile={mobileWriting && focus ? 1 : 0}>
        <StyledAnswerBox>
          {selectedLetters?.map((v, i) => (
            <StyledAnswerChar
              sx={{ letterSpacing: v == '_' ? '4px' : 'unset', fontWeight: v == '_' ? '500' : '700' }}
              key={`answer_char_${i}`}
            >
              <StyledAnswerLeftBox onClick={e => onClickAnswer(i)}>
                {cursorIdx == 0 && i == 0 && !(visibleGrading || endModuleModal.visible) ? <StyledCursorBox /> : null}
              </StyledAnswerLeftBox>
              <StyledAnswerRightBox onClick={e => onClickAnswer(i + 1)}>
                {cursorIdx > 0 && cursorIdx == i + 1 && !(visibleGrading || endModuleModal.visible) ? (
                  <StyledCursorBox sx={{ right: '0' }} />
                ) : null}
              </StyledAnswerRightBox>
              {v == ' ' ? <>&nbsp;</> : v}
            </StyledAnswerChar>
          ))}
          <StyledAnswerLeftBox onClick={() => onClickAnswer(0)} />
          <StyledAnswerRightBox onClick={() => onClickAnswer(selectedLetters.length)} />
        </StyledAnswerBox>
      </StyledAnswerWrap>
      {mobileWriting ? (
        <StyledHiddenInput
          autoComplete='off'
          id='writing-input'
          onChange={touchKeyboardChange}
          onKeyUp={touchKeyboardUp}
          value={input_val.current}
          spellCheck='false'
        />
      ) : null}
      <ModalGrading
        visible={visibleGrading}
        onSoundPlay={onSoundPlayRef.current}
        onPlayEffectSoundPlay={onPlayEffectSoundPlayRef.current}
        onAnswerSoundPlay={onAnswerSoundPlayRef.current}
        onAnswerSoundStop={onAnswerSoundStopRef.current}
        onClose={() => goNextProcess()}
        rightanswer={rightanswer}
        answer={answer}
        question={question}
        content={content}
        type={right ? 'right_answer' : 'wrong_answer2'}
      />
      {endModuleModal.visible ? (
        <ModalEndModule
          visible={endModuleModal.visible}
          kor_label={endModuleModal.kor_label}
          label={endModuleModal.label}
          icon={endModuleModal.icon}
          score={endModuleModal.score}
          nextCallback={nextCallback}
        />
      ) : null}
    </StyledWritingWrap>
  );
}

export default Writing;
