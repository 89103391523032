import { useContext, useEffect, useState } from 'react';
import { styled, Box, Typography, Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL } from '../../utils/constants';
import { ModalContext } from '../../provider/ModalProvider';
import { fetchGetApi } from '../../utils/api';

import { HiUser } from 'react-icons/hi';
import { BiEdit } from 'react-icons/bi';
import { IoMdInformationCircle } from 'react-icons/io';
import LayoutMain from '../../components/layout/LayoutMain';
import { d_flex_center, ignore_drag } from '../../styles/common';

import React from 'react';

function Leveltest() {
  return <Box sx={{ width: '100%', height: '100%' }}>레벨테스트</Box>;
}

export default Leveltest;
