import * as React from 'react';
import ModalDefault from './ModalDefault';
import { IoCloseSharp } from 'react-icons/io5';

interface ModalCustomDefault {
  visible: boolean;
  title: string;
  children: React.ReactNode;
  height?: string;
  overflow?: boolean;
  header?: boolean;
  onClose: () => void;
  useBlur?: boolean;
}

import { d_flex_center, d_flex_space_between } from '../../styles/common';
import { styled, Box } from '@mui/material';

const StyledIconWrap = styled(Box)(props => ({
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: props.theme.palette.white.main,
  color: props.theme.palette.red.main,
  svg: {
    width: '1.7rem',
    height: '1.7rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  ...d_flex_center,
}));

interface StyledModalCustomProps {
  isoverflow?: boolean;
}

const StyledModalCustom = styled(Box)<StyledModalCustomProps>(props => ({
  height: 'auto',
  borderRadius: '4px',
  overflow: props.isoverflow ? 'visible' : 'hidden',
  backgroundColor: props.theme.palette.white.main,
}));

interface StyledHeaderProps {
  height?: string;
}

const StyledHeader = styled(Box)<StyledHeaderProps>(props => ({
  width: '100%',
  height: props.height ? props.height : '3.5rem',
  padding: '0 1rem',
  fontSize: '1.1rem',
  // backgroundColor: '#f12211',
  background: 'linear-gradient(0deg, rgba(242,36,19,1) 0%, rgba(255,85,58,1) 100%)',
  color: props.theme.palette.white.main,
  ...d_flex_space_between,
}));

function ModalCustomDefault(props: ModalCustomDefault) {
  const visible = props.visible;
  const title = props.title;
  const children = props.children;
  const onClose = props.onClose;
  const height = props.height;
  const overflow = props.overflow;
  const header = props.header;
  const use_blur = props.useBlur;

  const ModalCustomHeader = () => {
    return (
      <>
        <Box component={'span'}>{title}</Box>
        <StyledIconWrap onClick={onClose}>
          <IoCloseSharp onClick={onClose} />
        </StyledIconWrap>
      </>
    );
  };

  return (
    <>
      <ModalDefault
        visible={visible}
        onClose={() => {
          if (use_blur !== false) {
            onClose();
          }
        }}
        overflow={overflow}
      >
        <StyledModalCustom isoverflow={overflow}>
          {header !== false ? (
            <StyledHeader height={height}>
              <ModalCustomHeader />
            </StyledHeader>
          ) : null}

          <Box>{children}</Box>
        </StyledModalCustom>
      </ModalDefault>
    </>
  );
}

export default ModalCustomDefault;
