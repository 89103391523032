import { SignupUI } from './Signup.Presenter';
import { useState, useEffect, useCallback, useContext, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalTabState, openModalTab } from 'recoil/common/modaltab';
import { modalManual2State, openModalManual2 } from 'recoil/common/modalManual2';
import { fetchGetApi, fetchSuperPostApi } from 'utils/api';
import { autoHypenPhone } from 'utils/tools';
import { BASE_URL, HOMEPAGE_URL } from 'utils/constants';
import { ModalContext } from 'provider/ModalProvider';
import { deviceState } from 'recoil/common/device';
import { useNavigate } from 'react-router';

interface SignupContainerProps {
  outSignupPage: () => void;
}

declare let window: any;

export function SignupContainer() {
  const navigate = useNavigate();
  const { modal_alert } = useContext(ModalContext);
  const setModalTab = useSetRecoilState(modalTabState);
  const setModalManual2 = useSetRecoilState(modalManual2State);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const [step, setStep] = useState(1);
  const [firstStepDone, setFirstStepDone] = useState<boolean>(false);
  const [existMember, setExistMember] = useState<boolean>(false); //이미 존재하는 회원인지
  const [parentName, setParentName] = useState<string>(''); //학부모 성함
  const [phoneNumber, setPhoneNumber] = useState<string>(''); //핸드폰번호
  const [isChecked1, setIsChecked1] = useState<boolean>(false); //이용약관
  const [isChecked2, setIsChecked2] = useState<boolean>(false); //개인정보 취급방침
  const [postdate, setPostdate] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errMsgPassword, setErrMsgPassword] = useState<string>('');
  const [top, setTop] = useState<string>('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const signUpBtnDisabled = useRef(false);

  const outSignupPage = useCallback(() => {
    navigate(`${BASE_URL}/login`);
  }, []);

  const onClickUnderbar = useCallback((tab: number) => {
    openModalTab({ setModalTab }, { tabNumber: tab });
  }, []);

  const onClickQuestion = useCallback(() => {
    openModalManual2({ setModalManual2 });
  }, []);

  const handlePostMessage = async (e: MessageEvent) => {
    const msgdata = e.data;

    if (typeof msgdata == 'string') {
      if (msgdata == 'kcpcert_display_none') {
        (document.getElementById('kcpcert_student') as HTMLLIElement).style.display = 'none';
      } else if (msgdata == 'kcpcert_display_blank') {
        (document.getElementById('kcpcert_student') as HTMLLIElement).style.display = '';
      } else {
        return;
      }
    } else {
      if (msgdata && msgdata.msg == 'cert_result' && msgdata.cellno != '' && msgdata.name != '') {
        (document.getElementById('kcpcert_student') as HTMLLIElement).style.display = 'none';
        setPhoneNumber(msgdata.cellno);
        setParentName(msgdata.name);
        handleNext(msgdata.cellno);
        // setFirstStepDone(true);
      }
    }
  };

  useEffect(() => {
    signUpBtnDisabled.current = false;
    //! kcpcert 세팅
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  const onClickKCP = () => {
    const frame = document.getElementById('kcpcert_student') as HTMLIFrameElement;

    if (frame && frame.contentWindow) {
      frame.contentWindow.postMessage('kcpcert_start', HOMEPAGE_URL ? HOMEPAGE_URL : '*');
    }
  };

  //! Checkbox
  const onClickCheckbox = useCallback((idx: number) => {
    if (idx == 1) {
      // 이용약관
      setIsChecked1(prev => !prev);
    } else {
      // 개인정보취급방침
      setIsChecked2(prev => !prev);
    }
  }, []);

  //! 다음 단계로 넘기는 함수 (+ 기존 사용자인지 신규 회원인지 check하기)
  const handleNext = useCallback(async (phoneNumber: string) => {
    try {
      const uri = `/signup/find_account?loginid=${phoneNumber}`;
      const res = await fetchGetApi(uri);
      console.log('res', res);
      if (res.result) {
        // * 기존에 존재하는 회원
        const date = new Date(res.data.accountInfo.postdate);
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
          date.getDate(),
        ).padStart(2, '0')}`;
        setPostdate(formattedDate);
        setExistMember(true);
      } else {
        // * 신규 회원
        setExistMember(false);
      }

      setStep(prev => prev + 1);
    } catch (error) {
      console.log(error);
    }
  }, []);

  //! step2 로그인 버튼 클릭 메서드
  const onClickToLoginPage = useCallback(() => {
    outSignupPage();
  }, []);

  //! 비밀번호 입력 메서드
  const onChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  //! 비밀번호 검증
  const passwordValidate = useCallback((password: string) => {
    if (password.length < 8) {
      setErrMsgPassword('최소 8자 이상입니다.');
      return false;
    }
    if (password.length > 20) {
      setErrMsgPassword('최대 20자 이하입니다.');
      return false;
    }
    const regex =
      /^(((?=.*[a-z])(?=.*[!@#$%&*_+]))|((?=.*[!@#$%&*_+])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9])))(?!.*[A-Zㄱ-힣])(\S){8,20}$/;

    if (!regex.test(password)) {
      setErrMsgPassword('영문 소문자, 숫자, 특수문자 등으로 다시 조합하세요.');
      return false;
    }

    setErrMsgPassword('');
    return true;
  }, []);

  //! step2 회원가입 버튼 클릭시 처리
  const handleSubmitToSignup = async () => {
    if (passwordValidate(password) && signUpBtnDisabled.current == false) {
      signUpBtnDisabled.current = true;
      // * 비밀번호 검증 통과 X
      const params: { [key: string]: string } = {
        loginId: phoneNumber,
        password: password,
        name: parentName,
        centerName: '홈스쿨',
        phone: autoHypenPhone(phoneNumber),
        type: 'B',
      };

      try {
        const res = await fetchSuperPostApi(`/longvoca/customers/freetrial?ver=2`, params);
        if (res.result) {
          modal_alert.openModalAlert(
            'empty',
            undefined,
            `회원가입 되었습니다. \r\n담당자를 통해 곧 연락드리겠습니다.`,
            () => {
              outSignupPage();
              signUpBtnDisabled.current = false;
            },
          );
        } else {
          modal_alert.openModalAlert('empty', undefined, res.message);
          signUpBtnDisabled.current = false;
        }
      } catch (error) {
        console.error(error);
        modal_alert.openModalAlert('error_post_failed');
        signUpBtnDisabled.current = false;
      }
    }
  };

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform === 'android') setTop('-40vh');
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform === 'android') setTop('0px');
  };

  const ifrmae_url = useMemo(() => {
    return `${HOMEPAGE_URL}/lib/certcellno_student_2/SMART_ENC/smartcert_start.php`;
  }, []);

  const onClickBackButton = useCallback(() => {
    navigate(`${BASE_URL}/login`);
  }, []);

  const props = {
    step,
    existMember,
    firstStepDone,
    parentName,
    phoneNumber,
    isChecked1,
    isChecked2,
    postdate,
    password,
    errMsgPassword,
    onClickUnderbar,
    onClickQuestion,
    onClickCheckbox,
    onClickKCP,
    handleNext,
    onClickToLoginPage,
    onChangePassword,
    handleSubmitToSignup,
    top,
    onFocus,
    onBlur,
    ifrmae_url,
    is_mobile,
    onClickBackButton,
  };

  return <SignupUI {...props} />;
}
