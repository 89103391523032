export function setTitle(payload: {
  title: string;
  sub_title: string | null | undefined;
  target: string | null | undefined;
}) {
  const title = payload.title;
  const sub_title = payload.sub_title;
  if (!payload.sub_title) {
    if (!payload.target) {
      return title;
    } else {
      return title.replace('target', `${payload.target}`);
    }
  } else {
    let tmp_title = title;
    let tmp_sub_title = sub_title;
    if (payload.target) {
      tmp_sub_title = tmp_sub_title?.replace('target', payload.target);
      tmp_title = title?.replace('target', payload.target);
    }

    return `<span>${tmp_title}</span><br/><span class="sub_title">${tmp_sub_title}</span>`;
  }
}

export function setContents(payload: { contents: string; score: string | null | undefined }) {
  const contents = payload.contents;

  let tmp_contents = contents;
  if (payload.score) {
    tmp_contents = contents?.replace('score', payload.score);
  }

  return `<span>${tmp_contents}</span>`;
}

export const confirm_buttons: { [key: string]: string[] } = {
  normal: ['확인', '취소'],
  normal_reverse: ['취소', '확인'],
  edit: ['수정', '취소'],
  registration: ['등록', '취소'],
  edit_reverse: ['취소', '수정'],
  registration_reverse: ['취소', '등록'],
  power: ['종료', '취소'],
  report: ['확인', '종료'],
  normal_one: ['확인'],
  logout: ['종료', '로그아웃'],
  exit_study_1: ['학습하기', '저장 후 종료'],
  exit_study_2: ['학습하기', '종료'],
  exit_study_3: ['학습하기', '취소'],
};

export const study_confirm_buttons: { [key: string]: string[] } = {
  normal: ['확인', '취소'],
  continue: ['이어서 학습', '처음부터 학습'],
  relearning: ['재학습', '취소'],
  relearning2: ['재학습', '성적표'],
  exit_study_3: ['학습하기', '취소'],
  end_video: ['다음 학습', '재시청'],
  review_video: ['확인', '재시청'],
};

export const confirm_contents: { [key: string]: { title: string; sub_title?: string; target?: string } } = {
  add_contents: { title: 'target 등록하시겠습니까?' },
  update_contents: { title: 'target 수정하시겠습니까?' },
  start_contents: { title: 'target 시작하시겠습니까?' },
  check_contents: { title: 'target 확인하시겠습니까?' },
  delete_contents: {
    title:
      '<span style="line-height: 2rem; font-family: Apple SD Gothic Neo;">선택 항목을 정말 삭제하시겠습니까?</span>\r\n<span style="line-height: 1.45rem; font-family: Apple SD Gothic Neo;" class="text-gray-1">(삭제된 target 복구되지 않습니다.)</span>',
  },
  registering: { title: '작성 중인 내용이 있습니다.\r\ntarget' },
  delete_student: {
    title: '선택 항목을 정말 삭제하시겠습니까?',
    sub_title: '<span class="text-gray-1">(삭제된 학생은 복구되지 않습니다.)</span>',
  },
  quit_voca: { title: '오래영어를 종료하시겠습니까?' },
  move_main: { title: '초기화면으로 이동하시겠습니까?' },
  reset_image: {
    title: '기본 target 설정하시겠습니까?',
  },
  move_page: { title: 'target 이동하시겠습니까?' },
  move_period: { title: '최근 10일 현황을 조회하시겠습니까?' },
  used_folder: {
    title: '폴더 내 항목이 존재합니다.<br />그럼에도 정말 폴더를 삭제하시겠습니까?',
    sub_title: '<span class="text-gray-1">(삭제된 항목은 복구할 수 없습니다.)</span>',
  },
  move_study_module: {
    title: 'target로 이동하시겠습니까?',
  },
  move_study_module2: {
    title: 'target으로 이동하시겠습니까?',
  },
  exit_memorize: {
    title: '학습을 종료하시겠습니까?',
  },
  exit_basic: {
    title: '기본학습을 종료하시겠습니까?',
  },
  exit_advanced: {
    title: '심화학습을 종료하시겠습니까?',
  },
  exit_speak: {
    title: '학습을 종료하시겠습니까?',
  },
  exit_grammar_t: {
    title: '이론학습을 종료하시겠습니까?',
  },
  start_restudy: {
    title: '재학습을 하시겠습니까?',
  },
  start_advanced: {
    title: '심화학습을 하시겠습니까?',
  },
  confirm_advanced: {
    title:
      '<span style="padding-top: 0.625rem; line-height: 1.5; font-family: Apple SD Gothic Neo;">학습 능률을 위해 심화학습을 권장합니다.</span><span style="font-size: 1.1rem; line-height: 1.5; font-family: Apple SD Gothic Neo;">심화학습을 하시겠습니까?</span>',
  },
  start_wrong: {
    title: '오답학습을 하시겠습니까?',
  },
  confirm_wrong: {
    title:
      '<span style="padding-top: 0.625rem; line-height: 1.5; font-family: Apple SD Gothic Neo;">학습 능률을 위해 오답학습을 권장합니다.</span><span style="font-size: 1.1rem; line-height: 1.5; font-family: Apple SD Gothic Neo;">오답학습을 하시겠습니까?</span>',
  },
  exit_wrong: {
    title: '오답학습을 종료하시겠습니까?',
  },
  exit_report: {
    title: '레포트를 종료하시겠습니까?',
  },
  recycle_memorize: {
    title: '암기 내역을 무시하고 처음부터 학습하시겠습니까?',
  },
  delete_note: {
    title:
      '<span style="line-height: 1.5; font-family: Apple SD Gothic Neo;">폴더 내 항목이 존재합니다.\r\n그럼에도 정말 폴더를 삭제하시겠습니까?</span>\r\n<span class="text-gray-1" style="padding-top: 0.75rem; line-height: 1; font-family: Apple SD Gothic Neo;">(삭제된 항목은 복구할 수 없습니다.)</span>',
  },
  duplicate_login: {
    title: '회원님의 계정이 다른 기기에서 접속 중입니다.\r\n다른 기기는 로그아웃되며, 현재 기기로 로그인합니다.',
  },
};

export const study_confirm_contents: { [key: string]: { title: string; sub_title?: string; target?: string } } = {
  move_record: { title: '성적표를 확인하시겠습니까?', sub_title: '<span class="text-gray-1">target</span>' },
  continue: {
    title: '현재 학습 진행중입니다.',
    sub_title: '<span class="text-gray-1">target</span>',
  },
  relearning: {
    title: '학습이 완료되었습니다.',
    sub_title: '<span class="text-gray-1">target</span>',
  },
  study_start_t: {
    title: '이론학습을 시작하시겠습니까?',
    sub_title: '<span class="text-gray-1">target</span>',
  },
  study_start_b: {
    title: '기본학습을 시작하시겠습니까?',
    sub_title: '<span class="text-gray-1">target</span>',
  },
  study_start_d: {
    title: '심화학습을 시작하시겠습니까?',
    sub_title: '<span class="text-gray-1">target</span>',
  },
  end_video: {
    title: '이론 영상강의가 종료되었습니다.',
    sub_title: '<span class="text-gray-1">target</span>',
  },
  grammar_continue: {
    title: '이미 학습된 내용이 있습니다.',
    sub_title: '<span class="text-gray-1">target</span>',
  },
  grammar_relearning: {
    title: '재학습을 시작하시겠습니까?',
    sub_title: '<span class="text-gray-1">target</span>',
  },
};

export const alert_contents: { [key: string]: { title: string; sub_title?: string; target?: string } } = {
  success_cerfication_password: {
    title: '고객님의 휴대폰 번호로 임시 비밀번호가 전송되었습니다.\r\n로그인 후 비밀번호를 변경해주세요.',
  },
  error_post_failed: { title: '오류로 인해 요청이 실패되었습니다.\r\n다시 시도해주세요.' },
  success_send_certification_number: { title: '입력하신 휴대폰 번호로 인증번호를 발송하였습니다.' },
  failed_send_certification_number: { title: '문의하신 고객님의 정보는 존재하지 않습니다.' },
  certification_time_over: { title: '인증 시간이 종료되었습니다.' },
  up_font_level: {
    title: '글씨 크기가 커졌습니다. (target)',
  },
  limit_up_font_level: {
    title: '가장 큰 글씨 크기입니다.',
  },
  down_font_level: {
    title: '글씨 크기가 작아졌습니다. (target)',
  },
  limit_down_font_level: {
    title: '가장 작은 글씨 크기입니다.',
  },
  personal_product_alert: {
    title: `구매하신 상품에서는 이용할 수 없습니다.\r\n<span id="change-product-inquiry" class="text-blue" style="font-weight: 700; cursor: pointer;">상품변경 문의하기 ></span>`,
  },
  personal_product_student_alert: {
    title: `현재 학습 불가한 상태입니다.\r\n관리자에게 문의해주세요.`,
  },
  upload_limit: { title: '업로드 가능한 최대 용량은 10MB입니다.' },
  empty: { title: 'target' },
  login: {
    title: '<span class="text-purple" style="font-weight: 700">target</span>\r\n오래영어에 오신 것을 환영합니다.',
  },
  is_ready_checkup_note: { title: '체크업 노트는 곧 서비스 오픈됩니다.' },
  please_off_full_screen: { title: '프로필 사진 변경을 위해 전체화면을 해제해주세요.' },
  check_mic: { title: '연결된 녹음 기기가 없습니다.\r\n녹음기기 연결 상태 및 학습 기기의 권한 설정을 확인해주세요.' },
  login_wrong: { title: '아이디 or 비밀번호가 일치하지 않습니다.\r\n다시 확인해주세요!' },
  stop_center: {
    title:
      '현재 학습 <span style="font-weight: 700">사용중지</span> 상태입니다.\r\n학습을 원하시는 경우 관리자에게 문의해주세요.',
  },
  stop_center2: {
    title: '현재 학습 <span style="font-weight: 700">사용중지</span> 상태입니다.',
  },
  login_empty: { title: '모든 정보를 입력해주세요.' },
  login_auto_expires_error: { title: '로그인 유지 기간이 지났습니다.\r\n다시 로그인해주세요.' },
  ready_service: { title: '체크업 학습은 곧 서비스 오픈됩니다.' },
  success_setting: { title: 'target 설정되었습니다.' },
  success_delete: { title: 'target 삭제되었습니다.' },
  success_create: { title: 'target 생성되었습니다.' },
  success_modify: { title: 'target 수정되었습니다.' },
  success_choice: { title: 'target 선택되었습니다.' },
  add_pocketnote: { title: '포켓노트에 등록되었습니다.' },
  disabled_book: { title: '해당 교재는 비활성화 되어있습니다.\r\n담당 관리자에게 문의하세요!' },
  disabled_series: { title: '해당 시리즈는 비활성화 되어있습니다.\r\n담당 관리자에게 문의하세요!' },
  prevunit_not_comp: { title: '이전 일차를 먼저 학습하세요.' },
  memorize_not_comp: { title: '암기 학습을 먼저 학습해주세요.' },
  basic_not_comp: { title: '기본 학습을 먼저 학습해주세요.' },
  add_bookmark: { title: '북마크 교재에 설정되었습니다.' },
  remove_bookmark: { title: '북마크 교재에서 해제되었습니다.' },
  change_settings: { title: '설정이 변경되었습니다.\r\n학습 종료 후 다시 학습을 시작해주세요.' },
  memorize_start: { title: '제시된 단어를 자유롭게 반복 암기하세요!' },
  memorize_success: { title: '암기 완료! 목록에서 제외되었습니다.' },
  memorize_voice_on: { title: '학습 음성을 ON 하였습니다.' },
  memorize_voice_off: { title: '학습 음성을 OFF 하였습니다.' },
  memorize_auto_on: { title: '자동 학습을 ON 하였습니다.' },
  memorize_auto_off: { title: '자동 학습을 OFF 하였습니다.' },
  flashcard_start: { title: '단어 카드를 반복하여 들어보세요!' },
  wordbingo_start: { title: '뜻에 알맞은 단어 카드를 선택해보세요!' },
  popquiz_start: { title: '문제에 알맞는 target해보세요!' },
  dictation_start: { title: '음성을 듣고 알맞은 단어를 입력해보세요!' },
  writing_start: { title: '제시된 뜻에 알맞게 단어 철자를 입력해보세요!' },
  pc_speaking_start: { title: '제시된 내용을 그대로 따라 말해보세요.\n최소 2~3회 반복해보세요.' },
  speaking_start: { title: '제시된 단어에 알맞게 직접 말해보세요!' },
  speak_video_start: { title: 'AI 선생님과 함께 추천 학습영상을 시청하세요!' },
  speak_check_start: { title: '예문 빈 칸에 알맞는 중요단어를 선택하세요!' },
  speak_speaking_start: { title: '다시 한 번 듣고 원어민처럼 따라 말해보세요!' },
  grammar_t_0: { title: '이론학습의 영상강의가 시작됩니다.' },
  grammar_t_1: { title: '이론학습의 단어점검이 시작됩니다.' },
  grammar_t_2: { title: '이론학습의 이론점검이 시작됩니다.' },
  grammar_b_0: { title: '기본학습의 기본학습1이 시작됩니다.' },
  grammar_b_1: { title: '기본학습의 기본학습2가 시작됩니다.' },
  grammar_b_2: { title: '기본학습의 기본학습3이 시작됩니다.' },
  grammar_b_3: { title: '기본학습의 기본학습4가 시작됩니다.' },
  grammar_b_r: { title: '기본학습이 시작됩니다.' },
  grammar_d_0: { title: '심화학습의 심화학습1이 시작됩니다.' },
  grammar_d_1: { title: '심화학습의 심화학습2가 시작됩니다.' },
  grammar_d_r: { title: '심화학습의 단원시험이 시작됩니다.' },
  ios_speaking_init: {
    title:
      '<span class="text-gray-1">말하기 학습 후,</span>\n<b>아이콘을 누르면 음성 인식이 완료</b><span class="text-gray-1">됩니다.</span>',
  },
  mic_permisson_failed: {
    title: '마이크 사용이 차단되어 있습니다.\n주소창 우측 끝 마이크 권한을 허용해주세요.',
  },
  mic_permisson_changed: {
    title: '마이크 설정이 변경되었습니다.\n학습을 다시 시작해주세요.',
  },
  mic_not_found: {
    title: '연결된 녹음 기기가 없습니다.\n마이크 및 헤드셋 연결을 확인해주세요.',
  },
  mic_ios_busy: {
    title: '다른 어플리케이션에서 마이크를 사용중입니다.\n스피킹 학습시 다른 어플리케이션을 종료해주세요.',
  },
  module_move_unable: { title: '이미 완료된 학습입니다.' },
  do_not_search_keyword: { title: '해당 키워드와 관련된 내용이 없습니다.' },
  fullscreen_on: { title: '전체 화면이 실행되었습니다.' },
  fullscreen_off: { title: '전체 화면이 해제되었습니다.' },
  admin_rank: {
    title: '관리자 계정은 리더보드 순위에 반영되지 않습니다.',
  },
  unused_rank: {
    title: '리더보드를 확인하려면, 관리자에게 문의하세요.',
  },
  relaerning_limit: {
    title: '재학습 가능 횟수(3회)를 모두 진행하셨습니다.',
  },
  unable_relearning: {
    title: '수업 설정 상 재학습이 불가능합니다.',
  },
  unchangeable_setting_student: {
    title: '본 기능은 관리자가 설정하신 내용으로, 수정 불가합니다.',
  },
  unchangeable_setting_admin: {
    title: '해당 기능은 관리자 모드에서 수정 부탁드립니다.',
  },
  unready_wrong_study: {
    title: '오답 학습 생성 전 입니다.',
  },
  trial_book: {
    title: '다른 사용자의 수업자료를 무상 공유해드립니다.\r\n배정된 담당자를 통해 문의하세요!',
  },
  pocketnote_bookmark_check: {
    title: '책갈피를 해제 후 선택해주세요.',
  },
  temp_account: {
    title: '체험계정으로 이용이 제한되며, 학생을 신규 등록해주세요.',
  },
  temp_account_datepicker: {
    title: '기간 조회는 정식가입 이후 상세 검색 가능합니다.',
  },
  temp_record: {
    title: '체험 학생이므로, 성적표는 저장되지 않습니다.',
  },
  mission_student_login: {
    title: '등록하신 학생 아이디로 로그인 하세요.',
  },
  change_video_setting: {
    title: '설정이 변경되어 처음부터 다시 학습합니다.',
  },
  video_learning_start: {
    title: '이론학습의 영상강의가 시작됩니다.',
  },
};

export const check_contents: { [key: string]: { title: string; contents: string; score?: string } } = {
  checkupnote_check: {
    title: '체크업 노트',
    contents:
      '체크업 <span class="text-black">학습 결과를 조회</span>할 수 있습니다.\r\n\r\n다양한 체크업 학습 기능으로\r\n<span class="text-black">단어 암기왕에 도전</span>하세요!',
  },
  learningschedule: {
    title: '학습 스케쥴',
    contents:
      '관리자가 부여해주신 학습 교재가\r\n<span class="text-black">수업 요일</span>에 맞게 <span class="text-black">자동 스케쥴링</span> 됩니다.\r\n\r\n(단, <span class="text-black">1회 이상 교재 학습 후 자동생성</span>)',
  },
  learningbook: {
    title: '스케쥴 교재',
    contents:
      '관리자가 <span class="text-black">부여해주신 학습 교재</span>의\r\n<span class="text-black">학습방식 및 상세정보</span>를 확인 가능합니다.\r\n\r\n(자주쓰는 교재는 북마크 설정 가능)',
  },
  board: {
    title: '리더보드',
    contents:
      '클래스별 <span class="text-black">성적 우수학생</span>을 조회합니다.\r\n\r\n(리더보드 결과는 <span class="text-black">최소 3회 학습 이상,</span>\r\n<span class="text-black">누적 학습량, 평균 성적</span>을 기반으로 산정)',
  },
  record: {
    title: '수상이력',
    contents:
      '나의 리더보드 <span class="text-black">수상이력을 조회</span>합니다.\r\n\r\n수상년월을 기준으로\r\n<span class="text-black">당월 학습 상세기록</span>을 확인하세요!',
  },
  lookup: {
    title: '학습 결과',
    contents:
      '<span class="text-black">학습결과를 내역</span>으로 확인 가능합니다.\r\n(학습형태, 교재명, 학습일 순 정렬)\r\n\r\n레포트 <span class="text-black">내역을 선택하면, 상세조회</span> 가능!',
  },
  period: {
    title: '레포트 기간분석',
    contents:
      '기간을 선택하여 <span class="text-black">학습 결과를 분석</span>합니다.\r\n(<span class="text-black">학습 문제수, 오답률, 종합점수</span> 등)\r\n\r\n지금 기간별 <span class="text-black">성취도 결과</span>를 확인하세요!',
  },
  month: {
    title: '레포트 월별분석',
    contents:
      '당해년 월별<span class="text-black">학습 결과를 분석</span>합니다.\r\n(<span class="text-black">학습 문제수, 오답률, 종합점수</span> 등)\r\n\r\n지금 기간별 <span class="text-black">성취도 결과</span>를 확인하세요!',
  },
  pocketnote: {
    title: '포켓 노트',
    contents:
      '<span class="text-black">나만의 복습 노트</span>를 만들 수 있습니다!\r\n\r\n혼돈, 빈출, 오답 어휘를 담아두고\r\n<span class="text-black">체크업 학습으로 반복 암기</span>해보세요!',
  },
  notice: {
    title: '공지사항',
    contents:
      '<span class="text-black">관리자 전달사항</span>을 조회할 수 있습니다.\r\n\r\n학습내용, 공지사항, 반별공지 등\r\n<span class="text-black">실시간으로 확인하세요!<span>',
  },
  notification: {
    title: '학습 알림센터',
    contents:
      '나의 <span class="text-black">학습 상세이력을 조회합니다.</span>\r\n\r\n스케쥴부터 학습, 평가 이력까지\r\n모든 학습 내역을 조회할 수 있습니다.',
  },
  manual: {
    title: '사용자 매뉴얼',
    contents:
      '<span class="text-black">프로그램 사용법을 조회</span>합니다.\r\n\r\n카테고리별 매뉴얼을 활용하여,\r\n<span class="text-black">숨겨진 학습관리 기능을 확인</span>하세요!',
  },
  qna: {
    title: '자주 묻는 질문',
    contents:
      '<span class="text-black">자주 묻는 질문을 모았습니다!</span>\r\n\r\n기기 이용부터 학습관련 내용까지\r\n<span class="text-black">프로그램 궁금증을 해결</span>해드립니다.',
  },
  settings: {
    title: '학습설정',
    contents:
      '오래영어의 <span class="text-black">모든 설정을 관리</span>합니다.\r\n\r\n나의 <span class="text-black">학습 환경에 맞게</span>\r\n설정을 변경하여 이용해주세요!',
  },
  longvoca: {
    title: '오래보카',
    contents:
      '오래보카만의 베스트셀러 컨텐츠\r\n<span class="text-black">스마트 보카 시리즈</span>를 모두 무료 제공합니다!\r\n\r\n더 빨리, 더 오래 기억되는 영어를 시작하세요!',
  },
  speak: {
    title: '오래회화',
    contents:
      '오래영어만의 실용회화 컨텐츠\r\n<span class="text-black">상황별 회화 시리즈를</span> 모두 무료 제공합니다!\r\n\r\n더 빨리, 더 오래 기억되는 영어를 시작하세요!',
  },
  customer: {
    title: '만든 교재',
    contents:
      '<span class="text-black">다른 사용자들이 직접 만든 교재</span>를\r\n<span class="text-black">무료로 공유</span> 받을 수 있습니다.\r\n\r\n고객센터 및 담당자에게 신청하세요.',
  },
  customer2: {
    title: '만든 교재',
    contents:
      '<span class="text-black">다른 사용자들이 직접 만든 교재</span>를\r\n<span class="text-black">무료로 공유</span> 받을 수 있습니다.\r\n\r\n담당 관리자에게 신청하세요.',
  },
  textbook: {
    title: '영어 교과서',
    contents:
      '초등부터 고등까지 <span class="text-black">전 학년대</span>\r\n<span class="text-black">영어 교과서 기출단어</span>를 제공합니다.\r\n\r\n교과서를 선택하여 학습해보세요!',
  },
  grammar: {
    title: '오래문법',
    contents:
      '오래영어와 일타강사가 함께하는\r\n<span class="text-black">초중등 필수 영문법 컨텐츠</span>를 무료 제공합니다!\r\n\r\n더 빨리, 더 오래 기억되는 영어를 시작하세요!',
  },
  exam: {
    title: '수능/모의고사',
    contents:
      '연도별 <span class="text-black">수능 및 모의고사</span>\r\n주요 어휘 자료를 모두 지원합니다.\r\n\r\n모의평가를 선택하여 학습해보세요!',
  },
  score1: { title: 'Excellent!', contents: 'score' },
  score2: { title: 'Bravo!', contents: 'score' },
  score3: { title: 'Good job!', contents: 'score' },
  score4: { title: 'Go for it!', contents: 'score' },
  score5: { title: 'Try Harder!', contents: 'score' },
  chrome: {
    title: '',
    contents:
      '<span class="text-black">오래영어 온라인 학습은\r\n크롬 브라우저에서만 가능합니다.</span>\r\n\r\n(확인을 누르시면 크롬 다운로드로 이동합니다.)',
  },
};
