import { styled, Box } from '@mui/material';
import theme from '../theme';
import memorize_start from '../assets/images/study/memorize_start.png';
import memorize_done from '../assets/images/study/memorize_done.png';
import basic_start from '../assets/images/study/basic_start.png';
import basic_done from '../assets/images/study/basic_done.png';
import advanced_start from '../assets/images/study/advanced_start.png';
import advanced_done from '../assets/images/study/advanced_done.png';
import wrong_unready from '../assets/images/study/wrong_unready.png';
import wrong_start from '../assets/images/study/wrong_start.png';
import wrong_done from '../assets/images/study/wrong_done.png';

const d_block = { display: 'block' };

const d_flex = { display: 'flex' };

const d_flex_center = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const d_flex_space_between = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };

const d_flex_space_evenly = { display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' };

const d_flex_space_around = { display: 'flex', alignItems: 'center', justifyContent: 'space-around' };

const d_flex_start = { display: 'flex', alignItems: 'center', justifyContent: 'flex-start' };

const d_flex_end = { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' };

const ignore_drag = { userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none' };

const text_clamp = {
  overflow: 'hidden',
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
  display: '-webkit-box !important',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'keep-all',
};

interface FlexDirection {
  flexDirection: 'row' | 'column';
}

interface StyledUtilWrapProps {
  $marginTop?: string;
  $width?: string;
}

interface StyledDirectionProps {
  $width: string;
  $direction?: string;
}

const dir_row: FlexDirection = { flexDirection: 'row' };

const dir_column: FlexDirection = { flexDirection: 'column' };

const Deco = styled(Box)({
  display: 'inline-block',
  marginRight: '0.5rem',
  borderLeft: `1px dotted ${theme.palette.gray_1.main}`,
  borderBottom: `1px dotted ${theme.palette.gray_1.main}`,
  transform: 'translateY(-50%)',
});

interface BarProps {
  $bgColor?: string;
  $marginLeft?: boolean;
}

export const Bar = styled(Box)<BarProps>(props => ({
  width: '2px',
  height: '1rem',
  marginRight: '0.5rem',
  marginLeft: props.$marginLeft ? '0.5rem' : '',
  backgroundColor: props.$bgColor ? props.theme.palette[props.$bgColor].main : props.theme.palette.black.main,
}));

interface BorderProps {
  $borderWidth: string;
  $borderStyle: string;
  $borderColor: string;
}

export const Border = styled(Box)<BorderProps>(props => ({
  borderWidth: props.$borderWidth,
  borderStyle: props.$borderStyle,
  borderColor: props.theme.palette[props.$borderColor].main,
}));

// ModalCheck, ModalConfirm Style 같은 부분들 존재해서 한 번만 작성후 가져다 사용하는 것으로 세팅
const StyledModalWrapper = styled(Box)(props => ({
  maxWidth: '480px',
  width: '35.7rem',
  borderRadius: '4px',
  lineHeight: '1.2',
  backgroundColor: props.theme.palette.white.main,
  ...d_flex_center,
  ...dir_column,
}));

const ConfirmModalWrapper = styled(Box)(props => ({
  maxWidth: '480px',
  width: '35.7rem',
  borderRadius: '4px',
  lineHeight: '1.2',
  backgroundColor: props.theme.palette.white.main,
  ...d_flex_center,
  ...dir_column,
  '@media (max-width: 1280px)': {
    width: '30.15rem',
  },
}));

const StyledModalTitle = styled(Box)(props => ({
  width: '100%',
  fontSize: '1.1rem',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '2.4rem 1.2rem',
  ...d_flex_center,
  ...dir_column,
  '& > span': {
    '&:last-of-type': {
      fontSize: '1rem',
    },
  },
}));

const StyledModalUtil = styled(Box)(props => ({
  width: '100%',
  height: '3.25rem',
  borderTop: `1px solid ${props.theme.palette.gray_3.main}`,
  ...d_flex_center,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

const StyledLine = styled(Box)(props => ({
  width: '1px',
  height: '100%',
  backgroundColor: props.theme.palette.gray_3.main,
  '@media(max-width: 1023px)': {
    backgroundColor: '#f5f5f5',
  },
}));

const StyledUtilWrap = styled(Box)<StyledUtilWrapProps>(props => ({
  width: props.$width ? props.$width : '100%',
  height: '2.5rem',
  marginBottom: '0.5rem',
  marginTop: props.marginTop === 'true' ? '0.5rem' : '0',
  ...d_flex_space_between,

  '.regi': {
    '&.edit-page': {
      position: 'fixed',
      top: 'calc(9.5rem + 8px)',
      height: '2.5rem',
      '&.student': {
        top: 'calc(12.5rem)',
      },
    },
  },
  '.require_text': {
    position: 'fixed',
    height: '2.5rem',
    top: 'calc(12.5rem)',
    width: '100%',
  },
  '.scroll_box': {
    position: 'fixed',
    height: '3rem',
    width: '3rem',
    bottom: '12px',
    right: '12px',

    '& > div': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: '#fafafa',
      border: '1px solid',
      borderColor: '#e4e4e4',
      borderRadius: '4px',
      fontSize: '0.9rem',
      cursor: 'pointer',

      svg: {
        marginTop: '-0.2rem',
        width: '1rem',
        height: '1rem',
        strokeWidth: 0.5,
      },
    },
  },
}));

const StyledUtil = styled(Box)<StyledDirectionProps>(props => ({
  width: props.$width,
  height: '100%',
  alignItems: 'center',
  justifyContent: props.$direction === 'left' ? 'flex-start' : 'flex-end',
  ...d_flex,
}));

const mode_imgaes = {
  memorize_start,
  memorize_done,
  basic_start,
  basic_done,
  advanced_start,
  advanced_done,
  wrong_unready,
  wrong_start,
  wrong_done,
};

const skelton_animatino_wave = {
  position: 'relative',
  overflowX: 'hidden',
  '&::after': {
    content: "''",
    animation: 'skeletonwave 2s linear 0.5s infinite',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    transform: 'translateX(-100%)',
    background: `linear-gradient(
                    90deg,
                    transparent,
                    rgba(0, 0, 0, 0.05),
                    transparent
                  )`,
    width: '100%',
    height: '100%',
  },
};

export {
  d_block,
  d_flex,
  d_flex_center,
  d_flex_start,
  d_flex_end,
  d_flex_space_between,
  d_flex_space_evenly,
  d_flex_space_around,
  ignore_drag,
  dir_row,
  dir_column,
  Deco,
  StyledModalWrapper,
  ConfirmModalWrapper,
  StyledModalTitle,
  StyledModalUtil,
  StyledLine,
  StyledUtilWrap,
  StyledUtil,
  mode_imgaes,
  text_clamp,
  skelton_animatino_wave,
};
