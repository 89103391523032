import { Box, Button as MuiButton, styled, IconButton } from '@mui/material';
import Button from '../../button/Button';

export const Wrapper = styled(Box)({
  position: 'fixed',
  left: '0px',
  top: '0px',
  backgroundColor: 'rgba(25, 25, 25, .9)',
  width: '100vw',
  height: '100vh',
  zIndex: '31',
});

export const CountBox = styled(Box)({
  paddingBottom: '0.25rem',
  '@media (max-width: 1903px)': {
    paddingBottom: '0.15rem',
  },
  '@media (max-width: 1263px)': {
    paddingBottom: '0.15rem',
  },
  '@media (max-width: 959px)': {
    paddingBottom: '0.1rem',
  },
  '@media (max-width: 767px)': {
    paddingBottom: '0.15rem',
  },
  '@media (max-width: 599px)': {
    paddingBottom: '0.1rem',
  },
  position: 'absolute',
  height: '3rem',
  minWidth: '8rem',
  backgroundColor: '#2b2b2b',
  color: '#fff',
  fontSize: '1.5rem',
  borderRadius: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '5.7vh',
  left: '3.2vw',
  fontFamily: `NanumSquare`,
});

export const TutorialButton = styled(MuiButton)(props => ({
  paddingTop: '0.5rem',
  '@media (max-width: 1903px)': {
    paddingTop: '0.65rem',
  },
  '@media (max-width: 1263px)': {
    paddingTop: '0.75rem',
  },
  '@media (max-width: 959px)': {
    paddingTop: '1rem',
  },
  '@media (max-width: 767px)': {
    paddingTop: '0.85rem',
  },
  '@media (max-width: 599px)': {
    paddingTop: '1.2rem',
  },
  position: 'absolute',
  height: '3rem',
  minWidth: '15rem',
  backgroundColor: '#fff',
  color: '#2d2d2d',
  fontSize: '1.5rem',
  borderRadius: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '5.7vh',
  right: '3.2vw',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#2d2d2d',
  },
  '&.Mui-disabled': {
    backgroundColor: '#2b2b2b',
    color: props.theme.palette.white.main,
    '&:hover': {
      backgroundColor: '#2b2b2b',
      color: props.theme.palette.white.main,
    },
  },
}));

export const CenterImageBox = styled(Box)({
  position: 'absolute',
  top: '20.125vh',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    height: '13.25rem',
  },
  '@media (max-height: 412px)': {
    img: {
      height: '11rem',
    },
  },
});

export const ExplainText = styled(Box)(props => ({
  color: props.theme.palette.white.main,
  top: '49.2vh',
  position: 'absolute',
  fontSize: '2.05rem',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  left: '50%',
  transform: 'translateX(-50%)',
  lineHeight: '1.55',
  fontFamily: `NanumSquare`,
  width: '100%',
  letterSpacing: '-1px',
  b: {
    fontFamily: `NanumSquare`,
    fontWeight: '800',
  },
}));

export const EndTuTorial = styled(Box)(props => ({
  color: '#6a6a6a',
  fontSize: '1.5rem',
  position: 'absolute',
  bottom: '5.1vh',
  minWidth: '12rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  right: '3.2vw',
  textDecoration: 'underline',
  cursor: 'pointer',
  '@media (max-height: 412px)': {
    bottom: '2.1vh',
  },
}));

export const ContentWrap = styled(Box)({
  left: '50%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1vh 1vh',
  minWidth: 'calc(70vw + 1vh)',
  transform: 'translateX(-50%)',
  position: 'absolute',
  bottom: '12.75vh',
  height: '17vh',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledIconButton = styled(IconButton)({
  backgroundColor: 'transparent',
  width: '3.75rem',
  height: '3.75rem',
  position: 'absolute',
  top: '54.75vh',
  transform: 'translateY(-50%)',
  svg: {
    width: '3.75rem',
    height: '3.75rem',
    strokeWidth: '4.5rem',
    color: '#e8e9ec',
  },
  '@media (max-width: 1903px)': {
    width: '4.5rem',
    height: '4.5rem',
    svg: {
      width: '4.5rem',
      height: '4.5rem',
      strokeWidth: '4.5rem',
    },
  },
  // '@media (max-width: 1263px)': {
  //   paddingBottom: '0.15rem',
  // },
  '@media (max-width: 959px)': {
    width: '5.5rem',
    height: '5.5rem',
    svg: {
      width: '5.5rem',
      height: '5.5rem',
      strokeWidth: '10.5rem',
    },
  },
  '@media (max-width: 767px)': {
    width: '5rem',
    height: '5rem',
    svg: {
      width: '5rem',
      height: '5rem',
      strokeWidth: '5rem',
    },
  },
  '@media (max-width: 599px)': {
    paddingBottom: '0.1rem',
  },
  '&.d-none': {
    display: 'none',
  },
});
