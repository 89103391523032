import React from 'react';
import { styled, Box, Checkbox, SxProps, Theme } from '@mui/material';
import { FiCornerLeftUp } from 'react-icons/fi';

const TableRow = styled(Box)<{ isfolder?: string; ischecked?: string }>(props => ({
  position: 'relative',
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: '3.5rem 5.5fr 5.5fr',
  backgroundColor: props.ischecked === 'true' ? props.theme.palette.light_purple_3.main : 'transparent',
  color: props.ischecked === 'true' ? props.theme.palette.purple.main : '#000',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '&:first-of-type': {
      borderRight: '1px solid #ededef',
      justifyContent: 'center',
    },
    '&:nth-of-type(2)': {
      borderRight: props.isfolder == 'true' ? '' : '1px solid #ededef',
      paddingLeft: '1rem',
    },
    '&:nth-of-type(3)': {
      paddingLeft: '1rem',
    },
  },
  '@media(max-width: 1023px)': {
    '& > div': {
      '&:first-of-type': {
        borderColor: '#f5f5f5',
      },
      '&:nth-of-type(2)': {
        borderColor: '#f5f5f5',
      },
    },
  },
}));

const TableCell = styled(Box)({
  height: '3.5rem',
  borderBottom: '1px solid #ededef',
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
});

export interface PocketNoteTableFieldType {
  isNote?: boolean;
  label: string;
}

export interface PocketNoteTableRowProps {
  spell: string;
  mean: string;
  isFolder?: boolean;
  checked?: boolean;
  onRowClicked: (item: any) => void;
}

const WordCell = React.memo(function SpellingCell({ value, sx }: { value: string; sx?: SxProps<Theme> }) {
  return <TableCell sx={sx}>{value}</TableCell>;
});

function PocketNoteTableRow({ isFolder, spell, mean, onRowClicked, checked }: PocketNoteTableRowProps) {
  return (
    <TableRow
      onClick={isFolder ? () => onRowClicked({ isFolder }) : undefined}
      isfolder={`${isFolder}`}
      sx={{ cursor: isFolder ? 'pointer' : 'default' }}
      ischecked={`${checked}`}
    >
      {isFolder ? (
        <>
          <TableCell>
            <FiCornerLeftUp size={'1.5rem'} color='#f44336' />
          </TableCell>
          <WordCell value={spell} />
          <WordCell value={mean} />
        </>
      ) : (
        <>
          <TableCell></TableCell>
          <WordCell sx={{ fontWeight: '600' }} value={spell} />
          <WordCell value={mean} />
        </>
      )}
    </TableRow>
  );
}

export default React.memo(PocketNoteTableRow);
