import { FaThumbsUp } from 'react-icons/fa';
import { HiCheck } from 'react-icons/hi';

// 크기 정할 때 사용하는 함수 -> 0 부터 1까지의 값을 넣어서 % 값 리턴

// 1보다 큰 수일 경우 해당 값 리턴 -> 해당값은 px로 MUI에서 인식
export function transform(value: number) {
  return value <= 1 ? `${value * 100}%` : value;
}

// 수업 날짜 한글로 출력
export function setStudyDayText(nums: number[] | []) {
  const days: string[] = ['일', '월', '화', '수', '목', '금', '토'];
  const res = [];

  const tmp_nums: number[] = nums;
  const zero_idx = tmp_nums.indexOf(0);
  if (zero_idx >= 0) {
    tmp_nums.splice(zero_idx, 1);
    tmp_nums.push(0);
  }

  for (const x of tmp_nums) {
    res.push(days[x]);
  }

  return res.join(', ');
}

export function autoHypenPhone(str: string) {
  str = str.replace(/[^0-9]/g, '');
  let tmp = '';
  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 6);
    tmp += '-';
    tmp += str.substring(6);
    return tmp;
  } else {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 7);
    tmp += '-';
    tmp += str.substring(7);
    return tmp;
  }
}

// 0000년 00월 00일
export function setLocaleDateString(date: Date | null, type: string): string {
  let new_date: Date | null;
  if (date !== null) {
    new_date = date;
  } else {
    new_date = new Date();
  }
  const y = new_date.getFullYear();
  const m = new_date.getMonth() + 1;
  const d = new_date.getDate();

  let res = '';

  if (type == 'kor') {
    res = `${y}년 ${Number(m) < 10 ? '0' + m : m}월 ${Number(d) < 10 ? '0' + d : d}일`;
  } else {
    res = `${y}${type}${Number(m) < 10 ? '0' + m : m}${type}${Number(d) < 10 ? '0' + d : d}`;
  }

  return res;
}

// path validation
// 뒤에 가변적인 params가 들어올 때 2중 검증 필요 -> 추후 필요예상시 도입

interface PathType {
  [key: string]: { [key: string]: { [key: string]: { [key: string]: object } } };
}

const paths: PathType = {
  schedule: {
    learningschedule: {},
    learningbook: {},
  },
  report: {
    lookup: {
      result: {},
    },
    period: {},
    month: {},
  },
  leaderboard: {
    board: {},
    record: {},
  },
  pocketnote: {
    learning: {},
    checkup: {},
  },
  leveltest: {
    leveltest: {},
    report: {},
  },
  manager: {
    notice: {},
    notification: {},
    manual: {},
    qna: {},
    settings: {},
  },
  book: {
    study: {
      learning: {},
      result: {},
    },
    school: {
      learning: {},
      result: {},
    },
  },
  recent: { learning: {}, result: {} },
  quiz: {},
  test: {},
};

export function validationPath(currentUrl: string): boolean {
  try {
    const current_url_array = currentUrl.split('/');
    const current_url_depth = current_url_array.length - 1;
    if (currentUrl === '/') return true;
    let target: { [key: string]: any } = {};
    for (let idx = 1; idx <= current_url_depth; idx++) {
      if (idx == 1) {
        target = paths[current_url_array[idx]];
      } else {
        target = target[current_url_array[idx]];
      }
    }

    if (target) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

//
// 머문시간 구하는 함수 (stay_time)
export function calculateStayTime(start_time: string, end_time: string): string {
  const start_time_array = start_time.split(':');
  const end_time_array = end_time.split(':');
  // 정규식 이용해 먼저 시간인지 확인 아니면 바로 '-'
  const time_format = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
  if (time_format.test(start_time) && time_format.test(end_time)) {
    if (start_time_array.length === 2 && end_time_array.length === 2) {
      if (
        Number(start_time_array[0]) > Number(end_time_array[0]) ||
        (Number(start_time_array[0]) === Number(end_time_array[0]) &&
          Number(start_time_array[1]) > Number(end_time_array[1]))
      ) {
        return '-';
      } else {
        const stay_time_array = end_time_array.map(
          (item, index) => Number(end_time_array[index]) - Number(start_time_array[index]),
        );
        if (stay_time_array[1] < 0) {
          stay_time_array[0] = stay_time_array[0] - 1;
          if (stay_time_array[0] === 0) return `${60 + stay_time_array[1]}분`;
          else return `${stay_time_array[0]}시간 ${60 + stay_time_array[1]}분`;
        } else if (stay_time_array[1] === 0) {
          if (stay_time_array[0] === 0) return `${stay_time_array[1]}분`;
          else return `${stay_time_array[0]}시간`;
        } else {
          if (stay_time_array[0] === 0) return `${stay_time_array[1]}분`;
          else return `${stay_time_array[0]}시간 ${stay_time_array[1]}분`;
        }
      }
    } else {
      return '-';
    }
  } else {
    return '-';
  }
}

export function getIdRCheckday(rcheckday: string) {
  const rcheckday_arr = rcheckday.split(', ').filter(item => item);

  const days: { [key: string]: string } = {
    일: '0',
    월: '1',
    화: '2',
    수: '3',
    목: '4',
    금: '5',
    토: '6',
  };

  return rcheckday_arr.map(item => days[item]).toString();
}

export function makeMinuteContents() {
  const minute_array = [];
  let id = 0;
  for (let i = 0; i <= 59; i++) {
    if (i % 5 != 0) continue;
    const obj = { id: 0, label: '' };
    obj.id = id;
    if (i < 10) obj.label = `0${i}분`;
    else obj.label = `${i}분`;
    minute_array.push(obj);
    id++;
  }
  return minute_array;
}

export function makeHourContents() {
  const hour_array = [];
  for (let i = 0; i < 24; i++) {
    const obj = { id: 0, label: '' };
    obj.id = i;
    if (i < 10) {
      obj.label = `0${i}시`;
    } else {
      obj.label = `${i}시`;
    }
    hour_array.push(obj);
  }
  return hour_array;
}

export function checkTableQueries(table_query: TableQueryType) {
  const { target, sort, page, max } = table_query;

  if (target == '' && sort == '' && page == 1 && max == 10) {
    return false;
  } else {
    return true;
  }
}

export function makeYearMonth(date: string): string {
  const date_array = date.split('-');
  return `${date_array[0]}년 ${date_array[1]}월`;
}

export function getYearArray(date: string): string[] {
  const year_arr = [];
  const start_year = +date.split('-')[0];
  for (let i = new Date().getFullYear(); i >= start_year; i--) {
    year_arr.push(String(i) + '년');
  }
  return year_arr;
}

export function getMonthArray(date: string, year: string | number): string[] {
  const month_arr = [];
  let max_month: number;
  let start_month: number;
  const start_year = +date.split('-')[0];
  const current_year = +year;
  if (current_year == start_year) {
    start_month = +date.split('-')[1];
    if (current_year == new Date().getFullYear()) {
      max_month = new Date().getMonth() + 1;
      for (let i = max_month; i >= start_month; i--) {
        month_arr.push(`${i < 10 ? '0' + i + '월' : i + '월'}`);
      }
    } else {
      for (let i = 12; i >= start_month; i--) {
        month_arr.push(`${i < 10 ? '0' + i + '월' : i + '월'}`);
      }
    }
  } else {
    if (current_year == new Date().getFullYear()) max_month = new Date().getMonth() + 1;
    else max_month = 12;
    for (let i = max_month; i >= 1; i--) {
      month_arr.push(`${i < 10 ? '0' + i + '월' : i + '월'}`);
    }
  }
  return month_arr;
}

export function makeFontSize(
  font_size_obj: { [key: string]: boolean },
  default_font_size: number,
  property: string,
  font?: boolean,
): { [key: string]: string } {
  let new_font_size = '';
  const font_obj: { [key: string]: string } = {};
  for (const font_size in font_size_obj) {
    if (font_size_obj[font_size]) {
      new_font_size = font_size;
      break;
    }
  }
  if (new_font_size == 'default') {
    font_obj[property] = default_font_size + `${font ? 'rem' : '%'}`;
  } else if (new_font_size == 'small') {
    font_obj[property] = default_font_size * 0.8 + `${font ? 'rem' : '%'}`;
  } else {
    font_obj[property] = default_font_size * 1.2 + `${font ? 'rem' : '%'}`;
  }
  return font_obj;
}

export const validatePassword = (value: string): boolean => {
  let status = false;
  status = validateKorean(value);
  if (status) return false;
  else {
    const passwordPattern = /^[a-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/;
    status = passwordPattern.test(value);
    if (status) {
      status = false;
      const p_num = value.search(/[0-9]/g);
      const p_spc = value.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+/g);
      const p_alp = value.search(/[a-z]/g);

      if (p_num > -1 && p_spc > -1) status = true;
      if (p_num > -1 && p_alp > -1) status = true;
      if (p_spc > -1 && p_alp > -1) status = true;
      if (p_num && p_spc > -1 && p_alp > -1) status = true;
      return status;
    } else {
      return status;
    }
  }
};

export const validateKorean = (value: string): boolean => {
  const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  if (koreanPattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const makeCommaAsThousandSeparator = (value: string | number): string => {
  return value.toLocaleString('ko-KR');
};

export function getContentFoldername(spell: string) {
  let foldername;
  foldername = spell.toLowerCase();
  foldername = foldername.replace("'", '');
  foldername = foldername.trim();
  foldername = foldername.replace(/ +/g, ' ');
  foldername = foldername.replace(/\s+/g, '_');
  foldername = foldername.replace(/[^0-9a-z_.]/g, '');
  return foldername;
}

export function getExampleFilename(spell: string) {
  let filename;
  filename = spell.toLowerCase();
  filename = filename.replace("'", '');
  filename = filename.replace(/[^0-9a-z]/gi, ' ');
  filename = filename.replace(/ +|\s+/g, ' ');
  filename = filename.trim();
  filename = filename.replace(/\s+/gi, '_');
  return filename;
}

export function getContentLecturename(spell: string) {
  if (spell == "Let's") {
    return 'lets_0';
  } else if (spell == "Don't") {
    return 'dont_0';
  } else if (spell == 'all day(long)') {
    return 'all_day_long_0';
  } else if (spell == 'be from ~') {
    return 'be_from_0';
  } else if (spell == 'Mr.') {
    return 'mr_0';
  } else if (spell == 'Mrs.') {
    return 'mrs_0';
  } else if (spell == 'Nice to meet you.') {
    return 'nice_to_meet_you_0';
  } else if (spell == 'Of course.') {
    return 'of_course_0';
  } else if (spell == 'really?') {
    return 'really_0';
  } else if (spell == 'Sure.') {
    return 'sure_0';
  } else if (spell == "You're welcome") {
    return 'youre_welcome_0';
  } else if (spell == 'Miss') {
    return 'ms';
  } else if (spell == 'be famous for ~') {
    return 'be_famous_for_';
  } else if (spell == 'have~in common') {
    return 'have__in_common';
  } else if (spell == 'City Hall') {
    return '_city_hall';
  } else if (spell == 'keep ... in mind') {
    return 'keep__in_mind';
  } else if (spell == 'not .... anymore') {
    return 'not__anymore';
  } else if (spell == 'keep -ing') {
    return 'keep__ing';
  } else if (spell == 'by -ing') {
    return 'by__ing';
  } else if (spell == 'would like to ~') {
    return 'would_like_to_';
  } else if (spell == 'go for it!') {
    return 'go_for_it_';
  } else if (spell == 'there is (are)') {
    return 'there_is_are_';
  } else if (spell == 'get + (비교급)') {
    return 'get_';
  } else if (spell == 'have ~ in common') {
    return 'have___in_common';
  } else if (spell == 'not ~ at all') {
    return 'not__at_all';
  } else if (spell == 'turn into') {
    return 'turn_into_';
  } else if (spell == 'try to') {
    return 'try_to_';
  } else if (spell == 'used to') {
    return 'used_to_';
  } else if (spell == 'be used to') {
    return 'be_used_to_';
  } else if (spell == 'so_that') {
    return 'so_that_';
  } else if (spell == 'be able to~') {
    return 'be_able_to_';
  } else if (spell == 'be supposed to ~') {
    return 'be_supposed_to_';
  } else if (spell == 'get to') {
    return 'get_to_';
  } else if (spell == 'make sure ~') {
    return 'make_sure_';
  } else if (spell == 'Not at all') {
    return 'not_at_all_';
  } else if (spell == 'right-hand') {
    return 'right__hand';
  } else if (spell == 'watch out!') {
    return 'watch_out_';
  } else if (spell == 'do ~ a favor') {
    return 'do__a_favor';
  } else if (spell == 'end up -ing') {
    return 'end_up__ing';
  } else if (spell == 'keep ~ in mind') {
    return 'keep___in_mind';
  } else if (spell == 'National Park') {
    return '_national_park';
  } else if (spell == 'Act') {
    return '_act';
  } else if (spell == '명령문 ~, and') {
    return '_and';
  } else if (spell == 'Scene') {
    return '_scene';
  } else if (spell == 'well done') {
    return 'well__done';
  }
  let filename;
  filename = spell.toLowerCase();
  filename = filename.replace("'", '');
  filename = filename.replace(/[^0-9a-z]/gi, ' ');
  filename = filename.replace(/ +|\s+/g, ' ');
  filename = filename.trim();
  filename = filename.replace(/\s+/gi, '_');
  return filename;
}

export function arrShuffle(array: any[]) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export function reverseString(str: string): string {
  return str.split('').reverse().join('');
}

export function shuffleDictation(array: any[], spell: string) {
  const new_array = [...array];
  let currentIndex = new_array.length,
    temporaryValue,
    randomIndex;

  while (0 != currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = new_array[currentIndex];
    new_array[currentIndex] = new_array[randomIndex];
    new_array[randomIndex] = temporaryValue;

    if (currentIndex == 0) {
      const char_set = new Set();
      new_array.forEach(v => {
        char_set.add(v.char);
      });
      const char_set_string = Array.from(char_set).join('');

      let processing_spell = '';
      let previousChar = '';

      for (let i = 0; i < spell.length; i++) {
        const currentChar = spell[i];

        if (currentChar != previousChar) {
          processing_spell += currentChar;
          previousChar = currentChar;
        }
      }

      const reverse_chart_set_string = reverseString(char_set_string);

      if (processing_spell.length == 2 && char_set_string.includes(processing_spell)) {
        // 선택 가능한 철자 버튼이 두개인 경우 역방향만 가능
        currentIndex = new_array.length;
      } else if (
        processing_spell.length >= 3 &&
        (char_set_string.includes(processing_spell) || reverse_chart_set_string.includes(processing_spell))
      ) {
        // 선택 가능한 철자 버튼이 3개 이상인 경우 정방향, 역방향 X
        currentIndex = new_array.length;
      }
    }
  }

  return new_array;
}

function shuffleArray(array: string[]) {
  const newArray = array.slice(); // 원본 배열 복사
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // 요소 교환
  }
  return newArray;
}

export function shuffleChip(array: string[], rightanswer: string) {
  let shuffledSentence = '';
  let shuffledArray;
  do {
    shuffledArray = shuffleArray(array);
    shuffledSentence = shuffledArray.join(' ');
  } while (shuffledSentence === rightanswer);
  return shuffledArray;
}

export function korToEng(char: string) {
  const trans_arr: { [key: string]: string } = {
    ㅂ: 'q',
    ㅃ: 'q',
    ㅈ: 'w',
    ㅉ: 'w',
    ㄷ: 'e',
    ㄸ: 'e',
    ㄱ: 'r',
    ㄲ: 'r',
    ㅅ: 't',
    ㅆ: 't',
    ㅛ: 'y',
    ㅕ: 'u',
    ㅑ: 'i',
    ㅐ: 'o',
    ㅒ: 'o',
    ㅔ: 'p',
    ㅖ: 'p',
    ㅁ: 'a',
    ㄴ: 's',
    ㅇ: 'd',
    ㄹ: 'f',
    ㅎ: 'g',
    ㅗ: 'h',
    ㅓ: 'j',
    ㅏ: 'k',
    ㅣ: 'l',
    ㅋ: 'z',
    ㅌ: 'x',
    ㅊ: 'c',
    ㅍ: 'v',
    ㅠ: 'b',
    ㅜ: 'n',
    ㅡ: 'm',
  };
  const trans_char = trans_arr[char];
  if (trans_char) {
    return trans_char;
  } else {
    return char;
  }
}

export const setPartofSpeech = (ps: string) => {
  switch (ps) {
    case 'nou':
      return 'n.';
    case 'pro':
      return 'pron.';
    case 'ver':
      return 'v.';
    case 'adj':
      return 'adj.';
    case 'adv':
      return 'adv.';
    case 'pre':
      return 'prep.';
    case 'con':
      return 'conj.';
    case 'int':
      return 'interj.';
    case 'art':
      return 'arti.';
    case 'det':
      return 'dete.';
  }
};

export const setKorofSpeech = (ps: string) => {
  switch (ps) {
    case 'nou':
      return '명사';
    case 'pro':
      return '대명사';
    case 'ver':
      return '동사';
    case 'adj':
      return '형용사';
    case 'adv':
      return '부사';
    case 'pre':
      return '전치사';
    case 'con':
      return '접속사';
    case 'int':
      return '감탄사';
    case 'art':
      return '관사';
    case 'det':
      return '한정사';
  }
};

export const setDayToNumber = (day: string): number => {
  switch (day) {
    case '일':
      return 0;
    case '월':
      return 1;
    case '화':
      return 2;
    case '수':
      return 3;
    case '목':
      return 4;
    case '금':
      return 5;
    default:
      return 6;
  }
};

export const setModeToKor = (mode: string): string => {
  switch (mode) {
    case 'basic':
    case 'grammar_b':
      return '기본학습';
    case 'advanced':
    case 'grammar_d':
      return '심화학습';
    case 'speak':
      return '회화학습';
    case 'grammar_t':
      return '이론학습';
    default:
      return '';
  }
};

export const setTextShadow = ({
  color,
  sizeShadow,
  directionShadow,
}: {
  color: string;
  directionShadow: string;
  sizeShadow: number;
}): string => {
  let textShadow = '';

  for (let i = 0, len = sizeShadow; i < len; i++) {
    switch (directionShadow) {
      case 'top':
        textShadow += `0 -${i}px 0 ${color},`;
        break;
      case 'right':
        textShadow += `${i}px 0 0 ${color},`;
        break;
      case 'bottom':
        textShadow += `0 ${i}px 0 ${color},`;
        break;
      case 'left':
        textShadow += `-${i}px 0 0 ${color},`;
        break;
      case 'top-left':
        textShadow += `-${i}px -${i}px 0 ${color},`;
        break;
      case 'top-right':
        textShadow += `${i}px -${i}px 0 ${color},`;
        break;
      case 'bottom-left':
        textShadow += `-${i}px ${i}px 0 ${color},`;
        break;
      case 'bottom-right':
        textShadow += `${i}px ${i}px 0 ${color},`;
        break;
      default:
        textShadow += `${i}px ${i}px 0 ${color},`;
        break;
    }
  }

  textShadow = textShadow.slice(0, -1);
  return textShadow;
};

export const setTabNameToKor = (tab_name: string) => {
  const tab_name_list: { [key: string]: string } = {
    longvoca: '오래보카',
    textbook: '영어 교과서',
    customer: '만든 교재',
    exam: '수능/모의고사',
  };

  return tab_name_list[tab_name];
};

export const makeGradeLabel = (score: number) => {
  if (score == 100) {
    return 'PERFECT';
  } else if (score >= 91) {
    return 'EXCELLENT';
  } else if (score >= 81) {
    return 'BRAVO';
  } else if (score >= 71) {
    return 'GOOD JOB';
  } else if (score >= 61) {
    return 'GO FOR IT';
  } else {
    return 'TRY HARDER';
  }
};

export const makeGradeKorLabel = (score: number) => {
  if (score == 100) {
    return '완벽합니다!';
  } else if (score >= 91) {
    return '아주 잘했어요!';
  } else if (score >= 81) {
    return '잘 하고 있어요!';
  } else if (score >= 71) {
    return '잘 하고 있어요!';
  } else if (score >= 61) {
    return '좀 더 노력해보세요!';
  } else {
    return '좀 더 분발하세요!';
  }
};

export const makeGradeIcon = (score: number) => {
  if (score > 70) {
    return <FaThumbsUp style={{ width: '3.25rem', height: '3.25rem' }} strokeWidth={'0'} />;
  } else {
    return <HiCheck strokeWidth={'2'} />;
  }
};

export const makeEndModuleModalProps = (resultsheet: ResultsheetType[]) => {
  const total_question_count = resultsheet.length;
  let right_count = 0;
  resultsheet.forEach(result => {
    if (result.answer == result.rightanswer) {
      right_count += 1;
    } else if (result.answer == '333') {
      right_count += 0.5;
    }
  });

  const score = (right_count / total_question_count) * 100;

  const label = makeGradeLabel(score);
  const icon = makeGradeIcon(score);
  const kor_label = makeGradeKorLabel(score);

  return { label, icon, score, kor_label };
};

export const shuffleWordBingo = (array: WordbingoCardType[]): WordbingoCardType[] => {
  if (array.length == 1) return array;
  const new_array = [...array];
  const strikeOut: WordbingoCardType[] = [];
  let not_reverse = false;

  while (new_array.length) {
    const lastIdx = new_array.length - 1;
    const roll = Math.floor(Math.random() * lastIdx);

    if (roll >= 0) {
      const temp = new_array[lastIdx];
      new_array[lastIdx] = new_array[roll];
      new_array[roll] = temp;
    }

    const card = new_array.pop();
    if (card) {
      strikeOut.unshift(card);
    }

    // 안에 요소가 3개 이상인 경우, 역방향으로 안되게 추가 검증
    if (array.length >= 3 && strikeOut.length == array.length) {
      const reverse_array = [...array].reverse();
      for (let i = 0; i < reverse_array.length; i++) {
        if (reverse_array[i].id !== strikeOut[i].id) {
          not_reverse = true;
          break;
        }
      }
    } else if (array.length == 2 && strikeOut.length == array.length) {
      not_reverse = true;
    }
  }

  if (not_reverse) {
    return strikeOut;
  } else {
    return shuffleWordBingo(array);
  }
};

export const setModalTitle = ({ title, subTitle, target }: { title: string; subTitle?: string; target?: string }) => {
  let tmp_title = title;
  let tmp_sub_title = subTitle;
  if (target) {
    tmp_title = title.replace('target', target);
    if (tmp_sub_title) {
      tmp_sub_title = tmp_sub_title.replace('target', target);
      return `<span>${tmp_title}</span>${tmp_sub_title ? `<span class="sub-title">${tmp_sub_title}</span>` : ''}`;
    }
  } else if (tmp_sub_title) {
    return `<span>${tmp_title}</span>${tmp_sub_title ? `<span class="sub-title">${tmp_sub_title}</span>` : ''}`;
  }
  return tmp_title;
};

export const imgPreload = (images: string[]) => {
  return images.map(src => {
    const img = new Image();
    img.src = src;
    return img;
  });
};

export const koreanReplace = (str: string) => {
  const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi;
  let res = str;
  if (korean.test(res)) {
    res = res.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi, (v, i) => {
      return '';
    });
  }
  return res;
};
