import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { learningState } from 'recoil/model/learning';
import { deviceState } from 'recoil/common/device';
import { loadingState, setPercent } from 'recoil/common/loading';
import { useVoice, voiceState } from 'recoil/common/voice';
import { settingState } from 'recoil/model/settings';
import { userState } from 'recoil/model/user';
// import { tutorialStateData, openTutorial } from 'recoil/common/tutorial';

import { ModalContext } from 'provider/ModalProvider';
import { EffectSoundContext } from 'provider/EffectSoundProvider';

import { styled, Box, IconButton, keyframes, tooltipClasses, Tooltip, TooltipProps } from '@mui/material';

import ModalGrading from 'components/modal/ModalGrading';
import Button from 'components/button/Button';

import { fetchGetApi, fetchGetAudioApi, fetchPostApi } from 'utils/api';

import { dir_column, d_flex_center } from 'styles/common';
import { getExampleFilename, korToEng } from 'utils/tools';
import getSentenceScore from 'utils/get_score';

import canvas_bg from 'assets/images/learning/wave-bg.png';
import ModalManual from 'components/modal/ModalManual';

declare let window: any;

interface SpeechRecognitionProps {
  setHtml: React.Dispatch<React.SetStateAction<string | null>>;
  finishCallback: (resultsheet: ResultsheetType[]) => void;
  progress?: number;
  onClickReplayButton?: (isForce?: boolean) => void;
  // recognition_available: React.MutableRefObject<boolean>;
  // permission: React.MutableRefObject<boolean>;
}

const StyledSpeechWrap = styled(Box)(props => ({
  width: '96%',
  height: '100%',
  position: 'relative',
  ...d_flex_center,
  ...dir_column,
}));

const StyledMicButton = styled(IconButton)<{ is_mobile: boolean }>(({ is_mobile }) => ({
  position: 'absolute',
  width: is_mobile ? '14.8vh' : '18.5vh',
  height: is_mobile ? '14.8vh' : '18.5vh',
  padding: '2.5vh',
  backgroundColor: '#ff3722',
  borderColor: '#ff3722',
  zIndex: '30',
}));

const StyledCountButton = styled(IconButton)<{ is_mobile: boolean }>(({ is_mobile }) => ({
  position: 'absolute',
  width: is_mobile ? '14.8vh' : '18.5vh',
  height: is_mobile ? '14.8vh' : '18.5vh',
  padding: '2.5vh',
  backgroundColor: '#ff3722',
  borderColor: '#ff3722',
  zIndex: '30',
}));

const StyledCount = styled('span')({
  positon: 'absolute',
  fontSize: '5rem',
  fontWeight: '600',
  color: '#fff',
  zIndex: '32',
  '@media (max-width: 1903px)': {
    fontSize: '5rem',
  },

  '@media (max-width: 1263px)': {
    fontSize: '5rem',
  },
  '@media (max-width: 1024px)': {
    fontSize: '4rem',
  },
  '@media (max-width: 767px)': {
    fontSize: '3rem',
  },
});

const PulseBox = styled('span')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '7vh',
  height: '7vh',
  backgroundColor: '#ff4c41',
  borderRadius: '50%',

  '@keyframes pulse': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(1.2)',
    },
    '50%': {
      transform: 'translate(-50%, -50%) scale(1.7)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1.2)',
    },
  },

  animation: 'pulse 1s cubic-bezier(0.4, 0, 0.2, 1) 2.5 normal forwards',
});

const bgcLoop = keyframes`
  0% { background: #ff0000; }
  50% {
    background: #ff5500;
  }
  100% {
    background: #ff0000;
  }`;

const wave1 = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const wave2 = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const wave3 = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const micbounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledIosMicButton = styled(IconButton)<{ is_mobile: boolean }>(({ is_mobile }) => ({
  animation: `${bgcLoop} 1.5s infinite`,
  position: 'absolute',
  width: is_mobile ? '14.8vh' : '18.5vh',
  height: is_mobile ? '14.8vh' : '18.5vh',
  padding: '2.5vh',
  backgroundColor: '#ff3722',
  borderColor: '#ff3722',
  zIndex: '30',
  '& .mic-bounce': {
    animation: `${micbounce}`,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    display: 'inline-flex',
    position: 'relative',
  },
  '& .wave': {
    top: '50%',
    transform: 'translateY(-54%)',
    fontSize: '3vh',
    fontWeight: '800',
    display: 'inline-block',
    position: 'absolute',
    '&.left': { color: 'white', left: '-9%' },
    '&.right': { color: 'white', right: '-6%' },

    '& .wave-1': {
      letterSpacing: '-2px !important',
      animation: `${wave1} 1.5s infinite`,
    },
    '& .wave-2': {
      letterSpacing: '-2px !important',
      animation: `${wave2} 1.5s infinite`,
    },
    '& .wave-3': {
      letterSpacing: '-2px !important',
      animation: `${wave3} 1.5s infinite`,
    },
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ theme, placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3f3f3f',
    transition: 'none !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    transition: '0.05s !important',
    marginTop: '1rem !important',
    backgroundColor: '#3f3f3f',
    fontSize: '1rem',
    textAlign: 'center',
    maxWidth: 'unset',
    lineHeight: '1',
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    top: '0.5vh',
    left: '0.125rem',
    '& > div': {
      fontSize: '1.1rem',
    },
  },
}));

function SpeechRecognition(props: SpeechRecognitionProps) {
  const finishCallback = props.finishCallback;
  const setHtml = props.setHtml;

  const { modal_alert } = useContext(ModalContext);
  // const setTutorialState = useSetRecoilState(tutorialStateData);
  const userStateData = useRecoilValue<UserType>(userState);
  const { customer_id, id } = userStateData;

  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { mod, current_step, contents, current_page, show_modal, resultsheet } = learningStateData;

  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { platform, screen_width, screen_height, is_mobile, current_version } = deviceStateData;

  const [retryCnt, setRetryCnt] = useState<number>(1);
  const [voiceStateData, setVoiceStateData] = useRecoilState<VoiceType>(voiceState);
  const { ready, element } = voiceStateData;
  const { setVoice, voicePlay, voiceStop } = useVoice();

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const settingStateData = useRecoilValue(settingState);
  const { check_type, enable_keyboard } = settingStateData;

  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [answer, setAnswer] = useState<string>('');
  const [rightanswer, setRightanswer] = useState<string>('');
  const rightanswerRef = useRef<string>('');
  const [question, setQuestion] = useState<string>('');

  const [canvWidth, setCanvWidth] = useState<number>(screen_width * 0.45);
  const [canvHeight, setCanvHeight] = useState<number>(screen_height * 0.28);

  const [nextDisable, setNextDisable] = useState<boolean>(false);
  const [visibleGrading, setVisibleGrading] = useState<boolean>(false);
  const [hideMic, setHideMic] = useState<boolean>(false);

  const [modalType, setModalType] = useState<
    'correct_recording' | 'good_recording' | 'wrong_recording' | 'fail_recording'
  >('fail_recording');

  const [readyNext, setReadyNext] = useState<boolean>(false);
  // const [goResult, setGoResult] = useState<boolean>(false);

  const ctx = useRef<CanvasRenderingContext2D | null>(null);

  const [keyboardStop, setKeyboardStop] = useState(false);

  const onSoundPlayRef = useRef<() => Promise<void>>();
  const onPlayEffectSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundStopRef = useRef<() => Promise<void>>();

  const [showReady, setShowReady] = useState(false);

  const [status, setStatus] = useState('prompt');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [modalManualVisible, setModalManualVisible] = useState(false);

  const audio_context = useRef<AudioContext | undefined>(undefined);

  const user_duration = useRef<number>(0);

  const record_cnt = useRef<number>(0);

  const settimeout1 = useRef<any>(null);
  const settimeout2 = useRef<any>(null);
  const settimeout3 = useRef<any>(null);
  const countDownInterval = useRef<any>(null);

  const is_ios = useRef<boolean>(false);
  const recognition_available = useRef<boolean>(false);
  const permission = useRef<boolean>(false);
  // const recognition_available = props.recognition_available;
  // const permission = props.permission;

  const record_audio_file = useRef<string>('');

  const [iosRecording, setIosRecording] = useState<boolean>(false);

  const is_ios_init = useRef<boolean>(false);

  const peaks = useRef<number[]>([]);

  const passed = useRef<boolean>(true);
  const prev_x = useRef<number>(0);
  const prev_val = useRef<number>(0);

  const req_frm = useRef<any[]>([]);
  const audio = useRef<HTMLAudioElement>(new Audio());

  const chk_played = useRef<boolean>(false);

  const { playEffectSound } = useContext(EffectSoundContext);

  const [waitingCount, setWaitingCount] = useState(0);
  const waitingCount_ref = useRef(0);
  waitingCount_ref.current = waitingCount;

  const useTimer = useMemo(() => {
    if (platform == 'ios') {
      return true;
    } else {
      return false;
    }
  }, []);

  const checkExampleTTS = async () => {
    for (let i = 0; i < contents.length; i++) {
      const sentence = contents[i].sentence;
      if (!sentence) continue;
      const gender = 0; // 남자 임시 고정
      const filename = getExampleFilename(sentence);
      const res = await fetchGetApi(`/etc/tts/example?filename=${filename}&gender=${gender}&ver=2`);
      if (!res.result) {
        const postdata = {
          example: sentence,
          filename: filename,
          gender: gender,
        };
        await fetchPostApi(`/etc/tts/example`, postdata);
      }
    }
  };

  useEffect(() => {
    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      const resultsheet_tmp = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
      }));
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: [],
      }));
    }

    if (platform == 'ios') {
      is_ios.current = true;
    } else {
      // 안드로이드 13 이상 알림창 권한 확인
      window.Permissions.notificationPermission();
    }

    if (!recognition_available.current) {
      window.plugins.speechRecognition.isRecognitionAvailable(
        function (res: boolean) {
          // success callback
          console.log('isRecognitionAvailable', res);
          recognition_available.current = res;
          // window.console.log('setRecognitionAvailable:'+res);
        },
        function (e: any) {
          console.error('isRecognitionAvailable err:' + e);
        },
      );
    }

    window.plugins.speechRecognition.hasPermission(
      (res: boolean) => {
        permission.current = res;
        if (!permission.current) {
          window.plugins.speechRecognition.requestPermission(
            () => {
              permission.current = true;
              console.log('granted');
              setStatus('granted');
            },
            () => {
              console.log('denied');
              setStatus('denied');
            },
          );
        } else {
          setStatus('granted');
        }
      },
      function (e: any) {
        console.error('permission err:' + e);
      },
    );

    window.setRecordFilename = function (path: string) {
      record_audio_file.current = path;
      setReadyNext(true);
      setNextDisable(false);
    };

    // 예문으로 스피킹 하는게 확실할 때, TTS 확인 필요
    checkExampleTTS();

    return () => {
      voiceStop();
      audio.current.onended = null;
      audio.current.oncanplay = null;
      if (audio.current.played) audio.current.pause();
      if (settimeout1.current) {
        window.clearTimeout(settimeout1.current);
        settimeout1.current = null;
      }

      if (settimeout2.current) {
        window.clearTimeout(settimeout2.current);
        settimeout2.current = null;
      }

      if (countDownInterval.current) {
        window.clearInterval(countDownInterval.current);
        countDownInterval.current = null;
      }
    };
  }, []);

  // useEffect(() => {
  //   if (percent == 100) {
  //     if (userStateData.show_tutorial && userStateData.show_tutorial.speak) {
  //       // 튜토리얼을 진행해야 한다면
  //       setTimeout(() => {
  //         setLearningStateData(prevState => ({
  //           ...prevState,
  //           show_modal: true,
  //         }));
  //         openTutorial({ setTutorialState }, { reopen: false });
  //       }, 275);
  //     } else {
  //       setTimeout(() => {
  //         setLearningStateData(prevState => ({
  //           ...prevState,
  //           show_modal: true,
  //         }));
  //         modal_alert.openModalAlert('speaking_start', undefined, undefined, () => {
  //           setLearningStateData(prevState => ({
  //             ...prevState,
  //             show_modal: false,
  //           }));
  //         });
  //       }, 400);
  //     }
  //   }
  // }, [percent]);

  useEffect(() => {
    setContent(contents[current_step]);
  }, [current_step]);

  useEffect(() => {
    if (readyNext) {
      setReadyNext(false);
      if (settimeout1.current) {
        window.clearTimeout(settimeout1.current);
        settimeout1.current = null;
      }

      if (settimeout2.current) {
        window.clearTimeout(settimeout2.current);
        settimeout2.current = null;
      }

      if (settimeout3.current) {
        window.clearTimeout(settimeout3.current);
        settimeout3.current = null;
      }

      goNext();
    }
  }, [readyNext]);

  useEffect(() => {
    const sentence = content['sentence'] ? content['sentence'] : '';
    const translate = content.translate ? content.translate : '';
    setRightanswer(sentence);
    rightanswerRef.current = sentence;
    setModalType('fail_recording');
    setQuestion(sentence);
    setInit();
    setRetryCnt(1);
    const gender = 0;
    const filename = getExampleFilename(sentence);
    const path = filename.charAt(0).toLocaleLowerCase();
    const full_path = `examples/${gender}/${path}/${filename}.mp3`;
    setVoice(sentence, full_path, false);
    if (!is_ios.current) {
      const filename = `${learningStateData.book_type}-${userStateData.id}-${learningStateData.schedule_id}-${learningStateData.record_id}-${current_step}`;
      window.Study.inputSentence(sentence);
      window.Study.inputSpeakingFileName(filename);
    }
  }, [content]);

  useEffect(() => {
    if (props.progress && props.progress === 100) {
      if (permission.current) {
        startRecord(true);
      } else {
        modal_alert.openModalAlert('check_mic');
      }
    }
  }, [props.progress]);

  useEffect(() => {
    if (enable_keyboard) bindKeyboard();
    return () => {
      if (enable_keyboard) unbindKeyboard();
    };
  }, [answer, ready, show_modal, visibleGrading, hideMic, keyboardStop]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const setInit = () => {
    try {
      if (settimeout2.current) {
        window.clearTimeout(settimeout2.current);
        settimeout2.current = null;
      }

      if (countDownInterval.current) {
        window.clearInterval(countDownInterval.current);
        countDownInterval.current = null;
      }

      audio.current = new Audio();
      record_audio_file.current = '';

      window.setTimeout(() => {
        setCanvWidth(screen_width * 0.45);
        setCanvHeight(screen_height * 0.28);
      }, 0);

      const canvas = document.getElementById('recode-canvas') as HTMLCanvasElement;
      ctx.current = canvas.getContext('2d');
      ctx.current?.clearRect(0, 0, canvWidth, canvHeight);
      prev_x.current = 0;
      prev_val.current = 300;
      passed.current = true;
    } catch (error) {
      //! 메일 발송 처리
      sendErrorMail(error, 'setInit');
    }
  };

  // 녹음 준비 및 실행
  const setStudy = () => {
    try {
      const options = {
        language: is_ios.current ? 'en-GB' : 'en-US',
        prompt: question,
        matches: 12,
        showPopup: true,
        showPartial: false,
      };

      if (!is_ios.current) {
        setLearningStateData(prevState => ({
          ...prevState,
          modal_disable: true,
        }));
      }

      record_cnt.current += 1;

      window.plugins.speechRecognition.startListening(
        (res: string[]) => {
          setHideMic(true);
          getScore(res);
        },
        (e: any) => {
          setNextDisable(false);
          setLearningStateData(prevState => ({
            ...prevState,
            modal_disable: false,
          }));
          if (is_ios.current) {
            setIosRecording(false);
            stopRecordIos();
            setWaitingCount(3);
          }
          window.console.error(e);
          if (e == 'Could not start Speech Recognition. Microphone may be busy.') {
            modal_alert.openModalAlert('mic_ios_busy', undefined, undefined, () => {
              setLearningStateData(prevState => ({
                ...prevState,
                show_modal: false,
              }));
            });
          }
          //! 메일 발송 처리
          sendErrorMail(e, 'window.plugins.speechRecognition.startListening');
        },
        options,
      );
    } catch (error) {
      //! 메일 발송 처리
      sendErrorMail(error, '');
    }
  };

  const sendErrorMail = async (message: any, fnName: string) => {
    await fetchPostApi(`/etc/error/mail`, {
      type: is_mobile ? 'Mobile' : 'PC',
      account_id: id,
      customer_id: customer_id,
      module: 'SpeechRecognition_Speak',
      version: current_version,
      agent: navigator.userAgent,
      message: JSON.stringify(message),
      fnName,
    });
  };

  const waveform = () => {
    if (ctx.current) {
      // ctx.current.lineWidth = 2;
      ctx.current.strokeStyle = '#69e5ff';
      ctx.current.lineCap = 'round';
      ctx.current.shadowBlur = 20;
      ctx.current.shadowColor = 'rgba(105, 229, 255, 1.0)';
      ctx.current.beginPath();

      const tmp_peaks = peaks.current;
      const time = audio.current.currentTime;
      const playX = getPlayX(time);
      let x = 0;

      if (tmp_peaks.length <= playX) {
        cancelAllFrame();
        return false;
      }
      ctx.current.clearRect(0, 0, canvWidth, canvHeight);
      x = draw(tmp_peaks.slice(0, playX), 2, '#69e5ff', x);
      if (x == 0) {
        draw(tmp_peaks.slice(playX), 2, '#69e5ff', x);
      }
      drawSlider(time);

      req_frm.current.push(requestAnimationFrame(waveform));

      if (!chk_played.current && playX > 0 && !audio.current.paused) {
        chk_played.current = true;
      } else {
        if (chk_played.current && playX == 0 && audio.current.paused) {
          cancelAllFrame();
        }
      }
    }
  };

  const draw = (data: number[], lineWidth: number, color: string, x: number) => {
    if (ctx.current) {
      ctx.current.lineWidth = lineWidth;
      ctx.current.strokeStyle = color;
      ctx.current.beginPath();
      data.forEach(v => {
        if (ctx.current) ctx.current.lineTo(x, v);
        x++;
      });
      ctx.current.stroke();
    }
    return x;
  };

  const drawSlider = (time: number) => {
    if (ctx.current) {
      const playX = getPlayX(time);
      ctx.current.lineWidth = 1;
      ctx.current.strokeStyle = '#69e5ff';
      ctx.current.beginPath();
      ctx.current.moveTo(playX, 0);
      ctx.current.lineTo(playX, canvHeight);
      ctx.current.stroke();
    }
  };

  const getPlayX = (time: number) => {
    return ~~((time / user_duration.current) * canvWidth);
  };

  const cancelAllFrame = () => {
    for (let i = 0; i < req_frm.current.length; i++) {
      window.cancelAnimationFrame(req_frm.current[i]);
    }
    req_frm.current = [];
  };

  const onClickMicButton = () => {
    const videoElement: HTMLVideoElement | null = document.querySelector('#speak-video-element');
    if (videoElement && !videoElement.paused) {
      videoElement.pause();
    }

    // timer 옵션에 따라 다르게 처리
    if (permission.current) {
      startRecord(useTimer ? true : false);
    } else {
      modal_alert.openModalAlert('check_mic');
    }
  };

  // 녹음 시작
  const startRecord = async (isCount?: boolean) => {
    try {
      if (nextDisable) return false;
      if (!permission.current) {
        window.plugins.speechRecognition.requestPermission(
          () => {
            permission.current = true;
            setStatus('granted');
          },
          () => {
            setStatus('denied');
          },
        );
        return false;
      }
      setNextDisable(true);
      // iOS
      if (is_ios.current) {
        if (iosRecording) return false;
        setLearningStateData(prevState => ({
          ...prevState,
          modal_disable: true,
        }));

        const iosStartRecording = async () => {
          if (isCount) {
            if (waitingCount_ref.current === 0) {
              setWaitingCount(3);
            }
            // 3초 카운트 다운 이후에 start 예정

            playEffectSound('speaking_count_1');
            countDownInterval.current = window.setInterval(async () => {
              if (waitingCount_ref.current == 3) {
                playEffectSound('speaking_count_1');
              }

              if (waitingCount_ref.current == 1) {
                if (countDownInterval.current) {
                  window.clearInterval(countDownInterval.current);
                  countDownInterval.current = null;
                }

                settimeout2.current = window.setTimeout(() => {
                  stopRecordIos();
                  if (!visibleGrading) {
                    console.log('ios_record_fail_dialog', 2000);
                  }
                }, 10000);
              }

              setWaitingCount(waitingCount_ref.current - 1);

              if (waitingCount_ref.current == 2) {
                await playEffectSound('speaking_count_2');
                setIosRecording(true);
                setStudy();
              }
            }, 1000);
          } else {
            await playEffectSound('speaking_rec');
            console.log('ios record');
            setIosRecording(true);
            setStudy();

            settimeout2.current = window.setTimeout(() => {
              stopRecordIos();
              if (!visibleGrading) {
                console.log('ios_record_fail_dialog', 2000);
              }
            }, 10000);
            console.log('settimeout2 : ', 10000);
          }
        };

        if (!is_ios_init.current) {
          // 이번 학습의 첫 녹음 시
          is_ios_init.current = true;
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('ios_speaking_init', 'white', undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
            iosStartRecording();
          });
        } else {
          iosStartRecording();
        }
      }
      // android
      else {
        setStudy();
      }
    } catch (error) {
      //! 메일 발송 처리
      sendErrorMail(error, 'startRecord');
    }
  };

  // ios 녹음 중지
  const stopRecordIos = () => {
    try {
      if (settimeout2.current) {
        window.clearTimeout(settimeout2.current);
        settimeout2.current = null;
      }

      if (countDownInterval.current) {
        window.clearInterval(countDownInterval.current);
        countDownInterval.current = null;
      }

      setNextDisable(false);
      setLearningStateData(prevState => ({
        ...prevState,
        modal_disable: false,
      }));

      window.plugins.speechRecognition.stopListening(
        (res: any) => {
          window.console.log('startListening success: ' + res);
          setIosRecording(false);
        },
        (e: any) => {
          window.console.error(e);
          setIosRecording(false);
          //! 메일 발송 처리
          sendErrorMail(e, 'window.plugins.speechRecognition.stopListening');
        },
      );
    } catch (error) {
      //! 메일 발송 처리
      sendErrorMail(error, 'stopRecordIos');
    }
  };

  const keyboardDownEvent = (evt: KeyboardEvent) => {
    if (show_modal) {
      evt.preventDefault();
      return false;
    }

    const key = korToEng(evt.key);

    if (key == 'Enter' && !keyboardStop) {
      evt.preventDefault();
      if (!nextDisable || !hideMic) onClickMicButton();
      return false;
    }
  };

  const goNext = async () => {
    if (is_ios.current || record_audio_file.current == '') {
      if (check_type) {
        onSoundPlayRef.current = async () => {
          playEffectSound(modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong');
          await new Promise(resolve => setTimeout(resolve, 1200));
          await voicePlay();
        };
        onPlayEffectSoundPlayRef.current = async () => {
          await playEffectSound(
            modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong',
          );
        };
        onAnswerSoundPlayRef.current = async () => {
          await voicePlay();
        };
        onAnswerSoundStopRef.current = async () => {
          voiceStop();
        };
        if (show_modal) {
          setShowReady(true);
        } else {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));

          setVisibleGrading(true);
        }
      } else {
        setTimeout(() => {
          goNextStep();
        }, 2000);
      }
    } else {
      console.log('goNext : ' + record_audio_file.current);
      const audioBlob = await fetchGetAudioApi(record_audio_file.current);
      if (audioBlob) {
        audio.current.src = URL.createObjectURL(audioBlob);
        audio.current.onended = () => {
          audio.current.oncanplay = null;
          if (check_type) {
            onSoundPlayRef.current = async () => {
              playEffectSound(modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong');
              await new Promise(resolve => setTimeout(resolve, 1200));
              await voicePlay();
            };
            onPlayEffectSoundPlayRef.current = async () => {
              await playEffectSound(
                modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong',
              );
            };
            onAnswerSoundPlayRef.current = async () => {
              await voicePlay();
            };
            onAnswerSoundStopRef.current = async () => {
              voiceStop();
            };

            if (show_modal) {
              setShowReady(true);
            } else {
              setLearningStateData(prevState => ({
                ...prevState,
                show_modal: true,
              }));

              setVisibleGrading(true);
            }
          } else {
            setTimeout(() => {
              goNextStep();
            }, 2000);
          }
        };
        audio.current.oncanplay = () => {
          audio.current.play();
          decode(audioBlob);
        };
        audio.current.load();
      } else {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: true,
        }));
        if (check_type) {
          onSoundPlayRef.current = async () => {
            await playEffectSound(
              modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong',
            );
            await voicePlay();
          };
          onPlayEffectSoundPlayRef.current = async () => {
            await playEffectSound(
              modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong',
            );
          };
          onAnswerSoundPlayRef.current = async () => {
            await voicePlay();
          };
          onAnswerSoundStopRef.current = async () => {
            voiceStop();
          };
          if (show_modal) {
            setShowReady(true);
          } else {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: true,
            }));

            setVisibleGrading(true);
          }
        } else {
          setTimeout(() => {
            goNextStep();
          }, 2000);
        }
      }
    }
  };

  const decode = async (blob: Blob) => {
    const file_reader = new FileReader();
    let arraybuffer;
    file_reader.onloadend = () => {
      arraybuffer = file_reader.result;
      if (arraybuffer && typeof arraybuffer != 'string') {
        audio_context.current = new AudioContext();
        audio_context.current
          .decodeAudioData(arraybuffer)
          .then(audioBuffer2 => {
            setPeaks(audioBuffer2);
          })
          .catch((e: any) => {
            console.error(e);
          });
      }
    };

    file_reader.readAsArrayBuffer(blob);
  };

  const setPeaks = (buffer: AudioBuffer) => {
    const tmp_peaks: number[] = [];
    let min = 0;
    let max = 0;
    let sum = 0;
    let top = 0;
    const segSize = Math.ceil(buffer.length / canvWidth);
    const width = canvWidth;
    const height = canvHeight;
    user_duration.current = buffer.duration;

    for (let c = 0; c < buffer.numberOfChannels; c++) {
      const data = buffer.getChannelData(c);

      for (let s = 0; s < width; s++) {
        const start = ~~(s * segSize);
        const end = ~~(start + segSize);
        min = 0;
        max = 0;
        for (let i = start; i < end; i++) {
          min = data[i] < min ? data[i] : min;
          max = data[i] > max ? data[i] : max;
          sum = ((max - min) / 3) * 2;
        }

        if (tmp_peaks[s]) {
          tmp_peaks[s] = tmp_peaks[s] < sum ? sum : tmp_peaks[s];
        }
        tmp_peaks[s] = sum;
      }
    }

    for (let i = 0; i < tmp_peaks.length; i++) {
      sum = tmp_peaks[i];
      top = Math.round(height - sum * height) - 20;
      tmp_peaks[i] = top;
    }

    peaks.current = tmp_peaks;
    waveform();
  };

  const getScore = (res: string[]) => {
    console.log('getScore', res, rightanswerRef.current);
    let idx = 0;
    let result = getSentenceScore(res[idx], rightanswerRef.current);
    for (let i = 0; i < res.length; i++) {
      const tmp = getSentenceScore(res[i], rightanswerRef.current);
      if (tmp.score > result.score) {
        result = tmp;
        idx = i;
      }
    }

    let tmp_answer = res[idx];
    const score = result.score;
    const is_included_red = result.html.includes(`<span class='c_red'>`);
    if (score >= 80) {
      if (is_included_red) {
        setModalType('good_recording');
      } else {
        setModalType('correct_recording');
      }
    } else if (score >= 40) {
      setModalType('good_recording');
    } else if (retryCnt > 0) {
      setModalType('fail_recording');
    } else {
      setModalType('wrong_recording');
    }

    if (res[idx].length > 0) {
      tmp_answer = tmp_answer.charAt(0).toUpperCase() + tmp_answer.slice(1);
    }

    setAnswer(tmp_answer);
    setHtml(result.html);

    if (is_ios.current) {
      setReadyNext(true);
      setNextDisable(false);
    }
  };

  const goNextStep = async (modal_type?: string) => {
    try {
      setNextDisable(false);
      setHideMic(false);
      setLearningStateData(prevState => ({
        ...prevState,
        modal_disable: false,
      }));
      if (modalType == 'fail_recording' && retryCnt > 0) {
        setVisibleGrading(false);
        setRetryCnt(retryCnt - 1);
        setHtml(null);
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
        setInit();
        if (props.onClickReplayButton) {
          props.onClickReplayButton(true);
        }
      } else if (modal_type == 'good_recording') {
        setVisibleGrading(false);
        setHtml(null);
        setInit();
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));

        if (props.onClickReplayButton) {
          props.onClickReplayButton(true);
        }
      } else {
        goNextProcess();
      }
    } catch (error) {
      //! 메일 발송 처리
      sendErrorMail(error, 'goNextStep');
    }
  };

  const goNextProcess = () => {
    try {
      onSoundPlayRef.current = undefined;
      onPlayEffectSoundPlayRef.current = undefined;
      onAnswerSoundPlayRef.current = undefined;
      onAnswerSoundStopRef.current = undefined;
      setVisibleGrading(false);
      setKeyboardStop(true);
      const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
      resultsheet_tmp[current_step] = {
        no: current_step + 1,
        sentence_id: content['sentence_id'],
        answer: answer,
        rightanswer: content['sentence'],
        question: content['translate'],
        type: 'sentence',
      };
      if (current_step == contents.length - 1) {
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            resultsheet: resultsheet_tmp,
          }));
          finishCallback(resultsheet_tmp);
        }, 0);
      } else {
        setTimeout(() => {
          setKeyboardStop(false);
        }, 1000);
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: false,
            current_step: current_step + 1,
            resultsheet: resultsheet_tmp,
          }));
        }, 0);
      }
    } catch (error) {
      //! 메일 발송 처리
      sendErrorMail(error, 'goNextProcess');
    }
  };

  // useEffect(() => {
  //   if (goResult) {
  //     finishCallback();
  //   }
  // }, [goResult]);

  const IconMic = () => {
    return (
      <svg
        style={{ width: '100%', height: '100%' }}
        width='120'
        height='120'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 120 120'
      >
        <g>
          <g>
            <path
              d='m60,72.5l0,0c-10.625,0 -18.75,-8.125 -18.75,-18.75l0,-25c0,-10 8.125,-18.75 18.75,-18.75l0,0c10,0 18.75,8.125 18.75,18.75l0,25c0,10 -8.75,18.75 -18.75,18.75z'
              fill='#fff'
            />
            <path
              d='m85,41.25l0,15.625c0,11.875 -11.25,21.875 -23.75,21.875l-2.5,0c-12.5,0 -23.75,-10 -23.75,-21.875l0,-15.625c-3.75,0 -6.25,2.5 -6.25,6.25l0,9.375c0,13.75 11.25,25.625 25,27.5l0,13.125c-18.75,0 -15.625,12.5 -15.625,12.5l43.75,0c0,0 3.125,-12.5 -15.625,-12.5l0,-13.125c13.75,-2.5 25,-13.75 25,-27.5l0,-9.375c0,-3.75 -2.5,-6.25 -6.25,-6.25z'
              fill='#fff'
            />
          </g>
        </g>
      </svg>
    );
  };

  useEffect(() => {
    if (!show_modal && showReady) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      setVisibleGrading(true);
      setShowReady(false);
    }
  }, [showReady, show_modal]);

  const onClickStatus = () => {
    if (status == 'prompt') {
      window.plugins.speechRecognition.hasPermission(
        (res: boolean) => {
          permission.current = res;
          if (!permission.current) {
            window.plugins.speechRecognition.requestPermission(
              () => {
                permission.current = true;
                setStatus('granted');
              },
              () => {
                setStatus('denied');
              },
            );
          } else {
            setStatus('granted');
          }
        },
        function (e: any) {
          console.error('permission err:' + e);
        },
      );
    } else if (status == 'denied') {
      setModalManualVisible(true);
    }
  };

  const onCloseModalManual = () => {
    setModalManualVisible(false);
  };

  return (
    <StyledSpeechWrap>
      <Box
        sx={{
          width: canvWidth,
          height: canvHeight,
          position: 'absolute',
        }}
      >
        <Button
          sx={{
            backgroundColor:
              status == 'granted'
                ? '#00ea5b !important'
                : status == 'prompt'
                ? '#103c5b !important'
                : '#ff4337 !important',
            color: 'white !important',
            margin: '2vh',
            padding: '1vh 2vh',
            borderRadius: '1vh',
            zIndex: '30',
          }}
          onClick={() => onClickStatus()}
          disableRipple
        >
          <StyledTooltip title='클릭하여 마이크 설정을 완료해주세요.' placement='bottom-start' open={openTooltip}>
            <span>
              {status == 'granted' ? '마이크 연결 완료' : status == 'prompt' ? '마이크 최초 설정' : '마이크 설정 필요'}
            </span>
          </StyledTooltip>
        </Button>
      </Box>
      {(!iosRecording || waitingCount > 0) && (nextDisable || visibleGrading || hideMic) ? (
        waitingCount > 0 && useTimer ? (
          <StyledCountButton disableRipple is_mobile={is_mobile}>
            <StyledCount>{waitingCount}</StyledCount>
            <PulseBox></PulseBox>
          </StyledCountButton>
        ) : null
      ) : iosRecording ? (
        <StyledIosMicButton onClick={() => stopRecordIos()} is_mobile={is_mobile}>
          <Box sx={{ width: 'inherit' }} className='mic-bounce'>
            <IconMic />
            <Box className='left wave'>
              <Box component={'span'} className='wave-3'>
                ❨
              </Box>
              <Box component={'span'} className='wave-2'>
                ❨
              </Box>
              <Box component={'span'} className='wave-1'>
                ❨
              </Box>
            </Box>
            <Box className='right wave'>
              <Box component={'span'} className='wave-1'>
                ❩
              </Box>
              <Box component={'span'} className='wave-2'>
                ❩
              </Box>
              <Box component={'span'} className='wave-3'>
                ❩
              </Box>
            </Box>
          </Box>
        </StyledIosMicButton>
      ) : (
        <StyledMicButton onClick={onClickMicButton} disableRipple is_mobile={is_mobile}>
          {<IconMic />}
        </StyledMicButton>
      )}
      <canvas
        id='recode-canvas'
        width={canvWidth}
        height={canvHeight}
        style={{
          width: `${canvWidth}px`,
          height: `${canvHeight}px`,
          zIndex: '20',
          overflowClipMargin: 'content-box',
          overflow: 'clip',
          background: `linear-gradient(#135f8b, #203151), url(${canvas_bg}) repeat 10px 10px`,
          borderRadius: '1.2rem',
          aspectRatio: `auto ${canvWidth} / ${canvHeight}`,
          position: 'absolute',
        }}
      ></canvas>

      <ModalGrading
        onSoundPlay={onSoundPlayRef.current}
        onPlayEffectSoundPlay={onPlayEffectSoundPlayRef.current}
        onAnswerSoundPlay={onAnswerSoundPlayRef.current}
        onAnswerSoundStop={onAnswerSoundStopRef.current}
        visible={visibleGrading}
        onClose={(modal_type?: string) => goNextStep(modal_type)}
        rightanswer={rightanswer}
        answer={answer}
        question={question}
        content={content}
        type={modalType}
        record_cnt={retryCnt}
        max_record_cnt={1}
        isSpeak={true}
      />
      <ModalManual visible={modalManualVisible} onClose={onCloseModalManual} />
    </StyledSpeechWrap>
  );
}

export default SpeechRecognition;
