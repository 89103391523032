import { atom } from 'recoil';

export const bookState = atom<BookType>({
  key: 'bookState',
  default: {
    series: null,
    longvoca: null,
    speak: null,
    customer: null,
    exam: null,
    textbook: null,
    selected_book: null,
    selected_series: null,
    bookmark: null,
    booklist_set: null,
    grammar: null,
  },
});
