import {
  TbSquare0Filled,
  TbSquare1Filled,
  TbSquare2Filled,
  TbSquare3Filled,
  TbSquare4Filled,
  TbSquare5Filled,
  TbSquare6Filled,
  TbSquare7Filled,
  TbSquare8Filled,
  TbSquare9Filled,
} from 'react-icons/tb';

//숫자로 된 문자열을 이모지로 바꿔줌
export function NumberToEmoji({ word, colorCode }: { word: string; colorCode: string }) {
  const parseEmoji = (word: string): JSX.Element[] => {
    const result: JSX.Element[] = [];

    for (let i = 0; i < word.length; i++) {
      switch (word[i]) {
        case '0':
          result.push(<TbSquare0Filled key={i} />);
          break;
        case '1':
          result.push(<TbSquare1Filled key={i} />);
          break;
        case '2':
          result.push(<TbSquare2Filled key={i} />);
          break;
        case '3':
          result.push(<TbSquare3Filled key={i} />);
          break;
        case '4':
          result.push(<TbSquare4Filled key={i} />);
          break;
        case '5':
          result.push(<TbSquare5Filled key={i} />);
          break;
        case '6':
          result.push(<TbSquare6Filled key={i} />);
          break;
        case '7':
          result.push(<TbSquare7Filled key={i} />);
          break;
        case '8':
          result.push(<TbSquare8Filled key={i} />);
          break;
        case '9':
          result.push(<TbSquare9Filled key={i} />);
          break;
        default:
          result.push(<span key={i}>{word[i]}</span>);
      }
    }

    return result;
  };

  const emojiWord = parseEmoji(word);

  return (
    <>
      <span
        style={{
          color: colorCode,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {emojiWord}
      </span>
    </>
  );
}
