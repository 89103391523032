import { styled, Box, IconButton, Slide } from '@mui/material';

import VideoLearning from './VideoLearning';
import { d_flex, d_flex_center } from 'styles/common';
import GrammarCAB from './GrammarCAB';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { learningState } from 'recoil/model/learning';
import GrammarMC from './GrammarMC';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { BASE_URL, GRAMMAR_CAB, GRAMMAR_MC, GRAMMAR_SA, GRAMMAR_SC, GRAMMAR_T } from 'utils/constants';
import { loadingState, setPercent } from 'recoil/common/loading';
import { fetchPostApi, fetchPutApi, fetchTabStudyGetApi } from 'utils/api';
import GrammarSA from './GrammarSA';
import GrammarSC from './GrammarSC';
import { userState } from 'recoil/model/user';
import { routesState } from 'recoil/common/routes';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import Stepper from 'components/common/Grammar/GrammarStepper';
import { bookState } from 'recoil/model/book';
import { ModalContext } from 'provider/ModalProvider';

export const StyledLearningWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
}));

function GrammarLearningIndex() {
  const { modal_alert } = useContext(ModalContext);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const bookStateData = useRecoilValue<BookType>(bookState);
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);

  const {
    mod,
    contents,
    resultsheet,
    current_step,
    current_page,
    show_modal,
    current_module_settings,
    use_image,
    direction,
    first_learning,
    relearning,
    reset_learning,
    status,
    record_id,
    unit_id,
    save_enabled,
    grammar_learning_type,
    grammar_contents,
    grammar_tab_unit_id,
    grammar_origin_unit_name,
    schedule_id,
    grammar_unit_seq,
    modules,
    learning_type,
  } = learningStateData;
  const { selected_book } = bookStateData;
  const [content, setContent] = useState<GrammarContentType | null>(null);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { customer_id } = userStateData;
  const { root_path, child_path } = routesStateData;
  const navigate = useNavigate();

  //! ref
  const contents_ref = useRef(contents);
  contents_ref.current = contents;

  useEffect(() => {
    if (!status || learning_type == null) {
      if (child_path == 'learning') navigate(`${BASE_URL}/${root_path}`);
      else if (root_path && child_path) navigate(`${BASE_URL}/${root_path}/${child_path}`);
      else navigate(`${BASE_URL}/`);
    } else if (['V003Y', 'V003DY', 'V003TY', 'S003Y', 'S003DY', 'S003TY'].includes(userStateData.product)) {
      setLearningStateData(prevState => ({
        ...prevState,
        status: false,
      }));
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      navigate(`${BASE_URL}/`);
    }
  }, []);

  const isReviewLearning = useMemo(() => {
    let res = false;
    const isMiddleSchoolBooks = selected_book?.grade.includes('b');
    if (isMiddleSchoolBooks) {
      // * 중등
      if (grammar_origin_unit_name?.includes('Review')) {
        res = true;
      }
    } else {
      // * 초등
      if (grammar_unit_seq && grammar_unit_seq % 3 === 0) {
        res = true;
      } else if (grammar_origin_unit_name?.includes('Review')) {
        res = true;
      }
    }
    return res;
  }, []);

  useEffect(() => {
    getGrammarContents();
  }, [current_page]);

  const getGrammarContents = async () => {
    //! 학습하는 시점 아니면 호출 안 되게 세팅
    if (!status) return false;

    if (grammar_learning_type === GRAMMAR_T && current_page === 0) {
      return false; // 영상강의는 요청 X
    }

    if (contents.length > 0) {
      //! 이어하기 하는 경우에 처리
      setPercent(setLoadingStateData, 67);
      setLearningStateData(prevState => ({
        ...prevState,
        grammar_contents: contents as any,
        grammar_init: true,
      }));

      return false;
    }

    const page = grammar_learning_type === GRAMMAR_T ? current_page : current_page + 1;
    const grammar_contents = await fetchTabStudyGetApi(
      `v2/book/getgrammardata?unitids=${grammar_tab_unit_id}&sub_unit=${grammar_learning_type}${page}`,
    );
    if (grammar_contents) {
      //! 컨텐츠 mod별로 안에 저장
      const mod_tmp = JSON.parse(JSON.stringify(mod));
      mod_tmp[current_page].contents = grammar_contents;

      setPercent(setLoadingStateData, 67);
      setLearningStateData(prevState => ({
        ...prevState,
        mod: mod_tmp,
        grammar_contents: grammar_contents,
        grammar_init: true,
      }));
    }
  };

  useEffect(() => {
    setContent(grammar_contents[current_step]);
  }, [current_step, grammar_contents]);

  const sendResultsheet = async (resultsheet: any) => {
    let mod_tmp: ModType[] = [];

    if (!resultsheet) return false;

    // 현재 모듈 정보 저장
    mod_tmp = JSON.parse(JSON.stringify(mod));

    mod_tmp[current_page].done = 1;
    mod_tmp[current_page].step = current_step;
    mod_tmp[current_page].resultsheet = resultsheet;

    let module_record_id = learningStateData.module_record_id;

    if (userStateData.id > 5) {
      if (first_learning) {
        const type = `grammar_${grammar_learning_type}`;
        const prepare_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules`,
          { type: type, book_type: 'books' },
        );

        if (prepare_record.result) {
          module_record_id = prepare_record.data.moduleRecordId;
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: module_record_id,
            first_learning: false,
            save_enabled: true,
          }));
        }
      } else if (relearning) {
        const reset_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}`,
          { type: 'relearning', book_type: 'books' },
        );

        if (reset_record.result) {
          module_record_id = reset_record.data.moduleRecordId;
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: module_record_id,
            relearning: false,
            save_enabled: true,
          }));
        }
      } else if (reset_learning) {
        const reset_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}`,
          { type: 'reset', book_type: 'books' },
        );

        if (reset_record.result) {
          setLearningStateData(prevState => ({
            ...prevState,
            reset_learning: false,
            save_enabled: true,
          }));
        }
      } else if (!save_enabled) {
        setLearningStateData(prevState => ({
          ...prevState,
          save_enabled: true,
        }));
      }
    }

    // 현재 단계 이후 완료 모듈 확인
    const incompleteModules = mod.slice(current_page + 1).filter((module: ModType) => module.done == 0);

    // 학습 단계 이후 미완료 모듈이 있는 경우
    if (incompleteModules.length > 0) {
      setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
      const firstIncompleteModule = incompleteModules[0];

      setLearningStateData(prevState => ({
        ...prevState,
        mod: mod_tmp,
        current_page: firstIncompleteModule.seq,
        current_step: firstIncompleteModule.step ?? 0, // 학습 진행도 없으면 0
        resultsheet:
          typeof firstIncompleteModule.seq == 'number' ? mod[firstIncompleteModule.seq].resultsheet ?? [] : [], // 성적표 초기화
        contents: [],
      }));
    } else {
      // 학습 단계 이전 미완료 모듈 확인
      setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
      const incompleteModulesBeforeCurrent = mod.slice(0, current_page).filter((module: ModType) => module.done == 0);

      // 이전 학습 단계에 미완료 모듈이 있을 경우
      if (incompleteModulesBeforeCurrent.length > 0) {
        const firstIncompleteModule = incompleteModulesBeforeCurrent[0];
        setLearningStateData(prevState => ({
          ...prevState,
          mod: mod_tmp,
          current_page: firstIncompleteModule.seq,
          current_step: firstIncompleteModule.step ?? 0, // 학습 진행도 없으면 0
          resultsheet:
            typeof firstIncompleteModule.seq == 'number' ? mod[firstIncompleteModule.seq].resultsheet ?? [] : [], // 성적표 초기화,
          contents: [],
        }));
      } else {
        setPercent(setLoadingStateData, 1, '학습 데이터를 분석하는 중입니다.');
        const learning_data = JSON.parse(JSON.stringify(learningStateData));
        learning_data.mod = mod_tmp;
        learning_data.studytime = learning_data.studytime + dayjs().diff(learningStateData.starttime, 's');

        if (userStateData.id > 5) {
          const result_res = await fetchPutApi(
            `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}/result`,
            { ...learning_data },
          );
          if (result_res.result) {
            setTimeout(() => {
              let path =
                root_path == 'recent'
                  ? `${BASE_URL}/${root_path}/result`
                  : `${BASE_URL}/${root_path}/${child_path}/result`;
              if (learning_type == 7 && mod.length == 1) {
                //! 이론학습의 복습강의 인 경우 성적표 페이지가 아닌 문법 교재목록 페이지로 이동시키기
                path = root_path == 'recent' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`;
              }
              navigate(path, {
                state: {
                  schedule_id: schedule_id,
                  unit_id: grammar_tab_unit_id,
                  record_id: record_id,
                  module_record_id: module_record_id,
                  tab_name: learningStateData.tab_name,
                  schedule_type: learningStateData.book_type,
                  afterLearn: true,
                },
              });
            }, 0);
          }
        } else {
          setLearningStateData(prevState => ({
            ...prevState,
            mod: mod_tmp,
          }));
          setTimeout(() => {
            let path =
              root_path == 'recent'
                ? `${BASE_URL}/${root_path}/result`
                : `${BASE_URL}/${root_path}/${child_path}/result`;
            if (learning_type == 7 && mod.length == 1) {
              //! 이론학습의 복습강의 인 경우 성적표 페이지가 아닌 문법 교재목록 페이지로 이동시키기
              path = root_path == 'recent' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`;
            }
            navigate(path, {
              state: {
                schedule_id: schedule_id,
                unit_id: grammar_tab_unit_id,
                record_id: record_id,
                module_record_id: module_record_id,
                tab_name: learningStateData.tab_name,
                schedule_type: learningStateData.book_type,
              },
            });
          }, 0);
        }
      }
    }
  };

  function createResultWrongData(
    answer: string,
    rightAnswer: string,
    mode?: string,
    sc_items?: string[],
    selectedWord?: string[],
  ) {
    let result = '';
    let match = false;

    if (mode == 'SC' && sc_items && selectedWord) {
      //! SC 모듈은 별도 처리 필요
      const right_sc_items: string[] = [];
      let sc_item_array: any[] = [];
      const result_html_array: string[] = [];
      const selected_word_array = selectedWord.map(item => item.replace('&nbsp;', ''));
      const rightAnswerWords = rightAnswer.split(' ');
      let spell_idx = 0;

      rightAnswerWords.forEach((word, idx) => {
        if (idx != 0) spell_idx += 1;
        let isIn = false;

        for (let i = 0; i < sc_items.length; i++) {
          const sc_item = sc_items[i];

          if (word == sc_item) {
            sc_item_array.push({ spell_idx, spell: sc_item });
            spell_idx += sc_item.length;
            isIn = true;
            break;
          }
        }

        if (isIn) {
          isIn = false;
        } else {
          if (word.includes(',') || word.includes('.') || word.includes('?') || word.includes('!')) {
            const split_words = word.split(/(\s|(?=\.)|(?=,)|(?=\?)|(?=!))/).filter(word => word != '');
            for (let i = 0; i < split_words.length; i++) {
              const split_word = split_words[i];
              for (let i = 0; i < sc_items.length; i++) {
                const sc_item = sc_items[i];

                if (split_word == sc_item) {
                  sc_item_array.push({ spell_idx, spell: split_word });
                  spell_idx += split_word.length;
                  isIn = true;
                  break;
                }
              }
            }
          }
        }
      });

      sc_item_array.sort((a: any, b: any) => {
        if (a.spell_idx < b.spell_idx) return -1;
        else return 1;
      });

      sc_item_array = sc_item_array.map(item => item.spell);

      for (let i = 0; i < sc_item_array.length; i++) {
        const answer = sc_item_array[i];
        right_sc_items.push(answer);
      }

      for (let i = 0; i < right_sc_items.length; i++) {
        const spell = selected_word_array[i];
        const right_spell = right_sc_items[i];
        let result_html = '';
        if (spell == right_spell) {
          result_html = `<span>${right_spell}&nbsp;</span>`;
        } else {
          result_html = `<span style="color: red;">${right_spell}&nbsp;</span>`;
        }

        if ([',', '.', '!', '?'].includes(right_spell)) {
          if (i - 1 >= 0) {
            result_html_array[i - 1] = result_html_array[i - 1].replace('&nbsp;', '');
          }
        }

        result_html_array.push(result_html);
      }

      return result_html_array.join('');
    }

    //! sa_type 0일 때, rightanswer가 '-'인 경우 (빈 값을 넣어야 정답인 경우)
    if (rightAnswer == '-') {
      if (answer == '') {
        return '-';
      } else {
        return `<span style="color: red;">-</span>`;
      }
    }

    // ! 입력한 답안 중, 일부만 맞았을 경우
    for (let i = 0; i < rightAnswer.length; i++) {
      if (answer[i] !== rightAnswer[i]) {
        result += `<span style="color: red;">${rightAnswer[i]}</span>`;
      } else {
        result += rightAnswer[i];
        match = true;
      }
    }

    // ! 정답을 입력하지 않았거나, 정답은 입력했지만 일치하는 문자열이 아예 없을경우
    if (!match || !answer) {
      result = `<span style="color: red;">${rightAnswer}</span>`;
    }

    return result;
  }

  return (
    <StyledLearningWrap>
      {grammar_learning_type === GRAMMAR_T && current_page === 0 ? null : <Stepper></Stepper>}
      <Box
        sx={{
          width: '100%',
          height: grammar_learning_type === GRAMMAR_T && current_page === 0 ? 'calc(100%)' : 'calc(100% - 5rem)',
        }}
      >
        {status && grammar_learning_type
          ? (function () {
              if (grammar_learning_type === GRAMMAR_T && current_page === 0) {
                return <VideoLearning isReviewLearning={isReviewLearning} sendResultsheet={sendResultsheet} />;
              }

              if (grammar_contents && content) {
                const type = grammar_contents[current_step] ? grammar_contents[current_step].type : content.type;

                if (type === GRAMMAR_CAB) {
                  return (
                    <GrammarCAB
                      content={content}
                      sendResultsheet={sendResultsheet}
                      isReviewLearning={isReviewLearning}
                    />
                  );
                } else if (type === GRAMMAR_MC) {
                  return (
                    <GrammarMC
                      content={content}
                      sendResultsheet={sendResultsheet}
                      isReviewLearning={isReviewLearning}
                    />
                  );
                } else if (type === GRAMMAR_SA) {
                  return (
                    <GrammarSA
                      content={content}
                      createResultWrongData={createResultWrongData}
                      sendResultsheet={sendResultsheet}
                      isReviewLearning={isReviewLearning}
                    />
                  );
                } else if (type === GRAMMAR_SC) {
                  return (
                    <GrammarSC
                      content={content}
                      createResultWrongData={createResultWrongData}
                      sendResultsheet={sendResultsheet}
                      isReviewLearning={isReviewLearning}
                    />
                  );
                }
              }
            })()
          : null}
      </Box>
    </StyledLearningWrap>
  );
}

export default GrammarLearningIndex;
