import { styled, Modal as MuiModal, Box } from '@mui/material';
import Button from '../button/Button';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { modalChec2kData, ModalCheck2DataType } from '../../recoil/common/modalCheck2';
import { bookState } from '../../recoil/model/book';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { resetUserState, userState } from '../../recoil/model/user';
import { learningState } from '../../recoil/model/learning';
import { useEffect, useContext } from 'react';
import { BackgroundSoundContext } from '../../provider/BackgroundSoundProvider';
import { useNavigate } from 'react-router';
import { BASE_URL } from '../../utils/constants';
import { modalHiddenMissionState } from 'recoil/common/modalHiddenMission';
import { hiddenMissionState } from 'recoil/common/mission';

declare let window: any;

export const Modal = styled(MuiModal)({
  zIndex: '2147483647',
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

export const Wrapper = styled(Box)({
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 'none',
});

export const Wrap = styled(Box)(props => ({
  maxWidth: '420px',
  width: '35.7rem',
  borderRadius: '4px',
  lineHeight: '2rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const Title = styled(Box)(props => ({
  width: '100%',
  [props.theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
  [props.theme.breakpoints.down('xxl')]: {
    fontSize: '1.2rem',
  },
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '2.25rem 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& > span': {
    '&:last-of-type': {
      fontSize: '1.2rem',
      lineHeight: '1.5',
    },
  },
}));

export const ButtonWrap = styled(Box)(props => ({
  width: '100%',
  height: '3.5rem',
  borderTop: `1px solid ${props.theme.palette.gray_3.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > button': {
    width: '100%',
    height: '100%',
    fontSize: '1.1rem',
  },
}));

function ModalCheck2() {
  const navigate = useNavigate();
  const setLearningStateData = useSetRecoilState<LearningType>(learningState);
  const setUserStateData = useSetRecoilState(userState);
  const [modalCheck2, setModalCheck2] = useRecoilState<ModalCheck2DataType>(modalChec2kData);
  const { playingBGM, toggleBGM, firstPlayingBGM, resetBGM } = useContext(BackgroundSoundContext);
  const { visible, title } = modalCheck2;

  const resetLearningState = useResetRecoilState(learningState);
  const resetBookState = useResetRecoilState(bookState);
  const resetPocketNoteState = useResetRecoilState(pocketNoteState);
  const resetModalMissionState = useResetRecoilState(modalHiddenMissionState);
  const resetHiddenMissionState = useResetRecoilState(hiddenMissionState);

  const resetRecoilState = () => {
    resetUserState({ setUserStateData });
    resetLearningState();
    resetBookState();
    resetPocketNoteState();
    resetModalMissionState();
    resetHiddenMissionState();
  };

  const quit_voca_callback = () => {
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('refreshToken');
    // 학습 중일 때 멈추는 로직
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));
    resetBGM();
  };

  useEffect(() => {
    if (visible) {
      quit_voca_callback();
    }
  }, [visible]);

  const onCloseModal = () => {
    if (window.FB_UNSCRIBE) {
      window.FB_UNSCRIBE();
    }
    setModalCheck2({ visible: false, title: '' });
    resetRecoilState();
    navigate(`${BASE_URL}/login`);
  };

  return (
    <Modal open={visible}>
      <Wrapper>
        <Wrap>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <ButtonWrap>
            <Button color='purple' onClick={onCloseModal}>
              확인
            </Button>
          </ButtonWrap>
        </Wrap>
      </Wrapper>
    </Modal>
  );
}

export default ModalCheck2;
