import { styled, Box } from '@mui/material';

export const Wrapper = styled(Box)({
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  padding: '0',
  height: '38.75rem',
  width: '100%',
  position: 'relative',

  '@media (max-width: 1903px)': {
    height: '49.4rem',
  },

  '@media (max-width: 1263px)': {
    height: '42.5rem',
  },

  '@media (max-width: 959px)': {
    height: '42.5rem',
  },

  '@media (max-width: 767px)': {
    height: '35rem',
  },
});

export const MissionCardWrap = styled(Box)({
  display: 'flex',
  position: 'absolute',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  top: '16rem',
  gap: '0.5rem',
  width: '100%',

  '@media (max-width: 1903px)': {
    top: '20.8rem',
    gap: '0.65rem',
  },

  '@media (max-width: 1263px)': {
    top: '18.25rem',
    gap: '0.65rem',
  },
  '@media (max-width: 959px)': {
    top: '18.25rem',
    gap: '0.75rem',
  },
  '@media (max-width: 767px)': {
    top: '15rem',
  },
});

export const Title = styled(Box)({
  color: 'white',
  position: 'absolute',
  top: '12.3rem',
  width: '100%',
  textAlign: 'center',
  fontSize: '1.1rem',

  '@media (max-width: 1903px)': {
    top: '15.8rem',
    fontSize: '1.43rem',
  },
  '@media (max-width: 1263px)': {
    top: '13.55rem',
    fontSize: '1.35rem',
  },
  '@media (max-width: 959px)': {
    top: '13.35rem',
    fontSize: '1.45rem',
  },
  '@media (max-width: 767px)': {
    top: '11rem',
    fontSize: '1.2rem',
  },
});

export const MissionCard = styled(Box)(props => {
  if (props.className === 'completed-step') {
    return {
      width: '9.5rem',
      height: '9rem',
      backgroundColor: 'white',
      display: 'grid',
      placeContent: 'center',
      placeItems: 'center',
      textAlign: 'center',
      borderRadius: '1rem',
      gap: '0.4rem',
      color: 'black',
      fontSize: '1.1rem',
      '& > div:nth-of-type(2)': {
        color: props.theme.palette.purple.main,
        fontSize: '1.65rem',
        fontWeight: '900',
        lineHeight: '1.76rem',
      },
      '@media (max-width: 1903px)': {
        width: '12.34rem',
        height: '11.7rem',
        fontSize: '1.43rem',
        '& > div:nth-of-type(2)': {
          fontSize: '2.145rem',
          lineHeight: '2.288rem',
        },
      },
      '@media (max-width: 1263px)': {
        width: '11rem',
        height: '10.5rem',
        fontSize: '1.45rem',
        '& > div:nth-of-type(2)': {
          fontSize: '1.95rem',
          lineHeight: '2.35rem',
        },
      },
      '@media (max-width: 959px)': {
        width: '11rem',
        height: '10.5rem',
        fontSize: '1.5rem',
        '& > div:nth-of-type(2)': {
          fontSize: '2.15rem',
          lineHeight: '2.35rem',
        },
      },
      '@media (max-width: 767px)': {
        width: '9rem',
        height: '8.5rem',
        fontSize: '1.35rem',
        '& > div:nth-of-type(2)': {
          fontSize: '1.75rem',
          lineHeight: '2.25rem',
        },
      },
    };
  }

  if (props.className === 'current-step') {
    return {
      width: '9.5rem',
      height: '9rem',
      backgroundColor: '#6A24DD',
      display: 'grid',
      placeContent: 'center',
      textAlign: 'center',
      borderRadius: '1rem',
      gap: '0.4rem',
      color: 'white',
      border: '2px solid white',
      fontSize: '1.1rem',
      '& > div:nth-of-type(2)': {
        fontSize: '1.65rem',
        fontWeight: '900',
        lineHeight: '1.76rem',
      },
      '@media (max-width: 1903px)': {
        width: '12.34rem',
        height: '11.7rem',
        fontSize: '1.43rem',
        '& > div:nth-of-type(2)': {
          fontSize: '2.145rem',
          lineHeight: '2.288rem',
        },
      },
      '@media (max-width: 1263px)': {
        width: '11rem',
        height: '10.5rem',
        fontSize: '1.45rem',
        '& > div:nth-of-type(2)': {
          fontSize: '1.95rem',
          lineHeight: '2.35rem',
        },
      },
      '@media (max-width: 959px)': {
        width: '11rem',
        height: '10.5rem',
        fontSize: '1.5rem',
        '& > div:nth-of-type(2)': {
          fontSize: '2.15rem',
          lineHeight: '2.35rem',
        },
      },
      '@media (max-width: 767px)': {
        width: '9rem',
        height: '8.5rem',
        fontSize: '1.35rem',
        '& > div:nth-of-type(2)': {
          fontSize: '1.75rem',
          lineHeight: '2.25rem',
        },
      },
    };
  }

  return {
    width: '9.5rem',
    height: '9rem',
    backgroundColor: '#6A24DD',
    display: 'grid',
    placeContent: 'center',
    textAlign: 'center',
    borderRadius: '1rem',
    gap: '0.4rem',
    color: '#4C2095',
    fontSize: '1.1rem',
    '& > div:nth-of-type(2)': {
      fontSize: '1.65rem',
      fontWeight: '900',
      lineHeight: '1.76rem',
    },
    '@media (max-width: 1903px)': {
      width: '12.34rem',
      height: '11.7rem',
      fontSize: '1.43rem',
      '& > div:nth-of-type(2)': {
        fontSize: '2.145rem',
        lineHeight: '2.288rem',
      },
    },
    '@media (max-width: 1263px)': {
      width: '11rem',
      height: '10.5rem',
      fontSize: '1.45rem',
      '& > div:nth-of-type(2)': {
        fontSize: '1.95rem',
        lineHeight: '2.35rem',
      },
    },
    '@media (max-width: 959px)': {
      width: '11rem',
      height: '10.5rem',
      fontSize: '1.5rem',
      '& > div:nth-of-type(2)': {
        fontSize: '2.15rem',
        lineHeight: '2.35rem',
      },
    },
    '@media (max-width: 767px)': {
      width: '9rem',
      height: '8.5rem',
      fontSize: '1.35rem',
      '& > div:nth-of-type(2)': {
        fontSize: '1.75rem',
        lineHeight: '2.25rem',
      },
    },
  };
});
