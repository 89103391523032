export const BASE_URL = process.env.REACT_APP_NODE_ENV == 'production' ? '' : '';
export const CDN =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? 'https://etltuzgdzyaa1067545.cdn.ntruss.com/longvoca/'
    : 'https://etltuzgdzyaa1067545.cdn.ntruss.com/longvoca-dev/';
export const TTS_CDN = 'https://kr.object.ncloudstorage.com/longvoca-tts/examples/';
export const IMAGE_DIR = 'wordimages/';
export const VOICE_DIR = 'voices/';
export const LECTURE_DIR = 'lecture/';
export const VOICE_TTS_CDN = `https://kr.object.ncloudstorage.com/voca-tts/`;
export const VOICE_TTS_URL = `https://pwu7jsh8kk.apigw.ntruss.com/tts/v1/fPGur2foEm/json`;
export const NG_CDN = 'https://kr.object.ncloudstorage.com/cdn-irontrain/ngstudy';
export const ADMIN_URL =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? process.env.REACT_APP_REAL_ADMIN_HOST
    : process.env.REACT_APP_DEV_ADMIN_HOST;
export const HOMEPAGE_URL =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? process.env.REACT_APP_REAL_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;
export const STUDENT_URL =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? process.env.REACT_APP_REAL_STUDENT_HOST
    : process.env.REACT_APP_DEV_STUDENT_HOST;

import MemorizeTutorialImage1 from 'assets/images/tutorial/t1.png';
import MemorizeTutorialImage2 from 'assets/images/tutorial/t2.png';
import MemorizeTutorialImage3 from 'assets/images/tutorial/t3.png';
import MemorizeTutorialImage4 from 'assets/images/tutorial/t4.png';
import BasicTutorialImage1 from 'assets/images/tutorial/t4.png';
import BasicTutorialImage2 from 'assets/images/tutorial/t5.png';
import BasicTutorialImage3 from 'assets/images/tutorial/t6.png';
import AdvancedTutorialImage1 from 'assets/images/tutorial/t7.png';
import AdvancedTutorialImage2 from 'assets/images/tutorial/t8.png';

import arrow from 'assets/images/tutorial/content/arrow.png';
import key01 from 'assets/images/tutorial/content/key01.png';
import key02 from 'assets/images/tutorial/content/key02.png';
import key03 from 'assets/images/tutorial/content/key03.png';
import key04 from 'assets/images/tutorial/content/key04.png';
import key05 from 'assets/images/tutorial/content/key05.png';
import key06 from 'assets/images/tutorial/content/key06.png';
import search from 'assets/images/tutorial/content/search.png';
import wave from 'assets/images/tutorial/content/wave.png';
import t_s_setting_1 from 'assets/images/tutorial/setting01.png';
import t_s_setting_2 from 'assets/images/tutorial/setting02.png';

export const IMAGE_DATA_BASE_64 = '';
export const EMPTY_BANNER: BannerType = {
  enddate: '2040-02-15 23:59:59',
  height: '96',
  href: '',
  id: 'defaul',
  modifydate: '2023-01-01 00:00:00',
  path: `longvoca_banner.png`,
  postdate: '2023-01-01 00:00:00',
  priority: '999',
  short_path: `longvoca_banner.png`,
  startdate: '2023-01-01 00:00:00',
  status: '진행 중',
  target: '_blank',
  title: 'ready',
  ver: '2',
  width: '499',
};

export const FIND_TEXT_FIELD_PLACEHOLDER_LIST: { [key: string]: string } = {
  name: '이름 입력',
  loginid: '아이디 입력',
  phone: '휴대폰 번호 입력',
  cert_num: '인증번호 입력',
};

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '.',
  '?',
  '!',
  "'",
  ',',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
];

/* 대표관리자 type */
export const PRESIDENT_OF_CENTER_TYPE = 21;

/* 임시 체험 학생의 account_id */
export const TEMP_STUDENTS_ACCOUNT_IDS = [1, 2, 3, 4, 5];

//** 문법학습 모드 **//
export const GRAMMAR_MODES = ['grammar_t', 'grammar_b', 'grammar_d'];

//** 학습 방식 **//
export const MEMORIZE_HOT = 1;
export const MEMORIZE_NEW = 2;
export const BASIC = 3;
export const ADVANCED = 4;
export const WRONG = 5;
export const SPEAK = 6;
//** 문법학습 방식 **//
export const GRAMMAR_T = 't';
export const GRAMMAR_B = 'b';
export const GRAMMAR_D = 'd';
//** 학습 모듈 분류 **//
export const FLASHCARD = 1;
export const WORDBINGO = 2;
export const POPQUIZ = 3;
export const DICTATION = 4;
export const WRITING = 5;
export const SPEAKING = 6;
export const VIDEO = 7;
export const CHECK = 8;
//** 암기학습 모듈 분류 **//
export const EXAMPLE = 1;
export const LECTURE = 2;
export const MEAN = 3;
export const SPELL = 4;
export const IMAGE = 5;
export const DICTIONARY = 6;
//** 문법학습 모듈 분류 **//
export const GRAMMAR_CAB = 'CAB';
export const GRAMMAR_MC = 'MC';
export const GRAMMAR_SC = 'SC';
export const GRAMMAR_SA = 'SA';

//** 학습 헤더 설정 **//
export const HEADER: { [key: number]: string } = {
  [MEMORIZE_HOT]: '암기학습',
  [MEMORIZE_NEW]: '암기학습',
  [BASIC]: '기본학습',
  [ADVANCED]: '심화학습',
  [WRONG]: '오답학습',
  [SPEAK]: '생활영어',
};

export const MODS: { [key: number]: string } = {
  [MEMORIZE_HOT]: 'memorize_hot',
  [MEMORIZE_NEW]: 'memorize_new',
  [BASIC]: 'basic',
  [ADVANCED]: 'advanced',
  [WRONG]: 'wrong',
  [SPEAK]: 'speak',
};

export const MODULES: { [key: number]: string } = {
  [FLASHCARD]: 'flash',
  [WORDBINGO]: 'bingo',
  [POPQUIZ]: 'popquiz',
  [DICTATION]: 'dictation',
  [WRITING]: 'writing',
  [SPEAKING]: 'speaking',
};

export const MODULES_LABEL: { [key: number]: string } = {
  [FLASHCARD]: 'FLASH CARD',
  [WORDBINGO]: 'WORD BINGO',
  [POPQUIZ]: 'POP QUIZ',
  [DICTATION]: 'DICTATION',
  [WRITING]: 'WRITING',
  [SPEAKING]: 'SPEAKING',
};

export const MEMORIZE_MODULES: { [key: number]: string } = {
  [EXAMPLE]: 'example',
  [LECTURE]: 'lecture',
  [MEAN]: 'mean',
  [SPELL]: 'spell',
  [IMAGE]: 'image',
  [DICTIONARY]: 'dictionary',
};

export const MEMORIZE_LABEL: { [key: number]: string } = {
  [EXAMPLE]: '예문',
  [LECTURE]: '강의',
  [MEAN]: '의미',
  [SPELL]: '철자',
  [IMAGE]: '이미지',
  [DICTIONARY]: '사전',
};

export const SPEAK_LABEL: { [key: number]: string } = {
  [VIDEO]: '추천 학습영상',
  [CHECK]: '주요예문 점검',
  [SPEAKING]: '실전 회화학습',
};

export const THEORY_LABEL: { [key: number]: string } = {
  1: '이론영상',
  2: '단어점검',
  3: '이론점검',
};

export const SEASON = {
  SPRING: [3, 4, 5],
  SUMMER: [6, 7, 8],
  FALL: [9, 10, 11],
  WINTER: [12, 1, 2],
};

export const FONT_STD = {
  HEAD: 1.8,
  STD: 2,
  MAIN: 1.6,
  FOOT: 1.8,
  WIDTH: 49.5,
  HEIGHT: 8,
};

export const effect_sounds: Sounds = {
  after_login: '/assets/audio/effect/after_login.mp3',
  alert: '/assets/audio/effect/alert.mp3',
  click: '/assets/audio/effect/click.mp3',
  confirm: '/assets/audio/effect/confirm.mp3',
  correct: '/assets/audio/effect/correct.mp3',
  dicbtn: '/assets/audio/effect/dicbtn.mp3',
  earthquake: '/assets/audio/effect/earthquake.mp3',
  fail: '/assets/audio/effect/fail.mp3',
  lecture: '/assets/audio/effect/lecture.mp3',
  pass: '/assets/audio/effect/pass.mp3',
  report: '/assets/audio/effect/report.mp3',
  show: '/assets/audio/effect/show.mp3',
  speaking_rec: '/assets/audio/effect/speaking_rec.mp3',
  study_start: '/assets/audio/effect/study_start.mp3',
  wrong: '/assets/audio/effect/wrong.mp3',
  speaking_count_1: '/assets/audio/effect/speaking_count_1.mp3',
  speaking_count_2: '/assets/audio/effect/speaking_count_2.mp3',
  alert_video: '/assets/audio/effect/alert_video_2.mp3',
};

export const explain_tutorial_text: { [key: string]: string } = {
  m_1: '올바른 암기를 위해 <b>예문 문제를 풀어야</b>지만,\r\n<b>다음 학습으로</b> 넘어갈 수 있습니다.',
  m_2: '빠른 학습을 위해 <b>4지선다 객관식 문제</b>는\r\n마우스 보다는, <b>번호 + Enter 로 진행</b>해주세요!',
  m_3: '<b>강의 음성은 재생바</b>로 위치 조절이 가능하며,\r\n일시정지와 재생은 <b>스페이스 바</b>로 활용해주세요.',
  m_4: '학습카드는 <b>방향키 or 드래그로 상하좌우 이동</b> 가능하며,\r\n<b>다시암기 1번, 암기완료 2번</b>을 눌러주세요.',
  b_1: '플래시 카드는 <b>방향키 or 드래그로 이동</b> 가능하며,\r\n <b>스페이스 바</b>로 일시정지/재생 가능합니다.',
  b_2: '화면 상단의 <b>학습단계 박스를 선택</b>하여,\r\n<b>자유롭게 학습 과정을 이동</b>할 수 있습니다.',
  b_3: '틀린 단어는 모아 모아 <b>오답 학습에 자동 생성</b>되고,\r\n<b>★을 눌러 포켓노트 암기장</b>으로 이용해보세요.',
  a_1: '<b>제시된 알파벳</b>을 조합하여\r\n빈 칸에 <b>철자 클릭 or 타이핑하여 완성</b>하세요!',
  a_2: '<b>빨간색 원형의 마이크 버튼</b>을 누르고,\r\n<b>띠리링 소리 이후,</b> 제시된 문장을 따라 말해보세요!',
  s_1: '화면 상단의 <b>학습단계 박스를 선택</b>하여,\r\n<b>자유롭게 학습 과정을 이동</b>할 수 있습니다.',
  s_2: '학습 문장은 <b>방향키로 상하 이동</b> 가능하며,\r\n<b>효과적인 학습을 위해 3번씩 반복</b>하여 들어주세요.',
  s_3: '예문의 주요 어휘 <b>객관식 문제</b>는\r\n마우스 보다는, <b>번호 + Enter 로 진행</b>해주세요!',
  s_4: '제시된 문장을 <b>AI 선생님의 목소리</b>로 들어보고,\r\n<b>음성 인식을 눌러</b> 따라 말해보세요!',
};

interface TutorialContent {
  key: string;
  tutorialImage: string;
  explainText: string;
}

export const memorize_tutorial: TutorialContent[] = [
  {
    key: 'm_1',
    tutorialImage: MemorizeTutorialImage1,
    explainText: explain_tutorial_text.m_1,
  },
  {
    key: 'm_2',
    tutorialImage: MemorizeTutorialImage2,
    explainText: explain_tutorial_text.m_2,
  },
  {
    key: 'm_3',
    tutorialImage: MemorizeTutorialImage3,
    explainText: explain_tutorial_text.m_3,
  },
  {
    key: 'm_4',
    tutorialImage: MemorizeTutorialImage4,
    explainText: explain_tutorial_text.m_4,
  },
];

export const basic_tutorial: TutorialContent[] = [
  {
    key: 'b_1',
    tutorialImage: BasicTutorialImage1,
    explainText: explain_tutorial_text.b_1,
  },
  {
    key: 'b_2',
    tutorialImage: BasicTutorialImage2,
    explainText: explain_tutorial_text.b_2,
  },
  {
    key: 'b_3',
    tutorialImage: BasicTutorialImage3,
    explainText: explain_tutorial_text.b_3,
  },
];

export const advanced_tutorial: TutorialContent[] = [
  {
    key: 'a_1',
    tutorialImage: AdvancedTutorialImage1,
    explainText: explain_tutorial_text.a_1,
  },
  {
    key: 'a_2',
    tutorialImage: AdvancedTutorialImage2,
    explainText: explain_tutorial_text.a_2,
  },
];

export const speak_tutorial: TutorialContent[] = [
  {
    key: 's_1',
    tutorialImage: BasicTutorialImage2,
    explainText: explain_tutorial_text.s_1,
  },
  {
    key: 's_2',
    tutorialImage: MemorizeTutorialImage4,
    explainText: explain_tutorial_text.s_2,
  },
  {
    key: 's_3',
    tutorialImage: MemorizeTutorialImage1,
    explainText: explain_tutorial_text.s_3,
  },
  {
    key: 's_4',
    tutorialImage: AdvancedTutorialImage2,
    explainText: explain_tutorial_text.s_4,
  },
];

export const MEMORIZE_IMGS: string[] = [
  t_s_setting_1,
  t_s_setting_2,
  MemorizeTutorialImage1,
  MemorizeTutorialImage2,
  MemorizeTutorialImage3,
  MemorizeTutorialImage4,
  key01,
  key02,
  key03,
  key04,
  key05,
];
export const BASIC_IMGS: string[] = [
  t_s_setting_1,
  t_s_setting_2,
  BasicTutorialImage1,
  BasicTutorialImage2,
  BasicTutorialImage3,
  key04,
  key03,
  search,
];
export const ADVANCED_IMGS: string[] = [
  t_s_setting_1,
  t_s_setting_2,
  AdvancedTutorialImage1,
  AdvancedTutorialImage2,
  key06,
  arrow,
  wave,
];
