import { atom, SetterOrUpdater } from 'recoil';

interface ModalManual2Type {
  visible: boolean;
}

export const modalManual2State = atom<ModalManual2Type>({
  key: 'modalManual2',
  default: {
    visible: false,
  },
});

interface ModalManual2Setter {
  setModalManual2: SetterOrUpdater<ModalManual2Type>;
}

export const openModalManual2 = ({ setModalManual2 }: ModalManual2Setter) => {
  setModalManual2({
    visible: true,
  });
};

export const closeModalManual2 = ({ setModalManual2 }: ModalManual2Setter) => {
  setModalManual2({
    visible: false,
  });
};
