import { Routes, Route, useLocation } from 'react-router';
import { Suspense, lazy, useState, useEffect } from 'react';
import { styled, Box } from '@mui/material';

const Lookup = lazy(() => import('./Lookup'));
const Period = lazy(() => import('./Period'));
const Month = lazy(() => import('./Month'));

function ScheduleIndex() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/' element={<Lookup />} />
        <Route path='/lookup' element={<Lookup />} />
        <Route path='/period' element={<Period />} />
        <Route path='/month' element={<Month />} />
      </Routes>
    </Suspense>
  );
}

export default ScheduleIndex;
