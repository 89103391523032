import { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import ModalDefault from './ModalDefault';
import ModalAddPocketNote from './ModalAddPocketNote';
import { learningState } from '../../recoil/model/learning';
import { voiceState } from '../../recoil/common/voice';
import Button from '../button/Button';
import { styled, Box, IconButton, IconButtonProps } from '@mui/material';
import { d_flex_center, dir_column, d_flex_space_evenly } from '../../styles/common';
import { GoThumbsup } from 'react-icons/go';
import { BiCircle } from 'react-icons/bi';
import { IoClose, IoReload } from 'react-icons/io5';
import { HiCheck } from 'react-icons/hi';
import { FiStar, FiTriangle } from 'react-icons/fi';

import { student_grade_list } from '../../utils/user_tools';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import AutoText from '../text/AutoText';

const StyledModalGradingContainer = styled(Box)(props => ({
  width: '32.5rem',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '2rem',
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledModalGradingWrapper = styled(Box)(props => ({
  width: '100%',
  height: '18rem',
  minHeight: '170px',
  padding: '1rem 0 1rem 0',
  ...d_flex_center,
}));

const StyledModalGradingBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  ...d_flex_space_evenly,
  ...dir_column,
}));

interface CustomIconButtonProps extends IconButtonProps {
  bookmark?: number;
}

const CustomIconButton = styled(IconButton)<CustomIconButtonProps>(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '2rem',
  transform: 'translate(50%, -50%)',
  right: '2rem',
  svg: {
    color: props.bookmark == 1 ? '#f1bc5f' : props.theme.palette.gray.main,
    width: '1.5rem',
    height: '1.5rem',
    fill: props.bookmark == 1 ? '#f5ff1b' : 'none',
  },
}));

const RetryIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '2.5rem',
  transform: 'translate(50%, -50%)',
  right: '5rem',
  color: props.theme.palette.gray_2.main,
  fontSize: '1.1rem',
}));

const StyledIconBox = styled(Box)(props => ({
  width: '6rem',
  height: '6rem',
  borderRadius: '50%',
  ...d_flex_center,
  svg: {
    color: props.theme.palette.white.main,
    width: '4rem',
    height: '4rem',
    '&.triangle': {
      width: '3.8rem',
      height: '3.8rem',
    },
  },
}));

const StyledButtonWrapper = styled(Box)(props => ({
  width: '100%',
  height: '60px',
  maxHeight: '10vh',
  ...d_flex_center,
  border: 'none',
  borderBottomLeftRadius: '2rem',
  borderBottomRightRadius: '2rem',
}));

const StyledButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.2rem',
}));

const StyledSpanSub = styled(Box)(props => ({
  cursor: 'default',
  fontSize: '1.6rem',
  color: props.theme.palette.black_4.main,
}));

const StyledSpanMain = styled(Box)(props => ({
  cursor: 'default',
  fontSize: '2.5rem',
  fontWeight: 'bolder',
  color: props.theme.palette.black_3.main,
}));

const StyledMainAutoText = styled(AutoText)(props => ({
  cursor: 'default',
  fontWeight: 'bolder',
  color: props.theme.palette.black_3.main,
}));

const StyledSubAutoText = styled(AutoText)(props => ({
  cursor: 'default',
  color: props.theme.palette.black_4.main,
}));

const StyledIoReload = styled(IoReload)(props => ({
  '& > path:first-of-type': {
    strokeWidth: '64',
  },
  '& > path:last-of-type': {
    strokeWidth: '8',
  },
}));

const CustomIconButton2 = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '3rem',
  transform: 'translate(50%, -50%)',
  right: '4rem',
  fontSize: '1.2rem',
  color: '#e3e3e3',
}));

interface ModalGradingProps {
  visible: boolean;
  onClose: (modal_type?: string) => void;
  onCloseLate?: () => void;
  onSoundPlay?: () => Promise<void>;
  onPlayEffectSoundPlay?: () => Promise<void>;
  onAnswerSoundPlay?: () => Promise<void>;
  onAnswerSoundStop?: () => Promise<void>;
  onCallback?: () => void;
  type:
    | 'leveltest_pass'
    | 'leveltest_fail'
    | 'right_answer'
    | 'wrong_answer1'
    | 'wrong_answer2'
    | 'correct_recording'
    | 'good_recording'
    | 'wrong_recording'
    | 'fail_recording'
    | 'memorize'
    | 'test_pass'
    | 'test_fail1'
    | 'test_fail2';
  word_id?: string;
  bookmark?: boolean;
  grade?: string;
  score?: number;
  rightanswer?: string;
  answer?: string;
  question?: string;
  record_cnt?: number;
  max_record_cnt?: number;
  studytime?: number;
  content?: ContentType;
  isSpeak?: boolean;
}

function ModalGrading(props: ModalGradingProps) {
  const [pocketNoteStateData, setPocketNoteStateData] = useRecoilState<PocketNoteType>(pocketNoteState);
  const { note_contents } = pocketNoteStateData;
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { learning_type, mod, current_page } = learningStateData;
  const module_idx = mod[current_page]?.module;
  const [voiceStateData, setVoiceStateData] = useRecoilState<VoiceType>(voiceState);
  const { playing } = voiceStateData;
  const visible = props.visible;
  const visibleRef = useRef(visible);
  const onClose = props.onClose;
  const type = props.type;
  const isSpeak = props.isSpeak;
  const type_ref = useRef(type);
  const playing_ref = useRef(playing);
  playing_ref.current = playing;
  type_ref.current = type;

  const grade = props.grade;
  const score = props.score ? props.score : '';
  const rightanswer = props.rightanswer ? props.rightanswer : '';
  const answer = props.answer ? props.answer : '';
  const record_cnt = props.record_cnt ? props.record_cnt : 0;
  const max_record_cnt = props.max_record_cnt ? props.max_record_cnt : 0;
  const question = props.question ? props.question : '';
  const studytime = props.studytime ? props.studytime : 0;
  const onCallback = props.onCallback ? props.onCallback : undefined;
  const onSoundPlay = props.onSoundPlay ? props.onSoundPlay : undefined;
  const onPlayEffectSoundPlay = props.onPlayEffectSoundPlay ? props.onPlayEffectSoundPlay : undefined;
  const onAnswerSoundPlay = props.onAnswerSoundPlay ? props.onAnswerSoundPlay : undefined;
  const onAnswerSoundStop = props.onAnswerSoundStop ? props.onAnswerSoundStop : undefined;
  const onCloseLate = props.onCloseLate ? props.onCloseLate : undefined;

  const content = props.content;
  const [isNext, setIsNext] = useState<boolean>(false);
  // module_idx가 [3,4,5,6]일 때, 사용하는 변수임
  const [autoNext, setAutoNext] = useState<boolean>(false);
  const [visibleAddPocketNote, setVisibleAddPocketNote] = useState<boolean>(false);

  const timeout_ref = useRef<NodeJS.Timeout>();

  useEffect(() => {
    visibleRef.current = visible;
    if (visible) {
      if (onSoundPlay) {
        // 정답이면 확인 또는 엔터 클릭 시 넘어가게 오답은 현행 유지
        if (
          (module_idx && [3, 4, 5, 6].includes(module_idx) && type == 'right_answer') ||
          type == 'correct_recording'
        ) {
          if (onPlayEffectSoundPlay && onAnswerSoundPlay) {
            onPlayEffectSoundPlay();
            setIsNext(true);
            timeout_ref.current = setTimeout(async () => {
              await onAnswerSoundPlay();
              setTimeout(() => {
                if (visibleRef.current) goNext();
              }, 1000);
            }, 1500);
          }
        } else {
          const soundPlay = async () => {
            await onSoundPlay();
            setIsNext(true);
          };
          soundPlay();
        }
      } else {
        setIsNext(true);
      }
    } else {
      setIsNext(false);
    }
  }, [visible]);

  useEffect(() => {
    if (isNext) {
      if (module_idx && [3, 4, 5, 6].includes(module_idx) && (type == 'right_answer' || type == 'correct_recording')) {
        if (!timeout_ref.current) {
          const timeout = setTimeout(() => {
            if (visibleRef.current) goNext();
          }, 1000);
          return () => {
            timeout;
          };
        }
      }
    }
  }, [isNext]);

  const goNext = () => {
    if (timeout_ref.current) {
      clearTimeout(timeout_ref.current);
      if (type == 'right_answer' && module_idx == 4 && playing_ref.current == false) {
        if (onCloseLate) onCloseLate();
        return;
      }
    }

    if (onAnswerSoundStop) {
      if (type == 'right_answer' && module_idx == 4 && playing_ref.current == true) {
        // 딕테이션에서 정답이고, 음원 재생중 끊은 case
        onAnswerSoundStop();
        if (onCloseLate) onCloseLate();
        return;
      }
      onAnswerSoundStop();
    }

    onClose();
  };
  let fail_recording_txt = '여러번 따라 읽어보세요!';
  if (record_cnt > 0 && type == 'fail_recording') {
    fail_recording_txt =
      record_cnt == 1 && max_record_cnt == 1
        ? isSpeak
          ? '다시 말해보세요!'
          : '마지막 시도입니다!'
        : '다시 말해보세요!';
  }

  const FingerHeart = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.0'
        viewBox='0 0 100 100'
        style={{ width: '4.5rem', height: '4.5rem' }}
      >
        <g>
          <path
            d='M72.51 45.24c9.32 12.28 6.13 24.90 -5.89 33.30 -18.37 12.84 -30.89 5.15 -42.85 -9.53 -4.22 -5.18 -0.02 -12.75 6.67 -11.97 -2.69 -5.35 1.69 -11.33 7.64 -10.61 -2.00 -5.36 2.52 -10.45 8.06 -9.97l-7.31 -8.84c-6.09 -7.40 5.12 -16.76 11.33 -9.42l7.00 8.47 0.17 -0.56c1.73 -5.89 4.01 -12.98 11.81 -10.76 3.59 1.19 5.30 5.61 4.34 9.07l-5.22 15.68 4.25 5.14zm-1.57 1.37l-22.37 -27.07c-4.44 -5.26 -12.52 1.43 -8.13 6.76 7.43 9.00 14.86 17.99 22.29 26.99 0.87 1.05 -0.73 2.38 -1.60 1.33 -3.94 -4.77 -7.88 -9.54 -11.83 -14.31 -0.90 -1.10 -2.22 -1.69 -3.59 -1.79 -4.68 -0.34 -7.87 4.89 -4.89 8.58 2.50 2.85 4.88 5.57 7.33 8.53 0.87 1.06 -0.74 2.37 -1.60 1.32 -2.15 -2.61 -4.48 -5.04 -6.63 -7.66 -5.04 -3.28 -10.81 3.16 -6.98 7.80l1.48 1.79c1.37 1.25 2.97 3.48 4.20 4.97 0.92 1.12 -0.80 2.42 -1.84 1.16l-3.86 -4.69c-4.83 -4.16 -11.61 2.38 -7.54 7.38 11.13 13.65 22.50 21.41 40.05 9.14 10.59 -7.40 14.28 -19.40 5.51 -30.23zm-12.20 -18.02l7.96 9.63 4.81 -14.44c0.63 -2.36 -0.56 -5.64 -3.03 -6.46 -6.74 -1.86 -8.24 6.62 -9.74 11.27z'
            fill='white'
            stroke='white'
            strokeWidth='3'
          />
        </g>
      </svg>
    );
  };

  const CurrentIcon = () => {
    if (type === 'leveltest_pass')
      return <GoThumbsup style={{ width: '3.25rem', height: '3.25rem' }} strokeWidth={'0'} />;
    else if (type === 'leveltest_fail') return <FingerHeart />;
    else if (type === 'right_answer' || type === 'correct_recording') return <BiCircle strokeWidth={'1.5'} />;
    else if (type == 'good_recording')
      return <FiTriangle className='triangle' style={{ paddingBottom: '0.25rem', strokeWidth: '3.5' }} />;
    else if (
      type === 'wrong_answer1' ||
      type === 'wrong_answer2' ||
      type === 'test_fail2' ||
      type == 'wrong_recording' ||
      (type === 'fail_recording' && record_cnt === 0)
    )
      return <IoClose style={{ width: '5.5rem', height: '5.5rem' }} strokeWidth={'15'} />;
    else if ((type === 'fail_recording' && record_cnt > 0) || type === 'test_fail1') return <StyledIoReload />;
    else if (type === 'memorize' || type === 'test_pass') return <HiCheck strokeWidth={'2'} />;
    else return null;
  };

  const CurrentResult = () => {
    let result = '';
    if (rightanswer) {
      if (type === 'wrong_answer2') {
        let last_wrong = false;
        for (let i = 0; i < rightanswer?.length; i++) {
          if (answer && rightanswer[i] === answer[i]) {
            if (last_wrong) {
              result += '</span>';
              last_wrong = false;
            }
            result += rightanswer[i];
          } else {
            if (!last_wrong) {
              result += '<span class="c_red">';
              last_wrong = true;
            }
            result += rightanswer[i];
          }
        }
        if (last_wrong) result += '</span>';
      } else {
        result = rightanswer;
      }
      return result;
    } else return '';
  };

  const color = [
    'leveltest_fail',
    'wrong_answer1',
    'wrong_answer2',
    'wrong_recording',
    'fail_recording',
    'test_fail1',
    'test_fail2',
  ].includes(type)
    ? '#fd5348'
    : type == 'good_recording'
    ? '#59d577'
    : '#48a1fd';

  const toggleBookmark = () => {
    if (content?.spell) {
      if (!note_contents.some(word => word.spell == content.spell)) setVisibleAddPocketNote(true);
    }
  };

  const onClickRetry = () => {
    if (isNext) {
      if (timeout_ref.current) {
        clearTimeout(timeout_ref.current);
      }
      if (onAnswerSoundStop) {
        onAnswerSoundStop();
      }
      onClose('good_recording');
    }
  };

  const onClickClose = () => {
    if (isNext) {
      if (timeout_ref.current) {
        clearTimeout(timeout_ref.current);
      }
      if (onAnswerSoundStop) {
        if (type == 'right_answer' && module_idx == 4 && playing_ref.current == true) {
          // 딕테이션에서 정답이고, 음원 재생중 끊은 case
          onAnswerSoundStop();
          if (onCloseLate) onCloseLate();
          return;
        }
        onAnswerSoundStop();
      }
      onClose();
    }
  };

  useEffect(() => {
    bindKeyboard();
    return () => {
      unbindKeyboard();
    };
  }, [visible, isNext]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (evt: KeyboardEvent) => {
    if (
      ![
        'right_answer',
        'wrong_answer1',
        'wrong_answer2',
        'correct_recording',
        'good_recording',
        'wrong_recording',
        'fail_recording',
      ].includes(type)
    ) {
      evt.preventDefault();
      return;
    }

    if (evt.key == 'Enter' && isNext) {
      evt.preventDefault();
      goNext();
    }
  };

  return (
    <ModalDefault
      visible={visible}
      onClose={() => {
        return;
      }}
      boxShadow={true}
      className={'grade'}
    >
      <StyledModalGradingContainer>
        {type === 'wrong_answer1' ||
        type === 'wrong_answer2' ||
        (type == 'wrong_recording' && !isSpeak) ||
        (type === 'fail_recording' && record_cnt === 0 && !isSpeak) ? (
          <CustomIconButton
            onClick={toggleBookmark}
            disableRipple
            bookmark={content?.spell && !note_contents.some(word => word.spell == content.spell) ? 0 : 1}
          >
            <FiStar />
          </CustomIconButton>
        ) : type == 'good_recording' || (type == 'correct_recording' && isSpeak) ? (
          <CustomIconButton
            sx={{
              top: '3rem',
              right: '4rem',
              fontSize: '1.2rem',
              color: '#616161',
              cursor: isNext ? 'pointer' : 'default',
            }}
            disableRipple
            disableFocusRipple
            onClick={onClickRetry}
          >
            재시도
          </CustomIconButton>
        ) : null}
        {type === 'memorize' ? (
          <RetryIconButton onClick={() => (onCallback ? onCallback() : null)} disableRipple>
            다시 암기하기
          </RetryIconButton>
        ) : null}
        <StyledModalGradingWrapper>
          <StyledModalGradingBox>
            <StyledIconBox
              sx={{
                backgroundColor: color,
                paddingLeft: type == 'fail_recording' ? '0.25rem' : '',
              }}
            >
              {<CurrentIcon />}
            </StyledIconBox>
            <Box sx={{ width: '80%', ...d_flex_space_evenly, ...dir_column }}>
              {type.includes('recording') ? (
                <>
                  <StyledSpanSub component={'span'}>
                    {type == 'correct_recording'
                      ? '완벽한 발음입니다!'
                      : type == 'good_recording'
                      ? '스스로 한번 더 말해보세요!'
                      : fail_recording_txt}
                  </StyledSpanSub>
                  {/* <br />
                  <StyledMainAutoText maxFontSize={1.4} minFontSize={1.2} maxLines={1}>
                    {`인식 : ${answer.trim() ? answer : '-'}`}
                  </StyledMainAutoText> */}
                </>
              ) : (
                <>
                  {type.includes('answer') ? (
                    <>
                      <StyledSpanMain dangerouslySetInnerHTML={{ __html: CurrentResult() }}></StyledSpanMain>
                      <StyledSubAutoText maxFontSize={1.6} minFontSize={1.4} maxLines={1}>
                        {question}
                      </StyledSubAutoText>
                    </>
                  ) : (
                    <>
                      {type.includes('level') ? (
                        <>
                          <StyledSpanMain component={'span'} sx={{ color: color }}>
                            {student_grade_list.find(item => item.id == grade)?.name}
                          </StyledSpanMain>
                          <StyledSpanSub component={'span'}>종합 점수 {score}점</StyledSpanSub>
                        </>
                      ) : (
                        <>
                          {type == 'memorize' ? (
                            <>
                              <StyledSpanMain component={'span'} sx={{ color: color }}>
                                PASS
                              </StyledSpanMain>
                              <StyledSpanSub component={'span'}>암기시간 {studytime}분</StyledSpanSub>
                            </>
                          ) : (
                            <>
                              <StyledSpanMain component={'span'} sx={{ color: color }}>
                                {type == 'test_pass' ? 'PASS' : 'FAIL'}
                              </StyledSpanMain>
                              <StyledSpanSub component={'span'}>
                                {type == 'test_pass'
                                  ? `합격 점수 ${score}점`
                                  : type == 'test_fail1'
                                  ? '추가 테스트 생성!'
                                  : `불합격 점수 ${score}점`}
                              </StyledSpanSub>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </StyledModalGradingBox>
        </StyledModalGradingWrapper>
        <StyledButtonWrapper
          sx={{
            cursor: isNext ? 'pointer' : 'default',
            backgroundColor: color,
          }}
          onClick={() => onClickClose()}
        >
          <StyledButton sx={{ cursor: isNext ? 'pointer' : 'default' }} color='white' disableRipple>
            {record_cnt > 0 && type == 'fail_recording' ? '재시도' : '확인'}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledModalGradingContainer>
      {content ? (
        <ModalAddPocketNote
          visible={visibleAddPocketNote}
          onClose={() => {
            setVisibleAddPocketNote(false);
          }}
          content={[content]}
        />
      ) : null}
    </ModalDefault>
  );
}

export default ModalGrading;
