import * as React from 'react';
import { useContext } from 'react';

import ModalDefault from './ModalDefault';
import Button from '../button/Button';

import { ModalContext } from '../../provider/ModalProvider';

import { StyledLine, d_flex_center, dir_column } from '../../styles/common';
import { styled, Box } from '@mui/material';

const StyledModalWrapper = styled(Box)(props => ({
  maxWidth: '420px',
  width: '31rem',
  borderRadius: '4px',
  lineHeight: '1.2',
  backgroundColor: props.theme.palette.white.main,
  ...d_flex_center,
  ...dir_column,
}));

const StyledModalTitle = styled(Box)(props => ({
  width: '100%',
  // [props.theme.breakpoints.up('xxl')]: {
  //   fontSize: '1.1rem',
  // },
  // [props.theme.breakpoints.down('xxl')]: {
  // },
  fontSize: '1.15rem',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '3rem 1.5rem 2.5rem 1.5rem',
  ...d_flex_center,
  ...dir_column,
  lineHeight: '1.3',
  span: {
    fontFamily: 'Apple SD Gothic Neo',
    '&:last-of-type': {
      fontSize: '1.15rem',
      lineHeight: '1.55',
    },
  },
}));

const StyledModalUtil = styled(Box)(props => ({
  width: '100%',
  height: '4rem',
  borderTop: `1px solid ${props.theme.palette.gray_3.main}`,
  ...d_flex_center,
}));

const ModalConfirmButton = styled(Button)(({ theme }) => ({
  fontSize: '1.15rem',
  fontFamily: 'Apple SD Gothic Neo',
  width: 'calc(50% - 0.5px)',
  height: '100%',
  '&.green': {
    color: '#57dc86 !important',
    fontWeight: '500',
  },
}));

function ModalStudyConfirm() {
  const { modal_study_confirm } = useContext(ModalContext);
  const onCloseModal = () => {
    modal_study_confirm.closeModalStudyConfirm();
  };
  function onButtonClick(text: string) {
    if (text == modal_study_confirm.data.right) {
      modal_study_confirm.data.cancle_callback ? modal_study_confirm.data.cancle_callback() : false;
    } else {
      modal_study_confirm.data.confirm_callback();
    }
    modal_study_confirm.closeModalStudyConfirm();
  }

  const isGrammarVideoLearning = modal_study_confirm.data.type === 'end_video';
  // console.log(isGrammarVideoLearning, 'isGrammarVideoLearning');
  return (
    <>
      <ModalDefault visible={modal_study_confirm.data.visible} onClose={onCloseModal}>
        <StyledModalWrapper>
          <StyledModalTitle dangerouslySetInnerHTML={{ __html: modal_study_confirm.data.title }}></StyledModalTitle>
          <StyledModalUtil>
            <ModalConfirmButton
              color='purple'
              className={isGrammarVideoLearning ? 'green' : ''}
              onClick={() => onButtonClick(modal_study_confirm.data.left)}
            >
              {modal_study_confirm.data.left}
            </ModalConfirmButton>
            <StyledLine></StyledLine>
            <ModalConfirmButton color='gray_1' onClick={() => onButtonClick(modal_study_confirm.data.right)}>
              {modal_study_confirm.data.right}
            </ModalConfirmButton>
          </StyledModalUtil>
        </StyledModalWrapper>
      </ModalDefault>
    </>
  );
}

export default ModalStudyConfirm;
