import { Box, BoxProps, Button, IconButton, LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { d_flex, d_flex_center, d_flex_start, dir_column } from 'styles/common';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingState, setPercent } from 'recoil/common/loading';
import AutoText, { AutoTextProps } from 'components/text/AutoText';
import { learningState } from 'recoil/model/learning';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { settingState } from 'recoil/model/settings';
import OXMark from 'components/common/Study/OXMark';
import { EffectSoundContext } from 'provider/EffectSoundProvider';
import { useVoice } from 'recoil/common/voice';
import { NG_CDN, FONT_STD, CDN, IMAGE_DIR } from 'utils/constants';
import O_ICON from '../../../assets/images/learning/O_mobile_gray.png';
import O_ICON_active from '../../../assets/images/learning/O_mobile.png';
import X_ICON from '../../../assets/images/learning/X_mobile_gray.png';
import X_ICON_active from '../../../assets/images/learning/X_mobile.png';
import { StyledCommentBox } from './GrammarSA';
import { ModalContext } from 'provider/ModalProvider';

interface PopquizBoxProps extends BoxProps {
  status: 'selected' | 'right' | 'wrong' | '';
}

const StyledGrammarMCWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledPopquizBody = styled(Box)(props => ({
  height: 'calc(100% - 4rem)',
  width: '100%',
  ...d_flex,
}));

const StyledQuestionWrap = styled(Box)(props => ({
  width: '50%',
  height: '100%',
  ...d_flex_center,
}));

const StyledQuestionAutoText = styled(AutoText)(props => ({
  fontWeight: '700',
  textAlign: 'center',
  color: props.theme.palette.purple.main,
}));

const StyledQuestionImageBox = styled(Box)(props => ({
  ...d_flex_start,
  ...dir_column,
}));

const StyledSelectionWrap = styled(Box)(props => ({
  width: '50%',
  height: '100%',
  backgroundColor: '#fcfaff',
  ...d_flex_center,
  ...dir_column,

  '& .u-l': {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
  },
}));

const StyledSelectionBox = styled(Box)<PopquizBoxProps>(props => ({
  width: '70%',
  height: '9vh',
  margin: '2vh auto',
  cursor: 'pointer',
  backgroundColor:
    props.status == 'selected'
      ? '#daffd7'
      : props.status == 'wrong'
      ? '#ffe9e5'
      : props.status == 'right'
      ? '#caf9ff'
      : props.theme.palette.white.main,
  border:
    props.status == 'selected'
      ? '1px solid #b6eabb'
      : props.status == 'wrong'
      ? '1px solid #ffd5d1'
      : props.status == 'right'
      ? '1px solid #8abdde'
      : '1px solid #e8dcfe',
  borderRadius: '1rem',
  ...d_flex_center,
}));

interface SelectionAutoTextProps extends AutoTextProps {
  status: 'selected' | 'right' | 'wrong' | '';
}

const StyledSelectionAutoText = styled(AutoText)<SelectionAutoTextProps>(props => ({
  width: '90%',
  fontWeight: '700',
  color:
    props.status == 'selected'
      ? '#0fd527'
      : props.status == 'wrong'
      ? '#ff4c39'
      : props.status == 'right'
      ? '#2973d0'
      : props.theme.palette.purple.main,
}));

const StyledPopquizBottom = styled(Box)(props => ({
  width: '96%',
  height: '4rem',
  borderTop: '1px solid #e0e3e5',
  ...d_flex_center,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

export const StyledIconButton = styled(IconButton)(props => ({
  position: 'absolute',
  backgroundColor: 'white',
  width: '3rem',
  height: '3rem',
  padding: '0',
  top: '50%',
  zIndex: '2',
  transform: 'translateY(-50%)',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
  '&.Mui-disabled > svg': {
    color: '#e8e9ec',
  },
}));

const TabStyleMCWrap = styled(Box)(props => ({
  height: '70vh',
  width: '100%',
  overflow: 'hidden auto',
}));

const TabStyleQuestionWrap = styled(Box)(props => ({
  ...d_flex_center,
  padding: '0 7vw',
  minHeight: '7vh',
  fontWeight: '600',
}));

const TabStylePassageWrap = styled(Box)(props => ({
  // border: 'thin dashed #ddd',
  backgroundColor: '#fcf9ff',
  padding: '1vh 2%',
  lineHeight: '1.4',
  // width: '80vw',
  width: '72vw',
  margin: '0 auto',
  ...d_flex_center,

  span: {
    lineHeight: '1.4',
  },

  ' .underline-under': {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: 'from-font',
    textUnderlineOffset: '-3px',
  },
}));

const TabStyleExampleWrap = styled(Box)(props => ({
  paddingX: '8%',
  display: 'flex',
  flexWrap: 'wrap',
  marginX: '-12px',
  height: '100%',
  alignItems: 'center',
  alignContent: 'center',
}));

const TabStyleExample = styled(Box)(props => ({
  width: '100%',
  padding: '1rem',
  height: '100%',
  maxWidth: '50%',
}));

const TabStyleChoiceButton = styled(Button)<SelectionAutoTextProps>(props => ({
  ...d_flex_center,
  justifyContent: props.className === 'review' ? 'flex-start' : 'center',
  textAlign: props.className === 'review' ? 'left' : 'center',
  width: '100%',
  marginBottom: '0',
  fontSize: 'inherit',
  height: '100%',
  padding: '0 23.1px',
  border: '2px solid #ddd',
  paddingX: '1rem',
  borderRadius: '1.2rem',
  // color: '#9e9e9e !important',
  backgroundColor:
    props.status == 'selected'
      ? '#daffd7'
      : props.status == 'wrong'
      ? '#ffe9e5'
      : props.status == 'right'
      ? '#caf9ff'
      : props.theme.palette.white.main,
  color:
    props.status == 'selected'
      ? '#0fd527'
      : props.status == 'wrong'
      ? '#ff4c39'
      : props.status == 'right'
      ? '#2973d0'
      : '#9e9e9e',
  textTransform: 'unset',

  '& .u-l': {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
  },

  '@media (max-width: 899px)': {
    borderWidth: '1px',
  },

  '&:hover': {
    backgroundColor: props.status === 'selected' ? '#daffd7' : 'unset',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(props => ({
  height: '2rem',
  width: '100%',
  borderRadius: '2rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#f5f5f5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '2rem',
    transition: props.value == 100 ? 'none' : 'transform .2s linear',
    backgroundImage: props.value ? `linear-gradient(to right, #1fda72 ${100 - props.value}%, #39d5f3 100%)` : null,
  },
}));

export const ImgRow = styled(Box)({
  ...d_flex_center,
  lineHeight: '1.5',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

const Img = styled('img')({
  marginBottom: '3vh',
  borderStyle: 'none',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

export const ExampleIndexBox = styled(Box)<SelectionAutoTextProps>(props => ({
  ...d_flex_center,
  width: '1.8rem',
  height: '2rem',
  border: '2px solid #ddd',
  borderRadius: '0.5rem',
  fontSize: '14px',
  marginRight: '1rem',

  backgroundColor:
    props.status == 'selected'
      ? '#1cd05b'
      : props.status == 'wrong'
      ? '#ea3700'
      : props.status == 'right'
      ? '#1da2ff'
      : props.theme.palette.white.main,
  color: props.status !== '' ? 'white' : '#9e9e9e',

  '@media (max-width: 899px)': {
    borderWidth: '1px',
  },
}));

interface GrammarMCProps {
  content: GrammarContentType;
  sendResultsheet: (resultsheet: any) => void;
  isReviewLearning: boolean;
}

function GrammarMC({ content, sendResultsheet, isReviewLearning }: GrammarMCProps) {
  const { modal_alert } = useContext(ModalContext);
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const {
    mod,
    modules,
    current_step,
    current_page,
    show_modal,
    resultsheet,
    current_module_settings,
    use_image,
    learning_type,
    direction,
    grammar_contents,
    font_level,
    grammar_init,
  } = learningStateData;
  const settingStateData = useRecoilValue<SettingsType>(settingState);
  const [imgReady, setImgReady] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [progress, setProgress] = useState<number>(100);
  const [disableMove, setDisableMove] = useState<boolean>(false);
  const [animationDirection, setAnimationDirection] = useState('g-horizontal-right');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [wrongIndex, setWrongIndex] = useState<number | null>(null);
  const [visibleGrading, setVisibleGrading] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);
  const [keyboardStop, setKeyboardStop] = useState(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const { enable_keyboard, check_type } = settingStateData;
  const [splitExample, setSplitExample] = useState<string[]>([]);
  const [visibleOX, setVisibleOX] = useState<boolean>(false);
  const [learningStart, setLearningStart] = useState(false);
  const [next, setNext] = useState(false);
  const { rightanswer, rightanswer_num } = content;
  const { playEffectSound } = useContext(EffectSoundContext);
  const { setVoice, voicePlay, voiceStop } = useVoice();
  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const onSoundPlayRef = useRef<() => Promise<void>>();
  const onPlayEffectSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundStopRef = useRef<() => Promise<void>>();
  const isGoNextRef = useRef(false);
  const isReviewAdvanced = isReviewLearning && content.comment;
  const timeout = 7;

  //* ref
  const content_ref = useRef(content);
  content_ref.current = content;

  const font_level_ref = useRef(font_level);
  font_level_ref.current = font_level;

  const grammar_init_ref = useRef(grammar_init);
  grammar_init_ref.current = grammar_init;

  useEffect(() => {
    initLearningStart();
    setLearningStart(false);

    setTimeout(() => {
      setLearningStart(true);
    }, 300);
    return () => {
      unbindKeyboard();
    };
  }, []);

  const grammar_t_1 = useMemo(() => {
    return current_page === 1 && learning_type === 7;
  }, []);

  useEffect(() => {
    initLearningStart();
  }, [grammar_contents]);

  useEffect(() => {
    if (percent == 100 && grammar_init_ref.current) {
      let alert_type = '';
      if (learning_type == 7) {
        alert_type += 'grammar_t';
        if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        }
      } else if (learning_type == 8) {
        alert_type += 'grammar_b';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        } else if (current_page == 3) {
          alert_type += '_3';
        }
      } else if (learning_type == 9) {
        alert_type += 'grammar_d';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        }
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      setTimeout(() => {
        modal_alert.openModalAlert(alert_type, undefined, undefined, () => {
          setLearningStateData(prevState => ({
            ...prevState,
            grammar_init: false,
            show_modal: false,
          }));
        });
      }, 300);
    }
  }, [percent]);

  const initLearningStart = () => {
    if (isReviewLearning && current_step !== 0 && modules.length == 1) return false;
    setPercent(setLoadingStateData, 100);
    setKeyboardStop(false);
  };
  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (!show_modal) {
      if (e.keyCode == 49 || e.keyCode == 97) {
        setSelectedIndex(0);
      } else if (e.keyCode == 50 || e.keyCode == 98) {
        setSelectedIndex(1);
      } else if (e.keyCode == 51 || e.keyCode == 99) {
        setSelectedIndex(2);
      } else if (e.keyCode == 52 || e.keyCode == 100) {
        setSelectedIndex(3);
      } else if (e.keyCode == 13 && !keyboardStop) {
        goNext();
      }
    } else {
      if (e.keyCode == 13 && visibleOX) {
        if (isReviewAdvanced && !right) {
          if (!disableMove) {
            goNextProcess();
          }
        }
      }
    }
  };

  const imgCheck = () => {
    if (content_ref.current.sub_unit !== 't1') return;

    const img = new Image();

    img.onload = function () {
      setImgReady(true);
    };

    img.onerror = function () {
      setImgReady(false);
    };

    img.src = imgSrc;
  };

  const setQuestions = () => {
    //! 단어점검 문제 세팅
    if (content_ref.current.sub_unit !== 't1') return;
    const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    let new_img_src = '';
    if (koreanPattern.test(content.passage)) {
      //! rightanswer로 spell 세팅
      new_img_src = `${CDN}${IMAGE_DIR}${content.rightanswer}/0.jpg`;
    } else {
      //! passage로 spell 세팅
      new_img_src = `${CDN}${IMAGE_DIR}${content.passage}/0.jpg`;
    }

    setImgSrc(new_img_src);
    setTimeout(() => {
      setProgress(100);
      setNext(true);
    }, 300);
    setRightbtn();
  };

  const setRightbtn = () => {
    // console.log('hihi');
    if (content_ref.current.sub_unit !== 't1') return;
    //! 우측 화살표 2, 3 번 선택지 가운데 위치 시키기
    const dom_right_btn = document.getElementById('grammar_mc_right_icon_btn');
    const dom_selection_wrap = document.getElementById('grammar_mc_selection_wrap');

    if (dom_right_btn && dom_selection_wrap) {
      const selection_wrap_height = (dom_selection_wrap as HTMLDivElement).clientHeight;
      dom_right_btn.style.top = `${selection_wrap_height / 2}px`;
    }
  };

  useEffect(() => {
    imgCheck();
  }, [imgSrc]);

  useEffect(() => {
    if (enable_keyboard) bindKeyboard();
    return () => {
      if (enable_keyboard) unbindKeyboard();
    };
  }, [show_modal, selectedIndex, visibleGrading, keyboardStop, disableMove, splitExample, current_page]);

  useEffect(() => {
    setVisibleGrading(false);
    if (content.example) {
      let example = content.example;
      example = example.replace(/\@\@/g, '<br />').replace(/\{\{/g, '<span class="u-l">').replace(/\}\}/g, '</span>');
      setSplitExample(example?.split('//'));
      setResultZoom(font_level_ref.current);
    }
  }, [current_step, current_page, content.example]);

  useEffect(() => {
    setLearningStart(false);
    setSelectedIndex(null);
    setTimeout(() => {
      setLearningStart(true);
    }, 800);
  }, [current_page]);

  useEffect(() => {
    // console.log(learningStart, 'learningStart');
  }, [learningStart]);

  useEffect(() => {
    if (direction == 'right') {
      setAnimationDirection('g-horizontal-right');
    } else if (direction == 'left') {
      setAnimationDirection('g-horizontal-left');
    }
  }, [direction]);

  useEffect(() => {
    if (!next || show_modal) return;
    if (timeout > 0) {
      if (progress > 0) {
        const tick = setTimeout(() => {
          setProgress(progress - 1);
        }, timeout * 10);

        return () => clearTimeout(tick);
      } else {
        //! timer 끝나는 시점의 처리
        const tick = setTimeout(() => {
          // console.log('11111');
          if (content_ref.current.sub_unit == 't1') goNext();
          else clearTimeout(tick);
        }, 200 + timeout * 10);

        return () => clearTimeout(tick);
      }
    }
  }, [progress, next, show_modal]);

  const goNext = async () => {
    if (isGoNextRef.current) return;
    isGoNextRef.current = true;

    let right = false;

    if (selectedIndex !== null) {
      if (rightanswer_num !== undefined) {
        right = selectedIndex === rightanswer_num;
      } else {
        right = selectedIndex === splitExample.indexOf(rightanswer);
      }
    }

    setRight(right);

    if (check_type) {
      onSoundPlayRef.current = async () => {
        playEffectSound(right ? 'correct' : 'wrong');
        await new Promise(resolve => setTimeout(resolve, 1500));
        await voicePlay();
      };
      onPlayEffectSoundPlayRef.current = async () => {
        await playEffectSound(right ? 'correct' : 'wrong');
      };
      onAnswerSoundPlayRef.current = async () => {
        await voicePlay();
      };
      onAnswerSoundStopRef.current = async () => {
        voiceStop();
      };

      setVisibleOX(true);
      setNext(false);
      playEffectSound(right ? 'correct' : 'wrong');
      if (!isReviewAdvanced || (isReviewAdvanced && right)) {
        setDisableMove(true);
      } else {
        setDisableMove(true);
        setTimeout(() => {
          setDisableMove(false);
        }, 3000);
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));

      if (!right) {
        const rightAnswer = content.rightanswer
          .replace(/\@\@/g, '<br />')
          .replace(/\{\{/g, '<span class="u-l">')
          .replace(/\}\}/g, '</span>');
        setWrongIndex(content.rightanswer_num ? content.rightanswer_num : splitExample.indexOf(rightAnswer));

        if (!isReviewAdvanced) {
          settimeout1.current.push(
            setTimeout(() => {
              setWrongIndex(null);
            }, 3000),
          );
        }
      }
    }

    // ! 일반 학습이거나, 리뷰강의 심화학습이 아니거나, 리뷰강의 심화학습에서 정답을 맞췄을 경우에만 다음문제 넘김처리
    if (!isReviewLearning || !isReviewAdvanced || (isReviewAdvanced && right)) {
      setTimeout(
        () => {
          goNextProcess();
        },
        // ! grammar_t_1 (단어점검) 모듈에서만 2s
        process.env.NODE_ENV === 'development' ? 1000 : grammar_t_1 ? 2000 : 3000,
      );
    }
  };

  const goNextProcess = async () => {
    const rightAnswer = content.rightanswer
      .replace(/\@\@/g, '<br />')
      .replace(/\{\{/g, '<span class="u-l">')
      .replace(/\}\}/g, '</span>');
    onSoundPlayRef.current = undefined;
    onPlayEffectSoundPlayRef.current = undefined;
    onAnswerSoundPlayRef.current = undefined;
    onAnswerSoundStopRef.current = undefined;
    setKeyboardStop(true);
    setWrongIndex(null);
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
    resultsheet_tmp[current_step] = {
      no: current_step + 1,
      question: content.question,
      passage: content.passage,
      selectedIndex: selectedIndex != null ? selectedIndex : null,
      rightanswerIndex: splitExample.indexOf(rightAnswer),
      rightanswer: rightAnswer,
      choice: splitExample,
      right: selectedIndex === splitExample.indexOf(rightAnswer),
      type: 'MC',
    };

    if (current_step == grammar_contents.length - 1) {
      setVisibleOX(false);
      setDisableMove(false);
      setSelectedIndex(null);

      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      }, 0);

      setTimeout(() => {
        sendResultsheet(resultsheet_tmp);
      }, 1);
    } else {
      setDisableMove(false);
      setSelectedIndex(null);
      setTimeout(() => {
        setKeyboardStop(false);
      }, 1000);
      if (isReviewLearning && mod.length == 1) {
        setVisibleOX(false);
      } else {
        setTimeout(() => {
          setVisibleOX(false);
        }, 300);
      }
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
        current_step: current_step + 1,
        resultsheet: resultsheet_tmp,
      }));
      // setTimeout(() => {
      //   setTimeout(() => {
      //     setProgress(100);
      //   }, 300);
      // }, 0);
    }
    setNextDisabled(false);
    isGoNextRef.current = false;
  };

  useEffect(() => {
    console.log(selectedIndex, 'selectedIndex');
  }, [selectedIndex]);

  const onClickSelection = (idx: number) => {
    setSelectedIndex(idx);
  };

  useEffect(() => {
    if (content.sub_unit == 't1' && font_level) {
      //! autotext 적용되어 있는 dom 찾아서 font-size 찾고 수동으로 UPDATE 처리해주기 > 단어점검 모듈 전용 코드
      //* Question
      const question_dom = document.getElementById('word-check-question');
      if (question_dom) {
        const new_font_size = font_level * 5;
        question_dom.style.fontSize = `calc(${new_font_size}vh)`;
      }

      //* Selection Box
      for (let i = 0; i < 4; i++) {
        const selection_dom = document.getElementById(`word-check-selection-${i}`);
        if (selection_dom) {
          const new_font_size = font_level * 4;
          selection_dom.style.fontSize = `calc(${new_font_size}vh)`;
        }
      }
    }
  }, [font_level, content]);

  useEffect(() => {
    setResultZoom(font_level);
  }, [font_level]);

  useEffect(() => {
    setQuestions();
    if (content_ref.current.sub_unit == 't1') window.addEventListener('resize', setRightbtn);
    return () => {
      if (content_ref.current.sub_unit == 't1') window.removeEventListener('resize', setRightbtn);
    };
  }, [content]);

  const imgWidth = useMemo(() => {
    switch (font_level) {
      case 0.6:
        return 44;
      case 0.8:
        return 46.75;
      case 1:
        return 49.5;
      case 1.2:
        return 52.25;
      case 1.4:
        return 55;
    }
  }, [font_level]);

  const setResultZoom = (font_level: number) => {
    const els = document.getElementsByClassName('result-zoom');
    if (els && els.length > 0) {
      for (let i = 0; i < els.length; i++) {
        let txt_leng = els[i].getAttribute('data-textleng') ? Number(els[i].getAttribute('data-textleng')) : 5.3;
        txt_leng = +txt_leng * font_level;
        (els[i] as HTMLElement).style.width = `${txt_leng}vw`;
      }
    }
  };

  return (
    <StyledGrammarMCWrap
      sx={{
        padding: content.sub_unit === 't1' ? '0' : '0 6%',
        ' #ox-wrapper > div': {
          top: isReviewAdvanced
            ? '7vh !important'
            : content.sub_unit == 't1'
            ? 'calc((100vh - 4rem - 5rem - 4rem) * 0.5)'
            : '15vh !important',
        },
      }}
    >
      <Box
        sx={{
          display: learningStart ? 'none' : isReviewLearning && mod.length == 1 ? 'block' : 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: '#fff',
          zIndex: '1',
        }}
      ></Box>
      <OXMark
        show={visibleOX}
        right={right}
        grammar={true}
        grammar_popquiz={content.sub_unit == 't1' ? true : false}
      ></OXMark>
      {content.comment && visibleOX && isReviewLearning ? (
        <StyledCommentBox sx={{ position: 'absolute !important', top: '61vh' }}>{content.comment}</StyledCommentBox>
      ) : null}
      <StyledIconButton
        disabled={disableMove}
        className={`${isReviewAdvanced && visibleOX && !right ? 'blink-right-button' : ''}`}
        onClick={isReviewAdvanced && visibleOX && !right ? () => goNextProcess() : () => goNext()}
        sx={
          content.sub_unit !== 't1' ? { right: '16px', zIndex: 2, top: '50% !important' } : { right: '16px', zIndex: 2 }
        }
        id='grammar_mc_right_icon_btn'
        disableRipple
      >
        <AiOutlineRight />
      </StyledIconButton>
      {content.sub_unit === 't1' ? (
        <>
          <SwitchTransition mode='out-in'>
            <CSSTransition
              key={`${current_page}-${current_step}-${content.type}`}
              classNames={animationDirection}
              timeout={learningStart ? 300 : 0}
              onExit={node => {
                if (isReviewLearning && mod.length == 1) {
                  node.style.visibility = 'hidden';
                } else {
                  setTimeout(() => {
                    node.style.visibility = 'hidden';
                  }, 300);
                }
              }}
              onEnter={(node: any) => {
                if (isReviewLearning && mod.length == 1) {
                  node.style.visibility = 'visible';
                } else {
                  node.style.visibility = 'visible';
                }
              }}
            >
              <StyledPopquizBody>
                <StyledQuestionWrap>
                  {imgReady ? (
                    <StyledQuestionImageBox sx={{ width: '70%' }}>
                      <img
                        style={{ height: '30vh', width: 'auto', maxWidth: '90%', borderRadius: '1rem' }}
                        src={imgSrc}
                        alt={content.passage}
                      />
                      <Box sx={{ height: '3vh' }} />
                      <StyledQuestionAutoText
                        fontSizeUnit='vh'
                        maxFontSize={5}
                        minFontSize={4}
                        id='word-check-question'
                      >
                        {content.passage}
                      </StyledQuestionAutoText>
                    </StyledQuestionImageBox>
                  ) : (
                    <StyledQuestionAutoText
                      fontSizeUnit='vh'
                      sx={{ width: '70%' }}
                      maxFontSize={5}
                      minFontSize={4}
                      id='word-check-question'
                    >
                      {content.passage}
                    </StyledQuestionAutoText>
                  )}
                </StyledQuestionWrap>
                <StyledSelectionWrap id='grammar_mc_selection_wrap'>
                  {content.example?.split('//').map((choice, idx) => {
                    return (
                      <StyledSelectionBox
                        status={
                          wrongIndex == idx
                            ? 'wrong'
                            : selectedIndex == idx
                            ? visibleGrading
                              ? right
                                ? 'right'
                                : 'wrong'
                              : 'selected'
                            : ''
                        }
                        key={`popquiz_selection_${idx}`}
                        onClick={() => onClickSelection(idx)}
                      >
                        <StyledSelectionAutoText
                          minFontSize={3}
                          maxFontSize={4}
                          fontSizeUnit='vh'
                          status={
                            wrongIndex == idx
                              ? 'wrong'
                              : selectedIndex == idx
                              ? visibleGrading
                                ? right
                                  ? 'right'
                                  : 'wrong'
                                : 'selected'
                              : ''
                          }
                          id={`word-check-selection-${idx}`}
                        >
                          {choice}
                        </StyledSelectionAutoText>
                      </StyledSelectionBox>
                    );
                  })}
                </StyledSelectionWrap>
              </StyledPopquizBody>
            </CSSTransition>
          </SwitchTransition>
          <StyledPopquizBottom>
            {timeout > 0 ? <StyledLinearProgress key={current_step} variant='determinate' value={progress} /> : null}
          </StyledPopquizBottom>
        </>
      ) : (
        <>
          <SwitchTransition mode='out-in'>
            <CSSTransition
              key={`${current_page}-${current_step}`}
              classNames={animationDirection}
              timeout={learningStart ? 300 : isReviewLearning && mod.length == 1 ? (grammar_init ? 0 : 300) : 0}
              onExit={node => {
                if (isReviewLearning && mod.length == 1) {
                  node.style.visibility = 'hidden';
                } else {
                  setTimeout(() => {
                    node.style.visibility = 'hidden';
                  }, 300);
                }
              }}
              onEnter={(node: any) => {
                if (isReviewLearning && mod.length == 1) {
                  node.style.visibility = 'visible';
                } else {
                  node.style.visibility = 'visible';
                }
              }}
            >
              <TabStyleMCWrap className='grammar-custom-scrollbar'>
                <TabStyleQuestionWrap
                  sx={{
                    fontSize: `${FONT_STD.HEAD * font_level}vw`,
                    marginBottom: isReviewAdvanced && !content.passage ? '10vh' : '3vh',
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: content.question ? content.question : '' }}></span>
                </TabStyleQuestionWrap>
                {content.img ? (
                  <ImgRow>
                    <Img sx={{ width: `${imgWidth}vw` }} src={`${NG_CDN}/lecture_img/${content.img}`}></Img>
                  </ImgRow>
                ) : null}
                {content.passage ? (
                  <TabStylePassageWrap
                    sx={{ fontSize: `${FONT_STD.MAIN * font_level}vw`, minHeight: isReviewAdvanced ? '10vh' : '22vh' }}
                  >
                    <span
                      style={{ textAlign: 'center' }}
                      dangerouslySetInnerHTML={{ __html: content.passage as string }}
                    ></span>
                  </TabStylePassageWrap>
                ) : null}
                <Box
                  sx={{
                    height: '38vh',
                    fontSize: `${FONT_STD.MAIN * font_level}vw`,
                    paddingX: '8%',
                    marginTop: isReviewAdvanced ? '7vh' : '',
                  }}
                >
                  <TabStyleExampleWrap>
                    {splitExample?.map((item, index) => {
                      return (
                        <TabStyleExample
                          sx={{
                            maxHeight: splitExample.length === 4 ? '40%' : isReviewAdvanced ? 'auto' : '60%',
                            flex: splitExample.length === 4 || isReviewAdvanced ? '' : '1',
                            maxWidth: isReviewAdvanced ? '100%' : '50%',
                            height: rightanswer.includes('@@') ? '35%' : isReviewAdvanced ? 'auto' : '100%',
                            minHeight: isReviewAdvanced ? 'calc(8vh + 2px)' : '0px',
                            padding: isReviewAdvanced ? '1.5vh' : '1rem',
                          }}
                          key={`example-${index}`}
                          // onClick={() => onClickSelection(index)}
                        >
                          {item === 'O' ? (
                            <TabStyleChoiceButton
                              onClick={() => onClickSelection(index)}
                              disableRipple={true}
                              status={
                                wrongIndex == index
                                  ? 'wrong'
                                  : selectedIndex == index
                                  ? visibleGrading
                                    ? right
                                      ? 'right'
                                      : 'wrong'
                                    : 'selected'
                                  : ''
                              }
                            >
                              <img src={selectedIndex == index ? O_ICON_active : O_ICON} style={{ maxWidth: '15%' }} />
                            </TabStyleChoiceButton>
                          ) : item === 'X' ? (
                            <TabStyleChoiceButton
                              onClick={() => onClickSelection(index)}
                              disableRipple={true}
                              status={
                                wrongIndex == index
                                  ? 'wrong'
                                  : selectedIndex == index
                                  ? visibleGrading
                                    ? right
                                      ? 'right'
                                      : 'wrong'
                                    : 'selected'
                                  : ''
                              }
                            >
                              <img src={selectedIndex == index ? X_ICON_active : X_ICON} style={{ maxWidth: '15%' }} />
                            </TabStyleChoiceButton>
                          ) : (
                            <TabStyleChoiceButton
                              onClick={() => onClickSelection(index)}
                              disableRipple={true}
                              status={
                                wrongIndex == index
                                  ? 'wrong'
                                  : selectedIndex == index
                                  ? visibleGrading
                                    ? right
                                      ? 'right'
                                      : 'wrong'
                                    : 'selected'
                                  : ''
                              }
                              className={`${isReviewAdvanced ? 'review' : ''}`}
                              sx={isReviewAdvanced ? { minHeight: 'inherit', lineHeight: '1.8' } : undefined}
                            >
                              {isReviewAdvanced ? (
                                <ExampleIndexBox
                                  status={
                                    wrongIndex == index
                                      ? 'wrong'
                                      : selectedIndex == index
                                      ? visibleGrading
                                        ? right
                                          ? 'right'
                                          : 'wrong'
                                        : 'selected'
                                      : ''
                                  }
                                >
                                  <b>{index + 1}</b>
                                </ExampleIndexBox>
                              ) : null}
                              <div dangerouslySetInnerHTML={{ __html: item }}></div>
                            </TabStyleChoiceButton>
                          )}
                        </TabStyleExample>
                      );
                    })}
                  </TabStyleExampleWrap>
                </Box>
              </TabStyleMCWrap>
            </CSSTransition>
          </SwitchTransition>
        </>
      )}
    </StyledGrammarMCWrap>
  );
}

export default GrammarMC;
