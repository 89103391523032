import { useState, useContext, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { moduleSettingState } from '../../recoil/model/module_setting';
import { learningState } from '../../recoil/model/learning';
import { deviceState } from 'recoil/common/device';
import ModalDefault from './ModalDefault';
import Button from '../../components/button/Button';
import { styled, Box, Checkbox, IconButton, ButtonProps } from '@mui/material';
import { d_flex_center, d_flex, dir_column, d_flex_space_around } from '../../styles/common';
import { fetchPostApi } from '../../utils/api';
import { ModalContext } from '../../provider/ModalProvider';
import { setModuleCategory, setModuleSetting, options } from '../../../src/utils/setting_contents';
import { CgClose } from 'react-icons/cg';
import { FiMinus, FiPlus, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BoxProps } from '@mui/system';

import { MODULES } from '../../utils/constants';

import bgImage0 from '../../assets/images/bg/0.png';
import bgImage1 from '../../assets/images/bg/1.png';
import { userState } from '../../recoil/model/user';

const StyledModalSettingsContainer = styled(Box)(props => ({
  width: '60vw',
  height: '65vh',
  fontSize: '1.2rem',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '5px',
}));

const StyledModalSettingsWrapper = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  padding: '4rem 2rem 2rem 2rem',
}));

const StyledModalSettingsBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
}));

const StyledCategoryBar = styled(Box)(props => ({
  width: '100%',
  height: '3rem',
  border: `1px solid ${props.theme.palette.gray_3.main}`,
  userSelect: 'none',
  ...d_flex,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

interface StyledCategoryItemProps {
  is_active: string;
}

const StyledCategoryItem = styled(Box)<StyledCategoryItemProps>(props => {
  const active_style =
    props.is_active === 'true'
      ? { background: 'linear-gradient(#652ae9, #4620e9)', color: props.theme.palette.white.main }
      : { color: props.theme.palette.gray.main, borderRight: `1px solid ${props.theme.palette.gray_3.main}` };

  return {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    ...d_flex_center,
    ...active_style,
    '@media(max-width: 1023px)': {
      borderColor: '#f5f5f5',
    },
  };
});

const StyledContentWrapper = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 3rem)',
  border: `2px solid ${props.theme.palette.gray_3.main}`,
  borderTop: '0',
  userSelect: 'none',
  overflowY: 'auto',
  ...d_flex,
  ...dir_column,
  '@media(max-width: 1023px)': {
    borderWidth: '1px',
  },
}));

const StyledContentBox = styled(Box)(props => ({
  flex: '0 0 auto',
  '& > div': {
    borderBottom: `1px solid ${props.theme.palette.gray_3.main}`,
  },
  '&:last-of-type > div': {
    borderBottom: 'none',
  },
  ...d_flex_center,
  ...dir_column,
  '@media(max-width: 1023px)': {
    '& > div': {
      borderColor: '#f5f5f5',
    },
  },
}));

const StyledContent = styled(Box)(props => ({
  width: '96%',
  height: '100%',
  ...d_flex_space_around,
}));

const CloseIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '2rem',
  transform: 'translate(50%, -50%)',
  right: '3rem',
  svg: {
    color: props.theme.palette.purple.main,
    width: '1.5rem',
    height: '1.5rem',
    strokeWidth: '1.5px',
  },
}));

const ButtonTypeLeftBox = styled(Box)(props => ({
  width: '75%',
  paddingLeft: '2rem',
}));

const ButtonTypeRightBox = styled(Box)(props => ({
  width: '25%',
  textAlign: 'center',
  paddingRight: '2rem',
  ...d_flex_space_around,
}));

const ButtonTypeTitleBox = styled(Box)(props => ({
  color: props.theme.palette.black.main,
  lineHeight: '2',
}));

const ButtonTypeSubTitleBox = styled(Box)(props => ({
  color: props.theme.palette.gray.main,
  whiteSpace: 'pre-wrap',
}));

const ButtonOptTitleBox = styled(Box)(props => ({
  whiteSpace: 'nowrap',
  fontSize: '1.5rem',
  fontWeight: '700',
  color: props.theme.palette.purple.main,
}));

const ButtonOptSubTitleBox = styled(Box)(props => ({
  color: props.theme.palette.purple.main,
}));

const StyledIconButton = styled(IconButton)(props => ({
  width: '2rem',
  height: '2rem',
  padding: '0',
  backgroundColor: '#cccdff',
  svg: {
    color: props.theme.palette.white.main,
    width: '2rem',
    height: '2rem',
    strokeWidth: '3px',
  },
  '&:hover': { backgroundColor: '#cccdff' },
}));

interface CheckboxBoxProps extends BoxProps {
  checked?: boolean;
}

const CheckboxCheckboxBox = styled(Box)(props => ({
  width: '15%',
  textAlign: 'center',
}));

const CheckboxTitleBox = styled(Box)<CheckboxBoxProps>(props => ({
  fontWeight: '700',
  width: '15%',
  textAlign: 'start',
  color: props.checked ? props.theme.palette.black.main : props.theme.palette.gray_2.main,
}));

const CheckboxSubTitleBox = styled(Box)<CheckboxBoxProps>(props => ({
  width: '70%',
  paddingLeft: '5%',
  color: props.checked ? props.theme.palette.gray.main : props.theme.palette.gray_2.main,
  'span.c_purple_2': {
    color: props.checked ? props.theme.palette.purple.main : props.theme.palette.gray_2.main,
  },
}));

const StyledCheckbox = styled(Checkbox)(props => ({
  '&.MuiCheckbox-root': {
    color: props.checked ? '' : props.theme.palette.gray.main,
    padding: '7px',
  },
}));

interface BackgroundButton extends ButtonProps {
  isActive: boolean | undefined;
  imgUrl: string;
}

const StyledBackgroundButton = styled(Button)<BackgroundButton>(props => ({
  display: 'block',
  width: '100%',
  // height: '11.25vw',
  aspectRatio: '16 / 9',
  border: props.isActive
    ? `2px solid ${props.theme.palette.yellow_1.main}`
    : `2px solid ${props.theme.palette.white.main}`,
  backgroundImage: `url(${props.imgUrl})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  transform: 'scale(0.9)',
  '&:hover': { backgroundColor: 'inherit' },
}));

interface ModalSettingsProps {
  visible: boolean;
  onClose: () => void;
}

function ModalSettings(props: ModalSettingsProps) {
  const visible = props.visible;
  const onClose = props.onClose;

  const { modal_alert } = useContext(ModalContext);

  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { platform, is_mobile } = deviceStateData;

  const [moduleSettingStateData, setModuleSettingStateData] = useRecoilState<ModuleSettings>(moduleSettingState);
  const [moduleSettings, setModuleSettings] = useState({ ...moduleSettingStateData });
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { learning_type, modules, current_page, tag } = learningStateData;
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [module, setModule] = useState<string>(learning_type != null ? MODULES[learning_type] : '');

  const changeModuleSettings = (module: string, key: string, value: number) => {
    setModuleSettings(current => {
      const newSettings = JSON.parse(JSON.stringify(current));
      newSettings[module][key] = value;
      return newSettings;
    });
  };

  const [categoryArr, setCategoryArr] = useState<{ id: string; label: string }[]>(
    setModuleCategory(learning_type, modules, tag),
  );

  const [categoryDatas, setCategoryDatas] = useState<ModalSettingsDatas[][]>(
    setModuleSetting(learning_type, modules, moduleSettings, is_mobile ? platform : undefined, tag),
  );

  const [curCategory, setCurCategory] = useState(0);

  useEffect(() => {
    setModuleSettings({ ...moduleSettingStateData });
    if (learning_type == 3 || learning_type == 4) {
      setCurCategory(current_page);
    }
    if (!learning_type) {
      setCategoryDatas(setModuleSetting(learning_type, modules, moduleSettings));
    }
  }, [visible]);

  const onClickCategoryItem = async (idx: number) => {
    setCurCategory(idx);
  };

  const bgArr = [bgImage0, bgImage1];

  const onClickClose = () => {
    // 설정 변경사항 확인

    let is_change = true;
    if (learning_type == 1) {
      is_change =
        JSON.stringify(moduleSettingStateData.memorize_hot_stage) ===
          JSON.stringify(moduleSettings.memorize_hot_stage) &&
        JSON.stringify(moduleSettingStateData.memorize_book.order) ===
          JSON.stringify(moduleSettings.memorize_book.order);
    } else if (learning_type == 2) {
      if (tag && tag == 'new') {
        // tag가 new일 때 처리
        is_change =
          JSON.stringify(moduleSettingStateData.memorize_new_stage) ===
            JSON.stringify(moduleSettings.memorize_new_stage) &&
          JSON.stringify(moduleSettingStateData.memorize_book.order) ===
            JSON.stringify(moduleSettings.memorize_book.order);
      } else {
        // tag가 normal일 떄 처리
        is_change =
          JSON.stringify(moduleSettingStateData.memorize_normal_stage) ===
            JSON.stringify(moduleSettings.memorize_normal_stage) &&
          JSON.stringify(moduleSettingStateData.memorize_book.order) ===
            JSON.stringify(moduleSettings.memorize_book.order);
      }
    } else {
      is_change = JSON.stringify(moduleSettingStateData) === JSON.stringify(moduleSettings);
    }
    // 변경사항 있을때만 모달 띄우고 스토어 세팅
    if (!is_change) {
      modal_alert.openModalAlert('change_settings', undefined, undefined, () =>
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        })),
      );
      setModuleSettingStateData(() => ({ ...moduleSettings }));
      const settings: { [key: string]: { [key: string]: number } } = {};
      if (learning_type == 1) {
        settings.memorize_hot_stage = moduleSettings.memorize_hot_stage;
        settings.memorize_book = moduleSettings.memorize_book;
        settings.memorize_voice = moduleSettings.memorize_voice;
      } else if (learning_type == 2) {
        if (tag && tag == 'new') {
          settings.memorize_new_stage = moduleSettings.memorize_new_stage;
        } else {
          settings.memorize_normal_stage = moduleSettings.memorize_normal_stage;
        }
        settings.memorize_book = moduleSettings.memorize_book;
        settings.memorize_voice = moduleSettings.memorize_voice;
      } else if (learning_type == 3) {
        settings.flash = moduleSettings.flash;
        settings.bingo = moduleSettings.bingo;
        settings.popquiz = moduleSettings.popquiz;
      } else if (learning_type == 4) {
        settings.dictation = moduleSettings.dictation;
        settings.writing = moduleSettings.writing;
        settings.speaking = moduleSettings.speaking;
      }
      const postdata = {
        type: learningStateData.book_type,
        scheduleId: learningStateData.schedule_id,
        settings,
      };
      fetchPostApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/settings?ver=2`,
        postdata,
      );
    } else if (learning_type == 1 || learning_type == 2) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
      is_change =
        JSON.stringify(moduleSettingStateData.memorize_voice) === JSON.stringify(moduleSettings.memorize_voice) &&
        JSON.stringify(moduleSettingStateData.memorize_book.auto) === JSON.stringify(moduleSettings.memorize_book.auto);
      if (!is_change) {
        setModuleSettingStateData(() => ({ ...moduleSettings }));
        const settings: { [key: string]: { [key: string]: number } } = {};
        if (learning_type == 1) {
          settings.memorize_hot_stage = moduleSettings.memorize_hot_stage;
          settings.memorize_book = moduleSettings.memorize_book;
          settings.memorize_voice = moduleSettings.memorize_voice;
        } else if (learning_type == 2) {
          if (tag && tag == 'new') {
            // tag가 new일 때, 처리
            settings.memorize_new_stage = moduleSettings.memorize_new_stage;
          } else {
            // tag가 normal일 때, 처리
            settings.memorize_normal_stage = moduleSettings.memorize_normal_stage;
          }
          settings.memorize_book = moduleSettings.memorize_book;
          settings.memorize_voice = moduleSettings.memorize_voice;
        }
        const postdata = {
          type: learningStateData.book_type,
          scheduleId: learningStateData.schedule_id,
          settings,
        };
        fetchPostApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/settings?ver=2`,
          postdata,
        );
      }
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
    }
    onClose();
    setCurCategory(0);
  };

  const buttonClick = (id: string, value: string) => {
    let index = moduleSettings[`${categoryArr[curCategory].id}`][`${id}`];

    if (value === 'increese') {
      if (index < options[categoryArr[curCategory].id][id].length - 1) {
        index += 1;
      } else {
        index = 0;
      }
    } else if (value === 'decreese') {
      if (index === 0) {
        index = options[categoryArr[curCategory].id][id].length - 1;
      } else {
        index -= 1;
      }
    }

    changeModuleSettings(`${categoryArr[curCategory].id}`, `${id}`, index);
  };

  const onChangeCheckbox = (checked: boolean | undefined, id: string, type: string) => {
    if (id === 'spell') return;

    if (type === 'checkbox') {
      if (learning_type == 1 && id == 'example') return false;
      if (learning_type == 2 && id == 'example' && tag && tag == 'new') return false;
      if (learning_type == 2 && id == 'spell' && tag && tag == 'normal') return false;
      let index;
      if (checked === true) index = 0;
      else index = 1;
      changeModuleSettings(`${categoryArr[curCategory].id}`, `${id}`, index);
    } else if (type === 'radio') {
      if (checked === true) return false;
      else {
        for (let i = 0; i < categoryDatas[curCategory].length; i++) {
          if (categoryDatas[curCategory][i].id === id) {
            changeModuleSettings(`${categoryArr[curCategory].id}`, `${categoryDatas[curCategory][i].id}`, 1);
          } else {
            changeModuleSettings(`${categoryArr[curCategory].id}`, `${categoryDatas[curCategory][i].id}`, 0);
          }
        }
      }
    }
  };

  const onClickBgButton = (index: number) => {
    changeModuleSettings('acidrain_background', 'src', index);
  };

  return (
    <ModalDefault
      visible={visible}
      onClose={() => {
        return;
      }}
    >
      <StyledModalSettingsContainer>
        <CloseIconButton onClick={onClickClose} disableRipple>
          <CgClose />
        </CloseIconButton>
        <StyledModalSettingsWrapper>
          <StyledModalSettingsBox>
            <StyledCategoryBar>
              {categoryArr?.map((item, idx) => (
                <StyledCategoryItem
                  key={`settings_category_${item.id}`}
                  is_active={`${idx === curCategory ? 'true' : 'false'}`}
                  onClick={() => onClickCategoryItem(idx)}
                >
                  {item.label}
                </StyledCategoryItem>
              ))}
            </StyledCategoryBar>
            <StyledContentWrapper>
              {categoryDatas && categoryDatas[curCategory] && categoryDatas[curCategory].length > 0
                ? categoryDatas[curCategory].map((item, idx) => (
                    <StyledContentBox
                      key={`category_items_${idx}`}
                      sx={{ height: item.height, minHeight: item.id === 'manual' ? '50%' : '' }}
                    >
                      {['checkbox', 'radio'].includes(item.type) ? (
                        <>
                          {categoryArr[curCategory].id === 'background' ? (
                            <>
                              <StyledContent
                                sx={{
                                  height: item.id === 'manual' ? '50%' : '100%',
                                  margin: item.id === 'manual' ? '5rem 0 2.5rem 0' : '',
                                }}
                              >
                                <CheckboxCheckboxBox>
                                  <StyledCheckbox
                                    disableRipple
                                    checked={item.checked}
                                    onChange={() => onChangeCheckbox(item.checked, item.id, item.type)}
                                    style={{ pointerEvents: 'auto' }}
                                    color={item.checked ? 'blue' : 'gray'}
                                  />
                                </CheckboxCheckboxBox>
                                <Box sx={{ width: '90%', paddingLeft: '2.5%', ...d_flex, ...dir_column }}>
                                  <CheckboxTitleBox
                                    sx={{ textAlign: 'left' }}
                                    component={'span'}
                                    checked={item.checked}
                                  >
                                    {item.title}
                                  </CheckboxTitleBox>
                                  <CheckboxSubTitleBox
                                    sx={{ paddingLeft: '0' }}
                                    component={'span'}
                                    checked={item.checked}
                                  >
                                    {item.sub_title}
                                  </CheckboxSubTitleBox>
                                </Box>
                              </StyledContent>
                              {item.id === 'manual' ? (
                                <Box
                                  sx={{
                                    height: '50%',
                                    width: '96%',
                                    paddingLeft: '8%',
                                    ...d_flex_center,
                                    margin: item.id === 'manual' ? '0 0 2.5rem 0' : '',
                                  }}
                                >
                                  {bgArr.map((bg, idx) => (
                                    <StyledBackgroundButton
                                      key={`bgbtn_${idx}`}
                                      isActive={item.checked && moduleSettings['acidrain_background']['src'] === idx}
                                      onClick={() => onClickBgButton(idx)}
                                      disabled={!item.checked}
                                      disableRipple
                                      imgUrl={bg}
                                    />
                                  ))}
                                </Box>
                              ) : null}
                            </>
                          ) : (
                            <StyledContent>
                              <CheckboxCheckboxBox>
                                <StyledCheckbox
                                  disableRipple
                                  checked={item.checked}
                                  onChange={() => onChangeCheckbox(item.checked, item.id, item.type)}
                                  style={{ pointerEvents: 'auto' }}
                                  color={item.checked ? 'blue' : 'gray'}
                                />
                              </CheckboxCheckboxBox>
                              <CheckboxTitleBox
                                checked={item.checked}
                                dangerouslySetInnerHTML={{ __html: item.title }}
                              />
                              <CheckboxSubTitleBox
                                checked={item.checked}
                                dangerouslySetInnerHTML={{ __html: item.sub_title }}
                              ></CheckboxSubTitleBox>
                            </StyledContent>
                          )}
                        </>
                      ) : (
                        <StyledContent>
                          <ButtonTypeLeftBox>
                            <ButtonTypeTitleBox>{item.title}</ButtonTypeTitleBox>
                            <ButtonTypeSubTitleBox>{item.sub_title}</ButtonTypeSubTitleBox>
                          </ButtonTypeLeftBox>
                          <ButtonTypeRightBox>
                            <Box sx={{ width: '33%' }}>
                              <StyledIconButton disableRipple onClick={() => buttonClick(item.id, 'decreese')}>
                                {item.type === 'plusminus' ? <FiMinus /> : <FiChevronDown />}
                              </StyledIconButton>
                            </Box>
                            <Box sx={{ width: '34%' }}>
                              <ButtonOptTitleBox>
                                {item.options[moduleSettings[`${categoryArr[curCategory].id}`][`${item.id}`]].title}
                              </ButtonOptTitleBox>
                              {item.options[moduleSettings[`${categoryArr[curCategory].id}`][`${item.id}`]]
                                .sub_title ? (
                                <ButtonOptSubTitleBox>
                                  {
                                    item.options[moduleSettings[`${categoryArr[curCategory].id}`][`${item.id}`]]
                                      .sub_title
                                  }
                                </ButtonOptSubTitleBox>
                              ) : null}
                            </Box>
                            <Box sx={{ width: '33%' }}>
                              <StyledIconButton disableRipple onClick={() => buttonClick(item.id, 'increese')}>
                                {item.type === 'plusminus' ? <FiPlus /> : <FiChevronUp />}
                              </StyledIconButton>
                            </Box>
                          </ButtonTypeRightBox>
                        </StyledContent>
                      )}
                    </StyledContentBox>
                  ))
                : null}
            </StyledContentWrapper>
          </StyledModalSettingsBox>
        </StyledModalSettingsWrapper>
      </StyledModalSettingsContainer>
    </ModalDefault>
  );
}

export default ModalSettings;
