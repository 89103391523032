import { Routes, Route, useLocation } from 'react-router';
import { Suspense, lazy, useState, useEffect } from 'react';
import { styled, Box } from '@mui/material';

const Learningschedule = lazy(() => import('./Learningschedule'));
const Learningbook = lazy(() => import('./Learningbook'));

function ScheduleIndex() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/' element={<Learningschedule />} />
        <Route path='/learningschedule' element={<Learningschedule />} />
        <Route path='/learningbook' element={<Learningbook />} />
      </Routes>
    </Suspense>
  );
}

export default ScheduleIndex;
