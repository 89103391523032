import { atom, SetterOrUpdater } from 'recoil';

export const loadingState = atom<LoadingType>({
  key: 'loadingState',
  default: {
    percent: 0, // 로딩 퍼센트
    message: '잠시만 기다려주세요.',
  },
});

export const setPercent = (setLoadingStateData: SetterOrUpdater<LoadingType>, percent: number, message = '') => {
  setLoadingStateData(prevState => ({
    ...prevState,
    percent: percent,
    message: message ? message : prevState.message,
  }));
};

export const fillMax = (setLoadingStateData: SetterOrUpdater<LoadingType>, message = '') => {
  let percent = 1;
  let interval: number[] = [];
  setLoadingStateData(prevState => ({
    ...prevState,
    percent: percent,
    message: message ? message : prevState.message,
  }));
  const setInter = function (payload: number | undefined) {
    if (payload === undefined) interval = [];
    else interval.push(payload);
  };
  const inter = window.setInterval(() => {
    percent += 3;
    setLoadingStateData(prevState => ({
      ...prevState,
      percent: percent,
    }));

    if (percent >= 100) {
      if (interval.length > 0) {
        for (let i = 0; i < interval.length; i++) {
          clearInterval(interval[i]);
        }
      }

      interval = [];

      setTimeout(() => {
        setInter(undefined);
      }, 300);
    }
  }, 10);

  setInter(inter);
};
