import React, { useRef, useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/system';
import { useRecoilValue } from 'recoil';
import { deviceState } from '../../recoil/common/device';

export interface AutoTextProps extends BoxProps {
  maxLines?: number;
  minFontSize?: number;
  maxFontSize?: number;
  lineHeight?: number;
  fontSizeUnit?: string;
  overflowY?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset'
    | '-moz-hidden-unscrollable'
    | 'auto'
    | 'clip'
    | 'hidden'
    | 'scroll'
    | 'visible';
  fixed?: boolean;
  onFontSizeChange?: (size: number) => void;
  id?: string;
}

const AutoText: React.FC<AutoTextProps> = ({
  maxLines = 2,
  minFontSize = 1.2,
  maxFontSize = 1.4,
  lineHeight = 1.2,
  fontSizeUnit = 'rem',
  overflowY = 'clip',
  fixed = false,
  onFontSizeChange,
  children,
  sx,
  ...props
}) => {
  const deviceStateData = useRecoilValue(deviceState);
  const { screen_width, screen_height } = deviceStateData;
  const autoTextRef = useRef<HTMLDivElement | null>(null);
  const [key, setKey] = useState<number>(0);
  const [visible, setVisible] = useState(false);
  const [autoFontSize, setAutoFontSize] = useState(`${maxFontSize}${fontSizeUnit}`);
  const fontSizeRef = useRef(maxFontSize);
  const [autoWebkitLineClamp, setAutoWebkitLineClamp] = useState<string | number>('unset');
  const [autoMaxHeight, setAutoMaxHeight] = useState<string | number>('unset');
  const [beforeChildren, setBeforeChildren] = useState<React.ReactNode>();

  useEffect(() => {
    const node = autoTextRef.current;
    if (!node || beforeChildren == children || (fixed && visible)) return;
    setVisible(false);
    setBeforeChildren(children);

    // Reset style
    node.style.fontSize = `${maxFontSize}${fontSizeUnit}`;
    node.style.webkitLineClamp = 'unset';
    node.style.maxHeight = 'unset';
    node.style.paddingBottom = 'unset';

    requestAnimationFrame(adjustFontSize);
  }, [children, screen_width, screen_height]);

  const adjustFontSize = () => {
    const node = autoTextRef.current;
    if (!node) return;

    let fontSize = fontSizeRef.current;
    const fontSizeInPx = parseFloat(getComputedStyle(node).fontSize);
    const lineHeightInPx = lineHeight * fontSizeInPx;
    const maxHeight = Math.round((lineHeightInPx + 0.01) * 100) / 100;

    if (fontSize < minFontSize) {
      fontSize = minFontSize;

      setAutoFontSize(`${fontSize}${fontSizeUnit}`);
      setAutoWebkitLineClamp(maxLines);
      setAutoMaxHeight(`${lineHeight * fontSize * maxLines}${fontSizeUnit}`);
      setKey(key + 1);
      onFontSizeChange?.(fontSize);
      setVisible(true);
    } else if (Math.round(node.getBoundingClientRect().height * 100) / 100 > maxHeight && fontSize >= minFontSize) {
      fontSizeRef.current = Math.round(fontSizeRef.current * 100 - 5) / 100;
      node.style.fontSize = `${fontSizeRef.current}${fontSizeUnit}`;

      requestAnimationFrame(adjustFontSize);
    } else {
      setAutoFontSize(`${fontSize}${fontSizeUnit}`);
      setAutoWebkitLineClamp(maxLines);
      setAutoMaxHeight(`${lineHeight * fontSize * maxLines}${fontSizeUnit}`);
      setKey(key + 1);
      onFontSizeChange?.(fontSize);
      setVisible(true);
    }
  };

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: children?.toString() ? children.toString() : '' }}
      ref={autoTextRef}
      key={key}
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        textAlign: 'center',
        whiteSpace: 'break-spaces',
        lineBreak: 'anywhere',
        fontSize: autoFontSize,
        lineHeight: `${lineHeight}`,
        WebkitLineClamp: autoWebkitLineClamp,
        maxHeight: overflowY == 'visible' ? 'unset' : autoMaxHeight,
        overflowY: overflowY,
        opacity: visible ? '1' : '0',
        '& > *': {
          lineHeight: 1,
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default AutoText;
