import { atom } from 'recoil';
import { default_module_settings } from '../../utils/user_tools';

export const learningState = atom<LearningType>({
  key: 'learningState',
  default: {
    status: false,
    learning_type: null,
    current_page: 0,
    current_step: 0,
    mod: [],
    modules: '',
    modal_disable: false,
    record_id: 0,
    module_record_id: 0,
    schedule_id: null,
    schedule_name: '',
    book_type: '',
    tab_name: '',
    unit_id: 0,
    unit_name: '',
    contents: [],
    studytime: 0,
    starttime: '',
    resultsheet: [],
    show_modal: false,
    current_module_settings: { ...default_module_settings },
    use_image: 1,
    study_method: 0,
    first_learning: false,
    relearning: false,
    reset_learning: false,
    save_enabled: false,
    direction: '',
    first_alert: false,
    tag: null,
    category_id: 0,
    folder_id: undefined,
    grammar_unit_seq: undefined,
    grammar_learning_type: undefined,
    grammar_contents: [],
    grammar_tab_unit_id: undefined,
    grammar_origin_unit_name: '',
    font_level: 1,
    grammar_init: false,
  },
});
