import { Box, styled } from '@mui/material';

// 암기학습 1일 때, 맨아래 content
export const SelectionBox = styled(Box)({
  height: '7.8vh',
  width: '33vw',
  backgroundColor: '#f7f7f7',
  color: '#6c6c6c',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '4vh',
  borderRadius: '1rem',
  '&.active': {
    backgroundColor: '#caf9ff',
    color: '#2973d0',
  },
});

export const ContentWrap = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

// 암기학습 2일 때, 맨아래 content
export const M2ContentWrap = styled(ContentWrap)({
  transform: 'translateX(0.5rem)',
  img: {
    '&.key01': {
      width: '24rem',
    },
    '&.key02': {
      width: '14rem',
    },
  },
  svg: {
    color: '#fff',
    width: '2rem',
    height: '2.75rem',
    margin: '0 1.5rem',
  },
});

// 암기학습 3일 때, 맨아래 content
export const M3ContentWrap = styled(ContentWrap)({
  img: {
    width: '22.5rem',
  },
});

// 암기학습 4일 때, 맨아래 content
export const M4ContentWrap = styled(ContentWrap)({
  transform: 'translateX(0.75rem)',
  img: {
    '&.key04': {
      width: '16.5rem',
    },
    '&.key05': {
      width: '16.5rem',
      marginLeft: '-2rem',
    },
  },
  svg: {
    color: '#fff',
    width: '5.5rem',
    height: '5rem',
    transform: 'translateX(0.25rem)',
  },
  '@media (max-height: 412px)': {
    img: {
      '&.key04': {
        width: '12.5rem',
      },
      '&.key05': {
        width: '14rem',
        marginLeft: '-2rem',
      },
    },
  },
});

// 기본학습 1일 때, 맨아래 content
export const B1ContentWrap = styled(ContentWrap)({
  transform: 'translateX(1.25rem)',
  img: {
    '&.key04': {
      width: '16.5rem',
    },
    '&.key03': {
      width: '22.5rem',
      marginLeft: '-1rem',
    },
  },
  svg: {
    color: '#fff',
    width: '5.5rem',
    height: '5rem',
    transform: 'translateX(0.25rem)',
  },
  '@media (max-height: 412px)': {
    img: {
      '&.key04': {
        width: '12.5rem',
      },
      '&.key03': {
        width: '18.5rem',
        marginLeft: '-1rem',
      },
    },
  },
});

// 기본학습 2일 때, 맨아래 content
export const B2ContentWrap = styled(ContentWrap)({
  transform: 'translateY(-0.35rem)',
  gap: '1rem',
  '& .menu': {
    backgroundColor: '#fff4e1',
    color: '#5f5f5f',
    width: '15rem',
    height: '6.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2.5rem',
    fontSize: '2rem',
    letterSpacing: '-2px',
    '&.active': {
      backgroundColor: '#ffd70f',
      color: '#fff',
    },
  },
  '@media (max-height: 412px)': {
    '& .menu': {
      width: '12.5rem',
      height: '5rem',
    },
  },
});

// 기본학습 3일 때, 맨아래 content
export const B3ContentWrap = styled(ContentWrap)({
  img: {
    width: '37.5rem',
  },
  '@media (max-height: 412px)': {
    img: {
      width: '30rem',
    },
  },
});

// 심화학습 1일 때, 맨아래 content
export const A1ContentWrap = styled(ContentWrap)({
  img: {
    '&.key06': {
      width: '17rem',
    },
    '&.arrow': {
      width: '5rem',
    },
  },
  svg: {
    color: '#fff',
    width: '5.5rem',
    height: '5rem',
  },
});

// 심화학습 1일 때, 맨아래 content
export const S1ContentWrap = styled(ContentWrap)({
  transform: 'translateY(-0.35rem)',
  gap: '1rem',
  '& .menu': {
    backgroundColor: '#fff4e1',
    color: '#5f5f5f',
    width: '15rem',
    height: '4.6429rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1.8rem',
    fontSize: '1.75rem',
    letterSpacing: '-2px',
    '&.active': {
      backgroundColor: '#ffd70f',
      color: '#fff',
    },
  },
  '@media (max-height: 412px)': {
    '& .menu': {
      width: '12.5rem',
      height: '3.5714rem',
    },
  },
});

// 심화학습 2일 때, 맨아래 content
export const A2ContentWrap = styled(ContentWrap)({
  img: {
    width: '22.5rem',
  },
});

// 회화학습 2일 때, 맨아래 content
export const S2ContentWrap = styled(ContentWrap)({
  transform: 'translateX(0.75rem)',
  img: {
    '&.key07': {
      width: '10.5rem',
      marginLeft: '-1rem',
    },
  },
  '@media (max-height: 412px)': {
    img: {
      '&.key07': {
        width: '9.5rem',
        marginLeft: '-2rem',
      },
    },
  },
});
