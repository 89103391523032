import { styled, Box, CircularProgress, BoxProps, circularProgressClasses } from '@mui/material';
import ModalDefault from '../modal/ModalDefault';

const LoadingBox = styled(Box)(props => {
  return {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '99',
  };
});

export const CircularProgressBottom = styled(CircularProgress)(props => ({
  color: props.theme.palette.grey[props.theme.palette.mode === 'light' ? 300 : 800],
}));

export const CircularProgressTop = styled(CircularProgress)(props => ({
  color: props.theme.palette.purple.main,
  animationDuration: '650ms',
  position: 'absolute',
}));

export const LoadingLabel = styled(Box)(props => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: props.theme.palette.white.main,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  position: 'absolute',
}));

interface LoadingProps extends BoxProps {
  visible: boolean;
  onClose: () => void;
}

export function Loading(props: LoadingProps) {
  return (
    <ModalDefault visible={props.visible} onClose={props.onClose}>
      <LoadingBox sx={props.sx}>
        <CircularProgressBottom variant='determinate' size={'10rem'} thickness={4} value={100} />
        <CircularProgressTop
          variant='indeterminate'
          disableShrink
          sx={{
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={'10rem'}
          thickness={4}
        />
        <LoadingLabel>Loading</LoadingLabel>
      </LoadingBox>
    </ModalDefault>
  );
}
