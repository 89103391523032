import { useNavigate, useLocation } from 'react-router-dom';
import { styled, Box } from '@mui/material';
import { d_flex_center } from '../../styles/common';

const StyledNotFoundContainer = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  ...d_flex_center,
}));

const StyledNotFoundWrapper = styled(Box)(props => ({
  lineHeight: '1',
  textAlign: 'center',
}));

const StyledTitle = styled(Box)(props => ({
  fontSize: '5.5rem',
  fontWeight: '800',
}));

const StyledSubTitle = styled(Box)(props => ({
  fontSize: '2rem',
}));

const StyledParagraph = styled(Box)(props => ({
  marginTop: '1.5rem',
  fontSize: '1.075rem',
}));

const StyledLink = styled(Box)(props => ({
  cursor: 'pointer',
  color: props.theme.palette.blue.main,
  '&:hover': {
    borderBottom: `1px solid ${props.theme.palette.blue.main}`,
    paddingBottom: '3px',
  },
}));

function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  const onClickHomePage = () => {
    if (location.pathname === '/404') navigate(-2);
    else navigate(-1);
  };
  return (
    <StyledNotFoundContainer>
      <StyledNotFoundWrapper>
        <StyledTitle>404</StyledTitle>
        <StyledSubTitle>Page Not Found</StyledSubTitle>
        <br />
        <StyledParagraph>
          The Page you are looking for doesn&apos;t exist or an other error occured. Go to{' '}
          <StyledLink component={'a'} onClick={onClickHomePage}>
            Home Page.
          </StyledLink>
        </StyledParagraph>
      </StyledNotFoundWrapper>
    </StyledNotFoundContainer>
  );
}

export default NotFound;
