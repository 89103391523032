import { Routes, Route } from 'react-router-dom';

import Loading from '../loading/Loading';
import { Suspense, lazy, useContext, useEffect } from 'react';
const LoginIndex = lazy(() => import('../../pages/login/Login.Container'));
import { FindPWContainer } from 'pages/login/FindPW/FindPW.Container';
import { SignupContainer } from 'pages/signup/Signup.Container';

import { styled, Box } from '@mui/material';
import { d_flex_center, dir_column } from '../../styles/common';
import { useRecoilValue } from 'recoil';
import { deviceState } from '../../recoil/common/device';
import { ModalContext } from '../../provider/ModalProvider';

const StyledLayoutLogin = styled(Box)(() => ({
  width: '100%',
  minHeight: '100vh',
  overflowX: 'hidden',
  overflowY: 'hidden',
  backgroundColor: '#fafcff',
  ...d_flex_center,
  ...dir_column,
}));

declare let navigator: any;

function LayoutLogin() {
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile } = deviceStateData;

  const { modal_confirm } = useContext(ModalContext);

  useEffect(() => {
    if (is_mobile) {
      document.addEventListener('backbutton', onBackButton, false);
    }
    return () => {
      if (is_mobile) document.removeEventListener('backbutton', onBackButton, false);
    };
  }, [is_mobile]);

  const onBackButton = () => {
    modal_confirm.openModalConfirm('power', 'quit_voca', () => navigator.app.exitApp());
  };

  return (
    <>
      <StyledLayoutLogin>
        <Suspense fallback={<Loading isLogo={true} />}>
          <Routes>
            <Route path={`/`} element={<LoginIndex />} />;
            <Route path={`findpw`} element={<FindPWContainer />} />
            <Route path={`/signup`} element={<SignupContainer />} />
          </Routes>
        </Suspense>
      </StyledLayoutLogin>
    </>
  );
}

export default LayoutLogin;
