import { atom, SetterOrUpdater } from 'recoil';
import { check_contents } from '../../utils/modal_check_contents';
import { setModalTitle } from '../../utils/tools';

export interface ModalCheck2DataType {
  visible: boolean;
  title: string;
}

export const modalChec2kData = atom<ModalCheck2DataType>({
  key: 'modalCheck2',
  default: {
    visible: false,
    title: '',
  },
});

interface ModalCheckSetter {
  setModalCheck2: SetterOrUpdater<ModalCheck2DataType>;
}

export const openModalCheck2 = ({ setModalCheck2 }: ModalCheckSetter, type: string, target?: string) => {
  const title = check_contents[type] ? check_contents[type].title : 'type이 잘 못되었습니다.';
  const subTitle = check_contents[type] && check_contents[type].sub_title ? check_contents[type].sub_title : undefined;

  const payload = {
    title,
    subTitle,
    target,
  };

  const modal_title = setModalTitle(payload);
  setModalCheck2({
    visible: true,
    title: modal_title,
  });
};
