import { Box, styled } from '@mui/material';
import { d_flex_center, dir_column } from 'styles/common';
import { QuestionText, PassageRow } from './common';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ALPHABET, NG_CDN, FONT_STD } from 'utils/constants';
import { koreanReplace } from 'utils/tools';
import { loadingState, setPercent } from 'recoil/common/loading';
import { useRecoilState, useRecoilValue } from 'recoil';
import { learningState } from 'recoil/model/learning';
import OXMark from 'components/common/Study/OXMark';
import { StyledIconButton } from './GrammarMC';
import { AiOutlineRight } from 'react-icons/ai';
import { settingState } from 'recoil/model/settings';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { deviceState } from 'recoil/common/device';
import { EffectSoundContext } from 'provider/EffectSoundProvider';
import { ModalContext } from 'provider/ModalProvider';

declare let window: any;

const StyledGrammarSAWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
}));

const SAWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  ...dir_column,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  justifyContent: 'center',
  padding: '0 6%',
});

const TypeRow = styled(Box)({
  minHeight: '36vh',
  display: 'flex',
  flexWrap: 'wrap',
});

const TypeWrap = styled(Box)({
  minHeight: 'inherit',
  paddingLeft: 'calc(16vh + 20px - 5vw)',
  paddingRight: 'calc(16vh + 20px - 5vw)',
  position: 'relative',
  flex: '0 0 100%',
});

const Type0Box = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexWrap: 'wrap',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  marginTop: '-12.05vh',
  width: '100%',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

const Type0Input = styled('input')({
  resize: 'none',
  fontSize: '2vw',
  width: '11vw',
  lineHeight: '3.42vw',
  textAlign: 'center',
  backgroundColor: '#f5f5f5',
  borderRadius: '35px',
  padding: '8px 16px',
  height: '100%',
  outline: 'none',
  border: 'none',
  fontFamily: 'Apple SD Gothic Neo',
});

const Type1Wrap = styled(Box)({
  minHeight: 'inherit',
  paddingLeft: 'calc(16vh + 20px - 5vw)',
  paddingRight: 'calc(16vh + 20px - 5vw)',
  position: 'relative',
  flex: '0 0 100%',
  display: 'flex',
  justifyContent: 'center',
});

const Type1Box = styled(Box)({
  paddingTop: '3vh',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexWrap: 'wrap',
  display: 'flex',
  position: 'absolute',
  width: '100%',
});

const Type1Textarea = styled('textarea')({
  resize: 'none',
  fontSize: '2vw',
  lineHeight: '3.42vw',
  textAlign: 'center',
  height: '100%',
  width: '100%',
  borderStyle: 'none',
  backgroundColor: 'transparent',
  overflow: 'auto',
});

//! 모바일일 때 사용할 UI
const Type1Input = styled('input')({
  opacity: '0',
  zIndex: '-1',
  position: 'absolute',
  '&:focus, &:active': {
    outlineOffset: '0',
    outline: 'none',
  },
});

const Type1ValueBox = styled(Box)({
  fontFamily: 'Inconsolata',
  zIndex: '2',
  position: 'absolute',
  span: {
    fontFamily: 'Inconsolata',
    fontWeight: '400',
  },
});

const Type2Row = styled(Box)({
  // ! img 여부에 따라 min-height 60vh or 30vh
  minHeight: '60vh',
  margin: '0 !important',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

const Type2Wrap = styled(Box)({
  paddingLeft: 'calc(16vh + 20px - 5vw)',
  paddingRight: 'calc(16vh + 20px - 5vw)',
  paddingTop: '0',
  paddingBottom: '0',
  height: '100%',
  flex: '0 0 100%',
  maxWidth: '100%',
  width: '100%',
});

const Type2Box = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  position: 'relative',
  height: '100%',
  width: '100%',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',

  ' .success--text': {
    //! sa_type 2 > 기본학습 color
    fontFamily: 'Apple SD Gothic Neo',
    fontWeight: '400',
    color: '#4620e9',
    caretColor: '#4620e9',
    fontSize: 'inherit',
  },

  ' .info2--text': {
    //! sa_type 2 > 심화학습 color
    fontFamily: 'Apple SD Gothic Neo',
    fontWeight: '400',
    color: '#4620e9',
    caretColor: '#4620e9',
    fontSize: 'inherit',
  },
});

const Type2Quetion = styled(Box)({
  lineHeight: '1.5',
  fontSize: '2.5vw',
  resize: 'none',
  fontFamily: 'Apple SD Gothic Neo',
  textAlign: 'center',
  color: 'rgba(0,0,0,.87)',
  fontWeight: '400',

  ' .sa_input': {
    resize: 'none',
    fontFamily: 'Apple SD Gothic Neo',
    lineHeight: '3.42vw',
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: '35px',
    padding: '8px 16px',
    height: '100%',
    borderStyle: 'none',
    fontSize: 'inherit !important',
  },

  ' .success--text': {
    //! sa_type 2 > 기본학습 color
    fontFamily: 'Apple SD Gothic Neo',
    fontWeight: '400',
    color: '#4620e9',
    caretColor: '#4620e9',
    fontSize: 'inherit',
  },

  ' .info2--text': {
    //! sa_type 2 > 심화학습 color
    fontFamily: 'Apple SD Gothic Neo',
    fontWeight: '400',
    color: '#4620e9',
    caretColor: '#4620e9',
    fontSize: 'inherit',
  },

  ' .g_kor_span': {
    fontFamily: 'Apple SD Gothic Neo',
    fontWeight: '400',
    fontSize: '80%',
  },

  ' .underline-under': {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: 'from-font',
  },
});

export const ImgRow = styled(Box)({
  ...d_flex_center,
  lineHeight: '1.5',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

const Img = styled('img')({
  //! img도 font-level에 맞춰서 커지거나 줄어들어야 한다.
  width: '49.5vw',
  marginBottom: '3vh',
  borderStyle: 'none',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});
const TabStyleSAWrap = styled(Box)(props => ({
  width: '100%',
  height: '70vh',
  overflow: 'hidden auto',
  padding: '0 6%',
}));

export const StyledCommentBox = styled(Box)(props => ({
  fontSize: '1.3vw',
  top: '65vh',
  left: '50%',
  color: 'white !important',
  background: 'rgba(65, 65, 65, 0.9)',
  zIndex: '15',
  padding: '1.5vw',
  borderRadius: '8px',
  fontWeight: '100',
  transform: 'translate(-50%, 0)',
  minWidth: '50vw',
  maxWidth: '50vw',
  textAlign: 'center',
  wordBreak: 'keep-all',
}));

interface GrammarSAProps {
  content: GrammarContentType;
  createResultWrongData: (answer: string, rightAnswer: string) => string;
  sendResultsheet: (resultsheet: any) => void;
  isReviewLearning: boolean;
}

function GrammarSA({ content, createResultWrongData, sendResultsheet, isReviewLearning }: GrammarSAProps) {
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { modal_alert } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const { is_mobile, platform } = deviceStateData;
  const [learningStart, setLearningStart] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('g-horizontal-right');
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;
  const {
    mod,
    modules,
    current_step,
    current_page,
    show_modal,
    resultsheet,
    current_module_settings,
    use_image,
    learning_type,
    direction,
    grammar_contents,
    font_level,
    grammar_init,
  } = learningStateData;
  const isReviewAdvanced = isReviewLearning && content.comment;
  const [mobileWriting, setMobileWriting] = useState<boolean>(false);

  const grammar_init_ref = useRef(grammar_init);
  grammar_init_ref.current = grammar_init;
  const is_review_learning_ref = useRef(isReviewLearning);
  is_review_learning_ref.current = isReviewLearning;

  const comment_ref = useRef(content.comment);

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (!window.ExternalKeyboard.isUsed) setMobileWriting(true);
    } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setMobileWriting(true);
    }
  }, [content]);

  useEffect(() => {
    initLearningStart();
    setLearningStart(false);
    setTimeout(() => {
      setLearningStart(true);
    }, 300);
  }, []);

  useEffect(() => {
    initLearningStart();
  }, [grammar_contents]);

  useEffect(() => {
    if (percent == 100 && grammar_init_ref.current) {
      let alert_type = '';
      if (learning_type == 7) {
        alert_type += 'grammar_t';
        if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        }
      } else if (learning_type == 8) {
        alert_type += 'grammar_b';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        } else if (current_page == 3) {
          alert_type += '_3';
        }
      } else if (learning_type == 9) {
        alert_type += 'grammar_d';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        }
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      setTimeout(() => {
        modal_alert.openModalAlert(alert_type, undefined, undefined, () => {
          setLearningStateData(prevState => ({
            ...prevState,
            grammar_init: false,
            show_modal: false,
          }));
        });
      }, 300);
    }
  }, [percent]);

  const initLearningStart = () => {
    // console.log('sa', is_review_learning_ref.current, current_step !== 0, modules.length == 1);
    if (is_review_learning_ref.current && current_step !== 0 && modules.length == 1) return false;
    setPercent(setLoadingStateData, 100);
  };

  const { question, origin_passage, passage, rightanswer, sa_type, img } = content;
  const isGoNextRef = useRef(false);

  //! common
  const [isScoring, setIsScoring] = useState<boolean>(false);

  //! sa_typye 0
  const input_el_0 = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const [type0Value, setType0Value] = useState('');
  const type0RefValue = useRef<string>('');
  type0RefValue.current = type0Value;

  //! sa_typye 1
  const input_el_1 = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const [emptyPos, setEmptyPos] = useState<number[]>([]);
  const [curAnswer, setCurAnswer] = useState<string[]>([]);
  const [answer, setAnswer] = useState('');
  const [visibleOX, setVisibleOX] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);
  const [disableMove, setDisableMove] = useState<boolean>(false);
  const settingStateData = useRecoilValue<SettingsType>(settingState);
  const answer_ref = useRef<string>('');
  answer_ref.current = is_mobile ? curAnswer.join('') : answer;

  //! sa_type 2
  const [type2Value, setType2Value] = useState('');
  const type2RefValue = useRef<string>('');
  type2RefValue.current = type2Value;

  //! ref
  const sa_type_ref = useRef(sa_type);
  sa_type_ref.current = sa_type;
  const font_level_ref = useRef(font_level);
  font_level_ref.current = font_level;
  const content_ref = useRef(content);
  content_ref.current = content;
  const resultsheet_ref = useRef(resultsheet);
  resultsheet_ref.current = resultsheet;
  const current_step_ref = useRef(current_step);
  current_step_ref.current = current_step;

  //! sa_type 0 input 세팅
  useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('wt0');
      if (input) {
        input_el_0.current = input as HTMLInputElement;
        if (sa_type === '0') {
          const txt = rightanswer?.split('');
          if (txt) {
            input.setAttribute('data-textleng', String(txt.length));
            input.setAttribute('maxlength', String(txt.length + 3));
            setInputType0Width(font_level_ref.current);
            if (!img) {
              setTimeout(() => {
                input.focus();
                if (is_mobile) setMobileFocus(true);
              }, 1000);
            }
          }
        }
      }
    }, 500);
  }, [content, current_step]);

  useEffect(() => {
    setInputType0Width(font_level);
    setInputType2Width(font_level);
  }, [font_level]);

  useEffect(() => {
    if (settingStateData.enable_keyboard) bindKeyboard();
    return () => {
      if (settingStateData.enable_keyboard) unbindKeyboard();
    };
  }, [disableMove, visibleOX, right]);

  useEffect(() => {
    setLearningStart(false);
    setTimeout(() => {
      setLearningStart(true);
    }, 800);
  }, [current_page]);

  useEffect(() => {
    // console.log(learningStart, 'learningStart');
  }, [learningStart]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', baseKeyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', baseKeyboardDownEvent);
  };

  const baseKeyboardDownEvent = (e: KeyboardEvent) => {
    if (visibleOX && e.keyCode == 13) {
      if (isReviewAdvanced && !right) {
        if (!disableMove) {
          goNextProcess();
        }
      }
    }
  };

  const setInputType0Width = (font_level: number) => {
    if (sa_type_ref.current == '0') {
      const input = document.getElementById('wt0');
      if (input) {
        input_el_0.current = input as HTMLInputElement;
        if (sa_type === '0') {
          let txt_leng = input.getAttribute('data-textleng') ? Number(input.getAttribute('data-textleng')) : 5;
          txt_leng = (txt_leng + 7) * font_level;
          input.style.width = `${txt_leng}vw`;
        }
      }
    }
  };

  const setInputType2Width = (font_level: number) => {
    if (sa_type_ref.current == '2') {
      const els = document.getElementsByClassName('sa_input');
      if (els && els.length > 0) {
        for (let i = 0; i < els.length; i++) {
          let txt_leng = els[i].getAttribute('data-textleng') ? Number(els[i].getAttribute('data-textleng')) : 5;
          txt_leng = +txt_leng * font_level;
          (els[i] as HTMLElement).style.width = `${txt_leng}vw`;
        }
      }
    }
  };

  //! sa_type 1 textarea 세팅
  useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('wti') as HTMLInputElement | HTMLTextAreaElement | undefined;
      if (input) {
        input_el_1.current = input;
        //! sa_type = 1
        if (sa_type === '1') {
          let reg_pattern = /[A-Za-z\.\,\!\?]/g;
          const splitted = Array.from(rightanswer.trim());
          const lower_passage = origin_passage.toLowerCase();
          const lower_right = rightanswer.toLowerCase();

          if (lower_passage.includes(lower_right)) {
            reg_pattern = /[A-Za-z]/g;
          }

          //? 시작했을 때, right answer를 띄어쓰기 제외하고 모두 _로 바꿔줘야 함
          const init_cur_answer = [];
          const init_empty_pos = [];

          for (let i = 0; i < splitted.length; i++) {
            let char = '_';
            if (new RegExp(reg_pattern).test(splitted[i])) {
              char = '_';
              init_empty_pos.push(i);
            } else {
              char = splitted[i];
            }
            init_cur_answer.push(char);
          }

          setEmptyPos(init_empty_pos);
          setCurAnswer(init_cur_answer);
          setAnswer(init_cur_answer.join(''));
        }
      }
      // }, 100);
    }, 500);
  }, [content, current_step]);

  //! sa_type 1 > 위 초기값 세팅 이후에 세팅
  useEffect(() => {
    if (curAnswer.length && input_el_1.current) {
      if (sa_type === '1') {
        input_el_1.current.value = curAnswer.join('');
        setTimeout(() => {
          if (input_el_1.current) {
            input_el_1.current.focus();
            if (is_mobile) setMobileFocus(true);
          }
        }, 1000);

        setTimeout(() => {
          setCursorPos(emptyPos[0], emptyPos[0]);
        }, 0);
      }
    }
  }, [curAnswer]);

  //! sa_type 2 초기화
  useEffect(() => {
    if (sa_type === '2') {
      const els = document.getElementsByClassName('sa_input');
      setTimeout(() => {
        if (els && els.length > 0) {
          for (let i = 0; i < els.length; i++) {
            const txt_leng = els[i].getAttribute('data-textleng') ? Number(els[i].getAttribute('data-textleng')) : 14;
            els[i].setAttribute('maxlength', String(txt_leng - 4));

            if (is_mobile) {
              //! 모바일 이벤트 처리 > sa_type2
              els[i].addEventListener('input', touchKeyboard2);
              els[i].addEventListener('keyup', keyupKeyobard);
              els[i].addEventListener('focus', () => check_focus(i));
              els[i].addEventListener('blur', () => check_blur(i));
            } else {
              //! input이 중복으로 들어갈 수 있으므로 각 input마다 event 걸어주는 처리
              els[i].addEventListener('keydown', keydownKeyboard_2);
              els[i].addEventListener('keyup', keyupKeyboard_2);
              els[i].addEventListener('paste', forbiddenPaste);
            }

            setInputType2Width(font_level_ref.current);
          }
          if (!img && !is_mobile) {
            setTimeout(() => {
              if (els[0]) (els[0] as HTMLInputElement).focus();
              if (is_mobile) setMobileFocus(true);
            }, 1000);
          }
        }
      }, 500);
    }
  }, [content, current_step]);

  //! 커서 위치 지정해주는 메서드
  const setCursorPos = (start: number, end: number) => {
    if (input_el_1.current) {
      const input = input_el_1.current as any;
      input.selectionStart = start;
      input.selectionEnd = end;
    }
  };

  //! 현재 커서 위치 가져오는 메서드
  const getCursorPos = () => {
    const input = input_el_1.current as any | null;
    let start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;

    if (input) {
      if (typeof input.selectionStart == 'number' && typeof input.selectionEnd == 'number') {
        start = input.selectionStart;
        end = input.selectionEnd;
      }
    }

    return {
      start,
      end,
    };
  };

  //! 키보드 이벤트 연결 > sa_type 0
  const keydownKeyboard_0 = (e: any) => {
    if (!input_el_0.current) return;

    const input = input_el_0.current;
    const splitted = Array.from(rightanswer.trim());

    if (e.keyCode === 222) {
      e.target.value = e.target.value.replace(/‘|’/g, "'");
    }

    if (e.defaultPrevented) {
      return;
    }

    if (e.repeat) {
      e.preventDefault();
      return;
    }

    const code = e.which || e.keyCode;
    const max = splitted.length;
    const start_pos = input.selectionStart;
    if (start_pos == null) return false;

    //! = 불허
    if (code == 91) return false;

    //! Tab 키 허용
    if ([9].includes(code)) {
      return true;
    }

    //! F12, F8 허용
    if (process.env.NODE_ENV == 'development') {
      if ([119, 123].includes(code)) {
        return true;
      }
    }

    //! Backspace
    if (code == 8 || e.code == 'Backspace' || e.key == 'Backspace') {
      e.preventDefault();
      e.stopPropagation();
      let txt = input.value;
      txt = koreanReplace(txt);
      txt = txt.substr(0, start_pos - 1) + txt.substr(start_pos, start_pos + 1);
      input.value = txt;
      setType0Value(txt);
      input.setSelectionRange(start_pos - 1, start_pos - 1);
      return false;
    }

    //! maxlength 처리
    if (max + 3 <= input.value.length) {
      e.preventDefault();
      return;
    }

    //! 좌로 이동
    if (code == 37) {
      input.setSelectionRange(start_pos, start_pos);
      return false;
    }

    //! 우로 이동
    if (code == 39) {
      input.setSelectionRange(start_pos, start_pos);
      return false;
    }

    //! Enter키
    if (code == 13) {
      goNext();
      return false;
    }

    //! del 키
    if (code == 46) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    const pre_processing_value = koreanReplace(input.value);
    if (ALPHABET.includes(e.key) || code == 32) {
      //? input의 value 세팅
      const txt = pre_processing_value + e.key;
      setType0Value(txt);
    } else {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  //! 모바일 이벤트 연결 > sa_type 0 (소프트 키보드)
  const touchKeyboard0 = (e: any) => {
    if (!input_el_0.current) return;
    const input = input_el_0.current;
    const val = e.target.value;
    input.value = val;
    setType0Value(val);
  };

  //! sa_type 0 keup event
  const keyupKeyboard_0 = (e: any) => {
    if (!input_el_0.current) return;

    //! 한글입력시 안되게 처리
    const input = input_el_0.current;
    input.value = type0RefValue.current;
  };

  //! sa_type 2 keup event
  const keyupKeyboard_2 = (e: any) => {
    const els = document.getElementsByClassName('sa_input');
    if (els && els.length > 0) {
      for (let i = 0; i < els.length; i++) {
        const input_el = els[i] as HTMLInputElement;
        input_el.value = koreanReplace(input_el.value);
      }
    }
  };

  //! 키보드 이벤트 연결 > sa_type 1
  const keydownKeyboard_1 = (e: any) => {
    if (!input_el_1.current) return;

    const cur_splitted = Array.from(input_el_1.current.value);
    const splitted = Array.from(rightanswer.trim());
    const code = e.keyCode;
    const max = cur_splitted.length > splitted.length ? cur_splitted.length : splitted.length;

    //? =
    if (code == 91) return false;

    let cur_pos = getCursorPos().start;

    //? backspace
    if (code == 8 || e.code == 'Backspace' || e.key == 'Backspace') {
      e.preventDefault();
      e.stopPropagation();

      if (cur_pos > 0) {
        let txt = input_el_1.current.value;

        if (max <= txt.length) {
          txt = koreanReplace(txt);
          txt = txt.substr(0, max);
          input_el_1.current.value = txt;
        }

        do {
          cur_pos -= 1;
          if (emptyPos.includes(cur_pos)) {
            input_el_1.current.value = txt.substr(0, cur_pos) + curAnswer[cur_pos] + txt.substr(cur_pos + 1);
          }
          setCursorPos(cur_pos, cur_pos);
        } while (!emptyPos.includes(cur_pos) && cur_pos > 0);
      }

      return false;
    }

    if (max <= (input_el_1.current.value as any)) {
      e.preventDefault();
      return;
    }

    //? 오른쪽 방향키
    if ((code == 32 || code == 39) && cur_pos <= max) {
      e.preventDefault();
      e.stopPropagation();

      //! 우측시 다음 입력할 곳으로 넘기는 처리가 필요함
      cur_pos += 1;
      let loop = true;
      while (loop) {
        if (emptyPos.includes(cur_pos) || cur_pos >= max) {
          loop = false;
          if (cur_pos >= max) {
            cur_pos = max;
          }
        } else {
          cur_pos += 1;
        }
      }

      setCursorPos(cur_pos, cur_pos);
      return false;
    }

    //? Delete키
    if (code == 46) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    //? Enter키
    if (code == 13) {
      e.preventDefault();
      e.stopPropagation();
      goNext();
      return false;
    }

    if (!emptyPos.includes(cur_pos)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    if (ALPHABET.includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      const txt = input_el_1.current.value;
      input_el_1.current.value = txt.substr(0, cur_pos) + e.key + txt.substr(cur_pos + 1);
      const new_pos = cur_pos + 1;
      setCursorPos(new_pos, new_pos);
      setAnswer(input_el_1.current.value);
      return true;
    } else {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  const [mobileFocus, setMobileFocus] = useState(false);
  const current_pos_ref = useRef(0);

  //! mobile focus + android 처리하기
  const check_focus = (idx?: number) => {
    if (is_mobile && platform == 'android') {
      const el = document.getElementById('responsive-wrapper');
      const el2 = document.getElementById('sa-wrap');
      const sa_type = sa_type_ref.current;
      //! mobile input focus 시에
      if (el) {
        const get_vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        if (sa_type == '0' && input_el_0.current) {
          const input_top_vh = 100 * (input_el_0.current.getBoundingClientRect().top / get_vh);
          if (input_top_vh >= 34) {
            el.style.marginTop = `calc(-${input_top_vh - 34}vh - 5.5rem)`;
          }
        } else if (sa_type == '1' && input_el_1.current) {
          const input_top_vh = 100 * (input_el_1.current.getBoundingClientRect().top / get_vh);
          if (input_top_vh >= 34) {
            el.style.marginTop = `calc(-${input_top_vh - 34}vh - 5rem)`;
          }
        } else if (sa_type == '2' && typeof idx == 'number') {
          const els = document.getElementsByClassName('sa_input');
          if (els && els[idx]) {
            const input_top_vh = 100 * (els[idx].getBoundingClientRect().top / get_vh);
            if (input_top_vh >= 34) {
              el.style.marginTop = `calc(-${input_top_vh - 34}vh - 21rem)`;
              if (el2) {
                el2.style.marginTop = `-5rem`;
                el2.style.backgroundColor = `#fff`;
                el2.style.position = `absolute`;
              }
            }
          }
        }
      }
    }
  };

  //! mobile blur + android 처리하기
  const check_blur = (idx?: number) => {
    if (is_mobile && platform == 'android') {
      //! mobile input blur 시에
      const el = document.getElementById('responsive-wrapper');
      const el2 = document.getElementById('sa-wrap');
      if (el) el.style.marginTop = '';
      if (el2) {
        el2.style.marginTop = ``;
        el2.style.backgroundColor = ``;
        el2.style.position = ``;
      }
    }
  };

  //! 모바일 이벤트 연결 > sa_type 1 (소프트 키보드)
  const touchKeyboard1 = (e: any) => {
    if (!input_el_1.current) return;
    const val = e.target.value;
    const char_array = Array.from(val);
    const input_char = char_array[0] as string;

    if (!curAnswer.includes('_')) return;

    const new_cur_answer: string[] = [...curAnswer];
    new_cur_answer[current_pos_ref.current] = input_char;

    setCurAnswer(new_cur_answer);
  };

  //! 모바일 div 클릭시 input focus > sa_type 1 (소프트 키보드)
  const focusInput = () => {
    if (!input_el_1.current) return;
    input_el_1.current.focus();
    setMobileFocus(true);
  };

  //! 모바일 div내에 들어갈 element 세팅 > sa_type 1 (소프트 키보드)
  const mobileCurAnswer = useMemo(() => {
    const cur_answer_array = Array.from(curAnswer);
    const new_answer_array: string[] = [];
    let first = true;
    for (let i = 0; i < cur_answer_array.length; i++) {
      let cls = '';
      const spell = cur_answer_array[i];
      if (emptyPos.includes(i)) {
        //! 문제
        cls += 'q';
        if (spell == '_') {
          if (first) {
            first = false;
            current_pos_ref.current = i;
            cls += ' current_pos ';
          }
        }
      } else {
        //! 고정단어
      }

      if (!curAnswer.includes('_') && current_pos_ref.current == emptyPos[emptyPos.length - 1]) {
        //! 마지막 단어 입력시 처리
        current_pos_ref.current += 1;
      }

      const tag = `<span data-index=${i} class='${cls}'>${spell}</span>`;
      new_answer_array.push(tag);
    }

    return new_answer_array.join('');
  }, [curAnswer]);

  //! 모바일 keydown (소프트 키보드) > sa_type 1
  const keydownKeyboard = (e: any) => {
    const code = e.which || e.keyCode;
    const isArrowLeft = code === 37 || e.key === 'ArrowLeft' || e.code === 'ArrowLeft';
    const isArrowRight = code === 39 || e.key === 'ArrowRight' || e.code === 'ArrowRight';

    if (isArrowLeft || isArrowRight) {
      e.preventDefault();
      return;
    }
  };

  //! 모바일 keyup (소프트 키보드) > 모든 sa_type 공통
  const keyupKeyobard = (e: any) => {
    const code = e.which || e.keyCode;
    const sa_type = sa_type_ref.current;

    if (13 == code) {
      //! Enter + blur 처리해서 소프트 키보드 제거하기
      if (sa_type == '0' && input_el_0.current) {
        input_el_0.current.blur();
      }

      if (sa_type == '1' && input_el_1.current) {
        input_el_1.current.blur();
      }

      if (sa_type == '2') {
        const els = document.getElementsByClassName('sa_input');
        if (els && els.length > 0) {
          for (let i = 0; i < els.length; i++) {
            (els[i] as HTMLInputElement).blur();
          }
        }
      }

      goNext();
      return false;
    }

    if (8 == code) {
      //! Backspace 처리
      if (sa_type === '1') {
        const new_cur_answer = [...curAnswer];

        for (let i = emptyPos.length - 1; i >= 0; i--) {
          if (emptyPos[i] < current_pos_ref.current) {
            new_cur_answer[emptyPos[i]] = '_';
            current_pos_ref.current = emptyPos[i];
            break;
          }
        }

        setCurAnswer(new_cur_answer);
      } else if (sa_type == '2') {
        const els = document.getElementsByClassName('sa_input');
        if (els && els.length > 0) {
          const answer_arr = [];
          for (let i = 0; i < els.length; i++) {
            const txt = (els[i] as HTMLInputElement).value;
            answer_arr.push(txt);
          }
          setType2Value(answer_arr.join(' / '));
        }
      }
    }
  };

  //! 모바일 이벤트 연결 > sa_type2 (소프트 키보드)
  const touchKeyboard2 = () => {
    const els = document.getElementsByClassName('sa_input');
    if (els && els.length > 0) {
      const answer_arr = [];
      for (let i = 0; i < els.length; i++) {
        const txt = (els[i] as HTMLInputElement).value;
        answer_arr.push(txt);
      }
      setType2Value(answer_arr.join(' / '));
    }
  };

  //! 키보드 이벤트 연결 > sa_type 2
  const keydownKeyboard_2 = (e: any) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = e.target.value.replace(/ /g, '');
      return;
    }

    if (e.keyCode === 222) {
      e.target.value = e.target.value.replace(/‘|’/g, "'");
    }

    if (e.defaultPrevented) {
      return;
    }

    if (e.repeat) {
      e.preventDefault();
      return;
    }

    const code = e.which || e.keyCode;
    const start_pos = e.target ? e.target.selectionStart : null;
    if (start_pos === null) return false;

    // Tab 키 허용
    if ([9].includes(code)) {
      return true;
    }

    if (code == 91) return false;

    if (process.env.NODE_ENV == 'development') {
      if ([119, 123].includes(code)) {
        return true;
      }
    }

    if (code == 8 || e.code == 'Backspace' || e.key == 'Backspace') {
      e.preventDefault();
      e.stopPropagation();
      let new_txt = e.target.value;
      new_txt = koreanReplace(new_txt);
      new_txt = new_txt.substr(0, start_pos - 1) + new_txt.substr(start_pos, start_pos + 1);
      e.target.value = new_txt;
      e.target.setSelectionRange(start_pos - 1, start_pos - 1);

      //! 지웠을 때 type 2 value 바꾸는 처리 여기서 해야함
      const els = document.getElementsByClassName('sa_input');
      if (els && els.length > 0) {
        const answer_arr = [];
        for (let i = 0; i < els.length; i++) {
          let txt = (els[i] as HTMLInputElement).value;
          if (els[i] === e.target) txt = new_txt;
          answer_arr.push(txt);
        }
        setType2Value(answer_arr.join(' / '));
      }

      return false;
    }

    // 좌로 이동
    if (code == 37) {
      e.target.setSelectionRange(start_pos, start_pos);
      return false;
    }

    // 우로 이동
    if (code == 32 || code == 39) {
      e.target.setSelectionRange(start_pos, start_pos);
      return false;
    }

    // del키
    if (code == 46) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    // Enter키
    if (code == 13) {
      e.preventDefault();
      e.stopPropagation();

      // console.log('enter', e);

      goNext();
      return false;
    }

    if (ALPHABET.includes(e.key)) {
      //! 여기서 input_value 세팅하기
      const els = document.getElementsByClassName('sa_input');
      if (els && els.length > 0) {
        const answer_arr = [];
        for (let i = 0; i < els.length; i++) {
          let txt = koreanReplace((els[i] as HTMLInputElement).value);
          if (els[i] === e.target) txt = txt + e.key;
          answer_arr.push(txt);
        }
        setType2Value(answer_arr.join(' / '));
      }

      return true;
    } else {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  //! blur 이벤트 연결 > sa_type 0
  const blurInput_0 = () => {
    const input = document.getElementById('wt0');
    if (input) {
      if (sa_type === '0' && !img && !is_mobile) {
        setTimeout(() => {
          input.focus();
        }, 1000);
      }
    }
  };

  //! blur 이벤트 연결 > sa_type 1
  const blurInput_1 = () => {
    if (input_el_1.current && !is_mobile) {
      setTimeout(() => {
        if (input_el_1.current) input_el_1.current.focus();
      }, 1000);
    }
  };

  //! 모바일 focus 이벤트
  const focusMobile = () => {
    // setMobileFocus(true);
  };

  //! 모바일 blur 이벤트
  const blurMobile = () => {
    setMobileFocus(false);
    check_blur();
  };

  //! paste 이벤트 연결
  const forbiddenPaste = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  //! keyup 이벤트 연결
  const keyupKeyboard = (e: any) => {
    if (!input_el_1.current) return;

    const cur_splitted = Array.from(input_el_1.current.value.trim());
    const splitted = Array.from(rightanswer.trim());
    const code = e.which || e.keyCode;
    const max = cur_splitted.length > splitted.length ? cur_splitted.length : splitted.length;

    //? Delete키
    if (code == 46) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    if (code == 8 || e.code == 'Backspace' || e.key == 'Backspace') {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    //! 왼쪽 커서 클릭
    if (code == 37) {
      e.preventDefault();
      e.stopPropagation();
      let cur_pos = getCursorPos().start;
      if (cur_pos > 0) {
        cur_pos -= 1;
        let loop = true;
        while (loop) {
          // console.log('loop');
          if (emptyPos.includes(cur_pos) || cur_pos == 0) {
            loop = false;
          } else {
            cur_pos -= 1;
          }
        }
        setCursorPos(cur_pos, cur_pos);
        input_el_1.current.value = koreanReplace(input_el_1.current.value);
      }
      return false;
    }

    //! 오른쪽 커서 클릭
    if (code == 32 || code == 39) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    //! 한글입력 막기
    if (code == 229) {
      input_el_1.current.value = koreanReplace(input_el_1.current.value);
    }

    let loop = true;
    let cur_pos = getCursorPos().start;
    //? 이부분에서 cursor처리 추가 필요해보임

    while (loop && cur_pos <= max) {
      // console.log('loop');
      if (emptyPos.includes(cur_pos)) {
        loop = false;
      } else {
        cur_pos += 1;
      }
    }

    setCursorPos(cur_pos, cur_pos);
  };

  const goNext = async () => {
    if (isGoNextRef.current) return;
    isGoNextRef.current = true;
    const { sa_type, rightanswer } = content_ref.current;

    let right = false;

    if (sa_type === '0') {
      if (rightanswer == '-') {
        if (type0RefValue.current == '') {
          right = true;
        } else {
          right = false;
        }
      } else {
        right = type0RefValue.current === rightanswer;
      }
    } else if (sa_type === '1') {
      right = answer_ref.current === rightanswer;
      console.log('answer_ref.current', answer_ref.current);
      console.log('right', right);
    } else if (sa_type === '2') {
      if (rightanswer == '-') {
        if (type2RefValue.current == '') {
          right = true;
        } else {
          right = false;
        }
      } else {
        right = type2RefValue.current === rightanswer;
      }
    }
    setRight(right);
    setIsScoring(true);

    if (settingStateData.check_type) {
      setVisibleOX(true);
      playEffectSound(right ? 'correct' : 'wrong');
      if (!isReviewAdvanced || (isReviewAdvanced && right)) {
        setDisableMove(true);
      } else {
        setDisableMove(true);
        setTimeout(() => {
          setDisableMove(false);
        }, 3000);
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }

    // ! 일반 학습이거나, 리뷰강의 심화학습이 아니거나, 리뷰강의 심화학습에서 정답을 맞췄을 경우에만 다음문제 넘김처리
    if (!isReviewLearning || !isReviewAdvanced || (isReviewAdvanced && right)) {
      setTimeout(
        () => {
          goNextProcess();
        },
        process.env.NODE_ENV === 'development' ? 1000 : 3000,
      );
    }
  };

  const goNextProcess = async () => {
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet_ref.current));
    const answer = answer_ref.current;
    const { rightanswer, question, passage, sa_type, type, origin_passage, right_passage } = content_ref.current;
    const type0Value = type0RefValue.current;

    const result_obj: { [key: string]: any } = {
      no: current_step_ref.current + 1,
      question,
      passage,
      rightanswer,
      sa_type,
      type,
    };

    if (sa_type === '0') {
      result_obj.value = type0Value;
      result_obj.wrongHtml = createResultWrongData(type0Value, rightanswer);
      result_obj.right = type0Value === rightanswer;
      if (rightanswer == '-') {
        if (type0Value == '') {
          result_obj.right = true;
        } else {
          result_obj.right = false;
        }
      }
    } else if (sa_type === '1') {
      result_obj.value = answer;
      result_obj.origin_passage = origin_passage;
      result_obj.right = answer === rightanswer;
      result_obj.wrongHtml = createResultWrongData(answer, rightanswer);
    } else if (sa_type === '2') {
      result_obj.value = type2RefValue.current;
      result_obj.right_passage = right_passage;
      result_obj.wrongHtml = createType2ResultWrongData();
      result_obj.right = type2RefValue.current === rightanswer;

      if (rightanswer == '-') {
        if (type2RefValue.current == '') {
          result_obj.right = true;
        } else {
          result_obj.right = false;
        }
      }
    }

    resultsheet_tmp[current_step_ref.current] = result_obj;
    //! 성적표 데이터 집어넣고 sa_type 2 value 초기화 처리
    setType2Value('');

    if (current_step_ref.current == grammar_contents.length - 1) {
      setVisibleOX(false);
      setDisableMove(false);
      setIsScoring(false);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      });

      setTimeout(() => {
        sendResultsheet(resultsheet_tmp);
      }, 1);
    } else {
      //! input value 초기화
      setType0Value('');
      setAnswer('');
      setType2Value('');

      setDisableMove(false);
      setIsScoring(false);
      if (isReviewLearning && mod.length == 1) {
        setVisibleOX(false);
      } else {
        setTimeout(() => {
          setVisibleOX(false);
        }, 300);
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
        current_step: current_step_ref.current + 1,
        resultsheet: resultsheet_tmp,
      }));
    }
    isGoNextRef.current = false;
  };

  const imgWidth = useMemo(() => {
    switch (font_level) {
      case 0.6:
        return 44;
      case 0.8:
        return 46.75;
      case 1:
        return 49.5;
      case 1.2:
        return 52.25;
      case 1.4:
        return 55;
    }
  }, [font_level]);

  const createType2ResultWrongData = () => {
    const right_answers = content_ref.current.rightanswer.split(' / ');
    const user_answers = type2RefValue.current.split(' / ');
    let right_passage = content_ref.current.passage;
    const matches = right_passage.match(/<input[^>]*type=[\"']?([^>\"']+)[\"']?[^>]*>/gi);

    if (matches) {
      for (let i = 0; i < right_answers.length; i++) {
        const right_answer = right_answers[i];
        const user_answer = user_answers[i];
        let html_result = '';

        if (user_answer === undefined) {
          //! 입력 안 한 경우 + 오답인 케이스로 이경우에는 right_answer 전부 red로 처리
          for (let j = 0; j < right_answer.length; j++) {
            html_result += `<span style="color: red;">${right_answer[j]}</span>`;
          }
        } else {
          if (user_answer == '') {
            if (right_answer == '-') {
              //! 정답
              html_result += '-';
            } else {
              //! 오답
              for (let j = 0; j < right_answer.length; j++) {
                html_result += `<span style="color: red;">${right_answer[j]}</span>`;
              }
            }
          } else {
            if (right_answer == '-') {
              //! 오답
              for (let j = 0; j < right_answer.length; j++) {
                html_result += `<span style="color: red;">${right_answer[j]}</span>`;
              }
            } else {
              for (let i = 0; i < right_answer.length; i++) {
                if (user_answer[i] !== right_answer[i]) {
                  html_result += `<span style="color: red;">${right_answer[i]}</span>`;
                } else {
                  html_result += right_answer[i];
                }
              }
            }
          }
        }

        if (html_result == '-') {
          right_passage = right_passage.replace(matches[i], '').replace('  ', ' ');
        } else {
          right_passage = right_passage?.replace(matches[i], html_result);
        }
      }
    }

    return right_passage;
  };

  return (
    <StyledGrammarSAWrap
      sx={{
        padding: content.sub_unit === 't1' ? '0' : '0 6%',
        ' #ox-wrapper > div': {
          top: isReviewAdvanced
            ? '14.1vh !important'
            : content.sub_unit == 't1'
            ? 'calc((100vh - 4rem - 5rem - 4rem) * 0.5)'
            : '15vh !important',
        },
      }}
    >
      <Box
        sx={{
          display: learningStart ? 'none' : isReviewLearning && mod.length == 1 ? 'block' : 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: '#fff',
          zIndex: '1',
        }}
      ></Box>
      <OXMark show={visibleOX} right={right} grammar={true}></OXMark>
      {content.comment && visibleOX && isReviewLearning ? (
        <StyledCommentBox sx={{ position: 'absolute !important', top: '61vh', fontFamily: 'Apple SD Gothic Neo' }}>
          {content.comment}
        </StyledCommentBox>
      ) : null}

      <StyledIconButton
        disabled={disableMove}
        sx={{ right: '16px' }}
        disableRipple
        className={`${isReviewAdvanced && visibleOX && !right ? 'blink-right-button' : ''}`}
        onClick={isReviewAdvanced && visibleOX && !right ? () => goNextProcess() : () => goNext()}
      >
        <AiOutlineRight />
      </StyledIconButton>

      <SAWrap id='sa-wrap'>
        <SwitchTransition mode='out-in'>
          <CSSTransition
            key={`${current_page}-${current_step}-${content.type}`}
            classNames={animationDirection}
            // timeout={learningStart ? 300 : isReviewLearning && mod.length == 1 ? (grammar_init ? 0 : 300) : 0}
            timeout={
              learningStart
                ? isReviewLearning && mod.length == 1
                  ? 300
                  : 300
                : isReviewLearning && mod.length == 1
                ? grammar_init
                  ? 0
                  : 300
                : 0
            }
            unmountOnExit
            onExit={node => {
              if (isReviewLearning && mod.length == 1) {
                node.style.visibility = 'hidden';
              } else {
                setTimeout(() => {
                  node.style.visibility = 'hidden';
                }, 300);
              }
            }}
            onEnter={(node: any) => {
              if (isReviewLearning && mod.length == 1) {
                node.style.visibility = 'visible';
              } else {
                node.style.visibility = 'visible';
              }
            }}
          >
            <TabStyleSAWrap
              className='grammar-custom-scrollbar'
              id='responsive-wrapper'
              sx={
                mobileFocus
                  ? {
                      backgroundColor: '#fff',
                      position: 'absolute',
                      width: 'calc(100% - 22.56%)',
                      padding: '0px 4.6464%',
                    }
                  : undefined
              }
            >
              <QuestionText
                sx={{
                  marginBottom: '3vh',
                  minHeight: '7vh',
                  fontSize: `${FONT_STD.HEAD * font_level}vw`,
                  // padding: '0 7vw',
                  padding: '0 3vw',
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: question as string }}></span>
              </QuestionText>
              {img ? (
                <ImgRow>
                  <Img sx={{ width: `${imgWidth}vw` }} src={`${NG_CDN}/lecture_img/${img}`}></Img>
                </ImgRow>
              ) : null}
              {sa_type != '2' ? (
                <PassageRow
                  sx={{
                    marginBottom: '3vh',
                    marginTop: '0',
                    fontSize: `${FONT_STD.MAIN * font_level}vw`,
                    position: 'relative',
                    left: '-2vw',
                  }}
                >
                  <span
                    style={{ fontFamily: 'Apple SD Gothic Neo', fontWeight: '400', fontSize: 'inherit' }}
                    dangerouslySetInnerHTML={{ __html: passage }}
                  ></span>
                </PassageRow>
              ) : null}
              {sa_type == '0' ? (
                <TypeRow>
                  <TypeWrap>
                    <Type0Box sx={{ marginTop: img ? '-2vh' : '-12.05vh' }}>
                      {isScoring ? (
                        <Box
                          sx={{
                            fontSize: `${FONT_STD.STD * font_level}vw`,
                            fontFamily: 'Apple SD Gothic Neo',
                            fontWeight: 'normal',
                            '& > span': {
                              fontFamily: 'Apple SD Gothic Neo',
                              fontSize: `${FONT_STD.STD * font_level}vw`,
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: createResultWrongData(type0Value, rightanswer) }}
                        ></Box>
                      ) : (
                        <Type0Input
                          id='wt0'
                          onBlur={is_mobile ? () => check_blur() : blurInput_0}
                          onPaste={forbiddenPaste}
                          onKeyDown={is_mobile ? undefined : keydownKeyboard_0}
                          onKeyUp={is_mobile ? keyupKeyobard : keyupKeyboard_0}
                          onInput={is_mobile ? touchKeyboard0 : undefined}
                          onFocus={is_mobile ? () => check_focus() : undefined}
                          autoComplete='off'
                          sx={{ fontSize: `${FONT_STD.STD * font_level}vw` }}
                        />
                      )}
                    </Type0Box>
                  </TypeWrap>
                </TypeRow>
              ) : sa_type == '1' ? (
                <TypeRow>
                  <Type1Wrap>
                    <Type1Box>
                      {isScoring ? (
                        <Box
                          sx={{
                            fontSize: `${FONT_STD.STD * font_level}vw`,
                            fontFamily: 'Inconsolata',
                            fontWeight: 'normal',
                            lineHeight: '3.42vw',
                            '& > span': {
                              fontFamily: 'Inconsolata',
                              fontSize: `${FONT_STD.STD * font_level}vw`,
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: createResultWrongData(is_mobile ? curAnswer.join('') : answer, rightanswer),
                          }}
                        ></Box>
                      ) : is_mobile ? (
                        <>
                          <Type1Input
                            id='wti'
                            autoComplete='off'
                            autoCorrect='off'
                            type='text'
                            sx={{ fontSize: `${FONT_STD.STD * font_level}vw` }}
                            onInput={touchKeyboard1}
                            onKeyDown={keydownKeyboard}
                            onKeyUp={keyupKeyobard}
                            onBlur={blurMobile}
                            onFocus={() => check_focus()}
                          />
                          <Type1ValueBox
                            onClick={focusInput}
                            sx={{
                              fontSize: `${FONT_STD.STD * font_level}vw`,
                              fontWeight: '400',
                              span: {
                                '&.q.current_pos': {
                                  borderLeft: mobileFocus ? '1px solid #858585' : 'unset',
                                  animation: mobileFocus ? 'fadein 0.75s infinite' : '',
                                },
                              },
                            }}
                            dangerouslySetInnerHTML={{ __html: mobileCurAnswer }}
                          ></Type1ValueBox>
                        </>
                      ) : (
                        <Type1Textarea
                          onKeyDown={keydownKeyboard_1}
                          onKeyUp={keyupKeyboard}
                          onBlur={blurInput_1}
                          onPaste={forbiddenPaste}
                          spellCheck={false}
                          className='Inconsolata'
                          autoComplete='off'
                          id='wti'
                          sx={{ fontSize: `${FONT_STD.STD * font_level}vw` }}
                        />
                      )}
                    </Type1Box>
                  </Type1Wrap>
                </TypeRow>
              ) : (
                <Type2Row sx={{ minHeight: img ? '30vh' : '60vh' }}>
                  <Type2Wrap sx={{ marginTop: img ? '-3vh' : '-20vh', height: img ? 'auto' : '100%' }}>
                    <Type2Box sx={{ marginTop: img ? '-2vh' : '-12.05vh', alignItems: 'center' }}>
                      {isScoring ? (
                        <Box
                          sx={{
                            fontSize: `${FONT_STD.STD * font_level + 0.5}vw`,
                            fontFamily: 'Apple SD Gothic Neo',
                            fontWeight: 'normal',
                            textAlign: 'center',
                            lineHeight: '1.5',
                            '& > span:not(.g_kor_span)': {
                              fontFamily: 'Apple SD Gothic Neo',
                              fontSize: `${FONT_STD.STD * font_level + 0.5}vw`,
                            },
                            '& > span.g_kor_span': {
                              fontFamily: 'Apple SD Gothic Neo',
                              fontSize: `80% !important`,
                            },
                            ' .underline-under': {
                              textDecoration: 'underline',
                              textUnderlinePosition: 'under',
                              textDecorationThickness: 'from-font',
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: createType2ResultWrongData() }}
                        ></Box>
                      ) : (
                        <Type2Quetion
                          sx={{ fontSize: `${FONT_STD.STD * font_level + 0.5}vw` }}
                          dangerouslySetInnerHTML={{ __html: passage }}
                        />
                      )}
                    </Type2Box>
                  </Type2Wrap>
                </Type2Row>
              )}
            </TabStyleSAWrap>
          </CSSTransition>
        </SwitchTransition>
      </SAWrap>
    </StyledGrammarSAWrap>
  );
}

export default GrammarSA;
