import { Box, styled } from '@mui/material';
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingState, setPercent } from 'recoil/common/loading';
import { learningState } from 'recoil/model/learning';
import { d_flex_center, dir_column } from 'styles/common';
import theme from 'theme';
import { StyledIconButton } from './GrammarMC';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import OXMark from 'components/common/Study/OXMark';
import { settingState } from 'recoil/model/settings';
import { FONT_STD, NG_CDN } from 'utils/constants';
import { StyledCommentBox } from './GrammarSA';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { EffectSoundContext } from 'provider/EffectSoundProvider';
import { ModalContext } from 'provider/ModalProvider';

const StyledGrammarCABWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
}));

export const StyledGrammarModuleWrap = styled(Box)(props => ({
  ...d_flex_center,
  ...dir_column,
  width: '100%',
  height: '100%',
  padding: '0 6%',
}));

const StyledQuestionText = styled(Box)(props => ({
  ...d_flex_center,
  fontWeight: '600',
  width: '100%',
  marginBottom: '3vh',
}));

const StyledChoiceWrapper = styled(Box)(props => ({
  ...d_flex_center,
  width: '100%',
}));

const StyledChoiceWrap = styled(Box)(props => ({
  ...d_flex_center,
  width: '72vw',
  height: '13rem',
  gap: '2rem',
  fontWeight: '400',
}));

export const ImgRow = styled(Box)({
  ...d_flex_center,
  lineHeight: '1.5',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

const Img = styled('img')({
  marginBottom: '3vh',
  borderStyle: 'none',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

const StyledSelectedWordSection = styled(Box)(props => ({
  ...d_flex_center,
  height: '10vh',
  width: '100%',
  fontSize: '1.8vw',
  borderTop: 'thin solid #edeeef!important',
}));

interface GrammarCABProps {
  content: GrammarContentType;
  sendResultsheet: (resultsheet: any) => void;
  isReviewLearning: boolean;
}

function GrammarCAB({ content, sendResultsheet, isReviewLearning }: GrammarCABProps) {
  const { modal_alert } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const [animationDirection, setAnimationDirection] = useState('g-horizontal-right');
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const [disableMove, setDisableMove] = useState<boolean>(false);
  const [visibleOX, setVisibleOX] = useState<boolean>(false);
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const selectedRefWord = useRef<string | null>('');
  selectedRefWord.current = selectedWord;
  const rightAnswerRef = useRef<string | null>('');
  const settingStateData = useRecoilValue<SettingsType>(settingState);
  const [learningStart, setLearningStart] = useState(false);
  const [right, setRight] = useState<boolean>(false);
  const isGoNextRef = useRef(false);
  const {
    mod,
    modules,
    current_step,
    current_page,
    show_modal,
    resultsheet,
    current_module_settings,
    use_image,
    learning_type,
    direction,
    grammar_contents,
    font_level,
    grammar_init,
  } = learningStateData;
  const isReviewAdvanced = isReviewLearning && content.comment;

  const content_ref = useRef<any>(content);
  content_ref.current = content;
  const current_step_ref = useRef(current_step);
  current_step_ref.current = current_step;
  const resultsheet_ref = useRef(resultsheet);
  resultsheet_ref.current = resultsheet;
  const grammar_init_ref = useRef(grammar_init);
  grammar_init_ref.current = grammar_init;

  useEffect(() => {
    initLearningStart();
    setLearningStart(false);
    setTimeout(() => {
      setLearningStart(true);
    }, 300);
    bindKeyboard();
    return () => {
      unbindKeyboard();
    };
  }, []);

  useEffect(() => {
    setLearningStart(false);
    setTimeout(() => {
      setLearningStart(true);
    }, 800);
  }, [current_page]);

  useEffect(() => {
    // console.log(learningStart, 'learningStart');
  }, [learningStart]);

  useEffect(() => {
    initLearningStart();
  }, [grammar_contents]);

  useEffect(() => {
    if (settingStateData.enable_keyboard) bindKeyboard();
    return () => {
      if (settingStateData.enable_keyboard) unbindKeyboard();
    };
  }, [selectedWord, disableMove, right, visibleOX]);

  useEffect(() => {
    rightAnswerRef.current = content.rightanswer;
  }, [content]);

  useEffect(() => {
    if (percent == 100 && grammar_init_ref.current) {
      let alert_type = '';
      if (learning_type == 7) {
        alert_type += 'grammar_t';
        if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        }
      } else if (learning_type == 8) {
        alert_type += 'grammar_b';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        } else if (current_page == 3) {
          alert_type += '_3';
        }
      } else if (learning_type == 9) {
        alert_type += 'grammar_d';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        }
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      setTimeout(() => {
        modal_alert.openModalAlert(alert_type, undefined, undefined, () => {
          setLearningStateData(prevState => ({
            ...prevState,
            grammar_init: false,
            show_modal: false,
          }));
        });
      }, 300);
    }
  }, [percent]);

  const initLearningStart = () => {
    if (isReviewLearning && current_step !== 0 && modules.length == 1) return false;
    setPercent(setLoadingStateData, 100);
  };

  const selectWord = (word: string) => {
    //! 선택지 선택
    playEffectSound('click');
    setSelectedWord(word);
  };

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (!visibleOX) {
      if (e.keyCode == 13) {
        goNext();
      }
    } else {
      if (isReviewAdvanced && !right) {
        if (e.keyCode == 13 && !disableMove) {
          goNextProcess();
        }
      }
    }
  };

  const goNext = async () => {
    if (isGoNextRef.current) return;
    isGoNextRef.current = true;
    let right = false;

    if (selectedRefWord.current !== null && rightAnswerRef.current !== null) {
      right = selectedRefWord.current === rightAnswerRef.current;
    }
    setRight(right);

    if (settingStateData.check_type) {
      setVisibleOX(true);
      playEffectSound(right ? 'correct' : 'wrong');
      if (!isReviewAdvanced || (isReviewAdvanced && right)) {
        setDisableMove(true);
      } else {
        setDisableMove(true);
        setTimeout(() => {
          setDisableMove(false);
        }, 3000);
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }

    if (selectedRefWord.current == null) {
      //! 아무것도 클릭 안 했을때 dom의 color 세팅
      const choose_a = document.getElementById('choose_a');
      const choose_b = document.getElementById('choose_b');
      const choose_slash = document.getElementById('choose_slash');

      if (choose_a && choose_b && choose_slash) {
        const a_text = choose_a.innerHTML;
        const b_text = choose_b.innerHTML;
        const slash_text = choose_slash?.innerHTML;
        if (content_ref.current.rightanswer == a_text) {
          choose_a.style.color = '#1776d1';
        } else {
          choose_a.style.color = '#eee';
        }

        if (content_ref.current.rightanswer == b_text) {
          choose_b.style.color = '#1776d1';
        } else {
          choose_b.style.color = '#eee';
        }

        choose_slash.style.color = '#eee';
      }
    }

    if (!isReviewLearning || (isReviewLearning && right)) {
      setTimeout(
        () => {
          goNextProcess();
        },
        process.env.NODE_ENV === 'development' ? 1000 : 3000,
      );
    }
  };

  const goNextProcess = async () => {
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet_ref.current));
    resultsheet_tmp[current_step_ref.current] = {
      no: current_step_ref.current + 1,
      word_id: content_ref.current['id'],
      question: content_ref.current.question,
      selectedWord: selectedRefWord.current,
      rightanswer: content_ref.current.rightanswer,
      right: selectedRefWord.current === rightAnswerRef.current,
      origin_passage: content_ref.current.origin_passage,
      passage: content_ref.current.passage,
      choose_a: content_ref.current.choose_a,
      choose_b: content_ref.current.choose_b,
      type: 'CAB',
    };

    // if (selectedRefWord.current == null) {
    //   //! 아무것도 클릭 안 했을때 dom의 color 세팅
    //   const choose_a = document.getElementById('choose_a');
    //   const choose_b = document.getElementById('choose_b');
    //   const choose_slash = document.getElementById('choose_slash');

    //   if (choose_a && choose_b && choose_slash) {
    //     const a_text = choose_a.innerHTML;
    //     const b_text = choose_b.innerHTML;
    //     const slash_text = choose_slash?.innerHTML;
    //     if (content_ref.current.rightanswer == a_text) {
    //       choose_a.style.color = '';
    //     } else {
    //       choose_a.style.color = '';
    //     }

    //     if (content_ref.current.rightanswer == b_text) {
    //       choose_b.style.color = '';
    //     } else {
    //       choose_b.style.color = '';
    //     }

    //     choose_slash.style.color = '';
    //   }
    // }

    if (current_step_ref.current == grammar_contents.length - 1) {
      setVisibleOX(false);
      setDisableMove(false);
      setSelectedWord(null);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      }, 0);

      setTimeout(() => {
        sendResultsheet(resultsheet_tmp);
      }, 1);
    } else {
      setDisableMove(false);
      setSelectedWord(null);

      if (isReviewLearning && mod.length == 1) {
        setVisibleOX(false);
      } else {
        setTimeout(() => {
          setVisibleOX(false);
        }, 300);
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
        current_step: current_step_ref.current + 1,
        resultsheet: resultsheet_tmp,
      }));
    }
    isGoNextRef.current = false;
  };

  const setColor = (char: 'a' | 'b' | '/') => {
    let res = '';
    if (!selectedWord) {
      res = '#4a31ff';
    } else {
      if (char === '/') {
        res = visibleOX && !right ? '#ff4c39' : '#eee';
      } else {
        const isSelected = content[`choose_${char}`] === selectedWord;
        if (visibleOX) {
          res = right ? (isSelected ? '#1776d1' : '#eee') : isSelected ? '#ff4c39' : '#1776d1';
        } else {
          res = isSelected ? '#4a31ff' : '#eee';
        }
      }
    }

    return res;
  };

  const imgWidth = useMemo(() => {
    switch (font_level) {
      case 0.6:
        return 44;
      case 0.8:
        return 46.75;
      case 1:
        return 49.5;
      case 1.2:
        return 52.25;
      case 1.4:
        return 55;
    }
  }, [font_level]);

  return (
    <StyledGrammarCABWrap
      sx={{
        padding: content.sub_unit === 't1' ? '0' : '0 6%',
        ' #ox-wrapper > div': {
          top: isReviewAdvanced
            ? '14.1vh !important'
            : content.sub_unit == 't1'
            ? 'calc((100vh - 4rem - 5rem - 4rem) * 0.5)'
            : '15vh !important',
        },
      }}
    >
      <Box
        sx={{
          display: learningStart ? 'none' : isReviewLearning && mod.length == 1 ? 'block' : 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: '#fff',
          zIndex: '1',
        }}
      ></Box>
      <OXMark show={visibleOX} right={right} grammar={true}></OXMark>
      {content.comment && visibleOX && isReviewLearning ? (
        <StyledCommentBox sx={{ position: 'absolute !important', top: '61vh', fontFamily: 'Apple SD Gothic Neo' }}>
          {content.comment}
        </StyledCommentBox>
      ) : null}
      <StyledIconButton
        disabled={disableMove}
        onClick={isReviewLearning && visibleOX && !right ? () => goNextProcess() : () => goNext()}
        sx={{ right: '16px' }}
        className={`${isReviewLearning && visibleOX && !right ? 'blink-right-button' : ''}`}
        disableRipple
      >
        <AiOutlineRight />
      </StyledIconButton>
      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={`${current_page}-${current_step}-${content.type}`}
          classNames={animationDirection}
          timeout={learningStart ? 300 : isReviewLearning && mod.length == 1 ? (grammar_init ? 0 : 300) : 0}
          onExit={node => {
            if (isReviewLearning && mod.length == 1) {
              node.style.visibility = 'hidden';
            } else {
              setTimeout(() => {
                node.style.visibility = 'hidden';
              }, 300);
            }
          }}
          onEnter={(node: any) => {
            if (isReviewLearning && mod.length == 1) {
              node.style.visibility = 'visible';
            } else {
              node.style.visibility = 'visible';
            }
          }}
        >
          <StyledGrammarModuleWrap>
            <Box sx={{ width: '100%', height: '70vh', overflow: 'hidden auto' }} className='grammar-custom-scrollbar'>
              <StyledQuestionText
                sx={{
                  padding: '0 7vw',
                  minHeight: '7vh',
                  lineHeight: '1.5',
                  fontSize: `${FONT_STD.HEAD * font_level}vw`,
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: content.question ? content.question : '' }}></span>
              </StyledQuestionText>
              {content.img ? (
                <ImgRow>
                  <Img sx={{ width: `${imgWidth}vw` }} src={`${NG_CDN}/lecture_img/${content.img}`}></Img>
                </ImgRow>
              ) : null}
              <StyledChoiceWrapper sx={{ minHeight: content.img ? '30vh' : '60vh' }}>
                <StyledChoiceWrap
                  sx={{
                    fontSize: `${FONT_STD.MAIN * font_level + 0.5}vw`,
                    marginTop: content.img ? '0' : '-20vh',
                    backgroundColor: !isReviewLearning ? '#fcfaff' : 'white',
                    border: 'none',
                  }}
                >
                  <Box sx={{ textAlign: 'center', lineHeight: '1.8' }}>
                    {content.passage?.split('<br />').map((sen, sen_key) => (
                      <Fragment key={`passage-${sen_key}`}>
                        {sen_key !== 0 && <br key={`passage-br-${sen_key}`} />}
                        {sen.charAt(0) === '<' ? (
                          <span dangerouslySetInnerHTML={{ __html: sen }}></span>
                        ) : (
                          Array.from(sen).map((char, char_key) => (
                            <Fragment key={`passage-span-${sen_key}-${char_key}`}>
                              {char === 'ⓐ' ? (
                                <Box
                                  component={'strong'}
                                  color={setColor('a')}
                                  onClick={() => selectWord(content.choose_a ? content.choose_a : '')}
                                  sx={{ cursor: 'pointer' }}
                                  id={`choose_a`}
                                >
                                  {content.choose_a}
                                </Box>
                              ) : char === 'ⓑ' ? (
                                <Box
                                  component={'strong'}
                                  color={setColor('b')}
                                  onClick={() => selectWord(content.choose_b ? content.choose_b : '')}
                                  sx={{ cursor: 'pointer' }}
                                  id={`choose_b`}
                                >
                                  {content.choose_b}
                                </Box>
                              ) : char === '/' ? (
                                <Box component={'b'} color={setColor('/')} id={`choose_slash`}>
                                  {' / '}
                                </Box>
                              ) : (
                                <span>{char}</span>
                              )}
                            </Fragment>
                          ))
                        )}
                      </Fragment>
                    ))}
                  </Box>
                </StyledChoiceWrap>
              </StyledChoiceWrapper>
            </Box>
            {isReviewLearning ? (
              <StyledSelectedWordSection
                color={!visibleOX && selectedWord ? '#4a31ff' : visibleOX && !right ? '#ff4c39' : '#1776d1'}
              >
                {visibleOX && !right ? content.rightanswer : selectedWord}
              </StyledSelectedWordSection>
            ) : null}
          </StyledGrammarModuleWrap>
        </CSSTransition>
      </SwitchTransition>
    </StyledGrammarCABWrap>
  );
}

export default GrammarCAB;
