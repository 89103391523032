import {
  RadioGroup,
  FormControl,
  Radio,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '28rem',
  height: 'auto',
};

const StyledFormControl = styled(FormControl)({
  borderRadius: '8px',
  padding: '2.5rem 1.75rem 2rem 2.75rem',
  width: '100%',
  maxWidth: '500px',
  margin: 'auto',
  backgroundColor: 'var(--white)',
  '&:focus-visible': {
    outline: 'none',
  },
});

const StyledFormLabel = styled(FormLabel)({
  color: 'var(--black)',
  fontSize: '1.125rem',
  marginBottom: '1em',
  fontWeight: '700',
  '&.Mui-focused': {
    color: 'var(--black)',
  },
  span: {
    fontWeight: '500',
    fontSize: '0.9rem',
    color: 'var(--gray-2)',
  },
});

const StyledRadioGroup = styled(RadioGroup)(props => ({
  paddingRight: '2.125rem',
  width: '100%',
  maxHeight: '220px',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'block',
}));

const StyledFormGroup = styled(FormGroup)(props => ({
  paddingRight: '2.125rem',
  width: '100%',
  maxHeight: '220px',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'block',
}));

const StyledFormControlLabel = styled(FormControlLabel)(props => ({
  width: '100%',
  color: 'var(--gray-1)',
  marginTop: '0.5rem',
  '&.MuiFormControlLabel-root': {
    marginRight: '1rem',
    marginLeft: '1rem',
  },
  '& .MuiTypography-root': {
    display: 'flex',
    marginBottom: '0.1rem',
    fontSize: '1.125rem',
    marginRight: 'auto',
  },
}));

const StyledRadio = styled(Radio)(props => ({
  '&.MuiRadio-root': {
    padding: '7px',
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--gray-1)',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      color: props.theme.palette.purple.main,
    },
  },
}));

const StyledCheckbox = styled(Checkbox)(props => ({
  '&.MuiCheckbox-root': {
    padding: '7px',
  },
}));

const StyledButton = styled(Button)(props => ({
  margin: '1rem calc(1.215rem - 16px + 2px) 0px auto',
  fontSize: '1.125rem',
  alignItems: 'start',
  justifyContent: 'center',
  width: '10%',
  height: '2.5rem',
  transition: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

function ModalSelect(props: ModalSelectFormProps) {
  const [value, setValue] = useState<ModalSelectContentsType[]>([]);
  const [contents, setContents] = useState<ModalSelectContentsType[]>([]);
  const [oldValue, setOldValue] = useState<ModalSelectContentsType[]>([]);
  const visible = props.visible;
  const title = props.data?.title;
  const subtitle = props.data?.subtitle;
  const type = props.data?.type;
  const selected_data = props.data?.selectedData;
  const old_value = props.currentInput;
  const changeValue = props.onChange;
  const offModal = props.onClick;
  const onCancel = props.onCancel;

  const getDefaultValue = (): string => {
    if (selected_data) {
      const default_value = contents.find(item => item.name === selected_data);
      if (default_value) {
        return default_value.id as string;
      } else {
        return 'etc';
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (visible) {
      const tmp_contents = [...props.data.contents];
      const tmp_old_value = [...old_value];

      if (type == 'checkbox') {
        const idx_arr: number[] = [];
        for (const item of tmp_old_value) {
          const check_idx = tmp_contents.findIndex(content => content.id == item.id);
          if (Number(check_idx) >= 0) {
            idx_arr.push(Number(check_idx));
          }
        }

        const find_all_idx = tmp_contents.findIndex(item => item.id == 'all');
        if (Number(find_all_idx) >= 0 && tmp_old_value.length == tmp_contents.length - 1) {
          idx_arr.push(find_all_idx);
          tmp_old_value.push({ id: 'all', name: tmp_contents[find_all_idx].name });
        }

        setContents([
          ...tmp_contents.map((contents_item, contents_idx) => {
            return {
              ...contents_item,
              checked: idx_arr.includes(contents_idx),
            };
          }),
        ]);
      } else {
        setContents([...tmp_contents]);
      }

      setOldValue([...tmp_old_value]);
      setValue([...tmp_old_value]);
    } else {
      setContents([]);
      setValue([]);
    }
  }, [visible]);

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;

    const find_item = contents.find(item => item.id == id);
    if (find_item) {
      setValue([find_item]);
    }
  };

  const onChangeCheckbox = (check: boolean, i: number) => {
    const val = !check;
    let tmp_value = value;
    const tmp_contents = [...contents];

    tmp_contents[i].checked = val;
    if (!val) {
      if (contents[i].id == 'all') {
        tmp_value = [];
        for (const content of tmp_contents) {
          content.checked = false;
        }
      } else {
        const find_target = tmp_value.findIndex(item => item.id == contents[i].id);

        if (Number(find_target) >= 0) {
          tmp_value.splice(find_target, 1);
        }

        if (tmp_value.length < contents.length) {
          const find_idx = tmp_value.findIndex(item => item.id == 'all');
          if (Number(find_idx) >= 0) {
            tmp_value.splice(find_idx, 1);

            const find_content_idx = contents.findIndex(item => item.id == 'all');
            if (Number(find_content_idx) >= 0) {
              tmp_contents[find_content_idx].checked = false;
            }
          }
        }
      }
    } else {
      if (contents[i].id == 'all') {
        tmp_value = [...contents.map(item => ({ id: item.id, name: item.name }))];

        for (const content of tmp_contents) {
          content.checked = true;
        }
      } else {
        tmp_value.push({ id: contents[i].id, name: contents[i].name });

        if (tmp_value.length == contents.length - 1) {
          const find_idx = contents.findIndex(item => item.id == 'all');
          if (Number(find_idx) >= 0) {
            tmp_value.push({ id: contents[find_idx].id, name: contents[find_idx].name });
            tmp_contents[find_idx].checked = true;
          }
        }
      }
    }

    setValue([
      ...tmp_value.map(item => {
        return {
          ...item,
        };
      }),
    ]);

    setContents([...tmp_contents]);
  };

  const onClick = () => {
    if (checkValue()) {
      const tmp_value = [...value];

      const check_all_idx = tmp_value.findIndex(item => item.id == 'all');
      if (Number(check_all_idx) >= 0) tmp_value.splice(check_all_idx, 1);
      changeValue(tmp_value);
    } else {
      if (onCancel) {
        onCancel();
      }
    }

    if (value[0] && (value[0].id == 'modify' || value[0].id == 'delete') && value[0].item) {
      offModal(true);
    } else {
      offModal();
    }
  };
  const checkValue = () => {
    if (type == 'radio') {
      if (oldValue.length > 0 && value.length > 0) {
        if (oldValue[0].id == value[0].id) return false;
        else return true;
      } else {
        if (value.length > 0) return true;
        else return false;
      }
    } else if (type == 'checkbox') {
      if (oldValue.length > 0 && value.length > 0) {
        if (oldValue.length == value.length) {
          let check_cnt = 0;
          for (const val of value) {
            const old_idx = oldValue.findIndex(old => old.id == val.id);
            if (Number(old_idx) >= 0) {
              check_cnt++;
            } else {
              return true;
            }
          }

          if (check_cnt == value.length) {
            return false;
          }
          return false;
        } else {
          return true;
        }
      } else {
        if (oldValue.length > 0) {
          return true;
        } else {
          if (value.length > 0) return true;
          else return false;
        }
      }
    }
  };

  return (
    <Modal
      open={visible}
      sx={{
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Box sx={style}>
        <StyledFormControl>
          <StyledFormLabel>
            <Box>
              {title} <Box component={'span'}>{subtitle}</Box>
            </Box>
          </StyledFormLabel>
          {type === 'radio' ? (
            <StyledRadioGroup onChange={onChangeRadio} defaultValue={getDefaultValue()}>
              {contents?.map((v, i) => (
                <StyledFormControlLabel
                  key={v.id}
                  style={{ pointerEvents: 'none' }}
                  value={v.id}
                  label={v.name}
                  labelPlacement='start'
                  control={<StyledRadio style={{ pointerEvents: 'auto' }} disableRipple={true} color='green' />}
                  sx={{ span: { pointerEvents: 'auto' } }}
                />
              ))}
            </StyledRadioGroup>
          ) : (
            <StyledFormGroup>
              {contents?.map((v, i) => (
                <StyledFormControlLabel
                  key={i}
                  style={{ pointerEvents: 'none' }}
                  label={v.name}
                  labelPlacement='start'
                  control={
                    <StyledCheckbox
                      checked={v.checked}
                      onChange={() => onChangeCheckbox(v.checked == true, i)}
                      name={v.name}
                      style={{ pointerEvents: 'auto' }}
                      color='green'
                    />
                  }
                  sx={{ span: { pointerEvents: 'auto' } }}
                />
              ))}
            </StyledFormGroup>
          )}
          <StyledButton onClick={onClick} disableRipple>
            {checkValue() ? (
              <Box component={'span'} sx={{ color: 'purple.main' }}>
                확인
              </Box>
            ) : (
              <Box component={'span'} sx={{ color: 'black.main' }}>
                취소
              </Box>
            )}
          </StyledButton>
        </StyledFormControl>
      </Box>
    </Modal>
  );
}

export default ModalSelect;
