import React, { useEffect, useState, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { learningState } from '../../../recoil/model/learning';
import { ModalContext } from '../../../provider/ModalProvider';
import { styled, Box, Button, BoxProps, IconButton } from '@mui/material';
import '../../../assets/scss/index.scss';
import ModalSettings from '../../modal/ModalSettings';
import ModalWordlist from '../../modal/ModalWordlist';
import { d_flex_center, d_flex_end, d_flex_start } from '../../../styles/common';
import { FLASHCARD, MODULES_LABEL } from '../../../utils/constants';
import { loadingState, setPercent } from '../../../recoil/common/loading';
import { userState } from '../../../recoil/model/user';
import { closeModalSelectCard, modalSelectCardData, openModalSelectCard } from '../../../recoil/common/modalSelectCard';
import { openTutorial, tutorialStateData } from '../../../recoil/common/tutorial';

const StyledStepper = styled(Box)(props => ({
  width: '100%',
  height: '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledStepperLeftBox = styled(Box)(props => ({
  width: '15%',
  height: '100%',
  paddingLeft: '16px',
  ...d_flex_start,
}));

const StyledStepperCenterBox = styled(Box)(props => ({
  width: '70%',
  height: '100%',
  flexBasis: 'auto',
  ...d_flex_center,
}));

const StyledStepperRightBox = styled(Box)(props => ({
  width: '15%',
  height: '100%',
  paddingRight: '16px',
  ...d_flex_end,
}));

const StyledSettingButton = styled(Button)(props => ({
  width: '9rem',
  height: '3.528rem',
  backgroundColor: '#f7f7f7',
  color: '#636363',
  boxShadow: 'none',
  fontSize: '1.2rem',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#f7f7f7',
    color: '#636363',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: '#f7f7f7',
    color: '#636363',
    boxShadow: 'none',
  },
}));

interface StepBox extends BoxProps {
  type: 'before' | 'after' | 'now';
}

const StyledStepBox = styled(Box)<StepBox>(props => ({
  width: '100%',
  height: '3.528rem',
  lineHeight: '1.375',
  fontSize: '1.26rem',
  padding: '6px 16px',
  borderRadius: '8px',
  ...d_flex_center,
  backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
  color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
  cursor: 'pointer',
  textAlign: 'center',
  marginLeft: '4px',
  marginRight: '4px',
  boxShadow: 'none',
  fontWeight: props.type == 'after' ? '500' : '700',
  '&:hover': {
    backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
    color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
    color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
    boxShadow: 'none',
  },
}));

interface CounterBox extends BoxProps {
  type: string;
}

const StyledStepCounter = styled(Box)<CounterBox>(props => ({
  width: '9rem',
  height: '3.528rem',
  backgroundColor: '#f7f7f7',
  position: 'relative',
  color: '#636363',
  boxShadow: 'none',
  fontSize: '1.2rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: props.type == 'button' ? 'space-between' : 'center',
  padding: '6px 0.8rem',
  minWidth: '64px',
  lineHeight: '1.925',
  borderRadius: '8px',
  cursor: props.type == 'button' ? 'pointer' : 'default',
  '&::after': {
    display: props.type == 'button' ? 'inline-block' : 'none',
    verticalAlign: '0.255em',
    content: '""',
    borderTop: '0.3em solid',
    borderRight: '0.3em solid transparent',
    borderBottom: '0',
    borderLeft: '0.3em solid transparent',
  },
  '&::before': {
    display: props.type == 'button' ? 'inline-block' : 'none',
    verticalAlign: '0.255em',
    content: '""',
    color: 'transparent',
    borderTop: '0.3em solid',
    borderRight: '0.3em solid transparent',
    borderBottom: '0',
    borderLeft: '0.3em solid transparent',
  },
}));

const StyledCounter = styled(Box)(props => ({
  color: props.theme.palette.purple.main,
  fontSize: '1.4rem',
  lineHeight: '0',
}));

function Stepper() {
  const setTutorialState = useSetRecoilState(tutorialStateData);
  const userStateData = useRecoilValue(userState);
  const [modalSelectCard, setModalSelectCard] = useRecoilState(modalSelectCardData);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const [visibleSettings, setVisibleSettings] = useState(false);
  const [visibleWordlist, setVisibleWordlist] = useState(false);
  const { modal_disable, learning_type, current_step, contents, current_page, mod, show_modal } = learningStateData;
  const { visible: visibleSelectCard } = modalSelectCard;

  const contents_leng =
    learning_type && learning_type > 2 && mod[current_page].module == 2 && contents.length > 15 ? 15 : contents.length;

  const choiceSettingCard = (idx: number) => {
    if (idx == 0) {
      // 튜토리얼 start
      openTutorial({ setTutorialState }, { reopen: true });
      closeModalSelectCard({ setModalSelectCard });
    } else if (idx == 1) {
      // 설정창 start
      setVisibleSettings(true);
      closeModalSelectCard({ setModalSelectCard });
    }
  };

  const closeModalSelectCardCallback = () => {
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: false,
    }));
  };

  const openModalSettings = () => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }
    if (!modal_disable) {
      openModalSelectCard(
        { setModalSelectCard },
        { type: 't_s', onSuccess: choiceSettingCard, onClose: closeModalSelectCardCallback },
      );
    }
  };

  const counterType = () => {
    if (mod[current_page].module == FLASHCARD && learningStateData.study_method == 0) return 'button';
    else return 'box';
  };

  const setCurrentStep = (step: number) => {
    if (mod[current_page].module == FLASHCARD) {
      if (current_step < step) {
        setLearningStateData(prevState => ({
          ...prevState,
          direction: 'right',
        }));
      } else {
        setLearningStateData(prevState => ({
          ...prevState,
          direction: 'left',
        }));
      }
    }
    setTimeout(() => {
      setLearningStateData(prevState => ({
        ...prevState,
        current_step: step,
      }));
    }, 1);
  };

  const setCurrentPage = (page: number) => {
    if (page == current_page || learningStateData.study_method == 1 || show_modal || modal_disable) return false;

    if (mod[page].done == 1) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      modal_alert.openModalAlert('module_move_unable', undefined, undefined, () => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      });
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      const mods = JSON.parse(JSON.stringify(mod));
      mods[current_page].step = current_step;
      mods[current_page].resultsheet = learningStateData.resultsheet;

      if (mods[current_page].module == 2) {
        mods[current_page].bingo_card = learningStateData.bingo_card;
      }

      const modal_content = mod[page].module > 3 ? 'move_study_module2' : 'move_study_module';

      modal_confirm.openModalConfirm(
        'normal',
        modal_content,
        () => {
          setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
          setLearningStateData(prevState => ({
            ...prevState,
            mod: mods,
            resultsheet: [],
            current_page: page,
            current_step: mods[page].step ?? 0,
            show_modal: false,
          }));
        },
        undefined,
        MODULES_LABEL[mod[page].module],
      );
    }
  };

  const onClickStepCounter = (type: string) => {
    if (type === 'button' && !show_modal && !modal_disable) setVisibleWordlist(true);
  };

  useEffect(() => {
    if (visibleSelectCard) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  }, [visibleSelectCard]);

  useEffect(() => {
    if (visibleSettings) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  }, [visibleSettings]);

  useEffect(() => {
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: visibleWordlist ? true : false,
    }));
  }, [visibleWordlist]);

  return (
    <StyledStepper>
      <StyledStepperLeftBox>
        {learning_type && [3, 4].includes(learning_type) ? (
          <StyledSettingButton variant='contained' disableRipple onClick={() => openModalSettings()}>
            설정
          </StyledSettingButton>
        ) : null}
      </StyledStepperLeftBox>
      <StyledStepperCenterBox>
        {mod?.map((v, i) => (
          <StyledStepBox
            onClick={() => setCurrentPage(v.seq)}
            key={v.seq}
            type={v.seq == current_page ? 'now' : v.done == 1 ? 'before' : 'after'}
          >
            <Box sx={{ marginTop: '1px' }}>{MODULES_LABEL[v.module as ModuleIndex]}</Box>
          </StyledStepBox>
        ))}
      </StyledStepperCenterBox>
      <StyledStepperRightBox>
        <StyledStepCounter type={counterType()} onClick={() => onClickStepCounter(counterType())}>
          <Box>
            <StyledCounter component={'span'} sx={{ transform: 'translateY(-2px)' }}>
              {current_step + 1}
            </StyledCounter>
            &nbsp;/&nbsp;{contents_leng}
          </Box>
        </StyledStepCounter>
      </StyledStepperRightBox>
      <ModalSettings visible={visibleSettings} onClose={() => setVisibleSettings(false)} />
      <ModalWordlist
        visible={visibleWordlist}
        onClose={() => setVisibleWordlist(false)}
        onClick={(idx: number) => {
          setCurrentStep(idx);
        }}
        contents={contents}
        current_step={current_step}
      />
    </StyledStepper>
  );
}

export default Stepper;
