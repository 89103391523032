import { atom } from 'recoil';

export interface Step {
  step: number;
  completed: boolean;
  content: string;
}

export interface MissionType {
  benefit_first_img: string;
  benefit_last_img: string;
  complete_img: string;
  curr_step: number;
  title: string;
  step: Step[];
}

export const hiddenMissionState = atom<MissionType>({
  key: 'hiddenMission',
  default: {
    benefit_first_img: '',
    benefit_last_img: '',
    complete_img: '',
    curr_step: 0,
    title: '',
    step: [],
  },
});
