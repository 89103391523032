import { Box, styled } from '@mui/material';
import { d_flex_center, dir_column } from 'styles/common';
import theme from 'theme';

export const QuestionText = styled(Box)(props => ({
  ...d_flex_center,
  fontWeight: '600',
  width: '100%',
  lineHeight: '1.5',
}));

export const PassageRow = styled(Box)({
  backgroundColor: '#fcf9ff',
  padding: '1vh 2%',
  lineHeight: '1.4',
  // width: '100%',
  width: '72vw',
  margin: '3vh auto 0',
  fontSize: '1.6vw',
  minHeight: '12.11vh',
  textAlign: 'center',
  ...d_flex_center,

  ' .underline-under': {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: 'from-font',
  },
});
