import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { EffectSoundContext } from 'provider/EffectSoundProvider';
import { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { deviceState } from 'recoil/common/device';

const StyledAlertWrapper = styled(Box)<{ isOpen: boolean; is_mobile: boolean }>(props => ({
  position: 'absolute',
  top: '5.5vh',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: props.className === 'mobile' ? '0.5rem 2.5rem' : '0.8rem 2.5rem',

  backgroundColor: props.theme.palette.black_2.main,
  color: 'white',
  borderRadius: '2rem',
  opacity: props.isOpen ? 1 : 0,
  transition: !props.isOpen ? 'opacity 0.5s ease-in-out' : '',
}));

interface StudyModuleAlertProps {
  message: string;
  visible: boolean;
}

const StudyModuleAlert = ({ message, visible }: StudyModuleAlertProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, device_pause, screen_width, screen_height } = deviceStateData;
  const { playEffectSound } = useContext(EffectSoundContext);

  useEffect(() => {
    if (visible) {
      setIsOpen(true);
      playEffectSound('alert_video');

      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }
  }, [visible, message]);

  return (
    <StyledAlertWrapper
      className={screen_width < 1000 || is_mobile ? 'mobile' : ''}
      is_mobile={is_mobile}
      isOpen={isOpen}
    >
      {message}
    </StyledAlertWrapper>
  );
};

export default StudyModuleAlert;
