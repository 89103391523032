import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { Box, Input, SxProps, Theme } from '@mui/material';
import { d_flex_center } from '../../styles/common';

interface DropdownListProps {
  contents: { label: string; id: number }[];
  title: string;
  onClickItem: (idx: number, type?: string, index?: number) => void;
  className?: string;
  type?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  menuCenter?: boolean;
  useInput?: boolean;
  placeholder?: string;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  menuSize?: string;
  onClickButton?: () => void;
  onBlurInput?: () => void;
  onFocusInput?: () => void;
  onSelectInput?: (num: number) => void;
  inputId?: string;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(props => {
  return {
    '& .MuiPaper-root': {
      borderRadius: 6,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      width: '11rem',
      color: 'var(--black)',
      border: '1px solid var(--gray-2)',
      '& .MuiMenu-list': {
        padding: '4px 0',
        maxHeight: 'calc(15rem + 8px)',
        overflowY: 'auto',
        '& .MuiMenuItem-gutters': {
          ...d_flex_center,
        },
      },
      '& .MuiMenuItem-root': {
        fontSize: props['aria-label'] ? props['aria-label'] : '1rem',
        height: '2.75rem',
        padding: '0 1rem',

        '&.empty': {
          color: props.theme.palette.gray_1.main,
        },
      },
    },
  };
});

const StyledButton = styled(Button)(props => ({
  width: '11rem',
  height: '3rem',
  backgroundColor: 'var(--white)',
  padding: '0 1rem',
  borderRadius: '10px',
  borderColor: 'var(--white)',
  fontSize: '1rem',
  color: '#727272',
  textTransform: 'none',
  position: 'relative',
  ...d_flex_center,
  '& .MuiButton-endIcon': {
    position: 'absolute',
    right: '1rem',
  },
  '&:hover': {
    backgroundColor: 'var(--white)',
    borderColor: 'var(--white)',
  },
  svg: {
    width: '1.2rem',
    height: '1.2rem',
    marginTop: '-2px',
  },
  '& .overflow-title': {
    paddingRight: '1rem',
  },
}));

const ButtonInput = styled(Input)(({ theme }) => ({
  border: 'none',
  height: '100%',
  width: '80%',
  input: {
    width: '100%',
    border: 'none',
    padding: 0,
    height: '100%',
    fontSize: '1rem',
    color: '#727272',
  },
}));

function DropdownList(props: DropdownListProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const contents = props.contents;
  const title = props.title;
  const onClickItem = props.onClickItem;
  const className = props.className;
  const type = props.type;
  const disabled = props.disabled;
  const sx = props.sx;
  const menuCenter = props.menuCenter;
  const use_input = props.useInput;
  const placeholder = props.placeholder;
  const onChangeInput = props.onChangeInput;
  const id = props.id;
  const menu_size = props.menuSize;
  const onClickButton = props.onClickButton;
  const onSelectInput = props.onSelectInput;
  const onBlurInput = props.onBlurInput;
  const onFocusInput = props.onFocusInput;
  const input_id = props.inputId;

  const [currentContents, setCurrentContents] = useState<{ label: string; id: number }[]>([]);
  const [noContent, setNoContent] = useState(false);

  const checkTextOverflow = (dom: HTMLElement) => {
    if (dom.clientWidth >= dom.scrollWidth) {
      return false;
    }

    return true;
  };

  React.useLayoutEffect(() => {
    const title_dom = document.querySelector('#dropdown-list-title') as HTMLElement;

    if (checkTextOverflow(title_dom)) {
      title_dom.classList.add('overflow-title');
    }
  }, []);

  useEffect(() => {
    if (contents) {
      if (contents.length == 1) {
        if (contents[0].label == '나가기') {
          setCurrentContents([]);
          setNoContent(true);
        } else {
          setCurrentContents([...contents]);
          setNoContent(false);
        }
      } else {
        setCurrentContents([...contents]);
        setNoContent(false);
      }
    } else {
      setCurrentContents([]);
    }
  }, [contents]);

  function handleMenuClick(id: number, index: number) {
    if (type) {
      onClickItem(id, type);
    } else onClickItem(id, undefined, index);
    setAnchorEl(null);
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (noContent) return;
    if (onClickButton) onClickButton();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <>
      <StyledButton
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='outlined'
        disableElevation
        color='gray_1'
        onClick={handleClick}
        endIcon={noContent ? undefined : open ? <IoIosArrowUp /> : <IoIosArrowDown />}
        className={className}
        disableRipple
        disabled={disabled}
        sx={sx}
        style={{ cursor: noContent ? 'default' : 'pointer' }}
      >
        {use_input ? (
          <ButtonInput
            id={input_id}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onKeyUp={handleKeyUp}
            value={title}
            disableUnderline
            placeholder={placeholder ? placeholder : ''}
            onChange={onChangeInput}
            onBlur={onBlurInput}
            onSelect={(e: React.SyntheticEvent<HTMLDivElement, Event>) => {
              const event = e as React.ChangeEvent<HTMLInputElement>;
              if (event.target.selectionEnd) {
                onSelectInput ? onSelectInput(event.target.selectionEnd) : undefined;
              }
            }}
            onFocus={onFocusInput}
          />
        ) : title.length > 0 ? (
          <Box id='dropdown-list-title' sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {title}
          </Box>
        ) : (
          'Class'
        )}
      </StyledButton>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={className}
        about={menuCenter ? 'center' : ''}
        aria-label={menu_size}
      >
        {currentContents.length > 0 ? (
          currentContents.map((item, idx) => {
            return (
              <MenuItem onClick={() => handleMenuClick(item.id, idx)} disableRipple key={`menu_item_${idx}`}>
                {item.label}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem className='empty' disableRipple>
            배정된 클래스가 없습니다.
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
}

export default DropdownList;
