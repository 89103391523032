import * as React from 'react';
import { useContext } from 'react';
import ModalDefault from './ModalDefault';
import { ModalContext } from '../../provider/ModalProvider';

import { d_flex_center } from '../../styles/common';
import { styled, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { useRecoilState, useRecoilValue } from 'recoil';
import { learningState } from '../../recoil/model/learning';

declare let window: any;

const StyledModalAlertWrap = styled(Box)(props => ({
  backgroundColor: '#fff',
  borderRadius: '4px',
  overflow: 'hidden',
}));

const StyledModalAlert = styled(Box)(props => ({
  width: '35.7rem',
  maxWidth: '480px',
  borderRadius: '4px',
  padding: '2.4rem 1.2rem',
  backgroundColor: props.color === 'white' ? '#fff' : '#000',
  opacity: '0.75',
  [props.theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
  [props.theme.breakpoints.down('xxl')]: {
    fontSize: '1.2rem',
  },
  whiteSpace: 'pre-wrap',
}));

const StyledTitle = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  lineHeight: '2rem',
  [props.theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
  [props.theme.breakpoints.down('xxl')]: {
    fontSize: '1.2rem',
  },
  color: props.color === 'white' ? '#000 !important' : '#fff !important',
  caretColor: props.color === 'white' ? '#000 !important' : '#fff !important',

  '.sub_title': {
    fontWeight: '300',
    fontSize: '1.1rem',
    marginBottom: '-16px',
  },
}));

function ModalAlert() {
  const { modal_alert } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const location = useLocation();
  const [learningStateData, setLearningStateData] = useRecoilState(learningState);
  const { first_alert } = learningStateData;
  const color = modal_alert.data.color ? modal_alert.data.color : 'black';
  const study_modal_arr = [
    'memorize_start',
    'flashcard_start',
    'wordbingo_start',
    'popquiz_start',
    'dictation_start',
    'writing_start',
    'pc_speaking_start',
    'speaking_start',
    'speak_check_start',
    'speak_video_start',
    'speak_speaking_start',
    'video_learning_start',
    'grammar_t_1',
    'grammar_t_2',
    'grammar_b_0',
    'grammar_b_1',
    'grammar_b_2',
    'grammar_b_3',
    'grammar_b_r',
    'grammar_d_0',
    'grammar_d_1',
    'grammar_d_r',
  ];

  React.useEffect(() => {
    if (modal_alert.data.visible) {
      if (!modal_alert.data.color) {
        if (study_modal_arr.includes(modal_alert.data.type)) {
          playEffectSound('study_start');
        } else if (modal_alert.data.type != 'temp_record') {
          playEffectSound('alert');
        }
      } else {
        if (modal_alert.data.type == 'login') {
          playEffectSound('click');
        }
      }

      if (modal_alert.data.type == 'personal_product_alert') {
        const click_handler = () => {
          if (window?.cordova) {
            if (window?.cordova?.platformId === 'ios') {
              window.cordova.InAppBrowser.open('https://pf.kakao.com/_VvCxkxj/chat', '_system');
            } else {
              window.Open.openUrl('https://pf.kakao.com/_VvCxkxj/chat');
            }
          } else {
            window.open('https://pf.kakao.com/_VvCxkxj/chat');
          }
        };
        setTimeout(() => {
          const click_dom = document.querySelector('#change-product-inquiry') as HTMLElement;
          if (click_dom) {
            click_dom.addEventListener('click', click_handler);
          }
        }, 0);
      }
    }
  }, [modal_alert.data.visible]);

  return (
    <>
      <ModalDefault
        visible={modal_alert.data.visible}
        opacity={46}
        onClose={() => {
          if (modal_alert.data.use_close_button) {
            modal_alert.closeModalAlert();
          }
        }}
      >
        <StyledModalAlertWrap>
          <StyledModalAlert color={color}>
            <StyledTitle color={color} dangerouslySetInnerHTML={{ __html: modal_alert.data.title }}></StyledTitle>
          </StyledModalAlert>
        </StyledModalAlertWrap>
      </ModalDefault>
    </>
  );
}

export default ModalAlert;

// function () {
//   if (window?.cordvoa) {
//     if (window?.cordova?.platformId === "ios") {
//       window.cordova.InAppBrowser.open("https://pf.kakao.com/_VvCxkxj/chat", "_system");
//     } else {
//       window.Open.openUrl("https://pf.kakao.com/_VvCxkxj/chat");
//     }
//   } else {
//     window.open("https://pf.kakao.com/_VvCxkxj/chat")
//   }
// }
