import { atom, SetterOrUpdater } from 'recoil';

interface OneTimeModalRecoilType {
  [key: string]: 0 | 1;
}

export const oneTimeModalState = atom<OneTimeModalRecoilType>({
  key: 'oneTimeModal',
  default: {
    // 학습 스케쥴 (빠른 메뉴)
    learningschedule: 1,
    learningbook: 1,
    // 리더보드 (빠른 메뉴)
    board: 1,
    record: 1,
    // 학습 결과 (메인 메뉴)
    lookup: 1,
    period: 1,
    month: 1,
    // 포켓노트 (메인 메뉴)
    pocketnote: 1,
    //  학습매니저 (빠른 메뉴)
    notice: 1,
    notification: 1,
    manual: 1,
    qna: 1,
    settings: 1,
    // 학습모드 (메인 메뉴)
    longvoca: 1,
    customer: 1,
    customer2: 1,
    grammar: 1,
    // 내신모드 (메인 메뉴)
    textbook: 1,
    exam: 1,
    speak: 1,
  },
});

interface OneTimeModalSetter {
  setOneTimeModal: SetterOrUpdater<OneTimeModalRecoilType>;
}

export const checkOneTimeModal = ({ setOneTimeModal }: OneTimeModalSetter, key: string) => {
  setOneTimeModal(prev => ({ ...prev, [key]: 0 }));
};
