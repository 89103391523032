import { Routes, Route, useLocation } from 'react-router';
import { Suspense, lazy, useState, useEffect } from 'react';
import { styled, Box } from '@mui/material';

const Board = lazy(() => import('./Board'));
const Record = lazy(() => import('./Record'));

function ScheduleIndex() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/' element={<Board />} />
        <Route path='/board' element={<Board />} />
        <Route path='/record' element={<Record />} />
      </Routes>
    </Suspense>
  );
}

export default ScheduleIndex;
