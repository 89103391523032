import { atom, SetterOrUpdater } from 'recoil';
import t_s_setting_1 from '../../assets/images/tutorial/setting01.png';
import t_s_setting_2 from '../../assets/images/tutorial/setting02.png';

export interface ModalSelectCardContentType {
  src: string;
  content: string;
  target?: string;
}

export interface ModalSelectCardDataType {
  visible: boolean;
  type: 't_s';
  title: string;
  contents: ModalSelectCardContentType[];
  onSuccess: (idx: number) => void;
  onClose?: () => void;
  value?: number;
}

export const modalSelectCardData = atom<ModalSelectCardDataType>({
  key: 'modalSelectCardDataType',
  default: {
    visible: false,
    type: 't_s',
    title: '',
    contents: [],
    onSuccess: (idx: number) => {
      return;
    },
    onClose: () => {
      return;
    },
  },
});

interface ModalSelectCardSetter {
  setModalSelectCard: SetterOrUpdater<ModalSelectCardDataType>;
}

interface openModalSelectCardArgs {
  type: 't_s';
  onSuccess: (idx: number) => void;
  onClose?: () => void;
  custom_title?: string;
  value?: number;
}

export const openModalSelectCard = (
  { setModalSelectCard }: ModalSelectCardSetter,
  { type, onSuccess, onClose, custom_title, value }: openModalSelectCardArgs,
) => {
  // type에 따라 title image, content 세팅

  let title = '';
  let contents: ModalSelectCardContentType[] = [];
  if (type == 't_s') {
    title = '학습 상세설정을 선택해주세요!';
    contents = [
      {
        src: t_s_setting_1,
        content: '튜토리얼',
      },
      {
        src: t_s_setting_2,
        content: '학습 설정',
      },
    ];
  }
  setModalSelectCard({
    visible: true,
    type,
    title,
    contents,
    onSuccess,
    onClose,
    value,
  });
};

export const closeModalSelectCard = ({ setModalSelectCard }: ModalSelectCardSetter) => {
  setModalSelectCard({
    visible: false,
    type: 't_s',
    title: '',
    contents: [],
    onSuccess: (idx: number) => {
      return;
    },
    onClose: () => {
      return;
    },
    value: 0,
  });
};
