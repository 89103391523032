import { InputLabel, Tooltip, styled, Box, SxProps, Theme } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React, { ReactElement, useEffect, useState } from 'react';
import { d_flex } from '../../styles/common';
import { BsInfoCircle } from 'react-icons/bs';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface InputTitleProps {
  title: string;
  sx?: SxProps<Theme>;
  tooltip?: {
    text: ReactElement;
    direction:
      | 'bottom'
      | 'left'
      | 'right'
      | 'top'
      | 'bottom-end'
      | 'bottom-start'
      | 'left-end'
      | 'left-start'
      | 'right-end'
      | 'right-start'
      | 'top-end'
      | 'top-start'
      | undefined;
  };
  required?: boolean;
  visibleButton?: boolean;
  classification?: string;
  onClickButtonName?: (type: string) => void;
  onClickButtonTime?: (type: string) => void;
  bold?: boolean;
  openInfo?: { type: string; open: boolean };
}

const StyledInputLabel = styled(InputLabel)({
  width: '100%',
  height: '2.25rem',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '500',
  marginTop: '0.5rem',
  marginBottom: '0.45rem',
  color: '#505050',
  '& .MuiInputLabel-asterisk': {
    color: 'var(--red)',
  },
});

// Tooltip Styled 작업 중
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ theme, placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3f3f3f',
    left: placement === 'top-start' ? '1.5rem !important' : '0px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3f3f3f',
    fontSize: '1rem',
    maxWidth: 'unset',
  },
  inset: placement === 'top-start' ? 'auto auto 0px -1.6rem !important' : 'unset',
}));

const StyledButtonWrap = styled(Box)({
  width: '100%',
  height: '100%',
  ...d_flex,
});

const StyledButton = styled(Box)(props => ({
  width: '3rem',
  height: '2.25rem',
  fontSize: '0.875rem',
  border: `1px solid ${props.theme.palette.gray_1.main}`,
  cursor: 'pointer',
}));

function InputTitle(props: InputTitleProps) {
  const title = props.title;
  const tooltip = props.tooltip;
  const required = props.required;
  const visibleButton = props.visibleButton;
  const classification = props.classification;
  const onClickButtonName = props.onClickButtonName;
  const onClickButtonTime = props.onClickButtonTime;
  const sx = props.sx;
  const bold = props.bold;
  const open_info = props.openInfo;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open_info) {
      setOpen(open_info.open);
    }
  }, [open_info]);

  return (
    <StyledInputLabel
      shrink={false}
      required={required ? true : false}
      sx={{ ...sx, fontWeight: bold ? 'bold' : 'initial' }}
    >
      {title}
      {tooltip ? (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box component={'span'} sx={{ ml: '0.25rem', cursor: 'pointer' }}>
            <StyledTooltip
              title={tooltip.text}
              placement={tooltip.direction}
              open={open}
              onClose={() => setOpen(false)}
              onClick={() => setOpen(true)}
            >
              <Box component={'span'} sx={{ fontSize: '1.182rem', display: 'flex', alignItems: 'center' }}>
                <BsInfoCircle />
              </Box>
            </StyledTooltip>
          </Box>
        </ClickAwayListener>
      ) : null}
      {visibleButton ? (
        <StyledButtonWrap>
          <StyledButton
            component={'button'}
            sx={{ ml: 'auto' }}
            onClick={onClickButtonName && classification ? () => onClickButtonName(classification) : undefined}
          >
            이름
          </StyledButton>
          <StyledButton
            component={'button'}
            sx={{ ml: '0.25rem' }}
            onClick={onClickButtonTime && classification ? () => onClickButtonTime(classification) : undefined}
          >
            시간
          </StyledButton>
        </StyledButtonWrap>
      ) : null}
    </StyledInputLabel>
  );
}

export default InputTitle;
