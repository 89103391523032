import { Box, styled } from '@mui/material';
import { d_flex_center, dir_column } from 'styles/common';
import theme from 'theme';
import { QuestionText, PassageRow } from './common';
import { shuffleChip } from 'utils/tools';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { loadingState, setPercent } from 'recoil/common/loading';
import { useRecoilState, useRecoilValue } from 'recoil';
import { learningState } from 'recoil/model/learning';
import OXMark from 'components/common/Study/OXMark';
import { StyledIconButton } from './GrammarMC';
import { AiOutlineRight } from 'react-icons/ai';
import { settingState } from 'recoil/model/settings';
import { FONT_STD } from 'utils/constants';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { StyledCommentBox } from './GrammarSA';
import { EffectSoundContext } from 'provider/EffectSoundProvider';
import { ModalContext } from 'provider/ModalProvider';

const StyledGrammarSCWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
}));

const SCWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  ...dir_column,
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  padding: '0 6%',
});

const AnswerRow = styled(Box)({
  height: '19.32vh',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
  width: '80vw',
});

const AnswerWrap = styled(Box)({
  ...d_flex_center,
  alignContent: 'center',
  maxWidth: '100%',
  flex: '0 0 100%',
});

const Answers = styled(Box)({
  fontSize: 'inherit',
  margin: 'auto',
  ...d_flex_center,
  alignContent: 'center',
  flexWrap: 'wrap',
  wordBreak: 'normal',
});

const Answer = styled(Box)({
  flex: '0 1 auto',
  order: '0',
  alignSelf: 'auto',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
});

const ChoiceRow = styled(Box)({
  height: '26.11vh',
  display: 'flex',
  flexWrap: 'wrap',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
  width: '80vw',
});

const ChoiceWrap = styled(Box)({
  borderTop: '1px solid #ddd',
  minWidth: '60%',
  textAlign: 'center',
  margin: 'auto',
  ...d_flex_center,
  alignContent: 'center',
  flexWrap: 'wrap',
  height: '100%',
  maxWidth: '100%',
});

const ChoiceBox = styled(Box)({
  fontSize: '2.3vw',
  lineHeight: '1.4',
  textAlign: 'center',
  width: '100%',
});

const Choices = styled(Box)({
  ...d_flex_center,
  alignContent: 'center',
  flexWrap: 'wrap',
});

const Choice = styled(Box)({
  height: 'auto',
  padding: '0.8vw',
  margin: '0.5vw',
  lineHeight: '2.3vw',
  minWidth: '4vw',
  borderRadius: '1rem',
  color: '#9e9e9e',
  border: 'thin solid #9e9e9e',
  cursor: 'pointer',
  userSelect: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TabStyleSCWrap = styled(Box)(props => ({
  height: '70vh',
  overflow: 'hidden auto',
}));

interface GrammarSCProps {
  content: GrammarContentType;
  createResultWrongData: (
    answer: string,
    rightAnswer: string,
    mode?: string,
    sc_items?: string[],
    selectedWord?: string[],
  ) => string;
  sendResultsheet: (resultsheet: any) => void;
  isReviewLearning: boolean;
}

function GrammarSC({ content, createResultWrongData, sendResultsheet, isReviewLearning }: GrammarSCProps) {
  const { modal_alert } = useContext(ModalContext);
  const [animationDirection, setAnimationDirection] = useState('g-horizontal-right');
  const [learningStart, setLearningStart] = useState(false);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;
  const {
    mod,
    modules,
    current_step,
    current_page,
    show_modal,
    resultsheet,
    current_module_settings,
    use_image,
    learning_type,
    direction,
    grammar_contents,
    font_level,
    grammar_init,
  } = learningStateData;

  const [selectedIdx, setSelectedIdx] = useState<number[]>([]);
  const [selectedWord, setSelectedWord] = useState<string[]>([]);
  const [visibleOX, setVisibleOX] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);
  const [disableMove, setDisableMove] = useState<boolean>(false);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);
  const settingStateData = useRecoilValue<SettingsType>(settingState);
  const isGoNextRef = useRef(false);
  const isReviewAdvanced = isReviewLearning && content.comment;
  const { playEffectSound } = useContext(EffectSoundContext);

  const selected_word_ref = useRef(selectedWord);
  selected_word_ref.current = selectedWord;
  const content_ref = useRef(content);
  content_ref.current = content;
  const current_step_ref = useRef(current_step);
  current_step_ref.current = current_step;
  const selected_idx_ref = useRef(selectedIdx);
  selected_idx_ref.current = selectedIdx;
  const resultsheet_ref = useRef(resultsheet);
  resultsheet_ref.current = resultsheet;
  const is_review_learning_ref = useRef(isReviewLearning);
  is_review_learning_ref.current = isReviewLearning;

  const shuffle_items = useMemo(() => {
    if (content.sc_items) {
      return shuffleChip(content.sc_items, content.rightanswer);
    }
  }, [content]);

  //* ref

  const suffle_items_ref = useRef(shuffle_items);
  suffle_items_ref.current = shuffle_items;

  const grammar_init_ref = useRef(grammar_init);
  grammar_init_ref.current = grammar_init;

  useEffect(() => {
    initLearningStart();
    bindKeyboard();
    setLearningStart(false);
    setTimeout(() => {
      setLearningStart(true);
    }, 300);
    return () => {
      unbindKeyboard();
    };
  }, []);

  useEffect(() => {
    setLearningStart(false);
    setTimeout(() => {
      setLearningStart(true);
    }, 800);
  }, [current_page]);

  useEffect(() => {
    // console.log(learningStart, 'learningStart');
  }, [learningStart]);

  useEffect(() => {
    if (settingStateData.enable_keyboard) bindKeyboard();
    return () => {
      if (settingStateData.enable_keyboard) unbindKeyboard();
    };
  }, [selectedWord, disableMove, right, visibleOX]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (!visibleOX) {
      if (e.keyCode == 13) {
        goNext();
      }
    } else {
      if (isReviewAdvanced && !right) {
        if (e.keyCode == 13 && !disableMove) {
          goNextProcess();
        }
      }
    }
  };

  useEffect(() => {
    initLearningStart();
  }, [grammar_contents]);

  useEffect(() => {
    if (percent == 100 && grammar_init_ref.current) {
      let alert_type = '';
      if (learning_type == 7) {
        alert_type += 'grammar_t';
        if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        }
      } else if (learning_type == 8) {
        alert_type += 'grammar_b';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        } else if (current_page == 2) {
          alert_type += '_2';
        } else if (current_page == 3) {
          alert_type += '_3';
        }
      } else if (learning_type == 9) {
        alert_type += 'grammar_d';
        if (isReviewLearning && mod.length == 1) {
          alert_type += '_r';
        } else if (current_page == 0) {
          alert_type += '_0';
        } else if (current_page == 1) {
          alert_type += '_1';
        }
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      setTimeout(() => {
        modal_alert.openModalAlert(alert_type, undefined, undefined, () => {
          setLearningStateData(prevState => ({
            ...prevState,
            grammar_init: false,
            show_modal: false,
          }));
        });
      }, 300);
    }
  }, [percent]);

  const initLearningStart = () => {
    // console.log('sc', is_review_learning_ref.current, current_step !== 0, modules.length == 1);
    if (is_review_learning_ref.current && current_step !== 0 && modules.length == 1) return false;
    setPercent(setLoadingStateData, 100);
  };

  const selectWord = (word: string, idx: number) => {
    setSelectedIdx(prev => [...prev, idx]);

    const last = selectedWord.length - 1;
    const new_selected_word = JSON.parse(JSON.stringify(selectedWord));
    if ([',', '.', '!', '?'].includes(word)) {
      if (last >= 0) {
        new_selected_word[last] = new_selected_word[last].replace('&nbsp;', '');
      }
    } else {
      if (last >= 0) {
        if (new_selected_word[last].indexOf('&nbsp;') == -1) {
          new_selected_word[last] = new_selected_word[last] + '&nbsp;';
        }
      }
    }

    new_selected_word.push(word + '&nbsp;');

    setSelectedWord(new_selected_word);
  };

  const subSelection = () => {
    const new_selected_idx = JSON.parse(JSON.stringify(selectedIdx));
    const new_selected_word = JSON.parse(JSON.stringify(selectedWord));

    new_selected_idx.pop();
    new_selected_word.pop();

    setSelectedIdx(new_selected_idx);
    setSelectedWord(new_selected_word);
  };

  const goNext = async () => {
    if (isGoNextRef.current) return;
    isGoNextRef.current = true;
    let right = false;

    const originWords = selected_word_ref.current.map(word => word.replace('&nbsp;', ' '));
    right = originWords.join('').trim() === content_ref.current.rightanswer;

    setRight(right);

    const correct_answer = createResultWrongData(
      originWords.join('').trim(),
      content_ref.current.rightanswer,
      'SC',
      content_ref.current.sc_items,
      selected_word_ref.current,
    );
    setCorrectAnswer(correct_answer);

    if (settingStateData.check_type) {
      setVisibleOX(true);
      playEffectSound(right ? 'correct' : 'wrong');
      if (!isReviewAdvanced || (isReviewAdvanced && right)) {
        setDisableMove(true);
      } else {
        setDisableMove(true);
        setTimeout(() => {
          setDisableMove(false);
        }, 3000);
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }

    // ! 일반 학습이거나, 리뷰강의 심화학습이 아니거나, 리뷰강의 심화학습에서 정답을 맞췄을 경우에만 다음문제 넘김처리
    if (!isReviewLearning || !isReviewAdvanced || (isReviewAdvanced && right)) {
      setTimeout(
        () => {
          goNextProcess();
        },
        process.env.NODE_ENV === 'development' ? 1000 : 3000,
      );
    }
  };

  const goNextProcess = async () => {
    const originWords = selected_word_ref.current.map(word => word.replace('&nbsp;', ' '));
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet_ref.current));

    resultsheet_tmp[current_step_ref.current] = {
      no: current_step_ref.current + 1,
      word_id: content_ref.current['id'],
      question: content_ref.current.question,
      passage: content_ref.current.passage,
      rightanswer: content_ref.current.rightanswer,
      selectedIdx: selected_idx_ref.current,
      selectedWord: selected_word_ref.current,
      right: originWords.join('').trim() === content_ref.current.rightanswer,
      sc_items: suffle_items_ref.current,
      wrongHtml: createResultWrongData(
        originWords.join('').trim(),
        content_ref.current.rightanswer,
        'SC',
        content_ref.current.sc_items,
        selected_word_ref.current,
      ),
      type: 'SC',
    };

    setSelectedIdx([]);
    setSelectedWord([]);

    if (current_step_ref.current == grammar_contents.length - 1) {
      setVisibleOX(false);
      setDisableMove(false);
      setCorrectAnswer(null);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      });

      setTimeout(() => {
        sendResultsheet(resultsheet_tmp);
      }, 1);
    } else {
      setDisableMove(false);
      setCorrectAnswer(null);
      if (isReviewLearning && mod.length == 1) {
        setVisibleOX(false);
      } else {
        setTimeout(() => {
          setVisibleOX(false);
        }, 300);
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
        current_step: current_step_ref.current + 1,
        resultsheet: resultsheet_tmp,
      }));
    }
    isGoNextRef.current = false;
  };

  return (
    <StyledGrammarSCWrap
      sx={{
        padding: content.sub_unit === 't1' ? '0' : '0 6%',
        ' #ox-wrapper > div': {
          top: isReviewAdvanced
            ? '14.1vh !important'
            : content.sub_unit == 't1'
            ? 'calc((100vh - 4rem - 5rem - 4rem) * 0.5)'
            : '15vh !important',
        },
      }}
    >
      <Box
        sx={{
          display: learningStart ? 'none' : isReviewLearning && mod.length == 1 ? 'block' : 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: '#fff',
          zIndex: '1',
        }}
      ></Box>
      <OXMark show={visibleOX} right={right} grammar={true}></OXMark>
      {content.comment && visibleOX && isReviewLearning ? (
        <StyledCommentBox sx={{ position: 'absolute !important', top: '61vh', fontFamily: 'Apple SD Gothic Neo' }}>
          {content.comment}
        </StyledCommentBox>
      ) : null}
      <StyledIconButton
        disabled={disableMove}
        className={`${isReviewAdvanced && visibleOX && !right ? 'blink-right-button' : ''}`}
        onClick={isReviewAdvanced && visibleOX && !right ? () => goNextProcess() : () => goNext()}
        sx={{ right: '16px' }}
        disableRipple
      >
        <AiOutlineRight />
      </StyledIconButton>
      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={`${current_page}-${current_step}-${content.type}`}
          classNames={animationDirection}
          timeout={
            learningStart
              ? isReviewLearning && mod.length == 1
                ? 300
                : 300
              : isReviewLearning && mod.length == 1
              ? grammar_init
                ? 0
                : 300
              : 0
          }
          onExit={node => {
            if (isReviewLearning && mod.length == 1) {
              node.style.visibility = 'hidden';
            } else {
              setTimeout(() => {
                node.style.visibility = 'hidden';
              }, 300);
            }
          }}
          onEnter={(node: any) => {
            if (isReviewLearning && mod.length == 1) {
              node.style.visibility = 'visible';
            } else {
              node.style.visibility = 'visible';
            }
          }}
        >
          <SCWrap>
            <TabStyleSCWrap className='grammar-custom-scrollbar'>
              <QuestionText
                sx={{
                  fontSize: `${FONT_STD.HEAD * font_level}vw`,
                  marginBottom: '3vh',
                  ...d_flex_center,
                  padding: '0 7vw',
                  minHeight: '7vh',
                  fontWeight: '600',
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: content.question ? content.question : '' }}></span>
              </QuestionText>
              {content.passage ? (
                <PassageRow sx={{ fontSize: `${FONT_STD.MAIN * font_level}vw`, margin: '3vh auto 0' }}>
                  <span dangerouslySetInnerHTML={{ __html: content.passage }}></span>
                </PassageRow>
              ) : null}

              <AnswerRow>
                <AnswerWrap>
                  <Box sx={{ fontSize: `${FONT_STD.STD * font_level}vw`, height: '100%' }}>
                    <Answers>
                      {correctAnswer ? (
                        <Answer dangerouslySetInnerHTML={{ __html: correctAnswer }}></Answer>
                      ) : (
                        selectedWord.map((word, widx) => (
                          <Answer
                            sx={{ cursor: selectedWord.length - 1 === widx ? 'pointer' : 'default' }}
                            key={`${word}_${widx}`}
                            dangerouslySetInnerHTML={{ __html: word }}
                            onClick={selectedWord.length - 1 === widx ? subSelection : undefined}
                          ></Answer>
                        ))
                      )}
                    </Answers>
                  </Box>
                </AnswerWrap>
              </AnswerRow>
              <ChoiceRow>
                <ChoiceWrap>
                  <ChoiceBox>
                    <Choices>
                      {shuffle_items?.map((item, idx) => (
                        <Choice
                          onClick={selectedIdx.includes(idx) ? undefined : () => selectWord(item, idx)}
                          sx={{
                            cursor: selectedIdx.includes(idx) ? 'default' : 'pointer',
                            visibility: selectedIdx.includes(idx) ? 'hidden' : 'visible',
                            fontSize: `${FONT_STD.STD * font_level}vw`,
                          }}
                          key={`${item}_${idx}`}
                        >
                          {item}
                        </Choice>
                      ))}
                    </Choices>
                  </ChoiceBox>
                </ChoiceWrap>
              </ChoiceRow>
            </TabStyleSCWrap>
          </SCWrap>
        </CSSTransition>
      </SwitchTransition>
    </StyledGrammarSCWrap>
  );
}

export default GrammarSC;
