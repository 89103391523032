import { atom, SetterOrUpdater } from 'recoil';

export interface ModalLeaderBoardType {
  visible: boolean;
}

export const modalLeaderBoardState = atom<ModalLeaderBoardType>({
  key: 'modalLeaderBoard',
  default: {
    visible: false,
  },
});

interface ModalLeaderBoardSetter {
  setModalLeaderBoard: SetterOrUpdater<ModalLeaderBoardType>;
}

export const openModalLeaderBoard = ({ setModalLeaderBoard }: ModalLeaderBoardSetter) => {
  setModalLeaderBoard(prev => ({
    ...prev,
    visible: true,
  }));
};

export const closeModalLeaderBoard = ({ setModalLeaderBoard }: ModalLeaderBoardSetter) => {
  setModalLeaderBoard({
    visible: false,
  });
};
