import React from 'react';
import { styled, Box } from '@mui/material';
import { ImFolderOpen } from 'react-icons/im';

const TableRow = styled(Box)<{ isfolder?: string }>(props => ({
  position: 'relative',
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: '3.5rem 5.5fr 5.5fr',
  backgroundColor: 'transparent',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '&:first-of-type': {
      borderRight: '1px solid #ededef',
      justifyContent: 'center',
      minWidth: '30px',
    },
    '&:nth-of-type(2)': {
      borderRight: '1px solid #ededef',
      paddingLeft: '1rem',
    },
    '&:nth-of-type(3)': {
      paddingLeft: '1rem',
    },
  },
  '@media(max-width: 1023px)': {
    '& > div': {
      '&:first-of-type': {
        borderColor: '#f5f5f5',
      },
      '&:nth-of-type(2)': {
        borderColor: '#f5f5f5',
      },
    },
  },
}));

const TableCell = styled(Box)({
  height: '3.5rem',
  borderBottom: '1px solid #ededef',
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
});

const SettingBox = styled(Box)({
  height: '100%',
  cursor: 'pointer',
  position: 'absolute',
  right: '1rem',
  top: '50%',
  transform: 'translateY(-50%)',
  color: '#c6c6c6',
  paddingLeft: '1rem',
});

export interface PocketNoteTableFieldType {
  isNote?: boolean;
  label: string;
}

export interface PocketNoteTableRowProps {
  account_id: number;
  id: number;
  is_default: number;
  name: string;
  postdate: string;
  word_count: number;
  onRowClicked: (item: any) => void;
  onClickSettingBox: (e: any, id: number) => void;
}

function PocketNoteTableRowNote({
  id,
  name,
  is_default,
  word_count,
  onRowClicked,
  onClickSettingBox,
}: PocketNoteTableRowProps) {
  return (
    <TableRow onClick={() => onRowClicked({ id })}>
      <TableCell>
        <ImFolderOpen size='1.5rem' color='#ffeb3b' />
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{word_count}개</TableCell>
      {is_default ? null : (
        <SettingBox onClick={e => onClickSettingBox(e, id)}>
          <Box
            sx={{
              height: '35%',
              width: '1px',
              backgroundColor: '#c6c6c6',
              marginRight: '1rem',
              '@media(max-width: 1023px)': {
                backgroundColor: '#dfdfdf',
              },
            }}
          ></Box>
          설정
        </SettingBox>
      )}
    </TableRow>
  );
}

export default React.memo(PocketNoteTableRowNote);
