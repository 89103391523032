import { FormControl, Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { learningState } from '../../recoil/model/learning';
import Box, { BoxProps } from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { d_flex_center, d_flex_start } from '../../styles/common';

interface ModalWordlistProps {
  visible: boolean;
  onClick: (idx: number) => void;
  onClose: () => void;
  contents: ContentType[];
  current_step: number;
}

// style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30rem',
  height: 'auto',
};

const StyledWordlistModalWrapper = styled(FormControl)({
  width: '100%',
  alignItems: 'center',
  backgroundColor: 'var(--white)',
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '4px',
  overflow: 'hidden',
});

const StyledModalBody = styled(Box)(props => ({
  width: '100%',
  flexDirection: 'column',
  height: '60vh',
  overflowY: 'auto',
}));

const StyledModalHeader = styled(Box)(props => ({
  background: 'linear-gradient(#652ae9, #4620e9)',
  height: '4rem',
  width: '100%',
  ...d_flex_start,
}));

const StyledModalTitle = styled(Box)({
  width: '100%',
  color: 'white',
  fontSize: '1.2rem',
  paddingLeft: '1.5rem',
});

interface WordlistButtonBoxProps extends BoxProps {
  current: 'true' | 'false';
}

const StyledWordBox = styled(Box)<WordlistButtonBoxProps>(props => ({
  border: 'none',
  borderBottom: '1px solid #f8f3ff',
  backgroundColor: props.current == 'true' ? props.theme.palette.light_purple_3.main : 'unset',
  width: '98%',
  height: '4rem',
  ...d_flex_center,
  cursor: 'pointer',
  margin: '0 auto',
}));

interface WordlistButtondProps {
  memorize: 'true' | 'false';
  current: 'true' | 'false';
}

const WordButton = styled(Button)<WordlistButtondProps>(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.2rem',
  color: props.current == 'true' ? props.theme.palette.purple.main : props.memorize == 'true' ? '#efefef' : 'unset',
  fontFamily: 'unset',
  textTransform: 'unset',
  letterSpacing: 'unset',
  '&:hover': {
    backgroundColor: 'unset',
  },
}));

function ModalWordlist(props: ModalWordlistProps) {
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { resultsheet } = learningStateData;
  const [index, setIndex] = useState(0);
  const contents = props.contents;
  const visible = props.visible;
  const offModal = props.onClick;
  const onClose = props.onClose;

  useEffect(() => {
    setIndex(props.current_step);
    setTimeout(() => {
      const location = document.getElementById(`word_btn_${index}`);
      const header = document.getElementById('wordlist_header');
      const body = document.getElementById('wordlist_body');
      if (location && header && body) {
        const offsetTop = location.offsetTop - header.offsetHeight / 2 - body.clientHeight / 2;
        body.scrollTo(0, offsetTop);
      }
    }, 0);
  }, [visible]);

  const onClick = (idx: number) => {
    offModal(idx);
    onClose();
  };

  return (
    <Modal
      open={visible}
      onClose={onClose}
      sx={{
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Box sx={style}>
        <StyledWordlistModalWrapper>
          <StyledModalHeader id='wordlist_header'>
            <StyledModalTitle>단어 목록 - {contents.length}개</StyledModalTitle>
          </StyledModalHeader>
          <StyledModalBody id='wordlist_body'>
            {contents?.map((v, i) => (
              <StyledWordBox key={i} current={i == index ? 'true' : 'false'}>
                <WordButton
                  id={`word_btn_${i}`}
                  memorize={resultsheet && resultsheet.length > 0 && resultsheet[i]?.memorize ? 'true' : 'false'}
                  current={i == index ? 'true' : 'false'}
                  onClick={() => onClick(i)}
                  disableRipple
                >
                  {v.spell}
                </WordButton>
              </StyledWordBox>
            ))}
          </StyledModalBody>
        </StyledWordlistModalWrapper>
      </Box>
    </Modal>
  );
}

export default ModalWordlist;
