import React from 'react';
import { styled, Box, Checkbox as MuiCheckbox } from '@mui/material';
import PocketNoteTableRow from './PocketNoteTableRow';
import PocketNoteTableRowNote from './PocketNoteTableRowNote';

const TableBody = styled(Box)({
  height: 'calc(100% - 3.5rem)',
  overflowY: 'auto',
  position: 'relative',
});

export interface PocketNoteTableFieldType {
  isNote?: boolean;
  label: string;
}

export interface PocketNoteTableBodyProps {
  tableItems: { [key: string]: any }[];
  isSelectedNote: boolean;
  checked_ids: any[];
  allCheck: boolean;
  onRowClicked: (item: any) => void;
  onClickSettingBox: (e: any, id: number) => void;
}

const Checkbox = React.memo(function SpellingCell({
  checked,
  _id,
  onRowClicked,
  tableItem,
}: {
  checked: boolean;
  _id: string;
  onRowClicked: (item: any) => void;
  tableItem: any;
}) {
  return (
    <MuiCheckbox
      sx={{
        position: 'absolute',
        cursor: 'pointer',
        width: '3.5rem',
        height: '3.5rem',
        zIndex: '3',
      }}
      checked={checked}
      onClick={() => onRowClicked({ tableItem })}
    />
  );
});

function PocketNoteTableBody({
  tableItems,
  isSelectedNote,
  checked_ids,
  allCheck,
  onRowClicked,
  onClickSettingBox,
}: PocketNoteTableBodyProps) {
  if (isSelectedNote) {
    return (
      <TableBody>
        {tableItems.map((tableItem, tIdx) => (
          <React.Fragment key={`table_row_${tableItem.id ? tableItem.id : tableItem._id}`}>
            <PocketNoteTableRow
              isFolder={tableItem.isFolder}
              spell={tableItem.spell}
              mean={tableItem.mean}
              onRowClicked={onRowClicked}
              checked={tIdx == 0 ? false : allCheck ? true : checked_ids.includes(tableItem._id)}
            />
            {tIdx == tableItems.length - 1 ? null : (
              <Checkbox
                checked={allCheck ? true : checked_ids.includes(tableItems[tIdx + 1]._id)}
                onRowClicked={onRowClicked}
                _id={tableItems[tIdx + 1]._id}
                tableItem={tableItems[tIdx + 1]}
              />
            )}
          </React.Fragment>
        ))}
      </TableBody>
    );
  } else {
    return (
      <TableBody>
        {tableItems.map((tableItem, tIdx) => {
          if (tableItem.account_id) {
            return (
              <PocketNoteTableRowNote
                key={`table_row_${tableItem.id}_${tableItem.name}`}
                account_id={tableItem.account_id}
                id={tableItem.id}
                is_default={tableItem.is_default}
                name={tableItem.name}
                postdate={tableItem.postdate}
                word_count={tableItem.word_count}
                onRowClicked={onRowClicked}
                onClickSettingBox={onClickSettingBox}
              />
            );
          } else {
            return null;
          }
        })}
      </TableBody>
    );
  }
}

export default React.memo(PocketNoteTableBody);
