import * as React from 'react';

import { Button as MuiButton, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(MuiButton)(props => ({
  boxShadow: 'none',
  fontSize: '1rem',
  lineHeight: 1.5,

  '&.regi': {
    minWidth: '8rem',
  },
  '&:hover': {
    boxShadow: 'none',
  },
  '&.disabled': {
    '&.MuiButton-contained': {
      backgroundColor: 'rgb(232, 232, 232) ',
      color: props.theme.palette.white.main,
    },

    '&.MuiButton-outlined': {
      borderColor: '#e8e8e8 ',
    },
  },
  '&.Mui-disabled': {
    '&.MuiButton-contained': {
      backgroundColor: 'rgb(232, 232, 232) ',
      color: props.theme.palette.white.main,
    },

    '&.MuiButton-outlined': {
      borderColor: '#e8e8e8 ',
    },
  },
}));

function Button(props: ButtonProps) {
  const variant = props.variant;
  const color = props.color;
  const children = props.children;
  const startIcon = props.startIcon;
  const endIcon = props.endIcon;
  const onClick = props.onClick;
  const disabled = props.disabled;
  const size = props.size;
  const sx = props.sx;
  const disableRipple = props.disableRipple;
  const class_name = props.className;
  const id = props.id;

  return (
    <CustomButton
      id={id}
      variant={variant}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      size={size}
      sx={sx}
      disableRipple={disableRipple}
      className={class_name}
    >
      {children}
    </CustomButton>
  );
}

export default Button;
