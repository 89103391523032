import {
  SelectionBox,
  M2ContentWrap,
  M3ContentWrap,
  M4ContentWrap,
  B1ContentWrap,
  B2ContentWrap,
  B3ContentWrap,
  A1ContentWrap,
  A2ContentWrap,
  S1ContentWrap,
  S2ContentWrap,
} from './TutorialContent.Styles';
import { Fragment } from 'react';

import arrow from '../../../../assets/images/tutorial/content/arrow.png';
import key01 from '../../../../assets/images/tutorial/content/key01.png';
import key02 from '../../../../assets/images/tutorial/content/key02.png';
import key03 from '../../../../assets/images/tutorial/content/key03.png';
import key04 from '../../../../assets/images/tutorial/content/key04.png';
import key05 from '../../../../assets/images/tutorial/content/key05.png';
import key06 from '../../../../assets/images/tutorial/content/key06.png';
import key07 from '../../../../assets/images/tutorial/content/key07.png';
import search from '../../../../assets/images/tutorial/content/search.png';
import wave from '../../../../assets/images/tutorial/content/wave.png';

import { FaPlus } from 'react-icons/fa';
import { CgFormatSlash } from 'react-icons/cg';

const mock_words = ['fan', 'bag', 'map', 'pan'];

interface TutorialContentVAProps {
  currentKey: string;
}

export function TutorialContentUI({ currentKey }: TutorialContentVAProps) {
  return (
    <Fragment>
      {currentKey == 'm_1'
        ? mock_words.map(word => (
            <SelectionBox key={word} className={word == 'bag' ? 'active' : ''}>
              {word}
            </SelectionBox>
          ))
        : null}
      {currentKey == 'm_2' || currentKey == 's_3' ? (
        <M2ContentWrap>
          <img src={key01} className='key01' />
          <FaPlus />
          <img src={key02} className='key02' />
        </M2ContentWrap>
      ) : null}
      {currentKey == 'm_3' ? (
        <M3ContentWrap>
          <img src={key03} />
        </M3ContentWrap>
      ) : null}
      {currentKey == 'm_4' ? (
        <M4ContentWrap>
          <img src={key04} className='key04' />
          <CgFormatSlash />
          <img src={key05} className='key05' />
        </M4ContentWrap>
      ) : null}
      {currentKey == 'b_1' ? (
        <B1ContentWrap>
          <img src={key04} className='key04' />
          <CgFormatSlash />
          <img src={key03} className='key03' />
        </B1ContentWrap>
      ) : null}
      {currentKey == 'b_2' ? (
        <B2ContentWrap>
          <div className='menu active'>FLASH CARD</div>
          <div className='menu'>WORD BINGO</div>
          <div className='menu'>POP QUIZ</div>
        </B2ContentWrap>
      ) : null}
      {currentKey == 'b_3' ? (
        <B3ContentWrap>
          <img src={search} />
        </B3ContentWrap>
      ) : null}
      {currentKey == 'a_1' ? (
        <A1ContentWrap>
          <img src={key06} className='key06' />
          <CgFormatSlash />
          <img src={arrow} className='arrow' />
        </A1ContentWrap>
      ) : null}
      {currentKey == 'a_2' || currentKey == 's_4' ? (
        <A2ContentWrap>
          <img src={wave} className='wave' />
        </A2ContentWrap>
      ) : null}
      {currentKey == 's_1' ? (
        <S1ContentWrap>
          <div className='menu active'>추천 학습영상</div>
          <div className='menu'>주요예문 점검</div>
          <div className='menu'>실전 회화학습</div>
        </S1ContentWrap>
      ) : null}
      {currentKey == 's_2' ? (
        <S2ContentWrap>
          <img src={key07} className='key07' />
        </S2ContentWrap>
      ) : null}
    </Fragment>
  );
}
