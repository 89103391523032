import { d_flex_center, dir_column } from '../../styles/common';
import {
  styled,
  CircularProgress,
  circularProgressClasses,
  LinearProgress,
  linearProgressClasses,
  Box,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { loadingState } from '../../recoil/common/loading';

import bi from '../../assets/images/longedu_bi.png';

interface StyledLoadingBoxProps {
  is_logo?: boolean;
}

const StyledLoadingContainer = styled(Box)<StyledLoadingBoxProps>(props => {
  return {
    width: '100%',
    height: 'calc(100% - 4rem)',
    top: props.is_logo ? '0' : '4rem',
    position: 'absolute',
    zIndex: '1000000',
    backgroundColor: 'white',
    ...d_flex_center,
  };
});

const StyledLoadingBox = styled(Box)(props => ({
  ...d_flex_center,
  ...dir_column,
  justifyContent: 'center',
}));

const StyledCircularProgressBox = styled(Box)(props => ({
  margin: '10px auto',
  width: '400px',
  position: 'relative',
  textAlign: 'center',
}));

const StyledLoadingLabel = styled(Box)(props => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: props.theme.palette.purple.main,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  position: 'absolute',
}));

const StyledCircularProgress = styled(CircularProgress)(props => ({
  color: props.theme.palette.purple.main,
  animationDuration: '650ms',
}));

const StyledLoadingLogoWrap = styled(Box)({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  zIndex: '99999',
  ...d_flex_center,
  top: 0,
  backgroundColor: 'white',
});

const StyledLoadingTextBox = styled(Box)(props => ({
  color: '#6e6e6e',
  margin: '25px auto',
  width: '400px',
  fontSize: '1.25rem !important',
  letterSpacing: '0px !important',
  lineHeight: '2rem',
  textAlign: 'center',
}));

const StyledLinearProgressBox = styled(Box)({
  margin: '10px auto',
  width: '400px',
});

const StyledLinearProgress = styled(LinearProgress)(props => ({
  width: '100%',
  height: '1rem',
  borderRadius: '4px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#f5f3ff',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: props.theme.palette.purple.main,
  },
}));

const StyledLogo = styled('img')({
  height: '7.2vh',
});

interface LoadingProps {
  isLogo?: boolean;
  isFetch?: boolean;
  isAbs?: boolean;
  isCalendar?: boolean;
  isCouncel?: boolean;
}

function Loading(props: LoadingProps) {
  const is_logo = props.isLogo;
  const loadingStateData = useRecoilValue<LoadingType>(loadingState);

  const { percent, message } = loadingStateData;

  return (
    <>
      {is_logo ? (
        <StyledLoadingLogoWrap>
          <StyledLogo src={bi} />
        </StyledLoadingLogoWrap>
      ) : (
        <StyledLoadingContainer>
          <StyledLoadingBox>
            <StyledCircularProgressBox>
              <StyledCircularProgress
                variant='indeterminate'
                disableShrink
                sx={{
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  },
                }}
                size={'85px'}
                thickness={2}
              />
              <StyledLoadingLabel>{percent}%</StyledLoadingLabel>
            </StyledCircularProgressBox>

            <StyledLoadingTextBox>{message}</StyledLoadingTextBox>
            <StyledLinearProgressBox>
              <StyledLinearProgress variant='determinate' value={percent} />
            </StyledLinearProgressBox>
          </StyledLoadingBox>
        </StyledLoadingContainer>
      )}
    </>
  );
}

export default Loading;
