import { atom } from 'recoil';

export const routesState = atom<RoutesType>({
  key: 'routesState',
  default: {
    path: '/',
    prev_path: '/',
    name: '',
    component: null,
    check_main_path: false,
    root_path: '',
    child_path: '',
  },
});
