import { useState, useEffect, useRef, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';

import { ModalContext } from '../../provider/ModalProvider';

import { styled, Box, IconButton, LinearProgress, linearProgressClasses } from '@mui/material';

import { dir_column, d_flex_center } from '../../styles/common';

import ModalEndModule from '../../components/modal/ModalEndModule';
import { deviceState } from '../../recoil/common/device';
import { IoPlay } from 'react-icons/io5';
import { makeGradeIcon, makeGradeKorLabel, makeGradeLabel } from 'utils/tools';
import getScore from 'utils/get_score';
import SpeechRecognition from './SpeechRecognition';
import WebkitSpeechRecognition from './WebkitSpeechRecognition';
import AutoText from 'components/text/AutoText';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';
import { CDN } from 'utils/constants';
import theme from 'theme';
import { userState } from 'recoil/model/user';

interface SpeakingProps {
  sendResultsheet: () => void;
}

const StyledVideoWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledVideoBody = styled(Box)(props => ({
  height: 'calc(100% - 4rem)',
  width: '100%',
  ...d_flex_center,
}));

const StyledVideoTextWrap = styled(Box)(props => ({
  height: '65vh',
  width: '100%',
  padding: '0 2vw',
  position: 'relative',
  ...d_flex_center,
}));

const StyledVideoTextVideoBox = styled(Box)(props => ({
  height: '100%',
  width: '40%',
  padding: '2rem',
  ...d_flex_center,
  borderRight: '2px solid #f2f2f2',
}));

const StyledTextVideoBox = styled(Box)(props => ({
  height: 'fit-content',
  width: 'fit-content',
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'flex',
  position: 'relative',
  '& > video': {
    objectFit: 'cover',
  },
}));

const StyledVideoTextTextBox = styled(Box)(props => ({
  height: '100%',
  width: '60%',
  padding: '3vh',
  ...d_flex_center,
  ...dir_column,
  position: 'relative',
  gap: '2vh',
}));

const StyledVideoBottom = styled(Box)(props => ({
  width: '96%',
  height: '4rem',
  borderTop: '1px solid #e0e3e5',
  ...d_flex_center,
}));

const StyledLinearProgress = styled(LinearProgress)(props => ({
  height: '2rem',
  width: '100%',
  borderRadius: '2rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#f5f5f5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '2rem',
    transition: props.value == 0 || props.value == 100 ? 'none' : 'transform .2s linear',
    backgroundImage: props.value ? `linear-gradient(to right, #e639f3 ${100 - props.value}%, #461fda 100%)` : null,
  },
}));

const StyledSpeakWrap = styled(Box)(props => ({
  width: '100%',
  height: '42%',
  ...d_flex_center,
  ...dir_column,

  '& .sentence-style': {
    fontSize: '2.8rem',
    fontWeight: '400',
  },

  '& .translate-style': {
    fontSize: '1.8rem',
    fontWeight: '100',
  },
}));

const StyledSpeechWrap = styled(Box)(props => ({
  width: '100%',
  height: '58%',
  ...d_flex_center,
}));

const StyledQuestionAutoText = styled(AutoText)<{ fixed?: boolean }>(props => {
  const check_spell_stype = {
    '& > .c_blue, & > .c_red, & > .c_orange, & > .emphasis-word': {
      letterSpacing: '-2.5px',
    },

    '@media (max-width: 1263px)': {
      letterSpacing: '-1.25px',
      '& > .c_blue, & > .c_red, & > .c_orange, & > .emphasis-word': {
        letterSpacing: '-1.25px !important',
      },
    },
  };
  return {
    width: '70%',
    color: '#5f5f5f',
    fontWeight: '700',
    letterSpacing: '-2.5px',
    whiteSpace: 'unset',
    wordBreak: 'keep-all',
    lineBreak: 'unset',
    ...check_spell_stype,
  };
});

const StyledQuestionBox = styled(Box)<{ fixed?: boolean }>(props => {
  const check_spell_stype = {
    '& > .c_blue, & > .c_red, & > .c_orange, & > .emphasis-word': {
      letterSpacing: '-2.5px',
    },

    '@media (max-width: 1263px)': {
      letterSpacing: '-1.25px',
      '& > .c_blue, & > .c_red, & > .c_orange, & > .emphasis-word': {
        letterSpacing: '-1.25px !important',
      },
    },
  };
  return {
    width: '70%',
    color: '#5f5f5f',
    fontWeight: '700',
    letterSpacing: '-2.5px',
    whiteSpace: 'unset',
    wordBreak: 'keep-all',
    lineBreak: 'unset',
    fontSize: '5vh',
    textAlign: 'center',
    ...check_spell_stype,
  };
});

const StyledSettingIconButton = styled(IconButton)(props => ({
  backgroundColor: '#fff',
  top: '1rem',
  width: '2rem',
  height: '2rem',
  zIndex: 2,
  ...d_flex_center,
  position: 'absolute',
  svg: {
    width: '100%',
    paddingLeft: '2px',
  },
  padding: '0px',
}));

function Speaking(props: SpeakingProps) {
  const sendResultsheet = props.sendResultsheet;
  const { modal_alert } = useContext(ModalContext);
  const setTutorialState = useSetRecoilState(tutorialStateData);
  const userStateData = useRecoilValue<UserType>(userState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { mod, contents, current_step, current_page, show_modal, learning_type, resultsheet, modal_disable } =
    learningStateData;
  const currentStepRef = useRef(current_step);
  const showModalRef = useRef<boolean | null>(null);
  const resultsheetRef = useRef(resultsheet);

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, device_pause, screen_width, screen_height } = deviceStateData;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  // const [videoSrc, setVideoSrc] = useState<string>('');

  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);

  const [html, setHtml] = useState<string | null>(null);

  const timeMapRef = useRef<Array<number | null>>();

  const loaded = useRef<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });
  const [sequence, setSequence] = useState<number[]>([]);

  // PC 권한
  // const recognition = useRef<any>(null);
  // const permissionStatus = useRef<PermissionStatus | null>(null);
  // const permission_init = useRef<boolean>(false);
  // const permission = useRef<boolean>(false);
  // const is_set = useRef<boolean>(false);

  // Mobile 권한
  // const m_recognition_available = useRef<boolean>(false);
  // const m_permission = useRef<boolean>(false);

  const nextCallback = () => {
    sendResultsheet();
  };

  const [videoBlob, setVideoBlob] = useState<string>('');
  const videoBlobRef = useRef<string>('');
  videoBlobRef.current = videoBlob;

  useEffect(() => {
    return () => {
      if (videoBlobRef.current) {
        URL.revokeObjectURL(videoBlobRef.current);
      }
    };
  }, []);

  const getVideo = async (url: string) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setVideoBlob(blobUrl);
      } else {
        //! error
        console.log('error1');
      }
    } catch (error) {
      console.log('error2', error);
    }
  };

  useEffect(() => {
    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      resultsheetRef.current = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheetRef.current,
      }));
    } else {
      resultsheetRef.current = [];
      for (let i = 0; i < contents.length; i++) {
        const sentence = contents[i].sentence ?? '';
        const translate = contents[i].translate ?? '';
        const word_id = contents[i].word_id ?? '';
        const sentence_id = contents[i].sentence_id ?? '';
        resultsheetRef.current[i] = {
          no: i + 1,
          word_id: word_id,
          sentence_id: sentence_id,
          answer: null,
          rightanswer: sentence,
          question: translate,
        };
      }
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheetRef.current,
      }));
    }

    const video_src_url = `${CDN}video/${learningStateData.category_id}/${learningStateData.folder_id}/${
      learningStateData.schedule_name.includes('?')
        ? learningStateData.schedule_name.replace(/\?/g, '')
        : learningStateData.schedule_name
    }.mp4`;

    // setVideoSrc(
    //   `${CDN}video/${learningStateData.category_id}/${learningStateData.folder_id}/${
    //     learningStateData.schedule_name[learningStateData.schedule_name.length - 1] === '?'
    //       ? learningStateData.schedule_name.replace(/\?/g, '')
    //       : learningStateData.schedule_name
    //   }.mp4`,
    // );

    getVideo(video_src_url);

    return () => {
      if (videoRef.current) {
        if (videoRef.current.played) videoRef.current.pause();
        videoRef.current.oncanplaythrough = null;
        videoRef.current.onloadedmetadata = null;
        videoRef.current.ontimeupdate = null;
        videoRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    setVideoSize();
  }, [screen_width, screen_height, videoBlob]);

  function setVideoSize() {
    if (!videoRef.current || !videoBlob) return;

    const video_box = document.getElementById('video-box');
    if (video_box) {
      const style = window.getComputedStyle(video_box);
      const box_width =
        video_box.offsetWidth -
        (parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)) -
        (parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth));
      const box_height =
        video_box.offsetHeight -
        (parseFloat(style.paddingTop) + parseFloat(style.paddingBottom)) -
        (parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth));

      if (box_width >= box_height) {
        setVideoWidth(+box_height);
        setVideoHeight(+box_height);
      } else {
        setVideoWidth(+box_width);
        setVideoHeight(+box_width);
      }
    }
  }

  const moveCurrentSentence = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = Number(contents[currentStepRef.current].start);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.oncanplaythrough = () => {
        if (!loaded.current) {
          loaded.current = true;
          if (videoRef.current) videoRef.current.oncanplaythrough = null;
          moveCurrentSentence();
        }
      };
      videoRef.current.onloadedmetadata = () => {
        if (videoRef.current) {
          const duration = videoRef.current.duration;
          const timeMapping = new Array(Math.ceil(duration * 100)).fill(null);

          // 재생시간에 해당하는 문장 배열의 index를 매핑 (소수점 단위 포함)
          contents.forEach((content, index) => {
            const start = content.start;
            const end = content.end;
            if (start && end) {
              for (let i = Math.ceil(Math.floor(+start * 100)); i <= Math.floor(+end * 100); i++) {
                timeMapping[i] = index;
              }
            }
          });

          timeMapRef.current = [...timeMapping];

          moveCurrentSentence();
          // setLoaded(true);
          setTimeout(() => {
            setPercent(setLoadingStateData, 100);
          }, 200);
        }
      };

      videoRef.current.ontimeupdate = () => {
        if (videoRef.current && timeMapRef.current) {
          const timeIndex = Math.round(videoRef.current.currentTime * 100);

          const start = Number(contents[currentStepRef.current].start);
          const currentTime = videoRef.current.currentTime;
          const end = Number(contents[currentStepRef.current].end);

          const contentIndex = timeMapRef.current[timeIndex];
          if (contentIndex !== currentStepRef.current) {
            setProgress(100);
            videoRef.current.pause();
          } else {
            const progressValue = ((currentTime - start) / (end - start)) * 100;

            if (!showModalRef.current) {
              setProgress(progressValue);
            }
          }
        }
      };

      videoRef.current.load();
    }
  }, [videoBlob]);

  useEffect(() => {
    setProgress(0);
    currentStepRef.current = current_step;
    moveCurrentSentence();
    setHtml(null);
  }, [current_step]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (show_modal || device_pause) {
      videoRef.current.pause();
    } else if (!modal_disable) {
      videoRef.current.play();
    }
  }, [device_pause, show_modal]);

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.speak) {
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
      } else {
        setTimeout(() => {
          showModalRef.current = true;
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('speak_speaking_start', undefined, undefined, () => {
            showModalRef.current = false;
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
        }, 400);
      }
    }
  }, [percent]);

  const finishCallback = (resultsheet: ResultsheetType[]) => {
    const total_question_count = resultsheet.length;
    let total_score = 0;
    for (let i = 0; i < resultsheet.length; i++) {
      const answer = typeof resultsheet[i].answer == 'string' ? resultsheet[i].answer : '   ';
      const rightanswer = typeof resultsheet[i].rightanswer == 'string' ? resultsheet[i].rightanswer : '   ';
      const score = getScore(answer as string, rightanswer as string).score;
      total_score += score;
    }

    const score = total_score / total_question_count;

    const label = makeGradeLabel(score);
    const icon = makeGradeIcon(score);
    const kor_label = makeGradeKorLabel(score);

    setEndModuleModal({ visible: true, icon, label, score, kor_label });
  };

  const onClickReplayButton = (isForce = false) => {
    if (videoRef.current) {
      if (isForce || !(modal_disable || show_modal)) {
        moveCurrentSentence();
        videoRef.current.play();
      }
    }
  };

  return (
    <StyledVideoWrap>
      <StyledVideoBody>
        <StyledVideoTextWrap>
          <StyledVideoTextVideoBox id='video-box'>
            <StyledTextVideoBox
              sx={{
                '& > video': {
                  width: `${videoWidth}px`,
                  height: `${videoHeight}px`,
                },
              }}
            >
              <StyledSettingIconButton
                sx={{
                  right: '1rem',
                  svg: {
                    color: theme.palette.purple.main,
                  },
                }}
                disableRipple
                onClick={() => onClickReplayButton(false)}
              >
                <IoPlay />
              </StyledSettingIconButton>
              <video
                id='speak-video-element'
                // src={`${videoBlob}`}
                ref={videoRef}
                // preload='auto'
                playsInline={true}
                controls={false}
                autoPlay={false}
              >
                <source src={videoBlob} type='video/mp4' />
              </video>
            </StyledTextVideoBox>
          </StyledVideoTextVideoBox>

          <StyledVideoTextTextBox>
            <StyledSpeakWrap>
              {html ? (
                <StyledQuestionBox
                  fixed={html ? true : false}
                  key={`sen_${current_step}`}
                  dangerouslySetInnerHTML={{ __html: html ? html : '' }}
                ></StyledQuestionBox>
              ) : (
                <StyledQuestionBox fixed={html ? true : false} key={`sen_${current_step}`}>
                  {contents[current_step].sentence}
                </StyledQuestionBox>
              )}

              <StyledQuestionAutoText
                minFontSize={1.5}
                maxFontSize={3.25}
                fontSizeUnit='vh'
                sx={{
                  color: '#5f5f5f',
                  fontFamily: 'Apple SD Gothic Neo',
                  fontWeight: '400',
                  position: 'relative',
                  letterSpacing: '-0.425px',
                  top: '1rem',
                }}
                key={`trans_${current_step}`}
              >
                {contents[current_step].translate}
              </StyledQuestionAutoText>
            </StyledSpeakWrap>
            <StyledSpeechWrap>
              {is_mobile ? (
                <SpeechRecognition
                  progress={progress}
                  setHtml={setHtml}
                  finishCallback={finishCallback}
                  onClickReplayButton={() => onClickReplayButton(true)}
                  // recognition_available={m_recognition_available}
                  // permission={m_permission}
                />
              ) : (
                <WebkitSpeechRecognition
                  progress={progress}
                  setHtml={setHtml}
                  finishCallback={finishCallback}
                  onClickReplayButton={() => onClickReplayButton(true)}
                  // recognition={recognition}
                  // permissionStatus={permissionStatus}
                  // permission_init={permission_init}
                  // permission={permission}
                  // is_set={is_set}
                />
              )}
            </StyledSpeechWrap>
          </StyledVideoTextTextBox>
        </StyledVideoTextWrap>
      </StyledVideoBody>
      <StyledVideoBottom>
        <StyledLinearProgress key={`pro_${current_step}`} variant={'determinate'} value={progress} />
      </StyledVideoBottom>
      <ModalEndModule
        visible={endModuleModal.visible}
        label={endModuleModal.label}
        kor_label={endModuleModal.kor_label}
        icon={endModuleModal.icon}
        score={endModuleModal.score}
        nextCallback={nextCallback}
      />
    </StyledVideoWrap>
  );
}

export default Speaking;
