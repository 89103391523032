export default function FontLoader() {
  return (
    <div style={{ position: 'absolute', color: '#fff' }}>
      <div className='SUIT-100'>테스트</div>
      <div className='SUIT-200'>테스트</div>
      <div className='SUIT-300'>테스트</div>
      <div className='SUIT-400'>테스트</div>
      <div className='SUIT-500'>테스트</div>
      <div className='SUIT-600'>테스트</div>
      <div className='SUIT-700'>테스트</div>
      <div className='SUIT-800'>테스트</div>
      <div className='SUIT-900'>테스트</div>
      <div className='Apple-300'>테스트</div>
      <div className='Apple-500'>테스트</div>
      <div className='Apple-700'>테스트</div>
      <div className='Apple-800'>테스트</div>
      <div className='TT-normal'>테스트</div>
      <div className='Nanum-normal'>테스트</div>
      <div className='Nanum-bold'>테스트</div>
      <div className='Inconsolata'>테스트</div>
    </div>
  );
}
