import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { voiceState, useVoice } from '../../recoil/common/voice';
import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { moduleSettingState } from '../../recoil/model/module_setting';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { userState } from '../../recoil/model/user';
import { settingState } from '../../recoil/model/settings';
import { deviceState } from '../../recoil/common/device';

import { ModalContext } from '../../provider/ModalProvider';

import dayjs from 'dayjs';
import { Box, styled, IconButton, IconButtonProps, BoxProps } from '@mui/material';
import produce from 'immer';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { fetchPostApi, fetchPutApi } from '../../utils/api';

import ModalAddPocketNote from '../../components/modal/ModalAddPocketNote';

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { FiStar } from 'react-icons/fi';

import { dir_column, d_flex_center, text_clamp } from '../../styles/common';
import { BASE_URL, CDN, IMAGE_DIR } from '../../utils/constants';
import { getContentFoldername, makeEndModuleModalProps } from '../../utils/tools';
import { options, setModuleCategory } from '../../utils/setting_contents';
import ModalEndModule from '../../components/modal/ModalEndModule';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';
import { AutoTextSize } from 'auto-text-size';

interface FlashcardProps {
  sendResultsheet: () => void;
}

const StyledFlashcardWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledIconButton = styled(IconButton)(props => ({
  position: 'absolute',
  backgroundColor: 'white',
  width: '3rem',
  height: '3rem',
  padding: '0',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
  '&.Mui-disabled > svg': {
    color: '#e8e9ec',
  },
}));

const StyledFlashcardContents = styled(Box)(props => ({
  width: '70%',
  height: '65vh',
  position: 'relative',
  ...d_flex_center,
  ...dir_column,
  backgroundColor: '#fcfaff',
  marginBottom: '3vh',
  borderRadius: '8px',
  cursor: 'pointer',
}));

const StyledLeftHeaderBox = styled(Box)(props => ({
  position: 'absolute',
  display: 'inline-flex',
  alignItems: 'center',
  top: '1.75vh',
  left: '1.75vh',
}));

const StyledRightHeaderBox = styled(Box)(props => ({
  position: 'absolute',
  top: '1.75vh',
  right: '1.75vh',
  display: 'inline-flex',
  alignItems: 'center',
}));

interface CustomSpeakButtonProps extends IconButtonProps {
  speak: number;
}

const CustomSpeakButton = styled(IconButton)<CustomSpeakButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    fill: props.speak == 1 ? props.theme.palette.purple.main : props.theme.palette.gray_2.main,
    width: '3.5vh',
    height: '3.5vh',
  },
}));

const CustomDiagonalLine = styled(Box)(props => ({
  width: '3.5vh',
  height: '2px',
  position: 'absolute',
  backgroundColor: props.theme.palette.red.main,
  transform: 'translateX(-0.25rem) rotate(-45deg)',
}));

interface CustomBookmarkButtonProps extends IconButtonProps {
  bookmark: number;
}

const CustomBookmarkButton = styled(IconButton)<CustomBookmarkButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    color: props.bookmark == 1 ? '#f1bc5f' : props.theme.palette.purple.main,
    width: '3.5vh',
    height: '3.5vh',
    fill: props.bookmark == 1 ? '#f5ff1b' : 'none',
  },
}));

const StyledFlashcardBody = styled(Box)(props => ({
  width: '100%',
  height: '65vh',
  position: 'relative',
  ...d_flex_center,
}));

const StyledImageBox = styled(Box)(props => ({
  width: '70%',
  height: '90%',
  borderRadius: '4px',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const StyledContentsBottom = styled(Box)(props => ({
  ...d_flex_center,
  position: 'absolute',
  width: '100%',
  bottom: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
}));

interface CustomProgressBoxProps extends BoxProps {
  progress: number;
}

const StyledProgressBox = styled(Box)<CustomProgressBoxProps>(props => ({
  height: '1rem',
  width: 'auto',
  backgroundColor: props.progress == 1 ? '#e4dfff' : 'none',
  flex: '1',
  marginLeft: '4px',
  marginRight: '4px',
  borderRadius: '4px',
}));

const StyledAutoTextSpellContainer = styled(Box)(props => ({
  overflow: 'hidden',
  width: '80%',
  '& .learning-auto-text': {
    fontFamily: "'KCC-Ganpan' !important",
    ...text_clamp,
  },
  ...d_flex_center,
}));

function Flashcard(props: FlashcardProps) {
  const sendResultsheet = props.sendResultsheet;

  const setTutorialState = useSetRecoilState(tutorialStateData);
  const { modal_alert } = useContext(ModalContext);

  const userStateData = useRecoilValue<UserType>(userState);

  const [voiceStateData, setVoiceStateData] = useRecoilState<VoiceType>(voiceState);
  const { ready, playing, element } = voiceStateData;
  const { setVoice, voicePlay, setAudioRate } = useVoice();
  const playingRef = useRef(playing);
  playingRef.current = playing;

  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const {
    mod,
    resultsheet,
    current_step,
    contents,
    current_page,
    show_modal,
    current_module_settings,
    use_image,
    direction,
  } = learningStateData;
  const currentStepRef = useRef(current_step);
  currentStepRef.current = current_step;
  const showModalRef = useRef(show_modal);
  showModalRef.current = show_modal;

  const pocketNoteStateData = useRecoilValue<PocketNoteType>(pocketNoteState);
  const { note_contents } = pocketNoteStateData;

  const settingStateData = useRecoilValue(settingState);
  const { enable_keyboard } = settingStateData;

  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, is_mobile, device_pause } = deviceStateData;

  const [moduleSettingStateData, setModuleSettingStateData] = useRecoilState<ModuleSettings>(moduleSettingState);
  const { flash } = current_module_settings;
  const maxVoiceCount = flash.count + 1;
  const [voiceCount, setVoiceCount] = useState<number>(0);
  const voiceCountRef = useRef(voiceCount);
  voiceCountRef.current = voiceCount;

  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [imgReady, setImgReady] = useState<boolean>(false);
  const [spell, setSpell] = useState<string>('');
  const [mean, setMean] = useState<string>('');
  const [flashOn, setFlashOn] = useState<boolean>(true);

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const [visibleAddPocketNote, setVisibleAddPocketNote] = useState<boolean>(false);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [flashReady, setFlashReady] = useState<boolean>(false);

  const [firstFlash, setFirstFlash] = useState<boolean>(true);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const settimeout2 = useRef<ReturnType<typeof setTimeout>[]>([]);

  const [beforeStep, setBeforeStep] = useState(0);
  const [animationDirection, setAnimationDirection] = useState('horizontal-right');

  const startX = useRef(0);
  const startY = useRef(0);

  const [disableMove, setDisableMove] = useState<boolean>(false);

  const [pause, setPause] = useState(false);
  const pauseRef = useRef(pause);

  const [key, setKey] = useState(0);

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });

  const nextCallback = () => {
    sendResultsheet();
  };

  useEffect(() => {
    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      const resultsheet_tmp = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
      }));
    } else {
      const resultsheet_tmp: ResultsheetType[] = [];
      for (let i = 0; i < contents.length; i++) {
        resultsheet_tmp[i] = {
          no: i + 1,
          word_id: contents[i].id,
          answer: null,
          rightanswer: contents[i].spell,
          question: contents[i].mean,
        };
      }
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
      }));
    }
    setPercent(setLoadingStateData, 100);
    return () => {
      clearTimeout1();
      clearTimeout2();
      setVoiceStateData(() => ({
        element: undefined,
        playing: false,
        loading: false,
        error: false,
        ready: false,
        volume: 1,
      }));
      setLearningStateData(prevState => ({
        ...prevState,
        direction: '',
      }));
    };
  }, []);

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.basic) {
        //  튜토리얼을 진행해야 한다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
        setTimeout(() => {
          setLoaded(true);
        }, 400);
      } else {
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('flashcard_start', undefined, undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
          setLoaded(true);
        }, 400);
      }
    }
  }, [percent]);

  useEffect(() => {
    if (!playing && ready && !show_modal && flashReady && !pause && !device_pause) {
      const timer = voiceCount == 0 ? 300 : 300 * (6 - flash.flash);
      settimeout1.current.push(
        setTimeout(() => {
          contentVoicePlay();
        }, timer),
      );
    }
  }, [playing, ready, show_modal, flashReady, pause, device_pause]);

  useEffect(() => {
    if (direction == 'right') {
      setAnimationDirection('horizontal-right');
    } else if (direction == 'left') {
      setAnimationDirection('horizontal-left');
    }
  }, [direction]);

  useEffect(() => {
    setContent(contents[current_step]);

    if (loaded) {
      const box = document.getElementById('contents-box');
      if (beforeStep > current_step) {
        if (box) {
          box.style.transform = 'translateX(150%)';
          box.style.position = 'absolute';
          box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
        }
      } else if (beforeStep < current_step) {
        if (box) {
          box.style.transform = 'translateX(-150%)';
          box.style.position = 'absolute';
          box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
        }
      }
    }

    setBeforeStep(current_step);

    setVoiceCount(0);
    voiceCountRef.current = 0;

    setPause(false);
    pauseRef.current = false;

    clearTimeout1();
    clearTimeout2();
    setTimeout(() => {
      setFlashOn(true);
    }, 1);
    setDisableMove(true);
    setTimeout(() => {
      setDisableMove(false);
    }, 1000);
    setFlashReady(false);
    if (firstFlash) {
      setFirstFlash(false);
      settimeout2.current.push(
        setTimeout(() => {
          setFlashReady(true);
        }, 2000),
      );
    } else {
      settimeout2.current.push(
        setTimeout(() => {
          setFlashReady(true);
        }, 1),
      );
    }
  }, [current_step]);

  useEffect(() => {
    if (voiceCount == 0) contentVoicePlay();
  }, [voiceCount]);

  const contentVoicePlay = () => {
    const before_step = current_step;
    const before_count = voiceCountRef.current;
    clearTimeout1();
    if (!loaded || !ready || show_modal || !flashReady || pauseRef.current) return;
    if (voiceCountRef.current < maxVoiceCount) {
      if (voiceCountRef.current > 0) setFlashOn(false);
      settimeout1.current.push(
        setTimeout(() => {
          if (
            before_step == currentStepRef.current &&
            before_count == voiceCountRef.current &&
            !showModalRef.current &&
            !pauseRef.current
          ) {
            setFlashOn(true);
            voiceCountRef.current++;
            setVoiceCount(voiceCountRef.current);
            voicePlay();
            const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
            if (
              resultsheet_tmp[currentStepRef.current].answer !== resultsheet_tmp[currentStepRef.current].rightanswer
            ) {
              resultsheet_tmp[currentStepRef.current].answer = '333';
              setLearningStateData(prevState => ({
                ...prevState,
                resultsheet: resultsheet_tmp,
              }));
            }
          }
        }, 300),
      );
    } else {
      // 여기서, answer를 rightanswer로 넣어줘야 한다.
      settimeout1.current.push(
        setTimeout(() => {
          if (before_step == currentStepRef.current && before_count == voiceCountRef.current && !showModalRef.current) {
            const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
            resultsheet_tmp[currentStepRef.current].answer = resultsheet_tmp[currentStepRef.current].rightanswer;
            setLearningStateData(prevState => ({
              ...prevState,
              resultsheet: resultsheet_tmp,
            }));
            goNext();
          }
        }, 300),
      );
    }
  };

  useEffect(() => {
    if (flash.audio == 0) {
      setVoiceStateData(prevState => ({
        ...prevState,
        volume: 0,
      }));
    } else {
      setVoiceStateData(prevState => ({
        ...prevState,
        volume: 1,
      }));
    }
  }, [flash.audio]);

  useEffect(() => {
    if (ready) {
      setAudioRate(Number(options['flash']['speed'][flash.speed].value));
    }
  }, [ready]);

  const clearTimeout1 = () => {
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
  };

  const clearTimeout2 = () => {
    for (let i = 0; i < settimeout2.current.length; i++) {
      clearTimeout(settimeout2.current[i]);
    }
    settimeout2.current = [];
  };

  useEffect(() => {
    if (show_modal) clearTimeout1();
    if (enable_keyboard) {
      bindKeyboard();
    }
    bindTouch();
    return () => {
      if (enable_keyboard) {
        unbindKeyboard();
      }
      unbindTouch();
    };
  }, [current_page, spell, show_modal, disableMove, pause]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const bindTouch = () => {
    document.addEventListener('touchstart', touchStart);
    document.addEventListener('touchend', touchEnd);
  };

  const unbindTouch = () => {
    document.removeEventListener('touchstart', touchStart);
    document.removeEventListener('touchend', touchEnd);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (show_modal || !loaded) return false;
    if (e.keyCode == 39) {
      goNext();
    } else if (e.keyCode == 37) {
      goPrev();
    } else if (e.keyCode == 32) {
      onClickBody();
    }
  };

  const touchStart = (e: TouchEvent) => {
    if (show_modal || mod[current_page].module == 6) return false;
    startX.current = e.touches[0].pageX;
    startY.current = e.touches[0].pageY;
  };

  const touchEnd = (e: TouchEvent) => {
    if (show_modal || mod[current_page].module == 6) return false;
    const move_x =
      startX.current > e.changedTouches[0].pageX
        ? startX.current - e.changedTouches[0].pageX
        : e.changedTouches[0].pageX - startX.current;
    const move_y =
      startY.current > e.changedTouches[0].pageY
        ? startY.current - e.changedTouches[0].pageY
        : e.changedTouches[0].pageY - startY.current;

    if (move_x > move_y && move_x > screen_width / 10) {
      if (startX.current > e.changedTouches[0].pageX) {
        goNext();
      } else {
        goPrev();
      }
    }
  };

  const onClickBody = () => {
    setPause(!pause);
    pauseRef.current = !pause;
    if (!pause) {
      clearTimeout1();
      setFlashOn(true);
    }
  };

  useEffect(() => {
    if (pause) {
      setFlashOn(true);
    } else if (loaded) {
      clearTimeout2();
      settimeout2.current.push(
        setTimeout(() => {
          setFlashReady(true);
        }, 1),
      );
    }
  }, [pause]);

  useEffect(() => {
    setSpell(content['spell']);
    setMean(content['mean']);
  }, [content]);

  useEffect(() => {
    if (spell) {
      const voice = content['voice'] ? content['voice'] : '0.mp3';
      const image = content['image'] ? content['image'] : '0.jpg';
      const foldername = getContentFoldername(spell);
      setVoice(spell, voice);
      if (use_image == 1) setImgSrc(`${CDN}${IMAGE_DIR}${foldername}/${image}`);
    }
  }, [spell]);

  useEffect(() => {
    imgCheck();
  }, [imgSrc]);

  const goNext = () => {
    if (disableMove) return false;
    if (current_step == contents.length - 1) {
      const { label, icon, score, kor_label } = makeEndModuleModalProps(resultsheet);
      setEndModuleModal({ visible: true, icon, label, score, kor_label });
      setPause(true);
      pauseRef.current = true;
    } else {
      setAnimationDirection('horizontal-right');
      setTimeout(() => {
        setVoiceStateData(prevState => ({
          ...prevState,
          element: undefined,
          playing: false,
          loading: false,
          error: false,
          ready: false,
        }));

        setVoiceCount(0);
        voiceCountRef.current = 0;
        setCurrentStep(current_step + 1);
      }, 1);
    }
  };

  const goPrev = () => {
    if (disableMove) return false;
    if (current_step == 0) return false;

    setAnimationDirection('horizontal-left');
    setTimeout(() => {
      setVoiceStateData(prevState => ({
        ...prevState,
        element: undefined,
        playing: false,
        loading: false,
        error: false,
        ready: false,
      }));
      setVoiceCount(0);
      voiceCountRef.current = 0;
      setCurrentStep(current_step - 1);
    }, 1);
  };

  const setCurrentStep = (step: number) => {
    if (current_step == step) return false;
    setVoiceStateData(prevState => ({
      ...prevState,
      element: undefined,
      playing: false,
      loading: false,
      error: false,
      ready: false,
    }));
    setLearningStateData(prevState => ({
      ...prevState,
      current_step: step,
    }));
  };

  const toggleSpeak = () => {
    const newSettings = JSON.parse(JSON.stringify(moduleSettingStateData));
    newSettings['flash']['audio'] = flash.audio == 1 ? 0 : 1;
    if (voiceStateData.element) voiceStateData.element.volume = flash.audio == 1 ? 0 : 1;
    setModuleSettingStateData(() => ({
      ...newSettings,
    }));
    const settings: { [key: string]: { [key: string]: number } } = {
      flash: newSettings['flash'],
    };
    const postdata = {
      type: learningStateData.book_type,
      scheduleId: learningStateData.schedule_id,
      settings,
    };
    fetchPostApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/settings?ver=2`,
      postdata,
    );
    const nextLearningStateData = produce(learningStateData, draft => {
      draft.current_module_settings.flash.audio = flash.audio == 1 ? 0 : 1;
    });
    setLearningStateData(() => ({
      ...nextLearningStateData,
    }));
  };

  const toggleBookmark = () => {
    if (!note_contents.some(word => word.spell == spell)) {
      setVisibleAddPocketNote(true);
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  };

  const VoiceIcon = (bol: boolean) => {
    if (bol)
      return (
        <svg viewBox='0 0 43 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 3.50134L6.05859 14.5006L3.5 14.4998C2.2693 14.4998 0.5 14.3263 0.5 15.4998V25.4998C0.5 26.6736 2.2693 26.4998 3.5 26.4998H6L18 37.4998C19.4918 38.5666 21.0027 39.2778 21.0027 37.4998V3.50065C21.0027 1.72268 19.4545 2.43456 18 3.50134Z'
            fill='current'
          />
          <path
            d='M26.7745 15.3426C26.1462 14.8858 25.0992 14.8858 24.4712 15.3426C23.8429 15.7994 23.8429 16.5608 24.4712 17.0176C27.496 19.2171 27.496 22.7873 24.4712 24.9868C23.8429 25.4436 23.8429 26.205 24.4712 26.6616C24.7967 26.8985 25.2155 27 25.6111 27C26.0066 27 26.449 26.8817 26.7512 26.6447C31.079 23.5485 31.079 18.4727 26.7745 15.3426Z'
            fill='current'
          />
          <path
            d='M28.5026 8.49083C27.8325 9.14929 27.8325 10.2467 28.5026 10.9052C31.1094 13.4658 32.5496 16.8557 32.5496 20.4894C32.5496 24.123 31.1094 27.5129 28.5026 30.0735C27.8325 30.732 27.8325 31.8294 28.5026 32.4879C28.8501 32.829 29.297 33 29.7191 33C30.166 33 30.6128 32.8293 30.9356 32.5123C34.1879 29.3175 36 25.0498 36 20.5137C36 15.9777 34.2125 11.7343 30.9356 8.51522C30.2901 7.83238 29.1977 7.83238 28.5026 8.49083Z'
            fill='current'
          />
        </svg>
      );
    else
      return (
        <svg viewBox='0 0 43 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 3.50134L6.05859 14.5006L3.5 14.4998C2.2693 14.4998 0.5 14.3263 0.5 15.4998V25.4998C0.5 26.6736 2.2693 26.4998 3.5 26.4998H6L18 37.4998C19.4918 38.5666 21.0027 39.2778 21.0027 37.4998V3.50065C21.0027 1.72268 19.4545 2.43456 18 3.50134Z'
            fill='current'
          />
        </svg>
      );
  };

  const imgCheck = () => {
    const img = new Image();

    img.onload = function () {
      setImgReady(true);
    };

    img.onerror = function () {
      setImgReady(false);
    };

    img.src = imgSrc;
  };

  useEffect(() => {
    setKey(key + 1);
  }, [imgReady]);

  return (
    <StyledFlashcardWrap>
      <StyledIconButton
        disabled={current_step == 0 || disableMove}
        onClick={() => goPrev()}
        sx={{ left: '16px' }}
        disableRipple
      >
        <AiOutlineLeft />
      </StyledIconButton>
      <StyledIconButton disabled={disableMove} onClick={() => goNext()} sx={{ right: '16px' }} disableRipple>
        <AiOutlineRight />
      </StyledIconButton>
      <SwitchTransition mode='in-out'>
        <CSSTransition key={`${current_step}`} classNames={animationDirection} timeout={300}>
          <StyledFlashcardContents onClick={() => onClickBody()} id='contents-box'>
            <StyledLeftHeaderBox sx={{ justifyContent: 'flex-start', paddingLeft: '1rem' }}>
              <CustomSpeakButton disableRipple onClick={toggleSpeak} speak={flash.audio}>
                {VoiceIcon(flash.audio == 1 ? true : false)}
                {flash.audio == 1 ? null : <CustomDiagonalLine />}
              </CustomSpeakButton>
            </StyledLeftHeaderBox>
            <StyledRightHeaderBox sx={{ justifyContent: 'flex-end', paddingRight: '1rem' }}>
              <CustomBookmarkButton
                disableRipple
                onClick={toggleBookmark}
                bookmark={note_contents.some(word => word.spell == spell) ? 1 : 0}
              >
                <FiStar />
              </CustomBookmarkButton>
            </StyledRightHeaderBox>
            <StyledFlashcardBody sx={{ visibility: flashOn ? 'visible' : 'hidden' }}>
              {imgReady ? (
                <Box
                  sx={{
                    ...d_flex_center,
                    width: '50%',
                    height: '55vh',
                    borderRight: 'thin solid #e3dfff',
                    paddingRight: '5%',
                    paddingLeft: '5%',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '75%',
                      position: 'relative',
                      overflow: 'hidden',
                      borderRadius: '1.5rem',
                      '&:before': {
                        content: '""',
                        display: 'block',
                      },
                    }}
                  >
                    <Box
                      component={'img'}
                      src={imgSrc}
                      sx={{
                        width: 'auto',
                        maxWidth: '100%',
                        height: 'fit-content',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        borderRadius: '1.5rem',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        clipPath: 'inset(0 0 0 0 round 1.5rem)',
                      }}
                    ></Box>
                  </Box>
                </Box>
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center !important',
                  justifyContent: 'center',
                  ...dir_column,
                  width: imgReady ? '50%' : '100%',
                }}
              >
                <StyledAutoTextSpellContainer sx={{ width: '80%' }}>
                  <AutoTextSize
                    className='learning-auto-text'
                    style={{
                      letterSpacing: screen_width > 1023 ? '-3px' : '-1px',
                      fontWeight: '500',
                      color: 'var(--purple)',
                      paddingBottom: '1vh',
                    }}
                    mode='multiline'
                    minFontSizePx={is_mobile ? 19 : 30}
                    maxFontSizePx={is_mobile ? 32 : 76}
                  >
                    {spell}
                  </AutoTextSize>
                </StyledAutoTextSpellContainer>
                <br />
                <Box
                  sx={{
                    width: '80%',
                    color: 'var(--black-2)',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center !important',
                    justifyContent: 'center',
                    ...dir_column,
                    '& .auto-text-mean': {
                      ...text_clamp,
                    },
                  }}
                >
                  <AutoTextSize
                    mode='multiline'
                    className='auto-text-mean'
                    minFontSizePx={is_mobile ? 11 : 15}
                    maxFontSizePx={is_mobile ? 15 : 37}
                  >
                    {mean}
                  </AutoTextSize>
                </Box>
              </Box>
            </StyledFlashcardBody>
            <StyledContentsBottom>
              {(function () {
                const arr = [];
                for (let i = 1; i <= maxVoiceCount; i++) {
                  arr.push(
                    <StyledProgressBox
                      key={`flashcard_progress_${current_step}_${i}`}
                      progress={voiceCount >= i ? 1 : 0}
                    />,
                  );
                }
                return arr;
              })()}
            </StyledContentsBottom>
          </StyledFlashcardContents>
        </CSSTransition>
      </SwitchTransition>
      <ModalAddPocketNote
        visible={visibleAddPocketNote}
        onClose={() => {
          setVisibleAddPocketNote(false);
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: false,
          }));
        }}
        content={[content]}
      />
      <ModalEndModule
        visible={endModuleModal.visible}
        label={endModuleModal.label}
        kor_label={endModuleModal.kor_label}
        icon={endModuleModal.icon}
        score={endModuleModal.score}
        nextCallback={nextCallback}
      />
    </StyledFlashcardWrap>
  );
}

export default Flashcard;
