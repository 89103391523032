import { FormControl, Button, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { d_flex_center, d_flex_start, StyledModalUtil, StyledLine, dir_column, d_flex } from '../../styles/common';
import { AiOutlineLeft } from 'react-icons/ai';
import stepping from '../../assets/images/checkup/stepping.png';
import sichuan from '../../assets/images/checkup/sichuan.png';
import acidrain from '../../assets/images/checkup/acidrain.png';
import hangman from '../../assets/images/checkup/hangman.png';

interface ModalCheckupModeProps {
  visible: boolean;
  onClose: () => void;
}

// style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '65%',
  maxWidth: '80%',
  width: '80rem',
  minHeight: '70%',
  maxHeight: '85%',
  height: '60rem',
};

const StyledLeaderboardModalWrapper = styled(FormControl)(props => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  backgroundColor: '#f1f5f9',
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '1rem',
}));

const CustomBackButton = styled(IconButton)(props => ({
  color: '#9a9a9a',
  svg: {
    color: '#9a9a9a',
    width: '1.5rem',
    height: '1.5rem',
  },
}));

const StyledModalBody = styled(Box)(props => ({
  padding: '2vh 4vh',
  ...d_flex_center,
  width: '100%',
  height: '100%',
  flexDirection: 'column',
}));

const StyledModalTitle = styled(Box)(props => ({
  ...d_flex_start,
  width: '100%',
  height: 'calc(2rem + 8px)',
}));

const StyledModalContents = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 2rem - 8px)',
}));

const StyledModalContent = styled(Box)(props => ({
  height: 'calc(50% - 2rem)',
  width: 'calc(50% - 2rem)',
  margin: '1rem',
  display: 'inline-flex',
  borderRadius: '8px',
  flexDirection: 'column',
}));

const ModalConfirmButton = styled(Button)(({ theme }) => ({
  fontSize: '1.075rem',
  width: 'calc(50% - 0.5px)',
  height: '100%',
  '&:hover': {
    backgroundColor: 'unset',
  },
}));

const StyledModalContentBody = styled(Box)(props => ({
  padding: '1rem',
  width: '100%',
  height: 'calc(100% - 54px)',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
}));

const CustomStyledModalUtil = styled(StyledModalUtil)(props => ({
  backgroundColor: '#2edbe1',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  borderTop: 'unset',
  height: '4.25rem',
  '& > button': {
    fontSize: '1.25rem',
  },
}));

function ModalCheckupMode(props: ModalCheckupModeProps) {
  const visible = props.visible;
  const onClose = props.onClose;

  return (
    <Modal
      open={visible}
      onClose={() => ''}
      sx={{
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Box sx={style}>
        <StyledLeaderboardModalWrapper>
          <StyledModalBody>
            <StyledModalTitle>
              <CustomBackButton onClick={onClose} disableRipple>
                <AiOutlineLeft /> &nbsp;포켓노트
              </CustomBackButton>
            </StyledModalTitle>
            <StyledModalContents>
              <StyledModalContent>
                <StyledModalContentBody sx={{ backgroundImage: `url(${stepping})` }}></StyledModalContentBody>
                <CustomStyledModalUtil>
                  <ModalConfirmButton color='white' disableRipple>
                    징검다리
                  </ModalConfirmButton>
                </CustomStyledModalUtil>
              </StyledModalContent>
              <StyledModalContent>
                <StyledModalContentBody sx={{ backgroundImage: `url(${sichuan})` }}></StyledModalContentBody>
                <CustomStyledModalUtil>
                  <ModalConfirmButton color='white' disableRipple>
                    사천성
                  </ModalConfirmButton>
                </CustomStyledModalUtil>
              </StyledModalContent>
              <StyledModalContent>
                <StyledModalContentBody sx={{ backgroundImage: `url(${acidrain})` }}></StyledModalContentBody>
                <CustomStyledModalUtil>
                  <ModalConfirmButton color='white' disableRipple>
                    산성비
                  </ModalConfirmButton>
                </CustomStyledModalUtil>
              </StyledModalContent>
              <StyledModalContent>
                <StyledModalContentBody sx={{ backgroundImage: `url(${hangman})` }}></StyledModalContentBody>
                <CustomStyledModalUtil>
                  <ModalConfirmButton color='white' disableRipple>
                    행맨
                  </ModalConfirmButton>
                </CustomStyledModalUtil>
              </StyledModalContent>
            </StyledModalContents>
          </StyledModalBody>
        </StyledLeaderboardModalWrapper>
      </Box>
    </Modal>
  );
}

export default ModalCheckupMode;
