import { styled, Modal as MuiModal, Box } from '@mui/material';

export const Modal = styled(MuiModal)({
  zIndex: '1301',
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

export const Wrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 'auto',
  height: '100%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
});

export const ContentWrap = styled(Box)(props => ({
  overflow: 'hidden',
  width: '100%',
  minWidth: '38.75rem',
  maxWidth: '38.75rem',
  height: '38.75rem',
  borderRadius: '1rem',
  position: 'relative',
  '& .arrow-back, .arrow-next': {
    color: '#6100ff',
    position: 'absolute',
    top: '0',
    zIndex: '99',
    cursor: 'pointer',
    width: '3rem',
    height: '100%',
  },
  '& .arrow-back': {
    left: '0',
  },
  '& .arrow-next': {
    right: '0',
  },

  '@media (max-width: 1903px)': {
    minWidth: '49.4rem',
    maxWidth: '49.4rem',
    height: '49.4rem',
  },

  '@media (max-width: 1263px)': {
    minWidth: '42.5rem',
    maxWidth: '42.5rem',
    height: '42.5rem',
  },
  '@media (max-width: 959px)': {
    minWidth: '42.5rem',
    maxWidth: '42.5rem',
    height: '42.5rem',
  },
  '@media (max-width: 767px)': {
    minWidth: '35rem',
    maxWidth: '35rem',
    height: '35rem',
  },
}));

export const ModalCloseButton = styled(Box)({
  position: 'absolute',
  color: 'white',
  top: '1rem',
  right: '1rem',
  zIndex: 1,
  cursor: 'pointer',
  svg: {
    '@media (max-width: 959px)': {
      minWidth: '20px',
      maxWidth: '20px',
      height: '20px',
    },
    '@media (max-width: 767px)': {
      minWidth: '20px',
      maxWidth: '20px',
      height: '20px',
    },
  },
});

export const MissionConfirmButton = styled(Box)(props => ({
  position: 'absolute',
  color: 'white',
  cursor: 'pointer',
  backgroundColor: '#6100ff',
  width: '100%',
  height: '3rem',
  bottom: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
}));

export const LastImgDescription = styled(Box)(props => ({
  position: 'absolute',
  bottom: '7.9rem',
  width: '100%',
  textAlign: 'center',
  zIndex: '2',
  fontSize: '1.3rem',
  fontWeight: '900',
  '& > span': {
    letterSpacing: '-1.5px',
  },
  '& > span:first-of-type': {
    color: 'green',
  },
}));
