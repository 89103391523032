import { useState, useEffect } from 'react';

interface IconReportProps {
  seq_id: string;
}

function IconReportO(props: IconReportProps) {
  const seq_id = props.seq_id;

  return (
    <svg
      width='80'
      height='80'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 80 80'
      xmlSpace='preserve'
    >
      <defs>
        <linearGradient id={seq_id} x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor='#6de9ff'>
            <animate
              attributeName='stop-color'
              values='#6de9ff; #0594c4; #6de9ff'
              dur='10s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='100%' stopColor='#0594c4'>
            <animate
              attributeName='stop-color'
              values='#0594c4; #6de9ff; #0594c4'
              dur='10s'
              repeatCount='indefinite'
            ></animate>
          </stop>
        </linearGradient>
      </defs>
      <g>
        <rect fill='none' id='canvas_background' height='82' width='82' y='-1' x='-1' />
        <g display='none' overflow='visible' y='0' x='0' height='100%' width='100%' id='canvasGrid'>
          <rect fill='url(#gridpattern)' strokeWidth='0' y='0' x='0' height='100%' width='100%' />
        </g>
      </g>
      <g>
        <ellipse
          id='svg_4'
          cy='50.453125'
          cx='73'
          fillOpacity='null'
          strokeOpacity='null'
          strokeWidth='11'
          stroke='#000'
          fill='#fff'
        />
        <ellipse
          stroke={`url(#${seq_id})`}
          ry='30.000001'
          rx='30.000001'
          id='icon-report-o-circle'
          cy='40.453126'
          cx='40.000001'
          strokeOpacity='null'
          strokeWidth='18'
          fill='none'
        />
      </g>
    </svg>
  );
}

export default IconReportO;
