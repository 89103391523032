import * as React from 'react';
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';

interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  small?: boolean;
}

const purple = {
  400: '#4620e9',
  500: '#a34fd0',
};

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
};

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 2.85rem;
  height: 1.35rem;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${grey[400]};
    border-radius: 0.71rem;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 1.1rem;
    height: 1.1rem;
    top: 0.1425rem;
    left: 0.17rem;
    border-radius: 1.14rem;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 0.0714rem 0.5714rem rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 1.65rem;
      top: 0.145rem;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #1bd05b;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }

  &.small {
    width: 2.5rem;
    height: 1.2rem;
    margin-bottom: -0.05rem;
    & .${switchUnstyledClasses.thumb} {
      width: 0.9rem;
      height: 0.9rem;
      top: 50%;
      transform: translateY(-50%);
      left: 0.17rem;
      border-radius: 1.14rem;
      background-color: #fff;
    }

    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 1.5rem;
      }
    }
  }
`;

export default function UnstyledSwitches(props: SwitchProps) {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  const checked = props.checked;
  const onChange = props.onChange;
  const small = props.small;

  return (
    <SwitchUnstyled
      checked={checked}
      onChange={onChange}
      component={Root}
      className={small ? 'small' : ''}
      {...label}
    />
  );
}
