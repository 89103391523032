import { useState, createContext, useContext } from 'react';
// ========================================================================================
// Type 설정

interface GlobalTableDataType {
  checked_cnt: number;
  checked_idx: number[];
}

interface TableControllerType {
  // Modal Confrim
  table_data: {
    [key: string]: GlobalTableDataType;
  };
  clearTableData: () => void;
  clearTargetTableData: (data_key: string) => void;
  setSavedTableIdx: (data_key: string, idx: number[]) => void;
}

// ========================================================================================
// 초기값 설정
export const TableContext = createContext<TableControllerType>({
  table_data: {},
  clearTableData: () => {
    return;
  },
  clearTargetTableData: (data_key: string) => {
    return;
  },
  setSavedTableIdx: (data_key: string, idx: number[]) => {
    return;
  },
});

// ========================================================================================
// Provider 설정

function TableProvider(props: { children: React.ReactNode }) {
  const children = props.children;
  const [tableData, setTableData] = useState<{ [key: string]: GlobalTableDataType }>({});

  // 모달 컨트롤러
  const TableController: TableControllerType = {
    table_data: tableData,
    clearTableData: () => {
      setTableData({});
      return;
    },
    clearTargetTableData: (data_key: string) => {
      setTableData(state => {
        return {
          ...state,
          [data_key]: {
            ...state[data_key],
            checked_idx: [],
            checked_cnt: 0,
          },
        };
      });
      return;
    },
    setSavedTableIdx: (data_key: string, idx: number[]) => {
      setTableData(state => {
        return {
          ...state,
          [data_key]: {
            ...state[data_key],
            checked_cnt: idx.length,
            checked_idx: [...idx],
          },
        };
      });
      return;
    },
  };

  return (
    <>
      <TableContext.Provider value={TableController}>{children}</TableContext.Provider>
    </>
  );
}

export default TableProvider;
