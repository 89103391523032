import React from 'react';
import { styled, Box, Checkbox } from '@mui/material';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';

const TableHead = styled(Box)({
  backgroundColor: '#f5f5f5',
  height: '3.5rem',
  fontWeight: 'bold',
});

const TableRow = styled(Box)<{ isfolder?: string }>(props => ({
  position: 'relative',
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: '3.5rem 5.5fr 5.5fr',
  backgroundColor: 'transparent',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '&:first-of-type': {
      borderRight: '1px solid #ededef',
      justifyContent: 'center',
    },
    '&:nth-of-type(2)': {
      borderRight: props.isfolder == 'true' ? 'none' : '1px solid #ededef',
      paddingLeft: '1rem',
    },
    '&:nth-of-type(3)': {
      paddingLeft: '1rem',
    },
  },
}));

const TableCell = styled(Box)({
  height: '3.5rem',
  borderBottom: '1px solid #ededef',
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
});

const SortBox = styled(Box)({
  position: 'relative',
  marginLeft: '4px',
  marginTop: '-0.125rem',
  svg: {
    position: 'absolute',
    width: '0.625rem',
    height: '0.625rem',
    color: 'var(--gray-1)',
    '&:first-of-type': {
      top: '50%',
      transform: 'translateY(-50%)',
      marginTop: '-0.25rem',
    },
    '&:last-of-type': {
      bottom: '50%',
      transform: 'translateY(50%)',
      marginBottom: '-0.25rem',
    },
  },
});

export interface PocketNoteTableFieldType {
  isNote?: boolean;
  key?: string;
  label: string;
}

export interface PocketNoteTableHeadProps {
  tableFields: PocketNoteTableFieldType[];
  checkedWrodListCount: number;
  wordListLength: number;
  tableQuery: { target: string; sort: string };
  onClickAllCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sortingMethod: (sortKey: string) => void;
}

function PocketNoteTableHead({
  tableFields,
  checkedWrodListCount,
  wordListLength,
  tableQuery,
  onClickAllCheckbox,
  sortingMethod,
}: PocketNoteTableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {tableFields.map((tableField, tIdx) => (
          <TableCell key={`table_field_${tIdx}`}>
            {tableField.isNote ? (
              tableField.label === 'check' ? null : (
                <>
                  {tableField.label}
                  <SortBox onClick={() => sortingMethod(tableField.key as string)}>
                    <GoTriangleUp
                      color={tableQuery.target == tableField.key && tableQuery.sort == 'asc' ? '#000' : '#d8d8d8'}
                    />
                    <GoTriangleDown
                      color={tableQuery.target == tableField.key && tableQuery.sort == 'desc' ? '#000' : '#d8d8d8'}
                    />
                  </SortBox>
                </>
              )
            ) : tableField.label === 'check' ? (
              <Checkbox
                onChange={onClickAllCheckbox}
                checked={checkedWrodListCount === wordListLength - 1 && wordListLength > 1}
                indeterminate={checkedWrodListCount > 0 && checkedWrodListCount !== wordListLength - 1 ? true : false}
              />
            ) : (
              <>
                {tableField.label}
                <SortBox onClick={() => sortingMethod(tableField.key as string)}>
                  <GoTriangleUp
                    color={tableQuery.target == tableField.key && tableQuery.sort == 'asc' ? '#000' : '#d8d8d8'}
                  />
                  <GoTriangleDown
                    color={tableQuery.target == tableField.key && tableQuery.sort == 'desc' ? '#000' : '#d8d8d8'}
                  />
                </SortBox>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default React.memo(PocketNoteTableHead);
