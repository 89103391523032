import { NumberToEmoji } from 'components/common/NumberToEmoji';
import { Box, styled } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { modalManual2State, closeModalManual2 } from 'recoil/common/modalManual2';
import { deviceState } from 'recoil/common/device';
import { useCallback } from 'react';

const Container = styled(Box)({
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: '1000',
  overflowX: 'hidden',
  overflowY: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  inset: '0',
  position: 'fixed',
  '&  *': {
    fontFamily: 'Apple SD Gothic Neo',
  },
});

const Wrapper = styled(Box)({
  minWidth: '18.75rem',
  backgroundColor: '#fff',
  borderRadius: '0.5rem',
  position: 'relative',
  top: '1.75rem',

  transform: 'scale(1.1)',

  '@media (min-width: 1266px) and (max-width: 1905px)': {
    transform: 'scale(1.6)',
  },

  '@media (min-width: 962px) and (max-width: 1265px)': {
    transform: 'scale(1.4)',
  },

  '@media (min-width: 770px) and (max-width: 961px)': {
    transform: 'scale(1.35)',
  },

  '@media (max-width: 769px)': {
    transform: 'scale(1.15)',
  },
});

const TopWrap = styled(Box)({
  padding: '1.5rem',
});

const Title = styled('p')({
  color: 'rgb(75, 85, 99)',
  fontWeight: '600',
  marginBottom: '0.25rem',
});

const SubTitle = styled('p')({
  color: 'rgb(156, 163, 175)',
  fontWeight: '600',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  cursor: 'pointer',
  marginTop: '0.75rem',
  marginBottom: '1rem',
});

const ContentBox = styled(Box)({
  width: '18.75rem',
  background: 'rgb(249, 246, 255)',
  padding: '1rem',
  borderRadius: '0.5rem',
  marginBottom: '0.5rem',
  '&:last-of-type': {
    marginBottom: '0',
  },
  '& > div': {
    display: 'flex',
    marginBottom: '0.25rem',
    '& > span': {
      '&:last-of-type': {
        color: 'rgba(83, 21, 255, 0.8)',
        fontWeight: '600',
        textAlign: 'center',
        marginLeft: '0.25rem',
      },
    },
  },
  '& > p': {
    color: 'rgb(156, 163, 175)',
    fontWeight: '300',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
});

const BottomWrap = styled(Box)({
  display: 'flex',
  height: '3.125rem',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
});

const BoxButton = styled(Box)({
  position: 'relative',
  width: '50%',
  background: 'rgb(240, 240, 240)',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
});

declare let window: any;

export function ModalManual2() {
  const deviceStateData = useRecoilValue(deviceState);
  const [modalManual2, setModalManual2] = useRecoilState(modalManual2State);
  const { is_mobile, platform } = deviceStateData;

  const onClose = useCallback(() => {
    closeModalManual2({ setModalManual2 });
  }, []);

  const onClickCustomerService = useCallback(() => {
    if (is_mobile) {
      if (platform == 'ios') {
        window.cordova.InAppBrowser.open('https://pf.kakao.com/_VvCxkxj/chat', '_system');
      } else {
        window.Open.openUrl('https://pf.kakao.com/_VvCxkxj/chat');
      }
    } else {
      window.open('https://pf.kakao.com/_VvCxkxj/chat');
    }
  }, [is_mobile, platform]);

  if (modalManual2.visible)
    return (
      <Container>
        <Wrapper>
          <TopWrap>
            <Title>인증에 어려움이 있으시다면?</Title>
            <SubTitle>혹시 아래 내용에 해당되는지 확인해주세요.</SubTitle>
            <ContentBox>
              <div>
                <NumberToEmoji word={'1'} colorCode='#5c3dff' />
                <span>인증번호 문자가 오지 않아요.</span>
              </div>
              <p>본인인증 기관의 발송번호 (1666-6410)가</p>
              <p>스팸으로 분류되지 않으셨는지 확인 부탁드리고</p>
              <p>그럼에도 수신 불가 시, 고객센터로 문의해주세요.</p>
            </ContentBox>
            <ContentBox>
              <div>
                <NumberToEmoji word={'2'} colorCode='#5c3dff' />
                <span>국내 거주자가 아니에요</span>
              </div>
              <p>고객센터로 접수해주시면, 해외 이용자 회원가입을 도와드릴 수 있어요.</p>
            </ContentBox>
          </TopWrap>
          <BottomWrap>
            <BoxButton sx={{ borderBottomLeftRadius: '0.5rem' }} onClick={onClose}>
              <p style={{ color: 'rgb(156, 163, 175)', fontWeight: '700' }}>닫기</p>
            </BoxButton>
            <BoxButton sx={{ background: 'rgb(90, 20, 219)', borderBottomRightRadius: '0.5rem' }}>
              <p style={{ color: '#fff', fontWeight: '700' }} onClick={onClickCustomerService}>
                고객센터
              </p>
            </BoxButton>
          </BottomWrap>
        </Wrapper>
      </Container>
    );
  else return null;
}
