import { useRecoilState } from 'recoil';
import { routesState } from '../../recoil/common/routes';

interface LayoutMainProps {
  children: React.ReactNode;
}

interface StyledLayoutMainProps {
  root_path: string;
}

import { styled, Box } from '@mui/material';

const StyledLayoutMain = styled(Box)<StyledLayoutMainProps>(props => {
  return {
    width: '100%',
    border: `8px solid #eef0f1`,
    background: '#eef0f1',
    // border: `8px solid #f0f2f3`,
    // background: '#f0f2f3',
    // border: `8px solid #f2f4f5`,
    // background: '#f2f4f5',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    paddingBottom: '0.3rem',
    '@media(max-width: 1023px)': {
      borderColor: '#f5f5f5',
      backgroundColor: '#f5f5f5',
    },
  };
});

function LayoutMain(props: LayoutMainProps) {
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { root_path } = routesStateData;
  const children = props.children;

  return <StyledLayoutMain root_path={root_path}>{children}</StyledLayoutMain>;
}

export default LayoutMain;
