import React, { useCallback, useContext, useState } from 'react';
import { styled, Box, Modal as MuiModal, IconButton } from '@mui/material';
import { IoCloseSharp } from 'react-icons/io5';
import { moduleSettingState } from 'recoil/model/module_setting';
import { useRecoilState } from 'recoil';

import t_s_setting_1 from '../../assets/images/tutorial/setting01.png';
import t_s_setting_2 from '../../assets/images/tutorial/setting02.png';
import { options } from 'utils/setting_contents';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { ModalContext } from 'provider/ModalProvider';

interface ModalSpeakVideoSettingProps {
  visible: boolean;
  settings: { [key: string]: number };
  onClose: (settings: { [key: string]: number }) => void;
}

const Modal = styled(MuiModal)({
  backgroundColor: 'rgba(33, 33, 33, 0.46)',
  transition: '0.2s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms !important',
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

const Wrapper = styled(Box)({
  borderRadius: '4px',
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 'none',
});

const Wrap = styled(Box)(props => ({
  height: 'auto',
  borderRadius: '1.5rem',
  overflow: 'hidden',
  backgroundColor: 'transparent',
}));

const Header = styled(Box)(props => ({
  width: '100%',
  height: '3.5rem',
  padding: '0 1rem',
  fontSize: '1.1rem',
  background: '#6b20e9',
  color: props.theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '.sub-title': {
    fontSize: '0.875rem',
    marginLeft: '0.25rem',
  },
  '@media (max-width: 1903px)': {
    fontSize: '1.25rem',
  },
  '@media (max-width: 1263px)': {
    height: '3.75rem',
  },
  '@media (max-width: 959px)': {
    height: '5rem',
    fontSize: '1.625rem',
  },
  '@media (max-width: 767px)': {
    height: '4rem',
    fontSize: '1.35rem',
  },
}));

const HeaderIconWrap = styled(Box)(props => ({
  right: '1rem',
  position: 'absolute',
  width: '1.75rem',
  height: '1.75rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: props.theme.palette.white.main,
  color: '#6b20e9',
  svg: {
    width: '1.6rem',
    height: '1.6rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media  (max-width: 959px)': {
    width: '2.75rem',
    height: '2.75rem',
    svg: {
      width: '2.65rem',
      height: '2.65rem',
    },
  },
  '@media (max-width: 767px)': {
    width: '2.25rem',
    height: '2.25rem',
    svg: {
      width: '2rem',
      height: '2rem',
    },
  },
}));

const Body = styled(Box)(props => ({
  width: '37rem',
  height: '21.5rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  gap: '2rem',
  padding: '2.35rem',
  '&.selected': {
    '& > .selected': {
      border: '4px solid #f6a21f',
    },
  },
  '@media (max-width: 1903px)': {
    width: '45rem',
    height: '27.5rem',
  },
  // '@media (max-width: 1263px)': {
  //   width: '45rem',
  //   height: '27.5rem',
  // },
  '@media (max-width: 959px)': {
    width: '55rem',
    height: '31.5rem',
  },
  '@media (max-width: 767px)': {
    width: '45rem',
    height: '25.5rem',
  },
}));

const ChoiceCard = styled(Box)(props => ({
  width: 'calc(50% - 0.5rem)',
  height: '100%',
  borderRadius: '1rem',
  // boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px;',
  boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
  cursor: 'pointer',

  '& > div': {
    '&:first-of-type': {
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      backgroundColor: '#f5f1f8',
      height: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        maxHeight: '100%',
        maxWidth: '67.5%',
      },
    },
    '&:last-of-type': {
      padding: '0 0.5rem',
      height: '20%',
      // paddingLeft: '1.5rem',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      fontSize: '1.1rem',
      fontWeight: '700',
      '@media (max-width: 1903px)': {
        fontSize: '1.25rem',
      },
      '@media (max-width: 959px)': {
        fontSize: '1.625rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '1.35rem',
      },

      '& > h3': {
        // fontFamily: 'NanumSquareRound !important',
        fontSize: '1.2rem',
        lineHeight: '2',
        span: {
          // fontFamily: 'NanumSquareRound !important',
          color: props.theme.palette.purple.main,
          '&.highlight': {
            display: 'inline-block',
            position: 'relative',
            '& > div': {
              display: 'inline-block',
              position: 'relative',
              zIndex: '1',
            },
          },
          '&.highlight:after': {
            width: '100%',
            content: '""',
            height: '0.6rem',
            display: 'inline-block',
            background: '#fffcb9',
            position: 'absolute',
            bottom: '0',
            left: '0',
            transform: 'translateY(-100%)',
          },
        },
      },
      '& > p': {
        fontSize: '1.1rem',
        color: '#8e8f8e',
        fontWeight: '300',
        span: {
          color: '#000',
        },
      },
    },
  },
}));

const StyledIconButton = styled(IconButton)(props => ({
  '@media (max-width: 1903px)': {
    width: '1.25rem',
    height: '1.25rem',
  },
  '@media (max-width: 959px)': {
    width: '1.625rem',
    height: '1.625rem',
  },
  '@media (max-width: 767px)': {
    width: '1.35rem',
    height: '1.35rem',
  },
  padding: '0',
  backgroundColor: '#cccdff',
  svg: {
    color: props.theme.palette.white.main,
    '@media (max-width: 1903px)': {
      width: '1.25rem',
      height: '1.25rem',
    },
    '@media (max-width: 959px)': {
      width: '1.625rem',
      height: '1.625rem',
    },
    '@media (max-width: 767px)': {
      width: '1.35rem',
      height: '1.35rem',
    },
    strokeWidth: '3px',
  },
  '&:hover': { backgroundColor: '#cccdff' },
}));

function ModalSpeakVideoSetting(props: ModalSpeakVideoSettingProps) {
  const visible = props.visible;
  const onClose = props.onClose;

  const [settings, setSettings] = useState({ ...props.settings });
  const { speed, repeat } = settings;

  const buttonClick = (id: 'speed' | 'repeat', value: string) => {
    let index = settings[id];

    if (value === 'increese') {
      if (index < options['video'][id].length - 1) {
        index += 1;
      } else {
        index = 0;
      }
    } else if (value === 'decreese') {
      if (index === 0) {
        index = options['video'][id].length - 1;
      } else {
        index -= 1;
      }
    }

    changeModuleSettings(id, index);
  };

  const changeModuleSettings = (key: string, value: number) => {
    setSettings(current => {
      const newSettings = JSON.parse(JSON.stringify(current));
      newSettings[key] = value;
      return newSettings;
    });
  };

  const onClickClose = () => {
    onClose(settings);
  };

  return (
    <Modal open={visible} onClose={onClickClose}>
      <Wrapper>
        <Wrap>
          <Header>
            <Box component={'span'}>학습 상세설정을 선택해주세요!</Box>
            <HeaderIconWrap onClick={onClickClose}>
              <IoCloseSharp />
            </HeaderIconWrap>
          </Header>
          <Body>
            <ChoiceCard sx={{ width: `calc(${Math.round(100 / 2)}% - 0.5rem)` }}>
              <Box>
                <img src={t_s_setting_1} />
              </Box>
              <Box>
                <Box>속도</Box>
                <StyledIconButton disableRipple onClick={() => buttonClick('speed', 'decreese')}>
                  <FiMinus />
                </StyledIconButton>
                <Box className='c_purple'>{options.video.speed?.[speed].title}</Box>
                <StyledIconButton disableRipple onClick={() => buttonClick('speed', 'increese')}>
                  <FiPlus />
                </StyledIconButton>
              </Box>
            </ChoiceCard>
            <ChoiceCard sx={{ width: `calc(${Math.round(100 / 2)}% - 0.5rem)` }}>
              <Box>
                <img src={t_s_setting_2} />
              </Box>
              <Box>
                <Box>반복</Box>
                <StyledIconButton disableRipple onClick={() => buttonClick('repeat', 'decreese')}>
                  <FiMinus />
                </StyledIconButton>
                <Box className='c_purple'>{options.video.repeat?.[repeat].title}</Box>
                <StyledIconButton disableRipple onClick={() => buttonClick('repeat', 'increese')}>
                  <FiPlus />
                </StyledIconButton>
              </Box>
            </ChoiceCard>
          </Body>
        </Wrap>
      </Wrapper>
    </Modal>
  );
}

export default ModalSpeakVideoSetting;
