import ModalCustomDefault from './ModalCustomDefault';
import { styled, Box } from '@mui/material';
import Button from '../button/Button';
import { d_flex_end, d_flex_space_around, d_flex_space_between } from '../../styles/common';
import { useEffect } from 'react';

const ContentsBox = styled(Box)(props => ({
  width: '100%',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  whiteSpace: 'pre-wrap',
  lineHeight: '1.4',
  color: '#8d8d8d',
}));

const CustomHeader = styled(Box)(props => ({
  width: '100%',
  height: '1.5rem',
  display: 'flex',
  fontSize: '1.1rem',
  fontWeight: '700',
  lineHeight: '1',
  backgroundColor: props.theme.palette.white.main,
}));

const ContentsWrapper = styled(Box)(({ theme }) => ({
  width: '26.25rem',
  maxWidth: '480px',
  height: '14rem',
  maxHeight: '300px',
  padding: '2.5rem 2.65rem',
  fontSize: '1.05rem',
  ...d_flex_space_between,
  flexDirection: 'column',

  button: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));

interface ModalTextProps {
  visible: boolean;
  onClose: () => void;
  text: string;
  title: string;
}

function ModalText(props: ModalTextProps) {
  const visible = props.visible;
  const onClose = props.onClose;
  const text = props.text;
  const title = props.title;

  useEffect(() => {
    if (visible) bindKeyboard();
    return () => {
      unbindKeyboard();
    };
  }, [visible]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (evt: KeyboardEvent) => {
    if (evt.key == 'Enter') {
      evt.preventDefault();
      onClose();
    }
  };

  return (
    <ModalCustomDefault title='' visible={visible} onClose={onClose} header={false}>
      <ContentsWrapper>
        <CustomHeader>{title}</CustomHeader>
        <ContentsBox>{text}</ContentsBox>
        <Button
          onClick={onClose}
          sx={{ width: '100%', ...d_flex_end, fontWeight: '500', padding: 0, fontSize: '1.05rem', lineHeight: '1' }}
          color='purple'
          disableRipple
        >
          확인
        </Button>
      </ContentsWrapper>
    </ModalCustomDefault>
  );
}

export default ModalText;
