import { atom } from 'recoil';

export const settingState = atom<SettingsType>({
  key: 'settingState',
  default: {
    enable_keyboard: true,

    order_word: true,
    check_type: true,
    use_push: true,

    effect_sound: true,
    series_filter: false,

    is_fullscreen: false,
    use_relearning: false,
  },
});
