import { useContext, useEffect, useMemo, useState } from 'react';
import { styled, Box, Typography, Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../recoil/model/user';
import { MissionType, hiddenMissionState } from 'recoil/common/mission';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL } from '../utils/constants';
import { ModalContext } from '../provider/ModalProvider';
import { fetchGetApi } from '../utils/api';

import { HiUser } from 'react-icons/hi';
import { BiEdit } from 'react-icons/bi';
import { IoMdInformationCircle } from 'react-icons/io';
import LayoutMain from '../components/layout/LayoutMain';
import { d_flex_center, ignore_drag, dir_column, d_flex_space_between } from '../styles/common';

import ModalLogoUpload from '../components/modal/ModalLogoUpload';

import main_study from '../assets/images/mainpage/main_study_v2.png';
import main_speak from '../assets/images/mainpage/main_speak_v2.png';
import main_school from '../assets/images/mainpage/main_school_v2.png';
import main_grammar from '../assets/images/mainpage/main_grammar_v2.png';

import icon_schedule from '../assets/images/icons/icon_schedule.png';
import icon_report from '../assets/images/icons/icon_report.png';
import icon_leaderboard from '../assets/images/icons/icon_leaderboard.png';
import icon_recent from '../assets/images/icons/icon_recent.png';
import icon_manager from '../assets/images/icons/icon_manager_v2.png';
import icon_pocketnote from '../assets/images/icons/icon_pocketnote_v2.png';

import default_profile from '../assets/images/default_img.png';

import { tooltip_index_first, tooltip_index_second, tooltip_index_third, tooltip_index_fourth } from '../utils/tooltip';
import { deviceState } from '../recoil/common/device';
import { EffectSoundContext } from '../provider/EffectSoundProvider';
import theme from '../theme';
import { MdOutlineSync } from 'react-icons/md';
import ModalLeaderboard from '../components/modal/ModalLeaderboard';
import { settingState } from '../recoil/model/settings';
import { routesState } from '../recoil/common/routes';
import { Loading } from '../components/loading/Loading2';
import { ModalHiddenMissionContainer } from 'components/common/ModalHiddenMission/ModalHiddenMission.Container';
import { ModalLeaderBoardType, modalLeaderBoardState, openModalLeaderBoard } from 'recoil/common/modalLeaderBoard';
import { bookState } from 'recoil/model/book';
import { AiOutlineRight } from 'react-icons/ai';
import ModalDefaultProfileSetting from 'components/modal/ModalDefaultProfileSetting';
import {
  ModalDefaultProfileSettingType,
  modalDefaultProfileSettingState,
  openModalDefaultProfileSetting,
} from 'recoil/common/modalDefaultProfileSetting';

interface MainContentsContainerProps {
  width: string;
  type: string;
}

const MainContentsContainer = styled(Box)<MainContentsContainerProps>(props => {
  const width = props.width;
  const height = 'calc(50% - 4px)';
  return {
    width,
    height,
    backgroundColor: props.theme.palette.white.main,
    borderRadius: '1rem',
    overflow: 'hidden',
  };
});

const MainContentsTitle = styled(Box)(props => ({
  width: '100%',
  height: '2.75rem',
  ...d_flex_center,
  backgroundColor: props.theme.palette.gray_5.main,
  gap: '2rem',
  position: 'relative',

  '& > h6': {
    fontWeight: 'bold',
    letterSpacing: '-0.05rem',
    fontSize: '1.05rem',
    lineHeight: 0,
    span: {
      marginLeft: '4px',
      color: props.theme.palette.gray_1.main,
    },
  },

  button: {
    svg: {
      width: '1rem',
      height: '1rem',
    },
  },
}));

const MainContents = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderTop: 'none',

  '#version_title': {
    ...d_flex_center,
    height: '12%',

    span: {
      fontWeight: '500',
      color: props.theme.palette.gray_1.main,
      fontSize: '1rem',
      letterSpacing: '-0.5px',
      lineHeight: 'inherit',
    },
  },
}));

const MainContentBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  ...d_flex_center,
}));

const ProfileBox = styled(Box)(props => ({
  width: '100%',
  height: '68%',
  marginBottom: '2%',
  ...d_flex_center,
  border: '1px solid',
  borderColor: props.theme.palette.gray_3.main,
  borderBottom: `1px solid ${props.theme.palette.gray_3.main}`,
  position: 'relative',
  overflow: 'hidden',
  '.default_icon': {
    height: '60%',
    color: props.theme.palette.gray_3.main,
  },
  '.edit_icon': {
    cursor: 'pointer',
    position: 'absolute',
    bottom: '0rem',
    right: '0rem',
    width: '1.95rem',
    height: '1.95rem',
    color: props.theme.palette.white.main,
    backgroundColor: props.theme.palette.gray_2.main,
    strokeWidth: 0.5,
    zIndex: 1,
  },

  '.user_profile': {
    width: '100%',
  },

  '.user-default-profile': {
    height: '100%',
    marginTop: '2.5rem',
    // paddingBottom: '1rem',
  },

  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },

  '@media(max-width: 960px)': {
    '.user-default-profile': {
      // paddingBottom: '0.75rem',
    },
  },

  '@media(max-width: 767px)': {
    '.user-default-profile': {
      // paddingBottom: '0.5rem',
    },
  },
}));

const InfoBox = styled(Box)(props => ({
  width: '100%',
  height: '30%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: props.theme.palette.gray_3.main,
  borderTop: 'none',
  '& > div': {
    width: '100%',
    height: '50%',
    ...d_flex_center,

    '&:first-of-type': {
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: props.theme.palette.gray_3.main,
    },
  },

  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
    '& > div': {
      '&:first-of-type': {
        borderColor: '#f5f5f5',
      },
    },
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ theme, placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3f3f3f',
    transition: 'none !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    transition: '0.05s !important',
    marginTop: '1vh !important',
    backgroundColor: '#3f3f3f',
    fontSize: '1.1rem',
    textAlign: 'center',
    minWidth: '25vw',
    maxWidth: 'unset',
    lineHeight: '1',
    padding: '0.75rem',
    borderRadius: '0.5rem',
    top: '-3.5vh',
    left: '0.125rem',
    '& > div': {
      fontSize: '1.1rem',
    },
  },
}));

const TooltipIconBox = styled(Box)(props => ({
  position: 'absolute',
  color: '#f0f2f3',
  fontSize: '2rem',
  right: '1rem',
  top: '1rem',
}));

const QuickMenuBox = styled(Box)(props => ({
  width: '100%',
  height: '88%',
  display: 'flex',
  flexWrap: 'wrap',
  cursor: 'pointer',
  borderBottom: 'none',
  '& > div': {
    width: '50%',
    height: 'calc(65% / 2)',
    // height: 'calc(100% / 2)',
    '&:nth-of-type(odd) > div': {
      borderRight: '1px solid',
      borderColor: props.theme.palette.gray_3.main,
    },

    '& > div': {
      width: '100%',
      height: '100%',
      ...d_flex_center,
      flexDirection: 'column',
      borderBottom: '1px solid',
      borderColor: props.theme.palette.gray_3.main,
      '& > span': {
        marginTop: 'calc(75% * 0.075 * -1)',
        paddingBottom: 'calc(75% * 0.075)',
        color: '#616161',
        letterSpacing: '-0.05rem',
      },
    },
  },
  '@media(max-width: 1023px)': {
    '& > div': {
      '&:nth-of-type(odd) > div': {
        borderColor: '#f5f5f5',
      },
      '& > div': {
        borderColor: '#f5f5f5',
      },
    },
  },
}));

const QuickMenuImg = styled('img')(({ theme }) => ({
  maxHeight: '75%',
  maxWidth: '75%',
}));

function MainIndex() {
  const location = useLocation();
  const navigate = useNavigate();
  const hiddenMission = useRecoilValue(hiddenMissionState);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { modal_alert } = useContext(ModalContext);

  const [deviceStateData, setDeviceState] = useRecoilState<DeviceType>(deviceState);
  const { is_mobile, current_version, last_version } = deviceStateData;

  const [modalLeaderBoard, setModalLeaderBoard] = useRecoilState<ModalLeaderBoardType>(modalLeaderBoardState);
  const { visible: visibleModalLeaderBoard } = modalLeaderBoard;

  const [modalDefaultProfileSetting, setModalDefaultProfileSetting] = useRecoilState<ModalDefaultProfileSettingType>(
    modalDefaultProfileSettingState,
  );

  const [modalLogoVisible, setModalLogoVisible] = useState(false);
  const [profileSrc, setProfileSrc] = useState('');

  const { playEffectSound } = useContext(EffectSoundContext);

  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { prev_path } = routesStateData;
  const [isLoading, setIsLoading] = useState(false);

  const [bookStateData, setBookStateData] = useRecoilState<BookType>(bookState);

  const quick_menu_contents = [
    {
      label: '학습 스케쥴',
      icon: <QuickMenuImg src={icon_schedule} />,
      path: '/schedule',
    },
    {
      label: '최근 학습내역',
      icon: <QuickMenuImg src={icon_recent} />,
      path: '/recent',
    },
    {
      label: '포켓노트',
      icon: <QuickMenuImg src={icon_pocketnote} />,
      path: '/pocketnote',
    },
    {
      label: '학습 결과',
      icon: <QuickMenuImg src={icon_report} />,
      path: '/report',
    },
    {
      label: '리더보드',
      icon: <QuickMenuImg src={icon_leaderboard} />,
      path: '/leaderboard',
    },
    {
      label: '학습 매니저',
      icon: <QuickMenuImg src={icon_manager} />,
      path: '/manager',
    },
  ];

  // const quick_menu_contents = [
  //   {
  //     label: '학습 스케쥴',
  //     icon: <QuickMenuImg src={icon_schedule} />,
  //     path: '/schedule',
  //   },
  //   {
  //     label: '최근 학습내역',
  //     icon: <QuickMenuImg src={icon_recent} />,
  //     path: '/recent',
  //   },
  //   {
  //     label: '리더보드',
  //     icon: <QuickMenuImg src={icon_leaderboard} />,
  //     path: '/leaderboard',
  //   },
  //   {
  //     label: '학습 결과',
  //     icon: <QuickMenuImg src={icon_report} />,
  //     path: '/report',
  //   },
  // ];

  useEffect(() => {
    if (userStateData.profile) {
      setProfileSrc(`data:image/png;base64,${userStateData.profile}`);
    } else {
      setProfileSrc('');
    }
  }, [userStateData]);

  const useRank = useMemo(() => {
    let result = false;
    if (userStateData.class && userStateData.class.length > 0) {
      for (let i = 0; i < userStateData.class.length; i++) {
        if (userStateData.class[i].use_rank == 1) {
          result = true;
          break;
        }
      }
    }
    return result;
  }, [userStateData.class]);

  useEffect(() => {
    if (
      useRank &&
      prev_path == '/login' &&
      hiddenMission.curr_step == 0 &&
      !(userStateData.profile_type == 'default' && userStateData.default_profile == '0')
    ) {
      openModalLeaderBoard({ setModalLeaderBoard });
    }
  }, [prev_path]);

  const movePage = (path: string) => {
    if (userStateData.id < 6 && path == '/pocketnote') {
      modal_alert.openModalAlert('temp_account');
      return false;
    } else if (
      (path == '/book/speak' &&
        ['V003Y', 'V003DY', 'V003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['/book/study', '/book/school'].includes(path) &&
        ['S003Y', 'S003DY', 'S003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (path == '/book/grammar' &&
        ['S003Y', 'S003DY', 'S003TY', 'V003Y', 'V003DY', 'V003TY'].includes(userStateData.product))
    ) {
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      return false;
    }
    playEffectSound('click');
    navigate(`${BASE_URL}${path}`);
  };

  const moveQuickPage = (path: string) => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }
    if (path == '/leveltest') {
      modal_alert.openModalAlert('ready_service');
    } else if (path == '/leaderboard' && userStateData.type > 20) {
      modal_alert.openModalAlert('admin_rank');
    } else if (path == '/leaderboard' && !useRank) {
      modal_alert.openModalAlert('unused_rank');
    } else {
      playEffectSound('click');
      navigate(`${BASE_URL}${path}`);
    }
  };
  const tooltip_state = [false, false, false, false];
  const [open, setOpen] = useState(tooltip_state);

  const onMouseLeave = (idx: number) => {
    setOpen(tooltip_state);
  };

  const setOpenMobile = (idx: number) => {
    const state = [];
    for (let i = 0; i < 4; i++) {
      if (i === idx) state.push(!open[idx]);
      else state.push(false);
    }
    setOpen(state);
  };

  const uploadingCallback = () => {
    setIsLoading(true);
  };

  const closeModalUpload = () => {
    setModalLogoVisible(false);
    setIsLoading(false);
  };

  const closeLoading = () => {
    setIsLoading(true);
  };

  const onClickProfile = () => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }
    playEffectSound('click');
    setModalLogoVisible(true);
  };

  return (
    <>
      <LayoutMain>
        <MainContentsContainer width='calc(25rem - 12px)' type='top'>
          <MainContentsTitle>
            <Typography component='h6'>사용자 정보</Typography>
          </MainContentsTitle>
          <MainContents sx={{ height: 'calc(100% - 2.74rem)', padding: '0.85rem' }}>
            <ProfileBox sx={{ ...ignore_drag }}>
              {userStateData.profile_type === 'default' ? (
                userStateData.default_profile == '0' ? (
                  <Box component='img' src={default_profile} alt='default_profile' className='default_icon' />
                ) : (
                  <Box component='img' src={profileSrc} alt='default_profile' className='user-default-profile' />
                )
              ) : (
                <Box component='img' src={profileSrc} alt='user_profile' className='user_profile' />
              )}
              <BiEdit className='edit_icon' onClick={onClickProfile} />
            </ProfileBox>
            <InfoBox>
              <Box>{userStateData.center_name}</Box>
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {userStateData.name}
                </Box>
              </Box>
            </InfoBox>
          </MainContents>
        </MainContentsContainer>
        <MainContentsContainer width='calc(50% - ((25rem - 12px) / 2) - 8px)' type='top'>
          <MainContentBox>
            {is_mobile ? (
              <TooltipIconBox onClick={() => setOpenMobile(3)}>
                <IoMdInformationCircle />
              </TooltipIconBox>
            ) : null}
            <StyledTooltip
              title={tooltip_index_fourth.text}
              placement={tooltip_index_fourth.direction}
              open={open[3]}
              onMouseLeave={() => (is_mobile ? null : onMouseLeave(3))}
              onMouseOver={() => (is_mobile ? null : setOpen([false, false, false, true]))}
            >
              <Box
                className='main_box'
                onClick={() => movePage('/book/grammar')}
                sx={{
                  ...dir_column,
                  ...d_flex_center,
                  ...ignore_drag,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% / 3)',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '6vh',
                      position: 'relative',
                      ...d_flex_space_between,
                    }}
                  >
                    <Box
                      sx={{
                        height: '6vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.palette.purple.main,
                        fontWeight: '500',
                        fontFamily: 'TTTogether',
                        fontSize: '4.6vh',
                        letterSpacing: '-0.2rem',
                        transform: 'scaleX(90%)',
                        transformOrigin: 'left',
                      }}
                    >
                      오래문법
                    </Box>
                    <Box
                      sx={{
                        width: '6vh',
                        height: '6vh',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: '#8d8d8d',
                        '& > svg': {
                          width: '75%',
                          height: '75%',
                          strokeWidth: '3vh',
                        },
                      }}
                    >
                      <AiOutlineRight />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      height: '3vh',
                      color: '#606060',
                      fontSize: '2.7vh',
                    }}
                  >
                    Long Grammar
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% * 2 / 3)',
                    position: 'relative',
                    textAlign: 'right',
                    paddingRight: '4rem',
                  }}
                >
                  <Box
                    component={'img'}
                    className='main_img main_grammar'
                    src={`${main_grammar}`}
                    sx={{
                      height: '100%',
                      transform: 'scale(1.2)',
                    }}
                  ></Box>
                </Box>
              </Box>
            </StyledTooltip>
          </MainContentBox>
        </MainContentsContainer>
        <MainContentsContainer width='calc(50% - ((25rem - 12px) / 2) - 8px)' type='top'>
          <MainContentBox>
            {is_mobile ? (
              <TooltipIconBox onClick={() => setOpenMobile(1)}>
                <IoMdInformationCircle />
              </TooltipIconBox>
            ) : null}
            <StyledTooltip
              title={tooltip_index_second.text}
              placement={tooltip_index_second.direction}
              open={open[1]}
              onMouseLeave={() => (is_mobile ? null : onMouseLeave(1))}
              onMouseOver={() => (is_mobile ? null : setOpen([false, true, false, false]))}
            >
              <Box
                className='main_box'
                onClick={() => movePage('/book/speak')}
                sx={{
                  ...dir_column,
                  ...ignore_drag,
                  ...d_flex_center,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% / 3)',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '6vh',
                      position: 'relative',
                      ...d_flex_space_between,
                    }}
                  >
                    <Box
                      sx={{
                        height: '6vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.palette.purple.main,
                        fontWeight: '500',
                        fontFamily: 'TTTogether',
                        fontSize: '4.6vh',
                        letterSpacing: '-0.2rem',
                        transform: 'scaleX(90%)',
                        transformOrigin: 'left',
                      }}
                    >
                      오래회화
                    </Box>
                    <Box
                      sx={{
                        width: '6vh',
                        height: '6vh',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: '#8d8d8d',
                        '& > svg': {
                          width: '75%',
                          height: '75%',
                          strokeWidth: '3vh',
                        },
                      }}
                    >
                      <AiOutlineRight />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      height: '3vh',
                      color: '#606060',
                      fontSize: '2.7vh',
                    }}
                  >
                    Long Speak
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% * 2 / 3)',
                    position: 'relative',
                    textAlign: 'right',
                    paddingRight: '4rem',
                    top: '0.6vw',
                  }}
                >
                  <Box
                    component={'img'}
                    className='main_img'
                    src={`${main_speak}`}
                    sx={{
                      width: 'calc(((100vh - 6.5rem) * 0.5 - 4px) * 0.6 * 2 / 3 * 0.96)',
                      // height: '100%',
                    }}
                  ></Box>
                </Box>
              </Box>
            </StyledTooltip>
          </MainContentBox>
        </MainContentsContainer>
        <MainContentsContainer width='calc(25rem - 12px)' type='bottom'>
          <MainContents sx={{ ...ignore_drag }}>
            <QuickMenuBox>
              {quick_menu_contents.map((item, item_idx) => {
                return (
                  <Box key={`quick_menu_item_${item_idx}`} onClick={() => moveQuickPage(item.path)}>
                    <Box>
                      {item.icon}
                      <Typography
                        component='div'
                        sx={{ marginTop: '-3px', fontSize: '0.95rem', color: '#616161', fontWeight: '500' }}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </QuickMenuBox>
            <Box id='version_title'>
              <Typography component='span'>오래영어 학습앱 v {current_version}&nbsp;</Typography>
              {is_mobile && current_version < last_version ? (
                <MdOutlineSync
                  style={{
                    backgroundColor: '#828282',
                    color: 'white',
                    borderRadius: '50%',
                    transform: 'scaleX(-1) rotate(90deg)',
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              ) : null}
            </Box>
          </MainContents>
        </MainContentsContainer>
        <MainContentsContainer width='calc(50% - ((25rem - 12px) / 2) - 8px)' type='bottom'>
          <MainContentBox>
            {is_mobile ? (
              <TooltipIconBox onClick={() => setOpenMobile(0)}>
                <IoMdInformationCircle />
              </TooltipIconBox>
            ) : null}
            <StyledTooltip
              title={tooltip_index_first.text}
              placement={tooltip_index_first.direction}
              open={open[0]}
              onMouseLeave={() => (is_mobile ? null : onMouseLeave(0))}
              onMouseOver={() => (is_mobile ? null : setOpen([true, false, false, false]))}
            >
              <Box
                className='main_box'
                onClick={() => movePage('/book/study')}
                sx={{
                  ...dir_column,
                  ...d_flex_center,
                  ...ignore_drag,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% / 3)',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '6vh',
                      position: 'relative',
                      ...d_flex_space_between,
                    }}
                  >
                    <Box
                      sx={{
                        height: '6vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.palette.purple.main,
                        fontWeight: '500',
                        fontFamily: 'TTTogether',
                        fontSize: '4.6vh',
                        letterSpacing: '-0.2rem',
                        transform: 'scaleX(90%)',
                        transformOrigin: 'left',
                      }}
                    >
                      오래보카
                    </Box>
                    <Box
                      sx={{
                        width: '6vh',
                        height: '6vh',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: '#8d8d8d',
                        '& > svg': {
                          width: '75%',
                          height: '75%',
                          strokeWidth: '3vh',
                        },
                      }}
                    >
                      <AiOutlineRight />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      height: '3vh',
                      color: '#606060',
                      fontSize: '2.7vh',
                    }}
                  >
                    Long Voca
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% * 2 / 3)',
                    position: 'relative',
                    textAlign: 'right',
                    paddingRight: '4rem',
                  }}
                >
                  <Box
                    component={'img'}
                    className='main_img'
                    src={`${main_study}`}
                    sx={{
                      height: '100%',
                    }}
                  ></Box>
                </Box>
              </Box>
            </StyledTooltip>
          </MainContentBox>
        </MainContentsContainer>
        <MainContentsContainer width='calc(50% - ((25rem - 12px) / 2) - 8px)' type='bottom'>
          <MainContentBox>
            {is_mobile ? (
              <TooltipIconBox onClick={() => setOpenMobile(2)}>
                <IoMdInformationCircle />
              </TooltipIconBox>
            ) : null}
            <StyledTooltip
              title={tooltip_index_third.text}
              placement={tooltip_index_third.direction}
              open={open[2]}
              onMouseLeave={() => (is_mobile ? null : onMouseLeave(2))}
              onMouseOver={() => (is_mobile ? null : setOpen([false, false, true, false]))}
            >
              <Box
                className='main_box'
                onClick={() => movePage('/book/school')}
                sx={{
                  ...dir_column,
                  ...d_flex_center,
                  ...ignore_drag,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% / 3)',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '6vh',
                      position: 'relative',
                      ...d_flex_space_between,
                    }}
                  >
                    <Box
                      sx={{
                        height: '6vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.palette.purple.main,
                        fontWeight: '500',
                        fontFamily: 'TTTogether',
                        fontSize: '4.6vh',
                        letterSpacing: '-0.2rem',
                        transform: 'scaleX(90%)',
                        transformOrigin: 'left',
                      }}
                    >
                      오래내신
                    </Box>
                    <Box
                      sx={{
                        width: '6vh',
                        height: '6vh',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: '#8d8d8d',
                        '& > svg': {
                          width: '75%',
                          height: '75%',
                          strokeWidth: '3vh',
                        },
                      }}
                    >
                      <AiOutlineRight />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      height: '3vh',
                      color: '#606060',
                      fontSize: '2.7vh',
                    }}
                  >
                    Long Study
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: 'calc(100% * 2 / 3)',
                    position: 'relative',
                    textAlign: 'right',
                    paddingRight: '4rem',
                  }}
                >
                  <Box
                    component={'img'}
                    className='main_img'
                    src={`${main_school}`}
                    sx={{
                      height: '100%',
                    }}
                  ></Box>
                </Box>
              </Box>
            </StyledTooltip>
          </MainContentBox>
        </MainContentsContainer>
      </LayoutMain>
      <Loading visible={isLoading} onClose={closeLoading} />
      <ModalLogoUpload
        visible={modalLogoVisible}
        onClose={closeModalUpload}
        uploadingCallback={uploadingCallback}
        onSetDefaultImage={() => {
          setProfileSrc('');
        }}
        isDefault={false}
      />
      {visibleModalLeaderBoard ? <ModalLeaderboard /> : null}
      <ModalHiddenMissionContainer />
      <ModalDefaultProfileSetting />
    </>
  );
}

export default MainIndex;
