export function getKorUclass(type: string | number): { type: string; label: string } {
  let res = { type: '', label: '' };

  switch (type) {
    case 11:
      res = { type: 'color #2b8ad1', label: '학생' };
      break;
    default:
      res = { type: 'color #2b8ad1', label: '관리자' };
      break;
  }

  return res;
}

export const student_grade_list = [
  { id: 'a1', name: '초등 1학년', fullname: '초등학교 1학년' },
  { id: 'a2', name: '초등 2학년', fullname: '초등학교 2학년' },
  { id: 'a3', name: '초등 3학년', fullname: '초등학교 3학년' },
  { id: 'a4', name: '초등 4학년', fullname: '초등학교 4학년' },
  { id: 'a5', name: '초등 5학년', fullname: '초등학교 5학년' },
  { id: 'a6', name: '초등 6학년', fullname: '초등학교 6학년' },
  { id: 'b1', name: '중등 1학년', fullname: '중학교 1학년' },
  { id: 'b2', name: '중등 2학년', fullname: '중학교 2학년' },
  { id: 'b3', name: '중등 3학년', fullname: '중학교 3학년' },
  { id: 'c1', name: '고등 1학년', fullname: '고등학교 1학년' },
  { id: 'c2', name: '고등 2학년', fullname: '고등학교 2학년' },
  { id: 'c3', name: '고등 3학년', fullname: '고등학교 3학년' },
  { id: 'etc', name: '기타', fullname: '기타' },
];

export const covertGradeId = (name: string) => {
  switch (name) {
    case '초1':
      return 'a1';
    case '초2':
      return 'a2';
    case '초3':
      return 'a3';
    case '초4':
      return 'a4';
    case '초5':
      return 'a5';
    case '초6':
      return 'a6';
    case '중1':
      return 'b1';
    case '중2':
      return 'b2';
    case '중3':
      return 'b3';
    case '고1':
      return 'c1';
    case '고2':
      return 'c2';
    case '고3':
      return 'c3';
  }
};

export const default_settings = {
  order_word: true,
  check_type: true,
  use_push: true,
  enable_keyboard: true,
};

export const default_module_settings: ModuleSettings = {
  acidrain_sound: {
    effect: 1,
    repeat: 1,
  },
  acidrain_difficult: { spelling: 0, mean: 1, image: 0 },
  acidrain_background: { default: 1, manual: 0, src: 0 },
  memorize_hot_stage: { example: 1, lecture: 1, spell: 1, image: 1, dictionary: 0 },
  memorize_new_stage: { example: 1, spell: 1, mean: 1, image: 1, dictionary: 0 },
  memorize_normal_stage: { spell: 1, mean: 1, image: 1, dictionary: 1 },
  memorize_voice: { count: 2, speed: 2, flash: 2, audio: 1 },
  memorize_book: { auto: 1, order: 0 },
  flash: { count: 2, speed: 2, flash: 2, audio: 1 },
  bingo: { time: 0, retry: 1 },
  popquiz: { time: 1, retry: 0 },
  dictation: { count: 2, hint: 0 },
  writing: { hint: 0 },
  speaking: { example_type: 0, type: 0, timer: 0, retry: 0 },
};
