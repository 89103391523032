import React, { useEffect, useState, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { learningState } from '../../../recoil/model/learning';
import { ModalContext } from '../../../provider/ModalProvider';
import { styled, Box, Button, BoxProps, IconButton } from '@mui/material';
import { IoSettingsSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import '../../../assets/scss/index.scss';
import ModalSettings from '../../modal/ModalSettings';
import ModalWordlist from '../../modal/ModalWordlist';
import { d_flex_center, d_flex_end, d_flex_start } from '../../../styles/common';
import { MEMORIZE_LABEL } from '../../../utils/constants';
import { loadingState, setPercent } from '../../../recoil/common/loading';
import theme from '../../../theme';
import { userState } from '../../../recoil/model/user';
import { modalSelectCardData, openModalSelectCard, closeModalSelectCard } from '../../../recoil/common/modalSelectCard';
import { tutorialStateData, openTutorial } from '../../../recoil/common/tutorial';

interface MemorizeStepperProps {
  next_disable: boolean;
  move_disable: boolean;
  animateShake: () => false | undefined;
  setCurrentStep: (idx: number) => void;
  setCurrentPage: (idx: number) => void;
}

const StyledStepper = styled(Box)(props => ({
  width: '100%',
  height: '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledStepperLeftBox = styled(Box)(props => ({
  width: '15%',
  height: '100%',
  paddingLeft: '16px',
  ...d_flex_start,
}));

const StyledStepperCenterBox = styled(Box)(props => ({
  width: '70%',
  height: '100%',
  flexBasis: 'auto',
  ...d_flex_center,
}));

const StyledStepperRightBox = styled(Box)(props => ({
  width: '15%',
  height: '100%',
  paddingRight: '16px',
  ...d_flex_end,
}));

const StyledSettingButton = styled(Button)(props => ({
  width: '9rem',
  height: '3.528rem',
  backgroundColor: '#f7f7f7',
  color: '#636363',
  boxShadow: 'none',
  fontSize: '1.2rem',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#f7f7f7',
    color: '#636363',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: '#f7f7f7',
    color: '#636363',
    boxShadow: 'none',
  },
}));

interface StepBox extends BoxProps {
  type: 'before' | 'after' | 'now';
}

const StyledStepBox = styled(Box)<StepBox>(props => ({
  width: '100%',
  height: '3.528rem',
  lineHeight: '1.375',
  fontSize: '1.26rem',
  padding: '6px 16px',
  borderRadius: '8px',
  ...d_flex_center,
  backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
  color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
  cursor: 'pointer',
  textAlign: 'center',
  marginLeft: '4px',
  marginRight: '4px',
  boxShadow: 'none',
  fontWeight: props.type == 'after' ? '500' : '700',
  '&:hover': {
    backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
    color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
    color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
    boxShadow: 'none',
  },
}));

interface CounterBox extends BoxProps {
  type: string;
}

const StyledStepCounter = styled(Box)<CounterBox>(props => ({
  width: '9rem',
  height: '3.528rem',
  backgroundColor: '#f7f7f7',
  position: 'relative',
  color: '#636363',
  boxShadow: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 0.8rem',
  minWidth: '64px',
  lineHeight: '3.528rem',
  borderRadius: '8px',
  cursor: props.type == 'button' ? 'pointer' : 'default',
  whiteSpace: 'nowrap',
  '&::after': {
    display: props.type == 'button' ? 'inline-block' : 'none',
    fontSize: '1.2rem',
    verticalAlign: '0.255em',
    content: '""',
    borderTop: '0.3em solid',
    borderRight: '0.3em solid transparent',
    borderBottom: '0',
    borderLeft: '0.3em solid transparent',
  },
  '&::before': {
    display: props.type == 'button' ? 'inline-block' : 'none',
    fontSize: '1.2rem',
    verticalAlign: '0.255em',
    content: '""',
    color: 'transparent',
    borderTop: '0.3em solid',
    borderRight: '0.3em solid transparent',
    borderBottom: '0',
    borderLeft: '0.3em solid transparent',
  },
}));

function MemorizeStepper(props: MemorizeStepperProps) {
  const setTutorialState = useSetRecoilState(tutorialStateData);
  const [modalSelectCard, setModalSelectCard] = useRecoilState(modalSelectCardData);
  const userStateData = useRecoilValue(userState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { modal_alert } = useContext(ModalContext);
  const [visibleSettings, setVisibleSettings] = useState(false);
  const [visibleWordlist, setVisibleWordlist] = useState(false);
  const { modal_disable, learning_type, current_step, contents, current_page, mod, resultsheet } = learningStateData;
  const next_disable = props.next_disable;
  const move_disable = props.move_disable;
  const animateShake = props.animateShake;
  const setCurrentStep = props.setCurrentStep;
  const setCurrentPage = props.setCurrentPage;

  const [contentsLength, setContentsLength] = useState(0);

  const { visible: visibleSelectCard } = modalSelectCard;

  const choiceSettingCard = (idx: number) => {
    if (idx == 0) {
      // 튜토리얼 start
      openTutorial({ setTutorialState }, { reopen: true });
      closeModalSelectCard({ setModalSelectCard });
    } else if (idx == 1) {
      // 설정창 start
      setVisibleSettings(true);
      closeModalSelectCard({ setModalSelectCard });
    }
  };

  const closeModalSelectCardCallback = () => {
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: false,
    }));
  };

  const openModalSettings = () => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }
    if (!modal_disable) {
      openModalSelectCard(
        { setModalSelectCard },
        { type: 't_s', onSuccess: choiceSettingCard, onClose: closeModalSelectCardCallback },
      );
    }
  };

  const onClickCurrentStep = (step: number) => {
    setCurrentStep(step);
  };

  const onClickCurrentPage = (page: number) => {
    if (page == current_page || move_disable) {
      if (next_disable) {
        animateShake();
      }
      return false;
    }
    setCurrentPage(page);
  };

  const onClickStepCounter = () => {
    setVisibleWordlist(true);
  };

  useEffect(() => {
    if (visibleSelectCard) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  }, [visibleSelectCard]);

  useEffect(() => {
    if (visibleSettings) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  }, [visibleSettings]);

  useEffect(() => {
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: visibleWordlist ? true : false,
    }));
  }, [visibleWordlist]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < resultsheet.length; i++) {
      if (resultsheet[i].memorize) count++;
    }
    setContentsLength(resultsheet.length - count);
  }, [resultsheet]);

  return (
    <StyledStepper>
      <StyledStepperLeftBox>
        {learning_type ? (
          <StyledSettingButton variant='contained' disableRipple onClick={() => openModalSettings()}>
            설정
          </StyledSettingButton>
        ) : null}
      </StyledStepperLeftBox>
      <StyledStepperCenterBox>
        {mod?.map((v, i) => (
          <StyledStepBox
            onClick={() => onClickCurrentPage(v.seq)}
            key={v.seq}
            type={v.seq == current_page ? 'now' : 'after'}
          >
            <Box sx={{ marginTop: '1px' }}>{MEMORIZE_LABEL[v.module as MemorizeIndex]}</Box>
          </StyledStepBox>
        ))}
      </StyledStepperCenterBox>
      <StyledStepperRightBox>
        <StyledStepCounter
          sx={{ fontSize: contentsLength >= 1000 ? '1rem' : contentsLength >= 100 ? '1.1rem' : '1.2rem' }}
          type={'button'}
          onClick={() => onClickStepCounter()}
        >
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            남은 단어
            <Box
              component={'span'}
              sx={{
                color: theme.palette.purple.main,
                fontWeight: 600,
              }}
            >
              &nbsp;{contentsLength}&nbsp;
            </Box>
          </Box>
        </StyledStepCounter>
      </StyledStepperRightBox>
      <ModalSettings visible={visibleSettings} onClose={() => setVisibleSettings(false)} />
      <ModalWordlist
        visible={visibleWordlist}
        onClose={() => setVisibleWordlist(false)}
        onClick={(idx: number) => {
          onClickCurrentStep(idx);
        }}
        contents={contents}
        current_step={current_step}
      />
    </StyledStepper>
  );
}

export default MemorizeStepper;
