import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { settingState } from '../recoil/model/settings';
import { effect_sounds } from '../utils/constants';
import { learningState } from 'recoil/model/learning';

interface EffectSoundContextType {
  playEffectSound: (payload: EffectSoundName) => Promise<void>;
}

interface Props {
  children: React.ReactNode;
}

export const EffectSoundContext = createContext<EffectSoundContextType>({
  playEffectSound: async () => {
    return;
  },
});

export const EffectSoundContextProvider = ({ children }: Props) => {
  const { effect_sound } = useRecoilValue(settingState);
  const learningStateData = useRecoilValue<LearningType>(learningState);
  const { current_page, mod } = learningStateData;

  let this_module = -1;
  if (mod.length > 0 && current_page >= 0) {
    this_module = mod[current_page].module;
  }

  const [audioElements, setAudioElements] = useState<{ [key: string]: HTMLAudioElement }>({});
  const prevEffectSound = useRef<HTMLAudioElement | null>(null);
  const currentEffectSound = useRef<{ [key: string]: HTMLAudioElement }>({});
  currentEffectSound.current = audioElements;

  useEffect(() => {
    const newAudioElements: { [key: string]: HTMLAudioElement } = {};
    Object.keys(effect_sounds).forEach(payload => {
      const audioUrl = effect_sounds[payload];
      const audio = new Audio(audioUrl);
      audio.setAttribute('playsinline', '');
      audio.onloadedmetadata = () => {
        // console.log(payload, audio.duration);
      };
      newAudioElements[payload] = audio;
    });
    setAudioElements(newAudioElements);
  }, []);

  const playEffectSound = (payload: EffectSoundName): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (prevEffectSound.current) {
        prevEffectSound.current.pause();
      }

      if (prevEffectSound.current != audioElements[payload]) prevEffectSound.current = audioElements[payload];

      if (prevEffectSound.current) {
        if (effect_sound) {
          prevEffectSound.current.volume = 0.8;
          if (payload === 'click') {
            prevEffectSound.current.volume = 0.56;
          }
        } else {
          prevEffectSound.current.volume = 0;
        }

        prevEffectSound.current.onpause = () => {
          // console.log('onpause', 'effect_sound', prevEffectSound.current, payload);
          if (prevEffectSound.current) {
            if (prevEffectSound.current == currentEffectSound.current[payload]) {
              // console.log('onpause', 'effect_sound', prevEffectSound.current.currentTime, payload);
              prevEffectSound.current.currentTime = 0;
            } else {
              prevEffectSound.current = null;
            }
          }
          resolve();
        };

        prevEffectSound.current.onended = () => {
          // console.log('onended', 'effect_sound', prevEffectSound.current, payload);
          if (prevEffectSound.current) prevEffectSound.current = null;
          resolve();
        };

        prevEffectSound.current.onerror = error => {
          // console.log('onerror', 'effect_sound');
          console.error(error);
          resolve();
        };

        // console.log('play1', prevEffectSound.current);

        if (prevEffectSound.current.currentTime != 0 && this_module != 2) {
          prevEffectSound.current.currentTime = 0;
        }

        // prevEffectSound.current.currentTime = 0;

        // console.log('play2', prevEffectSound.current.currentTime);
        prevEffectSound.current
          .play()
          // .then(() => console.log('playing'))
          .catch(error => {
            console.error(error);
            resolve();
          });
        // console.log('play3', prevEffectSound.current.currentTime);
      } else {
        // console.error(`The sound ${payload} does not exist.`);
        resolve();
      }
    });
  };

  return <EffectSoundContext.Provider value={{ playEffectSound }}>{children}</EffectSoundContext.Provider>;
};

export default EffectSoundContextProvider;
