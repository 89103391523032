import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext, useRef } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { resetUserState, userState } from '../../recoil/model/user';
import { loadingState } from '../../recoil/common/loading';
import { learningState } from '../../recoil/model/learning';
import { routesState } from '../../recoil/common/routes';
import { ModalContext } from '../../provider/ModalProvider';
import { BASE_URL, HEADER, PRESIDENT_OF_CENTER_TYPE, TEMP_STUDENTS_ACCOUNT_IDS } from '../../utils/constants';

import { bookState } from '../../recoil/model/book';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { deviceState } from '../../recoil/common/device';
import { settingState } from '../../recoil/model/settings';

import { fetchGetApi, fetchPostApi, fetchPutApi } from '../../../src/utils/api';
import { FaPowerOff, FaArrowLeft, FaTimes, FaBell } from 'react-icons/fa';

import icon_back from '../../assets/images/back.png';
import icon_close from '../../assets/images/close.png';

import bi from '../../assets/images/longedu_bi_white2.png';
import memorize from '../../assets/images/memorize.png';
import basic from '../../assets/images/basic.png';
import advanced from '../../assets/images/advanced.png';
import wrong from '../../assets/images/wrong.png';
import study from '../../assets/images/study.png';
import speak from '../../assets/images/speak.png';
import grammar from '../../assets/images/grammar.png';
// import grammar from '../../assets/images/grammar.png';
import school from '../../assets/images/school.png';
import pocketnote from '../../assets/images/pocketnote.png';

import { styled, Box, Typography } from '@mui/material';
import {
  d_flex_center,
  d_flex_space_around,
  d_flex_start,
  d_flex_space_evenly,
  d_flex_end,
  d_flex,
} from '../../styles/common';

import logo2 from '../../assets/images/longvoca_logo_white.png';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { MdStars } from 'react-icons/md';
import { GrExpand, GrContract } from 'react-icons/gr';
import { CgArrowsExpandRight } from 'react-icons/cg';
import dayjs from 'dayjs';
import { BackgroundSoundContext } from '../../provider/BackgroundSoundProvider';
import { setUserInFireStoreToNull } from '../../utils/firebase';
import { modalHiddenMissionState } from 'recoil/common/modalHiddenMission';
import { hiddenMissionState } from 'recoil/common/mission';

const StyledHeaderWrapper = styled(Box)(props => ({
  width: '100%',
  userSelect: 'none',
}));

interface StyledHeaderStyleProps {
  bgcolor?: string;
  color?: string;
  active?: string;
}

const StyledNaviWrap = styled(Box)<StyledHeaderStyleProps>(props => ({
  width: '100%',
  height: '4rem',
  padding: '0 0.8vw',
  background: props.bgcolor,
  ...d_flex_space_around,
}));

const StyledLogoLink = styled(Link)(props => ({
  width: '25rem',
  height: '100%',
  borderRight: '1px solid transparent',
  ...d_flex_start,
  '&.active': {
    backgroundColor: '#1a2a3d',
    borderRight: '1px solid #1a2a3d',
  },
}));

const StyledLogoBox = styled(Box)(props => ({
  width: '25rem',
  ...d_flex_start,
  paddingLeft: '1vw',
}));

const StyledLogoSubTitle = styled(Box)(props => ({
  color: 'white',
  fontSize: '1.25rem',
  fontWeight: '700',
  whiteSpace: 'break-spaces',
}));

const StyledAnchorWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xxl')]: {
    width: 'calc(100% - 25rem - calc(18px + 2.75rem + (calc(100px - 2.75rem) / 2)))',
  },

  [theme.breakpoints.down('xxl')]: {
    width: 'calc(100% - 25rem - calc(18px + 2.75rem + (calc(90px - 2.75rem) / 2)))',
  },

  [theme.breakpoints.down('xl')]: {
    width: 'calc(100% - 25rem - calc(18px + 2.75rem + (calc(72.5px - 2.75rem) / 2)))',
  },

  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 25rem - calc(18px + 2.75rem + (calc(65px - 2.75rem) / 2)))',
  },

  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 25rem - calc(18px + 2.75rem + (calc(60px - 2.75rem) / 2)))',
  },

  // width: 'calc(100% - 25rem - calc(18px + 2.75rem + (calc(90px - 2.75rem) / 2)))',
  height: '100%',
  zIndex: '2',
  ...d_flex_space_evenly,
}));

const StyledIconWrap = styled(Box)(({ theme }) => {
  return {
    [theme.breakpoints.up('xxl')]: {
      width: `calc(72px + 6.25rem + (calc(200px - 6.25rem) / 2))`,
    },

    [theme.breakpoints.down('xxl')]: {
      width: `calc(72px + 6.25rem + (calc(180px - 6.25rem) / 2))`,
    },

    [theme.breakpoints.down('xl')]: {
      width: `calc(72px + 6.25rem + (calc(145px - 6.25rem) / 2))`,
    },

    [theme.breakpoints.down('md')]: {
      width: `calc(72px + 6.25rem + (calc(130px - 6.25rem) / 2))`,
    },

    [theme.breakpoints.down('sm')]: {
      width: `calc(72px + 6.25rem + (calc(120px - 6.25rem) / 2))`,
    },
    height: '100%',
    ...d_flex_end,
    alignItems: 'center',
    paddingRight: '1vw',
  };
});

const BookNameBox = styled(Box)<{ child_path?: string }>(props => ({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%)',
  color: `${props.theme.palette.white.main}`,
  backgroundColor: '#5f4bff',
  lineHeight: '2.0',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  borderRadius: '0.6rem',
  fontSize: '1rem',
  paddingTop: props.child_path === 'speak' ? '1px' : '',
}));

const icon_wrap_style = {
  borderRadius: '50%',
  border: 'none',
  width: '2.75rem',
  height: '2.75rem',
  padding: '0',
  cursor: 'pointer',
  ...d_flex_center,
};

const FullscreenIconContainer = styled(Box)(({ theme }) => {
  return {
    // [theme.breakpoints.up('xxl')]: {
    //   width: `calc(18px + 2.75rem + (calc(100px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('xxl')]: {
    //   width: `calc(18px + 2.75rem + (calc(90px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('xl')]: {
    //   width: `calc(18px + 2.75rem + (calc(72.5px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('md')]: {
    //   width: `calc(18px + 2.75rem + (calc(65px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('sm')]: {
    //   width: `calc(18px + 2.75rem + (calc(60px - 2.75rem) / 2))`,
    // },

    // height: '100%',
    // ...d_flex_end,
    // alignItems: 'center',
    height: '100%',
    ...d_flex_end,
    alignItems: 'center',
    justifyContent: 'flex-start',
  };
});

const PowerIconContainer = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    ...d_flex_end,
    alignItems: 'center',
    justifyContent: 'flex-start',
  };
});

const BellIconContainer = styled(Box)(({ theme }) => {
  return {
    [theme.breakpoints.up('xxl')]: {
      width: `calc(36px + 4.25rem + (calc(100px - 4.25rem) / 2))`,
    },

    [theme.breakpoints.down('xxl')]: {
      width: `calc(36px + 4.25rem + (calc(90px - 4.25rem) / 2))`,
    },

    [theme.breakpoints.down('xl')]: {
      width: `calc(36px + 4.25rem + (calc(72.5px - 4.25rem) / 2))`,
    },

    [theme.breakpoints.down('md')]: {
      width: `calc(36px + 4.25rem + (calc(65px - 4.25rem) / 2))`,
    },

    [theme.breakpoints.down('sm')]: {
      width: `calc(36px + 4.25rem + (calc(60px - 4.25rem) / 2))`,
    },

    height: '100%',
    ...d_flex_end,
    alignItems: 'center',
    zIndex: '2',
  };
});

const StyledPowerIconWrap = styled(Box)<StyledHeaderStyleProps>(props => ({
  backgroundColor: props.bgcolor,
  ...icon_wrap_style,
}));

const StyledBackIconWrap = styled(Box)<StyledHeaderStyleProps>(props => ({
  backgroundColor: props.bgcolor,
  ...icon_wrap_style,
}));

const icon_style = {
  width: '1.85rem',
  height: '1.85rem',
};

const StyledExpandIcon = styled(CgArrowsExpandRight)<StyledHeaderStyleProps>(props => ({
  color: props.color,
  ...icon_style,
  width: '1.65rem',
  height: '1.65rem',
  '& path': {
    stroke: props.color,
    strokeWidth: '1px',
  },
}));

const StyledBellIcon = styled(FaBell)<StyledHeaderStyleProps>(props => ({
  color: props.color,
  ...icon_style,
  width: '1.5rem',
  height: '1.5rem',
  cursor: 'pointer',
  '& path': {
    stroke: props.color,
    strokeWidth: '3px',
  },
}));

const CustomDiagonalLine = styled(Box)(props => ({
  width: '2rem',
  height: '2px',
  position: 'absolute',
  backgroundColor: props.theme.palette.red.main,
  transform: 'rotate(-45deg)',
}));

const StyledContractIcon = styled(GrContract)<StyledHeaderStyleProps>(props => ({
  color: props.color,
  ...icon_style,
  width: '1.5rem',
  height: '1.5rem',
  '& path': {
    stroke: props.color,
    strokeWidth: '3px',
  },
}));

const StyledPowerIcon = styled(FaPowerOff)<StyledHeaderStyleProps>(props => ({
  color: props.color,
  ...icon_style,
  width: '1.475rem',
  height: '1.475rem',
}));

const StyledBackIcon = styled(FaArrowLeft)<StyledHeaderStyleProps>(props => ({
  color: props.color,
  strokeWidth: '1px',
  ...icon_style,
}));

const StyledTimesIcon = styled(FaTimes)<StyledHeaderStyleProps>(props => ({
  color: props.color,
  ...icon_style,
}));

const StyledUtilsWrap = styled(Box)(props => ({
  width: '100%',
  height: '2.5rem',
  backgroundColor: 'white',
  ...d_flex_center,
}));

const StyledSettinghWrap = styled(Box)(props => ({
  width: '25rem',
  height: '100%',
  gap: '4px',
  borderTop: `1px solid ${props.theme.palette.gray_3.main}`,
  borderBottom: `1px solid ${props.theme.palette.gray_3.main}`,
  position: 'relative',
  ...d_flex_center,

  '&.active': {
    backgroundColor: '#0a1b2d',
    borderRight: '1px solid #0a1b2d',
    borderTop: `1px solid #0a1b2d`,
    borderBottom: `1px solid #0a1b2d`,
    '& > span': {
      color: '#f0f0f0 !important',
    },
  },
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '50%',
    right: 0,
    height: '80%',
    width: '1px',
    backgroundColor: props.theme.palette.gray_3.main,
    transform: 'translateY(-50%)',
  },

  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
    '&::after': {
      backgroundColor: '#f5f5f5',
    },
  },
}));

const StyledSetting = styled(Box)(props => ({
  width: '60%',
  lineHeight: '2',
  borderRadius: '50px',
  ...d_flex,
  color: 'white',
  background: 'linear-gradient(to right, #60e3b8, #6edfce)',
  '& > svg': {
    paddingLeft: '2px',
    paddingRight: '2px',
    transform: 'translateY(2px)',
  },
  cursor: 'pointer',
}));

const StyledRecentWrap = styled(Box)(props => ({
  width: 'calc(100% - 25rem)',
  height: '100%',
  borderRight: `1px solid ${props.theme.palette.gray_3.main}`,
  padding: 'calc(0.45% - 0px) 18px',
  borderTop: `1px solid #e5e5e5`,
  borderBottom: `1px solid #edeeef`,
  zIndex: '1',
  ...d_flex_end,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

const StyledRecentNavi = styled(Box)(props => {
  return {
    ...d_flex_center,
    cursor: 'pointer',
  };
});

function MainHeader() {
  const navigate = useNavigate();
  const routesStateData = useRecoilValue<RoutesType>(routesState);
  const { root_path, child_path, path } = routesStateData;
  const { modal_alert } = useContext(ModalContext);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { modal_disable, current_page, resultsheet } = learningStateData;
  const loadingStateData = useRecoilValue(loadingState);
  const { percent } = loadingStateData;
  const { modal_confirm } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const { playingBGM, toggleBGM, firstPlayingBGM, resetBGM } = useContext(BackgroundSoundContext);
  const [deviceStateData, setDeviceStateData] = useRecoilState<DeviceType>(deviceState);
  const { is_mobile } = deviceStateData;
  const [settingStateData, setSettingStateData] = useRecoilState<SettingsType>(settingState);
  const { is_fullscreen } = settingStateData;
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { pass } = userStateData;

  const resetLearningState = useResetRecoilState(learningState);
  const resetBookState = useResetRecoilState(bookState);
  const resetPocketNoteState = useResetRecoilState(pocketNoteState);
  const resetModalMissionState = useResetRecoilState(modalHiddenMissionState);
  const resetHiddenMissionState = useResetRecoilState(hiddenMissionState);

  const [avg, setAvg] = useState(0);

  const [headerStyle, setHeaderStyle] = useState({
    nav_background_color: 'linear-gradient(#652ae9, #4620e9)',
    logo_text_color: 'var(--black)',
    icon_background_color: 'var(--white)',
    icon_color: 'var(--purple)',
  });

  useEffect(() => {
    if (pass) getPeriodStatus();
  }, [pass]);

  const getPeriodStatus = async () => {
    if (pass) {
      const res = await fetchGetApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/results/status?sdate=${dayjs()
          .subtract(9, 'day')
          .format('YYYY-MM-DD')}&edate=${dayjs().format('YYYY-MM-DD')}`,
      );
      if (res.result) {
        setAvg(+res.data.avg);
      }
    }
  };

  const quit_voca_callback = () => {
    // 추가적으로 임시 학생 데이터도 안하게 처리 필요
    if (userStateData.type != PRESIDENT_OF_CENTER_TYPE && !TEMP_STUDENTS_ACCOUNT_IDS.includes(userStateData.id)) {
      setUserInFireStoreToNull(userStateData.id);
    }
    resetRecoilState();
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('refreshToken');
    setTimeout(() => {
      navigate(`${BASE_URL}/login`);
    }, 0);
  };

  const resetRecoilState = () => {
    resetUserState({ setUserStateData });
    resetLearningState();
    resetBookState();
    resetPocketNoteState();
    resetModalMissionState();
    resetHiddenMissionState();
    resetBGM();
  };

  const exit_learning_callback = async () => {
    if (learningStateData.book_type != 'pocketnote' && learningStateData.save_enabled) {
      const learning_data = JSON.parse(JSON.stringify(learningStateData));
      learning_data.mod[current_page].resultsheet = resultsheet;
      if (learning_data.bingo_card) learning_data.mod[current_page].bingo_card = learning_data.bingo_card;
      learning_data.studytime = learning_data.studytime + dayjs().diff(learningStateData.starttime, 's');

      const save_res = await fetchPutApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/units/${learningStateData.unit_id}/records/${learningStateData.record_id}/modules/${learningStateData.module_record_id}`,
        learning_data,
      );
    }
    resetLearningState();
    navigate(
      root_path == 'recent' || child_path == 'learning'
        ? `${BASE_URL}/${root_path}`
        : `${BASE_URL}/${root_path}/${child_path}`,
    );
  };

  const onClickPowerOff = (e: React.MouseEvent<HTMLDivElement>) => {
    resetButtonFocus();
    if (modal_disable) return false;
    if (root_path == '') {
      modal_confirm.openModalConfirm('power', 'quit_voca', quit_voca_callback);
    } else if (learningStateData?.status) {
      if (percent > 0) return false;
      const exit_message = getExitMessage();
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      const exit_type =
        !learningStateData.save_enabled || learningStateData.book_type == 'pocketnote'
          ? 'exit_study_2'
          : 'exit_study_1';

      modal_confirm.openModalConfirm(
        exit_type,
        exit_message,
        () => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: false,
          }));
        },
        exit_learning_callback,
      );
    }
  };

  const getExitMessage = () => {
    let exit = 'exit_';
    switch (learningStateData.learning_type) {
      case 1:
      case 2:
        exit += 'memorize';
        break;
      case 3:
        exit += 'basic';
        break;
      case 4:
        exit += 'advanced';
        break;
      case 5:
        exit += 'wrong';
        break;
      case 6:
        exit += 'speak';
        break;
      default:
        break;
    }
    switch (learningStateData.grammar_learning_type) {
      case 't':
        exit += 'grammar_t';
        break;
      case 'b':
        exit += 'basic';
        break;
      case 'd':
        exit += 'advanced';
        break;
      default:
        break;
    }
    return exit;
  };

  const movePage = () => {
    resetButtonFocus();
    playEffectSound('click');
    navigate(`${BASE_URL}/`);
  };

  const moveSettings = () => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }
    modal_confirm.openModalConfirm(
      'normal',
      'move_page',
      () => {
        navigate(`${BASE_URL}/manager/settings`);
      },
      undefined,
      '학습 설정으로',
    );
  };

  const movePeriod = () => {
    modal_confirm.openModalConfirm('normal', 'move_period', () => {
      navigate(`${BASE_URL}/report/period`);
    });
  };

  const headerTitle = () => {
    if (path == '/test') {
      return '평가모드';
    } else if (learningStateData.status && learningStateData.learning_type && learningStateData.schedule_id) {
      return HEADER[learningStateData.learning_type];
    } else {
      return '오래보카';
    }
  };

  const headerSubTitle = () => {
    if (root_path == 'pocketnote' && child_path == 'learning') {
      return '   |   포켓노트 암기학습';
    } else return null;
  };

  const onClickBGMToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    toggleBGM();
  };

  const onClickFullscreenOn = (e: React.MouseEvent<HTMLDivElement>) => {
    document.exitFullscreen().catch(err => {
      console.error(err);
    });
    resetButtonFocus();
  };

  const onClickFullscreenOff = (e: React.MouseEvent<HTMLDivElement>) => {
    document.documentElement.requestFullscreen().catch(err => {
      console.error(err);
    });
    resetButtonFocus();
  };

  const headerImage = () => {
    if (path.includes('study')) {
      return study;
    } else if (path.includes('speak')) {
      return speak;
    } else if (path.includes('school')) {
      return school;
    } else if (path.includes('pocketnote')) {
      return pocketnote;
    } else if (path.includes('grammar')) {
      return grammar;
    }
    //  else if (path.includes('grammar')) {
    //   return grammar;
    // }
    else {
      return bi;
    }
  };

  const contractIconButton = useRef(null);
  const expandIconButton = useRef(null);
  const powerIconButton = useRef(null);
  const closeIconButton = useRef(null);
  const backIconButton = useRef(null);

  const resetButtonFocus = () => {
    if (contractIconButton.current) {
      (contractIconButton.current as any).blur();
    }

    if (expandIconButton.current) {
      (expandIconButton.current as any).blur();
    }

    if (powerIconButton.current) {
      (powerIconButton.current as any).blur();
    }

    if (closeIconButton.current) {
      (closeIconButton.current as any).blur();
    }

    if (backIconButton.current) {
      (backIconButton.current as any).blur();
    }
  };

  return (
    <StyledHeaderWrapper
      sx={{ height: ['', 'pocketnote'].includes(root_path) && !learningStateData.status ? '6.5rem' : '4rem' }}
    >
      <StyledNaviWrap bgcolor={headerStyle.nav_background_color}>
        <StyledLogoBox
          sx={{ cursor: learningStateData.status || root_path == '' ? 'default' : 'pointer' }}
          onClick={learningStateData.status || root_path == '' ? undefined : () => movePage()}
        >
          <Box component={'img'} sx={{ height: '2rem' }} src={headerImage()} alt='logo' />
          <StyledLogoSubTitle component={'span'}>{headerSubTitle()}</StyledLogoSubTitle>
        </StyledLogoBox>
        <StyledAnchorWrap>
          {learningStateData.status && learningStateData.schedule_name && learningStateData.unit_name ? (
            <BookNameBox child_path={child_path}>
              {learningStateData.schedule_name} - {learningStateData.unit_name}
            </BookNameBox>
          ) : null}
        </StyledAnchorWrap>
        <StyledIconWrap>
          {learningStateData?.status ? null : (
            <BellIconContainer>
              <StyledPowerIconWrap
                ref={contractIconButton}
                component={'button'}
                onClick={onClickBGMToggle}
                bgcolor={'#5f4bff'}
              >
                <StyledBellIcon color={'white'} />
                {playingBGM || firstPlayingBGM ? null : <CustomDiagonalLine />}
              </StyledPowerIconWrap>
            </BellIconContainer>
          )}
          {is_mobile ? null : (
            <>
              <Box sx={{ height: '50%', margin: '0 0.375rem' }}></Box>
              <FullscreenIconContainer>
                {is_fullscreen ? (
                  <StyledPowerIconWrap
                    ref={contractIconButton}
                    component={'button'}
                    onClick={onClickFullscreenOn}
                    bgcolor={'#5f4bff'}
                  >
                    <StyledContractIcon color={'white'} />
                  </StyledPowerIconWrap>
                ) : (
                  <StyledPowerIconWrap
                    ref={expandIconButton}
                    component={'button'}
                    onClick={onClickFullscreenOff}
                    bgcolor={'#5f4bff'}
                  >
                    <StyledExpandIcon color={'white'} />
                  </StyledPowerIconWrap>
                )}
              </FullscreenIconContainer>
            </>
          )}
          {is_mobile && learningStateData.status ? null : (
            <Box sx={{ width: '1px', height: '50%', backgroundColor: '#7d45ff', margin: '0 0.75rem' }}></Box>
          )}
          <PowerIconContainer>
            {root_path == '' ? (
              <StyledPowerIconWrap
                ref={powerIconButton}
                component={'button'}
                onClick={onClickPowerOff}
                bgcolor={'#5f4bff'}
              >
                <StyledPowerIcon color={'white'} />
              </StyledPowerIconWrap>
            ) : learningStateData?.status ? (
              <StyledPowerIconWrap
                ref={closeIconButton}
                component={'button'}
                onClick={onClickPowerOff}
                bgcolor={'#5f4bff'}
              >
                <img style={{ width: '2.55rem', height: '2.55rem' }} src={icon_close}></img>
              </StyledPowerIconWrap>
            ) : (
              <StyledBackIconWrap ref={backIconButton} sx={{ backgroundColor: '#5f4bff' }} onClick={movePage}>
                <img style={{ width: '2.25rem', height: '2.25rem' }} src={icon_back}></img>
              </StyledBackIconWrap>
            )}
          </PowerIconContainer>
        </StyledIconWrap>
      </StyledNaviWrap>
      {['', 'pocketnote'].includes(root_path) && !learningStateData.status ? (
        <StyledUtilsWrap>
          <StyledSettinghWrap>
            <StyledSetting onClick={() => moveSettings()}>
              <Box sx={{ width: '20%' }}></Box>
              <Box sx={{ width: '60%', ...d_flex_center }}>학습 설정</Box>
              <Box sx={{ width: '20%', ...d_flex_center }}>&gt;</Box>
            </StyledSetting>
          </StyledSettinghWrap>
          <StyledRecentWrap>
            <StyledRecentNavi onClick={() => movePeriod()}>
              <Box sx={{ ...d_flex, alignItems: 'center' }}>
                <Box
                  sx={{
                    ...d_flex,
                    alignItems: 'center',
                    '& > svg': {
                      width: '1.5rem',
                      height: '1.5rem',
                      fill: '#f9b52c',
                      backgroundImage: 'radial-gradient(ellipse at center, #fef102 0%, rgba(255, 0, 0, 0) 70%)',
                    },
                  }}
                >
                  <MdStars />
                </Box>
                <Box
                  component={'span'}
                  sx={{
                    color: 'black.main',
                    fontWeight: '500',
                    margin: '0 0.25rem',
                  }}
                >
                  최근 10일
                </Box>
                <Box component={'span'} sx={{ color: 'purple.main' }}>
                  평균 점수 {avg}점
                </Box>
              </Box>
            </StyledRecentNavi>
          </StyledRecentWrap>
        </StyledUtilsWrap>
      ) : null}
    </StyledHeaderWrapper>
  );
}

export default MainHeader;
