export default function getScore(answer: string, rightanswer: string) {
  return checkSpeaking(answer, rightanswer);
}

const covorg = new Array<string>(
  'It is not',
  "It's not",
  "It isn't",
  'It isnt',
  'it is not',
  "it's not",
  "it isn't",
  'it isnt',
  'There is not',
  "There's not",
  "There isn't",
  'There isnt',
  'there is not',
  "there's not",
  "there isn't",
  'there isnt',
  'He is not',
  "He's not",
  "He isn't",
  'He isnt',
  'He has not',
  "He hasn't",
  'He hasnt',
  'he is not',
  "he's not",
  "he isn't",
  'he isnt',
  'he has not',
  "he hasn't",
  'he hasnt',
  'She is not',
  "She's not",
  "She isn't",
  'She isnt',
  'She has not',
  "She hasn't",
  'She hasnt',
  'she is not',
  "she's not",
  "she isn't",
  'she isnt',
  'she has not',
  "she hasn't",
  'she hasnt',
  'That is not',
  "That's not",
  "That isn't",
  'That isnt',
  'that is not',
  "that's not",
  "that isn't",
  'that isnt',
  'We are not',
  "We're not",
  "We aren't",
  'We arent',
  'we are not',
  "we're not",
  "we aren't",
  'we arent',
  'I am\\.',
  'I have\\.',
  'I would\\.',
  'It is\\.',
  'It will\\.',
  'You are\\.',
  'You have\\.',
  'You would\\.',
  'That is\\.',
  'She is\\.',
  'She would\\.',
  'He is\\.',
  'He would\\.',
  'We are\\.',
  'We have\\.',
  'They are\\.',
  'They will\\.',
  'They have\\.',
  'They would\\.',
  'There is\\.',
  'i am\\.',
  'i have\\.',
  'i would\\.',
  'it is\\.',
  'it will\\.',
  'you are\\.',
  'you have\\.',
  'you would\\.',
  'that is\\.',
  'she is\\.',
  'she would\\.',
  'he is\\.',
  'he would\\.',
  'we are\\.',
  'we have\\.',
  'they are\\.',
  'they will\\.',
  'they have\\.',
  'they would\\.',
  'there is\\.',
  'she will\\.',
  'he will\\.',
  'you will\\.',
  'were not ',
  'was not ',
  'did not ',
  'do not ',
  'does not ',
  'is not ',
  'could not ',
  'would not ',
  'should not ',
  'have not ',
  'has not ',
  'could have ',
  'would have ',
  'must not ',
  'I am ',
  'It is ',
  'You are ',
  'Your ',
  'What is ',
  'That is ',
  'Is not ',
  'Who is ',
  'She is ',
  'He is ',
  'Am i ',
  'Am I ',
  'Are not ',
  'We are ',
  'i am ',
  'it is ',
  'you are ',
  'your ',
  'what is ',
  'that is ',
  'is not ',
  'who is ',
  'she is ',
  'he is ',
  'am i ',
  'am I ',
  'are not ',
  'we are ',
  'They are ',
  'Is not ',
  'There is ',
  'Where is ',
  'How is ',
  'When is ',
  'Why is ',
  'they are ',
  'is not ',
  'there is ',
  'where is ',
  'how is ',
  'when is ',
  'why is ',
  //Yes, I am. 이유로 주석 품... 그전에 왜 주석이..
  // ,"I am","It is","You are","That is","She is","He is","We are","They are","There is"
  'I will ',
  'I will\\.',
  'You will ',
  'She will ',
  'He will ',
  'They will ',
  'We will ',
  'It will ',
  'Will not ',
  'i will ',
  'i will\\.',
  'you will ',
  'she will ',
  'he will ',
  'they will ',
  'we will ',
  'it will ',
  'will not ',
  'I have ',
  'You have ',
  'He have ',
  'She have ',
  'They have ',
  'We have ',
  'It have ',
  'i have ',
  'you have ',
  'he have ',
  'she have ',
  'they have ',
  'we have ',
  'it have ',
  'She has ',
  'He has ',
  'she has ',
  'he has ',
  'she has.',
  'he has.',
  'I would ',
  'You would ',
  'He would ',
  'She would ',
  'They would ',
  'We would ',
  'It would ',
  'i would ',
  'you would ',
  'he would ',
  'she would ',
  'they would ',
  'we would ',
  'it would ',
  '1st',
  '2nd',
  '3rd',
  'cell phone',
  'fire fighter',
  'a clock',
  'milk shake',
  'a lot',
  'ice skate',
  'roller coaster',
  'hair stylists',
  'hot dog',
  'home run',
  'lunch box',
  'high school',
  'weather girl',
  'one way',
  'Jay Z',
  'fortune tellers',
  'air base',
  'anti smoking',
  'ball park',
  'push ups',
  'sit ups',
  'round trip',
  'short sleeved',
  'hair pins',
  're open',
  'rain forest',
  'hip hop',
  'roller blades',
  'ice-skate',
  'seven twenty',
  'six fifteen',
  'eleven twenty five',
  'three thirty five',
  // ,"to ninety six", "to eighty four", "to seventy three", "to sixty five","to fifty six","to fifty three", "to fifty two", "to forty five", "to forty one"
  '296',
  '284',
  '273',
  '265',
  '256',
  '253',
  '252',
  '245',
  '241',
  'one hundred',
  'ninety six',
  'eighty four',
  'seventy three',
  'sixty five',
  'fifty six',
  'fifty three',
  'fifty two',
  'forty five',
  'forty one',
  'seventy eight',
  'twenty two',
  'thirty five',
  'thirty',
  'forty',
  'sixty',
  'seventy',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'One hundred',
  'Ninety six',
  'Eighty four',
  'Seventy three',
  'Sixty five',
  'Fifty six',
  'Fifty three',
  'Fifty two',
  'Forty five',
  'Forty one',
  'Seventy eight',
  'Thirty',
  'Forty',
  'Sixty',
  'Seventy',
  'Ten',
  'Eleven',
  'Twelve',
  'Thirteen',
  'Fourteen',
  'Fifteen',
  'Sixteen',
  'Seventeen',
  'Eighteen',
  'Nineteen',
  'Twenty',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'you have\\?',
  'he have\\?',
  'she have\\?',
  'I have\\?',
  'they have\\?',
  'we have\\?',
  'it have\\?',
  'am i\\?',
  'am I\\?',
  'She is\\?',
  "She's\\?",
  'she is\\?',
  "she's\\?",
  'He is\\?',
  "He's\\?",
  'he is\\?',
  "he's\\?",
  '\\$35',
  '\\$5',
  '\\$',
);

const covcheck = new Array<string>(
  '{It:isn:t}',
  '{It:isn~t}',
  '{It:isn!t}',
  '{It:isn_t}',
  '{it:isn:t}',
  '{it:isn~t}',
  '{it:isn!t}',
  '{it:isn_t}',
  '{There:isn:t}',
  '{There:isn~t}',
  '{There:isn!t}',
  '{There:isn_t}',
  '{there:isn:t}',
  '{there:isn~t}',
  '{there:isn!t}',
  '{there:isn_t}',
  '{He:isn:t}',
  '{He:isn~t}',
  '{He:isn!t}',
  '{He:isn_t}',
  '{He:hasn:t}',
  '{He:hasn!t}',
  '{He:hasn_t}',
  '{he:isn:t}',
  '{he:isn~t}',
  '{he:isn!t}',
  '{he:isn_t}',
  '{he:hasn:t}',
  '{he:hasn!t}',
  '{he:hasn_t}',
  '{She:isn:t}',
  '{She:isn~t}',
  '{She:isn!t}',
  '{She:isn_t}',
  '{She:hasn:t}',
  '{She:hasn!t}',
  '{She:hasn_t}',
  '{she:isn:t}',
  '{she:isn~t}',
  '{she:isn!t}',
  '{she:isn_t}',
  '{she:hasn:t}',
  '{she:hasn!t}',
  '{she:hasn_t}',
  '{That:isn:t}',
  '{That:isn~t}',
  '{That:isn!t}',
  '{That:isn_t}',
  '{that:isn:t}',
  '{that:isn~t}',
  '{that:isn!t}',
  '{that:isn_t}',
  '{We:are:t}',
  '{We:are~t}',
  '{We:are!t}',
  '{We:are_t}',
  '{we:are:t}',
  '{we:are~t}',
  '{we:are!t}',
  '{we:are_t}',
  '{I:m}.',
  '{I:ve}.',
  '{I:d}.',
  '{It:s}.',
  '{It:ll}.',
  '{You:re}.',
  '{You:ve}.',
  '{You:d}.',
  '{That:s}.',
  '{She:s}.',
  '{She:d}.',
  '{He:s}.',
  '{He:d}.',
  '{We:re}.',
  '{We:ve}.',
  '{They:re}.',
  '{They:ll}.',
  '{They:ve}.',
  '{They:d}.',
  '{There:s}.',
  '{i:m}.',
  '{i:ve}.',
  '{i:d}.',
  '{it:s}.',
  '{it:ll}.',
  '{you:re}.',
  '{you:ve}.',
  '{you:d}.',
  '{that:s}.',
  '{she:s}.',
  '{she:d}.',
  '{he:s}.',
  '{he:d}.',
  '{we:re}.',
  '{we:ve}.',
  '{they:re}.',
  '{they:ll}.',
  '{they:ve}.',
  '{they:d}.',
  '{there:s}.',
  '{she:ll}.',
  '{he:ll}.',
  '{you:ll}.',
  '{weren:t} ',
  '{wasn:t} ',
  '{didn:t} ',
  '{don:t} ',
  '{doesn:t} ',
  '{isn:t} ',
  '{couldn:t} ',
  '{wouldn:t} ',
  '{shouldn:t} ',
  '{haven:t} ',
  '{hasn:t} ',
  '{could:ve} ',
  '{would:ve} ',
  '{mustn:t} ',
  '{I:m} ',
  '{It:s} ',
  '{You:re} ',
  '{You~re} ',
  '{What:s} ',
  '{That:s} ',
  '{Isn:t} ',
  '{Who:s} ',
  '{She:s} ',
  '{He:s} ',
  '{M:i} ',
  '{M:I} ',
  '{Aren:t} ',
  '{We:re} ',
  '{i:m} ',
  '{it:s} ',
  '{you:re} ',
  '{you~re} ',
  '{what:s} ',
  '{that:s} ',
  '{isn:t} ',
  '{who:s} ',
  '{she:s} ',
  '{he:s} ',
  '{m:i} ',
  '{m:I} ',
  '{aren:t} ',
  '{we:re} ',
  '{They:re} ',
  '{Isn:t} ',
  '{There:s} ',
  '{Where:s} ',
  '{How:s} ',
  '{When:s} ',
  '{Why:s} ',
  '{they:re} ',
  '{isn:t} ',
  '{there:s} ',
  '{where:s} ',
  '{how:s} ',
  '{when:s} ',
  '{why:s} ',
  // ,"{I:m}","{it:s}","{you:re}","{that:s}","{she:s}","{he:s}","{we:re}","{they:re}","{there:s}"
  '{I:ll} ',
  '{I:ll}.',
  '{You:ll} ',
  '{She:ll} ',
  '{He:ll} ',
  '{They:ll} ',
  '{We:ll} ',
  '{It:ll} ',
  '{Won:t} ',
  '{i:ll} ',
  '{i:ll}.',
  '{you:ll} ',
  '{she:ll} ',
  '{he:ll} ',
  '{they:ll} ',
  '{we:ll} ',
  '{it:ll} ',
  '{won:t} ',
  '{I:ve} ',
  '{You:ve} ',
  '{He:ve} ',
  '{She:ve} ',
  '{They:ve} ',
  '{We:ve} ',
  '{It:ve} ',
  '{i:ve} ',
  '{you:ve} ',
  '{he:ve} ',
  '{she:ve} ',
  '{they:ve} ',
  '{we:ve} ',
  '{it:ve} ',
  '{She~s} ',
  '{He~s} ',
  '{she~s} ',
  '{he~s} ',
  '{she~s} ',
  '{he~s} ',
  '{I:d} ',
  '{You:d} ',
  '{He:d} ',
  '{She:d} ',
  '{They:d} ',
  '{We:d} ',
  '{It:d} ',
  '{i:d} ',
  '{you:d} ',
  '{he:d} ',
  '{she:d} ',
  '{they:d} ',
  '{we:d} ',
  '{it:d} ',
  '{first:}',
  '{second:}',
  '{third:}',
  '{cellphone}',
  '{firefighter}',
  '{o:clock}',
  '{milkshake}',
  '{alot}',
  '{iceskate}',
  'rollercoaster',
  '{hairstylists}',
  '{hotdog}',
  '{homerun}',
  '{lunchbox}',
  '{highschool}',
  '{weathergirl}',
  '{one-way}',
  '{Jay-Z}',
  '{fortunetellers}',
  '{airbase}',
  '{antismoking}',
  '{ballpark}',
  '{push-ups}',
  '{sit-ups}',
  '{round-trip}',
  '{short-sleeved}',
  '{hairpins}',
  '{reopen}',
  '{rainforest}',
  '{hiphop}',
  '{rollerblades}',
  '{iceskate}',
  '{720:}',
  '{615:}',
  '{1125:}',
  '{335:}',
  '{to 96:}',
  '{to 84:}',
  '{to 73:}',
  '{to 65:}',
  '{to 56:}',
  '{to 53:}',
  '{to 52:}',
  '{to 45:}',
  '{to 41:}',
  '{100:}',
  '{96:}',
  '{84:}',
  '{73:}',
  '{65:}',
  '{56:}',
  '{53:}',
  '{52:}',
  '{45:}',
  '{41:}',
  '{78:}',
  '{22:}',
  '{35:}',
  '{30:}',
  '{40:}',
  '{60:}',
  '{70:}',
  '{10:}',
  '{11:}',
  '{12:}',
  '{13:}',
  '{14:}',
  '{15:}',
  '{16:}',
  '{17:}',
  '{18:}',
  '{19:}',
  '{20:}',
  '{1:}',
  '{2:}',
  '{3:}',
  '{4:}',
  '{5:}',
  '{6:}',
  '{7:}',
  '{8:}',
  '{9:}',
  '{100::}',
  '{96::}',
  '{84::}',
  '{73::}',
  '{65::}',
  '{56::}',
  '{53::}',
  '{52::}',
  '{45::}',
  '{41::}',
  '{78::}',
  '{30::}',
  '{40::}',
  '{60::}',
  '{70::}',
  '{10::}',
  '{11::}',
  '{12::}',
  '{13::}',
  '{14::}',
  '{15::}',
  '{16::}',
  '{17::}',
  '{18::}',
  '{19::}',
  '{20::}',
  '{1::}',
  '{2::}',
  '{3::}',
  '{4::}',
  '{5::}',
  '{6::}',
  '{7::}',
  '{8::}',
  '{9::}',
  '{you::ve}',
  '{he::ve}',
  '{she::ve}',
  '{I::ve}',
  '{they::ve}',
  '{we::ve}',
  '{it::ve}',
  '{m::i}',
  '{m::I}',
  '{She::s}',
  '{She:!s}',
  '{she::s}',
  '{she:!s}',
  '{He::s}',
  '{he:!s}',
  '{he::s}',
  '{he:!s}',
  '{35 dolls}',
  '{5 dolls}',
  '{dollar}',
);

const covorgword = new Array<string>(
  'their',
  'there',
  'those',
  'dosar',
  'pens',
  'nyan',
  'hello',
  'jenny',
  'eraser',
  'cold',
  'pear',
  "don't",
  'ax',
  'chug',
  'GOG',
  'sit',
  'tiger',
  'zero',
  'sink',
  'cent',
  'pond',
  'kick',
  'meat',
  'glue',
  'say',
  'Phillip',
  'see',
  'sea',
  'poi',
  'wrap',
  'knight',
  'whose',
  'you',
  'and',
  'hes',
  'Thai',
  'Pi',
  'die',
  'moustache',
  'be',
  'gimbap',
  'Jay',
  'Ireland',
  'Incheon',
  'Mrs',
  'Jays',
  'too',
  'Jim',
  'Sarah',
  'Zero',
  'ink',
);
const covcheckword = new Array<string>(
  'theyre',
  'theyre',
  'does',
  'does',
  'pence',
  '9',
  'hi',
  'jennie',
  'racer',
  'chord',
  'pair',
  'don',
  'x',
  'jug',
  'jug',
  'st',
  'tyga',
  '0',
  'sync',
  'sent',
  'pwned',
  'kik',
  'meet',
  'gluu',
  'se',
  'philip',
  'c',
  'c',
  'tie',
  'rap',
  'night',
  'whos',
  'u',
  'n',
  'his',
  'tie',
  'tie',
  'tie',
  'mustache',
  'b',
  'kimbap',
  'J',
  'island',
  'inchon',
  'misses',
  'js',
  'to',
  'gym',
  'sara',
  '0',
  'pink',
);

const alwaysright = new Array<string>('Minsu', 'Kyungki', 'Jisu', '3030', 'Kwangju', 'Hanbok', 'Namsan');

function convertCheckMode(sentence: string) {
  for (let i = 0; i < covorg.length; i++) {
    const reg = new RegExp(covorg[i], 'g');
    sentence = sentence.replace(reg, covcheck[i]);
  }

  return sentence;
}

function convertOrgMode(sentence: string) {
  for (let i = 0; i < covcheck.length; i++) {
    const reg = new RegExp(covcheck[i].trim(), 'g');
    sentence = sentence.replace(reg, covorg[i]);
  }

  sentence = sentence.replace(/\\/gi, '');
  return sentence;
}

function convertCheckModeWord(word: string) {
  for (let i = 0; i < covorgword.length; i++) {
    const reg = new RegExp(covorgword[i], 'gi');
    word = word.replace(reg, covcheckword[i]);
  }
  return word;
}

function checkAlwaysRight(word: string) {
  for (let i = 0; i < alwaysright.length; i++) {
    if (word.toLowerCase() == alwaysright[i].toLowerCase()) return true;
  }
  return false;
}

function checkSpeaking(user: string, org: string) {
  //org = org.toLowerCase();
  //user = user.toLowerCase();
  user = user + ' ';

  org = convertCheckMode(org);
  user = convertCheckMode(user);

  const orgdim = org.split(' ');
  const userdim = user.split(' ');

  for (let i = 0, leng = userdim.length - 1; i < leng; i++) {
    if (!isNaN(userdim[i].replace('-', '') as any) && !isNaN(userdim[i + 1].replace('-', '') as any)) {
      userdim[i] = userdim[i] + userdim[i + 1];
      userdim.splice(i + 1, 1);
      i--;
      leng--;
    }
  }

  let offset = 0;

  const checkwords: number[] = [];
  let html = '';
  let rightwordcnt = 0;

  for (let i = 0; i < orgdim.length; i++) {
    let org1 = orgdim[i];
    checkwords[i] = 0;

    for (let j = offset; j < userdim.length; j++) {
      let user1 = userdim[j];

      if (user1 == undefined) user1 = '';

      org1 = org1.toLowerCase();
      user1 = user1.toLowerCase();

      org1 = org1.replace(/[^a-zA-Z0-9 ]/g, '');
      user1 = user1.replace(/[^a-zA-Z0-9 ]/g, '');

      if (convertCheckModeWord(org1) == convertCheckModeWord(user1) || checkAlwaysRight(org1)) {
        offset = j + 1;
        checkwords[i] = 1;
        break;
      } else {
        //동일 단어가 아닌 경우 스펠링 확인
        let rightspellcnt = 0;
        for (let sorg = 0; sorg < org1.length; sorg++) {
          if (org1.substr(sorg, 1) == user1.substr(sorg, 1)) {
            rightspellcnt++;
          }
        }
        const spellscore = rightspellcnt / org1.length;
        if (checkwords[i] < spellscore) checkwords[i] = spellscore;
      }
    }

    let orgsentence = convertOrgMode(orgdim[i]);
    orgsentence = convertOrgMode(orgsentence).trim();

    if (checkwords[i] == 1) {
      html = html + " <span class='c_blue'>" + orgsentence + '</span>';
      rightwordcnt++;
    } else {
      if (checkwords[i] == 0) {
        html = html + " <span class='c_red'>" + orgsentence + '</span>';
      } else {
        html = html + " <span class='c_orange'>" + orgsentence + '</span>';
        rightwordcnt = rightwordcnt + checkwords[i];
      }
    }
  }

  const score = (rightwordcnt / orgdim.length) * 100;

  return { html: html.trim(), score: score };
}
