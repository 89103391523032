import { useSetRecoilState } from 'recoil';
import { oneTimeModalState, checkOneTimeModal } from '../../recoil/common/oneTimeModal';
import { useContext, useCallback, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { ButtonPropsColorOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';
import theme from '../../theme';

import ModalDefault from './ModalDefault';
import Button from '../button/Button';

import { ModalContext } from '../../provider/ModalProvider';

import { StyledModalWrapper, StyledModalTitle, StyledModalUtil } from '../../styles/common';

interface CheckModalProps {
  types: string | undefined;
}

type Color = OverridableStringUnion<
  'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  ButtonPropsColorOverrides
>;

interface ScoreColor {
  [key: string]: { color: Color; color_var: string };
}

const StyledCheckModalWrapper = styled(StyledModalWrapper)(props => ({
  width: '28rem',
}));

const StyledCheckModalBody = styled(StyledModalTitle)(props => ({
  padding: '8px 16px',
}));

const StyledCheckModalTitle = styled(Box)(props => ({
  width: '98%',
  borderBottom: '1px dashed',
  padding: '0.85rem 0 1.1rem 0',
  fontWeight: '800',
  fontSize: '1.12rem',
}));

const StyledCheckModalContents = styled(Box)(props => ({
  padding: '2.75rem 0 2.5rem',
  fontSize: '1.12rem !important',
}));

const StyledCheckModalUtil = styled(StyledModalUtil)<CheckModalProps>(props => ({
  height: '3.9375rem',
  border: 'none',
  background: props.types ? 'var(--white)' : 'linear-gradient(#652ae9, #4620e9)',
}));

const CheckButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.12rem',
}));

function ModalCheck() {
  const { modal_check } = useContext(ModalContext);
  const setOneTimeModal = useSetRecoilState(oneTimeModalState);

  const score_color: ScoreColor = {
    score1: { color_var: 'var(--cyan)', color: 'cyan' },
    score2: { color_var: 'var(--cyan_2)', color: 'cyan_2' },
    score3: { color_var: 'var(--yellow-green)', color: 'yellow_green' },
    score4: { color_var: 'var(--yellow)', color: 'yellow_1' },
    score5: { color_var: 'var(--deep-orange)', color: 'deep_orange' },
  };

  useEffect(() => {
    if (modal_check.data.visible && modal_check.data.oneTimeModalKey) {
      bindKeyboard();
    }
    return () => {
      unbindKeyboard();
    };
  }, [modal_check.data.visible]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (evt: KeyboardEvent) => {
    if (evt.key == 'Enter') {
      evt.preventDefault();
      onClickCheck();
    }
  };

  const onClickCheck = useCallback(() => {
    const oneTimeModalKey = modal_check.data.oneTimeModalKey;
    if (oneTimeModalKey) {
      checkOneTimeModal({ setOneTimeModal }, oneTimeModalKey);
    }
    if (modal_check.data.check_callback) {
      modal_check.data.check_callback();
    }
    modal_check.closeModalCheck();
  }, [modal_check]);

  return (
    <>
      <ModalDefault
        visible={modal_check.data.visible}
        onClose={() => {
          return;
        }}
      >
        <StyledCheckModalWrapper>
          <StyledCheckModalBody
            sx={{
              backgroundColor: modal_check.data.type ? score_color[modal_check.data.type].color_var : 'var(--white)',
            }}
          >
            {modal_check.data.title ? (
              <StyledCheckModalTitle
                sx={{
                  borderBottomColor: modal_check.data.type ? 'var(--white)' : 'var(--gray-2)',
                  color: modal_check.data.type ? 'var(--white)' : theme.palette.purple.main,
                }}
                dangerouslySetInnerHTML={{ __html: modal_check.data.title }}
              ></StyledCheckModalTitle>
            ) : null}
            <StyledCheckModalContents
              sx={{
                color: modal_check.data.type ? 'var(--white)' : '#636363',
                fontSize: modal_check.data.type ? '5rem' : '1rem',
                lineHeight: modal_check.data.type ? 'normal' : '',
              }}
              dangerouslySetInnerHTML={{ __html: modal_check.data.contents }}
            ></StyledCheckModalContents>
          </StyledCheckModalBody>
          <StyledCheckModalUtil types={modal_check.data.type} onClick={onClickCheck} sx={{ cursor: 'pointer' }}>
            <CheckButton color={modal_check.data.type ? score_color[modal_check.data.type].color : 'white'}>
              확인
            </CheckButton>
          </StyledCheckModalUtil>
        </StyledCheckModalWrapper>
      </ModalDefault>
    </>
  );
}

export default ModalCheck;
