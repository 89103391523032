import ReportHeader from '../../components/common/Report/ReportHeader';
import ReportSide from '../../components/common/Report/ReportSide';
import IconReportO from '../../components/common/Report/IconReportO';
import IconReportX from '../../components/common/Report/IconReportX';
import IconReportT from '../../components/common/Report/IconReportT';

import { useLocation, useNavigate } from 'react-router';
import { useState, useEffect, Fragment, useContext, useMemo, useRef } from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
  Box,
  Table as MuiTable,
  styled,
  ButtonProps,
  Button,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { BASE_URL, MODULES_LABEL, SPEAK_LABEL, GRAMMAR_MODES, THEORY_LABEL } from '../../utils/constants';
import { routesState } from '../../recoil/common/routes';
import { dir_column, d_flex, d_flex_center, d_flex_space_between, d_flex_start } from '../../styles/common';
import ModalAddPocketNote from '../../components/modal/ModalAddPocketNote';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { FiStar } from 'react-icons/fi';
import ModalWordDetail from '../../components/modal/ModalWordDetail';
import { IoMdInformationCircle } from 'react-icons/io';
import theme from '../../theme';
import { ModalContext } from '../../provider/ModalProvider';
import getScore from '../../utils/get_score';
import CircleAudioPlayer from '../../components/common/Report/CircleAudioPlayer';
import { arrShuffle, getContentFoldername, setTextShadow } from '../../utils/tools';
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, ResponsiveContainer } from 'recharts';
import { deviceState } from '../../recoil/common/device';
import DonutChart from '../../components/common/Report/DounutChart';
import { BoxProps } from '@mui/system';
import dayjs from 'dayjs';
import CustomLineChart from '../../components/common/Report/CustomLineChart';
import { learningState } from '../../recoil/model/learning';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { fetchGetApi, fetchGetAudioApi, fetchPostApi, fetchTabStudyGetApi } from '../../utils/api';
import { userState } from '../../recoil/model/user';
import { moduleSettingState } from '../../recoil/model/module_setting';
import { bookState } from '../../recoil/model/book';
import { settingState } from '../../recoil/model/settings';
import { hiddenMissionState, MissionType } from 'recoil/common/mission';
import {
  modalHiddenMissionState,
  ModalHiddenMissionType,
  openModalHiddenMission,
} from 'recoil/common/modalHiddenMission';
import { ModalHiddenMissionContainer } from 'components/common/ModalHiddenMission/ModalHiddenMission.Container';

const StyledReportWrap = styled(Box)(props => ({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: props.theme.palette.white.main,
}));

const StyledMainWrap = styled(Box)({
  width: 'calc(100% - 4.25rem)',
  height: 'calc(100% - 6.5rem)',
  padding: '0.5rem 1rem 0 2rem',
  marginTop: '-0.5rem',
  display: 'flex',
  overflowY: 'auto',
  fontWeight: '600',
});

const StyledAnalysisWrap = styled(Box)({
  width: '100%',
  height: '100%',
});

const StyledAnalysisHeaderBox = styled(Box)({
  ...d_flex,
  width: '100%',
  height: '40vh',
  paddingBottom: '1rem',
});

const StyledAnalysisOuterBox = styled(Box)({
  width: '100%',
  height: '100%',
});

interface StyledAnalysisInnerBoxProps extends BoxProps {
  selected?: number;
}

const StyledAnalysisInnerBox = styled(Box)<StyledAnalysisInnerBoxProps>(props => ({
  ...d_flex_center,
  ...dir_column,
  width: '100%',
  height: '100%',
  borderRadius: '2rem',
  backgroundColor: props.selected == 1 ? '#ffda70' : 'white',
  boxShadow: props.theme.palette.boxShadow.main,
}));

const StyledAnalysisTitleBox = styled(Box)<StyledAnalysisInnerBoxProps>(props => ({
  ...d_flex_start,
  width: '80%',
  height: '7.5vh',
  color: props.selected == 1 ? 'white' : 'black',
  borderBottom: props.selected == 1 ? '1px solid #fff' : '1px solid #f0f1f2',
  fontSize: '2vh',
  paddingTop: '1vh',
  '@media(max-width: 1023px)': {
    borderColor: props.selected == 1 ? '#fff' : '#f5f5f5',
  },
}));

const StyledAnalysisContentBox = styled(Box)<StyledAnalysisInnerBoxProps>(props => ({
  ...d_flex_center,
  ...dir_column,
  color: props.selected == 1 ? 'white' : props.theme.palette.purple_3.main,
  width: '80%',
  height: 'calc(100% - 7.5vh)',
  padding: '2.5vh 0 3.5vh 0',
  justifyContent: 'center',
}));

const StyledAnalysisBodyTabBox = styled(Box)({
  ...d_flex,
  width: '100%',
  height: '25vh',
  padding: '1rem 0',
});

const StyledAnalysisBodyBox = styled(Box)({
  ...d_flex,
  width: '100%',
  height: '55vh',
  padding: '1rem 0 0.5rem 0',
});

const StyledDetailListBox = styled(Box)(props => ({
  width: '100%',
  height: '99%',
  backgroundColor: 'white',
  borderRadius: '2rem',
  overflow: 'hidden',
  boxShadow: props.theme.palette.boxShadow.main,
}));

const StyledDetailListHeader = styled(Box)({
  width: '100%',
  height: '7vh',
  borderBottom: '2px solid #f0f1f2',
  ...d_flex,
  '@media(max-width: 1023px)': {
    border: '1px solid #f0f1f2',
  },
});

interface StyledTabButtonProps extends ButtonProps {
  selected: number;
}

const StyledTabButton = styled(Button)<StyledTabButtonProps>(props => ({
  width: '100%',
  height: '100%',
  fontWeight: '700',
  fontSize: '1.25rem',
  color: props.selected == 1 ? props.theme.palette.white.main : props.theme.palette.gray.main,
  backgroundColor: props.selected == 1 ? '#ffc663' : '#fffaf5',
  borderRadius: '0',
  paddingTop: '9px',
  '&:hover': {
    backgroundColor: props.selected == 1 ? '#ffc663' : '#fffaf5',
  },
}));

const StyledDetailListBody = styled(Box)({
  width: '100%',
  overflowY: 'auto',
  textAlign: 'center',
});

const CustomTable = styled(MuiTable)(() => ({
  width: '98%',
  tableLayout: 'fixed',
  margin: '0 auto',

  '.MuiCheckbox-root': {
    width: 'auto !important',
  },
}));

const CustomTableBody = styled(TableBody)(() => ({
  width: '100%',
  td: {
    paddingRight: '0.25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const CustomTableRow = styled(TableRow)(props => ({
  width: '100%',
  height: '6.4vh',
  borderBottom: '1px solid var(--gray-3)',
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
  '&.detail-row:not(:last-child)': {
    borderBottom: 'none',
  },
  '& > *': {
    borderBottom: 'unset',
  },
  'td, th': {
    padding: 0,
    width: 'auto',
    position: 'relative',
    fontSize: '1.1rem',
    div: {
      whiteSpace: 'pre-wrap',
    },
  },
  ' .underline-under': {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationThickness: 'from-font',
  },
  '&:focus-within': {
    // background: 'var(--light-cyan)',
  },
}));

const CustomTableCell = styled(TableCell)(props => ({
  display: 'table-cell !important',
  border: 'none',
  fontWeight: '500',
  '&.MuiTableCell-alignCenter': {
    width: `${props.rowSpan ? '100%' : 'auto'}`,
  },
  '.word-wrap': {
    ...d_flex,
    ...dir_column,
    paddingLeft: '2rem',
    paddingTop: '2px',
  },
}));

const ListNumberBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  ...d_flex_center,
}));

const StyledNumber = styled(Box)(props => ({
  width: '2.25rem',
  height: '2.25rem',
  fontSize: '1.65rem',
  ...d_flex_center,
  backgroundColor: '#cdcdcd',
  color: 'white',
  borderRadius: '4px',
  overflow: 'hidden',
  fontWeight: '700',
  textShadow: setTextShadow({ color: '#afafaf', sizeShadow: 70, directionShadow: '' }),
}));

const ListMarkingBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  ...d_flex_center,
  '& > svg': {
    width: '2.25rem',
    height: '2.25rem',
    '&.triangle': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
}));

const CustomWordDetailButton = styled(IconButton)(props => ({
  zIndex: 1,
  svg: {
    color: '#474747',
    width: '2rem',
    height: '2rem',
  },
}));

interface CustomBookmarkButtonProps extends IconButtonProps {
  bookmark: number;
}

const CustomBookmarkButton = styled(IconButton)<CustomBookmarkButtonProps>(props => ({
  zIndex: 1,
  svg: {
    color: props.bookmark == 1 ? '#f1bc5f' : props.theme.palette.gray.main,
    width: '1.9rem',
    height: '1.9rem',
    fill: props.bookmark == 1 ? '#f5ff1b' : 'none',
  },
}));

const StyledDetailListBottom = styled(Box)({
  width: '100%',
  height: '7vh',
  overflow: 'hidden',
});

const StyledWrongStudyButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontWeight: '700',
  fontSize: '1.25rem',
  color: props.theme.palette.white.main,
  backgroundColor: props.theme.palette.green.main,
  borderRadius: '0',
  paddingTop: '9px',
  '&:hover': {
    backgroundColor: props.theme.palette.green.main,
  },
}));

const StyledRadarChart = styled(RadarChart)(props => ({
  '& > svg': {
    overflow: 'visible',
  },
}));

function ResultIndex() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const setModalHiddenMission = useSetRecoilState<ModalHiddenMissionType>(modalHiddenMissionState);
  const [hiddenMission, setHiddenMission] = useRecoilState<MissionType>(hiddenMissionState);
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [tab, setTab] = useState<number>(0);
  const [mode, setMode] = useState<string>('');
  const [tabName, setTabName] = useState<string>('');
  const [scheduleType, setScheduleType] = useState<string>('');
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;
  const [moduleSettingStateData, setModuleSettingStateData] = useRecoilState<ModuleSettings>(moduleSettingState);
  const [bookStateData, setBookStateData] = useRecoilState<BookType>(bookState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const [settingStateData, setSettingStateData] = useRecoilState<SettingsType>(settingState);
  const [scheduleId, setScheduleId] = useState<number>(0);
  const [unitId, setUnitId] = useState<number>(0);
  const [recordId, setRecordId] = useState<number>(0);
  const [moduleRecordId, setModuleRecordId] = useState<number>(0);
  const [recordData, setRecordData] = useState<ScheduleModuleDetailRecordType>();
  const [recentRecordData, setRecentRecordData] = useState<ScheduleModuleRecordType[]>();
  const [contents, setContents] = useState<ContentType[]>();
  const [searchContents, setSearchContents] = useState<ContentType[]>();
  const [wrongContents, setWrongContents] = useState<ContentType[]>();
  const [detailRecordTab, setDetailRecordTab] = useState<number>(0);
  const [visibleWordDetail, setVisibleWordDetail] = useState<boolean>(false);
  const [wordDetailcontent, setWordDetailcontent] = useState<ContentType>();
  const [visibleAddPocketNote, setVisibleAddPocketNote] = useState<boolean>(false);
  const [pocketNoteContent, setPocketNoteContent] = useState<ContentType>();
  const [pocketNoteStateData, setPocketNoteState] = useRecoilState<PocketNoteType>(pocketNoteState);
  const { note_contents } = pocketNoteStateData;
  const [radarChartData, setRadarChartData] = useState<ChartDataType[]>();
  const [radarChartSize, setRadarChartSize] = useState<number>(0);
  const [lineChartData, setLineChartData] = useState<LineChartDataType[]>();
  const [deviceStateData, setDeviceStateData] = useRecoilState<DeviceType>(deviceState);
  const { screen_width, screen_height } = deviceStateData;
  const [selectedAnalysisIdx, setSelectedAnalysisIdx] = useState<number>(-1);
  const [searchWord, setSearchWord] = useState<string>('');
  const [isTemp, setIsTemp] = useState(false);

  const resetLearningState = useResetRecoilState(learningState);
  const isSpeakMode = mode === 'speak';

  const setWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code == 'Enter' || e.code == 'NumpadEnter') {
      onSearch(searchWord);
    }
  };

  useEffect(() => {
    if (state && state.afterLearn && hiddenMission.curr_step <= 4 && hiddenMission.curr_step != 0) {
      getMission();
    }
  }, []);

  const getMission = async () => {
    try {
      const res = await fetchGetApi(`/customers/${userStateData.customer_id}/mission`);
      if (res && res.data) {
        // 미션 4 달성여부 확인
        if (res.data.step[3].completed) {
          setHiddenMission({ ...res.data });
          openModalHiddenMission({ setModalHiddenMission }, false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userStateData.id < 6) {
      if (learningStateData.status) {
        setTempRecordInit();
      } else if (state?.module_record_id) {
        setTempInit(state);
      } else {
        if (root_path && child_path) navigate(`${BASE_URL}/${root_path}/${child_path}`);
        else if (root_path) navigate(`${BASE_URL}/${root_path}`);
        else navigate(`${BASE_URL}/`);
        setPercent(setLoadingStateData, 100);
      }
    } else if (state) {
      setInit(state);
    } else {
      if (root_path && child_path) navigate(`${BASE_URL}/${root_path}/${child_path}`);
      else if (root_path) navigate(`${BASE_URL}/${root_path}`);
      else navigate(`${BASE_URL}/`);
      setPercent(setLoadingStateData, 100);
    }
  }, []);

  const setTempRecordInit = async () => {
    const learning_data = JSON.parse(JSON.stringify(learningStateData)) as LearningType;
    setScheduleId(learning_data.schedule_id ?? 0);
    setUnitId(learning_data.unit_id);
    setRecordId(learning_data.record_id);
    setModuleRecordId(learning_data.module_record_id);
    setTabName(learning_data.tab_name);
    setScheduleType('books');
    setMode(learning_data.learning_type == 3 ? 'basic' : 'advanced');
    const word_count = learning_data.contents.length;

    const wrong_contents: any[] = [];

    let score = 0;

    const wrong_module = learning_data.learning_type == 3 ? 3 : 5;

    for (let i = 0; i < learning_data.mod.length; i++) {
      const mod = JSON.parse(JSON.stringify(learning_data.mod[i])) as ModType;
      const resultsheet = mod.resultsheet;

      if (mod.module == 6) {
        let total_score = 0;
        if (resultsheet) {
          for (let j = 0; j < resultsheet.length; j++) {
            const result = getScore(resultsheet[j].answer as string, resultsheet[j].rightanswer as string);
            if (resultsheet[j].type == 'sentence') {
              total_score += result.score;
            } else {
              if (score >= 70) {
                total_score += 100;
              } else if (score > 0) {
                total_score += 50;
              } else {
                total_score += 0;
              }
            }
          }

          mod.score = Math.round((total_score / resultsheet.length) * 100) / 100;

          score += mod.score ?? 0;

          learning_data.mod[i] = mod;
        }
      } else {
        if (resultsheet) {
          let wrong_cnt = 0;
          for (let j = 0; j < resultsheet.length; j++) {
            if (mod.module == 1 || mod.module == 7) {
              if (resultsheet[j].rightanswer != resultsheet[j].answer) {
                if (resultsheet[j].answer === '333') {
                  wrong_cnt += 0.5;
                } else {
                  wrong_cnt++;
                }
              }
            } else {
              if (resultsheet[j].rightanswer != resultsheet[j].answer) {
                const content = learning_data.contents.find(content => content.id == resultsheet[j].word_id);
                if (content && mod.module == wrong_module) wrong_contents.push(content);
                wrong_cnt++;
              }
            }
          }

          mod.score = Math.round((100 - (100 / word_count) * wrong_cnt) * 100) / 100;

          score += mod.score ?? 0;

          learning_data.mod[i] = mod;
        }
      }
    }

    score = Math.round((score / learning_data.mod.length) * 100) / 100;
    let radar_chart_data: { subject: string; score: number }[] = [];

    if (learning_data.learning_type == 3) {
      radar_chart_data = [
        { subject: '듣기', score: 0 },
        { subject: '독해', score: 0 },
        { subject: '리딩', score: 0 },
        { subject: '읽기', score: 0 },
      ];

      const flash = learning_data.mod.find(item => item.module == 1);
      const bingo = learning_data.mod.find(item => item.module == 2);
      const popquiz = learning_data.mod.find(item => item.module == 3);
      if (flash) {
        radar_chart_data[0].score = flash.score ?? 0;
      }
      if (bingo && popquiz) {
        radar_chart_data[1].score = bingo.score ?? 0;
        radar_chart_data[2].score = bingo.score && popquiz.score ? (bingo.score + popquiz.score) / 2 : 0;
        radar_chart_data[3].score = popquiz.score ?? 0;
      } else if (bingo) {
        radar_chart_data[1].score = bingo.score ?? 0;
        radar_chart_data[2].score = bingo.score ?? 0;
      } else if (popquiz) {
        radar_chart_data[2].score = popquiz.score ?? 0;
        radar_chart_data[3].score = popquiz.score ?? 0;
      }
    } else if (learning_data.learning_type == 4) {
      radar_chart_data = [
        { subject: '듣기', score: 0 },
        { subject: '쓰기', score: 0 },
        { subject: '말하기', score: 0 },
        { subject: '읽기', score: 0 },
      ];
      const dictation = learning_data.mod.find(item => item.module == 4);
      const writing = learning_data.mod.find(item => item.module == 5);
      const speaking = learning_data.mod.find(item => item.module == 6);
      if (dictation && speaking) {
        radar_chart_data[0].score = dictation.score ?? 0;
        radar_chart_data[2].score = speaking.score ?? 0;
        radar_chart_data[3].score = dictation.score && speaking.score ? (dictation.score + speaking.score) / 2 : 0;
      } else if (dictation) {
        radar_chart_data[0].score = dictation.score ?? 0;
        radar_chart_data[3].score = dictation.score ?? 0;
      } else if (speaking) {
        radar_chart_data[2].score = speaking.score ?? 0;
        radar_chart_data[3].score = speaking.score ?? 0;
      }

      if (writing) {
        radar_chart_data[1].score = writing.score ?? 0;
      }
    }

    setContents([...learning_data.contents]);

    setRadarChartData([...radar_chart_data]);

    const wrong_words = wrong_contents.map(({ id, spell, mean, image, voice }) => ({
      id,
      spell,
      mean,
      image,
      voice,
    }));

    const wrong_count = wrong_contents.length;

    if (wrong_words) {
      setWrongContents([...wrong_words]);
    }
    setRecentRecordData([
      {
        record_id: learning_data.record_id,
        module_record_id: learning_data.module_record_id,
        schedule_id: learning_data.schedule_id ?? 0,
        schedule_name: learning_data.schedule_name,
        unit_id: learning_data.unit_id,
        unit_name: learning_data.unit_name,
        mode: learning_data.learning_type == 3 ? 'basic' : learning_data.learning_type == 4 ? 'advanced' : 'speak',
        done_count: 1,
        done_modules: learning_data.modules,
        word_count: word_count,
        wrong_word_count: wrong_count,
        score: score,
        studytime: learning_data.studytime,
        startdate: dayjs(learning_data.starttime).unix(),
        lastdate: dayjs().unix(),
        memorize: '1245',
        basic: '123',
        advanced: '456',
        tag: null,
        tab_name: learning_data.tab_name,
        schedule_words_count: 0,
        schedule_type: '',
        folder_id: learning_data.folder_id,
        category_id: learning_data.category_id,
      },
    ]);
    setRecordData({
      record_id: learning_data.record_id,
      module_record_id: learning_data.module_record_id,
      schedule_id: learning_data.schedule_id ?? 0,
      schedule_name: learning_data.schedule_name,
      unit_id: learning_data.unit_id,
      unit_name: learning_data.unit_name,
      mode: learning_data.learning_type == 3 ? 'basic' : learning_data.learning_type == 4 ? 'advanced' : 'speak',
      done_count: 1,
      done_modules: learning_data.modules,
      word_count: word_count,
      wrong_word_count: wrong_count,
      score: score,
      studytime: learning_data.studytime,
      startdate: dayjs(learning_data.starttime).unix(),
      lastdate: dayjs().unix(),
      memorize: '1245',
      basic: '123',
      advanced: '456',
      tag: null,
      tab_name: learning_data.tab_name,
      schedule_words_count: 0,
      recent_records: [],
      mod: learning_data.mod,
      contents: learning_data.contents,
      radar_chart_data: radar_chart_data,
      schedule_type: '',
      modules: {
        memorize: {
          record_id: null,
          done: false,
          done_modules: null,
          done_count: 0,
        },
        basic: {
          record_id: null,
          done: false,
          done_count: 0,
          done_modules: null,
        },
        advanced: {
          status: true,
          record_id: null,
          done: false,
          done_count: 0,
          done_modules: null,
        },
        wrong: {
          status: false,
          record_id: null,
          done: false,
          done_modules: null,
          startdate: null,
          done_count: 0,
        },
        speak: {
          done: false,
          record_id: null,
        },
        grammar_t: {
          done: false,
          record_id: null,
        },
        grammar_b: {
          done: false,
          record_id: null,
        },
        grammar_d: {
          done: false,
          record_id: null,
        },
      },
      wrong_words: wrong_words,
      category_id: learning_data.category_id,
      folder_id: learning_data.folder_id,
    });
    handleResize();
    setIsTemp(true);
    setPercent(setLoadingStateData, 100);
    modal_alert.openModalAlert('temp_record');
    playEffectSound('report');
    resetLearningState();
  };

  const setInit = async (state: {
    schedule_id: number;
    unit_id: number;
    record_id: number;
    module_record_id: number;
    tab_name: string;
    schedule_type: string;
  }) => {
    const { schedule_id, unit_id, record_id, module_record_id, tab_name, schedule_type } = state;
    setScheduleId(schedule_id);
    setUnitId(unit_id);
    setRecordId(record_id);
    setModuleRecordId(module_record_id);
    setTabName(tab_name);
    setScheduleType(schedule_type);
    const book_type = userStateData.type < 20 && schedule_type == 'schedules' ? 'schedules' : 'books';
    const report_res = await fetchGetApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}/result?type=${book_type}`,
    );

    if (report_res.result) {
      const report_data: ScheduleModuleDetailRecordType = report_res.data;
      setMode(report_data.mode);

      setRecordData(report_data);

      setContents([...report_data.contents]);
      setRecentRecordData([...report_data.recent_records]);

      setRadarChartData([...report_data.radar_chart_data]);

      if (report_data.wrong_words) {
        setWrongContents([...report_data.wrong_words]);
      }

      handleResize();
      setPercent(setLoadingStateData, 100);
      playEffectSound('report');
      if (learningStateData.status) {
        resetLearningState();
      }
    } else {
      if (root_path && child_path) navigate(`${BASE_URL}/${root_path}/${child_path}`);
      else if (root_path) navigate(`${BASE_URL}/${root_path}`);
      else navigate(`${BASE_URL}/`);
      setPercent(setLoadingStateData, 100);
    }
  };

  const setTempInit = async (state: {
    schedule_id: number;
    unit_id: number;
    record_id: number;
    module_record_id: number;
  }) => {
    const { schedule_id, unit_id, record_id, module_record_id } = state;
    setScheduleId(schedule_id);
    setUnitId(unit_id);
    setRecordId(record_id);
    setModuleRecordId(module_record_id);
    setTabName('longvoca');
    setScheduleType('books');
    const book_type = 'books';
    const report_res = await fetchGetApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}/result?type=${book_type}`,
    );
    if (report_res.result) {
      const report_data: ScheduleModuleDetailRecordType = report_res.data;
      setMode(report_data.mode);
      setRecordData(report_data);

      setContents([...report_data.contents]);
      setRecentRecordData([...report_data.recent_records]);

      setRadarChartData([...report_data.radar_chart_data]);

      if (report_data.wrong_words) {
        setWrongContents([...report_data.wrong_words]);
      }

      handleResize();
      setPercent(setLoadingStateData, 100);
      playEffectSound('report');
      if (learningStateData.status) {
        resetLearningState();
      }
    } else {
      if (root_path && child_path) navigate(`${BASE_URL}/${root_path}/${child_path}`);
      else if (root_path) navigate(`${BASE_URL}/${root_path}`);
      else navigate(`${BASE_URL}/`);
      setPercent(setLoadingStateData, 100);
    }
  };

  useEffect(() => {
    if (recordData && mode && userStateData.id > 5) {
      if (mode == 'basic') {
        if (recordData.study_method == 1) {
          if (settingStateData.use_relearning && recordData.modules.basic.done_count > 3) {
            thisStepReStudy.current = () => modal_alert.openModalAlert('relaerning_limit');
          } else if (!settingStateData.use_relearning && recordData.modules.basic.done_count > 0) {
            thisStepReStudy.current = () => modal_alert.openModalAlert('unable_relearning');
          } else {
            thisStepReStudy.current = startRelearning.bind(null, mode);
          }
        } else {
          thisStepReStudy.current = startRelearning.bind(null, mode);
        }
      } else if (mode == 'advanced') {
        if (recordData.study_method == 1) {
          if (settingStateData.use_relearning && recordData.modules.advanced.done_count > 3) {
            thisStepReStudy.current = () => modal_alert.openModalAlert('relaerning_limit');
          } else if (!settingStateData.use_relearning && recordData.modules.advanced.done_count > 0) {
            thisStepReStudy.current = () => modal_alert.openModalAlert('unable_relearning');
          } else {
            thisStepReStudy.current = startRelearning.bind(null, mode);
          }
        } else {
          thisStepReStudy.current = startRelearning.bind(null, mode);
        }
      } else if (mode == 'wrong') {
        thisStepReStudy.current = startRelearning.bind(null, mode);
      } //! (문법) 이론학습 / 기본학습 / 심화학습 처리 추가
      else if (mode == 'grammar_t') {
        thisStepReStudy.current = startRelearning.bind(null, mode);
      } else if (mode == 'grammar_b') {
        thisStepReStudy.current = startRelearning.bind(null, mode);
      } else if (mode == 'grammar_d') {
        thisStepReStudy.current = startRelearning.bind(null, mode);
      } else if (mode == 'speak') {
        thisStepReStudy.current = startRelearning.bind(null, mode);
      }

      if (mode == 'basic') {
        if (settingStateData.use_relearning && recordData.modules.advanced.done_count > 3) {
          nextStepStudy.current = () => modal_alert.openModalAlert('relaerning_limit');
        } else if (!settingStateData.use_relearning && recordData.modules.advanced.done_count > 0) {
          nextStepStudy.current = () => modal_alert.openModalAlert('unable_relearning');
        } else if (recordData.modules.advanced.record_id && !recordData?.modules.advanced.done) {
          nextStepStudy.current = continueLearning.bind(null, 'advanced');
        } else if (!recordData.modules.advanced.record_id) {
          nextStepStudy.current = prepareRecord.bind(null, 'advanced');
        }
      } else if (mode == 'advanced') {
        if (recordData.modules.wrong.status && !recordData.modules.wrong.done) {
          if (recordData.modules.wrong.startdate) {
            nextStepStudy.current = continueLearning.bind(null, 'wrong');
          } else {
            nextStepStudy.current = prepareRecord.bind(null, 'wrong');
          }
        }
      } //! (문법) 이론학습 / 기본학습 / 심화학습 처리 추가
      else if (mode == 'grammar_t') {
        if (recordData.modules.grammar_b.record_id && !recordData?.modules.grammar_b.done) {
          nextStepStudy.current = continueLearning.bind(null, 'grammar_b');
        } else if (!recordData.modules.grammar_b.record_id) {
          nextStepStudy.current = prepareRecord.bind(null, 'grammar_b');
        }
      } else if (mode == 'grammar_b') {
        if (recordData.modules.grammar_d.record_id && !recordData?.modules.grammar_d.done) {
          nextStepStudy.current = continueLearning.bind(null, 'grammar_d');
        } else if (!recordData.modules.grammar_d.record_id) {
          nextStepStudy.current = prepareRecord.bind(null, 'grammar_d');
        }
      }

      if (recordData.modules.wrong.status && !recordData.modules.wrong.done) {
        if (recordData.modules.wrong.startdate) {
          wrongStudy.current = continueLearning.bind(null, 'wrong');
        } else {
          wrongStudy.current = prepareRecord.bind(null, 'wrong');
        }
      }
    }
  }, [recordData, mode]);

  const handleResize = () => {
    const radar_chart = document.getElementById('radar_chart');
    if (radar_chart) {
      const width = radar_chart.offsetWidth;
      const height = radar_chart.offsetHeight;
      if (width > height) {
        setRadarChartSize(height);
      } else {
        setRadarChartSize(width);
      }
    }
  };

  useEffect(() => {
    handleResize();
  }, [screen_width, screen_height]);

  useEffect(() => {
    if (tab !== -1 && searchContents?.length) setSearchWord('');
    if (tab == 0) handleResize();
  }, [tab, searchContents]);

  useEffect(() => {
    const tmp_line_data: LineChartDataType[] = [];
    if (recentRecordData) {
      for (let i = 0; i < recentRecordData.length; i++) {
        const schedule_name = recentRecordData[i].schedule_name;
        const unit_name = recentRecordData[i].unit_name;
        const mode =
          recentRecordData[i].mode == 'basic'
            ? '기본학습'
            : recentRecordData[i].mode == 'advanced'
            ? '심화학습'
            : recentRecordData[i].mode == 'grammar_t'
            ? '이론학습'
            : recentRecordData[i].mode == 'grammar_b'
            ? '기본학습'
            : recentRecordData[i].mode == 'grammar_d'
            ? '심화학습'
            : '회화학습';
        switch (selectedAnalysisIdx) {
          case -1:
            tmp_line_data.push({
              schedule_name: schedule_name,
              mode: mode,
              unit_name: unit_name,
              subject: dayjs.unix(recentRecordData[i].lastdate).format('YY.MM.DD'),
              score: Number(recentRecordData[i].score),
            });
            break;
          case 0:
            tmp_line_data.push({
              schedule_name: schedule_name,
              unit_name: unit_name,
              mode: mode,
              subject: dayjs.unix(recentRecordData[i].lastdate).format('YY.MM.DD'),
              score: recentRecordData[i].word_count,
            });
            break;
          case 1:
            tmp_line_data.push({
              schedule_name: schedule_name,
              unit_name: unit_name,
              mode: mode,
              subject: dayjs.unix(recentRecordData[i].lastdate).format('YY.MM.DD'),
              score: recentRecordData[i].wrong_word_count,
            });
            break;
          case 2:
            tmp_line_data.push({
              schedule_name: schedule_name,
              unit_name: unit_name,
              mode: mode,
              subject: dayjs.unix(recentRecordData[i].lastdate).format('YY.MM.DD'),
              score: Math.round((recentRecordData[i].wrong_word_count / recentRecordData[i].word_count) * 100),
            });
            break;
          case 3:
            tmp_line_data.push({
              schedule_name: schedule_name,
              unit_name: unit_name,
              mode: mode,
              subject: dayjs.unix(recentRecordData[i].lastdate).format('YY.MM.DD'),
              score: Math.round(recentRecordData[i].studytime / 60),
            });
            break;
        }
      }
    }
    setLineChartData([...tmp_line_data]);
  }, [recentRecordData, selectedAnalysisIdx]);

  const onClickTab = (idx: number) => {
    playEffectSound('click');
    setTab(idx);
  };

  const onSearch = (spell: string) => {
    if (!contents || !spell) return false;
    let tmp_contents = JSON.parse(JSON.stringify(contents));
    tmp_contents = tmp_contents.filter((content: ContentType) => {
      if (content.type === 'sentence') {
        return content.sentence && content.sentence.includes(spell);
      } else {
        return content.spell && content.spell.includes(spell);
      }
    });

    setSearchContents([...tmp_contents]);
    setTab(-1);
  };

  const toggleWordDetail = (content: ContentType) => {
    setWordDetailcontent(content);
    setVisibleWordDetail(true);
  };

  const toggleWordDetail2 = (resultsheet: ResultsheetType) => {
    if (!contents) return false;
    const content = contents.find((content: ContentType) => content.id == resultsheet.word_id);
    setWordDetailcontent(content);
    setVisibleWordDetail(true);
  };

  const toggleBookmark = (content: ContentType) => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }

    if (content.type && content.type === 'sentence') {
      if (
        !note_contents.some(word => word.spell == content.sentence && word.type == content.type) &&
        !note_contents.some(word => word.spell == content.spell && word.type == content.type)
      ) {
        setPocketNoteContent(content);
        setVisibleAddPocketNote(true);
      }
    } else {
      if (!note_contents.some(word => word.spell == content.spell)) {
        setPocketNoteContent(content);
        setVisibleAddPocketNote(true);
      }
    }
  };

  const changeDetailRecordTab = (idx: number) => {
    setDetailRecordTab(idx);
  };

  const mergeAnswer = (answer: string, rightanswer: string) => {
    let result = '';

    let last_wrong = false;
    for (let i = 0; i < rightanswer.length; i++) {
      if (answer && rightanswer[i] === answer[i]) {
        if (last_wrong) {
          result += '</span>';
          last_wrong = false;
        }
        result += rightanswer[i];
      } else {
        if (!last_wrong) {
          result += '<span class="c_red">';
          last_wrong = true;
        }
        result += rightanswer[i];
      }
    }
    if (last_wrong) result += '</span>';

    return result;
  };

  const onWrongStudyClicked = () => {
    if (
      (mode == 'speak' && ['V003Y', 'V003DY', 'V003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['basic', 'advanced'].includes(mode) &&
        ['S003Y', 'S003DY', 'S003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['grammar_t', 'grammar_d', 'grammar_b'].includes(mode) &&
        ['S003Y', 'S003DY', 'S003TY', 'V003Y', 'V003DY', 'V003TY'].includes(userStateData.product))
    ) {
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      return false;
    }
    const wrong_study = wrongStudy.current;
    if (wrong_study != undefined)
      modal_confirm.openModalConfirm('normal', 'start_wrong', () => {
        wrong_study();
      });
  };

  const SpeakingAudioPlayer = (item: ResultsheetType) => {
    const book_type = userStateData.type < 20 && scheduleType == 'schedules' ? 'schedules' : 'books';
    const filename = `${book_type}-${userStateData.id}-${scheduleId}-${recordId}-${item.no - 1}.mp3`;
    const audio_url = '/etc/speaking/' + filename;
    return (
      <CircleAudioPlayer
        visibleWordDetail={visibleWordDetail}
        audio_url={audio_url}
        idx={item.no - 1}
      ></CircleAudioPlayer>
    );
  };

  // 재학습
  const thisStepReStudy = useRef<(() => void) | undefined>(undefined);

  // 다음 학습
  const nextStepStudy = useRef<(() => void) | undefined>(undefined);

  // 오답 학습
  const wrongStudy = useRef<(() => void) | undefined>(undefined);

  const prepareRecord = async (type: string) => {
    setPercent(setLoadingStateData, 1);
    setLearningStateData(prevState => {
      return {
        ...prevState,
        first_learning: true,
        tag: state.tag,
      };
    });
    startLearning(type);
  };

  const startLearning = async (type: string) => {
    if (!recordData) return false;
    setPercent(setLoadingStateData, 33);
    if (['basic', 'advanced', 'wrong'].includes(type)) {
      //! 오래보카 / 내신
      const book_type = userStateData.type < 20 && scheduleType == 'schedules' ? 'schedules' : 'books';
      const word_type = type == 'wrong' ? 'wrongwords' : 'words';
      const contents = await fetchGetApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${recordData?.schedule_id}/units/${recordData?.unit_id}/${word_type}?type=${book_type}`,
      );
      if (contents.result) {
        setPercent(setLoadingStateData, 67);

        const basic_module = recordData?.basic ? recordData.basic : '123';
        const advanced_module = recordData?.advanced ? recordData.advanced : '456';

        // 학습 시작시 로직, 이어하기 로직 필요
        const learning_type = type == 'basic' ? 3 : type == 'advanced' ? 4 : 5;
        const modules = type == 'basic' ? basic_module : type == 'advanced' ? advanced_module : '23';
        const mod: ModType[] = [];
        let contents_data = contents.data;

        // 교재순서 랜덤
        if (!settingStateData.order_word) {
          contents_data = arrShuffle(contents_data);
        }

        for (let i = 0; i < modules.length; i++) {
          const module = Number(modules[i]) as ModuleIndex;

          mod[i] = {
            module: module,
            seq: i, // 학습모듈 순서
            done: 0, // 완료여부 (0: 미완료, 1: 완료)
            step: 0, // 학습 진행도
          };
        }

        setLearningStateData(prevState => {
          return {
            ...prevState,
            status: true,
            starttime: dayjs().format(),
            studytime: 0,
            learning_type: learning_type,
            current_page: 0,
            current_step: 0,
            mod: mod,
            modules: modules,
            modal_disable: false,
            record_id: recordData.record_id,
            schedule_id: recordData.schedule_id,
            schedule_name: recordData.schedule_name,
            book_type: book_type,
            tab_name: tabName,
            unit_id: recordData.unit_id,
            unit_name: recordData.unit_name,
            contents: contents_data,
            resultsheet: [],
            show_modal: false,
            current_module_settings: moduleSettingStateData,
            use_image: recordData.use_image == 0 ? 0 : 1,
            study_method: recordData.study_method ? recordData.study_method : 0,
            first_alert: true,
          };
        });

        const path =
          child_path == 'result'
            ? `${BASE_URL}/${root_path}/learning`
            : `${BASE_URL}/${root_path}/${child_path}/learning`;

        navigate(path);
      } else {
        // error
        setPercent(setLoadingStateData, 100);
      }
    } else if (['grammar_t', 'grammar_b', 'grammar_d'].includes(type)) {
      //! 문법
      const grammar_type = type.substr(-1);

      const grammar_count = await fetchTabStudyGetApi(
        `v2/book/getgrammarcount?unit_id=${recordData.tab_unit_id}&type=G${grammar_type?.toUpperCase()}`,
      );

      const mod: ModType[] = [];

      if (grammar_count) {
        let modules: number[] = grammar_count.cnt.split('-').map((item: any) => Number(item));

        const grammar_total_count = modules.reduce((acc, cur) => {
          return (acc += cur);
        }, 0);

        if (grammar_type == 't') {
          //! 이론학습
          if (grammar_total_count == 0) {
            //! 복습강의 O
            modules = [0];
          }
        } else {
          //! 기본학습, 심화학습
          const filter_modules = modules.filter(item => item !== 0);

          if (filter_modules.length != modules.length) {
            //! 복습강의
            modules = [...filter_modules];
          }
        }

        modules = modules.map((item, idx) => idx + 1);

        for (let i = 0; i < modules.length; i++) {
          mod.push({
            module: modules[i],
            seq: i, // 학습모듈 순서
            done: 0, // 완료여부 (0: 미완료, 1: 완료)
            step: 0, // 학습 진행도
          });
        }

        const learning_type = grammar_type == 't' ? 7 : grammar_type == 'b' ? 8 : 9;

        setPercent(setLoadingStateData, 67);

        setLearningStateData(prevState => ({
          ...prevState,
          status: true,
          starttime: dayjs().format(),
          studytime: 0,
          learning_type: learning_type,
          current_page: 0,
          current_step: 0,
          mod: mod,
          modules: modules.join(''),
          modal_disable: false,
          record_id: recordData.record_id,
          schedule_id: recordData.schedule_id,
          schedule_name: recordData.schedule_name,
          book_type: 'books',
          tab_name: 'grammar',
          unit_id: recordData.unit_id,
          unit_name: `Unit ${recordData.sequence}`,
          show_modal: false,
          resultsheet: [],
          first_alert: true,
          schedule_type: 'books',
          grammar_learning_type: grammar_type as GrammarIndex,
          grammar_unit_seq: recordData.sequence,
          grammar_tab_unit_id: recordData.tab_unit_id as number,
          grammar_total_count: grammar_total_count,
          grammar_origin_unit_name: recordData.unit_name,
          font_level: 1,
        }));

        const path =
          child_path == 'result'
            ? `${BASE_URL}/${root_path}/grammar`
            : `${BASE_URL}/${root_path}/${child_path}/grammar`;

        navigate(path);
      } else {
        // error
        setPercent(setLoadingStateData, 100);
      }
    } else if (type == 'speak') {
      //! 회화
      const book_type = 'books';
      const word_type = 'speak';
      const contents = await fetchGetApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${recordData?.schedule_id}/units/${recordData?.unit_id}/${word_type}?type=${book_type}`,
      );
      if (contents.result) {
        setPercent(setLoadingStateData, 67);

        // 학습 시작시 로직, 이어하기 로직 필요
        const learning_type = 6;
        const modules = '786';
        const mod: ModType[] = [];
        const contents_data = contents.data;

        for (let i = 0; i < modules.length; i++) {
          const module = Number(modules[i]) as ModuleIndex;

          mod[i] = {
            module: module,
            seq: i, // 학습모듈 순서
            done: 0, // 완료여부 (0: 미완료, 1: 완료)
            step: 0, // 학습 진행도
          };
        }

        setLearningStateData(prevState => ({
          ...prevState,
          status: true,
          starttime: dayjs().format(),
          studytime: 0,
          learning_type: learning_type,
          current_page: 0,
          current_step: 0,
          mod: mod,
          modules: modules,
          modal_disable: false,
          record_id: recordData.record_id,
          schedule_id: recordData.schedule_id,
          schedule_name: recordData.schedule_name,
          book_type: book_type,
          tab_name: 'speak',
          unit_id: recordData.unit_id,
          unit_name: recordData.unit_name,
          contents: contents_data,
          resultsheet: [],
          show_modal: false,
          current_module_settings: moduleSettingStateData,
          use_image: 1,
          study_method: 0,
          first_alert: true,
          schedule_type: 'books',
          category_id: recordData.category_id,
          folder_id: recordData.folder_id,
        }));

        const path =
          child_path == 'result' ? `${BASE_URL}/${root_path}/speak` : `${BASE_URL}/${root_path}/${child_path}/speak`;

        navigate(path);
      } else {
        // error
        setPercent(setLoadingStateData, 100);
      }
    }
  };

  const startRelearning = async (
    type: 'basic' | 'advanced' | 'wrong' | 'speak' | 'grammar_t' | 'grammar_b' | 'grammar_d',
  ) => {
    if (!recordData) return;
    const unit_detail = recordData['modules'][type];
    setPercent(setLoadingStateData, 1);

    const module_record_id = unit_detail.record_id;
    if (module_record_id) {
      setLearningStateData(prevState => {
        return {
          ...prevState,
          module_record_id: module_record_id,
          relearning: true,
          tag: state.tag,
        };
      });
      startLearning(type);
    } else {
      setPercent(setLoadingStateData, 100);
    }
  };

  const continueLearning = async (type: 'advanced' | 'wrong' | 'grammar_b' | 'grammar_d') => {
    if (!recordData) return;
    const unit_detail = recordData['modules'][type];
    if (unit_detail && unit_detail.record_id) {
      setPercent(setLoadingStateData, 33);

      if (['grammar_b', 'grammar_d'].includes(type)) {
        //! 오래문법 처리
        const grammar_type = type.substr(-1);

        const grammar_count = await fetchTabStudyGetApi(
          `v2/book/getgrammarcount?unit_id=${recordData.tab_unit_id}&type=G${grammar_type?.toUpperCase()}`,
        );

        const grammar_total_count = grammar_count.cnt
          .split('-')
          .map((item: any) => Number(item))
          .reduce((acc: any, cur: any) => {
            return (acc += cur);
          }, 0);

        const module_record_id = unit_detail.record_id;

        //! 중간에 저장한 성적표 데이터 가져오기
        const middlesheet_res = await fetchGetApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${recordData.schedule_id}/units/${recordData.unit_id}/records/${recordData.record_id}/modules/${module_record_id}?type=books`,
        );

        if (module_record_id && middlesheet_res.result) {
          const learning_type = grammar_type == 't' ? 7 : grammar_type == 'b' ? 8 : 9;
          const modules = middlesheet_res.data.mod.map((item: ModType) => item.module).join('');
          setLearningStateData(prevState => ({
            ...prevState,
            status: true,
            starttime: dayjs().format(),
            learning_type: learning_type,
            studytime: middlesheet_res.data.studytime,
            current_page: middlesheet_res.data.current_page ?? 0,
            current_step: middlesheet_res.data.current_step ?? 0,
            mod: middlesheet_res.data.mod,
            modules: modules,
            modal_disable: false,
            record_id: recordData.record_id as number,
            schedule_id: recordData.schedule_id,
            schedule_name: recordData.schedule_name,
            unit_id: recordData.unit_id,
            unit_name: `Unit ${recordData.sequence}`,
            book_type: 'books',
            resultsheet: middlesheet_res.data.mod[middlesheet_res.data.current_page].resultsheet,
            show_modal: false,
            module_record_id: module_record_id,
            save_enabled: true,
            first_alert: true,
            schedule_type: 'books',
            grammar_learning_type: grammar_type as GrammarIndex,
            grammar_unit_seq: recordData.sequence,
            grammar_tab_unit_id: recordData.tab_unit_id as number,
            grammar_total_count: grammar_total_count,
            grammar_origin_unit_name: recordData.unit_name,
            contents: middlesheet_res.data.mod[middlesheet_res.data.current_page].contents ?? [],
            font_level: 1,
          }));

          const path =
            child_path == 'result'
              ? `${BASE_URL}/${root_path}/grammar`
              : `${BASE_URL}/${root_path}/${child_path}/grammar`;

          navigate(path);
        } else {
          //! resetRecord 처리
          resetRecord(type);
        }
      } else {
        //! 기존 오래보카 / 오래내신 처리
        const book_type = userStateData.type < 20 && scheduleType == 'schedules' ? 'schedules' : 'books';
        const module_record_id = unit_detail.record_id;
        const middlesheet_res = await fetchGetApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${recordData.schedule_id}/units/${recordData.unit_id}/records/${recordData.record_id}/modules/${module_record_id}?type=${book_type}`,
        );
        if (module_record_id && middlesheet_res.result) {
          let learning_type = 4;

          if (middlesheet_res.data.mod) {
            setPercent(setLoadingStateData, 66);
            if (middlesheet_res.data.mode == 'advanced') {
              learning_type = 4;
            } else {
              learning_type = 5;
            }
            setLearningStateData(prevState => {
              return {
                ...prevState,
                status: true,
                starttime: dayjs().format(),
                learning_type: learning_type as learningTypeIndex,
                studytime: middlesheet_res.data.studytime,
                current_page: middlesheet_res.data.current_page ?? 0,
                current_step: middlesheet_res.data.current_step ?? 0,
                mod: middlesheet_res.data.mod,
                modal_disable: false,
                record_id: recordData.record_id,
                schedule_id: recordData.schedule_id,
                schedule_name: recordData.schedule_name,
                unit_id: recordData.unit_id,
                unit_name: recordData.unit_name,
                book_type: book_type,
                tab_name: tabName,
                contents: middlesheet_res.data.contents,
                resultsheet: middlesheet_res.data.mod[middlesheet_res.data.current_page].resultsheet,
                show_modal: false,
                current_module_settings: moduleSettingStateData,
                module_record_id: module_record_id,
                use_image: recordData.use_image == 0 ? 0 : 1,
                study_method: recordData.study_method ? recordData.study_method : 0,
                save_enabled: true,
                first_alert: true,
                tag: state.tag,
              };
            });

            const path =
              child_path == 'result'
                ? `${BASE_URL}/${root_path}/learning`
                : `${BASE_URL}/${root_path}/${child_path}/learning`;

            navigate(path);
          } else {
            resetRecord(type);
          }
        } else {
          resetRecord(type);
        }
      }
    }
  };

  const resetRecord = async (type: 'basic' | 'advanced' | 'wrong' | 'grammar_b' | 'grammar_d') => {
    if (!recordData) return false;
    const unit_detail = recordData['modules'][type];
    setPercent(setLoadingStateData, 1);
    const module_record_id = unit_detail.record_id;
    if (module_record_id) {
      setLearningStateData(prevState => {
        return {
          ...prevState,
          module_record_id: module_record_id,
          reset_learning: true,
        };
      });
      startLearning(type);
    } else {
      setPercent(setLoadingStateData, 100);
    }
  };

  const CustomTick = (props: any) => {
    const { x, y, payload } = props;

    const makeXAxis = (index: number) => {
      if (index == 0) {
        return x;
      } else if (index == 1) {
        return screen_height > 600 ? x + 15 : x + 7;
      } else if (index == 2) {
        return x;
      } else {
        if (payload.value.length == 3) return screen_height > 600 ? x - 22 : x - 11;
        else return screen_height > 600 ? x - 15 : x - 8;
      }
    };

    const makeYAxis = (index: number) => {
      if (index == 0) {
        return screen_height > 600 ? y : y + 2;
      } else if (index == 1) {
        return y + 5;
      } else if (index == 2) {
        return screen_height > 600 ? y + 12 : y + 6;
      } else {
        return y + 5;
      }
    };

    return (
      <g transform={`translate(${makeXAxis(payload.index)},${makeYAxis(payload.index)})`}>
        <text x={0} y={0} fill={'#000'} fontSize={'1.1rem'} textAnchor='middle' fontWeight={500}>
          {payload.value}
        </text>
      </g>
    );
  };

  const renderMarking = (module: number, mode: string, item: ResultsheetType, idx: number) => {
    if (module == 6) {
      const result = getScore(item.answer as string, item.rightanswer as string);
      if (item.type == 'sentence') {
        const is_inclouded_red = result.html.includes(`<span class='c_red'>`);
        if (result.score >= 80) {
          if (is_inclouded_red) {
            return <IconReportT className='triangle' seq_id={`t_${idx}`} />;
          } else {
            return <IconReportO seq_id={`o_${idx}`} />;
          }
        } else if (result.score >= 40) {
          return <IconReportT className='triangle' seq_id={`t_${idx}`} />;
        } else {
          return <IconReportX seq_id={`x_${idx}`} />;
        }
      } else {
        if (result.score >= 70) {
          return <IconReportO seq_id={`o_${idx}`} />;
        } else if (result.score > 0) {
          return <IconReportT className='triangle' seq_id={`t_${idx}`} />;
        } else {
          return <IconReportX seq_id={`x_${idx}`} />;
        }
      }
    } else {
      if (item.rightanswer == item.answer) {
        return <IconReportO seq_id={`o_${idx}`} />;
      } else if (mode == 'basic' && module == 1 && item.answer == '333') {
        return <IconReportT className='triangle' seq_id={`t_${idx}`} />;
      } else if (module == 7 && item.answer == '333') {
        return <IconReportT className='triangle' seq_id={`t_${idx}`} />;
      } else {
        return <IconReportX seq_id={`x_${idx}`} />;
      }
    }
  };

  const grammarRenderMarking = (right: boolean, idx: number) => {
    if (right) {
      return <IconReportO seq_id={`o_${idx}`} />;
    } else {
      return <IconReportX seq_id={`x_${idx}`} />;
    }
  };

  const handleSpeakContentBookmarkClick = (id: string) => {
    if (recordData) {
      const selectedBookmarkItem = recordData.contents.find(content => content.sentence_id === id);

      if (selectedBookmarkItem) {
        toggleBookmark(selectedBookmarkItem);
      }
    }
  };

  useEffect(() => {
    if ([1, 2].includes(tab)) {
      //! sa_input 클래스 찾아서 모두 disabled 세팅
      const els = document.getElementsByClassName('sa_input');
      if (els && els.length > 0) {
        for (let i = 0; i < els.length; i++) {
          (els[i] as HTMLElement).setAttribute('disabled', 'true');
        }
      }
    }
  }, [tab, detailRecordTab]);

  return (
    <>
      {percent > 0 ? null : (
        <StyledReportWrap>
          <ReportSide
            nextStepStudy={nextStepStudy.current}
            tab={tab}
            onClickTab={onClickTab}
            mode={mode}
            modLength={recordData?.mod.length}
          />
          <ReportHeader
            thisStepReStudy={thisStepReStudy.current}
            nextStepStudy={nextStepStudy.current}
            wrongStudy={wrongStudy.current}
            onSearch={onSearch}
            mode={mode}
            searchWord={searchWord}
            onKeyDown={onKeyDown}
            setWord={setWord}
          />
          <StyledMainWrap>
            {tab == -1 ? (
              <StyledDetailListBox>
                <StyledDetailListHeader>
                  <StyledTabButton sx={{ cursor: 'default' }} selected={1} disableRipple>
                    REPORT
                  </StyledTabButton>
                </StyledDetailListHeader>
                <StyledDetailListBody sx={{ height: 'calc(100% - 7vh)' }}>
                  <CustomTable id='data_table'>
                    <CustomTableBody>
                      {searchContents && searchContents.length > 0 ? (
                        searchContents.map((item, idx1) => {
                          return (
                            <Fragment key={`body_row_wrap_${idx1}`}>
                              <CustomTableRow key={`body_row_${idx1}`}>
                                <TableCell sx={{ width: '5% !important', borderBottom: 'unset' }} component='td'>
                                  <ListNumberBox className='icon-box'>
                                    <StyledNumber>{idx1 + 1}</StyledNumber>
                                  </ListNumberBox>
                                </TableCell>
                                <CustomTableCell component='td'>
                                  {isSpeakMode ? (
                                    <Box sx={{ ...d_flex_space_between }}>
                                      <Box className='word-wrap'>
                                        <Box sx={{ lineHeight: '1' }}>{item.translate}</Box>
                                        <Box sx={{ lineHeight: '1', color: theme.palette.purple_2.main }}>
                                          {item.sentence}
                                        </Box>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box sx={{ ...d_flex_space_between }}>
                                      <Box className='word-wrap'>
                                        <Box sx={{ lineHeight: '1' }}>{item.mean}</Box>
                                        <Box sx={{ lineHeight: '1', color: theme.palette.purple_2.main }}>
                                          {item.spell}
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                </CustomTableCell>
                                {!isSpeakMode ? (
                                  <TableCell sx={{ width: '5% !important', textAlign: 'center' }} component='td'>
                                    <CustomWordDetailButton disableRipple onClick={() => toggleWordDetail(item)}>
                                      <IoMdInformationCircle />
                                    </CustomWordDetailButton>
                                  </TableCell>
                                ) : (
                                  <TableCell sx={{ width: '5% !important', textAlign: 'center' }} component='td'>
                                    <CustomBookmarkButton
                                      disableRipple
                                      onClick={() => toggleBookmark(item)}
                                      bookmark={note_contents.some(word => word.spell == item.sentence) ? 1 : 0}
                                    >
                                      <FiStar />
                                    </CustomBookmarkButton>
                                  </TableCell>
                                )}
                              </CustomTableRow>
                            </Fragment>
                          );
                        })
                      ) : (
                        <CustomTableRow>
                          <CustomTableCell
                            width={'100%'}
                            sx={{ textAlign: 'center', color: '#c0c0c0', fontWeight: '400' }}
                            colSpan={3}
                          >
                            검색 결과가 없습니다.
                          </CustomTableCell>
                        </CustomTableRow>
                      )}
                    </CustomTableBody>
                  </CustomTable>
                </StyledDetailListBody>
              </StyledDetailListBox>
            ) : mode != 'wrong' && tab == 0 ? (
              <StyledAnalysisWrap>
                <StyledAnalysisHeaderBox>
                  <StyledAnalysisOuterBox sx={{ paddingRight: '1rem' }}>
                    <StyledAnalysisInnerBox>
                      <StyledAnalysisTitleBox>
                        {(function () {
                          switch (mode) {
                            case 'basic':
                              return '기본학습';
                            case 'advanced':
                              return '심화학습';
                            case 'grammar_t':
                              return '이론학습';
                            case 'grammar_b':
                              return '기본학습';
                            case 'grammar_d':
                              return '심화학습';
                            default:
                              return null;
                          }
                        })()}
                        &nbsp;Report
                      </StyledAnalysisTitleBox>
                      <StyledAnalysisContentBox
                        sx={{
                          alignItems: 'flex-start',
                          fontSize: '2vh',
                          justifyContent: 'space-evenly',
                          padding: '1.7rem 0 2.45rem 0',
                        }}
                      >
                        <Box>
                          <Box sx={{ fontWeight: '600', marginBottom: '0.25rem' }}>Book</Box>
                          <Box sx={{ color: '#646464', fontWeight: '400' }}>{recordData?.schedule_name}</Box>
                        </Box>
                        <Box>
                          <Box sx={{ fontWeight: '600', marginBottom: '0.25rem' }}>Unit</Box>
                          <Box sx={{ color: '#646464', fontWeight: '400' }}>{recordData?.unit_name}</Box>
                        </Box>
                        <Box>
                          <Box sx={{ fontWeight: '600', marginBottom: '0.25rem' }}>Date</Box>
                          <Box sx={{ color: '#646464', fontWeight: '400' }}>
                            {recordData?.lastdate ? dayjs.unix(recordData.lastdate).format('YYYY-MM-DD HH:MM:ss') : ''}
                            &nbsp;
                            {(function () {
                              if (recordData) {
                                if (recordData.done_count == 1) {
                                  return (
                                    <Box component={'span'} sx={{ color: '#b5b5b5' }}>
                                      (정규학습)
                                    </Box>
                                  );
                                } else {
                                  return (
                                    <Box component={'span'} sx={{ color: '#ff976b' }}>
                                      ({recordData.done_count - 1}차 재학습)
                                    </Box>
                                  );
                                }
                              }
                            })()}
                          </Box>
                        </Box>
                      </StyledAnalysisContentBox>
                    </StyledAnalysisInnerBox>
                  </StyledAnalysisOuterBox>
                  {GRAMMAR_MODES.includes(mode) ? null : (
                    <StyledAnalysisOuterBox sx={{ padding: '0 1rem' }}>
                      <StyledAnalysisInnerBox>
                        <StyledAnalysisTitleBox>Graph</StyledAnalysisTitleBox>
                        <StyledAnalysisContentBox id='radar_chart'>
                          {radarChartData ? (
                            <ResponsiveContainer width='100%' height='100%'>
                              <StyledRadarChart
                                outerRadius={'85%'}
                                width={radarChartSize}
                                height={radarChartSize}
                                data={radarChartData}
                              >
                                <PolarGrid />
                                <PolarAngleAxis
                                  dataKey='subject'
                                  tick={<CustomTick />}
                                  tickLine={false}
                                  axisLine={false}
                                />
                                <Radar
                                  max={100}
                                  dataKey='score'
                                  stroke='#ffb276'
                                  fill='#ffb276'
                                  fillOpacity={0.6}
                                  isAnimationActive={false}
                                />
                              </StyledRadarChart>
                            </ResponsiveContainer>
                          ) : null}
                        </StyledAnalysisContentBox>
                      </StyledAnalysisInnerBox>
                    </StyledAnalysisOuterBox>
                  )}
                  <StyledAnalysisOuterBox sx={{ paddingLeft: '1rem' }}>
                    <StyledAnalysisInnerBox
                      sx={{ cursor: 'pointer !important', '& *': { cursor: 'pointer !important' } }}
                      onClick={() => {
                        playEffectSound('click');
                        setSelectedAnalysisIdx(-1);
                      }}
                    >
                      <StyledAnalysisTitleBox>Score</StyledAnalysisTitleBox>
                      <StyledAnalysisContentBox>
                        {recordData && recordData?.score != null ? (
                          <DonutChart score={Math.round(recordData.score)}></DonutChart>
                        ) : null}
                      </StyledAnalysisContentBox>
                    </StyledAnalysisInnerBox>
                  </StyledAnalysisOuterBox>
                </StyledAnalysisHeaderBox>
                <StyledAnalysisBodyTabBox>
                  <StyledAnalysisOuterBox sx={{ paddingRight: '1rem' }}>
                    <StyledAnalysisInnerBox
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        playEffectSound('click');
                        setSelectedAnalysisIdx(0);
                      }}
                      selected={selectedAnalysisIdx == 0 ? 1 : 0}
                    >
                      <StyledAnalysisTitleBox selected={selectedAnalysisIdx == 0 ? 1 : 0}>
                        학습 문제
                      </StyledAnalysisTitleBox>
                      <StyledAnalysisContentBox selected={selectedAnalysisIdx == 0 ? 1 : 0}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <Box
                            sx={{
                              fontSize: '5vh',
                              fontWeight: '600',
                              lineHeight: '0.75',
                              letterSpacing: '-2px',
                            }}
                            component={'span'}
                          >
                            {recordData?.word_count}
                          </Box>
                          &nbsp;
                          <Box sx={{ fontSize: '3vh', fontWeight: '300', lineHeight: '1' }} component={'span'}>
                            개
                          </Box>
                        </Box>
                      </StyledAnalysisContentBox>
                    </StyledAnalysisInnerBox>
                  </StyledAnalysisOuterBox>
                  <StyledAnalysisOuterBox sx={{ padding: '0 1rem' }}>
                    <StyledAnalysisInnerBox
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        playEffectSound('click');
                        setSelectedAnalysisIdx(1);
                      }}
                      selected={selectedAnalysisIdx == 1 ? 1 : 0}
                    >
                      <StyledAnalysisTitleBox selected={selectedAnalysisIdx == 1 ? 1 : 0}>
                        오답 문제
                      </StyledAnalysisTitleBox>
                      <StyledAnalysisContentBox selected={selectedAnalysisIdx == 1 ? 1 : 0}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <Box
                            sx={{
                              fontSize: '5vh',
                              fontWeight: '600',
                              lineHeight: '0.75',
                              letterSpacing: '-px',
                            }}
                            component={'span'}
                          >
                            {recordData?.wrong_word_count}
                          </Box>
                          &nbsp;
                          <Box sx={{ fontSize: '3vh', fontWeight: '300', lineHeight: '1' }} component={'span'}>
                            개
                          </Box>
                        </Box>
                      </StyledAnalysisContentBox>
                    </StyledAnalysisInnerBox>
                  </StyledAnalysisOuterBox>
                  <StyledAnalysisOuterBox sx={{ padding: '0 1rem' }}>
                    <StyledAnalysisInnerBox
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        playEffectSound('click');
                        setSelectedAnalysisIdx(2);
                      }}
                      selected={selectedAnalysisIdx == 2 ? 1 : 0}
                    >
                      <StyledAnalysisTitleBox selected={selectedAnalysisIdx == 2 ? 1 : 0}>
                        오답률
                      </StyledAnalysisTitleBox>
                      <StyledAnalysisContentBox selected={selectedAnalysisIdx == 2 ? 1 : 0}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <Box
                            sx={{
                              fontSize: '5vh',
                              fontWeight: '600',
                              lineHeight: '0.75',
                              letterSpacing: '-px',
                            }}
                            component={'span'}
                          >
                            {recordData
                              ? recordData.mode == 'grammar_t' &&
                                recordData.word_count == 0 &&
                                recordData.wrong_word_count == 0
                                ? 0
                                : Math.round((recordData.wrong_word_count / recordData.word_count) * 100)
                              : 0}
                          </Box>
                          &nbsp;
                          <Box sx={{ fontSize: '3vh', fontWeight: '300', lineHeight: '0.85' }} component={'span'}>
                            %
                          </Box>
                        </Box>
                      </StyledAnalysisContentBox>
                    </StyledAnalysisInnerBox>
                  </StyledAnalysisOuterBox>
                  <StyledAnalysisOuterBox sx={{ paddingLeft: '1rem' }}>
                    <StyledAnalysisInnerBox
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        playEffectSound('click');
                        setSelectedAnalysisIdx(3);
                      }}
                      selected={selectedAnalysisIdx == 3 ? 1 : 0}
                    >
                      <StyledAnalysisTitleBox selected={selectedAnalysisIdx == 3 ? 1 : 0}>
                        학습 시간
                      </StyledAnalysisTitleBox>
                      <StyledAnalysisContentBox selected={selectedAnalysisIdx == 3 ? 1 : 0}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <Box
                            sx={{
                              fontSize: '5vh',
                              fontWeight: '600',
                              lineHeight: '0.75',
                              letterSpacing: '-px',
                            }}
                            component={'span'}
                          >
                            {recordData && recordData.studytime ? Math.round(recordData.studytime / 60) : 0}
                          </Box>
                          &nbsp;
                          <Box sx={{ fontSize: '3vh', fontWeight: '300', lineHeight: '1' }} component={'span'}>
                            분
                          </Box>
                        </Box>
                      </StyledAnalysisContentBox>
                    </StyledAnalysisInnerBox>
                  </StyledAnalysisOuterBox>
                </StyledAnalysisBodyTabBox>
                {lineChartData?.length ? (
                  <StyledAnalysisBodyBox>
                    <StyledAnalysisOuterBox>
                      <StyledAnalysisInnerBox
                        sx={{
                          heigth: '55vh',
                          '.recharts-xAxis': {
                            '.recharts-cartesian-axis-tick:last-child, .recharts-cartesian-axis-tick:first-child': {
                              display: lineChartData?.length >= 1 ? 'none' : '',
                            },
                          },
                        }}
                      >
                        {lineChartData ? (
                          <CustomLineChart useTootip tab={selectedAnalysisIdx} data={lineChartData} />
                        ) : null}
                      </StyledAnalysisInnerBox>
                    </StyledAnalysisOuterBox>
                  </StyledAnalysisBodyBox>
                ) : null}
              </StyledAnalysisWrap>
            ) : tab == 1 || (mode == 'wrong' && tab == 0) ? (
              <StyledDetailListBox>
                <StyledDetailListHeader>
                  {GRAMMAR_MODES.includes(recordData && recordData.mode ? recordData?.mode : '') ? (
                    recordData && recordData.mod.length > 0 ? (
                      recordData.mod.map((item, idx) => {
                        if (recordData.mode == 'grammar_t' && item.module == 1) return null;
                        const kor_mode = recordData.mode == 'grammar_b' ? '기본학습' : '심화학습';
                        const tab_name =
                          recordData.mode == 'grammar_t' ? THEORY_LABEL[item.module] : `${kor_mode}${item.module}`;

                        let select_idx = idx;
                        if (recordData.mode == 'grammar_t') select_idx -= 1;

                        return (
                          <StyledTabButton
                            key={`tab_btn_${idx}`}
                            selected={detailRecordTab == select_idx ? 1 : 0}
                            onClick={() => changeDetailRecordTab(select_idx)}
                            disableRipple
                          >
                            {tab_name}
                          </StyledTabButton>
                        );
                      })
                    ) : null
                  ) : recordData && recordData.mod.length > 0 ? (
                    <>
                      {recordData.mod.map((item, idx) => {
                        return (
                          <StyledTabButton
                            key={`tab_btn_${idx}`}
                            selected={detailRecordTab == idx ? 1 : 0}
                            onClick={() => changeDetailRecordTab(idx)}
                            disableRipple
                          >
                            {isSpeakMode
                              ? SPEAK_LABEL[item.module as ModuleIndex]
                              : MODULES_LABEL[item.module as ModuleIndex]}
                          </StyledTabButton>
                        );
                      })}
                    </>
                  ) : null}
                </StyledDetailListHeader>
                <StyledDetailListBody sx={{ height: 'calc(100% - 7vh)' }}>
                  <CustomTable id='data_table'>
                    <CustomTableBody>
                      {GRAMMAR_MODES.includes(recordData && recordData.mode ? recordData?.mode : '') ? (
                        recordData && recordData.mode == 'grammar_t' ? (
                          recordData && recordData.mod[detailRecordTab + 1] ? (
                            recordData.mod[detailRecordTab + 1].resultsheet?.map((item, idx1) => {
                              const result: any = item;
                              return (
                                <CustomTableRow key={`body_row_${idx1}`} sx={{ minHeight: '6.4vh', height: 'auto' }}>
                                  <TableCell
                                    sx={{ width: '8.3333333333% !important', borderBottom: 'unset' }}
                                    component='td'
                                  >
                                    <ListNumberBox
                                      className='icon-box'
                                      sx={{ position: 'absolute', top: '0.75rem', height: 'auto' }}
                                    >
                                      <StyledNumber>{idx1 + 1}</StyledNumber>
                                    </ListNumberBox>
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: '8.3333333333% !important', borderBottom: 'unset' }}
                                    component='td'
                                  >
                                    <ListMarkingBox
                                      className='icon-box'
                                      sx={{ position: 'absolute', top: '0.75rem', height: 'auto' }}
                                    >
                                      {grammarRenderMarking(result.right, idx1)}
                                    </ListMarkingBox>
                                  </TableCell>
                                  <CustomTableCell component='td' sx={{ padding: '0.625rem 0 !important' }}>
                                    <div
                                      style={{
                                        lineHeight: 'calc(1rem + 3px)',
                                        whiteSpace: 'normal',
                                        wordBreak: 'break-all',
                                      }}
                                      dangerouslySetInnerHTML={{ __html: result.question }}
                                    ></div>
                                    {item.type == 'CAB' ? (
                                      result.passage.split('<br />').map((sen: any, sen_key: number) => (
                                        <Fragment key={`passage-${sen_key}`}>
                                          {sen_key !== 0 && <br key={`passage-br-${sen_key}`} />}
                                          {sen.charAt(0) === '<' ? (
                                            <span
                                              style={{ color: '#757575', caretColor: '#757575' }}
                                              dangerouslySetInnerHTML={{ __html: sen }}
                                            ></span>
                                          ) : (
                                            Array.from(sen).map((char: any, char_key: number) => (
                                              <Fragment key={`passage-span-${sen_key}-${char_key}`}>
                                                {char === 'ⓐ' ? (
                                                  <Box
                                                    component={'span'}
                                                    sx={{
                                                      color:
                                                        result.rightanswer == result.choose_a
                                                          ? '#1776d1'
                                                          : result.selectedWor == result.choose_a
                                                          ? '#FF0000'
                                                          : '#eee',
                                                    }}
                                                  >
                                                    {result.choose_a}
                                                  </Box>
                                                ) : char === 'ⓑ' ? (
                                                  <Box
                                                    component={'span'}
                                                    sx={{
                                                      color:
                                                        result.rightanswer == result.choose_b
                                                          ? '#1776d1'
                                                          : result.selectedWor == result.choose_b
                                                          ? '#FF0000'
                                                          : '#eee',
                                                    }}
                                                  >
                                                    {result.choose_b}
                                                  </Box>
                                                ) : char === '/' ? (
                                                  <Box component={'span'} sx={{ color: '#eee', caretColor: '#eee' }}>
                                                    {' / '}
                                                  </Box>
                                                ) : (
                                                  <Box component={'span'}>{char}</Box>
                                                )}
                                              </Fragment>
                                            ))
                                          )}
                                        </Fragment>
                                      ))
                                    ) : (
                                      <Box
                                        sx={{
                                          lineHeight: '1.2',
                                          fontSize: '1.05rem !important',
                                          whiteSpace: 'nowrap',
                                          paddingBottom: item.type == 'MC' ? '2px' : '0px',
                                          color: '#616161',
                                          caretColor: '#616161',
                                          ' .sa_input': {
                                            zoom: '.5',
                                            border: 'none',
                                          },
                                        }}
                                        dangerouslySetInnerHTML={{ __html: result.passage }}
                                      ></Box>
                                    )}
                                    {item.type == 'MC' ? (
                                      <Box
                                        sx={{
                                          lineHeight: 'calc(1rem + 3px)',
                                          wordBreak: 'break-all',
                                          whiteSpace: 'normal',
                                          color: '#757575',
                                          caretColor: '#757575',
                                        }}
                                      >
                                        <Box
                                          component={'span'}
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'start',
                                            listStyle: 'none',
                                            counterReset: 'spancounter',
                                            width: '100%',
                                            textAlign: 'center',
                                          }}
                                        >
                                          {result.choice?.map((item: any, idx: number) => {
                                            return (
                                              <Box
                                                component={'span'}
                                                sx={{
                                                  textAlign: 'left',
                                                  whiteSpace: 'normal',
                                                  counterIncrement: 'spancounter',
                                                  color:
                                                    idx == result.rightanswerIndex
                                                      ? '#1776d1'
                                                      : idx == result.selectedIndex
                                                      ? '#ff0000'
                                                      : '#757575',
                                                  '&::before': {
                                                    content: 'counter(spancounter)". "',
                                                    fontWeight: 'normal',
                                                    whiteSpace: 'normal',
                                                    wordBreak: 'break-all',
                                                  },
                                                }}
                                                key={idx}
                                                dangerouslySetInnerHTML={{ __html: item }}
                                              ></Box>
                                            );
                                          })}
                                        </Box>
                                      </Box>
                                    ) : typeof item.type == 'string' && ['SA', 'SC'].includes(item.type) ? (
                                      <Box
                                        sx={{
                                          lineHeight: 'calc(1rem + 3px)',
                                          wordBreak: 'break-all',
                                          whiteSpace: 'normal',
                                          color: item.type == 'SA' && (item as any).right ? '#1776d1' : '#757575',
                                          caretColor: '#757575',
                                          ' .sa_input': {
                                            zoom: '.5',
                                            border: 'none',
                                          },
                                        }}
                                        dangerouslySetInnerHTML={{ __html: result.wrongHtml }}
                                      ></Box>
                                    ) : null}
                                  </CustomTableCell>
                                </CustomTableRow>
                              );
                            })
                          ) : null
                        ) : recordData && recordData.mod[detailRecordTab] ? (
                          recordData &&
                          recordData.mod[detailRecordTab].resultsheet?.map((item, idx1) => {
                            const result: any = item;
                            return (
                              <CustomTableRow key={`body_row_${idx1}`} sx={{ minHeight: '6.4vh', height: 'auto' }}>
                                <TableCell
                                  sx={{ width: '8.3333333333% !important', borderBottom: 'unset' }}
                                  component='td'
                                >
                                  <ListNumberBox
                                    className='icon-box'
                                    sx={{ position: 'absolute', top: '0.75rem', height: 'auto' }}
                                  >
                                    <StyledNumber>{idx1 + 1}</StyledNumber>
                                  </ListNumberBox>
                                </TableCell>
                                <TableCell
                                  sx={{ width: '8.3333333333% !important', borderBottom: 'unset' }}
                                  component='td'
                                >
                                  <ListMarkingBox
                                    className='icon-box'
                                    sx={{ position: 'absolute', top: '0.75rem', height: 'auto' }}
                                  >
                                    {grammarRenderMarking(result.right, idx1)}
                                  </ListMarkingBox>
                                </TableCell>
                                <CustomTableCell component='td' sx={{ padding: '0.625rem 0 !important' }}>
                                  <div
                                    style={{
                                      lineHeight: 'calc(1rem + 3px)',
                                      whiteSpace: 'normal',
                                      wordBreak: 'break-all',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: result.question }}
                                  ></div>
                                  {item.type == 'CAB' ? (
                                    result.passage.split('<br />').map((sen: any, sen_key: number) => (
                                      <Fragment key={`passage-${sen_key}`}>
                                        {sen_key !== 0 && <br key={`passage-br-${sen_key}`} />}
                                        {sen.charAt(0) === '<' ? (
                                          <span
                                            style={{ color: '#757575', caretColor: '#757575' }}
                                            dangerouslySetInnerHTML={{ __html: sen }}
                                          ></span>
                                        ) : (
                                          Array.from(sen).map((char: any, char_key: number) => (
                                            <Fragment key={`passage-span-${sen_key}-${char_key}`}>
                                              {char === 'ⓐ' ? (
                                                <Box
                                                  component={'span'}
                                                  sx={{
                                                    color:
                                                      result.rightanswer == result.choose_a
                                                        ? '#1776d1'
                                                        : result.selectedWor == result.choose_a
                                                        ? '#FF0000'
                                                        : '#eee',
                                                  }}
                                                >
                                                  {result.choose_a}
                                                </Box>
                                              ) : char === 'ⓑ' ? (
                                                <Box
                                                  component={'span'}
                                                  sx={{
                                                    color:
                                                      result.rightanswer == result.choose_b
                                                        ? '#1776d1'
                                                        : result.selectedWor == result.choose_b
                                                        ? '#FF0000'
                                                        : '#eee',
                                                  }}
                                                >
                                                  {result.choose_b}
                                                </Box>
                                              ) : char === '/' ? (
                                                <Box component={'span'} sx={{ color: '#eee', caretColor: '#eee' }}>
                                                  {' / '}
                                                </Box>
                                              ) : (
                                                <Box component={'span'}>{char}</Box>
                                              )}
                                            </Fragment>
                                          ))
                                        )}
                                      </Fragment>
                                    ))
                                  ) : (
                                    <Box
                                      sx={{
                                        lineHeight: '1.2',
                                        fontSize: '1.05rem !important',
                                        whiteSpace: 'nowrap',
                                        paddingBottom: item.type == 'MC' ? '2px' : '0px',
                                        color: '#616161',
                                        caretColor: '#616161',
                                        ' .sa_input': {
                                          zoom: '.5',
                                          border: 'none',
                                        },
                                      }}
                                      dangerouslySetInnerHTML={{ __html: result.passage }}
                                    ></Box>
                                  )}
                                  {item.type == 'MC' ? (
                                    <Box
                                      sx={{
                                        lineHeight: 'calc(1rem + 3px)',
                                        wordBreak: 'break-all',
                                        whiteSpace: 'normal',
                                        color: '#757575',
                                        caretColor: '#757575',
                                      }}
                                    >
                                      <Box
                                        component={'span'}
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'start',
                                          listStyle: 'none',
                                          counterReset: 'spancounter',
                                          width: '100%',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {result.choice?.map((item: any, idx: number) => {
                                          return (
                                            <Box
                                              component={'span'}
                                              sx={{
                                                textAlign: 'left',
                                                whiteSpace: 'normal',
                                                counterIncrement: 'spancounter',
                                                color:
                                                  idx == result.rightanswerIndex
                                                    ? '#1776d1'
                                                    : idx == result.selectedIndex
                                                    ? '#ff0000'
                                                    : '#757575',
                                                '&::before': {
                                                  content: 'counter(spancounter)". "',
                                                  fontWeight: 'normal',
                                                  whiteSpace: 'normal',
                                                  wordBreak: 'break-all',
                                                },
                                              }}
                                              key={idx}
                                              dangerouslySetInnerHTML={{ __html: item }}
                                            ></Box>
                                          );
                                        })}
                                      </Box>
                                    </Box>
                                  ) : typeof item.type == 'string' && ['SA', 'SC'].includes(item.type) ? (
                                    <Box
                                      component={'span'}
                                      sx={{
                                        lineHeight: 'calc(1rem + 3px)',
                                        wordBreak: 'break-all',
                                        whiteSpace: 'normal',
                                        color: item.type == 'SA' && (item as any).right ? '#1776d1' : '#757575',
                                        caretColor: '#757575',
                                        ' .sa_input': {
                                          zoom: '.5',
                                          border: 'none',
                                        },
                                      }}
                                      dangerouslySetInnerHTML={{ __html: result.wrongHtml }}
                                    ></Box>
                                  ) : null}
                                </CustomTableCell>
                              </CustomTableRow>
                            );
                          })
                        ) : null
                      ) : recordData && recordData.mod[detailRecordTab] ? (
                        recordData.mod[detailRecordTab].resultsheet?.map((item, idx1) => {
                          return (
                            <Fragment key={`body_row_wrap_${idx1}`}>
                              <CustomTableRow key={`body_row_${idx1}`} sx={{ minHeight: '35px' }}>
                                <TableCell sx={{ width: '5% !important', borderBottom: 'unset' }} component='td'>
                                  <ListNumberBox className='icon-box'>
                                    <StyledNumber>{idx1 + 1}</StyledNumber>
                                  </ListNumberBox>
                                </TableCell>
                                <TableCell sx={{ width: '5% !important', borderBottom: 'unset' }} component='td'>
                                  <ListMarkingBox className='icon-box'>
                                    {renderMarking(recordData.mod[detailRecordTab].module, recordData.mode, item, idx1)}
                                  </ListMarkingBox>
                                </TableCell>
                                <CustomTableCell component='td'>
                                  <Box sx={{ ...d_flex_space_between }}>
                                    {(function () {
                                      if (recordData.mod[detailRecordTab].module == 1) {
                                        return (
                                          <Box className='word-wrap'>
                                            <Box sx={{ lineHeight: '1' }}>{item.question}</Box>
                                            <Box sx={{ lineHeight: '1' }}>
                                              {item.rightanswer == item.answer ? (
                                                <Box component={'span'} className='c_blue'>
                                                  {item.rightanswer}
                                                </Box>
                                              ) : (
                                                <Box component={'span'} className='c_red'>
                                                  {item.rightanswer}
                                                </Box>
                                              )}
                                            </Box>
                                          </Box>
                                        );
                                      } else if (recordData.mod[detailRecordTab].module == 2) {
                                        return (
                                          <Box className='word-wrap'>
                                            <Box sx={{ lineHeight: '1' }}>{item.question}</Box>
                                            <Box sx={{ lineHeight: '1' }}>
                                              {item.rightanswer == item.answer ? (
                                                <Box component={'span'} className='c_blue'>
                                                  {item.rightanswer}
                                                </Box>
                                              ) : (
                                                <Box component={'span'} className='c_red'>
                                                  {item.rightanswer}
                                                </Box>
                                              )}
                                            </Box>
                                          </Box>
                                        );
                                      } else if (recordData.mod[detailRecordTab].module == 3) {
                                        return (
                                          <Box className='word-wrap'>
                                            <Box sx={{ lineHeight: '1' }}>{item.question}</Box>
                                            <Box sx={{ lineHeight: '1' }}>
                                              {item.choices
                                                ? item.choices.map((item2, idx2) => {
                                                    if (idx2 + 1 == item.rightanswer) {
                                                      return (
                                                        <Box
                                                          key={`detail_result_${idx1}_${idx2}`}
                                                          component={'span'}
                                                          className='c_blue'
                                                        >
                                                          {item.choices ? `${idx2 + 1}. ${item.choices[idx2]} ` : null}
                                                        </Box>
                                                      );
                                                    } else if (idx2 + 1 == item.answer) {
                                                      return (
                                                        <Box
                                                          key={`detail_result_${idx1}_${idx2}`}
                                                          component={'span'}
                                                          className='c_red'
                                                        >
                                                          {item.choices ? `${idx2 + 1}. ${item.choices[idx2]} ` : null}
                                                        </Box>
                                                      );
                                                    } else {
                                                      return (
                                                        <Box key={`detail_result_${idx1}_${idx2}`} component={'span'}>
                                                          {item.choices ? `${idx2 + 1}. ${item.choices[idx2]} ` : null}
                                                        </Box>
                                                      );
                                                    }
                                                  })
                                                : null}
                                            </Box>
                                          </Box>
                                        );
                                      } else if ([4, 5].includes(recordData.mod[detailRecordTab].module)) {
                                        return (
                                          <Box className='word-wrap'>
                                            <Box sx={{ lineHeight: '1' }}>{item.question}</Box>

                                            {item.rightanswer == item.answer ? (
                                              <Box sx={{ lineHeight: '1' }}>
                                                <Box component={'span'} className='c_blue'>
                                                  {item.rightanswer}
                                                </Box>
                                              </Box>
                                            ) : (
                                              <Box
                                                sx={{ lineHeight: '1' }}
                                                dangerouslySetInnerHTML={{
                                                  __html: mergeAnswer(
                                                    item.answer as string,
                                                    item.rightanswer as string,
                                                  ),
                                                }}
                                              ></Box>
                                            )}
                                          </Box>
                                        );
                                      } else if (recordData.mod[detailRecordTab].module == 6) {
                                        return (
                                          <>
                                            <Box className='word-wrap'>
                                              <Box sx={{ lineHeight: '1' }}>
                                                {item.question.replace('{{', '').replace('}}', '')}
                                              </Box>
                                              {item.type == 'sentence' ? (
                                                <Box
                                                  sx={{ lineHeight: '1' }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: getScore(item.answer as string, item.rightanswer as string)
                                                      .html,
                                                  }}
                                                />
                                              ) : (
                                                <Box sx={{ lineHeight: '1' }}>
                                                  {getScore(item.answer as string, item.rightanswer as string).score >=
                                                  70 ? (
                                                    <Box component={'span'} className='c_blue'>
                                                      {item.rightanswer}
                                                    </Box>
                                                  ) : getScore(item.answer as string, item.rightanswer as string)
                                                      .score > 0 ? (
                                                    <Box component={'span'} className='c_red'>
                                                      {item.rightanswer}
                                                    </Box>
                                                  ) : (
                                                    <Box component={'span'} className='c_red'>
                                                      {item.rightanswer}
                                                    </Box>
                                                  )}
                                                </Box>
                                              )}
                                            </Box>
                                            <Box sx={{ minHeight: '35px' }}></Box>
                                            {/* {isSpeakMode ? (
                                              <CustomBookmarkButton
                                                disableRipple
                                                onClick={() =>
                                                  item.sentence_id && handleSpeakContentBookmarkClick(item.sentence_id)
                                                }
                                                bookmark={
                                                  note_contents.some(word => word.spell === item.rightanswer) ? 1 : 0
                                                }
                                              >
                                                <FiStar />
                                              </CustomBookmarkButton>
                                            ) : null} */}
                                          </>
                                        );
                                      } else if (recordData.mod[detailRecordTab].module == 7) {
                                        return (
                                          <>
                                            <Box className='word-wrap'>
                                              <Box sx={{ lineHeight: '1' }}>{item.question}</Box>
                                              <Box sx={{ lineHeight: '1' }}>
                                                {item.rightanswer == item.answer ? (
                                                  <Box component={'span'} className='c_blue'>
                                                    {item.rightanswer}
                                                  </Box>
                                                ) : getScore(item.answer as string, item.rightanswer as string).score >
                                                  0 ? (
                                                  <Box component={'span'} className='c_orange'>
                                                    {item.rightanswer}
                                                  </Box>
                                                ) : (
                                                  <Box component={'span'} className='c_red'>
                                                    {item.rightanswer}
                                                  </Box>
                                                )}
                                              </Box>
                                            </Box>
                                            <Box sx={{ minHeight: '35px' }}></Box>
                                            {/* <CustomBookmarkButton
                                              disableRipple
                                              onClick={() =>
                                                item.sentence_id && handleSpeakContentBookmarkClick(item.sentence_id)
                                              }
                                              bookmark={
                                                note_contents.some(word => word.spell === item.rightanswer) ? 1 : 0
                                              }
                                            >
                                              <FiStar />
                                            </CustomBookmarkButton> */}
                                          </>
                                        );
                                      } else if (recordData.mod[detailRecordTab].module == 8) {
                                        const blank = '  '.repeat(8);
                                        const example = item.question.replace(
                                          /{{(.*?)}}/g,
                                          `<span style="color: #F7F7F7;background-color: #F7F7F7;border-radius: 8px;">${blank}</span>`,
                                        );

                                        const selectedSpeakContent = recordData.contents.find(
                                          content => content.sentence_id == item.sentence_id,
                                        );

                                        return (
                                          <>
                                            <Box className='word-wrap'>
                                              <Box
                                                sx={{ lineHeight: '1', marginBottom: '0.2rem' }}
                                                dangerouslySetInnerHTML={{
                                                  __html: example ? example : '',
                                                }}
                                              ></Box>
                                              <Box sx={{ lineHeight: '1' }}>
                                                {item.choices
                                                  ? item.choices.map((item2, idx2) => {
                                                      if (idx2 + 1 == item.rightanswer) {
                                                        return (
                                                          <Box
                                                            key={`detail_result_${idx1}_${idx2}`}
                                                            component={'span'}
                                                            className='c_blue'
                                                          >
                                                            {item.choices
                                                              ? `${idx2 + 1}. ${item.choices[idx2]} `
                                                              : null}
                                                          </Box>
                                                        );
                                                      } else if (idx2 + 1 == item.answer) {
                                                        return (
                                                          <Box
                                                            key={`detail_result_${idx1}_${idx2}`}
                                                            component={'span'}
                                                            className='c_red'
                                                          >
                                                            {item.choices
                                                              ? `${idx2 + 1}. ${item.choices[idx2]} `
                                                              : null}
                                                          </Box>
                                                        );
                                                      } else {
                                                        return (
                                                          <Box
                                                            key={`detail_result_${idx1}_${idx2}`}
                                                            component={'span'}
                                                            sx={{ fontWeight: '300' }}
                                                          >
                                                            {item.choices
                                                              ? `${idx2 + 1}. ${item.choices[idx2]} `
                                                              : null}
                                                          </Box>
                                                        );
                                                      }
                                                    })
                                                  : null}
                                              </Box>
                                            </Box>
                                            <Box sx={{ minHeight: '35px' }}></Box>
                                            {/* <CustomBookmarkButton
                                              disableRipple
                                              onClick={() =>
                                                item.sentence_id && handleSpeakContentBookmarkClick(item.sentence_id)
                                              }
                                              bookmark={
                                                selectedSpeakContent &&
                                                note_contents.some(word => word.spell === selectedSpeakContent.sentence)
                                                  ? 1
                                                  : 0
                                              }
                                            >
                                              <FiStar />
                                            </CustomBookmarkButton> */}
                                          </>
                                        );
                                      }
                                    })()}
                                  </Box>
                                </CustomTableCell>
                                {recordData.mod[detailRecordTab].module == 6 && !isTemp ? (
                                  <>
                                    {!isSpeakMode ? (
                                      <TableCell sx={{ width: '5% !important', textAlign: 'right' }} component='td'>
                                        <CustomWordDetailButton disableRipple onClick={() => toggleWordDetail2(item)}>
                                          <IoMdInformationCircle />
                                        </CustomWordDetailButton>
                                      </TableCell>
                                    ) : null}
                                    <TableCell sx={{ width: '5% !important', textAlign: 'center' }} component='td'>
                                      {SpeakingAudioPlayer(item)}
                                    </TableCell>
                                  </>
                                ) : !isSpeakMode ? (
                                  <TableCell sx={{ width: '5% !important', textAlign: 'center' }} component='td'>
                                    <CustomWordDetailButton disableRipple onClick={() => toggleWordDetail2(item)}>
                                      <IoMdInformationCircle />
                                    </CustomWordDetailButton>
                                  </TableCell>
                                ) : null}
                              </CustomTableRow>
                            </Fragment>
                          );
                        })
                      ) : (
                        <CustomTableRow>
                          <CustomTableCell
                            width={'100%'}
                            sx={{ textAlign: 'center', color: '#c0c0c0', fontWeight: '400' }}
                            colSpan={3}
                          >
                            학습 문제가 없습니다.
                          </CustomTableCell>
                        </CustomTableRow>
                      )}
                    </CustomTableBody>
                  </CustomTable>
                </StyledDetailListBody>
              </StyledDetailListBox>
            ) : (
              <StyledDetailListBox>
                <StyledDetailListHeader>
                  <StyledTabButton selected={1} disableRipple sx={{ cursor: 'default' }}>
                    오답 문제
                  </StyledTabButton>
                </StyledDetailListHeader>
                <StyledDetailListBody
                  sx={{
                    height:
                      wrongContents && wrongContents.length > 0 && !GRAMMAR_MODES.includes(mode)
                        ? 'calc(100% - 14vh)'
                        : 'calc(100% - 7vh)',
                  }}
                >
                  <CustomTable id='data_table'>
                    <CustomTableBody>
                      {wrongContents && wrongContents.length > 0 ? (
                        GRAMMAR_MODES.includes(mode) ? (
                          wrongContents.map((item, idx1) => {
                            const result: any = item;
                            return (
                              <Fragment key={`body_row_wrap_${idx1}`}>
                                <CustomTableRow key={`body_row_${idx1}`}>
                                  <TableCell
                                    sx={{ width: '8.3333333333% !important', borderBottom: 'unset' }}
                                    component='td'
                                  >
                                    <ListNumberBox
                                      className='icon-box'
                                      sx={{ position: 'absolute', top: '0.75rem', height: 'auto' }}
                                    >
                                      <StyledNumber>{idx1 + 1}</StyledNumber>
                                    </ListNumberBox>
                                  </TableCell>
                                  <CustomTableCell component='td' sx={{ padding: '0.625rem 0 !important' }}>
                                    <div
                                      style={{
                                        lineHeight: 'calc(1rem + 3px)',
                                        whiteSpace: 'normal',
                                        wordBreak: 'break-all',
                                      }}
                                      dangerouslySetInnerHTML={{ __html: result.question }}
                                    ></div>
                                    {item.type == 'CAB' ? (
                                      result.passage.split('<br />').map((sen: any, sen_key: number) => (
                                        <Fragment key={`passage-${sen_key}`}>
                                          {sen_key !== 0 && <br key={`passage-br-${sen_key}`} />}
                                          {sen.charAt(0) === '<' ? (
                                            <span
                                              style={{ color: '#757575', caretColor: '#757575' }}
                                              dangerouslySetInnerHTML={{ __html: sen }}
                                            ></span>
                                          ) : (
                                            Array.from(sen).map((char: any, char_key: number) => (
                                              <Fragment key={`passage-span-${sen_key}-${char_key}`}>
                                                {char === 'ⓐ' ? (
                                                  <Box
                                                    component={'span'}
                                                    sx={{
                                                      color:
                                                        result.rightanswer == result.choose_a
                                                          ? '#1776d1'
                                                          : result.selectedWor == result.choose_a
                                                          ? '#FF0000'
                                                          : '#eee',
                                                    }}
                                                  >
                                                    {result.choose_a}
                                                  </Box>
                                                ) : char === 'ⓑ' ? (
                                                  <Box
                                                    component={'span'}
                                                    sx={{
                                                      color:
                                                        result.rightanswer == result.choose_b
                                                          ? '#1776d1'
                                                          : result.selectedWor == result.choose_b
                                                          ? '#FF0000'
                                                          : '#eee',
                                                    }}
                                                  >
                                                    {result.choose_b}
                                                  </Box>
                                                ) : char === '/' ? (
                                                  <Box component={'span'} sx={{ color: '#eee', caretColor: '#eee' }}>
                                                    {' / '}
                                                  </Box>
                                                ) : (
                                                  <Box component={'span'}>{char}</Box>
                                                )}
                                              </Fragment>
                                            ))
                                          )}
                                        </Fragment>
                                      ))
                                    ) : (
                                      <Box
                                        sx={{
                                          lineHeight: '1.2',
                                          fontSize: '1.05rem !important',
                                          whiteSpace: 'nowrap',
                                          paddingBottom: item.type == 'MC' ? '2px' : '0px',
                                          color: '#616161',
                                          caretColor: '#616161',
                                          ' .sa_input': {
                                            zoom: '.5',
                                            border: 'none',
                                          },
                                        }}
                                        dangerouslySetInnerHTML={{ __html: result.passage }}
                                      ></Box>
                                    )}
                                    {item.type == 'MC' ? (
                                      <Box
                                        sx={{
                                          lineHeight: 'calc(1rem + 3px)',
                                          wordBreak: 'break-all',
                                          whiteSpace: 'normal',
                                          color: '#757575',
                                          caretColor: '#757575',
                                        }}
                                      >
                                        <Box
                                          component={'span'}
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'start',
                                            listStyle: 'none',
                                            counterReset: 'spancounter',
                                            width: '100%',
                                            textAlign: 'center',
                                          }}
                                        >
                                          {result.choice?.map((item: any, idx: number) => {
                                            return (
                                              <Box
                                                component={'span'}
                                                sx={{
                                                  textAlign: 'left',
                                                  whiteSpace: 'normal',
                                                  counterIncrement: 'spancounter',
                                                  color:
                                                    idx == result.rightanswerIndex
                                                      ? '#1776d1'
                                                      : idx == result.selectedIndex
                                                      ? '#ff0000'
                                                      : '#757575',
                                                  '&::before': {
                                                    content: 'counter(spancounter)". "',
                                                    fontWeight: 'normal',
                                                    whiteSpace: 'normal',
                                                    wordBreak: 'break-all',
                                                  },
                                                }}
                                                key={idx}
                                                dangerouslySetInnerHTML={{ __html: item }}
                                              ></Box>
                                            );
                                          })}
                                        </Box>
                                      </Box>
                                    ) : typeof item.type == 'string' && ['SA', 'SC'].includes(item.type) ? (
                                      <Box
                                        sx={{
                                          lineHeight: 'calc(1rem + 3px)',
                                          wordBreak: 'break-all',
                                          whiteSpace: 'normal',
                                          color: item.type == 'SA' && (item as any).right ? '#1776d1' : '#757575',
                                          caretColor: '#757575',
                                          ' .sa_input': {
                                            zoom: '.5',
                                            border: 'none',
                                          },
                                        }}
                                        dangerouslySetInnerHTML={{ __html: result.wrongHtml }}
                                      ></Box>
                                    ) : null}
                                  </CustomTableCell>
                                </CustomTableRow>
                              </Fragment>
                            );
                          })
                        ) : (
                          wrongContents.map((item, idx1) => {
                            return (
                              <Fragment key={`body_row_wrap_${idx1}`}>
                                <CustomTableRow key={`body_row_${idx1}`}>
                                  <TableCell sx={{ width: '5% !important', borderBottom: 'unset' }} component='td'>
                                    <ListNumberBox className='icon-box'>
                                      <StyledNumber>{idx1 + 1}</StyledNumber>
                                    </ListNumberBox>
                                  </TableCell>
                                  <CustomTableCell component='td'>
                                    <Box sx={{ ...d_flex_space_between }}>
                                      <Box className='word-wrap'>
                                        <Box sx={{ lineHeight: '1' }}>{item.mean}</Box>
                                        <Box sx={{ lineHeight: '1', color: theme.palette.purple.main }}>
                                          {item.spell}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </CustomTableCell>
                                  {!isSpeakMode ? (
                                    <>
                                      <TableCell sx={{ width: '5% !important', textAlign: 'right' }} component='td'>
                                        <CustomWordDetailButton disableRipple onClick={() => toggleWordDetail(item)}>
                                          <IoMdInformationCircle />
                                        </CustomWordDetailButton>
                                      </TableCell>
                                      <TableCell sx={{ width: '5% !important', textAlign: 'center' }} component='td'>
                                        <CustomBookmarkButton
                                          disableRipple
                                          onClick={() => toggleBookmark(item)}
                                          bookmark={note_contents.some(word => word.spell == item.spell) ? 1 : 0}
                                        >
                                          <FiStar />
                                        </CustomBookmarkButton>
                                      </TableCell>
                                    </>
                                  ) : null}
                                </CustomTableRow>
                              </Fragment>
                            );
                          })
                        )
                      ) : (
                        <CustomTableRow>
                          <CustomTableCell
                            width={'100%'}
                            sx={{ textAlign: 'center', color: '#c0c0c0', fontWeight: '400' }}
                            colSpan={3}
                          >
                            오답 문제가 없습니다.
                          </CustomTableCell>
                        </CustomTableRow>
                      )}
                    </CustomTableBody>
                  </CustomTable>
                </StyledDetailListBody>
                {wrongStudy.current !== undefined ? (
                  <StyledDetailListBottom>
                    <StyledWrongStudyButton onClick={() => onWrongStudyClicked()}>오답학습 시작</StyledWrongStudyButton>
                  </StyledDetailListBottom>
                ) : null}
              </StyledDetailListBox>
            )}
          </StyledMainWrap>
          {wordDetailcontent ? (
            <ModalWordDetail
              visible={visibleWordDetail}
              onClose={() => setVisibleWordDetail(false)}
              content={wordDetailcontent}
              show_bookmark={tab == 2 ? false : true}
            />
          ) : null}
          {pocketNoteContent ? (
            <ModalAddPocketNote
              visible={visibleAddPocketNote}
              onClose={() => {
                setVisibleAddPocketNote(false);
              }}
              content={[pocketNoteContent]}
            />
          ) : null}
          <ModalHiddenMissionContainer />
        </StyledReportWrap>
      )}
    </>
  );
}

export default ResultIndex;
