import {
  RadioGroup,
  FormControl,
  Radio,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  Button,
  FormControlLabelProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { student_grade_list } from '../../utils/user_tools';
import { d_flex_center } from '../../styles/common';

interface ModalLeveltestProps {
  visible: boolean;
  onClick: () => void;
  onChange: (value: ModalSelectContentsType[]) => void;
  onCancel?: () => void;
  onClose?: () => void;
}

interface LeveltestModalButtondProps {
  disabled: boolean;
}

interface LabelProps extends FormControlLabelProps {
  index: number | undefined;
}

// style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '28rem',
  height: 'auto',
};

const StyledLeveltestModalWrapper = styled(FormControl)({
  width: '100%',
  alignItems: 'center',
  backgroundColor: 'var(--white)',
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '0.5rem',
});

const StyledModalBody = styled(Box)(props => ({
  padding: '8px 16px',
  ...d_flex_center,
  width: '100%',
  flexDirection: 'column',
}));

const StyledModalTitle = styled(Box)({
  width: '98%',
  color: 'var(--cyan)',
  fontSize: '1.2rem',
  textAlign: 'center',
  borderBottom: '1px solid var(--gray-2)',
  padding: '8px 0 16px 0',
  '&.Mui-focused': {
    color: 'var(--cyan)',
  },
});

const StyledRadioGroup = styled(RadioGroup)(props => ({
  width: '100%',
  maxHeight: '30rem',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'block',
  padding: '16px 0 16px 0',
}));

const StyledFormControlLabel = styled(FormControlLabel)<LabelProps>(props => ({
  width: '100%',
  color: 'var(--gray-1)',
  height: '3.5rem',
  '&.MuiFormControlLabel-root': {
    textAlign: 'center',
    margin: '0',
    ...d_flex_center,
  },
  [`&.MuiFormControlLabel-root:nth-of-type(${props.index})`]: {
    color: 'var(--cyan)',
    backgroundColor: 'var(--light-purple)',
  },
  '& .MuiTypography-root': {
    fontSize: '1.2rem',
  },
}));

const StyledRadio = styled(Radio)(props => ({
  '&.MuiRadio-root': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
}));

const StyledModalUtil = styled(Box)<LeveltestModalButtondProps>(props => ({
  border: 'none',
  backgroundColor: props.disabled ? 'var(--gray-2)' : 'var(--cyan)',
  width: '100%',
  height: '3.5rem',
  ...d_flex_center,
  cursor: props.disabled ? 'default' : 'pointer',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
}));

const CheckButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.2rem',
  color: 'var(--white)',
}));

function ModalLeveltest(props: ModalLeveltestProps) {
  const [value, setValue] = useState<ModalSelectContentsType[]>([]);
  const [contents, setContents] = useState<ModalSelectContentsType[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [index, setIndex] = useState(0);
  const visible = props.visible;
  const changeValue = props.onChange;
  const offModal = props.onClick;
  const onClose = props.onClose;

  useEffect(() => {
    if (visible) {
      setContents([...student_grade_list]);
      setValue([]);
    } else {
      setContents([]);
      setValue([]);
      setIndex(0);
    }
  }, [visible]);

  useEffect(() => {
    if (value.length > 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [value]);

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;

    const find_item = contents.find(item => item.id == id);
    const find_index = contents.findIndex(item => item.id == id);
    if (find_item) {
      setValue([find_item]);
      setIndex(find_index > 0 ? find_index + 1 : find_index);
    }
  };

  const onClick = () => {
    if (buttonDisabled) {
      const tmp_value = [...value];
      changeValue(tmp_value);
      offModal();
    }
  };

  return (
    <Modal
      open={visible}
      onClose={onClose}
      sx={{
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Box sx={style}>
        <StyledLeveltestModalWrapper>
          <StyledModalBody>
            <StyledModalTitle>학년을 선택하세요!</StyledModalTitle>
            <StyledRadioGroup onChange={onChangeRadio}>
              {contents?.map((v, i) => (
                <StyledFormControlLabel
                  index={index}
                  key={v.id}
                  style={{ pointerEvents: 'none' }}
                  value={v.id}
                  label={v.name}
                  control={<StyledRadio style={{ pointerEvents: 'auto' }} disableRipple={true} />}
                  sx={{ backgroundColor: v.checked ? 'red' : 'white', span: { pointerEvents: 'auto' } }}
                />
              ))}
            </StyledRadioGroup>
          </StyledModalBody>
          <StyledModalUtil disabled={!buttonDisabled} onClick={onClick}>
            <CheckButton disabled={!buttonDisabled}>시작</CheckButton>
          </StyledModalUtil>
        </StyledLeveltestModalWrapper>
      </Box>
    </Modal>
  );
}

export default ModalLeveltest;
