import { useState, useEffect, useContext, useMemo, Fragment, useRef, useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { voiceState } from '../../recoil/common/voice';
import { deviceState } from '../../recoil/common/device';

import { styled, Box } from '@mui/material';

import { dir_column, d_flex_center } from '../../styles/common';
import getScore from '../../utils/get_score';

import WebkitSpeechRecognition from './WebkitSpeechRecognition';
import SpeechRecognition from './SpeechRecognition';
import AutoText from '../../components/text/AutoText';
import ModalEndModule from '../../components/modal/ModalEndModule';
import { makeGradeIcon, makeGradeKorLabel, makeGradeLabel } from '../../utils/tools';
import { settingState } from 'recoil/model/settings';

interface SpeakingProps {
  sendResultsheet: () => void;
  tag: string | null;
}

const StyledSpeakingWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  backgroundColor: 'white',
  position: 'relative',
  ...d_flex_center,
  ...dir_column,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledQuestionWrap = styled(Box)(props => ({
  width: '100%',
  height: '42%',
  ...d_flex_center,
  flexDirection: 'column',
}));

const StyledQuestionAutoText = styled(AutoText)<{ questiontype?: number; tag?: string | null; fixed?: boolean }>(
  props => {
    const check_words_style =
      props.questiontype == 0 && props.tag != null
        ? {
            '& > .c_blue, & > .c_red, & > .c_orange, & > .emphasis-word': {
              letterSpacing: '-2.5px',
            },

            '@media (max-width: 1263px)': {
              letterSpacing: '-1.25px',
              '& > .c_blue, & > .c_red, & > .c_orange, & > .emphasis-word': {
                letterSpacing: '-1.25px !important',
              },
            },
          }
        : {};

    return {
      width: '70%',
      color: props.questiontype == 0 && props.tag != null ? '#5f5f5f' : props.theme.palette.purple.main,
      fontWeight: '700',
      letterSpacing: props.questiontype == 0 && props.tag != null ? '-2.5px' : '-0.425px',
      ...check_words_style,
    };
  },
);

const StyledSpeechWrap = styled(Box)(props => ({
  width: '100%',
  height: '58%',
  backgroundColor: '#fcfaff',
  ...d_flex_center,
}));

function Speaking(props: SpeakingProps) {
  const sendResultsheet = props.sendResultsheet;
  const tag = props.tag;
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile } = deviceStateData;

  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { current_step, contents, current_module_settings } = learningStateData;

  const { example_type, type } = current_module_settings.speaking;
  const [questionType, setQuestionType] = useState<number>(-1);
  const questionTypeCount = useRef(0);

  const setVoiceStateData = useSetRecoilState<VoiceType>(voiceState);

  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [html, setHtml] = useState<string | null>(null);

  const settingStateData = useRecoilValue(settingState);
  const { check_type } = settingStateData;

  const contentRef = useRef(content);
  contentRef.current = content;
  const [initSpeakingQuestion, setInitSpeakinQuestion] = useState(false);

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });

  const nextCallback = () => {
    sendResultsheet();
  };

  const finishCallback = (resultsheet: ResultsheetType[]) => {
    const total_question_count = resultsheet.length;
    let total_score = 0;
    for (let i = 0; i < resultsheet.length; i++) {
      const answer = typeof resultsheet[i].answer == 'string' ? resultsheet[i].answer : '   ';
      const rightanswer = typeof resultsheet[i].rightanswer == 'string' ? resultsheet[i].rightanswer : '   ';
      const score = getScore(answer as string, rightanswer as string).score;
      if (resultsheet[i].type == 'sentence') {
        total_score += score;
      } else {
        if (score >= 70) {
          total_score += 100;
        } else if (score > 0) {
          total_score += 50;
        } else {
          total_score += 0;
        }
      }
    }

    const score = total_score / total_question_count;

    const label = makeGradeLabel(score);
    const icon = makeGradeIcon(score);
    const kor_label = makeGradeKorLabel(score);

    setEndModuleModal({ visible: true, icon, label, score, kor_label });
  };

  useEffect(() => {
    return () => {
      setVoiceStateData(() => ({
        element: undefined,
        playing: false,
        loading: false,
        error: false,
        ready: false,
        volume: 1,
      }));
    };
  }, []);

  useEffect(() => {
    setVoiceStateData(() => ({
      element: undefined,
      playing: false,
      loading: false,
      error: false,
      ready: false,
      volume: 1,
    }));
    setContent(contents[current_step]);
    setHtml(null);
    // setInitSpeakinQuestion(false);
  }, [current_step]);

  useEffect(() => {
    setInit();
  }, [content]);

  const setInit = () => {
    let question_type = 0;
    if (tag === null) {
      question_type = type;
      if (type == 2) {
        question_type = Math.floor(Math.random() * 2);

        if (questionType == question_type) questionTypeCount.current += 1;
        else questionTypeCount.current = 0;

        if (questionTypeCount.current > 2) {
          question_type = question_type === 0 ? 1 : 0;
          questionTypeCount.current = 1;
        } else {
          if (questionType != question_type) {
            questionTypeCount.current += 1;
          }
        }

        setQuestionType(question_type);
      } else {
        setQuestionType(question_type);
      }
    } else {
      question_type = example_type;
      if (example_type == 2) {
        question_type = Math.floor(Math.random() * 2);

        if (questionType == question_type) questionTypeCount.current += 1;
        else questionTypeCount.current = 0;

        if (questionTypeCount.current > 2) {
          question_type = question_type === 0 ? 1 : 0;
          questionTypeCount.current = 1;
        } else {
          if (questionType != question_type) {
            questionTypeCount.current += 1;
          }
        }

        setQuestionType(question_type);
      } else {
        setQuestionType(question_type);
      }
    }
    setTimeout(() => {
      setInitSpeakinQuestion(prev => !prev);
    }, 0);
  };

  const speakingQuestion = useMemo(() => {
    const content = contentRef.current;
    if (content && content['example']) {
      const match_array = content['example'].match(new RegExp(`\\b(${content['spell']})\\b`, 'gi'));

      if (match_array && match_array.length) {
        const regex = new RegExp(`\\b(${content['spell']})\\b`, 'gi');
        const return_sentence_eng = content['example'].replace(
          regex,
          `<span class="emphasis-word" style="color: #4620e9;letter-spacing:-2.5px;">${match_array[0]}</span>`,
        );

        return return_sentence_eng;
      } else {
        return content['example']
          .replace(/{{/g, `<span class="emphasis-word" style="color: #4620e9;letter-spacing:-2.5px;">`)
          .replace(/}}/g, '</span>');
      }
    } else {
      return content['spell'];
    }
  }, [initSpeakingQuestion]);

  return (
    <StyledSpeakingWrap>
      <StyledQuestionWrap>
        <StyledQuestionAutoText
          fixed={html ? true : false}
          minFontSize={questionType == 0 && tag !== null ? 4.5 : 5}
          maxFontSize={questionType == 0 && tag !== null ? 6.5 : 7}
          fontSizeUnit='vh'
          questiontype={questionType}
          tag={tag}
          key={`${current_step}_${questionType}`}
        >
          {check_type && html
            ? html
            : tag !== null
            ? questionType == 0
              ? speakingQuestion
              : content['spell']
            : questionType == 0
            ? content['spell']
            : content['mean']}
        </StyledQuestionAutoText>
        {questionType == 0 && tag != null ? (
          <StyledQuestionAutoText
            minFontSize={1.5}
            maxFontSize={3.25}
            fontSizeUnit='vh'
            key={current_step + 100}
            sx={{
              color: '#5f5f5f',
              fontFamily: 'Apple SD Gothic Neo',
              fontWeight: '400',
              position: 'relative',
              letterSpacing: '-0.425px',
              top: '1rem',
            }}
          >
            {content['translate'] ? content['translate'].replace(/{{/g, '').replace(/}}/g, '') : ''}
          </StyledQuestionAutoText>
        ) : null}
      </StyledQuestionWrap>
      <StyledSpeechWrap>
        {is_mobile ? (
          <SpeechRecognition setHtml={setHtml} finishCallback={finishCallback} questionType={questionType} tag={tag} />
        ) : (
          <WebkitSpeechRecognition
            setHtml={setHtml}
            finishCallback={finishCallback}
            questionType={questionType}
            tag={tag}
          />
        )}
      </StyledSpeechWrap>
      <ModalEndModule
        visible={endModuleModal.visible}
        label={endModuleModal.label}
        kor_label={endModuleModal.kor_label}
        icon={endModuleModal.icon}
        score={endModuleModal.score}
        nextCallback={nextCallback}
      />
    </StyledSpeakingWrap>
  );
}

export default Speaking;
