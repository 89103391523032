import * as React from 'react';
import { useContext, useCallback } from 'react';

import ModalDefault from './ModalDefault';
import Button from '../button/Button';

import { ModalContext } from '../../provider/ModalProvider';

import { ConfirmModalWrapper, StyledModalTitle, StyledModalUtil, StyledLine } from '../../styles/common';
import { styled, Box } from '@mui/material';

import { AiOutlineLeft } from 'react-icons/ai';
import { learningState } from '../../recoil/model/learning';
import { useSetRecoilState } from 'recoil';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';

const ModalConfirmButton = styled(Button)(({ theme }) => ({
  fontSize: '1.1rem',
  width: 'calc(50% - 0.5px)',
  height: '100%',
}));

function ModalConfirm() {
  const { modal_confirm } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const setLearningStateData = useSetRecoilState(learningState);
  const onCloseModal = () => {
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: false,
    }));
    modal_confirm.closeModalConfirm();
  };

  const onButtonClick = useCallback(
    (text: string) => {
      if (text == modal_confirm.data.right) {
        modal_confirm.data.cancle_callback ? modal_confirm.data.cancle_callback() : false;
      } else {
        modal_confirm.data.confirm_callback();
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
      modal_confirm.closeModalConfirm();
    },
    [modal_confirm],
  );

  React.useEffect(() => {
    if (modal_confirm.data.visible) {
      playEffectSound('confirm');
    }
  }, [modal_confirm.data.visible]);

  return (
    <>
      <ModalDefault
        visible={modal_confirm.data.visible}
        onClose={
          modal_confirm.data.block_background_close
            ? () => {
                return;
              }
            : onCloseModal
        }
      >
        <ConfirmModalWrapper>
          <StyledModalTitle
            sx={{ lineHeight: '2rem', fontFamily: 'Apple SD Gothic Neo' }}
            dangerouslySetInnerHTML={{ __html: modal_confirm.data.title }}
          ></StyledModalTitle>
          <StyledModalUtil sx={{ heihgt: '52px' }}>
            <ModalConfirmButton color='purple' onClick={() => onButtonClick(modal_confirm.data.left)}>
              {modal_confirm.data.left}
            </ModalConfirmButton>
            <StyledLine></StyledLine>
            <ModalConfirmButton color='gray_1' onClick={() => onButtonClick(modal_confirm.data.right)}>
              {modal_confirm.data.right}
            </ModalConfirmButton>
          </StyledModalUtil>
        </ConfirmModalWrapper>
      </ModalDefault>
    </>
  );
}

export default ModalConfirm;
