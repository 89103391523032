import { Box } from '@mui/material';

export const tooltip_index_first = {
  text: <Box sx={{ fontSize: '1rem', fontFamily: 'NanumSquare' }}>오래영어만의 스마트 보카를 매일 학습하세요.</Box>,
  direction: 'bottom' as const,
};

export const tooltip_index_second = {
  text: <Box sx={{ fontSize: '1rem', fontFamily: 'NanumSquare' }}>오래영어만의 실용 영어회화를 학습하세요.</Box>,
  direction: 'bottom' as const,
};

export const tooltip_index_third = {
  text: <Box sx={{ fontSize: '1rem', fontFamily: 'NanumSquare' }}>오래영어만의 초중고 내신 기출어휘를 학습하세요.</Box>,
  direction: 'bottom' as const,
};

export const tooltip_index_fourth = {
  text: <Box sx={{ fontSize: '1rem', fontFamily: 'NanumSquare' }}>오래영어만의 필수 영문법 핵심강의를 수강하세요.</Box>,
  direction: 'bottom' as const,
};
