import { FormControl, Button, RadioGroup, FormControlLabel, FormControlLabelProps, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect, useContext } from 'react';
import { useRecoilState } from 'recoil';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import Box, { BoxProps } from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { d_flex_center, d_flex_start } from '../../styles/common';
import { GiOpenFolder } from 'react-icons/gi';
import { ModalContext } from '../../provider/ModalProvider';
import { fetchGetApi, fetchPostApi } from '../../utils/api';
import { userState } from '../../recoil/model/user';

interface ModalAddPocketNoteProps {
  visible: boolean;
  onClose: () => void;
  content: ContentType[] | undefined;
}

interface AddPocketNoteButtondProps {
  disabled: boolean;
}

interface LabelProps extends FormControlLabelProps {
  index: number | undefined;
}

// style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30rem',
  height: 'auto',
};

const StyledWordlistModalWrapper = styled(FormControl)({
  width: '100%',
  alignItems: 'center',
  backgroundColor: 'var(--white)',
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '4px',
  overflow: 'hidden',
});

const StyledModalBody = styled(Box)(props => ({
  width: '100%',
  flexDirection: 'column',
  height: '55vh',
  overflowY: 'auto',
}));

const StyledModalHeader = styled(Box)(props => ({
  background: 'linear-gradient(#652ae9, #4620e9)',
  height: '4rem',
  width: '100%',
  ...d_flex_start,
}));

const StyledModalTitle = styled(Box)({
  width: '100%',
  color: 'white',
  fontSize: '1.12rem',
  paddingLeft: '1.5rem',
});

const StyledRadioGroup = styled(RadioGroup)(props => ({
  width: '100%',
  maxHeight: '30rem',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'block',
}));

const StyledRadio = styled(Radio)(props => ({
  '&.MuiRadio-root svg': {
    color: '#ffeb3b',
    width: '1.5rem',
    height: '1.5rem',
    margin: '0 0.5rem 0 1rem',
  },
  '& .MuiSvgIcon-root': {
    color: '#ffeb3b',
    width: '1.5rem',
    height: '1.5rem',
    margin: '0 0.5rem 0 1rem',
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)<LabelProps>(props => ({
  width: '100%',
  color: 'var(--black)',
  height: '4rem',
  borderBottom: `1px solid #edeeef`,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
  '&.MuiFormControlLabel-root': {
    textAlign: 'left',
    margin: '0',
    ...d_flex_center,
  },
  [`&.MuiFormControlLabel-root:nth-of-type(${props.index})`]: {
    color: props.theme.palette.purple.main,
    backgroundColor: props.theme.palette.light_purple_3.main,
  },
  '& .MuiTypography-root': {
    fontSize: '1.12rem',
    width: '100%',
  },
}));

const StyledModalUtil = styled(Box)<AddPocketNoteButtondProps>(props => ({
  border: 'none',
  backgroundColor: props.disabled ? 'var(--gray-2)' : props.theme.palette.green.main,
  width: '100%',
  height: 'calc(4rem - 1px)',
  ...d_flex_center,
  cursor: props.disabled ? 'default' : 'pointer',
}));

const CheckButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.12rem',
  color: 'var(--white) !important',
}));

function ModalAddPocketNote(props: ModalAddPocketNoteProps) {
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [pocketNoteStateData, setPocketNoteStateData] = useRecoilState<PocketNoteType>(pocketNoteState);
  const [pocketNoteFolders, setPocketNoteFolders] = useState<PocketNoteFolderType[]>([]);
  const [value, setValue] = useState<PocketNoteFolderType[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [index, setIndex] = useState(0);
  const { modal_alert } = useContext(ModalContext);
  const visible = props.visible;
  const onClose = props.onClose;
  const content = props.content;

  useEffect(() => {
    if (visible) {
      getFolders();
      setValue([]);
    } else {
      setValue([]);
      setIndex(0);
    }
  }, [visible]);

  useEffect(() => {
    if (value.length > 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [value]);

  const getFolders = async () => {
    const res = await fetchGetApi(`/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes`);
    if (res.result) {
      setPocketNoteFolders([...res.data]);
    }
  };

  const onClick = async () => {
    if (buttonDisabled && content) {
      onClose();
      modal_alert.openModalAlert('add_pocketnote');
      const result = await fetchPostApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/${value[0].id}/words`,
        { words: content },
      );
      if (result.result) {
        const res = await fetchGetApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/words`,
        );

        if (res.result) {
          setPocketNoteStateData(prevState => ({ note_contents: [...res.data.words] }));
        }
      }
    }
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(e.target.value);

    const find_item = pocketNoteFolders.find(item => item.id == id);
    const find_index = pocketNoteFolders.findIndex(item => item.id == id);
    if (find_item) {
      setValue([find_item]);
      setIndex(find_index + 1);
    }
  };

  return (
    <Modal
      open={visible}
      onClose={onClose}
      sx={{
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Box sx={style}>
        <StyledWordlistModalWrapper>
          <StyledModalHeader id='wordlist_header'>
            <StyledModalTitle>
              포켓노트 추가 - {content ? content[0].spell : null}
              {content && content.length > 1 ? ' 외 ' + (content.length - 1) + '개' : null}
            </StyledModalTitle>
          </StyledModalHeader>
          <StyledModalBody>
            <StyledRadioGroup onChange={onChangeRadio}>
              {pocketNoteFolders?.map((v, i) => (
                <StyledFormControlLabel
                  index={index}
                  key={v.id}
                  style={{ pointerEvents: 'none' }}
                  value={v.id}
                  label={v.name}
                  control={
                    <StyledRadio
                      checkedIcon={<GiOpenFolder />}
                      icon={<GiOpenFolder />}
                      style={{ pointerEvents: 'auto' }}
                      disableRipple={true}
                    />
                  }
                  sx={{ backgroundColor: v.checked ? 'red' : 'white', span: { pointerEvents: 'auto' } }}
                />
              ))}
            </StyledRadioGroup>
          </StyledModalBody>
          <StyledModalUtil disabled={!buttonDisabled} onClick={onClick}>
            <CheckButton disabled={!buttonDisabled}>추가</CheckButton>
          </StyledModalUtil>
        </StyledWordlistModalWrapper>
      </Box>
    </Modal>
  );
}

export default ModalAddPocketNote;
