import { Box, styled } from '@mui/material';

const Table = styled('table')({});

export function Use() {
  // const screenWidth = globalThis?.window?.innerWidth;
  return (
    <Box
      style={{ paddingRight: '1.25rem', fontSize: '0.875rem' }}
      sx={{
        '& > *': {
          fontFamily: 'Apple SD Gothic Neo',
          fontWeight: '300',
          lineHeight: '1.5',
        },
      }}
    >
      <p>
        <span style={{ fontWeight: '700' }}>제 1 장 총칙</span>
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 1 조 [목 적]</span>
      </p>

      <p>
        본 약관은 오래영어 (이하 “회사”라 합니다.) 가 운영하는 온라인 사이트 오래영어 (http://www.longedu.co.kr 이하
        “사이트”라 합니다.)와 오래영어 App을 통하여 제공하는 교육서비스 (이하 “서비스”라 합니다.)의 이용과 관련하여
        회사와 이용자 사이에 권리•의무 및 책임사항 등을 규정하는 것을 목적으로 합니다.
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>
          <br />
        </span>
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 2조 [용어의 정의]</span>
      </p>

      <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>

      <p>
        ① “회사”라 함은 “학습자료”와 관련된 경제활동을 영위하는 자로서 “사용자”에게 “학습자료” 및 제반 서비스를 제공하는
        자를 말합니다.
      </p>

      <p>
        ② “사용자”라 함은 이 약관에 따라 “회사”가 제공하는 “학습자료” 및 제반 서비스를 이용하는 학원, 개인 과외, 교습소,
        공부방 등의 “유료고객” 및 “체험고객”을 말합니다.
      </p>

      <p>
        ③ “유료고객”이라 함은 오래영어 App 에 접속하여, 본 약관에 동의함으로써 “회사”와 이용계약을 체결하고 아이디(ID)
        이용을 부여 받은 자로서 “회사”가 제공하는 정보와 서비스를 지속적으로 이용할 수
      </p>

      <p>있는자를 말합니다.</p>

      <p>
        ④ “체험고객”이라 함은 “유료고객”이 아니면서 “회사”가 제공하는 “학습자료” 및 제반 서비스를 임시적으로
        “무료체험”하는 자를 말합니다.
      </p>

      <p>
        ⑤ “무료체험”이라 함은 “유료고객”이 되기 전 가입 여부를 결정하기 위해 7일간 무료로 서비스를 이용해 보는 것을
        말합니다.
      </p>

      <p>⑥ “학습자료”라 함은 회사가 제작하여 오래영어 App 에 제공되는 모든 서비스 및 기타 관련정보를 말합니다.</p>

      <p>⑦ “아이디(ID)”라 함은 “회사”가 승인하는 문자와 숫자의 조합을 말합니다.</p>

      <p>
        ⑧ &quot;상품&quot;이라 함은 “회사”에서 지정하는 상거래를 목적으로 유료로 판매하는 학습자료와 교육정보서비스,
        전반적인 관리를 말합니다.
      </p>

      <p>⑨ “전자우편(Email)”이라 함은 인터넷을 통한 우편 혹은 전기적 매체를 이용한 우편을 말합니다.</p>

      <p>
        ⑩ “운영자(관리자)”라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 “회사”에서 선정한 사람 또는 회사를
        말합니다.
      </p>

      <p>
        ⑪ “계약기간”이라 함은 “유료고객”이 “회사”에, 혹은 “회사”가 “유료고객”에게 계약 해지를 통보하는 시기까지
        유효합니다.
      </p>

      <p>
        ⑫ “해지&quot;라 함은 &quot;유료고객&quot; 혹은 &quot;회사&quot;가 &quot;유료고객&quot;의 유료 서비스 이용 계약을
        종료시키는 것을 말합니다.
      </p>

      <p>
        ⑬ “오래영어 App”이라 함은 “회사”에서 제공하는 모든 상품이 담긴 학습자료를 이용하기 위해서 태블릿 기기와 노트북,
        데스크 탑 등에 설치하여 사용하는 것을 말합니다.
      </p>

      <p>
        ⑭ “사이트”라 함은 회사가 재화 ㆍ 용역 ㆍ 정보를 “사용자”에게 제공하기 위하여 기기 등 정보통신 설비를 이용하여
        재화·용역을 거래할 수 있도록 설정한 가상의 영업장 또는 “회사”가 운영하는 웹 사이트를
      </p>

      <p>말합니다.</p>

      <p>⑮ “서비스”라 함은 “회사”가 “유료고객”에게 제공하는 오래영어 서비스 일체로 모바일 및 웹페이지를 말합니다.</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 3 조 [회사정보 등의 제공]</span>
      </p>

      <p>1.“회사”는 “사용자”가 쉽게 알 수 있도록 다음 각 호의 사항을 사이트 초기 화면에 게시합니다.</p>

      <p>① 대표자의 성명 및 상호</p>

      <p>② 영업소 소재지 주소(사용자의 불만을 처리할 수 있는 곳의 주소를 포함)및 전자우편주소</p>

      <p>③ 전화번호</p>

      <p>④ 사업자등록번호</p>

      <p>⑤ 사이트의 이용약관</p>

      <p>⑥ 호스팅서비스를 제공하는 자의 상호</p>

      <p>⑦ 개인정보취급방침</p>

      <p>⑧ 개인정보관리책임자의 성명 및 전화번호 등의 연락처</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 4 조 [약관의 게시 등]</span>
      </p>

      <p>
        1.“회사”는 “사용자”에게 이 약관을 제시하여 동의를 받으며, 사이트의 초기화면에 이 약관을 게시합니다. 이 경우 제
        3조를 준용합니다.
      </p>

      <p>2.“회사”는 “사용자”가 언제든지 이 약관을 인쇄하거나, 다운로드 할 수 있는 조치를 취합니다.</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 5 조 [약관의 변경 등]</span>
      </p>

      <p>
        1.“회사”는 「콘텐츠산업진흥법」,「전자상거래 등에서의 소비자보호에 관한 법률」,「학원의 설립ㆍ운영 및 과외교습에
        관한 법률」,「약관의 규제에 관한 법률」, 문화체육관광부장관이 정하는 「콘텐츠
      </p>

      <p>사용자보호지침」, 기타 관계법령 또는 상관습에 위배하지 않는 범위에서 이 약관을 변경할 수 있습니다.</p>

      <p>
        2.“회사”가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 현행약관과 함께 사이트 초기화면에 그 적용일자
        7일(“사용자”에게 불리한 변경 또는 중대한 사항의 변경은 30일) 이전부터 적용일자 이후
      </p>

      <p>
        상당한 기간 동안 공지하고, 기존 “유료고객”에게는 변경될 약관, 적용일자 및 변경사유(중요내용에 대한 변경인 경우
        이에 대한 설명을 포함)를 본 약관 제6조의 방법을 통하여 통지합니다.
      </p>

      <p>
        3.“회사”가 약관을 변경할 경우에는 변경약관을 공지한 후 7일(“사용자”에게 불리한 변경 또는 중대한 사항의 변경은
        30일) 동안 변경약관의 적용에 대한 “사용자”의 동의 여부를 확인합니다.
      </p>

      <p>
        4.“회사”는 제3항의 기간 동안【“사용자”가 거절의 의사표시를 하지 않으면 동의한 것으로 간주 하겠다】는 내용을
        별도로 고지하였음에도 불구하고 “사용자”가 이 기간 내에 거절의사를 표시하지 않았을 경우
      </p>

      <p>변경약관에 동의한 것으로 간주할 수 있습니다.</p>

      <p>
        5.“사용자”가 변경약관의 적용을 거절한 경우 “회사”는 변경 전 약관에 따른 서비스 제공이 기술적, 영업적으로 가능한
        때에 변경 전 약관에 따라 서비스를 제공합니다. 다만, 변경 전 약관에 따라 서비스를
      </p>

      <p>제공하는 것이 기술적, 영업적으로 곤란한 경우 “회사”는 이용계약을 해지할 수 있습니다.</p>

      <p>
        ① “회사”는 “사용자”가 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 약관변경을 거절한 경우,
        사전통지 없이 계약을 해지하거나 또는 기간을 정하여 서비스 이용을 제한할 수 있습니다.
      </p>

      <p>
        ② 제 1항의 해지는 “회사”가 자신이 정한 통지방법에 따라 “사용자”에게 그 의사를 표시한 때에 효력이 발생합니다.
      </p>

      <p>③ “회사”의 해지 및 이용제한에 대하여 “사용자”는 “회사”가 정한 절차에 따라 이의신청을 할 수 있습니다.</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 6 조 [“유료고객”에 대한 통지]</span>
      </p>

      <p>
        1. 회사는 유료고객에게 알려야 할 사항이 발생하는 경우, 정식 가입 시 유료고객이 공개한 전자우편주소 또는 쪽지,
        팝업 창, 유 • 무선 등의 방법으로 통지할 수 있습니다.
      </p>

      <p>
        2. 회사는 “사용자” 전체에 대한 통지의 경우 웹 사이트에 팝업 및 어플리케이션에 알림을 띄움으로써 통지에 갈음할 수
        있습니다. 다만, 유료고객 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 전항과 동일한 방법으로 별도 통지할
        수 있습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 7 조 [약관의 해석]</span>
      </p>

      <p>
        이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「콘텐츠산업진흥법」, 「전자상거래 등에서의
        소비자보호에 관한 법률」, 「학원의 설립ㆍ운영 및 과외교습에 관한 법률」,「약관의 규제에 관한 법률」,
        문화체육관광부장관이 정하는 「콘텐츠 사용자보호지침」, 기타 관계법령 및 상관습에 따릅니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ fontWeight: '700' }}>제 2 장 유료고객 가입과 탈퇴</span>
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 8 조 [유료고객 가입]</span>
      </p>

      <p>
        1. 유료고객으로 가입하여 회사 서비스의 이용을 희망하는 자는 약관의 내용을 숙지한 후 동의함을 표시하고, 회사가
        제시하는 소정의 학습자 가입 양식에 관련사항을 기재하여 유료고객 가입을 신청하여야 합니다.
      </p>

      <p>2. 회사는 전항에 따라 유료고객이 신청양식에 기재하는 모든 유료고객 정보를 실제 데이터인 것으로 간주합니다.</p>

      <p>
        3. 실명이나 실제 정보를 입력하지 않은 유료고객은 법적인 보호를 받을 수 없으며 본 약관의 관련 규정에 따라 서비스
        사용에 제한을 받을 수 있습니다.
      </p>

      <p>
        4. “회사”는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있으며, 승낙 이후라도 취소할 수 있습니다.
      </p>

      <p>① 사용자의 귀책사유로 인하여 승인이 불가능한 경우</p>

      <p>② 실명을 사용하지 않은 경우</p>

      <p>③ 타인의 명의 또는 개인정보를 도용하는 경우</p>

      <p>④ 허위의 정보를 제공하는 경우</p>

      <p>⑤ 타인과 공유하여 이용한 경우</p>

      <p>⑥ 회사가 제시하는 유료고객 가입 신청양식에 관련 내용을 기재하지 않은 경우</p>

      <p>⑦ 이전에 회사 이용약관 또는 관계법령을 위반하여 자격이 상실되었던 경우</p>

      <p>⑧ 본 약관 제 16조 [유료고객의 의무]를 위반하는 경우</p>

      <p>⑨ 기타 본 약관 및 관계법령을 위반하는 경우</p>

      <p>
        ⑩ 유료고객의 신청양식에 기재되어 회사에 제공되는 개인정보 (ID, 비밀번호, 주소 등) 가 선량한 풍속 기타 사회질서에
        위배되거나 타인을 모욕하는 경우
      </p>

      <p>
        5. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
      </p>

      <p>
        6. “회사”가 본 조 제4항과 제5항에 따라 유료고객 가입신청의 승낙을 하지 아니하거나 유보한 경우에는 무료체험 신청
        시 공개한 전자우편주소 또는 쪽지, 팝업 창, 유 • 무선 등의 방법으로 즉시 “사용자”(신청자)에게 알립니다. 단,
        “회사”의 귀책사유 없이 “사용자”에게 알릴 수 없는 경우에는 예외로 합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 9 조 [유료고객 정보의 변경]</span>
      </p>

      <p>1. 유료고객의 상호명 등 개인정보 변경은 회사에게 고지하여야 하며 회사 담당자만이 변경 가능합니다.</p>

      <p>2. 유료고객이 전항의 변경사항을 고지하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 10 조 [“유료고객”의 “아이디” 및 “비밀번호”의 관리에 대한 의무]</span>
      </p>

      <p>
        1. 유료고객은 아이디와 비밀번호에 대한 관리책임이 있으며, 이를 타인에게 공개하여 제 3자가 이용하도록 하여서는
        아니 됩니다.
      </p>

      <p>
        2. 유료고객은 자신의 아이디 및 비밀번호가 유출되어 제 3자에 의해 사용되고 있음을 인지한 경우, 즉시 회사에 알려야
        합니다.
      </p>

      <p>
        3. ‘유료고객”의 아이디가 제 3자에 의해 사용되고 있을 경우 “회사”는 “유료고객”의 개인정보보호 및 기타 서비스
        부정이용행위 방지 등을 위하여 유료고객에게 비밀번호의 변경 등 필요한 조치를 요구할 수 있으며, “유료고객”은
        “회사”의 요구가 있는 즉시 “회사”의 요청에 성실히 응해야 합니다.
      </p>

      <p>
        4. 회사는 유료고객이 본 조 제 2항 및 제 3항에 따른 의무를 성실히 이행하지 않아 발생한 불이익에 대하여 책임지지
        않습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 11 조 [유료고객 해지(탈퇴) 및 자격 상실 등]</span>
      </p>

      <p>
        1. “유료고객”은 계약기간 도중 임의로 계약을 해지할 수 있으며 해지하고자 할 경우 사용자는 고객센터에 전화를
        하거나 담당자에게 유선 또는 문자로 해지 요청 후 해지 신청서를 해지 희망일 전까지 접수하여야 합니다. 단, 유선 상
        본사에 통보하였다 하더라도 해지신청서로 정상 접수된 건에 한해 효력이 발생합니다. 이 경우 “회사”는 지체 없이 해지
        처리하고 유료고객의 환불, 추가 납부 등의 확인 후 “유료고객”에게 해지 사실을 회신합니다. 이때 “유료고객”의 추가
        납부 혹은 태블릿 디바이스 반환 등의 처리가 “회사”의 요청에 반하게 되는 경우 해지처리가 지연 혹은 유보될 수
        있습니다.
      </p>

      <p>
        2. “회사”는 “유료고객”이 본 약관 또는 관계법령을 위반하는 경우, 서비스 이용을 제한하거나 유료고객 자격을
        상실시킬 수 있습니다.
      </p>

      <p>3. 해지로 인한 환불 금액은 사용자의 사용여부와 관계없이 제25조 [회사의 환불규정] 조항을 따릅니다.</p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ fontWeight: '700' }}>제 3장 서비스의 이용 계약</span>
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 12 조 [서비스의 이용]</span>
      </p>

      <p>
        1. 회사는 서비스의 원활한 제공을 위하여 유료고객과 최초 이용계약 체결 시, 회사 관련 모든 학습자료를 제공합니다.
      </p>

      <p>
        2. 회사가 제공하는 서비스는 인터넷 통신망을 통해 전송되며, 서비스 이용을 위한 인터넷 통신망의 설치 의무는
        유료고객에게 있습니다.
      </p>

      <p>
        3. “회사”는 “사용자”에게 오래영어 소프트웨어 기능 장애에 대한 기술적 지원을 제공합니다. 다만 오래영어 소프트웨어
        자체의 문제가 아닌, 사용자 컴퓨터의 낮은 사양, 사용자 컴퓨터의 하드웨어/소프트웨어 불안정, 태블릿 디바이스의
        불량, 인터넷 통신망 불안정, 외부 연동 서비스의 오류, 프린터의 기기 및 드라이버 오류 등 오래영어 소프트웨어
        외부에서 발생하는 문제는 “회사”의 기술 지원 범위에 해당하지 않습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 13 조 [권장기기사양]</span>
      </p>

      <p>1. “회사”가 제공하는 학습자료를 이용하는데 필요한 학습전용기기(태블릿 PC)의 사양은 아래와 같습니다.</p>

      <p>- OS : Android 6.0 이상, LCD : 10인치(1900X1200) 이상, 터치스크린, RAM : 2GB 이상, 2016년 이후 출시기기</p>

      <p>- CPU : ARM Cortex-A53 MP8 1.6 GHz 혹은 그 이상</p>

      <p>
        2. 학습자료를 이용하는데 필요한 학습전용기기(태블릿PC)의 사양은 회사에서 제시하는 권장사양정보에 따르며, 이는
        변경될 수 있습니다.
      </p>

      <p>
        3. “회사”에서 제공하는 태블릿PC 이 외의 기종이 1항의 권장 사양에 해당된다 하더라도 최적화되어 있지 않은 기종이
        있을 수 있으며 이에 대해 “회사”는 책임지지 않습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 14 조 [서비스 이용계약의 성립 및 결제방법 등]</span>
      </p>

      <p>
        1. 유료고객은 회사가 제공하는 다음 각 호 또는 이와 유사한 절차에 의하여 서비스 이용을 신청을 합니다. 회사는 계약
        체결 전, 다음 각 호의 사항에 관하여 유료고객이 정확하게 이해하고 실수 또는 착오 없이
      </p>

      <p>거래할 수 있도록 정보를 제공합니다.</p>

      <p>① 앱 내 모든 학습자료 확인</p>

      <p>② 문자 알림 서비스 등 관리서비스 안내</p>

      <p>③ 계약 진행</p>

      <p>④ 주문상품 및 결제금액 확인 (환불규정 안내)</p>

      <p>⑤ 계약서 최종 회신(결제수단 선택)</p>

      <p>⑥ 결제금액 재확인</p>

      <p>⑦ 결제완료</p>

      <p>
        2. 유료고객은 가상계좌, 카드 자동이체 등 회사에서 정하고 있는 방법으로 유료서비스의 결제가 가능합니다. 다만, 각
        결제수단마다 결제수단의 특성에 따른 일정한 제한이 있을 수 있습니다.
      </p>

      <p>
        3. 회사는 유료고객의 유료서비스 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나, 그 사유가 해소될
        때까지 승낙을 유보할 수 있습니다.
      </p>

      <p>① 계약 의사결정 이후 계약서 회신이 지나치게 늦는 경우</p>

      <p>② 기타 합리적인 이유로 회사가 필요하다고 인정되는 경우</p>

      <p>
        4. 회사는 유료고객이 본 조 위 조항의 절차에 따라 유료서비스 이용을 신청할 경우, 승낙의 의사표시로써 본 약관 제
        6조의 방법을 통하여 유료고객에게 통지하고, 승낙의 통지가 유료고객에게 도달한 시점에 계약이 성립한 것으로 봅니다.
        5. 회사의 승낙의 의사표시에는 유료고객의 이용신청에 대한 확인 및 서비스제공 가능 여부, 이용신청의 정정, 취소
        등에 관한 정보 등을 포함합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 15 조 [회사의 의무]</span>
      </p>

      <p>1. 회사는 법령과 본 약관이 정하는 권리의 행사와 의무의 이행을 신의에 쫓아 성실하게 하여야 합니다.</p>

      <p>
        2. 회사는 유료고객이 서비스를 이용하는 과정에서 유료고객 개인정보가 외부로 유출되지 않도록 보호하고 있습니다.
      </p>

      <p>3. 회사는 유료고객이 유료서비스 이용 및 그 대금 내역을 원할 경우 상세내역을 안내할 수 있습니다.</p>

      <p>
        4. 회사는 서비스 이용과 관련하여 유료고객으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체
        없이 처리합니다. 유료고객이 제기한 의견이나 불만사항에 대해서는 앱 내 문의사항을 활용하거나 문자, 메일 등을
        통하여 그 처리과정 및 결과를 전달합니다.
      </p>

      <p>5. “회사”는 “사용자”의 오래영어 사용권에 대한 지역 영업권을 보장하지 아니합니다.</p>

      <p>
        ① ‘지역 영업권’이라 함은 일정 지역에서의 “사용자”의 오래영어 독점 이용을 보장하여 해당 지역의 신규 가입자를
        제한하는 것을 말합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 16 조 [유료고객의 의무]</span>
      </p>

      <p>1. “사용자”는 정식가입 체결 시 가맹비와 이용료 지급 의무가 있습니다.</p>

      <p>① 가맹비는 일시에 지급해야 하며 분할 납부가 불가합니다.</p>

      <p>② 프로그램 사용에 대한 이용료는 매월 1일자에 선불로 지급해야 합니다</p>

      <p>
        ③ 매월 1일 사용료 산정 기준을 통해 기본 학생 수가 초과 될 경우, 초과 ID 과금 정책에 따라 월 이용료가 발생하며
        이는 퇴원처리와 무관합니다.
      </p>

      <p>
        ④ 3항의 ‘사용료 산정 기준’은 프로그램에서 학습하기 or 시험보기 or 진단평가 유형별 최소 1회 완료된 경우를
        기준으로 하며, 산정된 추가 월 이용료는 익월의 기본료에 합산되어 청구됩니다.
      </p>

      <p>2. “회사”의 명시적 동의가 없는 한 사용자의 타인에게 양도, 증여하거나 이를 담보로 제공할 수 없습니다.</p>

      <p>
        3. “사용자”는 상호명(간판 등)에 오래영어에 대한 광고성 문구나 이미지를 삽입해선 아니되며, 필요한 경우에는
        “회사”의 동의를 구해야 합니다.
      </p>

      <p>
        4. “회사”는 “유료고객”이 다음 각 호의 1에 해당하는 의무를 위반 하였을 경우 기간을 정하여 전부 또는 일부의 서비스
        이용을 중지하거나 상당기간의 최고 후 이용계약을 해지할 수 있습니다.
      </p>

      <p>
        ① “유료고객” 이용신청 또는 변경 시, 허위사실을 기재하거나, 자신 또는 다른 유료고객의 ID 및 개인정보를
        이용ㆍ공유하는 경우
      </p>

      <p>② “유료고객”이 “회사” 또는 제 3자의 권리나 저작권을 침해하는 경우</p>

      <p>
        ③ “유료고객”이 “회사”에서 제공하는 서비스를 이용하여 상품 또는 용역을 판매하는 영업활동 등의 상행위를 하는 경우
      </p>

      <p>④ “유료고객”이 다른 “사용자”의 서비스 이용을 방해하거나, “회사”의 운영진, 직원 또는 관계자를 사칭하는 경우</p>

      <p>
        ⑤ “유료고객”이 “회사”를 비방할 목적으로 부호ㆍ문자ㆍ음성ㆍ음향 및 영상 등의 정보를 게시하여 타인에게 유포시키는
        경우
      </p>

      <p>
        ⑥ “회사”의 서비스 운영을 고의로 방해 하거나 그리할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우
      </p>

      <p>⑦ 관계법령 및 기타 본 약관에서 규정한 사항을 위반한 경우</p>

      <p>⑧ “회사” 및 타인의 명예를 훼손하거나 모욕하는 경우</p>

      <p>
        ⑨ “유료고객”이 그 채무를 이행하지 아니하여 “회사”가 상당기간 그 이행을 요청하였음에도 이를 이행하지 않는 경우
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 17 조 [부정이용 금지 및 미납]</span>
      </p>

      <p>1. “회사”는 다음 각 호에 해당하는 경우를 부정 이용행위로 봅니다.</p>

      <p>① 동일한 ID로 계약을 진행하지 않은 타인(학원, 교습소, 공부방 등)과 서비스를 이용하는 경우</p>

      <p>② 자신ID를 타인이 이용하도록 하는 경우</p>

      <p>③ 자신의 ID와 서비스를 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는 행위</p>

      <p>④ 서비스 이용 중, 복제프로그램을 실행하는 경우</p>

      <p>⑤ “회사”의 콘텐츠를 “유료고객”의 문제은행 구축, 오래영어 외 프로그램을 통한 2차 저작물 제작에 활용하는 행위</p>

      <p>2. “회사”는 전항에 따른 부정 사용자가 발견되었을 경우, 아래와 같은 조치를 취할 수 있습니다.</p>

      <p>① 유선, 문자, 전자우편을 통하여 위반 내용의 통지 및 서비스 이용을 정지시키고 강제 해지시킵니다.</p>

      <p>
        ② 부정이용을 한 “사용자”는 “회사” 또는 제3자에게 부정이용으로 인해 발생한 손해에 대해 모두 배상해야 합니다. 또한
        “사용자”는 위 손해배상과 별도로 위약벌로 “회사”에게 아래의 항목에 대해 지급해야 합니다. 아래의 항목은 위약금이
        아닌 위약벌임을 명시합니다.
      </p>

      <p>가. 법정 분쟁으로 발생한 회사의 모든 비용일체</p>

      <p>나. 부정 이용된 콘텐츠의 저작 금액(문항당 10,000원)</p>

      <p>
        “사용자”는 위약금에 상당하는 금액을 지급하면서 본 조를 위반할 수 없으며, 또한 위약금의 지급은 부정이용 사용
        제한에 영향을 미치지 않습니다.
      </p>

      <p>
        3. “유료고객”은 “회사”로부터의 본 조 제2항의 조치에 이의가 있는 경우, “회사” 고객센터에 해당 사실에 대하여
        소명할 수 있으며, “유료고객”이 자신의 고의나 과실이 없었음을 입증한 경우 “회사”는 서비스 제공 정지 기간만큼
        이용기간을 연장합니다. 그에 따라 이용에 관한 별도의 조치를 받을 수 있습니다.
      </p>

      <p>
        4. “사용자”는 오래영어가 보유한 서비스를 외부로 무단 반출할 시에는 그에 따른 민형사상 책임을 “회사”가
        “사용자”에게 물을 수 있습니다.
      </p>

      <p>5. 부정이용 식별방법 및 차단</p>

      <p>
        ① “회사”는 “유료고객”의 서비스 이용 중에 수집ㆍ확인된 IP정보 등의 자료를 토대로, 서버를 통하여 부정이용 여부를
        분류ㆍ확인 합니다.
      </p>

      <p>
        ② “회사”는 사용자가 서비스 이용 중에 동일한 ID로 여러 학원이 이용한 것을 확인한 경우 그 즉시 아이디를 정지 혹은
        해지시킵니다.
      </p>

      <p>
        6. “사용자”가 계약된 결제일에 이용료 납부를 지연할 경우 5일 단위로 동일한 결제방식으로 재청구됩니다. 이 때, 3차
        청구 안내 이후 부재 시, 서비스가 중단됩니다.
      </p>

      <p>
        7. “유료고객”은 본 조 6항의 위반의 조치를 이유로 서비스 이용기간의 연장 혹은 환불을 요구할 수 없으며 어떠한
        경우라도 전체 미납금액의 정산 없이 오래영어 서비스 이용이 불가합니다. 미납정산은 등록된 동일한 결제방식 혹은
        고객별 전용계좌 송금이 가능합니다.
      </p>

      <p>
        8. 본 조 6항의 미납조치에 따른 ID 정지 이력이 3회를 초과한 “유료고객”은 유선, 문자, 전자우편을 통하여 위반
        내용의 통지 및 서비스 이용이 강제 해지처리 되며 해당 고객은 추후 오래영어 정식 가입이 불가합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 18 조 [서비스의 제공 및 변경]</span>
      </p>

      <p>1. 회사는 운영상 또는 기술상 등의 상당한 이유가 있는 경우 제공하고 있는 서비스를 변경할 수 있습니다.</p>

      <p>
        2. 전항에 따라 서비스가 변경되는 경우에는 변경되는 사유 및 내용을 본 약관 제6조에 따른 방법으로 유료고객에게
        통지 합니다.
      </p>

      <p>3. 회사는 본조의 2항에 의해 해지하게 되는 유료고객에게는 환불 규정에 따라 처리합니다.</p>

      <p>
        4. 회사 사정에 의한 서비스 이용 중단 및 기타 부득이한 사유로 서비스를 지속할 수 없을 경우에는 이를 유료고객에게
        공지한 후 최대한 신속하게 환불하여 드립니다.
      </p>

      <p>
        5. 한 “체험고객”은 최대 2회의 “무료체험”을 신청할 수 있으며 1회당 부여되는 기간은 7일입니다. 해당 기간 동안
        오래영어의 모든 서비스를 사용할 수 있습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 19 조 [서비스의 중단]</span>
      </p>

      <p>
        1. “회사”는 기기 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 오래영어
        App의 이용을 일시적으로 중단할 수 있습니다. 이 경우“회사”는 제6조에서 정한 방법으로 사용자에게 통지합니다. 다만,
        “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
      </p>

      <p>
        2. “회사”는 오래영어 App의 이용이 일시적으로 중단됨으로 인하여 “사용자”가 입은 손해에 대하여 배상 또는
        보상합니다. 이때 배상 또는 보상의 범위는 사용하지 못한 기간에 대한 사용연장 혹은 일할 계산된 이용료의
        환불입니다. 다만, “회사”가 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다.
      </p>

      <p>
        3. “회사”는 “학습자료”의 이용에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검을 실시하기 24시간 이전에 제
        6조에서 정한 방법으로 사용자에게 통지합니다. 이 경우 정기 점검시간은 제 6조에서 정한 방법에 따라 통지한 시간으로
        합니다.
      </p>

      <p>
        4. 사업의 포기, 업체 간의 통합 등의 이유로 “학습자료”를 제공할 수 없게 되는 경우에 “회사”는 제 6조에서 정한
        방법으로 “사용자”에게 통지하고, “회사”가 정한 바에 따라 “사용자”에게 보상합니다. 다만, “회사”가 보상기준 등을
        고지하지 아니하거나, 고지한 보상기준이 적절하지 않은 경우에는 「콘텐츠사용자보호지침」에 따라 “사용자”에게
        보상합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 20 조 [정보의 제공 및 광고의 게재]</span>
      </p>

      <p>
        1. 회사는 유료고객이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 이메일 또는 유선상 등의
        방법으로 유료고객에게 제공할 수 있습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 21 조 [유료고객이 등록한 게시물의 삭제]</span>
      </p>

      <p>
        1. 회사는 유료고객 등록한 게시물 중 본 약관 및 관계법령 등에 위배되는 게시물이 있는 경우 이를 지체 없이 삭제할
        수 있습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 22 조 [저작권]</span>
      </p>

      <p>1. 회사가 제공하는 모든 학습자료에 대한 저작권은 회사에 있습니다.</p>

      <p>
        2. 유료고객은 회사가 제공하는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이
        녹화ㆍ복제ㆍ편집ㆍ전시ㆍ전송ㆍ배포ㆍ판매ㆍ방송ㆍ공연하는 등의 행위로 회사의 저작권을 침해 하여서는 안 됩니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 23 조 [개인정보보호 의무와 파기]</span>
      </p>

      <p>
        1. 회사는 유료고객의 개인정보보호를 중요시 하며, 유료고객이 회사의 서비스를 이용함과 동시에 회사에게 제공한
        개인정보의 철저한 보호를 위하여 최선을 다하고 있습니다.
      </p>

      <p>
        2. 개인정보보호와 관련된 자세한 사항은 회사 사이트에서 전자적 표시형태로 제공되는 개인정보취급방침에서 확인할 수
        있습니다.
      </p>

      <p>
        3. 회사는 원칙적으로 고객의 개인정보를 회원 탈퇴 또는 이용 정지 시, 지체 없이 파기하고 있습니다. 단, 고객에게
        개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당
        기간 동안 개인정보를 안전하게 보관합니다.
      </p>

      <p>
        4. 참고로 회사는 ‘개인정보 유효기간제’에 따라 2년 간 서비스를 이용하지 않은 고객의 개인정보를 별도로 분리 보관
        또는 삭제하여 관리 후, 고객에게 고지 없이 분기별로 파기합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ fontWeight: '700' }}>제 4장 서비스의 환불 및 변경</span>
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 24 조 [관계법령상 청약철회 및 환불정책]</span>
      </p>

      <p>
        1. 회사의 본 약관상 환불규정 등은 “약관의 규제에 관한 법률”, “콘텐츠산업 진흥법”, “전자상거래 등에서의
        소비자보호에 관한 법률”, “학원의 설립ㆍ운영 및 과외교습에 관한 법률” 등을 반영했습니다.
      </p>

      <p>
        2. 회사는 유료고객에게 대금 등을 환불할 때에 기 지불한 대금 결제 방법과는 별도로 계좌 송금으로 전부 또는 일부를
        환불합니다.
      </p>

      <p>3. 청약철회 등의 경우 재화 등의 반환에 필요한 비용은 유료고객이 부담합니다.</p>

      <p>4. 본 조 1항의 관계법령에 따른 소비자의 청약철회가 불가능한 경우로는 다음과 같습니다.</p>

      <p>: 계약날짜 이후 학습자료를 사용을 한 경우</p>

      <p>
        <span style={{ color: '#4983ee' }}>
          <br />
        </span>
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 25 조 [회사의 환불규정]</span>
      </p>

      <p>
        1. 회사는 유료고객의 편의를 위하여 전 조 제 1항의 관계법령에도 불구하고 본 약관을 통하여 유료고객에게 좀 더
        유리한 별도의 환불규정을 적용합니다.
      </p>

      <p>
        2. “유료고객”은 환불 또는 변경사항을 유ㆍ무선의 방법을 통하여 회사에 그 의사를 표시하여야 하며, “회사”는
        환불요청을 접수하고 “유료고객”의 요청과 환불규정 확인 후 환불할 때에 기 지불한 대금 결제 방법과 동일하게
        처리합니다.
      </p>

      <p>3. 유료고객은 다음 사항에 따라 회사의 학습자료 서비스 환불 또는 변경을 요청할 수 있습니다.</p>

      <p>(1) 환불 기준</p>

      <p>
        <b>a. 개인 수강 클래스</b> (12개월 권)
      </p>

      <p>
        <Table
          style={{ width: '100%', textAlign: 'center' }}
          sx={{
            border: '1px solid rgb(197, 197, 197)',
            borderCollapse: 'collapse',
            'thead, tbody, tr, td, th': {
              border: '1px solid rgb(197, 197, 197)',
              fontWeight: '300',
            },
          }}
        >
          <thead style={{ height: '3rem' }}>
            {/* <tr style="background-color:#e5e5e5;"> */}
            <tr style={{ backgroundColor: '#e5e5e5' }}>
              <th scope='col'>결제일로부터</th>
              <th scope='col'>환불 금액</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '3rem' }}>
              <th scope='row'>3개월 이내</th>
              <td>
                잔여 유료기간의
                <br />
                이용료 70% 환불
              </td>
            </tr>
            <tr style={{ height: '3rem' }}>
              <th scope='row'>3개월 초과</th>
              <td>환불 불가</td>
            </tr>
          </tbody>
        </Table>
      </p>

      <p>
        <span>* 12개월 클래스는 결제 후 초기 3개월 간, 1대1 맞춤학습 튜터가 무료지원 되는 제도입니다.</span>
      </p>
      <p>
        <span>
          * 6개월 클래스는 결제일로부터 1개월 이내로 잔여 유료기간의 70% 환불 가능합니다. (초기 1개월 튜터지원)
        </span>
      </p>
      <p>
        <span>
          * 구독형 클래스는 최소 3개월 계약 유지되어야 하며, 중도 해지 시 월 구독 금액의 10% 위약금이 발생됩니다.
        </span>
      </p>
      <p>
        <span>* 카드결제 건의 환불은 PG사 수수료를 제외한 당사에 집금된 금액 기준으로 산정됩니다.</span>
      </p>
      <p>
        <span>* 실물 상품권 수령시, 환불 금액에서 상품가를 제외 후 산정됩니다.</span>
      </p>
      <p>
        <span>* 미사용 상품은 구매 후, 7일 이내 청약철회 가능합니다.</span>
      </p>

      <p>
        <br />
      </p>

      <p>
        <b>b. 학원/기관 연 혜택상품</b>
      </p>

      <p>
        {/* <span STYLE='font-weight:"bold";color:#cccccc'>(표식 참고)</span> */}
        <Table
          style={{ width: '100%', textAlign: 'center' }}
          sx={{
            border: '1px solid rgb(197, 197, 197)',
            borderCollapse: 'collapse',
            'thead, tbody, tr, td, th': {
              border: '1px solid rgb(197, 197, 197)',
              fontWeight: '300',
            },
          }}
        >
          <thead style={{ height: '3rem' }}>
            <tr style={{ backgroundColor: '#e5e5e5' }}>
              <th scope='col'>결제일로부터</th>
              <th scope='col'>환불 금액</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '3rem' }}>
              <th scope='row'>3개월 이내</th>
              <td>
                잔여 유료기간의
                <br />
                이용료 70% 환불
              </td>
            </tr>
            <tr style={{ height: '3rem' }}>
              <th scope='row'>3개월 초과</th>
              <td>환불 불가</td>
            </tr>
          </tbody>
        </Table>
      </p>

      <p>
        <span>
          * 유료 결제 시, 이후 기간이 무료로 적용되는 제도입니다.{' '}
          <span style={{ color: 'rgb(153, 153, 153)', fontStyle: 'italic' }}>
            ex) 6개월 유료 결제 시, 7개월 차부터 6개월 간 무료.
          </span>
        </span>
      </p>
      <p>
        <span>* 카드결제 건의 환불은 PG사 수수료를 제외한 당사에 집금된 금액 기준으로 산정됩니다.</span>
      </p>
      <p>
        <span>* 이용기간 내 자유롭게 상품 상향이 가능합니다. (인원 수 하향은 불가)</span>
      </p>

      <p>
        <br />
      </p>

      <p>
        <b>c. 학원/기관 월 정기상품</b>
      </p>

      <p>
        {/* <span STYLE='font-weight:"bold";color:#cccccc'>(표식 참고)</span> */}
        <Table
          style={{ width: '100%', textAlign: 'center' }}
          sx={{
            border: '1px solid rgb(197, 197, 197)',
            borderCollapse: 'collapse',
            'thead, tbody, tr, td, th': {
              border: '1px solid rgb(197, 197, 197)',
              fontWeight: '300',
            },
          }}
        >
          <thead style={{ height: '3rem' }}>
            <tr style={{ backgroundColor: '#e5e5e5' }}>
              <th scope='col'>결제일로부터</th>
              <th scope='col'>환불 금액</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '3rem' }}>
              <th scope='row'>7일 이내</th>
              <td>이용료 70% 환불</td>
            </tr>
            <tr style={{ height: '3rem' }}>
              <th scope='row'>7일 초과</th>
              <td>환불 불가</td>
            </tr>
          </tbody>
        </Table>
      </p>

      <p>
        <br />
      </p>

      <p>(2) 환불액이 소수점으로 나올 시 환불액은 소수점 이하 반올림합니다.</p>

      <p>(3) 과오금의 환불 방법 및 절차</p>

      <p>① “회사”는 과오금이 발생한 경우 이용대금의 결제 방법과 동일하게 환불처리 합니다.</p>

      <p>
        ② 회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 금융수수료 등에 관계없이 과오금 전액을 환불하여야 합니다.
      </p>

      <p>
        ③ 유료고객이 요청하는 과오금에 대해 회사는 정당하게 이용대금이 부과되었음을 입증할 경우 환불을 거절할 수
        있습니다.
      </p>

      <p>4. 서비스 하자 등에 의한 피해보상</p>

      <p>
        ① 회사의 귀책사유로 연속 1일 이상 이용중지 또는 장애가 발생하거나, 이용중지 또는 장애발생 누적시간이 1월 기준
        48시간을 초과하는 경우 (이용중지 또는 장애시간은 사용자가 사업자에게 통지 시부터 계산)에는 이용중지 또는 장애
        시간동안의 이용료를 일할 계산하여 다음 달 결제될 이용료에 적용합니다.
      </p>

      <p>
        ② 서비스 개선을 위한 설비점검 및 보수 시에 회사는 합리적인 시간 내에서 홈페이지를 통한 사전고지를 합니다. 이
        경우 회사는 서비스 이용 장애에 대한 책임이 없습니다.
      </p>

      <p>
        ③ 제 3자의 고의 또는 과실로 인하여 서비스가 중지되거나 장애가 발생한 경우 회사는 사후 고지를 합니다. 이 경우
        회사는 서비스 이용 장애에 대한 책임이 없습니다.
      </p>

      <p>
        5. 강제해지 유료고객이 관계법령 및 본 약관의 규정을 위반하여 회사로부터 강제해지 처리가 되는 경우에는 본 조의
        환불규정이 적용되지 않습니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ fontWeight: '700' }}>제 5장 피해보상 및 분쟁해결</span>
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 26 조 [면책조항]</span>
      </p>

      <p>
        1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한
        책임이 면제됩니다.
      </p>

      <p>2. 회사는 유료고객의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</p>

      <p>
        3. 회사는 사용자 상호간 또는 사용자와 제 3자 간에 학습 자료를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지
        않습니다.
      </p>

      <p>
        4. 회사는 유료고객이 구매한 태블릿 기기의 임의적인 설정 변경 또는 회사가 제공하지 않았던 소프트웨어를 설치하여
        발생하는 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>
          <br />
        </span>
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 27 조 [사용자의 권익보호]</span>
      </p>

      <p>
        1. 회사는 사용자의 수, 이용시간 등을 감안하여 사용자가 원활하게 학습 자료를 이용할 수 있도록 서버다운, 기술적
        오류 등에 대비한 설비를 구축하고 필요한 조치를 취합니다.
      </p>

      <p>
        2. 회사는 학습자료 계약에서 발생하는 사용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및
        체계(시스템 등)를 구비합니다.
      </p>

      <p>
        3. 회사는 동일 또는 유사한 사용자피해가 계속하여 발생하고 있는 사실을 인식한 경우 추가적인 사용자피해를 예방하기
        위하여 오래영어 App 내 또는 문자 등을 통하여 피해사실을 공지하고 안내합니다.
      </p>

      <p>
        <br />
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 28 조 [분쟁의 해결 등]</span>
      </p>

      <p>
        1. “회사”는 “사용자”가 제기하는 의견이나 불만에 대하여 적절하고 신속하게 처리하고, 그 결과를 통지합니다. 다만,
        신속한 처리가 곤란한 경우에 “회사”는 “사용자”에게 그 사유와 처리일정을 통보합니다.
      </p>

      <p>2. “회사”는 “사용자”가 제기한 의견 등이 정당하지 않음을 이유로 처리하지 않은 경우 이의 사유를 통보합니다.</p>

      <p>
        3. “회사”와 “사용자” 사이에 분쟁이 발생한 경우 “회사”또는 “사용자”는「콘텐츠산업진흥법」제 29조에서 정하고 있는
        콘텐츠분쟁조정위원회에 분쟁조정을 신청할 수 있습니다.
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>
          <br />
        </span>
      </p>

      <p>
        <span style={{ color: '#4983ee' }}>제 29 조 [재판관할]</span>
      </p>

      <p>
        “회사&quot;와 &quot;사용자&quot;간의 &quot;학습자료&quot;이용계약에 관한 소송에 관한 관할법원은
        서울중앙지방법원으로 할 수 있습니다.
      </p>

      <p>
        <br />
      </p>

      <p>[부칙]</p>
      <p>(시행일) 본 이용약관은 2023년 7월 1일부터 적용됩니다.</p>
      <p>이용약관 시행일자: 2023.07.01</p>
      <p>이용약관 시행일자: 2023.12.14</p>
      <p>이용약관 시행일자: 2024.01.01</p>
      <p>이용약관 시행일자: 2024.04.01</p>
    </Box>
  );
}
