import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled, SxProps, Theme } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { ModalContext } from '../../provider/ModalProvider';

interface ModalDefaultProps {
  visible: boolean;
  children: React.ReactNode;
  boxShadow?: boolean;
  opacity?: number;
  onClose: () => void;
  overflow?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
}

const CustomModal = styled(Modal)(({ theme }) => ({
  position: 'absolute',
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
}));

interface StyledBoxProps {
  boxShadow?: number;
}

const StyledBox = styled(Box)<StyledBoxProps>(props => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 'none',
  borderRadius: '0.35rem',
  '& > *': {
    boxShadow:
      props.boxShadow == 1
        ? props.className == 'grade'
          ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
          : // ? 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'
            // ? 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            // ? 'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset'
            '#00000026 2.5px 2.5px 10px'
        : 'none',
  },
}));

function ModalDefault(props: ModalDefaultProps) {
  const { setRootState } = useContext(ModalContext);

  const visible = props.visible;
  const children = props.children;
  const onClose = props.onClose;
  const boxShadow = props.boxShadow;
  const props_opacity = props.opacity;
  const overflow = props.overflow;
  const className = props.className;

  const [curOpacity, setCurOpacity] = useState(46);

  useEffect(() => {
    const modals = document.getElementsByClassName('modal_default');
    if (visible) {
      setOpacity(modals.length + 1);
    } else {
      setOpacity(modals.length);
    }
    setRootState(visible);
  }, [visible]);

  const setOpacity = (length: number) => {
    let cur_opacity = 0;
    if (length == 1) {
      cur_opacity = props_opacity ? props_opacity : 46;
    } else {
      cur_opacity = 4 - length;
    }
    setCurOpacity(cur_opacity);
  };

  const onFocusModal = (event: React.FocusEvent<HTMLDivElement>) => {
    event.currentTarget.blur();
  };

  return (
    <CustomModal
      open={visible}
      onClose={onClose}
      id='modal_default'
      className='modal_default'
      sx={props.sx}
      BackdropProps={{
        sx: {
          backgroundColor: `rgba(33, 33, 33, 0.${props_opacity ? props_opacity : curOpacity})`,
          transition: '0.2s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms !important',
        },
      }}
    >
      <StyledBox
        className={className}
        boxShadow={boxShadow ? 1 : 0}
        sx={{ overflow: overflow || boxShadow ? 'unset' : 'hidden' }}
      >
        {children}
      </StyledBox>
    </CustomModal>
  );
}

export default ModalDefault;
