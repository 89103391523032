import React from 'react';

interface IconReportProps {
  seq_id: string;
  className?: string;
}

const IconReportT = ({ seq_id, className }: IconReportProps) => {
  return (
    <svg
      width='80'
      height='80'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 80 80'
      xmlSpace='preserve'
      className={className}
    >
      <defs>
        <linearGradient id={seq_id} x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor='#35e62b'>
            <animate attributeName='stop-color' values='#35e62b; #29c600; #35e62b' dur='10s' repeatCount='indefinite' />
          </stop>
          <stop offset='100%' stopColor='#29c600'>
            <animate attributeName='stop-color' values='#29c600; #35e62b; #29c600' dur='10s' repeatCount='indefinite' />
          </stop>
        </linearGradient>
      </defs>
      <g>
        <rect fill='none' id='canvas_background' height='82' width='82' y='-1' x='-1' />
        <g display='none' overflow='visible' y='0' x='0' height='100%' width='100%' id='canvasGrid'>
          <rect fill='url(#gridpattern)' strokeWidth='0' y='0' x='0' height='100%' width='100%' />
        </g>
      </g>
      <g>
        <path
          stroke={`url(#${seq_id})`}
          id='icon-report-t-circle'
          d='m 11.4525,64.77062 l 28.71428,-50.25 l 28.71428,50.25 l -57.50,0 z'
          fillOpacity='null'
          strokeOpacity='null'
          strokeWidth='17'
          fill='none'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default IconReportT;
