import ModalDefault from './ModalDefault';
import Button from '../../components/button/Button';
import { styled, Box } from '@mui/material';
import { d_flex_center, dir_column } from '../../styles/common';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { MdStars } from 'react-icons/md';

const StyledModalTestInfoContainer = styled(Box)(props => ({
  width: '480px',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '5px',
}));

const StyledModalInfoWrapper = styled(Box)(props => ({
  width: '100%',
  height: '15.25rem',
  minHeight: '170px',
  padding: '1rem 0 1rem 0',
  ...d_flex_center,
}));

const StyledModalSelectBox = styled(Box)(props => ({
  width: '100%',
  height: '80%',
  ...d_flex_center,
  ...dir_column,
}));

const StyledIconBox = styled(Box)(props => ({
  width: '5rem',
  height: '5rem',
  marginBottom: '0.25rem',
  ...d_flex_center,
  svg: {
    width: '4.5rem',
    height: '4.5rem',
  },
}));

const StyledButtonWrapper = styled(Box)(props => ({
  width: '100%',
  height: '3.5rem',
  ...d_flex_center,
  border: 'none',
  backgroundColor: 'var(--cyan)',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
}));

const StyledButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.2rem',
}));

const StyledSpanSub = styled(Box)(props => ({
  cursor: 'default',
  fontSize: '1.1rem',
  color: props.theme.palette.black_3.main,
}));

const StyledSpanMain = styled(Box)(props => ({
  cursor: 'default',
  fontSize: '2.2rem',
  color: props.theme.palette.cyan.main,
}));

interface ModalTestInfoProps {
  visible: boolean;
  onClose: () => void;
  questions: number;
  passing: number;
}

function ModalTestInfo(props: ModalTestInfoProps) {
  const visible = props.visible;
  const onClose = props.onClose;
  const questions = props.questions;
  const passing = props.passing;

  return (
    <ModalDefault
      visible={visible}
      onClose={() => {
        return;
      }}
    >
      <StyledModalTestInfoContainer>
        <StyledModalInfoWrapper>
          <StyledModalSelectBox sx={{ paddingLeft: '2rem' }}>
            <StyledIconBox>
              <AiFillQuestionCircle color='#ff6c59' />
            </StyledIconBox>
            <StyledSpanSub component={'span'}>문항 수</StyledSpanSub>
            <StyledSpanMain component={'span'}>{questions}개</StyledSpanMain>
          </StyledModalSelectBox>
          <StyledModalSelectBox sx={{ paddingRight: '2rem', borderLeft: '1px solid #edeeef' }}>
            <StyledIconBox>
              <MdStars color='#fcbe00' />
            </StyledIconBox>
            <StyledSpanSub component={'span'}>합격 점수</StyledSpanSub>
            <StyledSpanMain component={'span'}>{passing}점</StyledSpanMain>
          </StyledModalSelectBox>
        </StyledModalInfoWrapper>
        <StyledButtonWrapper sx={{ cursor: 'pointer' }} onClick={onClose}>
          <StyledButton color='white' onClick={onClose}>
            확인
          </StyledButton>
        </StyledButtonWrapper>
      </StyledModalTestInfoContainer>
    </ModalDefault>
  );
}

export default ModalTestInfo;
