import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import ModalDefault from './ModalDefault';
import { IoIosArrowForward, IoIosSettings } from 'react-icons/io';
import { styled, Box, IconButton } from '@mui/material';
import { d_flex_center, d_flex, dir_column, d_flex_start } from '../../styles/common';
import { setManualCategory } from '../../../src/utils/setting_contents';
import { CgClose } from 'react-icons/cg';

import { deviceState } from 'recoil/common/device';
import { ImMobile2 } from 'react-icons/im';
import chromeLogo from 'assets/images/speaking-manual/chrome-logo.svg';
import img_01 from 'assets/images/speaking-manual/01.png';
import img_02 from 'assets/images/speaking-manual/02.png';
import img_03 from 'assets/images/speaking-manual/03.png';
import img_04 from 'assets/images/speaking-manual/04.png';
import img_05 from 'assets/images/speaking-manual/05.png';
import img_06 from 'assets/images/speaking-manual/06.png';
import img_07 from 'assets/images/speaking-manual/07.png';
import img_08 from 'assets/images/speaking-manual/08.png';
import img_09 from 'assets/images/speaking-manual/09.png';
import img_10 from 'assets/images/speaking-manual/10.png';
import img_11 from 'assets/images/speaking-manual/11.png';
import img_12 from 'assets/images/speaking-manual/12.png';

declare let window: any;

const StyledModalSettingsContainer = styled(Box)(props => ({
  width: '60vw',
  height: '65vh',
  fontSize: '1.2rem',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '5px',
}));

const StyledModalSettingsWrapper = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  padding: '4rem 2rem 2rem 2rem',
}));

const StyledModalSettingsBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
}));

const StyledCategoryBar = styled(Box)(props => ({
  width: '100%',
  height: '3rem',
  border: `1px solid ${props.theme.palette.gray_3.main}`,
  userSelect: 'none',
  ...d_flex,
}));

interface StyledCategoryItemProps {
  is_active: string;
}

const StyledCategoryItem = styled(Box)<StyledCategoryItemProps>(props => {
  const active_style =
    props.is_active === 'true'
      ? { background: 'linear-gradient(#652ae9, #4620e9)', color: props.theme.palette.white.main }
      : { color: props.theme.palette.gray.main, border: `1px solid ${props.theme.palette.gray_3.main}` };

  return {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    ...d_flex_center,
    ...active_style,
  };
});

const StyledContentWrapper = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 3rem)',
  border: `2px solid ${props.theme.palette.gray_3.main}`,
  borderTop: '0',
  userSelect: 'none',
  overflowY: 'auto',
  ...d_flex,
  ...dir_column,
}));

const CloseIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '2rem',
  transform: 'translate(50%, -50%)',
  right: '3rem',
  svg: {
    color: props.theme.palette.purple.main,
    width: '1.5rem',
    height: '1.5rem',
    strokeWidth: '1.5px',
  },
}));

const StyledContentWrap = styled(Box)(props => ({
  padding: '3rem',
  ...d_flex,
  ...dir_column,
  gap: '2rem',
}));

const StyledHeaderBox = styled(Box)(props => ({
  ...d_flex_start,
  gap: '0.8rem',

  '& .header-title': {
    fontSize: '1.6rem',
    fontWeight: '700',
  },

  '& .title-icon': {
    // width: '2.2rem',
    width: '1.8rem',
  },
}));

const StyledSubTitleBox = styled(Box)(props => ({
  '& .subtitle-title': {
    '& > span': {
      color: 'gray',
    },
  },
}));

const StyledContentBox = styled(Box)(props => ({
  ...d_flex,
  ...dir_column,
  gap: '0.5rem',

  '& .content-items': {
    ...d_flex,
    gap: '0.5rem',

    '& .content': {
      '& > span': {
        color: '#ffb609',
      },
    },
  },
}));

const StyledManualImgBox = styled(Box)(props => ({
  display: 'grid',
  placeItems: 'center',
  alignItems: 'flex-start',
  gap: '3rem',
  gridTemplateColumns: props.className === 'mobile' ? 'repeat(3,1fr)' : 'repeat(2,1fr)',
}));

const ManualImageWrap = styled(Box)(props => ({
  width: '100%',

  '& .index-circle': {
    width: '2rem',
    height: '2rem',
    backgroundColor: 'black',
    color: 'white',
    ...d_flex_center,
    borderRadius: '100%',
    marginBottom: '1rem',
    fontSize: '1rem',
  },

  '& > img': {
    width: '100%',
  },
}));

const DirectKatalkButtonWrap = styled(Box)(props => ({
  ...d_flex_center,
  width: '100%',
  color: '#4a3005',
  fontSize: '1.3rem',
  fontWeight: '600',

  '& > a': {
    color: 'inherit',
    '&:active': {
      color: 'inherit',
    },
    '&:visited': {
      color: 'inherit',
    },
    '&:hover': {
      color: 'inherit',
    },
  },
}));

const StyledKatalkButton = styled(Box)(props => ({
  ...d_flex_center,
  gap: '0.5rem',
  width: '16rem',
  height: '3rem',
  backgroundColor: '#ffe711',
  borderRadius: '0.5rem',
  cursor: 'pointer',

  '& .katalk-circle': {
    width: '1.8rem',
    height: '1.8rem',
    backgroundColor: '#4a3005',
    borderRadius: '100%',
    ...d_flex_center,
    color: '#ffe711',
  },
}));

const categoryContents = [
  {
    browser: {
      logoSrc: chromeLogo,
      title: '크롬 설정 확인',
      subTitle: [
        '오래영어는 <span>크롬</span> 브라우져에서 최적화된 학습이 가능합니다.',
        '스피킹 학습시, <span>브라우져 설정</span>을 꼭 확인해주세요.',
      ],
      contents: [
        '크롬 우측 상단 <span>점 3개</span> 아이콘 클릭 후 <span>설정</span> 클릭',
        '개인 정보 보호 및 보안 카테고리에서 <span>사이트 설정</span> 클릭',
        '하단의 <span>권한</span> 카테고리에서 <span>마이크</span> 선택',
        '오래영어 학습앱 선택 후 <span>마이크 권한 허용</span>',
      ],
      manualImages: [img_01, img_02, img_03, img_04],
    },
    settings: {
      logoSrc: '',
      title: '제어판 설정',
      subTitle: ['기기에서 원활한 음성 인식을 위해 <span>마이크 연결 상태</span>를 확인해주세요.'],
      contents: [
        '제어판 클릭 후 <span>하드웨어 및 소리</span> 클릭',
        '오디오 장치 관리에서 <span>마이크 연결</span> 상태를 확인',
      ],
      manualImages: [img_05, img_06],
    },
    android: {
      logoSrc: '',
      title: '모바일 및 태블릿 설정 확인',
      subTitle: ['모바일 및 태블릿 기기에서 오래영어 앱 권한 확인 후<br />마이크 권한을 허용해주세요.'],
      contents: [
        '설정 > <span>애플리케이션 정보</span>에서 오래영어 앱 검색',
        '<span>권한</span>에서 <span>마이크 권한</span>을 확인',
        '마이크 액세스 권한을 <span>앱 사용중에만 허용</span>으로 변경',
      ],
      manualImages: [img_07, img_08, img_09],
    },
    ios: {
      logoSrc: '',
      title: '모바일 및 태블릿 설정 확인',
      subTitle: ['모바일 및 태블릿 기기의 오래영어 앱 설정에서<br />마이크 및 음성인식을 활성화 해주세요.'],
      contents: [
        '기본메뉴 중 <span>설정</span> 클릭',
        '스크롤 후 <span>오래영어</span> 앱 클릭',
        '<span>마이크, 음성인식</span>을 초록색으로 활성화',
      ],
      manualImages: [img_10, img_11, img_12],
    },
  },
];

interface ModalSettingsProps {
  visible: boolean;
  onClose: () => void;
}

function ModalManual(props: ModalSettingsProps) {
  const visible = props.visible;
  const onClose = props.onClose;
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile, platform } = deviceStateData;

  const [curCategory, setCurCategory] = useState(0);
  const [categoryArr, setCategoryArr] = useState<{ id: string; label: string }[]>(
    setManualCategory(is_mobile, platform),
  );

  const onClickCategoryItem = async (idx: number) => {
    const loadImagePromises = categoryContents
      .map(categoryItem => {
        return Object.values(categoryItem)
          .map(category => {
            return category.manualImages.map(image => {
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = image;
                img.onload = resolve;
                img.onerror = reject;
              });
            });
          })
          .flat();
      })
      .flat();

    Promise.all(loadImagePromises).finally(() => {
      setCurCategory(idx);
    });

    const contentElement = document.querySelector('#category-content-wrap');
    if (contentElement) {
      contentElement.scrollTo(0, 0);
    }
  };

  const onClickKakao = () => {
    if (!is_mobile) return;
    if (platform == 'ios') {
      window.cordova.InAppBrowser.open('https://pf.kakao.com/_VvCxkxj/chat', '_system');
    } else {
      window.Open.openUrl('https://pf.kakao.com/_VvCxkxj/chat');
    }
  };
  return (
    <ModalDefault
      visible={visible}
      onClose={() => {
        return;
      }}
    >
      <StyledModalSettingsContainer>
        <CloseIconButton onClick={onClose} disableRipple>
          <CgClose />
        </CloseIconButton>
        <StyledModalSettingsWrapper>
          <StyledModalSettingsBox>
            <StyledCategoryBar>
              {categoryArr?.map((item, idx) => (
                <StyledCategoryItem
                  key={`settings_category_${item.id}`}
                  is_active={`${idx === curCategory ? 'true' : 'false'}`}
                  onClick={() => onClickCategoryItem(idx)}
                >
                  {item.label}
                </StyledCategoryItem>
              ))}
            </StyledCategoryBar>
            <StyledContentWrapper id='category-content-wrap'>
              {categoryContents?.map((item, index) => {
                if (!is_mobile) {
                  // PC
                  const items = curCategory === 0 ? item.browser : item.settings;
                  return (
                    <StyledContentWrap key={index}>
                      <StyledHeaderBox>
                        {curCategory === 0 ? (
                          <img src={items.logoSrc} alt='title-icon' className='title-icon' />
                        ) : (
                          <IoIosSettings size={'2.4rem'} fill='gray' style={{ margin: '-0.313rem' }} />
                        )}
                        <Box className='header-title'>{items.title}</Box>
                      </StyledHeaderBox>
                      <StyledSubTitleBox>
                        {items.subTitle.map((title, index) => (
                          <Box className='subtitle-title' key={index} dangerouslySetInnerHTML={{ __html: title }} />
                        ))}
                      </StyledSubTitleBox>
                      <StyledContentBox>
                        {items.contents.map((content, index) => (
                          <Box className='content-items' key={index}>
                            <Box>{index + 1}.</Box>
                            <Box className='content' dangerouslySetInnerHTML={{ __html: content }} />
                          </Box>
                        ))}
                      </StyledContentBox>
                      <StyledManualImgBox>
                        {items.manualImages?.map((img, index) => {
                          return (
                            <ManualImageWrap key={index}>
                              <Box className='index-circle'>{index + 1}</Box>
                              <img src={img} alt='manual-img' />
                            </ManualImageWrap>
                          );
                        })}
                      </StyledManualImgBox>

                      <DirectKatalkButtonWrap>
                        <a href='https://pf.kakao.com/_VvCxkxj/chat' target='_blank' rel='noreferrer'>
                          <StyledKatalkButton>
                            <Box>실시간 상담 바로가기</Box>
                            <Box className='katalk-circle'>
                              <IoIosArrowForward />
                            </Box>
                          </StyledKatalkButton>
                        </a>
                      </DirectKatalkButtonWrap>
                    </StyledContentWrap>
                  );
                } else {
                  // 모바일(ios, android)
                  const items = curCategory === 0 ? item.android : item.ios;
                  return (
                    <StyledContentWrap key={index}>
                      <StyledHeaderBox>
                        <ImMobile2 size={'2rem'} fill='black' style={{ margin: '0 -0.188rem' }} />
                        <Box className='header-title'>{items.title}</Box>
                      </StyledHeaderBox>
                      <StyledSubTitleBox>
                        {items.subTitle.map((title, index) => (
                          <Box className='subtitle-title' key={index} dangerouslySetInnerHTML={{ __html: title }} />
                        ))}
                      </StyledSubTitleBox>
                      <StyledContentBox>
                        {items.contents.map((content, index) => (
                          <Box className='content-items' key={index}>
                            <Box>{index + 1}.</Box>
                            <Box className='content' dangerouslySetInnerHTML={{ __html: content }} />
                          </Box>
                        ))}
                      </StyledContentBox>
                      <StyledManualImgBox className={'mobile'}>
                        {items.manualImages?.map((img, index) => {
                          return (
                            <ManualImageWrap key={index}>
                              <Box className='index-circle'>{index + 1}</Box>
                              <img src={img} alt='manual-img' />
                            </ManualImageWrap>
                          );
                        })}
                      </StyledManualImgBox>
                      <DirectKatalkButtonWrap>
                        <StyledKatalkButton onClick={() => onClickKakao()}>
                          <Box>실시간 상담 바로가기</Box>
                          <Box className='katalk-circle'>
                            <IoIosArrowForward />
                          </Box>
                        </StyledKatalkButton>
                      </DirectKatalkButtonWrap>
                    </StyledContentWrap>
                  );
                }
              })}
            </StyledContentWrapper>
          </StyledModalSettingsBox>
        </StyledModalSettingsWrapper>
      </StyledModalSettingsContainer>
    </ModalDefault>
  );
}

export default ModalManual;
