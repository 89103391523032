import { useState, useEffect, useRef, useContext } from 'react';
import ModalDefault from './ModalDefault';
import Button from '../button/Button';
import { styled, Box, IconButton } from '@mui/material';
import { d_flex_center, dir_column, d_flex_space_evenly } from '../../styles/common';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';

const StyledModalGradingContainer = styled(Box)(props => ({
  width: '32.5rem',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '2rem',
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledModalGradingWrapper = styled(Box)(props => ({
  width: '100%',
  height: '18.5rem',
  minHeight: '170px',
  padding: '1rem 0 1rem 0',
  ...d_flex_center,
}));

const StyledModalGradingBox = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  ...d_flex_space_evenly,
  ...dir_column,
  justifyContent: 'center',
}));

const CustomIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '3rem',
  transform: 'translate(50%, -50%)',
  right: '4rem',
  fontSize: '1.2rem',
  color: '#e3e3e3',
}));

const StyledIconBox = styled(Box)(props => ({
  width: '6rem',
  height: '6rem',
  borderRadius: '50%',
  ...d_flex_center,
  svg: {
    color: props.theme.palette.white.main,
    width: '4rem',
    height: '4rem',
  },
}));

const StyledButtonWrapper = styled(Box)(props => ({
  width: '100%',
  height: '60px',
  maxHeight: '10vh',
  ...d_flex_center,
  border: 'none',
  borderBottomLeftRadius: '2rem',
  borderBottomRightRadius: '2rem',
}));

const StyledButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.2rem',
}));

const StyledSpanSub = styled(Box)(props => ({
  cursor: 'default',
  fontSize: '1.6rem',
  color: props.theme.palette.black_4.main,
}));

interface ModalEndModuleProps {
  visible: boolean;
  label: string;
  kor_label: string;
  icon: JSX.Element;
  score: number;
  nextCallback: () => void;
  onClickRetry?: () => void;
}

function ModalEndModule(props: ModalEndModuleProps) {
  const visible = props.visible;
  const label = props.label;
  const kor_label = props.kor_label;
  const icon = props.icon;
  const score = props.score;
  const nextCallback = props.nextCallback;
  const { playEffectSound } = useContext(EffectSoundContext);
  const [time, setTime] = useState(3);
  const time_interval = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (visible) {
      playEffectSound(score > 70 ? 'pass' : 'fail');
      if (!props.onClickRetry) {
        time_interval.current = setInterval(() => {
          setTime(prev => prev - 1);
        }, 1000);
      }
    }
  }, [visible]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(time_interval.current);
      nextCallback();
    }
  }, [time]);

  const onClickCheck = () => {
    clearInterval(time_interval.current);
    nextCallback();
  };

  return (
    <ModalDefault
      visible={true}
      onClose={() => {
        return;
      }}
      boxShadow={true}
      className={'grade'}
      sx={visible && label != '' ? { zIndex: '1301' } : { zIndex: '-100' }}
    >
      <StyledModalGradingContainer>
        {props.onClickRetry ? (
          <CustomIconButton
            onClick={props.onClickRetry}
            disableRipple
            disableFocusRipple
            sx={{ cursor: 'pointer', color: '#e2e2e2' }}
          >
            다시 듣기
          </CustomIconButton>
        ) : (
          <CustomIconButton disableRipple disableFocusRipple sx={{ cursor: 'default' }}>
            다음 {time}
          </CustomIconButton>
        )}

        <StyledModalGradingWrapper>
          <StyledModalGradingBox>
            <StyledIconBox
              sx={{
                backgroundColor: '#59d577',
              }}
            >
              {icon}
            </StyledIconBox>
            <Box sx={{ width: '80%', ...d_flex_space_evenly, ...dir_column }}>
              <StyledSpanSub
                component={'span'}
                sx={{
                  color: '#59d577',
                  fontSize: '2.5rem',
                  fontWeight: '800',
                  marginTop: '1rem',
                  letterSpacing: '-0.09375rem',
                }}
              >
                {label ? label : 'PERFECT'}
              </StyledSpanSub>
              <StyledSpanSub component={'span'} sx={{ color: '#777', fontWeight: '500', lineHeight: '1.2' }}>
                {kor_label}
              </StyledSpanSub>
            </Box>
          </StyledModalGradingBox>
        </StyledModalGradingWrapper>
        <StyledButtonWrapper
          sx={{
            cursor: 'pointer',
            backgroundColor: '#59d577',
          }}
          onClick={onClickCheck}
        >
          <StyledButton color='white'>확인</StyledButton>
        </StyledButtonWrapper>
      </StyledModalGradingContainer>
    </ModalDefault>
  );
}

export default ModalEndModule;
