import { useState, createContext } from 'react';

import ModalConfirm from '../components/modal/ModalConfirm';
import ModalStudyConfirm from '../components/modal/ModalStudyConfirm';
import ModalAlert from '../components/modal/ModalAlert';
import ModalCheck from '../components/modal/ModalCheck';

import {
  confirm_buttons,
  confirm_contents,
  alert_contents,
  check_contents,
  setTitle,
  setContents,
  study_confirm_contents,
  study_confirm_buttons,
} from '../utils/modal_contents';
import ModalCheck2 from '../components/modal/ModalCheck2';

// ========================================================================================
// Type 설정
interface ModalConfirmDataType {
  visible: boolean;
  title: string;
  left: string;
  right: string;
  type: string;
  block_background_close?: boolean;
  confirm_callback: () => void;
  cancle_callback?: () => void;
}

interface ModalStudyConfirmDataType {
  visible: boolean;
  title: string;
  left: string;
  right: string;
  type: string;
  confirm_callback: () => void;
  cancle_callback?: () => void;
}

interface ModalAlertDataType {
  visible: boolean;
  title: string;
  timeout: ReturnType<typeof setTimeout> | undefined;
  color: string | undefined;
  type: string;
  use_close_button: boolean;
}
interface ModalCheckDataType {
  visible: boolean;
  title: string;
  contents: string;
  type: string | undefined;
  oneTimeModalKey?: string;
  check_callback?: () => void;
}

interface ModalControllerType {
  // Modal Confrim
  root_visible: boolean;
  setRootState: (visible: boolean) => void;
  modal_confirm: {
    data: ModalConfirmDataType;
    closeModalConfirm: () => void;
    openModalConfirm: (
      button_type: string,
      type: string,
      confirm_callback: () => void,
      cancle_callback?: () => void,
      target?: string,
      block_background_close?: boolean,
    ) => void;
  };
  modal_study_confirm: {
    data: ModalStudyConfirmDataType;
    closeModalStudyConfirm: () => void;
    openModalStudyConfirm: (
      button_type: string,
      type: string,
      confirm_callback: () => void,
      cancle_callback?: () => void,
      target?: string,
    ) => void;
  };
  modal_alert: {
    data: ModalAlertDataType;
    closeModalAlert: () => void;
    openModalAlert: (
      type: string,
      color?: string,
      target?: string,
      callback?: () => void,
      timeout?: number,
      manualClose?: boolean,
      useCloseButton?: boolean,
    ) => void;
  };
  modal_check: {
    data: ModalCheckDataType;
    closeModalCheck: () => void;
    openModalCheck: (type: string, score?: string, oneTimeModalKey?: string, check_callback?: () => void) => void;
  };
}

// ========================================================================================
// 초기값 설정
export const ModalContext = createContext<ModalControllerType>({
  root_visible: false,
  setRootState: (visible: boolean) => {
    return;
  },
  modal_confirm: {
    data: {
      visible: false,
      title: '',
      left: '',
      right: '',
      type: '',
      confirm_callback: () => {
        return;
      },
      cancle_callback: () => {
        return;
      },
    },
    closeModalConfirm: () => {
      return;
    },
    openModalConfirm: (
      button_type: string,
      type: string,
      confirm_callback: () => void,
      cancle_callback?: () => void,
      target?: string,
      block_background_close?: boolean,
    ) => {
      return;
    },
  },
  modal_study_confirm: {
    data: {
      visible: false,
      title: '',
      left: '',
      right: '',
      type: '',
      confirm_callback: () => {
        return;
      },
      cancle_callback: () => {
        return;
      },
    },
    closeModalStudyConfirm: () => {
      return;
    },
    openModalStudyConfirm: (
      button_type: string,
      type: string,
      confirm_callback: () => void,
      cancle_callback?: () => void,
      target?: string,
    ) => {
      return;
    },
  },
  modal_alert: {
    data: {
      visible: false,
      title: '',
      type: '',
      timeout: undefined,
      color: undefined,
      use_close_button: false,
    },
    closeModalAlert: () => {
      return;
    },
    openModalAlert: (type: string) => {
      return;
    },
  },
  modal_check: {
    data: {
      visible: false,
      title: '',
      contents: '',
      type: undefined,
      check_callback: undefined,
    },
    closeModalCheck: () => {
      return;
    },
    openModalCheck: (type: string) => {
      return;
    },
  },
});

// ========================================================================================
// Provider 설정

function ModalProvider(props: { children: React.ReactNode }) {
  const children = props.children;

  // modalController 객체 안에 넣으면
  // 실시간 렌더 x
  // useState 사용 바람
  const [rootVisible, setRootVisible] = useState(false);
  const [modalConfirmData, setModalConfirmData] = useState<ModalConfirmDataType>({
    visible: false,
    title: '',
    left: '',
    right: '',
    type: '',
    block_background_close: false,
    confirm_callback: () => {
      return;
    },
    cancle_callback: () => {
      return;
    },
  });

  const [modalStudyConfirmData, setModalStudyConfirmData] = useState<ModalStudyConfirmDataType>({
    visible: false,
    title: '',
    left: '',
    right: '',
    type: '',
    confirm_callback: () => {
      return;
    },
    cancle_callback: () => {
      return;
    },
  });

  const [modalAlertData, setModalAlertData] = useState<ModalAlertDataType>({
    visible: false,
    title: '',
    type: '',
    timeout: undefined,
    color: undefined,
    use_close_button: false,
  });

  const [modalCheckData, setModalCheckData] = useState<ModalCheckDataType>({
    visible: false,
    title: '',
    contents: '',
    type: undefined,
    check_callback: () => {
      return;
    },
  });

  // 모달 컨트롤러
  const modalController: ModalControllerType = {
    root_visible: rootVisible,
    setRootState: (visible: boolean) => {
      setRootVisible(visible);
    },
    modal_confirm: {
      data: modalConfirmData,
      closeModalConfirm: () => {
        setModalConfirmData({
          title: '',
          visible: false,
          left: '',
          right: '',
          type: '',
          block_background_close: false,
          confirm_callback: () => {
            return;
          },
          cancle_callback: () => {
            return;
          },
        });
      },
      openModalConfirm: (
        button_type: string,
        type: string,
        confirm_callback: () => void,
        cancle_callback?: () => void,
        target?: string,
        block_background_close?: boolean,
      ) => {
        const title_data = {
          title: confirm_contents[type].title,
          sub_title: confirm_contents[type].sub_title ? confirm_contents[type].sub_title : null,
          target: target ? target : null,
        };

        setModalConfirmData({
          title: setTitle(title_data),
          visible: true,
          type: type,
          left: confirm_buttons[button_type][0],
          right: confirm_buttons[button_type][1],
          confirm_callback: confirm_callback,
          cancle_callback: cancle_callback,
          block_background_close,
        });
      },
    },
    modal_study_confirm: {
      data: modalStudyConfirmData,
      closeModalStudyConfirm: () => {
        setModalStudyConfirmData({
          title: '',
          visible: false,
          left: '',
          right: '',
          type: '',
          confirm_callback: () => {
            return;
          },
          cancle_callback: () => {
            return;
          },
        });
      },
      openModalStudyConfirm: (
        button_type: string,
        type: string,
        confirm_callback: () => void,
        cancle_callback?: () => void,
        target?: string,
      ) => {
        const title_data = {
          title: study_confirm_contents[type].title,
          sub_title: study_confirm_contents[type].sub_title ? study_confirm_contents[type].sub_title : null,
          target: target ? target : null,
        };
        setModalStudyConfirmData({
          title: setTitle(title_data),
          visible: true,
          type: type,
          left: study_confirm_buttons[button_type][0],
          right: study_confirm_buttons[button_type][1],
          confirm_callback: confirm_callback,
          cancle_callback: cancle_callback,
        });
      },
    },
    modal_alert: {
      data: modalAlertData,
      closeModalAlert: () => {
        setModalAlertData({
          visible: false,
          title: '',
          type: '',
          timeout: undefined,
          color: undefined,
          use_close_button: false,
        });
        if (modalAlertData.timeout != undefined) {
          clearTimeout(modalAlertData.timeout);
        }
      },
      openModalAlert: (
        type: string,
        color?: string,
        target?: string,
        callback?: () => void,
        timeout?: number,
        manualClose?: boolean,
        useCloseButton?: boolean,
      ) => {
        const title_data = {
          title: alert_contents[type].title,
          sub_title: alert_contents[type].sub_title ? alert_contents[type].sub_title : null,
          target: target ? target : null,
        };
        setModalAlertData(state => {
          return {
            visible: true,
            title: setTitle(title_data),
            type: type,
            use_close_button: useCloseButton ? true : false,
            timeout: manualClose
              ? undefined
              : setTimeout(
                  () => {
                    modalController.modal_alert.closeModalAlert();
                    if (callback) callback();
                  },
                  timeout ? timeout : 2000,
                ),
            color: color,
          };
        });
      },
    },
    modal_check: {
      data: modalCheckData,
      closeModalCheck: () => {
        setModalCheckData({ visible: false, title: '', contents: '', type: undefined, check_callback: undefined });
      },
      openModalCheck: (type: string, score?: string, oneTimeModalKey?: string, check_callback?: () => void) => {
        if (score) {
          switch (true) {
            case Number(score) >= 91:
              type = type + '1';
              break;
            case Number(score) >= 81:
              type = type + '2';
              break;
            case Number(score) >= 71:
              type = type + '3';
              break;
            case Number(score) >= 61:
              type = type + '4';
              break;
            default:
              type = type + '5';
              break;
          }
        }
        const title_data = {
          title: check_contents[type].title,
          sub_title: null,
          target: null,
        };
        const contents_data = {
          contents: check_contents[type].contents,
          score: score ? score : null,
        };
        setModalCheckData({
          visible: true,
          title: setTitle(title_data),
          contents: setContents(contents_data),
          type: score ? type : undefined,
          oneTimeModalKey: oneTimeModalKey,
          check_callback: check_callback,
        });
      },
    },
  };

  return (
    <>
      <ModalContext.Provider value={modalController}>
        {children}
        <ModalConfirm />
        <ModalStudyConfirm />
        <ModalAlert />
        <ModalCheck />
        <ModalCheck2 />
      </ModalContext.Provider>
    </>
  );
}

export default ModalProvider;
