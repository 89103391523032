import { d_flex_center, d_block } from './common';
import { styled, Box } from '@mui/material';

interface ThContentProps {
  $is_center?: boolean;
  $is_no?: boolean;
  $padding?: string;
}

// span tag
export const ThContent = styled(Box)<ThContentProps>(props => ({
  justifyContent: props.$is_center ? 'center' : 'flex-start',
  paddingLeft: props.$is_no && props.$padding ? props.$padding : '0',
}));

interface TdContentProps {
  $is_center?: boolean;
  $is_no?: boolean;
  $batch?: string;
  $padding?: string;
  text_color?: string;
}

// span tag
export const TdContentOnlyOne = styled(Box)<TdContentProps>(props => ({
  // justifyContent: props.$is_center === 'true' ? 'center' : 'flex-start',
  justifyContent: props.$is_center ? 'center' : 'flex-start',
  color: props.text_color ? props.text_color : 'inherit',
}));

// span tag
export const TdContent = styled(Box)<TdContentProps>(props => ({
  width: '100%',
  minHeight: '3rem',
  overflow: props.$batch === 'true' ? 'visible' : 'hidden',
  color: props.text_color ? props.text_color : 'inherit',
  ...d_flex_center,
  justifyContent: props.$is_center ? 'center' : 'flex-start',
  paddingLeft: props.$is_no && props.$padding ? props.$padding : '0',
  '& .MuiCheckbox-root': {
    justifyContent: props.$is_center ? 'center' : 'flex-start',
  },
}));

interface TdHoverProps {
  $hover?: boolean;
}

export const TdHover = styled(Box)<TdHoverProps>(props => {
  let hover_style = {};
  if (props.$hover) {
    hover_style = {
      width: '100%',
      height: '100%',
      span: {
        width: '95%',
        fontSize: '1.05rem',
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...d_block,
        '&:hover': {
          color: props.theme.palette.purple.main,
          textDecoration: 'underline',
        },
      },
    };
  } else {
    hover_style = {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '90%',
    };
  }
  return {
    ...hover_style,
  };
});
