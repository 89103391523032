import * as React from 'react';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import en from 'date-fns/locale/en-US';

import { styled, Box, IconButton, SxProps, Theme } from '@mui/material';

import 'react-datepicker/dist/react-datepicker.css';

import { d_flex_space_around } from '../../styles/common';

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

interface CustomDatePickerProps {
  useMonth?: boolean;
  useYear?: boolean;
  minDate?: string;
  maxDate?: string;
  selected?: string;
  onCancel?: () => void;
  onChange?: (date: Date) => void;
  onClose?: (date: Date) => void;
  sx?: SxProps<Theme>;
}

registerLocale('en-US', { ...en, options: { ...en.options, weekStartsOn: 0 } });

const CustomHeaderBox = styled(Box)(props => ({
  ...d_flex_space_around,
  color: '#3e676a',
  padding: '0.7rem 0 0.7rem 0',
  fontSize: '1.2rem',
  fontWeight: 'bolder',
}));

const CustomIconButton = styled(IconButton)(props => ({
  backgroundColor: 'white',
  svg: {
    color: props.theme.palette.purple.main,
    width: '1.2rem',
    height: '1.2rem',
    strokeWidth: '1rem',
  },
  '&.Mui-disabled > svg': {
    color: props.theme.palette.gray_2.main,
  },
}));

function CustomDatePicker(props: CustomDatePickerProps) {
  const sx = props.sx;
  const sdate = props.selected ? props.selected : dayjs().format();
  const onChange = props.onChange;
  const onClose = props.onClose;
  const useMonth = props.useMonth ? props.useMonth : false;
  const useYear = props.useYear ? props.useYear : false;
  const maxDate = props.maxDate ? new Date(props.maxDate) : new Date(dayjs().format());
  const minDate = props.minDate ? new Date(props.minDate) : new Date(dayjs('2020-01-01').format());
  const [startDate, setStartDate] = useState<Date>(new Date(dayjs(sdate).format()));
  const [showYear, setShowYear] = useState<boolean>(false);
  const [showMonth, setShowMonth] = useState<boolean>(useMonth);
  const [closeOnSelect, setCloseOnSelect] = useState<boolean>(true);

  function onChangeDate(date: Date) {
    setStartDate(date);

    setCloseOnSelect(true);
    if (onChange != undefined) onChange(date);
    if (showYear && !useYear) {
      setShowYear(false);
      setShowMonth(true);
      if (!useMonth) setCloseOnSelect(false);
    } else if (showMonth && !useMonth) {
      setShowMonth(false);
    }
  }

  function onShowMonth() {
    setShowMonth(true);
    setCloseOnSelect(false);
  }
  function onShowYear() {
    setShowYear(true);
    setCloseOnSelect(false);
  }

  const handleDateChangeRaw = (e: any) => {
    e.preventDefault();
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', ...sx }}>
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CustomHeaderBox>
            <CustomIconButton
              onClick={showYear || showMonth ? decreaseYear : decreaseMonth}
              disableRipple
              disabled={showYear || showMonth ? prevYearButtonDisabled : prevMonthButtonDisabled}
            >
              <AiOutlineLeft />
            </CustomIconButton>
            <Box>
              {!showMonth && !showYear ? (
                <>
                  <Box onClick={() => onShowMonth()} component={'span'} sx={{ cursor: 'default' }}>
                    {date ? dayjs(date).format('MMM') : ''}
                  </Box>
                  &nbsp;
                </>
              ) : null}

              <Box onClick={() => onShowYear()} component={'span'} sx={{ cursor: 'default' }}>
                {date ? dayjs(date).format('YYYY') : ''}
              </Box>
            </Box>
            <CustomIconButton
              onClick={showYear || showMonth ? increaseYear : increaseMonth}
              disableRipple
              disabled={showYear || showMonth ? nextYearButtonDisabled : nextMonthButtonDisabled}
            >
              <AiOutlineRight />
            </CustomIconButton>
          </CustomHeaderBox>
        )}
        selected={startDate}
        onChange={(date: Date) => onChangeDate(date)}
        startDate={startDate}
        withPortal
        // calendarStartDay={1}
        useWeekdaysShort={true}
        dayClassName={date =>
          dayjs(date).format('ddd') == 'Sat' ? 'saturday' : dayjs(date).format('ddd') == 'Sun' ? 'sunday' : ''
        }
        calendarClassName={'datepicker'}
        showYearPicker={showYear}
        showMonthYearPicker={showMonth}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={useMonth ? 'yyyy-MM' : 'yyyy-MM-dd'}
        shouldCloseOnSelect={closeOnSelect}
        onCalendarClose={() => (onClose ? onClose(startDate) : console.log(startDate))}
        className={'datepicker_input'}
        onFocus={(e: any) => e.target.blur()}
        onChangeRaw={handleDateChangeRaw}
        locale='en-US'
      />
    </Box>
  );
}

export default CustomDatePicker;
