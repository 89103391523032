import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import O_pc from '../../../assets/images/learning/O_pc.png';
import O_mobile from '../../../assets/images/learning/O_mobile.png';
import X_pc from '../../../assets/images/learning/X_pc.png';
import X_mobile from '../../../assets/images/learning/X_mobile.png';
import { useRecoilState } from 'recoil';
import { deviceState } from '../../../recoil/common/device';

interface OXMarkProps {
  show: boolean;
  right: boolean;
  grammar?: boolean;
  grammar_popquiz?: boolean;
}

const OXMark = (props: OXMarkProps) => {
  const show = props.show;
  const right = props.right;
  const grammar = props.grammar;
  const grammar_popquiz = props.grammar_popquiz;
  const [deviceStateData, setDeviceStateData] = useRecoilState(deviceState);
  const { is_mobile } = deviceStateData;
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (show) {
      setImgSrc(right ? (is_mobile ? O_mobile : O_pc) : is_mobile ? X_mobile : X_pc);
    } else {
      setImgSrc('');
    }
  }, [show]);

  return (
    <Box
      sx={{
        display: show ? 'block' : 'none',
        position: 'relative',
        width: '100%',
        height: 0,
      }}
      id='ox-wrapper'
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 15,
          top: grammar && grammar_popquiz ? 'calc((100vh - 4rem - 5rem - 4rem) * 0.5)' : grammar ? '15vh' : '17vh',
          transform: grammar && grammar_popquiz ? 'translateY(-50%)' : 'none',
          left: '50%',
          width: '46vh',
          height: '46vh',
          marginLeft: '-23vh',
          backgroundImage: `url(${imgSrc})`,
          opacity: right ? 0.85 : 0.8,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      ></Box>
    </Box>
  );
};

export default OXMark;
